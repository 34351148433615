export default {
  labels: {
    category_id: 'Kategorija',
    body: 'Sadržaj e-pošte',
    filterTemplateKeys: 'Platzhalter durchsuchen...',
    preview: 'Pregled',
    signature: 'Zadani potpis e-pošte',
    subject: 'Naslov',
    placeholder: 'Platzhalter hinzufügen',
    fileClassificationTags: 'Automatska oznaka za datoteke:',
    fileClassificationTagsDescription:
      'Odabirom oznake narudžbe, ova oznaka se automatski dodaje narudžbi ako je ovaj e-mail poslan.',
    classificationTags: 'Automatska oznaka za narudžbe:',
    classificationTagsDescription:
      'Odabirom oznake datoteke, ova se oznaka automatski dodaje datoteci ako je ova e-pošta poslana s ovom datotekom kao privitak.',
  },
  notifications: {
    emailTemplateCreate: {
      success: 'Kreiran predložak e-pošte!',
      validationError: 'Neuspješna provjera valjanosti!',
    },
    emailTemplateEdit: {
      success: 'Izmjenjen predložak e-pošte!',
      validationError: 'Neuspješna provjera valjanosti!',
    },
    emailTemplateDelete: {
      success: 'Izbrisan predložak e-pošte!',
    },
  },
  dialogs: {
    emailTemplateCreate: {
      title: 'Izrada E-pošta predloška',
      note: 'Ovdje možete izraditi predloške e-pošte.',
      signatureNote:
        'Ako je aktiviran "Zadani predložak e-pošte", tada će se ovaj predložak prikazivati ​​izravno sa svakom novom e-poštom.',
    },
    emailTemplateDelete: {
      title: 'Izbrisan predložak e-pošte',
      note: 'Želite li izbrisati predložak e-pošte?',
    },
    chooseEmailTemplate: {
      title: 'Odaberite predložak e-pošte',
      note: 'Odabirom predloška brišu se polja koja su već postavljena!',
    },
  },
  pages: {
    emailTemplateOverview: {
      title: 'Pregled e-pošte',
      description: 'Ovdje možete vidjeti popis predložaka e-pošte.',
      toolbar: {
        create: 'Stvori predložak',
      },
    },
    emailTemplateEdit: {
      title: 'Predložak e-pošte - {{email_template_id}}',
      description: 'Ovdje možete urediti predložak e-pošte',
    },
  },
};
