import { Loader, Tag } from '@module/common';
import {
  CancelButton,
  CreateButton,
  Dialog,
  DialogContent,
  DialogHeader,
  DialogHeaderBar,
  DialogHeaderIcon,
} from '@module/layout';
import { DialogProps, useDialogs } from '@module/shared/dialogs';
import {
  Form,
  FormElement,
  FormStateConsumer,
  FormStateProvider,
  useSchemaValidator,
} from '@module/shared/forms';
import { stripGraphQLTypeName } from '@module/shared/graphql';
import { NotificationType, useNotifications } from '@module/shared/notifications';
import { DialogActionsBar } from '@progress/kendo-react-dialogs';
import { useCallback, useId } from 'react';
import { useTranslation } from 'react-i18next';

import { SubformClassificationTagFormFields } from '../components/SubfromClassificationTagFormFields';
import {
  SubformClassificationTagFragment,
  useUpdateSubformClassificationTagMutation,
} from '../graphql';
import { useSubformClassificationTagInputSchema } from '../hooks';

interface EditSubformClassificationTagDialogProps extends DialogProps {
  tag: SubformClassificationTagFragment;
}

export const EditSubformClassificationTagDialog = (
  props: EditSubformClassificationTagDialogProps,
) => {
  const { t } = useTranslation();
  const { hideDialog } = useDialogs();
  const { showNotification } = useNotifications();
  const schema = useSubformClassificationTagInputSchema();
  const validator = useSchemaValidator(schema);
  const [{ fetching: isSaving }, mutate] = useUpdateSubformClassificationTagMutation();
  const formId = useId();

  const handleSubmit = useCallback(
    (values: unknown) => {
      const input = stripGraphQLTypeName(values as Tag);
      mutate(
        {
          id: props.tag.id,
          input: { title: input.title, color: input.color },
        },
        { additionalTypenames: ['TagPaginator'] },
      ).then((response) => {
        if (!response.error) {
          showNotification(
            t('settings.pages.subformClassificationTags.notifications.edit.success'),
            NotificationType.Success,
          );
          hideDialog(props.dialogId);
        }
        if (response.error) {
          showNotification(
            t('settings.pages.subformClassificationTags.notifications.edit.error'),
            NotificationType.Error,
          );
        }
      });
    },
    [mutate, hideDialog, showNotification, props.dialogId, t, props.tag],
  );

  const handleClose = () => {
    hideDialog(props.dialogId);
  };

  return (
    <FormStateProvider>
      <Dialog width="small" onClose={handleClose}>
        {isSaving && <Loader />}

        <DialogHeaderBar layout="flex">
          <DialogHeaderIcon iconClass="l-i-contact u-text-2xl" color="primary" />

          <DialogHeader
            title={t('settings.pages.subformClassificationTags.dialogs.edit.title')}
            description={t('settings.pages.subformClassificationTags.dialogs.edit.note')}
          />
        </DialogHeaderBar>

        <DialogContent>
          <Form
            id={formId}
            schema={schema}
            onSubmit={handleSubmit}
            validator={validator}
            initialValues={props.tag}
            render={(formRenderProps) => (
              <FormElement formRenderProps={formRenderProps}>
                <SubformClassificationTagFormFields />
              </FormElement>
            )}
          />
        </DialogContent>
        <DialogActionsBar layout="end">
          <FormStateConsumer>
            {() => (
              <>
                <CancelButton onClick={handleClose} />
                <CreateButton type="submit" form={formId} />
              </>
            )}
          </FormStateConsumer>
        </DialogActionsBar>
      </Dialog>
    </FormStateProvider>
  );
};
