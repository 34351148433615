import { useVerifyEmailMutation } from '@module/auth/graphql';
import { BrandingPanel, Loader, Message, VerifyEmailInput } from '@module/common';
import { PublicLayout } from '@module/layout';
import { Nl2Br } from '@module/shared/components';
import { Form, FormButtons, useSchemaValidator } from '@module/shared/forms';
import { Button } from '@progress/kendo-react-buttons';
import { FormElement } from '@progress/kendo-react-form';
import { Card, CardBody, CardHeader } from '@progress/kendo-react-layout';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';
import { ObjectSchema } from 'yup';

import { FooterMini } from '../../components/FooterMini';

const schema: ObjectSchema<VerifyEmailInput> = yup.object({
  token: yup.string().required(),
});

function useVerifyEmailInputSchema() {
  const validator = useSchemaValidator(schema);
  return { schema, validator };
}

const FormFields = () => {
  const { t } = useTranslation();

  return (
    <FormElement>
      <div className="k-text-center">
        <Nl2Br text={t('auth.pages.verifyEmail.note')} />
      </div>
      <FormButtons>
        <Button className="k-flex-grow" themeColor="primary" size="large" type={'submit'}>
          {t('auth.pages.verifyEmail.submit')}
        </Button>
      </FormButtons>
    </FormElement>
  );
};

export const VerifyEmailPage = () => {
  const { t } = useTranslation();
  const params = useParams();
  const navigate = useNavigate();
  const [message, setMessage] = useState<string>();
  const { validator } = useVerifyEmailInputSchema();
  const [error, setError] = useState<string>();
  const [{ fetching: isLoading }, verifyEmail] = useVerifyEmailMutation();

  // get url params
  const token = params?.token;
  const initialValues = useMemo(() => {
    return { token };
  }, [token]);

  // check url params
  useEffect(() => {
    if (!token) {
      navigate('/');
    }
  }, [navigate, token]);

  // handle form submit
  const handleSubmit = useCallback(
    (values: { [name: string]: unknown }) => {
      const input = values as VerifyEmailInput;

      verifyEmail({ input }).then((response) => {
        if (!response.error) {
          setMessage(t(response.data?.verifyEmail?.message ?? ''));
        }
        if (response.error?.graphQLErrors.length) {
          setError(t(response.error?.graphQLErrors[0].message));
        }
      });
    },
    [t, verifyEmail],
  );

  return (
    <PublicLayout>
      <div className="k-display-flex k-flex-grow k-flex-column k-justify-content-top k-align-items-center">
        <Card style={{ width: 460 }} className="k-shadow k-mt-20">
          <CardHeader className="k-px-8 k-pt-8 k-text-center">
            <BrandingPanel size="large" />
            <h1>{t('auth.pages.verifyEmail.title')}</h1>
          </CardHeader>
          <CardBody className="k-px-16 k-py-12">
            {message && <Message type="success">{message}</Message>}
            {error && <Message type="error">{error}</Message>}
            {token && !message && !error && (
              <Form
                schema={schema}
                ignoreModified={true}
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validator={validator}
                render={() => <FormFields />}
              />
            )}
            {isLoading && <Loader />}
          </CardBody>
        </Card>
        <FooterMini />
      </div>
    </PublicLayout>
  );
};
