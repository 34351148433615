import './AutoSizeTextArea.scss';

import { classNames } from '@progress/kendo-react-common';
import { TextArea, TextAreaHandle, TextAreaProps } from '@progress/kendo-react-inputs';
import { forwardRef } from 'react';

export interface AutoSizeTextAreaProps extends TextAreaProps {
  autoSize?: never;
  prefix?: never;
  suffix?: never;
}

/**
 * A wrapper around the TextArea component that automatically resizes based on the content.
 *
 * We use this as an alternative to the `autoSize` prop of the original TextArea component, which
 * has jumping issues when the text exceeds the viewport / scrollable area.
 *
 * Implementation has been adapted from the grid layout technique described here:
 * https://css-tricks.com/the-cleanest-trick-for-autogrowing-textareas/
 *
 * @see IN-3390
 */
export const AutoSizeTextArea = forwardRef<TextAreaHandle, AutoSizeTextAreaProps>((props, ref) => {
  const { value, className, ...rest } = props;

  // Need to add an extra space to mimic the layout behavior of the textarea when
  // there is an empty final new line. Otherwise resizing will feel jumpy.
  const sizerContent = `${value ?? ''} `;

  return (
    <TextArea
      ref={ref}
      className={classNames('AutoSizeTextArea', className)}
      value={value}
      prefix={
        <div className="AutoSizeTextArea__Sizer k-input-inner" aria-hidden="true">
          {sizerContent}
        </div>
      }
      {...rest}
    />
  );
});
AutoSizeTextArea.displayName = 'AutoSizeTextArea';
