import { pick } from 'lodash';

/** Strictly typed wrapper for lodash/pick */
export const typedPick = <TObject extends object, TKey extends keyof TObject>(
  object: TObject,
  keys: Array<TKey> | ReadonlyArray<TKey>,
): Pick<TObject, TKey> => pick(object, keys);

type Partition<TObject, TKey extends keyof TObject> = [
  picked: Pick<TObject, TKey>,
  rest: Omit<TObject, TKey>,
];

export const typedPickWithRest = <TObject extends object, TKey extends keyof TObject>(
  object: TObject,
  keys: Array<TKey> | ReadonlyArray<TKey>,
): Partition<TObject, TKey> => {
  const picked = {} as Pick<TObject, TKey>;
  const rest = { ...object };

  keys.forEach((key) => {
    if (key in object) {
      picked[key] = object[key];
      delete rest[key];
    }
  });

  return [picked, rest];
};
