import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type TaskVisitTypesQueryVariables = Types.Exact<{ [key: string]: never }>;

export type TaskVisitTypesQuery = {
  __typename?: 'Query';
  taskVisitTypes: Array<{
    __typename?: 'TaskVisitType';
    id: string;
    title: string;
    need_location: boolean;
  }>;
};

export const TaskVisitTypesDocument = gql`
  query TaskVisitTypes {
    taskVisitTypes {
      id
      title
      need_location
    }
  }
`;

export function useTaskVisitTypesQuery(
  options?: Omit<Urql.UseQueryArgs<TaskVisitTypesQueryVariables>, 'query'>,
) {
  return Urql.useQuery<TaskVisitTypesQuery, TaskVisitTypesQueryVariables>({
    query: TaskVisitTypesDocument,
    ...options,
  });
}
