import { BaseButton } from '@module/layout';
import { ToolbarItem } from '@progress/kendo-react-buttons';
import { useTranslation } from 'react-i18next';

import { useFilterContext } from '../hooks/useFilterContext';

export const ResetFilterContextButton = () => {
  const { t } = useTranslation();
  const filterContext = useFilterContext();
  if (!filterContext.hasActiveFilters) return null;
  return (
    <ToolbarItem>
      <BaseButton
        label={t('tasks.pages.overview.toolbar.resetFilter')}
        onClick={filterContext.resetToInitialFilterState}
        iconClass="l-i-trash-2"
      />
    </ToolbarItem>
  );
};
