import { Button, ToolbarItem, ToolbarItemProps } from '@progress/kendo-react-buttons';
import { classNames } from '@progress/kendo-react-common';
import { InputChangeEvent } from '@progress/kendo-react-inputs';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { IconInput } from '../../forms';
import { useFilterContext } from '../hooks/useFilterContext';

const SearchToolbarItem = ({ children, style, className, ...rest }: ToolbarItemProps) => {
  return (
    <ToolbarItem
      {...rest}
      style={{ ...style, maxWidth: 400 }}
      className={classNames('k-flex-grow', className)}
    >
      {children}
    </ToolbarItem>
  );
};

export interface FilterSearchBarProps {
  className?: string | undefined;
}

export const FilterSearchBar = () => {
  const { t } = useTranslation();
  const filterContext = useFilterContext();

  const handleFilter = useCallback(
    (e: InputChangeEvent) => {
      filterContext.changeSearch(e.value);
    },
    [filterContext],
  );

  return (
    <div className="k-display-flex k-flex-row k-flex-grow k-gap-2">
      <IconInput
        iconClass="l-i-search"
        placeholder={t('tasks.pages.overview.toolbar.filter_placeholder')}
        className="k-input-lg k-flex-grow"
        value={filterContext.filterState.search}
        onChange={handleFilter}
      />
      {filterContext.expandedFields.length > 0 && (
        <Button
          iconClass="l-i-filter"
          className="k-icon-button"
          size="large"
          themeColor="base"
          onClick={filterContext.toggleExpanded}
        >
          {filterContext.filterState.expanded && (
            <div
              style={{
                position: 'absolute',
                top: 4,
                right: 4,
                width: '8px',
                height: '8px',
                borderRadius: '4px',
                backgroundColor: 'var(--primary)',
              }}
            />
          )}
        </Button>
      )}
    </div>
  );
};
export const ToolbarFilterSearchBar = () => (
  <SearchToolbarItem>
    <FilterSearchBar />
  </SearchToolbarItem>
);
