import { ReactNode } from 'react';

interface OverlayProps {
  children?: ReactNode;
}

export const Overlay = (props: OverlayProps) => {
  return (
    <>
      <div className="k-overlay" />
      {props.children}
    </>
  );
};
