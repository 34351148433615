import {
  Ability,
  AbilityBuilder,
  AbilityClass,
  ForcedSubject,
  subject as subjectHelper,
} from '@casl/ability';
import { useCallback, useMemo } from 'react';

import { Permission } from '../../../types/graphql.generated';
import { GenericCan, GenericCanSharedProps } from '../components/GenericCan';
import { CurrentUserAbilityFragment } from '../graphql';
import { detectSubjectTypeByTypename as detectSubjectType } from '../helpers';
import { useMeOrDefault } from '../hooks';

interface ClassificationTagSubject {
  me: CurrentUserAbilityFragment;
}

type ClassificationTagSubjectArg = Partial<ClassificationTagSubject>;

type Actions =
  | 'viewClassificationTags'
  | 'viewClassificationTag'
  | 'createClassificationTag'
  | 'updateClassificationTag'
  | 'deleteClassificationTag'
  | 'exportClassificationTags';
type Subjects = ClassificationTagSubject | 'ClassificationTagSubject';

type ClassificationTagAbility = Ability<[Actions, Subjects]>;
const taskTemplateAbility = Ability as AbilityClass<ClassificationTagAbility>;

export const useClassificationTagAbility = (): [
  ClassificationTagAbility,
  (
    sub?: ClassificationTagSubjectArg,
  ) => ClassificationTagSubject & ForcedSubject<'ClassificationTagSubject'>,
] => {
  const ability = useMemo(() => {
    const { can, build } = new AbilityBuilder(taskTemplateAbility);

    can('viewClassificationTags', 'ClassificationTagSubject', {
      'me.globalPermissions': { $in: [Permission.CLASSIFICATION_TAG_INDEX] },
    });
    can('viewClassificationTag', 'ClassificationTagSubject', {
      'me.globalPermissions': { $in: [Permission.CLASSIFICATION_TAG_READ] },
    });
    can('createClassificationTag', 'ClassificationTagSubject', {
      'me.globalPermissions': { $in: [Permission.CLASSIFICATION_TAG_CREATE] },
    });
    can('updateClassificationTag', 'ClassificationTagSubject', {
      'me.globalPermissions': { $in: [Permission.CLASSIFICATION_TAG_UPDATE] },
    });
    can('deleteClassificationTag', 'ClassificationTagSubject', {
      'me.globalPermissions': { $in: [Permission.CLASSIFICATION_TAG_DELETE] },
    });
    can('exportClassificationTags', 'ClassificationTagSubject', {
      'me.globalPermissions': { $in: [Permission.CLASSIFICATION_TAG_EXPORT] },
    });

    return build({ detectSubjectType });
  }, []);

  const me = useMeOrDefault();
  const subject = useCallback(
    (sub?: ClassificationTagSubjectArg) => {
      return subjectHelper('ClassificationTagSubject', { me, ...sub });
    },
    [me],
  );

  return [ability, subject];
};

type CanClassificationTagProps = GenericCanSharedProps<Actions>;

export const CanClassificationTag = (props: CanClassificationTagProps) => {
  const [classificationTagAbility, classificationTagSubject] = useClassificationTagAbility();

  return (
    <GenericCan<Actions, Subjects, ClassificationTagAbility>
      ability={classificationTagAbility}
      subject={classificationTagSubject()}
      {...props}
    />
  );
};
