import { useDetectClickOutside, useMarkAllNotificationsAsReadMutation } from '@module/common';
import { NotificationsDocument, NotificationsQuery } from '@module/common/graphql';
import { Popover } from '@module/shared/components';
import { NotificationType, useNotifications } from '@module/shared/notifications';
import { Button } from '@progress/kendo-react-buttons';
import { Badge } from '@progress/kendo-react-indicators';
import { useEffect, useId, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { OperationResult, useClient } from 'urql';
import useInterval from 'use-interval';

import { NotificationItem } from './NotificationItem';

export const PageHeaderNotifications = () => {
  const { t } = useTranslation();
  const buttonId = useId();
  const { showNotification } = useNotifications();
  const [notificationPopupOpen, setNotificationPopupOpen] = useState(false);
  const notificationPopupAnchor = useRef<HTMLDivElement | null>(null);
  const client = useClient();
  const notificationPopupRef = useDetectClickOutside<HTMLDivElement>(
    () => setNotificationPopupOpen(false),
    undefined,
    [`#${CSS.escape(buttonId)}`],
  );

  const [notificationData, setNotificationData] = useState<NotificationsQuery['notifications']>();

  const fetchNotifications = () => {
    client
      .query(NotificationsDocument, {}, { requestPolicy: 'network-only' })
      .toPromise()
      .then((queryData: OperationResult<NotificationsQuery>) => {
        if (queryData && queryData.data?.notifications) {
          setNotificationData(queryData.data.notifications);
        } else {
          setNotificationData([]);
        }
      });
  };

  useEffect(() => {
    // fetch notifications on mount
    fetchNotifications();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [, markAllNotificationsAsRead] = useMarkAllNotificationsAsReadMutation();

  const notifications = useMemo(() => {
    if (notificationData) {
      return {
        data: notificationData,
        unread: notificationData?.filter((item) => !item.read).length || 0,
        count: notificationData?.length || 0,
      };
    }
    return null;
  }, [notificationData]);

  const handleMarkAllAsRead = async () => {
    const markNotificationAsReadResponse = await markAllNotificationsAsRead(
      {},
      {
        additionalTypenames: ['Notification'],
      },
    );
    if (markNotificationAsReadResponse.error) {
      showNotification(
        t('common.components.layout.pageHeader.markAllAsRead.error'),
        NotificationType.Error,
      );
      return;
    }
    showNotification(
      t('common.components.layout.pageHeader.markAllAsRead.success'),
      NotificationType.Success,
    );
    fetchNotifications();
  };

  useInterval(() => {
    fetchNotifications();
  }, 60000);

  return (
    <div ref={notificationPopupAnchor}>
      <Button
        id={buttonId}
        onClick={() => {
          setNotificationPopupOpen(!notificationPopupOpen);
        }}
        size="large"
        themeColor="primary"
        fillMode="flat"
        aria-label={t('common.labels.notifications')}
      >
        <span className="l-i-bell">
          {notifications && notifications.unread > 0 && (
            <Badge
              align={{ vertical: 'bottom', horizontal: 'end' }}
              position="inside"
              themeColor="error"
              size="small"
              rounded="full"
            />
          )}
        </span>
      </Button>
      <Popover
        anchor={notificationPopupAnchor.current}
        popoverClass="notification-popup"
        collision={{
          vertical: 'flip',
          horizontal: 'fit',
        }}
        callout={false}
        show={notificationPopupOpen}
      >
        <div ref={notificationPopupRef}>
          <div className="notification-popup-header k-display-flex k-justify-content-between k-px-3 k-py-1 k-border-left-0 k-border-top-0 k-border-right-0 k-border-bottom-1 k-border-solid k-border-light">
            <span className="k-display-flex k-align-items-center">
              {t('common.components.layout.pageHeader.unread')}
              <span className="unread-badge k-display-flex k-align-items-center k-justify-content-center k-mx-1">
                {notifications?.unread}
              </span>
            </span>
            <Button
              onClick={handleMarkAllAsRead}
              disabled={notifications?.unread === 0}
              size="large"
              fillMode="flat"
            >
              {t('common.components.layout.pageHeader.markAllAsRead.title')}
            </Button>
          </div>
          <div className="notification-popup-items">
            {notifications?.data?.map((notification, index) => (
              <NotificationItem
                key={notification.id}
                notification={notification}
                lastItem={index === notifications.count - 1}
              />
            ))}
          </div>
        </div>
      </Popover>
    </div>
  );
};
