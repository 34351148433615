import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type UpdateTaskClassificationTagsMutationVariables = Types.Exact<{
  taskIds: Array<Types.Scalars['ID']> | Types.Scalars['ID'];
  classificationTagIds: Array<Types.Scalars['ID']> | Types.Scalars['ID'];
}>;

export type UpdateTaskClassificationTagsMutation = {
  __typename?: 'Mutation';
  updateTaskClassificationTags: {
    __typename?: 'TasksBatchResponseMessage';
    message: string;
    success: boolean;
    tasks: Array<{ __typename?: 'Task'; id: string }>;
  };
};

export const UpdateTaskClassificationTagsDocument = gql`
  mutation UpdateTaskClassificationTags($taskIds: [ID!]!, $classificationTagIds: [ID!]!) {
    updateTaskClassificationTags(
      task_ids: $taskIds
      classification_tag_ids: $classificationTagIds
    ) {
      message
      success
      tasks {
        id
      }
    }
  }
`;

export function useUpdateTaskClassificationTagsMutation() {
  return Urql.useMutation<
    UpdateTaskClassificationTagsMutation,
    UpdateTaskClassificationTagsMutationVariables
  >(UpdateTaskClassificationTagsDocument);
}
