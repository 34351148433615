import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ClientTaxQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;

export type ClientTaxQuery = {
  __typename?: 'Query';
  client?: {
    __typename?: 'Client';
    client_tax?: {
      __typename?: 'ClientTax';
      vat_number?: string | null;
      accounts_receivable_number?: string | null;
      eligible_tax_deduction: boolean;
    } | null;
  } | null;
};

export const ClientTaxDocument = gql`
  query ClientTax($id: ID!) {
    client(id: $id) {
      client_tax {
        vat_number
        accounts_receivable_number
        eligible_tax_deduction
      }
    }
  }
`;

export function useClientTaxQuery(
  options: Omit<Urql.UseQueryArgs<ClientTaxQueryVariables>, 'query'>,
) {
  return Urql.useQuery<ClientTaxQuery, ClientTaxQueryVariables>({
    query: ClientTaxDocument,
    ...options,
  });
}
