import { useFormBlocker, useFormBlockerConfirmDialog } from '@module/common/components';
import {
  CancelButton,
  Dialog,
  DialogContent,
  DialogHeader,
  DialogHeaderBar,
  DialogHeaderIcon,
} from '@module/layout';
import { DialogProps, useDialogs } from '@module/shared/dialogs';
import { Form, FormContextProvider } from '@module/shared/forms';
import { NotificationType, useNotifications } from '@module/shared/notifications';
import { Button } from '@progress/kendo-react-buttons';
import { DialogActionsBar } from '@progress/kendo-react-dialogs';
import { FormElement } from '@progress/kendo-react-form';
import { isEmpty } from 'lodash';
import { useId, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { InterfaceMessageTemplateFormFields } from '../components/InterfaceMessageTemplateFormFields';
import {
  InterfaceMessageTemplateFragment,
  useUpdateInterfaceMessageTemplateMutation,
} from '../graphql';
import { InterfaceMessageTemplateFormValues, useInterfaceMessageTemplateSchema } from '../hooks';

interface UpdateInterfaceMessageTemplateDialogProps {
  template: InterfaceMessageTemplateFragment;
}

export const UpdateInterfaceMessageTemplateDialog = (
  props: DialogProps & UpdateInterfaceMessageTemplateDialogProps,
) => {
  const { t } = useTranslation();
  const { hideDialog } = useDialogs();
  const { showNotification } = useNotifications();
  const { dialogId, template } = props;

  const handleHideDialog = () => hideDialog(dialogId);

  const formId = useId();

  const [{ fetching }, updateTemplate] = useUpdateInterfaceMessageTemplateMutation();

  const handleSubmit = async (values: unknown) => {
    const input = values as InterfaceMessageTemplateFormValues;

    const mutationResult = await updateTemplate(
      { id: template.id, input },
      { additionalTypenames: ['InterfaceMessageTemplate'] },
    );
    if (mutationResult.error) {
      showNotification(t('common.errors.generic'), NotificationType.Error);
    } else {
      showNotification(
        t('interfaceMessageTemplates.notifications.templateUpdated'),
        NotificationType.Success,
      );
      handleHideDialog();
    }
  };

  const { schema, validator } = useInterfaceMessageTemplateSchema();
  const { ref, Blocker } = useFormBlocker();

  const submitButtonRef = useRef<Button | null>(null);

  const blockerConfirm = useFormBlockerConfirmDialog(ref, {
    discard: handleHideDialog,
  });

  const handleBlockerCancel = () => {
    if (!isEmpty(ref.current?.modified)) {
      blockerConfirm();
    } else {
      handleHideDialog();
    }
  };

  const initialValues = {
    title: template.title,
    description: template.description,
    category_id: template.category?.id ?? undefined,
    subject: template.subject ?? '',
    body: template.body ?? '',
    message_type: template.message_type,
  } satisfies InterfaceMessageTemplateFormValues;

  return (
    <Dialog
      width="fullscreen"
      height="fullscreen"
      onClose={handleBlockerCancel}
      preventCloseOnEscape
    >
      <DialogHeaderBar>
        <DialogHeaderIcon iconClass="l-i-plug u-text-2xl" color="success" />
        <DialogHeader title={t('interfaceMessageTemplates.dialogs.update.title')} />
      </DialogHeaderBar>
      <DialogContent>
        <Form
          ref={ref}
          id={formId}
          schema={schema}
          validator={validator}
          onSubmit={handleSubmit}
          initialValues={initialValues}
          render={(formRenderProps) => (
            <FormContextProvider value={formRenderProps}>
              <FormElement>
                <InterfaceMessageTemplateFormFields />
                <Blocker />
              </FormElement>
            </FormContextProvider>
          )}
        />
      </DialogContent>

      <DialogActionsBar layout="end">
        <CancelButton onClick={handleBlockerCancel} />
        <Button
          ref={submitButtonRef}
          type="submit"
          form={formId}
          iconClass="l-i-save"
          size="large"
          themeColor="primary"
          disabled={fetching}
        >
          {t('common.labels.save')}
        </Button>
      </DialogActionsBar>
    </Dialog>
  );
};
