import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type InsurancesTaskStatisticExportConfigFragment = {
  __typename?: 'Client';
  id: string;
  name: string;
  client_additional_insurance?: {
    __typename?: 'ClientAdditionalInsurance';
    tasks_statistic_export_columns?: Array<string> | null;
  } | null;
};

export type InsurancesTaskStatisticExportConfigsQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type InsurancesTaskStatisticExportConfigsQuery = {
  __typename?: 'Query';
  insurances: {
    __typename?: 'InsuranceList';
    data: Array<{
      __typename?: 'Client';
      id: string;
      name: string;
      client_additional_insurance?: {
        __typename?: 'ClientAdditionalInsurance';
        tasks_statistic_export_columns?: Array<string> | null;
      } | null;
    }>;
  };
};

export const InsurancesTaskStatisticExportConfigFragmentDoc = gql`
  fragment InsurancesTaskStatisticExportConfig on Client {
    id
    name
    client_additional_insurance {
      tasks_statistic_export_columns
    }
  }
`;
export const InsurancesTaskStatisticExportConfigsDocument = gql`
  query InsurancesTaskStatisticExportConfigs {
    insurances {
      data {
        ...InsurancesTaskStatisticExportConfig
      }
    }
  }
  ${InsurancesTaskStatisticExportConfigFragmentDoc}
`;

export function useInsurancesTaskStatisticExportConfigsQuery(
  options?: Omit<Urql.UseQueryArgs<InsurancesTaskStatisticExportConfigsQueryVariables>, 'query'>,
) {
  return Urql.useQuery<
    InsurancesTaskStatisticExportConfigsQuery,
    InsurancesTaskStatisticExportConfigsQueryVariables
  >({ query: InsurancesTaskStatisticExportConfigsDocument, ...options });
}
