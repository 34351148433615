import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CreateTaskRoomCategoryMutationVariables = Types.Exact<{
  taskId: Types.Scalars['ID'];
  input: Types.TaskRoomCategoryInput;
}>;

export type CreateTaskRoomCategoryMutation = {
  __typename?: 'Mutation';
  createTaskRoomCategory: {
    __typename?: 'TaskRoomCategory';
    id: string;
    task_id: string;
    title: string;
    uuid: string;
  };
};

export const CreateTaskRoomCategoryDocument = gql`
  mutation CreateTaskRoomCategory($taskId: ID!, $input: TaskRoomCategoryInput!) {
    createTaskRoomCategory(task_id: $taskId, input: $input) {
      id
      task_id
      title
      uuid
    }
  }
`;

export function useCreateTaskRoomCategoryMutation() {
  return Urql.useMutation<CreateTaskRoomCategoryMutation, CreateTaskRoomCategoryMutationVariables>(
    CreateTaskRoomCategoryDocument,
  );
}
