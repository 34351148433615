import { useClientAbility } from '@module/casl';
import {
  CanInterfaceMessageTemplate,
  useInterfaceMessageTemplateAbility,
} from '@module/casl/abilities/InterfaceMessageTemplateAbility';
import { AssignedInsurancesBadges } from '@module/clients/components/AssignedInsurancesBadges';
import { useClientsForTask } from '@module/clients/hooks/useClientsForTasks';
import {
  GRID_ROW_SPACING_X,
  InterfaceMessageTemplatesFilterAndSortColumns,
  Loader,
  useConfirmDialogs,
  useGridPagerSettings,
  useGridStateWithPaginationReset,
  useTdElementProps,
} from '@module/common';
import { RefreshButton } from '@module/layout';
import { useDialogs } from '@module/shared/dialogs';
import { FilterContextProvider, ToolbarFilterSearchBar } from '@module/shared/filters';
import { useGridFilter, useGridPagination, useGridSort } from '@module/shared/graphql';
import { isTruthy } from '@module/shared/helpers';
import { NotificationType, useNotifications } from '@module/shared/notifications';
import { Button, DropDownButton, ToolbarItem, ToolbarSpacer } from '@progress/kendo-react-buttons';
import { Grid, GridCellProps, GridColumn, GridToolbar } from '@progress/kendo-react-grid';
import { useTranslation } from 'react-i18next';

import { AssignInterfaceMessageTemplatesDialog } from '../dialogs/AssignInterfaceMessageTemplateToClientsDialog';
import { CreateInterfaceMessageTemplateDialog } from '../dialogs/CreateInterfaceMessageTemplateDialog';
import { UpdateInterfaceMessageTemplateDialog } from '../dialogs/UpdateInterfaceMessageTemplateDialog';
import {
  InterfaceMessageTemplateFragment,
  useDeleteInterfaceMessageTemplateMutation,
  useInterfaceMessageTemplatesQuery,
} from '../graphql';
import { useInterfaceMessageTemplatesFilters } from '../hooks/useInterfaceMessageTemplatesFilters';

interface InterfaceMessageTemplateCellProps extends GridCellProps {
  dataItem: InterfaceMessageTemplateFragment;
}

const AssignedInsurancesCell = (props: InterfaceMessageTemplateCellProps) => {
  const assignedInsurances = props.dataItem.assignments;
  const tdElementProps = useTdElementProps(props);

  const { clientsForTask } = useClientsForTask();

  return (
    <td {...tdElementProps}>
      <AssignedInsurancesBadges
        numVisibleBadges={1}
        allInsurances={clientsForTask}
        assignedInsurances={assignedInsurances}
      />
    </td>
  );
};

const CommandCell = (props: InterfaceMessageTemplateCellProps) => {
  const { dataItem } = props;
  const { t } = useTranslation();
  const { showDialog } = useDialogs();
  const { showNotification } = useNotifications();
  const tdElementProps = useTdElementProps(props);

  const confirm = useConfirmDialogs();

  const [, deleteTemplate] = useDeleteInterfaceMessageTemplateMutation();

  const handleDeleteTemplate = () => {
    confirm.delete({
      title: t('interfaceMessageTemplates.dialogs.delete.title'),
      description: t('interfaceMessageTemplates.dialogs.delete.description'),
      delete: async () => {
        const mutationResult = await deleteTemplate(
          { id: dataItem.id },
          { additionalTypenames: ['InterfaceMessageTemplate'] },
        );
        if (mutationResult.error) {
          showNotification(t('common.errors.generic'), NotificationType.Error);
        } else {
          showNotification(
            t('interfaceMessageTemplates.notifications.templateDeleted'),
            NotificationType.Success,
          );
        }
      },
    });
  };

  const [interfaceMessageTemplateAbility, interfaceMessageTemplateSubject] =
    useInterfaceMessageTemplateAbility();

  const [clientAbility, clientSubject] = useClientAbility();

  const items = [
    interfaceMessageTemplateAbility.can(
      'updateInterfaceMessageTemplate',
      interfaceMessageTemplateSubject(),
    ) && {
      text: t('common.labels.edit'),
      iconClass: 'l-i-pencil',
      action: () => showDialog({ template: dataItem }, UpdateInterfaceMessageTemplateDialog),
    },
    interfaceMessageTemplateAbility.can(
      'deleteInterfaceMessageTemplate',
      interfaceMessageTemplateSubject(),
    ) && {
      text: t('common.labels.delete'),
      iconClass: 'l-i-trash-2',
      action: handleDeleteTemplate,
    },
    clientAbility.can('assignInterfaceMessageTemplates', clientSubject()) && {
      text: t('interfaceMessageTemplates.labels.assign'),
      iconClass: 'l-i-arrow-left-right',
      action: () => showDialog({ template: dataItem }, AssignInterfaceMessageTemplatesDialog),
    },
  ].filter(isTruthy);

  return (
    <td {...tdElementProps}>
      <DropDownButton
        iconClass="l-i-more-vertical"
        items={items}
        popupSettings={{
          anchorAlign: { horizontal: 'right', vertical: 'bottom' },
          popupAlign: { horizontal: 'right', vertical: 'top' },
        }}
        disabled={!items.length}
        onItemClick={(event: { item: (typeof items)[number] }) => event.item.action()}
        ariaLabel={t('common.labels.more')}
      />
    </td>
  );
};

const mapColumns = {
  title: InterfaceMessageTemplatesFilterAndSortColumns.TITLE,
};

export const InterfaceMessageTemplatesGrid = () => {
  const { t } = useTranslation();
  const { showDialog } = useDialogs();

  const filterContext = useInterfaceMessageTemplatesFilters();
  const pagerSettings = useGridPagerSettings();
  const [gridState, setGridState] = useGridStateWithPaginationReset(
    filterContext.filterState.filter,
  );
  const gridFilter = useGridFilter(filterContext.filterState.filter, mapColumns);
  const gridSort = useGridSort(gridState.sort, mapColumns);
  const gridPagination = useGridPagination(gridState);

  const [{ data, fetching }, refetch] = useInterfaceMessageTemplatesQuery({
    variables: {
      filter: gridFilter,
      sort: { columns: gridSort },
      pagination: gridPagination,
    },
  });

  const templates = data?.interfaceMessageTemplates.data ?? [];
  const total = data?.interfaceMessageTemplates.paginatorInfo.total ?? 0;

  return (
    <FilterContextProvider filterContext={filterContext}>
      {fetching && <Loader />}

      <Grid
        {...gridState}
        className="!k-border-0"
        pageable={pagerSettings}
        sortable={true}
        onDataStateChange={({ dataState }) => setGridState(dataState)}
        total={total}
        data={templates}
      >
        <GridToolbar>
          <ToolbarFilterSearchBar />
          <ToolbarSpacer />
          <ToolbarItem>
            <RefreshButton onClick={() => refetch({ requestPolicy: 'network-only' })} onlyIcon />
          </ToolbarItem>
          <CanInterfaceMessageTemplate action="createInterfaceMessageTemplate">
            <ToolbarItem>
              <Button
                iconClass="l-i-plus"
                size="large"
                themeColor="primary"
                onClick={() => showDialog({}, CreateInterfaceMessageTemplateDialog)}
              >
                {t('interfaceMessageTemplates.labels.newTemplate')}
              </Button>
            </ToolbarItem>
          </CanInterfaceMessageTemplate>
        </GridToolbar>
        <GridColumn field="title" title={t('interfaceMessageTemplates.labels.title')} />
        <GridColumn
          field="description"
          title={t('interfaceMessageTemplates.labels.description')}
          sortable={false}
        />
        <GridColumn
          field="category.title"
          title={t('interfaceMessageTemplates.labels.category')}
          sortable={false}
        />
        <GridColumn
          field="message_type"
          title={t('interfaceMessageTemplates.labels.messageType')}
          sortable={false}
        />
        <GridColumn
          field="assignments"
          title={t('interfaceMessageTemplates.labels.assignedInsurances')}
          sortable={false}
          cell={AssignedInsurancesCell}
        />
        <GridColumn
          title={t('common.labels.actions')}
          width={64 + GRID_ROW_SPACING_X}
          sortable={false}
          cell={CommandCell}
        />
      </Grid>
    </FilterContextProvider>
  );
};
