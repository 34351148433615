import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type TaskDeclineReasonsQueryVariables = Types.Exact<{ [key: string]: never }>;

export type TaskDeclineReasonsQuery = {
  __typename?: 'Query';
  taskDeclineReasons: Array<{
    __typename?: 'TaskDeclineReason';
    id: string;
    title?: string | null;
    required_note?: boolean | null;
  }>;
};

export const TaskDeclineReasonsDocument = gql`
  query TaskDeclineReasons {
    taskDeclineReasons {
      id
      title
      required_note
    }
  }
`;

export function useTaskDeclineReasonsQuery(
  options?: Omit<Urql.UseQueryArgs<TaskDeclineReasonsQueryVariables>, 'query'>,
) {
  return Urql.useQuery<TaskDeclineReasonsQuery, TaskDeclineReasonsQueryVariables>({
    query: TaskDeclineReasonsDocument,
    ...options,
  });
}
