import {
  PasswordFieldExtension as PasswordFieldExtensionBase,
  PasswordFieldExtensionProps,
} from '@module/shared/forms';

import { PasswordRequirements } from './PasswordRequirements';
import { PasswordStrength } from './PasswordStrength';

export const PasswordFieldExtension = (props: PasswordFieldExtensionProps) => {
  return (
    <PasswordFieldExtensionBase {...props}>
      <PasswordStrength value={props.value} />
      <PasswordRequirements value={props.value} />
    </PasswordFieldExtensionBase>
  );
};
