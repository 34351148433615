import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type AcceptTaskMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;

export type AcceptTaskMutation = {
  __typename?: 'Mutation';
  acceptTask?: {
    __typename?: 'ResponseMessage';
    success?: boolean | null;
    message?: string | null;
  } | null;
};

export const AcceptTaskDocument = gql`
  mutation AcceptTask($id: ID!) {
    acceptTask(id: $id) {
      success
      message
    }
  }
`;

export function useAcceptTaskMutation() {
  return Urql.useMutation<AcceptTaskMutation, AcceptTaskMutationVariables>(AcceptTaskDocument);
}
