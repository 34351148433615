import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import { ClassificationTagFragmentDoc } from './classificationTagFragment.generated';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ClassificationTagsAllQueryVariables = Types.Exact<{ [key: string]: never }>;

export type ClassificationTagsAllQuery = {
  __typename?: 'Query';
  classificationTags: {
    __typename?: 'ClassificationTagsPaginator';
    data: Array<{
      __typename?: 'ClassificationTag';
      id: string;
      title: string;
      color: string;
      alias: string;
      api_only: boolean;
    }>;
  };
};

export const ClassificationTagsAllDocument = gql`
  query ClassificationTagsAll {
    classificationTags(
      filter: { AND: [] }
      sort: { columns: [] }
      pagination: { skip: 0, take: 10000 }
    ) {
      data {
        ...ClassificationTag
      }
    }
  }
  ${ClassificationTagFragmentDoc}
`;

export function useClassificationTagsAllQuery(
  options?: Omit<Urql.UseQueryArgs<ClassificationTagsAllQueryVariables>, 'query'>,
) {
  return Urql.useQuery<ClassificationTagsAllQuery, ClassificationTagsAllQueryVariables>({
    query: ClassificationTagsAllDocument,
    ...options,
  });
}
