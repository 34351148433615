import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import { ExportResponseFragmentDoc } from '../../../common/graphql/generated/exportResponseFragment.generated';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ExportClassificationTagsMutationVariables = Types.Exact<{
  filter: Types.ClassificationTagsFilter;
  sort: Types.ClassificationTagsSort;
  config: Types.ExportConfigInput;
}>;

export type ExportClassificationTagsMutation = {
  __typename?: 'Mutation';
  exportClassificationTags: {
    __typename?: 'ExportResponse';
    success: boolean;
    message: string;
    download?: {
      __typename?: 'Download';
      id: string;
      state_id: Types.DownloadStateId;
      filename: string;
      file_ids: Array<string>;
      url_download: string;
    } | null;
  };
};

export const ExportClassificationTagsDocument = gql`
  mutation ExportClassificationTags(
    $filter: ClassificationTagsFilter!
    $sort: ClassificationTagsSort!
    $config: ExportConfigInput!
  ) {
    exportClassificationTags(filter: $filter, sort: $sort, config: $config) {
      ...ExportResponse
    }
  }
  ${ExportResponseFragmentDoc}
`;

export function useExportClassificationTagsMutation() {
  return Urql.useMutation<
    ExportClassificationTagsMutation,
    ExportClassificationTagsMutationVariables
  >(ExportClassificationTagsDocument);
}
