export default {
  emailTransport: {
    title: 'E-Mail Transport',
    description: 'You can configure different e-mail transports here.',
    addTransport: 'Add E-Mail Transport',
    type: 'Type',
    editTransport: 'Edit transport',
    deleteTransport: 'Delete transport',
    testTransport: 'Send Test E-Mail',
    labels: {
      title: 'Title',
      type: 'Type',
      active: 'Active',
    },
    transportTypes: {
      SMTP: 'SMTP',
    },
    dialogs: {
      create: {
        title: 'Add E-Mail Transport',
        description: 'Here you can create a new e-mail transport.',
        success: 'E-Mail Transport created',
      },
      update: {
        title: 'Update E-Mail Transport',
        description: 'Here you can update a e-mail transport config.',
        success: 'E-Mail Transport updated',
      },
      delete: {
        title: 'Delete E-Mail Transport?',
        description: 'Do you really want to delete this e-mail transport?',
      },
    },
    smtp: {
      smtpHost: 'Host',
      smtpPort: 'Port',
      smtpUsername: 'Username',
      smtpPassword: 'Password',
      smtpEncryption: 'Encryption',
      encryptionOptions: {
        SSL: 'SSL',
        TLS: 'TLS',
      },
      passwordUpdatePlaceholder: 'Leave blank to not change the password.',
    },
    notifications: {
      activateSuccess: 'E-Mail transport activated',
      deleteSuccess: 'E-Mail transport deleted',
    },
  },
  buttonGroup: {
    none: 'Off',
    inapp: 'In-app',
    email: 'Email',
  },
  loginPage: {
    title: 'Login Page',
    description: 'Here you can define details about the login page.',
    welcometext: 'Welcome text',
    welcometextDesc: 'This is the text that will be displayed under the logo.',
    imprint: 'Imprint',
    conditions: 'Terms and Conditions',
    privacy: 'Privacy',
  },
  loginLogo: {
    title: 'Login Logo',
    description: 'Upload the logo for the login page here',
  },
  loginBackground: {
    title: 'Login Background',
    description: 'Choose a suitable background image for the right-hand side of the entry page.',
  },
  reportBackground: {
    title: 'Report Background',
    description: 'Select a suitable background image for the report page.',
  },
  uploadTab: {
    title: 'Logos & Images',
    description: 'Upload suitable images for the app here',
    confirmDelete: {
      title: 'Delete file?',
      description: 'Do you really want to delete this file?',
    },
  },
  reportLogo: {
    title: 'Report Logo',
    description: 'Upload the logo for the report page here',
  },
  menuLogo: {
    title: 'Menu Logo',
    description: 'Choose the appropriate menu icon',
  },
  colorTab: {
    title: 'Colours',
    description: 'Choose the colours for your app here',
  },
  primaryColor: {
    title: 'Primary colour',
    description: 'Choose your primary colour',
  },
  generalTab: {
    title: 'General settings',
    description: 'Here you can make general settings for the app',
  },
  activatePushNotifications: {
    title: 'Enable Push Notifications',
    desciption: 'Here you can enable the sending of messages to mobile devices',
    labels: {
      activate: 'Enable Push Notifications',
    },
    description: 'Here you can enable sending messages to mobile devices.',
  },
  configurePushNotifications: {
    title: 'Push Notification Configuration',
    desciption:
      'Copy your APNS configuration in here so that the system can communicate with mobile devices (iOS and Android)',
    labels: {
      configure: 'Push Notification Configuration',
    },
    description:
      'Copy your APNS configuration in here so that the system can communicate with the mobile devices (iOS and Android).',
  },
  appTitle: {
    title: 'Platform title',
    description: 'Here you can change the title of the platform',
    labels: {
      appTitle: 'Title',
    },
  },
  currencies: {
    title: 'Calculation',
    description: 'Set the settings for the calculation here',
    labels: {
      currencies: 'Currency',
      tax: 'Tax in %',
    },
  },
  defaultCountry: {
    title: 'Country',
    description: 'Set the default country.',
  },
  calendar: {
    title: 'Calendar',
    description: 'Define default settings for the calendars here',
    labels: {
      show_weekends: 'Show Weekends',
      calendar_driving_times: 'Use driving times in calendar',
      driving_times_off: 'Off',
      driving_times_simple: 'Simple',
      driving_times_extended: 'Extended',
    },
  },
  theme: {
    title: 'Color theme',
    description: 'Set the color theme.',
  },
  webservice: {
    title: 'REST web service',
    description: 'Here you can enable or disable the REST web service for the portal.',
    labels: {
      webservice_active: 'REST web service',
    },
  },
  mailTab: {
    title: 'Email Settings',
    description:
      'All settings for sending e-mails can be configured here. A functioning e-mail server is required for sending e-mails.',
  },
  moduleTab: {
    title: 'Modules',
    description: 'Here you can define which modules are active',
  },
  modules: {
    title: 'Email Settings',
    description: 'Define visibility: ',
    labels: {
      images: 'Images',
      documents: 'Documents',
      calculations: 'Calculation',
      roomPlan: 'Room plan',
      mails: 'Emails',
      customerPortal: 'Customer-portal',
      statistics: 'Statistics',
      invoices: 'Invoices',
      logs: 'Reports',
      subTasks: 'Tasks',
      additionalForms: 'Subforms',
      qualifications: 'Qualifications',
    },
  },
  calculationModule: {
    title: 'Calculation Settings',
    description:
      'Here you can change the visibility of individual columns in the calculation. The changes will affect all future tasks with calculations.',
    labels: {
      hideStatusColumn: 'Hide "status" column',
      hideAcceptedColumn: 'Hide "accepted" column',
      hidePayoutStateColumn: 'Payout "state" column',
      calculationDefaultNet: 'Net calculation as standard',
    },
  },
  mail: {
    title: 'SMTP server',
    description: 'Enter the access data and the address of your SMTP server here',
    labels: {
      port: 'Port',
      host: 'Host',
      username: 'Username',
      password: 'Password',
      encryption: 'Encryption',
      fromAddress: 'Sender address',
      fromName: 'sender name',
      defaultFooter: 'Default-Footer',
      prefix: 'Prefix',
      active: 'Enable email sending',
      mailSendFailureNotice: 'Send error notification?',
    },
    updateSMTPSettingsDialog: {
      title: 'SMTP settings',
    },
    confirmReset: {
      title: 'Reset SMTP settings',
      description: 'Do you really want to reset the SMTP settings?',
    },
  },
  notifications: {
    title: 'Notifications',
    description: 'Choose how Platform users should be notified',
  },
  profileUserSettings: {
    title: 'Nutzerdaten',
  },
  profileImage: {
    title: 'Profile image',
    description: 'Upload your profile image here',
    confirmDelete: {
      title: 'Delete profile image?',
      description: 'Do you really want to delete your profile image?',
    },
  },
  clientNotifications: {
    title: 'Notifications',
  },
  changePasswordSelf: {
    title: 'Change password',
    description: 'Change your password here',
    success: 'Password changes successfully',
  },
  createApiToken: {
    title: 'API Token',
    description: 'Create a new API token here',
    create: 'Generate API Token',
    copied: 'API token copied!',
    labels: {
      createdAtDate: 'Created on',
      expiresAtDate: 'Expires on',
      revoked: 'Locked',
      uuid: 'Uuid',
    },
    confirm: {
      title: 'Delete Api Token',
      description: 'Permanently delete the Api token?',
    },
  },
  mapsTab: {
    title: 'Map settings',
    description: 'Here you can adjust settings for the maps in the app.',
  },
  mapBounds: {
    title: 'Map section',
    description: 'Set your preferred map section.',
  },
  tasks: {
    title: 'Tasks',
    description: 'Configure the task module',
    generalSection: {
      title: 'General settings',
      description: 'Here you can make general settings for the task module',
      labels: {
        tasks_leader_visible_all:
          'Team leaders can see all tasks, and not just those assigned to them',
        auto_accept_task:
          'Automatic order acceptance - the order is automatically set to "accepted" by the payers at 22:00.',
        statistic_required_for_controlling:
          'The statistics must be filled in (mandatory fields) before the order can be transferred to Controlling.',
        statistic_required_for_completion:
          'The statistics must be filled in (mandatory fields) before the order can be marked as connected.',
        activate_task_default_off: 'Deactivate task default',
        leader_can_assign: 'Leader can assign tasks',
        permissions_delete_files_admin_only: 'Only the admins are allowed to delete files.',
        adjuster_can_create_tasks: 'Regulator can create tasks',
        hide_drive_log: 'Hide travel time input in tasks',
        msg_convert_single_file: 'Outlook MSG convert file attachments to one file',
        everybody_can_see_finished_tasks: 'Everyone can see completed orders',
        create_task_auto_fill_client:
          'When creating an order, transfer the contact person/insurance holder from the basic data block',
        create_task_auto_fill_client_address:
          'When creating an order, transfer the inspection location from the basic data block',
        allow_adjuster_to_send_emails: 'Regulator may send e-mails',
        adjuster_can_duplicate_own_tasks: 'Regulator can duplicate own orders',
        adjuster_can_change_task_location: 'Regulator can adjust the inspection location',
        adjuster_can_set_rework: 'Payer can place the order in subsequent entry',
        adjuster_can_send_interface: 'Regulator can send files via interface',
        disable_task_type_on_desk: 'Deactivate order type On-Desk',
        disable_task_type_video: 'Deactivate order type Video',
        log_mails_in_task_log: 'Log mails in the file',
        load_predefined_email: 'Load insurance automatically for messages',
      },
    },
    reportSection: {
      title: 'Global report settings',
      description: 'Here you can make general settings for the report module',
      labels: {
        show_summary_service_provider_block: 'Show service provider block in summary',
        global_phone: 'Global contact phone number',
        global_mail: 'Global contact e-mail address',
        max_export_pages: 'Maximum number of Pdf pages exported in the report.',
        show_adjuster_email_service_provider_block: 'Show payer e-mail in service provider block',
      },
    },
    logSection: {
      title: 'Visibility and privacy',
      description: 'Here you can make global settings for visibility',
      labels: {
        hide_log_times: 'Hide times in logs',
      },
    },
    tasksSection: {
      title: 'Tasks Settings',
      description: 'Here you can find general task settings',
      labels: {
        tasks_delete_tags_on_complete: 'Automatically delete order classification on completion.',
        tasks_fields_identifier_active: 'Show damage number in the form',
        tasks_fields_external_number_active: 'Display external system number in the form',
        tasks_fields_parallel_number_active: 'Display parallel / follow-up order in the form',
        tasks_fields_urgent_active: 'Show rush order in the form',
        tasks_fields_client_contact_id_active: 'Display clerk in the form',
        tasks_filter_cancelled: 'Hide canceled orders',
        tasks_hide_duplicate: 'Deactivate duplicate order function',
        tasks_allow_send_sms: 'Aktiviere SMS-Versand in Aufträgen',
      },
    },
  },
  dialogs: {
    profileSettings: {
      title: 'Profile settings',
      description: 'Change your profile settings here',
    },
  },
  subnavigation: {
    general: 'General',
    login: 'Login Page',
    uploads: 'Logos and images',
    email: 'email',
    notification: 'Notification',
    maps: 'Maps',
    push: 'Push Notifications',
    tasks: 'Tasks',
    modules: 'Modules',
    assignments: 'Allocations',
    invoices: 'Invoices',
    emailTransport: 'E-mail transport',
    appMessages: 'App News',
    apiTokens: 'API Tokens',
    appModules: 'App modules',
  },
  pages: {
    platform: {
      title: 'App settings',
      description: 'Here you will find an overview of the settings',
    },
    classificationTags: {
      title: 'Order Classification',
      description:
        'Here you can create your own categories to assign categories to your tasks. You can also filter according to these criteria.',
      dialogs: {
        create: {
          title: 'Create classification',
          note: 'Choose a name and a colour',
          name: 'Name',
          colour: 'colour',
        },
        delete: {
          title: 'Delete classification',
          note: 'Do you really want to delete the classification?',
        },
        exports: {
          title: 'Export order classification',
          note: 'Download the exported order classification.',
        },
      },
      notifications: {
        create: {
          success: 'Job classification saved!',
        },
        delete: {
          success: 'Order classification deleted!',
          error: 'Order classification could not be deleted as it is still in use!',
        },
      },
      labels: {
        name: 'Name',
        alias: 'Alias',
        api_only: 'Used only by the API',
        color: 'Color',
      },
    },
    fileClassificationTags: {
      title: 'File Classification',
      description:
        'Here you can create your own categories to categorise your files. You can also filter according to these criteria.',
      labels: {
        name: 'Name',
        alias: 'Alias',
        api_only: 'Used only by the API',
        colour: 'colour',
        color: 'Color',
      },
      dialogs: {
        create: {
          title: 'Create classification',
          note: 'Choose a name and a colour',
        },
        delete: {
          title: 'Delete classification',
          note: 'Do you really want to delete the classification?',
        },
        exports: {
          title: 'Export file classifications',
          note: 'Download the exported file classifications.',
        },
      },
      notifications: {
        create: {
          success: 'File classification saved!',
        },
        delete: {
          success: 'File classification deleted!',
          error: 'File classification could not be deleted because it is still in use!',
        },
      },
    },
    qualifications: {
      title: 'Qualifications',
      description:
        'Here you can create your own qualifications to categorize your orders. You can also filter according to these criteria.',
      labels: {
        name: 'Name',
        color: 'Color',
      },
      dialogs: {
        create: {
          title: 'Create qualification',
          note: 'Choose a name and a color.',
        },
        update: {
          title: 'Edit qualification',
          note: 'Change the name or the color.',
        },
        delete: {
          title: 'Delete qualification',
          note: 'Do you really want to delete the qualification?',
        },
        exports: {
          title: 'Export qualifications',
          note: 'Download the exported qualifications.',
        },
      },
      notifications: {
        create: {
          success: 'Qualification saved!',
        },
        delete: {
          success: 'Qualification deleted!',
          error: 'Qualification could not be deleted because it is still in use!',
        },
      },
    },
  },
  userDetails: {
    title: 'User Details',
    description: 'Here you can customise your user details',
  },
  userPassword: {
    title: 'Change user password',
    description: 'Choose a secure password',
  },
  userRole: {
    title: 'Customise user role',
    description: 'Choose your user role here',
  },
  emailVerification: {
    title: 'Status',
    description: 'View the status of your email verification here',
  },
  invitationLink: {
    title: 'Invitation-Link',
    description: 'Resend the invitation link to the user',
  },
  general: {
    notifications: {
      success: 'Successfully saved',
      error: 'Unfortunately, an error has occurred',
    },
  },
  invoicesTab: {
    title: 'Invoices',
    description: 'Configure the settings for generating invoices here.',
    paymentInformation: {
      title: 'Payment information',
      description: 'Here you can enter your payment information.',
    },
    invoicesOf: {
      title: 'Invoice from',
      description: 'Your company information.',
    },
    extended: {
      title: 'Extended',
      description: 'Other settings.',
    },
    labels: {
      invoice_from_name: 'Name',
      invoice_from_street: 'Street',
      invoice_from_postcode: 'Postal code',
      invoice_from_city: 'City',
      invoice_from_country_id: 'Country',
      invoice_from_phone: 'Phone',
      invoice_from_email: 'E-mail',
      invoice_from_web: 'Web',
      invoice_from_bank_title: 'Bank name',
      invoice_from_bank_iban: 'IBAN',
      invoice_from_bank_swift: 'BIC/SWIFT',
      invoice_from_commercial_register: 'Commercial register number',
      invoice_from_ceo: 'Managing Director',
      invoice_from_vat_title: 'Tax office name',
      invoice_from_vat_id: 'Sales tax ID',
      invoice_from_invoice_number_prefix: 'Abbreviation for invoice number',
    },
  },
  modulesTab: {
    title: 'Modules',
    description: 'Here you can define which modules are active',
  },
  assignmentsTab: {
    title: 'Allocations',
    description: 'Here you can define the assignments.',
  },
  assignments: {
    title: 'Allocations',
    description: 'Here you can define the assignments.',
    labels: {
      assignment_auto_active: 'Auto assignment',
      assignment_type: 'Assignment type',
      assignment_max_tasks_per_week: ' Maximum tasks per week per user',
      assignment_max_tasks_week_start_day: 'Reset the weekly counter from day',
      assignment_max_tasks_max_per_day: 'Maximum tasks per day',
    },
  },
  mailSettings: {
    title: 'E-mail settings',
    description: 'Here you can still find general settings for shipping.',
  },
  alternativeReportLogo: {
    title: 'Alternative report logo',
    description: 'Upload an alternative logo for the report cover page here.',
  },
  auth: {
    title: 'Authentication',
    description: 'Here you can set the validity period of the authentication.',
    labels: {
      max_session_duration: 'Session length / Session duration',
      single_session: 'You can only log in on one device',
      standard: 'Standard',
      one_hour: '1 hour',
      two_hours: '2 hours',
      four_hours: '4 hours',
      twofa_active: '2 ways authentication active',
      twofa_sms: 'Token via SMS',
      twofa_email: 'Token by e-mail',
      yes: 'Yes',
      no: 'No',
    },
  },
  gdv: {
    title: 'GDV web service',
    description:
      'Here you can activate the classic GDV web service. For this you need a separate client, so that GDV messages can be sent.',
    labels: {
      activate_legacy_gdv: 'GDV web service',
    },
  },
  appSettingsModule: {
    title: 'Mobile app settings',
    description: 'Here you can specify configurations for using the mobile apps.',
    labels: {
      mobile_app_active: 'Enable app usage',
      mobile_can_accept: 'App user may accept tasks',
      mobile_can_set_appointments: 'App user may set appointments',
      mobile_lock_tasks_when_mobile_active: 'Tasks are locked when using the app',
    },
  },
  overviewPage: {
    title: 'Settings',
    description:
      'Settings - here you can find all settings for the system. Enclosed are some of the most important settings for configuring your system.',
    goToLinkTitle: 'To the settings',
    label: {
      settings: 'Settings',
      template: 'Template',
    },
  },
  pushNotificationsTab: {
    title: 'Push notifications',
    description: 'Configure the sending of push notifications',
  },
  invoices: {
    title: 'Invoice module',
    description: 'Here you can configure the generation of invoices.',
    labels: {
      invoices: 'Invoices',
    },
  },
  activateEmailDelivery: {
    title: 'Enable email sending',
    description: 'Here you can activate the sending of e-mails.',
  },
  appMessages: {
    dialogs: {
      create: {
        title: 'Create message',
        description: 'Here you can create a new message.',
        success: 'Message was created.',
      },
      update: {
        title: 'Edit message',
        description: 'Here you can edit a message',
        success: 'Message was changed.',
      },
      delete: {
        title: 'Delete message?',
        description: 'Do you really want to delete the message?',
        success: 'Message was deleted.',
      },
      changeActiveState: {
        title: 'Change status?',
        description: 'Do you really want to change the status of the message?',
        success: 'Status was changed.',
      },
    },
    title: 'App News',
    description:
      'Here you can create global messages that will be displayed to all users in their dashboard page.',
    typeOptions: {
      INFO: 'Information',
      SUCCESS: 'Success message',
      ERROR: 'Error',
      WARNING: 'Warning',
    },
    labels: {
      title: 'Title',
      body: 'Message',
      active: 'Active',
      visible_from: 'Visible from',
      visible_to: 'Visible until',
      type: 'Type',
    },
    gridNoRecords: {
      title: 'No news available.',
      text: 'Create a new message now.',
      create: 'Create message',
    },
  },
  devicesModule: {
    title: 'Devices',
    description: 'You can configure the visibility of the device module here.',
    labels: {
      module_devices_active: 'Devices',
    },
  },
  appModules: {
    title: 'App modules',
    description: 'Here you can configure the environment variables for your app modules.',
    notifications: {
      updateSuccess: 'App module has been updated',
      toggleActive: {
        activateSuccess: 'App module has been activated',
        deactivateSuccess: 'App module has been deactivated',
      },
    },
    dialogs: {
      update: {
        title: 'Edit app module',
        description: 'You can edit the app module here.',
      },
    },
    formFields: {
      title: 'Title',
      description: 'Description',
      active: 'Activated',
      variables: 'Variables',
      value: 'Value',
      secretHint:
        'The value is not displayed for security reasons. However, you can overwrite the value.',
    },
  },
  password: {
    title: 'password',
    description: 'Here you can set the minimum requirement for passwords.',
    labels: {
      auth_password_min_length: 'Minimum length',
      auth_password_min_special: 'Number of special characters',
      auth_password_min_upper_case: 'Number of capital letters',
      auth_password_min_numbers: 'Number of numbers',
    },
  },
  autoLogout: {
    title: 'Automatic logout',
    description: 'Specify whether users should be automatically logged out after inactivity.',
    labels: {
      auto_logout_timeout: 'Automatic logout after',
    },
  },
};
