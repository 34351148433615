import { EditorChangeEvent } from '@progress/kendo-react-editor';
import { useMemo } from 'react';

import { isNumber, isString, isStringArray } from '../../helpers';

export function sanitizeInputValue(value: unknown): string | string[] | number | undefined {
  if (isString(value) || isNumber(value) || isStringArray(value)) {
    return value;
  }

  return undefined;
}

export function useSanitizedValue(value: unknown) {
  return useMemo(() => sanitizeInputValue(value), [value]);
}

/**
 * Checks whether the given Editor AST contains meaningful content.
 * Background: Kendo's Editor has the tendency to leave an empty paragraph element.
 * @see IN-3795
 */
export function isEmptyEditorTextNode(node: EditorChangeEvent['value']): boolean {
  if (node.content.childCount > 1) {
    return false;
  }

  return Boolean(node.content.firstChild?.isTextblock && node.content.firstChild.childCount === 0);
}
