export default {
  labels: {
    qualifications: 'Verwaltung',
    newTask: 'Neue Besichtigung',
    classificationTags: 'Besichtigungs-Klassifikationen',
    globalTaskSearch: 'Besichtigungen durchsuchen...',
  },
  navigation: {
    sections: {
      tasks: 'Besichtigungen',
    },
    items: {
      tasks: 'Besichtigungen',
      newTask: 'Neue Besichtigung',
      qualifications: 'Verwalter',
      classificationTags: 'Besichtigungs-Klassifikationen',
    },
  },
  components: {
    filterDialog: {
      note: 'Hier können Sie komplexere Filter für Ihre Besichtigungen konfigurieren.',
    },
    maps: {
      labels: {
        zoomTaskBounds: 'Zoom anpassen, um alle Besichtigungen anzuzeigen',
      },
    },
  },
  notificationGroups: {
    tasks: {
      title: 'Besichtigungs-Benachrichtigungen',
    },
  },
};
