import { createContext, ReactNode, useContext } from 'react';

export interface ILabel {
  [name: string]: string;
}

export interface ILabelStoreState {
  labels: ILabel;
}

type ContextType = [ILabelStoreState, (name: string, label: string) => void];

export const LabelStoreContext = createContext<ContextType | null>(null);

export const useLabelStoreState = () => {
  const context = useContext(LabelStoreContext);
  if (!context) {
    return [null, null];
  }
  return context;
};

interface LabelStoreProps {
  children: ReactNode;
}

const LabelStore = ({ children }: LabelStoreProps) => {
  const labels: ILabel = {};

  const addLabel = (name: string, label: string) => {
    if (!labels[name]) {
      labels[name] = label;
    }
  };

  return (
    <LabelStoreContext.Provider value={[{ labels }, addLabel]}>
      {children}
    </LabelStoreContext.Provider>
  );
};

export default LabelStore;
