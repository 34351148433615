import { createContext, useContext, useMemo } from 'react';
import { AnyObjectSchema, reach } from 'yup';

export const FormSchemaContext = createContext<AnyObjectSchema | undefined>(undefined);

export const useIsFieldRequired = (name: string | undefined) => {
  const schema = useContext(FormSchemaContext);

  return useMemo(() => {
    try {
      if (!name || !schema) return false;

      const schemaDesc = reach(schema, name)?.describe();
      const isOptional = schemaDesc && 'optional' in schemaDesc ? schemaDesc.optional : true;

      return !isOptional;
    } catch (e) {
      return false;
    }
  }, [name, schema]);
};
