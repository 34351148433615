export default {
  pages: {
    overview: {
      title: 'Artikel',
      description: 'Hier sehen Sie eine List vordefinierter Artikel für die Rechnungsstellung.',
      toolbar: {
        createArticle: 'Neuer Artikel',
        sync: 'Mit Billomat Synchronisieren',
      },
    },
  },
  labels: {
    articleNumber: 'Artikelnummer',
    title: 'Titel',
    description: 'Beschreibung',
    price: 'Preis',
    tax: 'Umsatzsteuer in %',
    unit: 'Einheit',
    assignments: 'Versicherungen',
    autoPayout: {
      minSum: 'Gültig für Rechnungssumme ab',
      maxSum: 'Gültig für Rechnungssumme bis',
      tooltip:
        'Damit diese Werte ausgelesen werden können, muss im Formulardesigner eine Frage mit einer Summe mit dem identifier „task_total_sum“ definiert werden. Dadurch ist eine automatische Rechnungslegung möglich.',
    },
  },
  dialogs: {
    createArticle: {
      title: 'Artikel erstellen',
      submit: 'Artikel erstellen',
    },
    updateArticle: {
      title: 'Artikel aktualisieren',
      submit: 'Artikel aktualisieren',
    },

    exports: {
      title: 'Artikel exportieren',
      note: 'Laden Sie die exportierten Artikel herunter.',
    },
  },
  notifications: {
    createArticle: {
      success: 'Artikel wurde erstellt.',
      error: 'Artikel konnte nicht erstellt werden.',
    },
    sync: {
      success: 'Artikel erfolgreich synchronisiert',
      error: 'Artikel konnte nicht synchronisiert werden.',
    },
    updateArticle: {
      success: 'Artikel wurde aktualisiert.',
      error: 'Artikel konnte nicht aktualisiert werden.',
    },
  },
};
