import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type DeleteTaskGroupMutationVariables = Types.Exact<{
  task_group_id: Types.Scalars['ID'];
}>;

export type DeleteTaskGroupMutation = {
  __typename?: 'Mutation';
  deleteTaskGroup?: { __typename?: 'TaskGroup'; id: string } | null;
};

export const DeleteTaskGroupDocument = gql`
  mutation DeleteTaskGroup($task_group_id: ID!) {
    deleteTaskGroup(task_group_id: $task_group_id) {
      id
    }
  }
`;

export function useDeleteTaskGroupMutation() {
  return Urql.useMutation<DeleteTaskGroupMutation, DeleteTaskGroupMutationVariables>(
    DeleteTaskGroupDocument,
  );
}
