import './LoginPage.scss';

import { GRAPHQL_API_URL } from '@env';
import { useAuthLogic } from '@module/auth';
import {
  LoadingButton,
  Message,
  PageTitle,
  useAppSettingsPublicQuery,
  useBrandingData,
} from '@module/common';
import { PublicLayout } from '@module/layout';
import { useDialogs } from '@module/shared/dialogs';
import {
  createSchemaValidator,
  EmailInput,
  Form,
  FormButtons,
  PasswordField,
  VerificationInput,
} from '@module/shared/forms';
import { email } from '@module/shared/helpers';
import { NotificationType, useNotifications } from '@module/shared/notifications';
import { Button } from '@progress/kendo-react-buttons';
import { Typography } from '@progress/kendo-react-common';
import { Field, FormElement } from '@progress/kendo-react-form';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { useLocalstorageState } from 'rooks';
import { InferType, object, string } from 'yup';

import { MobileAppDialog } from '../../dialogs';
import { ImprintPrivacyTerms } from './ImprintPrivacyTerms';

const loginSchema = object({
  email: email().required(),
  password: string().required(),
});
type LoginSchema = InferType<typeof loginSchema>;
const loginSchemaValidator = createSchemaValidator(loginSchema);

interface LoginFormProps {
  submitting: boolean;
  onSubmit: (values: LoginSchema) => Promise<void>;
}

const LoginForm = (props: LoginFormProps) => {
  const { submitting, onSubmit } = props;
  const { t } = useTranslation();

  return (
    <Form
      schema={loginSchema}
      validator={loginSchemaValidator}
      onSubmit={(validValues) => onSubmit(validValues as LoginSchema)}
      render={() => (
        <FormElement>
          <Field
            id="login.email"
            name="email"
            label={t('common.labels.email')}
            autoComplete="autocomplete"
            component={EmailInput}
          />

          <Field
            id="login.password"
            name="password"
            label={t('common.labels.password')}
            component={PasswordField}
          />

          <div className="k-text-center k-mt-8">
            <Link className="text-decoration-none" to="/forgot-password">
              {t('auth.pages.login.forgotPassword')}
            </Link>
          </div>

          <FormButtons align="center">
            <LoadingButton
              type="submit"
              className="k-flex-grow"
              themeColor="primary"
              size="large"
              isLoading={submitting}
            >
              {t('auth.pages.login.submit')}
            </LoadingButton>
          </FormButtons>
        </FormElement>
      )}
    />
  );
};

interface VerifyLoginFormProps {
  submitting: boolean;
  onSubmit: (values: { code: string }) => void;
}

const CODE_LENGTH = 6;

const VerifyLoginForm = (props: VerifyLoginFormProps) => {
  const { submitting, onSubmit } = props;
  const { t } = useTranslation();
  const [code, setCode] = useState<string>('');

  const handleSubmit = async () => {
    if (code.length !== CODE_LENGTH) return;
    onSubmit({ code });
  };

  useEffect(() => {
    if (code.length === CODE_LENGTH) handleSubmit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code]);

  return (
    <>
      <div className="k-display-flex k-justify-content-center k-my-8">
        <VerificationInput autoFocus onChange={(value) => setCode(value)} />
      </div>
      <FormButtons>
        <LoadingButton
          type="button"
          onClick={handleSubmit}
          className="k-flex-grow"
          themeColor="primary"
          size="large"
          disabled={code.length !== CODE_LENGTH}
          isLoading={submitting}
        >
          {t('auth.pages.login.submit')}
        </LoadingButton>
      </FormButtons>
    </>
  );
};

const serviceURL = GRAPHQL_API_URL;

type LoginStep = { step: 'login' } | { step: 'verifyLogin'; email: string };

export const LoginPage = () => {
  const { t } = useTranslation();
  const { showNotification } = useNotifications();
  const [error, setError] = useState<string>();
  const { login, verifyLogin, isLoggingIn, isVerifying } = useAuthLogic();
  const navigate = useNavigate();
  const branding = useBrandingData();
  const [{ data, fetching }] = useAppSettingsPublicQuery();
  const { showDialog } = useDialogs();
  const [taskAfterLogin] = useLocalstorageState('taskAfterLogin');
  const [loginState, setLoginState] = useState<LoginStep>({ step: 'login' });

  const openMobileDialog = () => {
    if (!serviceURL) return;
    showDialog({ serviceURL }, MobileAppDialog);
  };
  return (
    <PublicLayout>
      <PageTitle
        title={
          data?.appSettingsPublic.app_title
            ? data?.appSettingsPublic.app_title + ' - Login'
            : 'Login'
        }
      />
      <div className="LoginPage">
        <div className="login-background">
          <img src={branding.background} />
        </div>
        <div className="login-modal">
          <div className="login-modal-container">
            <div className="login-modal-input">
              <div className="k-display-flex k-flex-column k-justify-content-center">
                <img className="login-logo" src={branding.logo} />
              </div>

              <Typography.h1 className="login-title">
                {data?.appSettingsPublic.app_title ?? '\u00A0'}
              </Typography.h1>

              {!fetching && data?.appSettingsPublic.login_welcome_text ? (
                <p>{data?.appSettingsPublic.login_welcome_text}</p>
              ) : (
                <p>{t('auth.pages.login.subtitle')}</p>
              )}

              {error && (
                <div className="k-mb-4">
                  <Message type="error">{error}</Message>
                </div>
              )}

              {loginState.step === 'login' && (
                <>
                  <LoginForm
                    submitting={isLoggingIn}
                    onSubmit={async ({ email, password }) => {
                      setError(undefined);

                      const response = await login({ email, password });

                      if (response.error || !response.data) {
                        const errorMessage =
                          response.error?.graphQLErrors[0].message ?? t('common.errors.generic');
                        showNotification(errorMessage, NotificationType.Error);
                        setError(errorMessage);
                        return;
                      }

                      if (response.data.login.requiresVerification === true) {
                        setLoginState({ step: 'verifyLogin', email });
                        return;
                      }

                      showNotification(response.data.login.message, NotificationType.Success);
                      if (!taskAfterLogin) navigate('/dashboard');
                      else navigate('/tasks');
                    }}
                  />

                  <div className="or-spacer">
                    <span className="or-spacer__line" />
                    <span className="or-spacer__text">{t('auth.pages.login.or')}</span>
                    <span className="or-spacer__line" />
                  </div>

                  <Button
                    size="large"
                    iconClass="l-i-smartphone"
                    onClick={openMobileDialog}
                    disabled={!serviceURL}
                  >
                    {t('auth.pages.login.useMobile')}
                  </Button>
                </>
              )}

              {loginState.step === 'verifyLogin' && (
                <VerifyLoginForm
                  submitting={isVerifying}
                  onSubmit={async ({ code }) => {
                    setError(undefined);

                    const response = await verifyLogin({ email: loginState.email, code });

                    if (response.error || !response.data) {
                      const errorMessage =
                        response.error?.graphQLErrors[0].message ?? t('common.errors.generic');
                      showNotification(errorMessage, NotificationType.Error);
                      setError(errorMessage);
                      return;
                    }

                    showNotification(response.data.verifyLogin.message, NotificationType.Success);
                    if (!taskAfterLogin) navigate('/dashboard');
                    else navigate('/tasks');
                  }}
                />
              )}
            </div>

            <div className="login-version">
              <span className="k-font-weight-semibold">
                {data?.appSettingsPublic.app_title ?? branding.name}
              </span>
              <span className="k-fs-sm">
                {t('common.labels.version')} {branding.version}
              </span>
            </div>
            <ImprintPrivacyTerms
              imprint={data?.appSettingsPublic?.imprint}
              privacy={data?.appSettingsPublic?.privacy}
              conditions={data?.appSettingsPublic?.conditions}
            />
          </div>
        </div>
      </div>
    </PublicLayout>
  );
};
