import { Label } from '@module/shared/forms/components/Label';
import { sanitizeInputValue } from '@module/shared/forms/inputs/helpers';
import { useSanitizeEditorPasteHtml } from '@module/shared/hooks';
import {
  Editor,
  EditorChangeEvent,
  EditorMountEvent,
  EditorTools,
} from '@progress/kendo-react-editor';
import { FieldRenderProps, FieldWrapper } from '@progress/kendo-react-form';
import { StackLayout } from '@progress/kendo-react-layout';
import { useCallback, useMemo, useRef } from 'react';

import { TaskTagFragment, useTaskTagsQuery } from '../../../graphql';
import { SelectTagMenu } from '../SelectTagMenu';
import { insertTemplateTag } from './helpers';

const {
  Bold,
  Italic,
  Underline,
  Strikethrough,
  Subscript,
  Superscript,
  AlignLeft,
  AlignCenter,
  AlignRight,
  AlignJustify,
  Indent,
  Outdent,
  OrderedList,
  UnorderedList,
  Undo,
  Redo,
  FontSize,
  FontName,
  FormatBlock,
  Link,
  Unlink,
  InsertImage,
  ViewHtml,
  InsertTable,
  AddRowBefore,
  AddRowAfter,
  AddColumnBefore,
  AddColumnAfter,
  DeleteRow,
  DeleteColumn,
  DeleteTable,
  MergeCells,
  SplitCell,
} = EditorTools;

export interface TemplateTagEditorKeyUpEvent {
  left: number;
  top: number;
  target: Editor;
}

export interface TemplateTagEditorProps extends FieldRenderProps {
  onKeyUp?: (event: TemplateTagEditorKeyUpEvent) => void;
}

export const TemplateTagEditor = (props: TemplateTagEditorProps) => {
  const { value, onBlur, onChange, onFocus } = props;
  const { label } = props;
  const ref = useRef<Editor>(null);

  // insert from tag list
  const handleSelectFilter = useCallback((tag: TaskTagFragment) => {
    if (ref.current) {
      insertTemplateTag(tag.key, ref.current);
    }
  }, []);

  const handleChange = useCallback(
    (event: EditorChangeEvent) => {
      onChange({
        ...event,
        value: event.html,
      });
    },
    [onChange],
  );

  const valueSanitized = sanitizeInputValue(value) as string;
  const styles = `
    p {
      font-family: Arial,Helvetica,sans-serif;
    }
  `;

  const onMount = (event: EditorMountEvent) => {
    const iframeDocument = event.dom.ownerDocument;
    const style = iframeDocument.createElement('style');
    style.appendChild(iframeDocument.createTextNode(styles));
    iframeDocument.head.appendChild(style);
  };

  const [{ data, fetching }] = useTaskTagsQuery();

  const tags = useMemo(() => {
    return data?.taskTags ?? [];
  }, [data?.taskTags]);

  const handlePasteHtml = useSanitizeEditorPasteHtml();
  return (
    <FieldWrapper>
      <div className="k-display-flex k-flex-row k-justify-content-between k-align-items-center">
        <Label label={label}>{label}</Label>
        <SelectTagMenu onSelect={handleSelectFilter} tags={tags} fetching={fetching} />
      </div>
      <StackLayout orientation={'horizontal'}>
        <div style={{ flexGrow: 5 }}>
          <Editor
            className="k-input k-rounded-md k-input-solid"
            ref={ref}
            onMount={onMount}
            tools={[
              [Bold, Italic, Underline, Strikethrough],
              [Subscript, Superscript],
              [AlignLeft, AlignCenter, AlignRight, AlignJustify],
              [Indent, Outdent],
              [OrderedList, UnorderedList],
              FontSize,
              FontName,
              FormatBlock,
              [Undo, Redo],
              [Link, Unlink, InsertImage, ViewHtml],
              [InsertTable],
              [AddRowBefore, AddRowAfter, AddColumnBefore, AddColumnAfter],
              [DeleteRow, DeleteColumn, DeleteTable],
              [MergeCells, SplitCell],
            ]}
            value={valueSanitized ?? ''}
            onBlur={onBlur}
            onChange={handleChange}
            onFocus={onFocus}
            onPasteHtml={handlePasteHtml}
          />
        </div>
      </StackLayout>

      {/* Empty element for consistent spacing */}
      <div className="HintAndError" />
    </FieldWrapper>
  );
};
