import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import { FileClassificationTagFragmentDoc } from './fileClassificationTagFragment.generated';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CreateFileClassificationTagMutationVariables = Types.Exact<{
  input: Types.FileClassificationTagInput;
}>;

export type CreateFileClassificationTagMutation = {
  __typename?: 'Mutation';
  createFileClassificationTag?: {
    __typename?: 'FileClassificationTag';
    id: string;
    title: string;
    color: string;
    alias: string;
    type_id: Types.FileClassificationTagTypeId;
  } | null;
};

export const CreateFileClassificationTagDocument = gql`
  mutation CreateFileClassificationTag($input: FileClassificationTagInput!) {
    createFileClassificationTag(input: $input) {
      ...FileClassificationTag
    }
  }
  ${FileClassificationTagFragmentDoc}
`;

export function useCreateFileClassificationTagMutation() {
  return Urql.useMutation<
    CreateFileClassificationTagMutation,
    CreateFileClassificationTagMutationVariables
  >(CreateFileClassificationTagDocument);
}
