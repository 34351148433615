import { classNames, Typography } from '@progress/kendo-react-common';
import { forwardRef, HTMLAttributes, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { Loader } from './Loader';

interface PdfContainerProps extends Omit<HTMLAttributes<HTMLDivElement>, 'children'> {
  loading: boolean;
  error?: Error;
}

const MessageWrapper = (props: { children: ReactNode }) => (
  <div
    {...props}
    className="k-d-flex-col k-justify-content-center k-align-items-center k-gap-1"
    style={{ gridColumn: '1 / -1', gridRow: '1 / -1', backgroundColor: 'rgba(255, 255, 255, 0.9' }}
  />
);

export const PdfContainer = forwardRef<HTMLDivElement, PdfContainerProps>((props, ref) => {
  const { className, loading, error, ...rest } = props;
  const { t } = useTranslation();

  return (
    <div {...rest} className={classNames('k-d-grid k-grid-cols-1 k-pos-relative', className)}>
      <div
        style={{
          gridColumn: '1 / -1',
          gridRow: '1 / -1',
          position: 'absolute',
          width: '100%',
          height: '100%',
          // we need to provide at least 1px for the container to be able to render
          // otherwise pspdfkit will throw an error
          minHeight: 1,
          minWidth: 1,
        }}
        ref={ref}
      />

      {error ? (
        <MessageWrapper>
          <span className="l-i-file-warning u-text-5xl u-text-error-500" />
          <Typography.p className="!k-m-0 k-text-center u-text-error-500">
            {t('common.pdf.documentError')}
          </Typography.p>
        </MessageWrapper>
      ) : loading ? (
        <MessageWrapper>
          <Loader />
          <Typography.p className="!k-m-0 k-text-center">
            {t('common.pdf.documentLoading')}
          </Typography.p>
        </MessageWrapper>
      ) : null}
    </div>
  );
});
PdfContainer.displayName = 'PdfContainer';
