import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CreateClientLocationMutationVariables = Types.Exact<{
  client_id: Types.Scalars['ID'];
  input: Types.ClientLocationInput;
}>;

export type CreateClientLocationMutation = {
  __typename?: 'Mutation';
  createClientLocation?: { __typename?: 'ClientLocation'; id: string } | null;
};

export const CreateClientLocationDocument = gql`
  mutation CreateClientLocation($client_id: ID!, $input: ClientLocationInput!) {
    createClientLocation(client_id: $client_id, input: $input) {
      id
    }
  }
`;

export function useCreateClientLocationMutation() {
  return Urql.useMutation<CreateClientLocationMutation, CreateClientLocationMutationVariables>(
    CreateClientLocationDocument,
  );
}
