import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type UpsertClientServiceLevelMutationVariables = Types.Exact<{
  client_id: Types.Scalars['ID'];
  input: Types.ClientServiceLevelInput;
}>;

export type UpsertClientServiceLevelMutation = {
  __typename?: 'Mutation';
  upsertClientServiceLevel?: {
    __typename?: 'Client';
    client_service_level?: {
      __typename?: 'ClientServiceLevel';
      accept_task?: number | null;
      send_report?: number | null;
      set_appointment?: number | null;
      set_controlling?: number | null;
      set_rework_done?: number | null;
    } | null;
  } | null;
};

export const UpsertClientServiceLevelDocument = gql`
  mutation UpsertClientServiceLevel($client_id: ID!, $input: ClientServiceLevelInput!) {
    upsertClientServiceLevel(client_id: $client_id, input: $input) {
      client_service_level {
        accept_task
        send_report
        set_appointment
        set_controlling
        set_rework_done
      }
    }
  }
`;

export function useUpsertClientServiceLevelMutation() {
  return Urql.useMutation<
    UpsertClientServiceLevelMutation,
    UpsertClientServiceLevelMutationVariables
  >(UpsertClientServiceLevelDocument);
}
