import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type UpdateCalculationCategoriesMutationVariables = Types.Exact<{
  categories:
    | Array<Types.TaskCalculationCategoryBatchInput>
    | Types.TaskCalculationCategoryBatchInput;
}>;

export type UpdateCalculationCategoriesMutation = {
  __typename?: 'Mutation';
  updateTaskCalculationCategories: Array<{ __typename?: 'TaskCalculationCategory'; id: string }>;
};

export const UpdateCalculationCategoriesDocument = gql`
  mutation UpdateCalculationCategories($categories: [TaskCalculationCategoryBatchInput!]!) {
    updateTaskCalculationCategories(categories: $categories) {
      id
    }
  }
`;

export function useUpdateCalculationCategoriesMutation() {
  return Urql.useMutation<
    UpdateCalculationCategoriesMutation,
    UpdateCalculationCategoriesMutationVariables
  >(UpdateCalculationCategoriesDocument);
}
