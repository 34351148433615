import { NavigateToLoginIfNotAuthenticated, useAuthContext } from '@module/auth';
import { useMe } from '@module/casl';
import {
  AppSettingsLoader,
  DownloadsPanel,
  DrawerContainer,
  DrawerContextProvider,
  DrawerSidebar,
  PageContextProvider,
  useDrawerContextData,
  useDrawerData,
} from '@module/common';
import { DialogsContainer, DialogsProvider } from '@module/shared/dialogs';
import { decodeJwt } from 'jose';
import { ReactNode, useCallback, useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import { ColumnLayout } from './ColumnLayout';
import { PrivatePageHeader } from './PrivatePageHeader';

export interface PrivateLayoutProps {
  children?: ReactNode | undefined;
  sidebar?: ReactNode;
}

export const PrivateLayout = (props: PrivateLayoutProps) => {
  const { children, sidebar } = props;
  const me = useMe();
  const { getAuthState } = useAuthContext();

  const handleTabVisibilityChange = useCallback(
    (e: Event) => {
      if ((e.target as Document).visibilityState === 'hidden') return;
      const authState = getAuthState();
      if (!authState) {
        location.reload();
      } else {
        const jwtPayload = decodeJwt(authState.data.access_token);
        const userId = jwtPayload.sub;
        if (me.id !== userId) location.reload();
      }
    },
    [me.id, getAuthState],
  );

  useEffect(() => {
    document.addEventListener('visibilitychange', handleTabVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleTabVisibilityChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const drawerContext = useDrawerContextData();

  const sidebarRegion = sidebar ? (drawerContext.layout === 'mobile' ? 'drawer' : 'page') : 'none';

  return (
    <PageContextProvider hasSidebar={sidebarRegion === 'page'}>
      <ColumnLayout
        sidebar={sidebarRegion === 'page' && sidebar}
        header={<PrivatePageHeader />}
        content={children}
      />

      {sidebarRegion === 'drawer' && sidebar && <DrawerSidebar>{sidebar}</DrawerSidebar>}
    </PageContextProvider>
  );
};

const PrivateDrawerContainer = (props: { children: ReactNode }) => {
  const permittedDrawerData = useDrawerData();
  const drawerContext = useDrawerContextData();

  return (
    <DrawerContextProvider drawerContext={drawerContext}>
      <DrawerContainer data={permittedDrawerData} {...props} />
    </DrawerContextProvider>
  );
};

export const PrivateLayoutOutlet = () => {
  return (
    <NavigateToLoginIfNotAuthenticated>
      <AppSettingsLoader>
        <DialogsProvider>
          <PrivateDrawerContainer>
            <Outlet />
          </PrivateDrawerContainer>

          <DownloadsPanel />

          <DialogsContainer />
        </DialogsProvider>
      </AppSettingsLoader>
    </NavigateToLoginIfNotAuthenticated>
  );
};
