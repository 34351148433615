import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
export type DownloadFragment = {
  __typename?: 'Download';
  id: string;
  state_id: Types.DownloadStateId;
  filename: string;
  file_ids: Array<string>;
  url_download: string;
};

export const DownloadFragmentDoc = gql`
  fragment Download on Download {
    id
    state_id
    filename
    file_ids
    url_download
  }
`;
