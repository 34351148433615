import * as Sentry from '@sentry/browser';
import pLimit from 'p-limit';
import Pica from 'pica';

import { FileType } from './fileTypes';

// Used for heic2any function since it return both single blob and blob array
const getSingleBlob = (blob: Blob | Blob[]) => {
  if (Array.isArray(blob)) return blob[0];
  return blob;
};

const getFilenameWithNewExtension = (filename: string, newExtension: string) => {
  const lastDotIndex = filename.lastIndexOf('.');
  if (lastDotIndex !== -1) {
    // Strip old file extension and add new one
    return filename.substring(0, lastDotIndex) + newExtension;
  }
  return filename;
};

type ConvertResult = { isOk: true; file: File } | { isOk: false; error: Error };

const loadHeicToJpeg = async () => {
  const heic2any = (await import('heic2any')).default;
  return async (blob: Blob) =>
    getSingleBlob(await heic2any({ blob, toType: FileType.JPG, quality: 0.8 }));
};

/**
 * Shrinks given canvas to minimum dimensions to release memory after usage.
 * This is a workaround for a bug in iOS Safari, where memory gets released too late.
 * @see https://pqina.nl/blog/total-canvas-memory-use-exceeds-the-maximum-limit/
 */
const releaseCanvasMemory = (canvas: HTMLCanvasElement) => {
  canvas.width = 1;
  canvas.height = 1;
  const ctx = canvas.getContext('2d');
  ctx?.clearRect(0, 0, 1, 1);
};

const MAX_PIXELS = 2560 * 1920;
const pica = Pica();

/**
 * Resizes a JPEG image to the maximum resolution.
 *
 * Returns null if the image doesn't exceed the maximum resolution.
 *
 * Also returns null in case of an error, since resizing can fail for various
 * reasons (memory etc.) and this is considered as a best-effort operation.
 */
const resizeJpegToMaxPixels = async (sourceBlob: Blob): Promise<Blob | null> => {
  let outputCanvas: HTMLCanvasElement | null = null;
  let resizedCanvas: HTMLCanvasElement | null = null;

  try {
    const sourceImageData = await window.createImageBitmap(sourceBlob);
    const sourceWidth = sourceImageData.width;
    const sourceHeight = sourceImageData.height;
    sourceImageData.close(); // Release memory. We're only interested in the dimensions.

    const scaleFactor = Math.sqrt(MAX_PIXELS / (sourceWidth * sourceHeight));

    // No need to resize if the image doesn't exceed the maximum resolution.
    if (scaleFactor >= 1) {
      return null;
    }

    const targetWidth = sourceWidth * scaleFactor;
    const targetHeight = sourceHeight * scaleFactor;

    outputCanvas = document.createElement('canvas');
    outputCanvas.width = targetWidth;
    outputCanvas.height = targetHeight;

    const image = await new Promise<HTMLImageElement>((resolve, reject) => {
      const element = document.createElement('img');
      const objectUrl = URL.createObjectURL(sourceBlob);

      element.onerror = () => {
        URL.revokeObjectURL(objectUrl);
        reject(new Error('Failed to create image from blob'));
      };
      element.onload = () => {
        URL.revokeObjectURL(objectUrl);
        resolve(element);
      };

      element.src = objectUrl;
    });

    resizedCanvas = await pica.resize(image, outputCanvas);
    const resizedJpegBlob = await pica.toBlob(resizedCanvas, 'image/jpeg');

    return resizedJpegBlob;
  } catch (originalError) {
    // Log, but do not throw, so that we get informed how often this happens.
    const error = new Error('JPEG resize skipped due to error', { cause: originalError });
    Sentry.captureException(error);
    console.error(error); // eslint-disable-line no-console

    return null;
  } finally {
    if (outputCanvas) {
      releaseCanvasMemory(outputCanvas);
    }
    if (resizedCanvas) {
      releaseCanvasMemory(resizedCanvas);
    }
  }
};

/**
 * We use a limit of one active conversion to optimize memory & CPU usage.
 * See: https://github.com/nodeca/pica?tab=readme-ov-file#resizefrom-to-options---promise
 */
const limitOne = pLimit(1);

export const convertFileIfNeeded = async (file: File): Promise<ConvertResult> => {
  try {
    if (file.type === FileType.HEIC) {
      const heicToJpeg = await loadHeicToJpeg();

      return await limitOne(async () => {
        const jpegBlob = await heicToJpeg(file);
        const jpegFilename = getFilenameWithNewExtension(file.name, '.jpg');
        const resizedJpegBlob = await resizeJpegToMaxPixels(jpegBlob);
        const processedFile = resizedJpegBlob
          ? new File([resizedJpegBlob], jpegFilename, { type: FileType.JPG })
          : new File([jpegBlob], jpegFilename, { type: FileType.JPG });

        return { isOk: true, file: processedFile };
      });
    }

    if (file.type === FileType.JPG) {
      return await limitOne(async () => {
        const resizedJpegBlob = await resizeJpegToMaxPixels(file);
        const processedFile = resizedJpegBlob
          ? new File([resizedJpegBlob], file.name, { type: file.type })
          : file;

        return { isOk: true, file: processedFile };
      });
    }

    return { isOk: true, file };
  } catch (errorLike) {
    const error =
      errorLike instanceof Error
        ? errorLike
        : new Error('File processing failed', { cause: errorLike });
    Sentry.captureException(error);
    console.error(error); // eslint-disable-line no-console

    return { isOk: false, error };
  }
};
