import {
  Ability,
  AbilityBuilder,
  AbilityClass,
  ForcedSubject,
  subject as subjectHelper,
} from '@casl/ability';
import { useCallback, useMemo } from 'react';

import { Permission } from '../../../types/graphql.generated';
import { GenericCan, GenericCanSharedProps } from '../components/GenericCan';
import { CurrentUserAbilityFragment } from '../graphql';
import { detectSubjectTypeByTypename as detectSubjectType } from '../helpers';
import { useMeOrDefault } from '../hooks';

interface ApiTokenSubject {
  me: CurrentUserAbilityFragment;
}

type ApiTokenSubjectArg = Partial<ApiTokenSubject>;

type Actions = 'viewApiTokens' | 'createApiTokens' | 'deleteApiTokens';
type Subjects = ApiTokenSubject | 'ApiTokenSubject';

type ApiTokenAbility = Ability<[Actions, Subjects]>;
const userAbility = Ability as AbilityClass<ApiTokenAbility>;

export const useApiTokenAbility = (): [
  ApiTokenAbility,
  (sub?: ApiTokenSubjectArg) => ApiTokenSubject & ForcedSubject<'ApiTokenSubject'>,
] => {
  const ability = useMemo(() => {
    const { can, build } = new AbilityBuilder(userAbility);

    can('viewApiTokens', 'ApiTokenSubject', {
      'me.globalPermissions': { $in: [Permission.USER_INDEX_API_TOKENS] },
    });
    can('createApiTokens', 'ApiTokenSubject', {
      'me.globalPermissions': { $in: [Permission.USER_CREATE_API_TOKEN] },
    });
    can('deleteApiTokens', 'ApiTokenSubject', {
      'me.globalPermissions': { $in: [Permission.USER_DELETE_API_TOKEN] },
    });

    return build({ detectSubjectType });
  }, []);

  const me = useMeOrDefault();
  const subject = useCallback(
    (sub?: ApiTokenSubjectArg) => {
      return subjectHelper('ApiTokenSubject', { me, ...sub });
    },
    [me],
  );

  return [ability, subject];
};

type CanApiTokenProps = GenericCanSharedProps<Actions>;

export const CanApiToken = (props: CanApiTokenProps) => {
  const [serviceCatalogAbility, serviceCatalogSubject] = useApiTokenAbility();

  return (
    <GenericCan<Actions, Subjects, ApiTokenAbility>
      ability={serviceCatalogAbility}
      subject={serviceCatalogSubject()}
      {...props}
    />
  );
};
