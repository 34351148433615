import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import { TaskGroupFragmentDoc } from '../../../tasks/graphql/generated/taskGroups.generated';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CreateTaskGroupMutationVariables = Types.Exact<{
  input: Types.TaskGroupInput;
}>;

export type CreateTaskGroupMutation = {
  __typename?: 'Mutation';
  createTaskGroup?: { __typename?: 'TaskGroup'; id: string; title: string; color: string } | null;
};

export const CreateTaskGroupDocument = gql`
  mutation CreateTaskGroup($input: TaskGroupInput!) {
    createTaskGroup(input: $input) {
      ...TaskGroup
    }
  }
  ${TaskGroupFragmentDoc}
`;

export function useCreateTaskGroupMutation() {
  return Urql.useMutation<CreateTaskGroupMutation, CreateTaskGroupMutationVariables>(
    CreateTaskGroupDocument,
  );
}
