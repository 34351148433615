import { TextInput, TextInputProps } from './TextInput';

export const EmailInput = (props: TextInputProps) => {
  return (
    <TextInput
      {...props}
      onChange={(event) => {
        props.onChange?.({ ...event, value: event.value.trim().toLowerCase() });
      }}
    />
  );
};
