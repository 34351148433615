import { classNames } from '@progress/kendo-react-common';
import { Link } from 'react-router-dom';

import { DrawerItemData } from './types';

export interface DrawerItemProps {
  item: DrawerItemData;
  isLastItem: boolean;
}

export const DrawerItem = (props: DrawerItemProps) => {
  const { item, isLastItem } = props;

  return (
    <li
      className={classNames(
        'k-drawer-item',
        item.isActive ? 'k-state-selected' : undefined,
        isLastItem ? 'k-mb-7' : undefined,
      )}
      onClick={item.onClick}
    >
      <Link to={item.route}>
        {item.icon && <span className={classNames('k-drawer-item-icon', item.icon)} />}
        <span className="k-item-text">{item.text}</span>
      </Link>
    </li>
  );
};
