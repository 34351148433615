import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ReorderTaskReworkDocumentsMutationVariables = Types.Exact<{
  taskId: Types.Scalars['ID'];
  order: Array<Types.Scalars['Int']> | Types.Scalars['Int'];
}>;

export type ReorderTaskReworkDocumentsMutation = {
  __typename?: 'Mutation';
  reorderTaskReworkDocuments: {
    __typename?: 'TaskReworkDocumentsPaginator';
    data: Array<{ __typename?: 'FileItem'; id: string }>;
  };
};

export const ReorderTaskReworkDocumentsDocument = gql`
  mutation ReorderTaskReworkDocuments($taskId: ID!, $order: [Int!]!) {
    reorderTaskReworkDocuments(task_id: $taskId, order: $order) {
      data {
        id
      }
    }
  }
`;

export function useReorderTaskReworkDocumentsMutation() {
  return Urql.useMutation<
    ReorderTaskReworkDocumentsMutation,
    ReorderTaskReworkDocumentsMutationVariables
  >(ReorderTaskReworkDocumentsDocument);
}
