import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CreateStaticMapMutationVariables = Types.Exact<{
  input: Types.StaticMapData;
}>;

export type CreateStaticMapMutation = {
  __typename?: 'Mutation';
  createStaticMap: { __typename?: 'FileItem'; id: string };
};

export const CreateStaticMapDocument = gql`
  mutation CreateStaticMap($input: StaticMapData!) {
    createStaticMap(input: $input) {
      id
    }
  }
`;

export function useCreateStaticMapMutation() {
  return Urql.useMutation<CreateStaticMapMutation, CreateStaticMapMutationVariables>(
    CreateStaticMapDocument,
  );
}
