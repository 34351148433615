import { CompositeFilterDescriptor, FilterDescriptor } from '@progress/kendo-data-query';
import { createElement, useCallback, useMemo } from 'react';

import { Label } from '../../forms';
import { isEmptyString, isNotNullish, isTruthy } from '../../helpers';
import { useFilterContext } from '../hooks/useFilterContext';
import {
  CompositeFilterDescriptorWithType,
  FilterChangeEvent,
  FilterDescriptorWithType,
  FilterFieldWithType,
  isCompositeFilterDescriptorWithType,
} from '../types';

function findValueByField(
  filter: FilterDescriptorWithType | CompositeFilterDescriptorWithType,
  field: FilterFieldWithType,
): unknown | undefined {
  if (isCompositeFilterDescriptorWithType(filter)) {
    // If it's a CompositeFilterDescriptor, recursively search in each filter
    for (const innerFilter of filter.filters) {
      const found = findValueByField(innerFilter, field);
      if (found !== undefined) {
        return found;
      }
    }
  } else {
    // If it's a FilterDescriptor, check if the field matches and return the value
    if (filter.field === field.name && filter.type === field.type) {
      return filter.value;
    }
  }

  // return undefined if the field is not found
  return undefined;
}

function useFieldsFilters(fields: FilterFieldWithType[]) {
  // we need to get the values from the filter state in the context to have a "single source of truth"
  // otherwise the filter state in the context would be out of sync with the filter from the FilterBar
  const { filterState } = useFilterContext();
  return useMemo(() => {
    return fields.map((field) => {
      const { customFilters, expandedFilters, additionalFilters, searchFilters } = filterState;
      const filtersArraysToSearch = [
        customFilters,
        expandedFilters,
        additionalFilters,
        searchFilters,
      ].filter(isTruthy);

      let result: unknown;

      for (const filtersArray of filtersArraysToSearch) {
        const value = findValueByField(filtersArray, field);
        if (value) {
          result = value;
          break;
        }
      }

      return {
        field: field.name,
        operator: field.operator,
        value: result,
        type: field.type,
      };
    });
  }, [fields, filterState]);
}

export interface FilterBarProps {
  fields: FilterFieldWithType[];
  onFilterChange: (filter: CompositeFilterDescriptor | null) => void;
  logic?: 'and' | 'or';
}

export const FilterBar = (props: FilterBarProps) => {
  const { fields, logic = 'and' } = props;

  const filters = useFieldsFilters(fields);
  const updateFilters = useCallback(
    (nextFilter: FilterDescriptor | FilterDescriptor[]) => {
      const filtersModified = Array.isArray(nextFilter) ? nextFilter : [nextFilter];

      // map to replace old with new filters
      const filtersMapped = filters.map<FilterDescriptor>((filter) => {
        const filterModified = filtersModified.find(
          (filterModified) => filter.field === filterModified.field,
        );

        if (filterModified) {
          const { field, operator, value } = filterModified;
          return { field, operator, value };
        }
        return filter;
      });

      // remove nullish filters
      const filtersUsed = filtersMapped.filter(
        (filterMapped) => isNotNullish(filterMapped.value) && !isEmptyString(filterMapped.value),
      );

      props.onFilterChange(filtersUsed.length > 0 ? { logic, filters: filtersUsed } : null);
    },
    [filters, logic, props],
  );

  const handleFilterChange = useCallback(
    (event: FilterChangeEvent) => {
      updateFilters(event.nextFilter);
    },
    [updateFilters],
  );

  return (
    <>
      {fields.map((field, index) => {
        if (field.hidden) {
          return null;
        }
        const fieldFilter = filters.find((f) => f.field === field.name);

        if (!fieldFilter) {
          return null;
        }
        const filterElement = createElement(field.filter, {
          filter: fieldFilter,
          onFilterChange: handleFilterChange,
          filterData: field.filterData,
        });
        return (
          <div key={index} className={field.className}>
            {field.label && <Label>{field.label}:</Label>}
            {filterElement}
          </div>
        );
      })}
    </>
  );
};
