import { string } from 'yup';

// Source: https://emailregex.com/
export const emailRegex = new RegExp(
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
);

export const email = () => {
  return string().test('email', 'common.components.emailInput.invalid', (value) =>
    value ? emailRegex.test(value) : true,
  );
};
