import { useMemo } from 'react';

import { ClientsForTaskQuery, useClientsForTaskQuery } from '../graphql';

type ClientForTask = ClientsForTaskQuery['clientsForTask']['data'][number];

export const useClientsForTask = () => {
  const [response] = useClientsForTaskQuery();
  const isLoading = response.fetching;
  const clientsForTask = useMemo<Array<ClientForTask>>(
    () => response.data?.clientsForTask.data ?? [],
    [response.data?.clientsForTask],
  );
  return { clientsForTask, isLoading };
};
