import { clamp } from 'lodash';

import { PasswordSettings } from '../hooks/usePasswordSettings';

export function getNumNumbers(value: string): number {
  return (value.match(/[0-9]/g) ?? []).length;
}

export function getNumSpecialChars(value: string): number {
  return (value.match(/[!@#$%^&*-]/g) ?? []).length;
}

export function getNumUppercase(value: string): number {
  return (value.match(/[A-Z]/g) ?? []).length;
}

export function checkPasswordLength(value: string | undefined, settings: PasswordSettings) {
  return value !== undefined && value.length >= settings.minLength;
}

export function checkPasswordNumbers(
  value: string | undefined,
  settings: PasswordSettings,
): boolean {
  return value !== undefined && getNumNumbers(value) >= settings.minNumbers;
}

export function checkPasswordSpecial(
  value: string | undefined,
  settings: PasswordSettings,
): boolean {
  return value !== undefined && getNumSpecialChars(value) >= settings.minSpecial;
}

export function checkPasswordUppercase(
  value: string | undefined,
  settings: PasswordSettings,
): boolean {
  return value !== undefined && getNumUppercase(value) >= settings.minUpperCase;
}

export function getPasswordStrength(value: string | undefined, settings: PasswordSettings): number {
  let level = 1;

  if (checkPasswordLength(value, settings)) {
    ++level;
  }

  if (checkPasswordSpecial(value, settings)) {
    ++level;
  }

  if (checkPasswordUppercase(value, settings)) {
    ++level;
  }

  if (checkPasswordNumbers(value, settings)) {
    ++level;
  }

  return clamp(level, 1, 5);
}
