import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
export type TaskVisitTypeFragment = {
  __typename?: 'TaskVisitType';
  id: string;
  title: string;
  need_location: boolean;
  show_location: boolean;
};

export const TaskVisitTypeFragmentDoc = gql`
  fragment TaskVisitType on TaskVisitType {
    id
    title
    need_location
    show_location
  }
`;
