import {
  Form as KendoForm,
  FormHandle,
  FormProps as KendoFormProps,
} from '@progress/kendo-react-form';
import { forwardRef } from 'react';
import { AnyObjectSchema } from 'yup';

import { FormSchemaContext } from '../context';

interface FormProps extends KendoFormProps {
  schema?: AnyObjectSchema;
}

export type FormSubmitCallback = NonNullable<FormProps['onSubmit']>;

export const Form = forwardRef<FormHandle, FormProps>((props, ref) => {
  const { schema, ...rest } = props;

  return (
    <FormSchemaContext.Provider value={schema}>
      <KendoForm ref={ref} {...rest} />
    </FormSchemaContext.Provider>
  );
});

Form.displayName = 'Form';
