import { Button } from '@progress/kendo-react-buttons';
import { classNames } from '@progress/kendo-react-common';
import { InputProps } from '@progress/kendo-react-inputs';
import { omit } from 'lodash';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

export type SearchInputProps = Omit<InputProps, 'size' | 'onChange'> & {
  size?: null | 'small' | 'medium' | 'large';
  onChange: (event: string) => void;
};

export const TextSearchInput = (props: SearchInputProps) => {
  const { className, valid, style, size = 'large', onChange } = props;
  const { t } = useTranslation();

  const inputRef = useRef<HTMLInputElement>(null);

  const handleChange = (searchTerm: string) => {
    onChange(searchTerm.trim());
  };

  const handleClear = () => {
    if (inputRef.current) {
      handleChange('');
      inputRef.current.value = '';
      inputRef.current.focus();
    }
  };

  const restProps = omit(props, [
    'size',
    'className',
    'label',
    'id',
    'validationMessage',
    'defaultValue',
    'valid',
    'ariaLabelledBy',
    'ariaDescribedBy',
    'validityStyles',
    'style',
  ]);

  return (
    <span
      className={classNames('k-input k-rounded-md k-input-solid', className, {
        'k-valid': valid,
        'k-input-sm': size === 'small',
        'k-input-md': size === 'medium',
        'k-input-lg': size === 'large',
      })}
      style={{
        width: 'min(100%, 400px)',
        ...style,
      }}
    >
      <span className="k-input-icon">
        <span className="l-i-search" />
      </span>
      <input
        className="k-input-inner"
        {...restProps}
        ref={inputRef}
        onChange={(e) => handleChange(e.target.value)}
      />
      <Button
        size="small"
        disabled={!inputRef.current?.value}
        fillMode="flat"
        onClick={() => handleClear()}
        className="k-input-icon"
        aria-label={t('common.labels.clear')}
      >
        <span className="l-i-x" />
      </Button>
    </span>
  );
};
