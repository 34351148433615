import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type UpdateClientQualificationHideInsuranceMutationVariables = Types.Exact<{
  client_id: Types.Scalars['ID'];
  input: Types.UpdateClientQualificationHideInsuranceInput;
}>;

export type UpdateClientQualificationHideInsuranceMutation = {
  __typename?: 'Mutation';
  updateClientQualificationHideInsurance?: {
    __typename?: 'Client';
    id: string;
    hidden_insurances: Array<{
      __typename?: 'ClientQualificationHideInsurance';
      id: string;
      insurance_client_id: string;
    }>;
  } | null;
};

export const UpdateClientQualificationHideInsuranceDocument = gql`
  mutation UpdateClientQualificationHideInsurance(
    $client_id: ID!
    $input: UpdateClientQualificationHideInsuranceInput!
  ) {
    updateClientQualificationHideInsurance(client_id: $client_id, input: $input) {
      id
      hidden_insurances {
        id
        insurance_client_id
      }
    }
  }
`;

export function useUpdateClientQualificationHideInsuranceMutation() {
  return Urql.useMutation<
    UpdateClientQualificationHideInsuranceMutation,
    UpdateClientQualificationHideInsuranceMutationVariables
  >(UpdateClientQualificationHideInsuranceDocument);
}
