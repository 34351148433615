import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type AssignEmailTemplatesMutationVariables = Types.Exact<{
  client_id: Types.Scalars['ID'];
  email_template_ids: Array<Types.Scalars['ID']> | Types.Scalars['ID'];
}>;

export type AssignEmailTemplatesMutation = {
  __typename?: 'Mutation';
  assignEmailTemplatesToClientDirectMessages: {
    __typename?: 'ResponseMessage';
    success?: boolean | null;
    message?: string | null;
  };
};

export const AssignEmailTemplatesDocument = gql`
  mutation AssignEmailTemplates($client_id: ID!, $email_template_ids: [ID!]!) {
    assignEmailTemplatesToClientDirectMessages(
      client_id: $client_id
      email_template_ids: $email_template_ids
    ) {
      success
      message
    }
  }
`;

export function useAssignEmailTemplatesMutation() {
  return Urql.useMutation<AssignEmailTemplatesMutation, AssignEmailTemplatesMutationVariables>(
    AssignEmailTemplatesDocument,
  );
}
