import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import { ClientSelectFragmentDoc } from './clientSelectFragment.generated';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type LeadersQueryVariables = Types.Exact<{ [key: string]: never }>;

export type LeadersQuery = {
  __typename?: 'Query';
  leaders: {
    __typename?: 'LeaderList';
    data: Array<{
      __typename?: 'Client';
      id: string;
      name: string;
      job_title?: string | null;
      email?: string | null;
    }>;
  };
};

export const LeadersDocument = gql`
  query Leaders {
    leaders {
      data {
        ...ClientSelect
      }
    }
  }
  ${ClientSelectFragmentDoc}
`;

export function useLeadersQuery(options?: Omit<Urql.UseQueryArgs<LeadersQueryVariables>, 'query'>) {
  return Urql.useQuery<LeadersQuery, LeadersQueryVariables>({ query: LeadersDocument, ...options });
}
