import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type TaskTemplatesForClientQueryVariables = Types.Exact<{
  client_id: Types.Scalars['ID'];
}>;

export type TaskTemplatesForClientQuery = {
  __typename?: 'Query';
  taskTemplatesForClient: Array<{
    __typename?: 'TaskTemplate';
    id: string;
    title: string;
    shortcut?: string | null;
    version: string;
    color: string;
    active?: boolean | null;
    json?: any | null;
    type_id: Types.TaskTemplateTypeId;
    default_supervisor_id?: string | null;
  }>;
};

export const TaskTemplatesForClientDocument = gql`
  query TaskTemplatesForClient($client_id: ID!) {
    taskTemplatesForClient(client_id: $client_id) {
      id
      title
      shortcut
      version
      color
      active
      json
      type_id
      default_supervisor_id
    }
  }
`;

export function useTaskTemplatesForClientQuery(
  options: Omit<Urql.UseQueryArgs<TaskTemplatesForClientQueryVariables>, 'query'>,
) {
  return Urql.useQuery<TaskTemplatesForClientQuery, TaskTemplatesForClientQueryVariables>({
    query: TaskTemplatesForClientDocument,
    ...options,
  });
}
