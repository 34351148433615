import { useCallback } from 'react';
import { useClient } from 'urql';

import {
  AutocompleteCityDocument,
  AutocompleteCityQuery,
  AutocompleteCityQueryVariables,
} from '../graphql';

export const useFetchCityForPostcode = () => {
  const client = useClient();

  return useCallback(
    async (postcode: string) => {
      const { data } = await client
        .query<AutocompleteCityQuery, AutocompleteCityQueryVariables>(AutocompleteCityDocument, {
          postcode,
        })
        .toPromise();

      return data?.autocomplete_city.city;
    },
    [client],
  );
};
