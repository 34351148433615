import {
  IntlProvider,
  LocalizationProvider as LocalizationProviderBase,
} from '@progress/kendo-react-intl';
import { ReactNode } from 'react';

import { useLocale } from './LocaleProvider';

export const LocalizationProvider = (props: { children?: ReactNode | undefined }) => {
  const { locale } = useLocale();

  return (
    <LocalizationProviderBase language={locale.localeId}>
      <IntlProvider locale={locale.localeId}>{props.children}</IntlProvider>
    </LocalizationProviderBase>
  );
};
