import { useLayoutEffect, useState } from 'react';

/**
 * window.matchMedia hook
 *
 * usage example: `const isLarge = useMatchMedia('(min-width: 1024px)')`
 */
export const useMatchMedia = (query: string) => {
  const [matches, setMatches] = useState(window.matchMedia(query).matches);

  useLayoutEffect(() => {
    let isMounted = true;

    const handleMediaChange: MediaQueryList['onchange'] = (changedMediaQueryList) => {
      if (!isMounted) {
        return;
      }

      setMatches(changedMediaQueryList.matches);
    };

    const mediaQueryList = window.matchMedia(query);
    mediaQueryList.addListener(handleMediaChange);

    return () => {
      isMounted = false;
      mediaQueryList.removeListener(handleMediaChange);
    };
  }, [query]);

  return matches;
};
