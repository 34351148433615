import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import { InterfaceMessageTemplateCategoryFragmentDoc } from './interfaceMessageTemplateCategoryFragment.generated';
export type InterfaceMessageTemplateFragment = {
  __typename?: 'InterfaceMessageTemplate';
  id: string;
  title: string;
  description?: string | null;
  message_type?: string | null;
  subject?: string | null;
  body?: string | null;
  category?: { __typename?: 'InterfaceMessageTemplateCategory'; id: string; title: string } | null;
  assignments: Array<{ __typename?: 'Client'; id: string; name: string }>;
};

export const InterfaceMessageTemplateFragmentDoc = gql`
  fragment InterfaceMessageTemplate on InterfaceMessageTemplate {
    id
    title
    description
    category {
      ...InterfaceMessageTemplateCategory
    }
    message_type
    subject
    body
    assignments {
      id
      name
    }
  }
  ${InterfaceMessageTemplateCategoryFragmentDoc}
`;
