export default {
  errors: {
    token_expired: 'Verification link has expired',
    already_verified: 'Email has already been verified.',
  },
  pages: {
    login: {
      title: 'Welcome back',
      subtitle: 'Welcome back, please enter your data',
      submit: 'Login',
      forgotPassword: 'Forgot your password?',
      or: 'or',
      useMobile: 'Use Inspcto Mobile',
    },
    initialLogin: {
      title: 'Welcome',
      subtitle: 'Welcome to INSPCTO, you have been invited to our portal',
      submit: 'Continue',
      note: 'Choose your initial password to complete the registration process',
    },
    forgotPassword: {
      title: 'Forgot password',
      submit: 'Send link',
      note:
        'To reset your password, enter the email address you used.\n' +
        'We will then send you a link to your email address to set a new password.',
      toLogin: 'Back to login',
    },
    resetPassword: {
      title: 'Reset password',
      submit: 'Reset password',
      note: 'Enter your new password',
    },
    verifyEmail: {
      title: 'Confirm email',
      submit: 'Confirm email',
      note: 'Confirm your email by clicking on the button',
    },
    noPermission: {
      error: '403 Error',
      title: 'No Permission',
      note: 'You do not have permission to access this page.',
      backToHome: 'Back to home',
      back: 'Back',
    },
    maintenance: {
      error: 'Maintenance',
      title: 'This page is under temporary maintenance.',
      note: 'Please come back at another time to experience all the new changes.',
    },
  },
  dialogs: {
    useMobileApp: {
      title: 'Inspcto Mobile App',
      appDescription:
        'You can also use the portal directly from your browser on your phone. But in addition, we also offer an app in the App Store for iOS devices and an app in the Play Store for Android devices. You can download the app here:',
      connectServiceTitle: 'Instructions for connecting the app to your portal:',
      connectServiceDescription:
        'If you have installed and opened the app on your device, then select "Link to portal" under "Settings" - this will bring up a camera. Use it to scan the QR code shown below, and the two devices will be linked.',
      alternativeServiceURL:
        'If you have problems, you can also enter the server there manually. To do this, select: ',
      copySuccess: 'The link was copied to the clipboard.',
    },
  },
};
