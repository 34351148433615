import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CreateCustomerPortalEventDataQueryVariables = Types.Exact<{ [key: string]: never }>;

export type CreateCustomerPortalEventDataQuery = {
  __typename?: 'Query';
  customerPortalEventTypes: Array<{
    __typename?: 'CustomerPortalEventType';
    id: string;
    title: string;
    has_video: boolean;
    need_appointment: boolean;
    type_id: Types.CustomerPortalEventTypeId;
  }>;
  countries: Array<{
    __typename?: 'Country';
    id: string;
    title: string;
    dial_code: string;
    code: string;
  }>;
};

export const CreateCustomerPortalEventDataDocument = gql`
  query CreateCustomerPortalEventData {
    customerPortalEventTypes {
      id
      title
      has_video
      need_appointment
      type_id
    }
    countries {
      id
      title
      dial_code
      code
    }
  }
`;

export function useCreateCustomerPortalEventDataQuery(
  options?: Omit<Urql.UseQueryArgs<CreateCustomerPortalEventDataQueryVariables>, 'query'>,
) {
  return Urql.useQuery<
    CreateCustomerPortalEventDataQuery,
    CreateCustomerPortalEventDataQueryVariables
  >({ query: CreateCustomerPortalEventDataDocument, ...options });
}
