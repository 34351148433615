import './PageSidebar.scss';

import { Button } from '@progress/kendo-react-buttons';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { useDrawerContext } from '../drawer';

interface PageSidebarProps {
  header?: ReactNode;
  children: ReactNode;
}

export const PageSidebar = ({ header, children }: PageSidebarProps) => {
  const drawer = useDrawerContext();
  const { t } = useTranslation();

  return (
    <aside className="PageSidebar">
      <header className="PageSidebarHeader">
        {header}

        {drawer.layout === 'mobile' && (
          <div className="PageSidebarDrawerToggle">
            <Button
              iconClass="l-i-x"
              size="large"
              fillMode="flat"
              onClick={drawer.disableMobileExpanded}
              aria-label={t('common.labels.close')}
            />
          </div>
        )}
      </header>

      <div className="PageSidebarContent">{children}</div>
    </aside>
  );
};

export const PageSidebarTitle = (props: { children: ReactNode }) => (
  <h2 {...props} className="PageSidebarTitle" />
);
