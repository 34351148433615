import './AppMessageIndicator.scss';

import { useVisibleMessagesQuery } from '@module/platform/graphql';
import { classNames } from '@progress/kendo-react-common';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useLocalstorageState } from 'rooks';

import { PageNotificationType } from '../../../../types/graphql.generated';
import { getNotificationTypeIcon } from './PageNotification';

export const AppMessageIndicator = () => {
  const { t } = useTranslation();
  const context = useMemo(() => ({ additionalTypenames: ['AppMessage'] }), []);
  const [{ data }] = useVisibleMessagesQuery({ context });
  const [removedAppMessages] = useLocalstorageState<string[]>('removedAppMessages', []);

  const latestMessage = useMemo(() => {
    if (!data || !data.visibleMessages.length) return null;
    const visibleMessage = data.visibleMessages.filter(
      (message) => message && !removedAppMessages?.includes(message.id),
    )[0];
    return visibleMessage ?? null;
  }, [data, removedAppMessages]);

  if (!latestMessage) return null;

  return (
    <Link
      to="/dashboard"
      className={classNames('AppMessageIndicator', {
        'page-notification--error': latestMessage.type === PageNotificationType.ERROR,
        'page-notification--info': latestMessage.type === PageNotificationType.INFO,
        'page-notification--success': latestMessage.type === PageNotificationType.SUCCESS,
        'page-notification--warning': latestMessage.type === PageNotificationType.WARNING,
      })}
    >
      <span className={getNotificationTypeIcon(latestMessage.type)} />
      {t('common.labels.newAppMessage')}
    </Link>
  );
};
