import { useSchemaValidator } from '@module/shared/forms';
import { InferType, object, string } from 'yup';

const schema = object({
  title: string().required(),
  description: string().max(250).nullable(),
  category_id: string().nullable(),
  subject: string().nullable(),
  body: string().nullable(),
  message_type: string().nullable(),
});

export type InterfaceMessageTemplateFormValues = InferType<typeof schema>;

export function useInterfaceMessageTemplateSchema() {
  const validator = useSchemaValidator(schema);
  return { schema, validator };
}
