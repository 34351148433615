import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type DeleteTaskFormQuestionTableRowMutationVariables = Types.Exact<{
  rowId: Types.Scalars['String'];
}>;

export type DeleteTaskFormQuestionTableRowMutation = {
  __typename?: 'Mutation';
  deleteTaskFormQuestionTableRow: { __typename?: 'TaskQuestionTableRow'; question_id: string };
};

export const DeleteTaskFormQuestionTableRowDocument = gql`
  mutation DeleteTaskFormQuestionTableRow($rowId: String!) {
    deleteTaskFormQuestionTableRow(row_id: $rowId) {
      question_id
    }
  }
`;

export function useDeleteTaskFormQuestionTableRowMutation() {
  return Urql.useMutation<
    DeleteTaskFormQuestionTableRowMutation,
    DeleteTaskFormQuestionTableRowMutationVariables
  >(DeleteTaskFormQuestionTableRowDocument);
}
