import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type DeleteTaskSubTaskMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;

export type DeleteTaskSubTaskMutation = {
  __typename?: 'Mutation';
  deleteTaskSubTask: {
    __typename?: 'ResponseMessage';
    success?: boolean | null;
    message?: string | null;
  };
};

export const DeleteTaskSubTaskDocument = gql`
  mutation DeleteTaskSubTask($id: ID!) {
    deleteTaskSubTask(id: $id) {
      success
      message
    }
  }
`;

export function useDeleteTaskSubTaskMutation() {
  return Urql.useMutation<DeleteTaskSubTaskMutation, DeleteTaskSubTaskMutationVariables>(
    DeleteTaskSubTaskDocument,
  );
}
