import {
  Ability,
  AbilityBuilder,
  AbilityClass,
  ForcedSubject,
  subject as subjectHelper,
} from '@casl/ability';
import { useCallback, useMemo } from 'react';

import { Permission, UserRole } from '../../../types/graphql.generated';
import { GenericCan, GenericCanSharedProps } from '../components/GenericCan';
import { ClientAbilityFragment, CurrentUserAbilityFragment } from '../graphql';
import { useMeOrDefault } from '../hooks';

interface ClientSubject {
  me: CurrentUserAbilityFragment;
  client?: ClientAbilityFragment;
}

type ClientSubjectArg = Partial<ClientSubject>;

type Actions =
  | 'viewClients'
  | 'viewClient'
  | 'viewClientCommon'
  | 'viewClientAddress'
  | 'viewClientLocations'
  | 'viewClientContacts'
  | 'viewClientBanking'
  | 'viewClientTaxation'
  | 'viewClientAbsence'
  | 'viewClientPayroll'
  | 'viewClientServiceLevel'
  | 'viewClientQualification'
  | 'viewClientNotificationEmailSettings'
  | 'viewClientAccount'
  | 'viewClientInvoices'
  | 'viewClientInsurance'
  | 'updateClientCommon'
  | 'updateClientAddress'
  | 'updateClientLocations'
  | 'updateClientContacts'
  | 'updateClientBanking'
  | 'updateClientTaxation'
  | 'updateClientAbsence'
  | 'updateClientPayroll'
  | 'updateClientInsurance'
  | 'updateClientServiceLevel'
  | 'updateClientQualification'
  | 'upsertClientQualification'
  | 'updateClientNotificationEmailSettings'
  | 'createClient'
  | 'updateClient'
  | 'deleteClient'
  | 'assignEmailTemplates'
  | 'assignInterfaceMessageTemplates'
  | 'assignTemplatesToDirectMessages';

type Subjects = ClientSubject | 'ClientSubject';

export type ClientAbility = Ability<[Actions, Subjects]>;
const clientAbility = Ability as AbilityClass<ClientAbility>;

export const useClientAbility = (): [
  ClientAbility,
  (sub?: ClientSubjectArg) => ClientSubject & ForcedSubject<'ClientSubject'>,
] => {
  const ability = useMemo(() => {
    const { can, build } = new AbilityBuilder(clientAbility);

    can('viewClients', 'ClientSubject', {
      'me.globalPermissions': { $in: [Permission.CLIENT_READ] },
    });

    can('viewClient', 'ClientSubject', {
      'me.globalPermissions': { $in: [Permission.CLIENT_READ] },
    });

    can('viewClientAccount', 'ClientSubject', {
      'me.globalPermissions': { $in: [Permission.CLIENT_READ] },
      'me.role': { $in: [UserRole.ADMIN] },
      'client.user.id': { $exists: true },
    });
    can('viewClientAbsence', 'ClientSubject', {
      'client.permissions': { $in: [Permission.CLIENT_READ_ABSENCE] },
    });
    can('viewClientAddress', 'ClientSubject', {
      'client.permissions': { $in: [Permission.CLIENT_READ_ADDRESS] },
    });
    can('viewClientBanking', 'ClientSubject', {
      'client.permissions': { $in: [Permission.CLIENT_READ_BANKING] },
    });
    can('viewClientCommon', 'ClientSubject', {
      'client.permissions': { $in: [Permission.CLIENT_READ_COMMON] },
    });
    can('viewClientContacts', 'ClientSubject', {
      'client.permissions': { $in: [Permission.CLIENT_READ_CONTACTS] },
    });
    can('viewClientLocations', 'ClientSubject', {
      'client.permissions': { $in: [Permission.CLIENT_READ_LOCATIONS] },
    });
    can('viewClientPayroll', 'ClientSubject', {
      'client.permissions': { $in: [Permission.CLIENT_READ_PAYROLL] },
    });
    can('viewClientServiceLevel', 'ClientSubject', {
      'client.permissions': { $in: [Permission.CLIENT_READ_SERVICE_LEVEL] },
    });
    can('viewClientTaxation', 'ClientSubject', {
      'client.permissions': { $in: [Permission.CLIENT_READ_TAXATION] },
    });
    can('viewClientQualification', 'ClientSubject', {
      'client.permissions': { $in: [Permission.CLIENT_READ_QUALIFICATIONS] },
    });
    can('viewClientNotificationEmailSettings', 'ClientSubject', {
      'client.permissions': { $in: [Permission.CLIENT_READ_NOTIFICATION_EMAIL_SETTINGS] },
    });
    can('viewClientInvoices', 'ClientSubject', {
      'client.permissions': { $in: [Permission.CLIENT_READ_INVOICES] },
    });
    can('viewClientInsurance', 'ClientSubject', {
      'client.permissions': { $in: [Permission.CLIENT_READ_INSURANCE] },
    });

    can('updateClientAbsence', 'ClientSubject', {
      'client.permissions': { $in: [Permission.CLIENT_UPDATE_ABSENCE] },
    });
    can('updateClientAddress', 'ClientSubject', {
      'client.permissions': { $in: [Permission.CLIENT_UPDATE_ADDRESS] },
    });
    can('updateClientBanking', 'ClientSubject', {
      'client.permissions': { $in: [Permission.CLIENT_UPDATE_BANKING] },
    });
    can('updateClientCommon', 'ClientSubject', {
      'client.permissions': { $in: [Permission.CLIENT_UPDATE_COMMON] },
    });
    can('updateClientContacts', 'ClientSubject', {
      'client.permissions': { $in: [Permission.CLIENT_UPDATE_CONTACTS] },
    });
    can('updateClientLocations', 'ClientSubject', {
      'client.permissions': { $in: [Permission.CLIENT_UPDATE_LOCATIONS] },
    });
    can('updateClientPayroll', 'ClientSubject', {
      'client.permissions': { $in: [Permission.CLIENT_UPDATE_PAYROLL] },
    });
    can('updateClientServiceLevel', 'ClientSubject', {
      'client.permissions': { $in: [Permission.CLIENT_UPDATE_SERVICE_LEVEL] },
    });
    can('updateClientTaxation', 'ClientSubject', {
      'client.permissions': { $in: [Permission.CLIENT_UPDATE_TAXATION] },
    });
    can('updateClientQualification', 'ClientSubject', {
      'client.permissions': { $in: [Permission.CLIENT_UPDATE_QUALIFICATIONS] },
    });
    can('upsertClientQualification', 'ClientSubject', {
      'client.permissions': { $in: [Permission.CLIENT_UPDATE_CLIENT_QUALIFICATION] },
    });
    can('updateClientNotificationEmailSettings', 'ClientSubject', {
      'client.permissions': { $in: [Permission.CLIENT_UPDATE_NOTIFICATION_EMAIL_SETTINGS] },
    });
    can('updateClientInsurance', 'ClientSubject', {
      'client.permissions': { $in: [Permission.CLIENT_UPDATE_INSURANCE] },
    });

    can('createClient', 'ClientSubject', {
      'me.globalPermissions': { $in: [Permission.CLIENT_CREATE] },
    });
    can('updateClient', 'ClientSubject', {
      'me.globalPermissions': { $in: [Permission.CLIENT_UPDATE] },
    });
    can('deleteClient', 'ClientSubject', {
      'me.globalPermissions': { $in: [Permission.CLIENT_DELETE] },
    });
    can('assignEmailTemplates', 'ClientSubject', {
      'me.globalPermissions': { $in: [Permission.CLIENT_ASSIGN_EMAIL_TEMPLATES] },
    });
    can('assignInterfaceMessageTemplates', 'ClientSubject', {
      'me.globalPermissions': { $in: [Permission.CLIENT_ASSIGN_INTERFACE_MESSAGE_TEMPLATES] },
    });
    can('assignTemplatesToDirectMessages', 'ClientSubject', {
      'client.permissions': { $in: [Permission.CLIENT_ASSIGN_TEMPLATES_TO_DIRECT_MESSAGES] },
    });

    return build();
  }, []);

  const me = useMeOrDefault();
  const subject = useCallback(
    (sub?: ClientSubjectArg) => {
      return subjectHelper('ClientSubject', { me, ...sub });
    },
    [me],
  );

  return [ability, subject];
};

interface CanClientProps {
  client?: ClientAbilityFragment;
}

export const CanClient = (props: CanClientProps & GenericCanSharedProps<Actions>) => {
  const [clientAbility, clientSubject] = useClientAbility();
  const { client, ...restProps } = props;

  return (
    <GenericCan<Actions, Subjects, ClientAbility>
      ability={clientAbility}
      subject={clientSubject({ client })}
      {...restProps}
    />
  );
};
