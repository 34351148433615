export default {
  labels: { role: 'Rolle' },
  errors: {
    not_found: {
      title: 'Benutzer nicht gefunden',
      note: 'Der von Ihnen angeforderte Benutzer wurde nicht gefunden',
    },
  },
  notifications: {
    send_qr_code: {
      success: 'QR-Code wurde verschickt!',
    },
    update_user_role: {
      success: 'Benutzer-Rolle geändert!',
    },
    update_user_password: {
      success: 'Passwort wurde geändert!',
    },
    update_user: {
      success: 'Benutzer Details gespeichert!',
    },
    createUser: {
      success: 'Benutzer wurde angelegt!',
    },
    deleteUser: {
      success: 'Benutzer wurde gelöscht!',
    },
    send_email_verification_link: {
      success: 'Bestätigungs-Link wurde verschickt!',
    },
    resendInvitation: {
      success: 'Einladungs-Link wurde verschickt!',
    },
  },
  components: {
    user_change_password_panel: {
      title: 'Passwort ändern',
    },
    user_send_qr_code_panel: {
      title: 'QR-Code senden',
      description: 'Hier können Sie den QR-Code für die Einrichtung der 2FA an den Benutzer senden',
      submit: 'QR-Code senden',
    },
    user_common_panel: {
      title: 'Benutzer Details',
      sections: {
        userDetails: {
          title: 'Benutzer Details',
          description: 'Hier können Sie die Benutzer Details sehen.',
        },
      },
    },
    user_email_verification_panel: {
      title: 'E-Mail Bestätigung',
      submit: 'Bestätigungs-Mail senden',
      send: 'Senden',
      status: {
        verified: 'E-Mail Adresse bestätig.',
        unverified: 'Die E-Mail Adresse muss noch bestätigt werden.',
      },
    },
    user_update_panel: {
      title: 'Benutzer Details',
    },
    update_user_role_panel: {
      title: 'Benutzerrolle ändern',
    },
  },
  dialogs: {
    createUser: {
      title: 'Benutzer anlegen',
      note: 'Erstelle einen neuen Benutzer-Account.',
    },
    deleteUser: {
      title: 'Benutzer löschen',
      note: 'Möchten Sie den Benutzer wirklich löschen?',
    },
  },
  pages: {
    overview: {
      title: 'Benutzer - Übersicht',
      description:
        'Hier sehen Sie eine Liste der existierenden Benutzer und deren Zugriffsberechtigungen.',
      toolbar: {
        create: 'Neuen Benutzer',
      },
    },
    detail: {
      title: 'Benutzer - {{user_id}}',
      description:
        'Hier können Sie die Benutzer-Daten z.b.(Passwörter, Benutzerrolle, Name, usw.) bearbeiten.',
      tabs: {
        common: 'Allgemein',
        password: 'Passwort',
        verify_email: 'E-Mail Bestätigung',
        role: 'Benutzerrolle',
      },
    },
  },
};
