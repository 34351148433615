import { createContext, ReactNode, useCallback, useContext, useState } from 'react';
import { createPortal } from 'react-dom';

type PageSlotType = 'left';

interface PageContextData {
  getElement: (slot: PageSlotType) => HTMLDivElement | null;
  setElement: (slot: PageSlotType, element: HTMLDivElement | null) => void;
  hasSidebar: boolean;
}

const PageContext = createContext<PageContextData | null>(null);

export function usePageContext() {
  return useContext(PageContext);
}

export function usePageSlot(slot: PageSlotType) {
  const pageContext = useContext(PageContext);

  const element = pageContext?.getElement(slot) ?? null;
  const setElement = useCallback(
    (element: HTMLDivElement | null) => {
      pageContext?.setElement(slot, element);
    },
    [pageContext, slot],
  );

  return { element, setElement };
}

export interface PageContextProviderProps {
  children?: ReactNode | undefined;
  hasSidebar: boolean;
}

export const PageContextProvider = (props: PageContextProviderProps) => {
  const [elementLeft, setElementLeft] = useState<HTMLDivElement | null>(null);

  const getElement = useCallback(
    (slot: PageSlotType) => {
      switch (slot) {
        case 'left':
          return elementLeft;
        default:
          return null;
      }
    },
    [elementLeft],
  );

  const setElement = useCallback((slot: PageSlotType, element: HTMLDivElement | null) => {
    switch (slot) {
      case 'left':
        setElementLeft(element);
        break;
    }
  }, []);

  const contextData = {
    getElement,
    setElement,
    hasSidebar: props.hasSidebar,
  };

  return <PageContext.Provider value={contextData}>{props.children}</PageContext.Provider>;
};

export interface PageSlotProps {
  children?: ReactNode | undefined;
  slot: PageSlotType;
}

export const PageSlot = (props: PageSlotProps) => {
  const { element } = usePageSlot(props.slot);

  if (!element) {
    return null;
  }
  return createPortal(<>{props.children}</>, element);
};
