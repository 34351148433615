export default {
  labels: {
    area: 'area',
    residents: 'Inhabitants',
    client_primary: 'AR1',
    client_secondary: 'AR2',
    client_tertiary: 'AR3',
    client_leader: 'team leader',
  },
  dialogs: {
    update: {
      title: 'Edit assignment area',
      note: 'Here you can set the payers and team leaders for the assignment area.',
    },
    import: {
      title: 'Import assignments',
      downloadHint: 'Download sample table',
      import: 'Import',
    },
  },
  pages: {
    overview: {
      title: 'Allocation areas',
      description: 'Here you can see a list of postcode areas and the respective assigned clerks',
      list: 'List',
      map: 'Map',
      import: 'Import',
      export: 'Export',
    },
  },
  notifications: {
    update_area_assignment: {
      success: 'Assignment area has been saved.',
    },
  },
};
