import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type UpdateClientNotificationSettingsMutationVariables = Types.Exact<{
  client_id: Types.Scalars['ID'];
  notification_settings:
    | Array<Types.ClientNotificationSettingsInput>
    | Types.ClientNotificationSettingsInput;
}>;

export type UpdateClientNotificationSettingsMutation = {
  __typename?: 'Mutation';
  updateClientNotificationSettings?: {
    __typename?: 'Client';
    id: string;
    notification_settings: {
      __typename?: 'CombinedNotificationSettingsList';
      id: string;
      data: Array<{
        __typename?: 'CombinedNotificationSetting';
        type_id: Types.NotificationTypeId;
        internal: boolean;
        email: boolean;
        sms: boolean;
        push: boolean;
      }>;
    };
  } | null;
};

export const UpdateClientNotificationSettingsDocument = gql`
  mutation UpdateClientNotificationSettings(
    $client_id: ID!
    $notification_settings: [ClientNotificationSettingsInput!]!
  ) {
    updateClientNotificationSettings(
      client_id: $client_id
      input: { notification_settings: $notification_settings }
    ) {
      id
      notification_settings {
        id
        data {
          type_id
          internal
          email
          sms
          push
        }
      }
    }
  }
`;

export function useUpdateClientNotificationSettingsMutation() {
  return Urql.useMutation<
    UpdateClientNotificationSettingsMutation,
    UpdateClientNotificationSettingsMutationVariables
  >(UpdateClientNotificationSettingsDocument);
}
