import {
  Ability,
  AbilityBuilder,
  AbilityClass,
  ForcedSubject,
  subject as subjectHelper,
} from '@casl/ability';
import { useCallback, useMemo } from 'react';

import { Permission } from '../../../types/graphql.generated';
import { GenericCan, GenericCanSharedProps } from '../components/GenericCan';
import { AppSettingsAbilityFragment, CurrentUserAbilityFragment } from '../graphql';
import { detectSubjectTypeByTypename as detectSubjectType } from '../helpers';
import { useMeOrDefault } from '../hooks';

interface AppSettingsSubject {
  me: CurrentUserAbilityFragment;
  appSettings?: AppSettingsAbilityFragment;
}

type AppSettingsSubjectArg = Partial<AppSettingsSubject>;

export type Actions =
  | 'viewAppSettings'
  | 'updateAssignments'
  | 'updateGeneral'
  | 'updateLogin'
  | 'updateColors'
  | 'updateEmail'
  | 'updateImages'
  | 'updateModules'
  | 'updateNotifications'
  | 'updatePageSettings'
  | 'updateMapBounds'
  | 'updatePushNotifications'
  | 'updateTasksSettings'
  | 'updateInvoicesSettings'
  | 'viewStamps'
  | 'createStamps'
  | 'updateStamps'
  | 'deleteStamps'
  | 'viewAppMessages'
  | 'createAppMessages'
  | 'updateAppMessages'
  | 'deleteAppMessages'
  | 'viewAppModules'
  | 'updateAppModules'
  | 'createAppAllowlist'
  | 'deleteAppAllowlist'
  | 'viewAppAllowlist'
  | 'toggleAppAllowlist';

export type Subjects = AppSettingsSubject | 'AppSettingsSubject';

export type AppSettingsAbility = Ability<[Actions, Subjects]>;
const appSettingsAbility = Ability as AbilityClass<AppSettingsAbility>;

export const useAppSettingsAbility = (): [
  AppSettingsAbility,
  (sub?: AppSettingsSubjectArg) => AppSettingsSubject & ForcedSubject<'AppSettingsSubject'>,
] => {
  const ability = useMemo(() => {
    const { can, build } = new AbilityBuilder(appSettingsAbility);

    can('viewAppSettings', 'AppSettingsSubject', {
      'me.globalPermissions': { $in: [Permission.APP_SETTINGS_READ] },
    });
    can('updateGeneral', 'AppSettingsSubject', {
      'me.globalPermissions': { $in: [Permission.APP_SETTINGS_UPDATE] },
    });
    can('updateLogin', 'AppSettingsSubject', {
      'me.globalPermissions': { $in: [Permission.APP_SETTINGS_UPDATE] },
    });
    can('updateColors', 'AppSettingsSubject', {
      'me.globalPermissions': { $in: [Permission.APP_SETTINGS_UPDATE_COLORS] },
    });
    can('updateEmail', 'AppSettingsSubject', {
      'me.globalPermissions': { $in: [Permission.APP_SETTINGS_UPDATE_EMAIL] },
    });
    can('updateImages', 'AppSettingsSubject', {
      'me.globalPermissions': { $in: [Permission.APP_SETTINGS_UPDATE_IMAGES] },
    });
    can('updateModules', 'AppSettingsSubject', {
      'me.globalPermissions': { $in: [Permission.APP_SETTINGS_UPDATE_MODULES] },
    });
    can('updateNotifications', 'AppSettingsSubject', {
      'me.globalPermissions': { $in: [Permission.APP_SETTINGS_UPDATE_NOTIFICATIONS] },
    });
    can('updatePageSettings', 'AppSettingsSubject', {
      'me.globalPermissions': { $in: [Permission.APP_SETTINGS_UPDATE_PAGE_SETTINGS] },
    });
    can('updateAssignments', 'AppSettingsSubject', {
      'me.globalPermissions': { $in: [Permission.APP_SETTINGS_UPDATE_ASSIGNMENT_SETTINGS] },
    });
    can('updateMapBounds', 'AppSettingsSubject', {
      'me.globalPermissions': { $in: [Permission.APP_SETTINGS_UPDATE_MAP_BOUNDS_SETTINGS] },
    });
    can('updatePushNotifications', 'AppSettingsSubject', {
      'me.globalPermissions': { $in: [Permission.APP_SETTINGS_UPDATE_PUSH_NOTIFICATION_SETTINGS] },
    });
    can('updateTasksSettings', 'AppSettingsSubject', {
      'me.globalPermissions': { $in: [Permission.APP_SETTINGS_UPDATE_TASKS_SETTINGS] },
    });
    can('updateInvoicesSettings', 'AppSettingsSubject', {
      'me.globalPermissions': { $in: [Permission.APP_SETTINGS_UPDATE_INVOICES_SETTINGS] },
    });
    can('viewStamps', 'AppSettingsSubject', {
      'me.globalPermissions': { $in: [Permission.APP_SETTINGS_READ_STAMPS] },
    });
    can('createStamps', 'AppSettingsSubject', {
      'me.globalPermissions': { $in: [Permission.APP_SETTINGS_CREATE_STAMPS] },
    });
    can('updateStamps', 'AppSettingsSubject', {
      'me.globalPermissions': { $in: [Permission.APP_SETTINGS_UPDATE_STAMPS] },
    });
    can('deleteStamps', 'AppSettingsSubject', {
      'me.globalPermissions': { $in: [Permission.APP_SETTINGS_DELETE_STAMPS] },
    });

    // APP MESSAGES
    can('viewAppMessages', 'AppSettingsSubject', {
      'me.globalPermissions': { $in: [Permission.APP_MESSAGES_INDEX] },
    });
    can('createAppMessages', 'AppSettingsSubject', {
      'me.globalPermissions': { $in: [Permission.APP_MESSAGES_CREATE] },
    });
    can('updateAppMessages', 'AppSettingsSubject', {
      'me.globalPermissions': { $in: [Permission.APP_MESSAGES_UPDATE] },
    });
    can('deleteAppMessages', 'AppSettingsSubject', {
      'me.globalPermissions': { $in: [Permission.APP_MESSAGES_DELETE] },
    });

    // APP ALLOW LIST
    can('createAppAllowlist', 'AppSettingsSubject', {
      'me.globalPermissions': { $in: [Permission.APP_ALLOWLIST_CREATE] },
    });
    can('deleteAppAllowlist', 'AppSettingsSubject', {
      'me.globalPermissions': { $in: [Permission.APP_ALLOWLIST_DELETE] },
    });
    can('viewAppAllowlist', 'AppSettingsSubject', {
      'me.globalPermissions': { $in: [Permission.APP_ALLOWLIST_INDEX] },
    });
    can('toggleAppAllowlist', 'AppSettingsSubject', {
      'me.globalPermissions': { $in: [Permission.APP_ALLOWLIST_TOGGLE] },
    });

    // APP MODULES
    can('viewAppModules', 'AppSettingsSubject', {
      'me.globalPermissions': { $in: [Permission.APP_MODULES_INDEX] },
    });
    can('updateAppModules', 'AppSettingsSubject', {
      'me.globalPermissions': { $in: [Permission.APP_MODULES_UPDATE] },
    });

    return build({ detectSubjectType });
  }, []);

  const me = useMeOrDefault();
  const subject = useCallback(
    (sub?: AppSettingsSubjectArg) => {
      return subjectHelper('AppSettingsSubject', { me, ...sub });
    },
    [me],
  );

  return [ability, subject];
};

type AppSettingsRouteProps = GenericCanSharedProps<Actions>;

export const CanAppSettings = (props: AppSettingsRouteProps) => {
  const [appSettingsAbility, appSettingsSubject] = useAppSettingsAbility();

  return (
    <GenericCan<Actions, Subjects, AppSettingsAbility>
      ability={appSettingsAbility}
      subject={appSettingsSubject()}
      {...props}
    />
  );
};
