export default {
  labels: {
    yes: 'Ja',
    no: 'Nein',
    makePayout: 'Neue Auszahlungen berechnen',
    addPayout: 'Neuer Eintrag',
    enableMultiselect: 'Mehrfachauswahl aktivieren',
    disableMultiselect: 'Mehrfachauswahl deaktivieren',
    markAsPaid: 'Als Bezahlt markieren',
    selectedPayoutsCount: '{{count}} Auszahlung ausgewählt',
    exportPayoutAsXLSX: 'Abrechnung als XLSX exportieren',
  },
  pages: {
    payouts: {
      title: 'Auszahlungen',
      description: 'Hier finden Sie eine Übersicht über alle Auszahlungen.',
      filter: {
        payoutId: 'Auszahlung {{id}}',
      },
      grid: {
        columns: {
          invoiceId: 'Rechnungsnummer',
          taskId: 'Für Auftrag',
          insurance: 'Versicherung',
          clientName: 'Empfänger',
          sumNet: 'Betrag',
          stateId: 'Status',
          payoutDate: 'Bezahlt am',
          payout: 'Auszahlung',
          title: 'Titel',
        },
        actions: {
          markAsPaid: {
            label: 'Als Bezahlt markieren',
          },
          cancel: {
            label: 'Stornieren',
          },
          edit: {
            label: 'Bearbeiten',
          },
        },
      },
    },
  },
  notifications: {
    createPayout: {
      success: 'Auszahlung erfolgreich erzeugt.',
      error: 'Leider ist ein Fehler in der Auszahlung aufgetreten.',
    },
    addPayout: {
      success: 'Abrechnung erfolgreich erzeugt.',
    },
  },
  dialogs: {
    createPayout: {
      title: 'Neue Auszahlung',
      note: 'Damit erzeugen Sie eine neue Auszahlung. Sämtliche Rechnungen, welche noch zu keiner Auszahlung gehören, werden für diese Auszahlung herangezogen. Die Einstellungen für Auszahlungen bei den Stammdaten für Regulierer werden hierbei herangezogen. Wollen Sie eine neue Auszahlung starten?',
    },
    addPayout: {
      title: 'Abrechnung erstellen',
      note: 'Erstellen Sie manuell eine neue Abrechnung. Dafür müssen den betreffenden Teamleiter oder Regulierer angeben und die dazugehörige Rechnung eintrage. Beträge können im vergleich zu den automatisch erstellten Abrechnungen manuell eingegeben werden und werden nicht auf Basis der Stammdateneintellungen berechnet.',
      form: {
        title: 'Titel',
        invoiceId: 'Rechnung',
        clientId: 'Regulierer / Teamleiter',
        sumNet: 'Betrag (Netto)',
      },
    },
    cancelPayout: {
      title: 'Auszahlung stornieren',
      note: 'Sind Sie sicher, dass Sie die Auszahlung stornieren möchten?',
    },
    updatePayout: {
      title: 'Auszahlung bearbeiten',
      note: 'Hier können Sie die Auszahlung bearbeiten.',
      form: {
        title: 'Titel',
        sumNet: 'Betrag (Netto)',
      },
    },
    confirmMarkAsPaid: {
      title: 'Auszahlung(en) als bezahlt markieren?',
      note: 'Sind Sie sicher, dass sie die Auszahlung(en) als bezahlt markieren möchten?',
    },
    exportPayouts: {
      title: 'Auszahlungen exportieren',
      description: 'Laden Sie die exportierten Auszahlungen herunter.',
    },
  },
  components: {
    invoiceSelect: {
      renderedValue: 'Rechnung #{{identifier}} - {{sum}}',
      columns: {
        identifier: 'Rechnungsnummer',
        taskId: 'Auftrag',
        clientName: 'Versicherung',
        externalNumber: 'Schadennummer',
        sumNet: 'Summe (Netto)',
      },
    },
  },
};
