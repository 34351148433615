import { FieldWrapper } from '@progress/kendo-react-form';
import { RadioGroup, RadioGroupProps } from '@progress/kendo-react-inputs';
import { Ripple } from '@progress/kendo-react-ripple';
import { useId } from 'react';

import { Label } from '../components/Label';
import { FieldError } from './FieldError';
import { FieldHint } from './FieldHint';
import { CommonFieldRenderProps } from './types';

export interface RadioGroupOption {
  value?: string | null;
  label: string;
}

export type RadioGroupInputProps = CommonFieldRenderProps &
  Pick<RadioGroupProps, 'id' | 'value' | 'layout' | 'disabled' | 'onChange' | 'onFocus'> & {
    options?: Array<RadioGroupOption>;
  };

export const RadioGroupInput = (props: RadioGroupInputProps) => {
  const defaultId = useId();
  const {
    id = defaultId,
    label,
    valid,
    value,
    options,
    layout = 'horizontal',
    disabled,
    onChange,
    onFocus,
    name,
    tooltip,
    tooltipPosition,
  } = props;
  const labelId = label && `${id}_label`;

  return (
    <Ripple className="Ripple">
      <FieldWrapper>
        {label && (
          <Label
            id={labelId}
            tooltip={tooltip}
            tooltipPosition={tooltipPosition}
            label={label}
            name={name}
            editorId={id}
            editorValid={valid}
          >
            {label}
          </Label>
        )}

        <RadioGroup
          id={id}
          ariaLabelledBy={labelId}
          value={value}
          data={options}
          layout={layout}
          disabled={disabled}
          onChange={onChange}
          onFocus={onFocus}
        />
        <div className="HintAndError">
          <FieldHint {...props} />
          <FieldError {...props} />
        </div>
      </FieldWrapper>
    </Ripple>
  );
};
