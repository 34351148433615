import { useMemo } from 'react';

import {
  ContactFunctionsQuery,
  useContactFunctionsQuery,
} from '../graphql/generated/useFunctionsQuery.generated';

export type ContactFunctionItem = ContactFunctionsQuery['contactFunctions'][number];

export function useContactFunctions() {
  const [{ data, fetching: isLoading }] = useContactFunctionsQuery();
  const contactFunctions = useMemo(() => data?.contactFunctions ?? [], [data?.contactFunctions]);
  return { contactFunctions, isLoading };
}
