import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
export type TaskSubTaskTemplateFragment = {
  __typename?: 'TaskSubTaskTemplate';
  id: string;
  title: string;
  description: string;
  finish_date_default_days: number;
};

export const TaskSubTaskTemplateFragmentDoc = gql`
  fragment TaskSubTaskTemplate on TaskSubTaskTemplate {
    id
    title
    description
    finish_date_default_days
  }
`;
