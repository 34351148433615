import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import { ClientAbsenceFieldsFragmentDoc } from './clientAbsenceFragment.generated';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CreateClientAbsenceMutationVariables = Types.Exact<{
  client_id: Types.Scalars['ID'];
  input: Types.CreateClientAbsenceInput;
}>;

export type CreateClientAbsenceMutation = {
  __typename?: 'Mutation';
  createClientAbsence?: {
    __typename?: 'ClientAbsence';
    client_id: string;
    from: string;
    id: string;
    reason: string;
    to: string;
  } | null;
};

export const CreateClientAbsenceDocument = gql`
  mutation CreateClientAbsence($client_id: ID!, $input: CreateClientAbsenceInput!) {
    createClientAbsence(client_id: $client_id, input: $input) {
      ...ClientAbsenceFields
    }
  }
  ${ClientAbsenceFieldsFragmentDoc}
`;

export function useCreateClientAbsenceMutation() {
  return Urql.useMutation<CreateClientAbsenceMutation, CreateClientAbsenceMutationVariables>(
    CreateClientAbsenceDocument,
  );
}
