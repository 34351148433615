export default {
  email: 'Unesite valjanu adresu e-pošte, npr. petar.petric@example.com',
  firstname: 'Ime treba imati najmanje 4 znaka',
  required: 'Ovo polje ne može biti prazno.',
  mixed: {
    required: 'Ovo polje ne može biti prazno.',
    notType: 'Polje je nevažeće.',
  },
  string: {
    email: 'Unesite valjanu adresu e-pošte, npr. petar.petric@example.com',
    min: 'Mora imati najmanje {{min}} znakova',
    max: 'Ovo polje može sadržavati najviše {{max}} znakova',
    length: 'Mora sadržavati {{length}} znakova.',
    trim: 'Ne smije počinjati ni završavati razmacima.',
    rgb: 'Mora biti u RGB formatu, npr. #FFFFFF.',
    rgba: 'Mora biti u RGBA formatu, npr. #FFFFFFFF.',
  },
  number: {
    integer: 'Mora biti cijeli broj.',
    min: 'Mora biti najmanje {{min}}.',
    max: 'Ne smije premašiti {{max}}.',
  },
  uploadFileInfo: 'Nevažeća datoteka(e). Pregledajte zahtjeve za učitavanje datoteka.',
  password: {
    length: 'Najmanje {{min}} znakova.',
    special: 'Najmanje {{min}} posebnih znakova (npr. !@#$%^&*-).',
    numbers: 'Najmanje {{min}} brojeva (npr. 0-9)',
    uppercase: 'Najmanje {{min}} velikih slova.',
    confirmation: 'Ponovi lozinku. Moraju odgovarati',
    strength: 'Lozinka je preslaba.',
    chain: {
      general: 'Lozinka mora sadržavati sljedeće: {{lanac}}',
      length: '{{min}} znakova',
      special: '{{min}} posebnih znakova (npr. !@#$%^&*-).',
      numbers: '{{min}} broj(ova) (npr. 0-9)',
      uppercase: '{{min}} veliko slovo(a).',
    },
  },
  array: {
    min: 'Mora sadržavati najmanje {{min}} element(a).',
    max: 'Može sadržavati najviše {{max}} element(a).',
  },
};
