import { useDetectNavigation } from '@module/shared/hooks';
import { useCallback, useMemo, useState } from 'react';

import { useBooleanState, useMediaQueries } from '../../hooks';
import { DrawerContextType } from './types';
import { usePersistedDrawerState } from './usePersistedDrawerState';

export function useDrawerContextData(): DrawerContextType {
  const [persistedDrawerState, setPersistedDrawerState] = usePersistedDrawerState();

  const setExpanded = useCallback(
    (expandedNew: boolean) => {
      const { expanded: _, ...rest } = persistedDrawerState;
      setPersistedDrawerState({ ...rest, expanded: expandedNew });
    },
    [persistedDrawerState, setPersistedDrawerState],
  );

  const toggleExpanded = useCallback(() => {
    setExpanded(!persistedDrawerState.expanded);
  }, [persistedDrawerState.expanded, setExpanded]);

  const expanded = persistedDrawerState.expanded;

  const { lgUp } = useMediaQueries();
  const layout = lgUp ? 'desktop' : 'mobile';

  const [mobileExpanded, enableMobileExpanded, disableMobileExpanded, toggleMobileExpanded] =
    useBooleanState();

  const [sidebarSlot, setSidebarSlot] = useState<HTMLDivElement | null>(null);
  const [hasSidebar, setHasSidebar] = useState(false);
  const registerSidebar = useCallback(() => {
    setHasSidebar(true);
    return () => setHasSidebar(false);
  }, []);

  useDetectNavigation(() => {
    disableMobileExpanded();
  });

  return useMemo(
    () => ({
      layout,
      toggleExpanded,
      expanded,
      enableMobileExpanded,
      disableMobileExpanded,
      toggleMobileExpanded,
      mobileExpanded,
      hasSidebar,
      registerSidebar,
      sidebarSlot,
      setSidebarSlot,
    }),
    [
      layout,
      toggleExpanded,
      expanded,
      enableMobileExpanded,
      disableMobileExpanded,
      toggleMobileExpanded,
      mobileExpanded,
      hasSidebar,
      registerSidebar,
      sidebarSlot,
      setSidebarSlot,
    ],
  );
}
