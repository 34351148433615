import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type AssignTaskMembersMutationVariables = Types.Exact<{
  members: Array<Types.TaskMemberInput> | Types.TaskMemberInput;
}>;

export type AssignTaskMembersMutation = {
  __typename?: 'Mutation';
  assignTaskMembers?: {
    __typename?: 'TasksBatchResponseMessage';
    message: string;
    success: boolean;
    tasks: Array<{ __typename?: 'Task'; id: string }>;
  } | null;
};

export const AssignTaskMembersDocument = gql`
  mutation AssignTaskMembers($members: [TaskMemberInput!]!) {
    assignTaskMembers(members: $members) {
      message
      success
      tasks {
        id
      }
    }
  }
`;

export function useAssignTaskMembersMutation() {
  return Urql.useMutation<AssignTaskMembersMutation, AssignTaskMembersMutationVariables>(
    AssignTaskMembersDocument,
  );
}
