export default {
  components: {
    templateDesignerToolbar: {
      title: 'Form Designer',
      buttons: {
        designer: 'Designer',
        grid: 'Table',
        json: 'JSON',
        debug: 'Debug',
      },
      extended: {
        import: 'Import from Rocketform',
        internalActivate: 'Enable all questions for internal report',
        internalDeactivate: 'Disable all questions for internal report',
        externalActivate: 'Activate all questions for external report',
        externalDeactivate: 'Deactivate all questions for external report',
      },
      saveStatus: {
        saved: 'Saved at',
        unsaved: 'Unsaved changes',
      },
    },
    templateRenderer: {
      noRecords: 'No records were found for your search',
      labels: {
        title: 'Insert an H1 heading to start with a new form.',
      },
    },
    templatePreview: {
      title: 'Preview',
      description: 'This is a preview of the input mask in tasks',
      alert: 'The table width must be 100%, please check your entries on the left.',
      setTableDefaultValuesSuccess: 'Default values have been saved',
      table: {
        addNew: 'New Entry',
        saveDefaultValues: 'Save default values',
        deleteRowConfirm: {
          title: 'Delete entry?',
          description: 'Do you really want to delete this entry?',
        },
      },
      maxLength: 'Text may be a maximum of {{length}} characters long.',
    },
    toolbar: {
      textField: 'Text',
      selectField: 'Selection',
      dateField: 'Date',
      dateTimeField: 'Date & Time',
      timeField: 'Time',
      numericField: 'Numbers',
      yesNoField: 'Yes/No',
      headlineField: 'Headline',
      tableField: 'Table',
      richtextField: 'Richtext',
      imageField: 'Images',
      subHeadlineField: 'Sub-heading',
      paste: 'Paste',
    },
    emptyDropZone: {
      title: 'No entries found',
      description: 'Here you can drop elements from the left to build the form.',
    },
    emptyPreview: {
      title: 'No preview possible yet',
      description: 'Here the preview is displayed as soon as there are elements in the form.',
    },
    assignAllTemplates: {
      title: 'Report question assignment',
      description:
        'Attention, this will assign all forms for all customers. Do you want to continue?',
    },
    imageColorPicker: {
      title: 'Choose a color',
    },
    pageBreakIndicator: {
      pageBreak: 'Page break',
    },
  },
  dialogs: {
    templateImport: {
      title: 'Import report questions',
      note: 'Here you can upload a templates.json file from Rocketform to import the form data.',
      jsonCode: "Here you can copy Rocketform's JSON code to import the content of the form.",
      save: 'Import',
      cancel: 'Cancel',
    },
    templateDelete: {
      title: 'Delete this question template?',
      note: 'Do you really want to delete this question template?',
    },
    templateEdit: {
      title: 'Edit question template?',
      note:
        'Do you really want to edit this question template?\n' +
        ' A copy will be created in the process',
    },
    templateSaveAsCopy: {
      title: 'Save question template as a copy?',
      note:
        'Do you really want to save this question template?\n' +
        ' A copy will be created in the process',
    },
    templateUpdate: {
      reportingTitle: 'Update reporting questions',
      statisticsTitle: 'Update statistics questions',
      all: 'All insurances',
      active: 'Active for these insurances',
    },
    templateCreate: {
      reportingTitle: 'Create reporting questions',
      statisticsTitle: 'Create statistics questions',
      tabs: {
        general: 'General',
        assignment: 'Assignment',
      },
      form: {
        shortcut: 'Shortcut',
        identifier: 'Internal identifier',
        colour: 'Colour',
        fieldset: 'Template',
        active: 'Active',
        types: {
          label: 'Type',
          form: 'Form',
          statistic: 'Statistics',
        },
        classification: {
          label: 'Classification',
          property: 'Property',
          liability: 'Liability',
          business: 'Business',
        },
        showTimeValueCalculation: 'enable time value calculation',
        category: {
          label: 'Standard Calculation Categories',
          description:
            'Here you can define standard calculation categories, which are immediately available for a new calculation of the selected formulator.',
        },
        enableWordConvert: 'Enable MS-Word Download',
        color: 'Color',
        type: 'Type',
        sum_alias: ' Sum alias',
        accepted_alias: 'Accepts alias',
        time_value_alias: 'Time Alias',
        status_alias: 'Status Alias',
        defaultSupervisorId: 'Predefined team leader',
      },
      values: {
        form: 'Form',
        additional: 'Subform',
      },
    },
    templateAssign: {
      title: 'Change report questions assignment.',
      note: 'Here you can assign the report questions to the insurances.',
    },
    confirmDebug: {
      title: 'Debug form',
      note: 'Do you really want to add the missing identifier everywhere in this form?',
    },
  },
  grid: {
    fields: {
      title: 'Title',
      description: 'Description',
      mandatory: 'Pfichtfeld',
      identifier: 'Identifier',
      type: 'Type',
      selectables: 'Answers',
    },
    toolbar: {
      exportExcel: 'Export Excel',
    },
  },
  pages: {
    breadcrumbs: {
      STATISTICS: 'Reporting Questions',
      PRIMARY: 'Primary form',
      ADDITIONAL: 'Subform',
    },
    overview: {
      create: 'create template',
      reportingTitle: 'Reporting Questions',
      statisticsTitle: 'Statistics Questions',
      reportingDescription: 'Here you will find a list of all reporting questions.',
      statisticsDescription: 'Here you will find a list of all statistics questions.',
      showInactive: 'show inactive',
      designer: 'Designer',
      import: 'Import',
      export: 'Export',
      grid: {
        titlePostfix: 'copy',
        columns: {
          id: 'ID',
          title: 'Name',
          colour: 'Colour',
          version: 'Version',
          stateId: 'Status',
          shortcut: 'Shortcut',
          identifier: 'Internal ID',
          description: 'Description',
          state: 'Status',
          classification: 'Classification',
          actions: 'Actions',
          color: 'Color',
          insurance: 'Insurer',
          template: 'Template',
          type: 'Type',
          updated_at: 'Last change on',
          usage_count: 'Usage count',
        },
        values: {
          active: 'Active',
          notActive: 'Not Active',
          all: 'All',
          none: 'None',
          form: 'Form',
          statistics: 'Statistics',
          additional: 'Subform',
        },
      },
      assignAll: 'Assign all forms for all customers',
    },
    designer: {
      title: 'Form Designer',
      viewNotificationTitle: 'View mode',
      viewNotificationDescription:
        'You are currently in view mode where you can view and check the form. Changes are not saved.',
    },
  },
  fields: {
    connectionActivates: 'Activated:',
    table: {
      operations: {
        none: 'None',
        sum: 'Sum',
        average: 'Average',
      },
      types: {
        text: 'Text',
        number: 'Number',
        currency: 'Currency',
        select: 'Select',
      },
      cols: {
        headline: 'Columns',
        type: {
          label: 'Type',
        },
        operation: {
          label: 'Operation',
        },
        name: {
          label: 'Title',
          placeholder: 'Enter value',
        },
        size: {
          label: 'Size',
        },
        selectOptions: {
          label: 'Selection fields',
          placeholder: 'Enter value separated by semicolon',
        },
      },
      addCol: 'add Column',
      alert: 'The table width must add up to 100%, please check your size entries.',
      tax: {
        label: 'Taxes',
        tooltip:
          'This will automatically add another VAT: and Gross line in the report, calculating the VAT and Gross total automatically.',
      },
    },
    additionalSettings: {
      button: 'Extended Settings',
      key: {
        label: 'Identifier',
        placeholder: 'value',
        notUnique: 'Identifier is not unique',
      },
      value: {
        label: 'Default Text',
        placeholder: 'value',
        numberLabel: 'Predefined number',
      },
      copy: 'Copy question',
      footer: {
        label: 'Report footer',
        description: 'This text is automatically displayed in the report below the answer.',
      },
      alternateQuestionTitle: {
        label: 'Alternative question title for report',
        description:
          'Here you can overwrite the question title to display an alternative title for the question in the report.',
      },
      showNow: {
        label: 'Automatically enter current date / time',
      },
    },
    validation: {
      headline: 'Validations',
      addValidation: 'add Validation',
      notImplemented: 'Validator is not yet implemented.',
      copy: 'Copy question',
      minMax: {
        min: {
          label: 'Min',
          placeholder: 'Min',
        },
        max: {
          label: 'Max',
          placeholder: 'Max',
        },
      },
      select: {
        label: 'Validator type',
        options: {
          minMax: 'Min/Max',
        },
      },
      doubleHeadline:
        'Attention, a main heading (H1) without questions will not be displayed in the report if another main heading is entered in the designer directly below it.',
      title: 'Title must not be empty!',
    },
    common: {
      new: 'New Question',
      new_clean: 'new Question',
      title: {
        label: 'Title',
      },
      description: {
        label: 'Description',
      },
      mandatory: {
        label: 'Mandatory',
      },
      pageBreak: {
        label: 'Page break',
      },
      customerId: {
        label: 'Customer report',
      },
      summary: {
        label: 'Add into Summary',
      },
      internal: {
        label: 'Intern',
      },
      hideReport: {
        label: 'Hide in report',
      },
    },
    numeric: {
      integer: 'Integer',
      currency: 'Add currency sign',
    },
    yesNo: {
      options: {
        yes: 'Yes',
        no: 'No',
      },
    },
    image: {
      allowMultiple: 'Allow multiple photos',
      allowImageDescription: 'Allow photo description',
      addRequirement: 'Add requirement',
      assigned: 'Assigned',
      notAssigned: 'Not assigned',
      grid: {
        status: 'Status',
        image: 'Image',
        description: 'Description',
        assignment: 'Assignment',
      },
      dialogs: {
        updateDescription: {
          title: 'Edit description',
          description: 'Enter your image description here',
        },
        delete: {
          title: 'Delete image',
          description: 'Do you really want to delete this image?',
        },
      },
      notifications: {
        updateDescriptionSuccess: 'Description has been saved',
        deleteSuccess: 'Image has been deleted',
      },
    },
    select: {
      multiple: 'Multiple selection',
      connectedValues: {
        hint: 'If you link questions together, the linked question will not appear until the choice you made has been selected.',
        title: 'Select question(s) to link',
        fieldSearch: {
          placeholder: 'Search for question...',
          label: 'Question title',
        },
        done: 'Close',
      },
      editConnectedValues: 'Linked',
      addOption: {
        button: 'Add question',
        label: 'Question',
      },
      options: {
        header: 'questions',
      },
      connectedValuesWeight: {
        hint: 'Here you can enter a weighting of questions, thus a score is automatically created via a form depending on the selection options. On the basis of this "Scoring" can e.g..: Results can be drawn from the report.',
        title: 'Select weighting of the question(s)',
        done: 'Ok',
        input: {
          label: 'Points',
        },
      },
      renderDropdown: 'Show as dropdown',
      hiddenAnswers: {
        hint: 'You can hide this question if specific answers are selected. You can configure this here.',
        title: 'Choose answers to hide',
        done: 'Ok',
        input: {
          label: 'Hide and seek',
        },
      },
    },
    text: {
      mask: 'Mask',
      maskValidationMessage: 'Input does not correspond to the format {{mask}} -',
      minLengthValidation: 'Text must be at least {{min}} characters long.',
      maxLengthValidation: 'Text may be a maximum of {{max}} characters long.',
      textFieldType: {
        label: 'Field type',
        options: {
          text: 'Text',
          mask: 'Text with mask',
          email: 'e-mail',
          phone: 'Phone number',
        },
      },
    },
  },
  notifications: {
    templateCreate: {
      success: 'Template created successfully.',
      error: 'Something went wrong.',
    },
    templateDelete: {
      success: 'Templates sucessfully deleted',
    },
    templateDuplicate: {
      success: 'Template sucessfully duplicated',
    },
    templateEdit: {
      success: 'Template wurde dupliziert.',
    },
    templateUpdate: {
      success: 'Template updated successfully.',
      error: 'Template could not be saved.',
    },
    templateAssign: {
      success: 'Template successfully assigned.',
      error: 'Something went wrong.',
    },
    copyQuestion: {
      success: '{{title}} was successfully copied.',
    },
    templateAssignAll: {
      success: 'Templates assigned successfully.',
      error: 'Templates could not be assigned.',
    },
  },
};
