import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type DeleteTaskCalculationItemMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;

export type DeleteTaskCalculationItemMutation = {
  __typename?: 'Mutation';
  deleteTaskCalculationItem: { __typename?: 'TaskCalculationItem'; id: string };
};

export const DeleteTaskCalculationItemDocument = gql`
  mutation DeleteTaskCalculationItem($id: ID!) {
    deleteTaskCalculationItem(id: $id) {
      id
    }
  }
`;

export function useDeleteTaskCalculationItemMutation() {
  return Urql.useMutation<
    DeleteTaskCalculationItemMutation,
    DeleteTaskCalculationItemMutationVariables
  >(DeleteTaskCalculationItemDocument);
}
