/**
 * MIME types handled by this application.
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types Common MIME types on mdn} if you want to extend this.
 */

export enum FileType {
  CSV = 'text/csv',
  DOC = 'application/msword',
  DOCX = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  EML = 'message/rfc822',
  HEIC = 'image/heic',
  JPG = 'image/jpeg',
  GIF = 'image/gif',
  JSON = 'application/json',
  MP4 = 'video/mp4',
  MSG = 'application/vnd.ms-outlook',
  PDF = 'application/pdf',
  PNG = 'image/png',
  RTF = 'application/rtf',
  ODT = 'application/vnd.oasis.opendocument.text',
  ODP = 'application/vnd.oasis.opendocument.presentation',
  HTML = 'text/html',
  PPT = 'application/vnd.ms-powerpoint',
  PPTX = 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  TIF = 'image/tiff',
  TXT = 'text/plain',
  XLS = 'application/vnd.ms-excel',
  XLSM = 'application/vnd.ms-excel.sheet.macroEnabled.12',
  XLSX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  ZIP = 'application/zip',

  UNKNOWN_BINARY = 'application/octet-stream',
  UNKNOWN = '',
}

export const fileTypeIcons = {
  [FileType.CSV]: 'l-i-sheet',
  [FileType.DOC]: 'l-i-file-text',
  [FileType.DOCX]: 'l-i-file-text',
  [FileType.EML]: 'l-i-mail',
  [FileType.HEIC]: 'l-i-file-image',
  [FileType.HTML]: 'l-i-file-image',
  [FileType.ODT]: 'l-i-file-image',
  [FileType.ODP]: 'l-i-file-image',
  [FileType.JPG]: 'l-i-file-json',
  [FileType.JSON]: 'l-i-file-image',
  [FileType.MP4]: 'l-i-file-video',
  [FileType.MSG]: 'l-i-mail',
  [FileType.PDF]: 'l-i-file-text',
  [FileType.PPT]: 'l-i-file-text',
  [FileType.PPTX]: 'l-i-file-image',
  [FileType.PNG]: 'l-i-file-image',
  [FileType.RTF]: 'l-i-file-text',
  [FileType.TIF]: 'l-i-file-image',
  [FileType.TXT]: 'l-i-file-text',
  [FileType.XLS]: 'l-i-sheet',
  [FileType.XLSM]: 'l-i-sheet',
  [FileType.XLSX]: 'l-i-sheet',
  [FileType.ZIP]: 'l-i-file-archive',
  [FileType.GIF]: 'l-i-file-image',
  [FileType.UNKNOWN_BINARY]: 'l-i-file',
  [FileType.UNKNOWN]: 'l-i-file',
} as const satisfies Record<FileType, string>;

export const fileExtensions = {
  CSV: ['csv'],
  DOC: ['doc'],
  DOCX: ['docx'],
  EML: ['eml'],
  HEIC: ['heic'],
  HTML: ['html', 'htm'],
  ODT: ['odt'],
  ODP: ['odp'],
  JPG: ['jpg', 'jpeg'],
  JSON: ['json'],
  MP4: ['mp4'],
  MSG: ['msg'],
  PDF: ['pdf'],
  PNG: ['png'],
  PPT: ['ppt'],
  PPTX: ['pptx'],
  RTF: ['rtf'],
  TIF: ['tif', 'tiff'],
  TXT: ['txt'],
  XLS: ['xls'],
  XLSM: ['xlsm'],
  XLSX: ['xlsx'],
  ZIP: ['zip'],
  GIF: ['gif'],
} satisfies Record<string, Array<string>>;

export const fileTypeExtensions = {
  [FileType.CSV]: fileExtensions.CSV,
  [FileType.DOC]: fileExtensions.DOC,
  [FileType.DOCX]: fileExtensions.DOCX,
  [FileType.EML]: fileExtensions.EML,
  [FileType.HEIC]: fileExtensions.HEIC,
  [FileType.HTML]: fileExtensions.HTML,
  [FileType.ODT]: fileExtensions.ODT,
  [FileType.ODP]: fileExtensions.ODP,
  [FileType.JPG]: fileExtensions.JPG,
  [FileType.JSON]: fileExtensions.JSON,
  [FileType.MP4]: fileExtensions.MP4,
  [FileType.MSG]: fileExtensions.MSG,
  [FileType.PDF]: fileExtensions.PDF,
  [FileType.PNG]: fileExtensions.PNG,
  [FileType.PPT]: fileExtensions.PPT,
  [FileType.PPTX]: fileExtensions.PPTX,
  [FileType.RTF]: fileExtensions.RTF,
  [FileType.TIF]: fileExtensions.TIF,
  [FileType.TXT]: fileExtensions.TXT,
  [FileType.XLS]: fileExtensions.XLS,
  [FileType.XLSM]: fileExtensions.XLSM,
  [FileType.XLSX]: fileExtensions.XLSX,
  [FileType.ZIP]: fileExtensions.ZIP,
  [FileType.GIF]: fileExtensions.GIF,
  [FileType.UNKNOWN_BINARY]: [],
  [FileType.UNKNOWN]: [],
} satisfies Record<FileType, Array<string>>;

const fileTypeMimeTypes = Object.values(FileType);

export const castToFileType = (mimeType: string | null | undefined): FileType => {
  if (mimeType && (fileTypeMimeTypes as Array<string>).includes(mimeType)) {
    return mimeType as FileType;
  }

  return FileType.UNKNOWN;
};

export const getExtensionFromFileName = (fileName: string): string | null => {
  const parts = fileName.toLowerCase().split('.');
  return parts.length > 1 ? parts[parts.length - 1] : null;
};

export const fileTypeByExtension = Object.fromEntries<FileType>(
  Object.entries(fileTypeExtensions).flatMap(([fileType, extensions]) =>
    extensions.map((extension) => [extension, fileType as FileType] as const),
  ),
);

export const guessFileTypeFromFileName = (fileName: string): FileType => {
  const maybeExtension = getExtensionFromFileName(fileName);

  if (maybeExtension && maybeExtension in fileTypeByExtension) {
    return fileTypeByExtension[maybeExtension];
  }

  return FileType.UNKNOWN;
};
