import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
export type LeaderSelectFragment = {
  __typename?: 'Client';
  id: string;
  name: string;
  email?: string | null;
  job_title?: string | null;
};

export const LeaderSelectFragmentDoc = gql`
  fragment LeaderSelect on Client {
    id
    name
    email
    job_title
  }
`;
