import './DragHandleCell.scss';

import { classNames } from '@progress/kendo-react-common';
import { GridCellProps } from '@progress/kendo-react-grid';
import { HTMLAttributes, useCallback, useEffect, useRef } from 'react';

import { useTdElementProps } from '../../hooks/useTdElementProps';

const useLockBodyTouchMove = () => {
  const removeListenerRef = useRef<(() => void) | undefined>(undefined);

  useEffect(() => () => removeListenerRef.current?.(), []);

  return {
    lockTouchMove: useCallback(() => {
      // Edge case: previous listener might not have been canceled.
      removeListenerRef.current?.();

      const preventTouchEvent = (event: TouchEvent) => event.preventDefault();

      document.body.addEventListener('touchmove', preventTouchEvent, { passive: false });

      removeListenerRef.current = () =>
        document.body.removeEventListener('touchmove', preventTouchEvent);
    }, []),
    unlockTouchMove: useCallback(() => removeListenerRef.current?.(), []),
  };
};

export const DragHandleCell = (
  props: GridCellProps & Pick<HTMLAttributes<HTMLTableCellElement>, 'onClick'>,
) => {
  const tdElementProps = useTdElementProps(props);
  const { lockTouchMove, unlockTouchMove } = useLockBodyTouchMove();

  return (
    <td
      {...tdElementProps}
      className={classNames('DragHandleCell noselect', tdElementProps.className)}
      data-drag-handle={true}
      onClick={props.onClick}
      onTouchStart={lockTouchMove}
      onTouchCancel={unlockTouchMove}
      onTouchEnd={unlockTouchMove}
    >
      <span className="DragHandleCell__Icon l-i-arrow-up-down" />
    </td>
  );
};
