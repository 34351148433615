import { createContext, ReactNode, useContext } from 'react';
import { UseQueryState } from 'urql';

import { UserRole } from '../../../types/graphql.generated';
import { MeQuery, useMeQuery } from '../graphql';

const emptyUser: MeQuery['me'] = {
  __typename: 'User',
  id: '0',
  name: '',
  email: '',
  profile_image: null,
  role: UserRole.NONE,
  permissions: [],
  globalPermissions: [],
};

const MeQueryContext = createContext<UseQueryState<MeQuery> | undefined>(undefined);

interface MeQueryProviderProps {
  children: ReactNode;
  paused: boolean;
}

export const MeQueryProvider = (props: MeQueryProviderProps) => {
  const [queryState] = useMeQuery({ pause: props.paused });

  return <MeQueryContext.Provider value={queryState}>{props.children}</MeQueryContext.Provider>;
};

export const useMeQueryContext = () => {
  const meQueryContext = useContext(MeQueryContext);

  if (!meQueryContext) {
    throw new Error('Missing MeQueryContext');
  }

  return meQueryContext;
};

export const useMe = () => {
  const { data } = useMeQueryContext();

  if (!data?.me) {
    throw new Error('Missing me user');
  }

  return data?.me;
};

export const useMeOrDefault = () => {
  const { data } = useMeQueryContext();

  return data?.me ?? emptyUser;
};
