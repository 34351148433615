import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type TaskSubTaskStatesQueryVariables = Types.Exact<{ [key: string]: never }>;

export type TaskSubTaskStatesQuery = {
  __typename?: 'Query';
  taskSubTaskStates?: Array<{
    __typename?: 'TaskSubTaskState';
    id: string;
    title: string;
    color: string;
    state_id: Types.TaskSubTaskStateEnum;
  }> | null;
};

export const TaskSubTaskStatesDocument = gql`
  query TaskSubTaskStates {
    taskSubTaskStates {
      id
      title
      color
      state_id
    }
  }
`;

export function useTaskSubTaskStatesQuery(
  options?: Omit<Urql.UseQueryArgs<TaskSubTaskStatesQueryVariables>, 'query'>,
) {
  return Urql.useQuery<TaskSubTaskStatesQuery, TaskSubTaskStatesQueryVariables>({
    query: TaskSubTaskStatesDocument,
    ...options,
  });
}
