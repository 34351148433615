import { LoadingButton } from '@module/common/components';
import {
  InsuranceAssignmentListboxes,
  insuranceAssignmentSelectableItemSchema,
} from '@module/common/components/inputs/InsuranceAssignmentListboxes';
import {
  CancelButton,
  Dialog,
  DialogContent,
  DialogHeader,
  DialogHeaderBar,
  DialogHeaderIcon,
} from '@module/layout';
import { DialogProps, useDialogs } from '@module/shared/dialogs';
import { Form, useSchemaValidator } from '@module/shared/forms';
import { NotificationType, useNotifications } from '@module/shared/notifications';
import { DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Field, FormElement } from '@progress/kendo-react-form';
import { useId, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { array, InferType, object } from 'yup';

import {
  InterfaceMessageTemplateFragment,
  useAssignInterfaceMessageTemplateToClientsMutation,
} from '../graphql';

const schema = object({
  client_ids: array().of(insuranceAssignmentSelectableItemSchema).required(),
});

const useAssignInterfaceMessageTemplateSchema = () => {
  const validator = useSchemaValidator(schema);
  return {
    schema,
    validator,
  };
};

type AssignInterfaceMessageTemplateFormValues = InferType<typeof schema>;

interface AssignInterfaceMessageTemplatesDialog {
  template: InterfaceMessageTemplateFragment;
}

export const AssignInterfaceMessageTemplatesDialog = (
  props: DialogProps & AssignInterfaceMessageTemplatesDialog,
) => {
  const { t } = useTranslation();
  const { hideDialog } = useDialogs();
  const { showNotification } = useNotifications();
  const { dialogId, template } = props;
  const formId = useId();

  const { validator, schema } = useAssignInterfaceMessageTemplateSchema();

  const handleHideDialog = () => hideDialog(dialogId);

  const [{ fetching }, assignTemplate] = useAssignInterfaceMessageTemplateToClientsMutation();

  const handleSubmit = async (values: unknown) => {
    const clients = values as AssignInterfaceMessageTemplateFormValues;

    const input = {
      templateId: template.id,
      clientIds: clients.client_ids.map(({ id }) => id),
    };

    const mutationResult = await assignTemplate(input, {
      additionalTypenames: ['InterfaceMessageTemplatePaginator'],
    });

    if (mutationResult.error || !mutationResult.data?.assignInterfaceMessageTemplateToClients) {
      showNotification(t('common.errors.generic'), NotificationType.Error);
    } else {
      const { message, success } = mutationResult.data.assignInterfaceMessageTemplateToClients;
      if (!success && message) {
        showNotification(message, NotificationType.Error);
      }
      if (success && message) {
        showNotification(message, NotificationType.Success);
        handleHideDialog();
      }
    }
  };

  const initialValues = useMemo(() => {
    return {
      client_ids: template.assignments.map((assignment) => ({
        id: assignment.id,
        name: assignment.name,
        selected: true,
      })),
    } satisfies AssignInterfaceMessageTemplateFormValues;
  }, [template.assignments]);

  return (
    <Dialog width="medium" onClose={handleHideDialog} preventCloseOnEscape>
      <DialogHeaderBar>
        <DialogHeaderIcon iconClass="l-i-plug u-text-2xl" color="success" />
        <DialogHeader title={t('interfaceMessageTemplates.dialogs.assign.title')} />
      </DialogHeaderBar>
      <DialogContent>
        <Form
          id={formId}
          schema={schema}
          validator={validator}
          initialValues={initialValues}
          onSubmit={handleSubmit}
          render={() => (
            <FormElement>
              <Field name="client_ids" component={InsuranceAssignmentListboxes} />
            </FormElement>
          )}
        />
      </DialogContent>

      <DialogActionsBar layout="end">
        <CancelButton onClick={handleHideDialog} />
        <LoadingButton
          type="submit"
          form={formId}
          iconClass="l-i-save"
          size="large"
          themeColor="primary"
          isLoading={fetching}
        >
          {t('common.labels.save')}
        </LoadingButton>
      </DialogActionsBar>
    </Dialog>
  );
};
