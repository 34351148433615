import './EditableTextPlaceholder.scss';

import { Nl2Br } from '@module/shared/components';
import { ComponentProps } from 'react';

interface EditableTextPlaceholderProps {
  text?: string | null;
  placeholder: string;
}

export const EditableTextPlaceholder = (
  props: EditableTextPlaceholderProps & ComponentProps<'div'>,
) => {
  const { text, placeholder } = props;

  return (
    <div className="EditableTextPlaceholder">
      {text ? (
        <Nl2Br text={text} />
      ) : (
        <span className="EditableTextPlaceholder__Placeholder">{placeholder}</span>
      )}

      <div className="EditableTextPlaceholder__Icon">
        <span className="l-i-pencil" />
      </div>
    </div>
  );
};
