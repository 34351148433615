export const fallbackMapLocation = {
  latitude: 51.337059,
  longitude: 12.324506,
} as const;

export enum MapStyle {
  Monochrome = 'mapbox://styles/mapbox/light-v9',
  Satellite = 'mapbox://styles/mapbox/satellite-v9',
  Streets = 'mapbox://styles/mapbox/streets-v9',
  SatelliteStreets = 'mapbox://styles/mapbox/satellite-streets-v11',
}
