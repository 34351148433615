import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import { InsuranceFragmentDoc } from './insuranceFragment.generated';
import { TaskVisitTypeFragmentDoc } from './taskVisitTypeFragment.generated';
import { ContactRoleFragmentDoc } from './contactRoleFragment.generated';
import { CountryFragmentDoc } from '../../../common/graphql/generated/countryFragment.generated';
import { LeaderSelectFragmentDoc } from './leaderSelectFragment.generated';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CreateTaskDataQueryVariables = Types.Exact<{ [key: string]: never }>;

export type CreateTaskDataQuery = {
  __typename?: 'Query';
  clientsForTask: {
    __typename?: 'ClientList';
    data: Array<{
      __typename?: 'Client';
      id: string;
      salutation_id: string;
      name: string;
      email?: string | null;
      phone?: string | null;
      notes?: string | null;
      job_title?: string | null;
      locations: Array<{
        __typename?: 'ClientLocation';
        id: string;
        street?: string | null;
        street_no?: string | null;
        postcode?: string | null;
        city?: string | null;
        contacts: Array<{
          __typename?: 'Contact';
          id: string;
          first_name?: string | null;
          name?: string | null;
        }>;
      }>;
      client_additional_insurance?: {
        __typename?: 'ClientAdditionalInsurance';
        damage_number_mask?: string | null;
      } | null;
      client_additional_policy_holder?: {
        __typename?: 'ClientAdditionalPolicyHolder';
        insurance_policy_number?: string | null;
        customer_number?: string | null;
        customer_state_date?: string | null;
        policy_holder_type_id?: Types.PolicyHolderType | null;
        customer_state?: { __typename?: 'ClientCustomerState'; title: string } | null;
      } | null;
      qualifications: {
        __typename?: 'ClientQualificationList';
        data: Array<{ __typename?: 'Qualification'; title: string; id: string; color: string }>;
      };
    }>;
  };
  taskVisitTypes: Array<{
    __typename?: 'TaskVisitType';
    id: string;
    title: string;
    need_location: boolean;
    show_location: boolean;
  }>;
  contactRoles: Array<{ __typename?: 'ContactRole'; id: string; title: string }>;
  countries: Array<{ __typename?: 'Country'; id: string; title: string }>;
  leaders: {
    __typename?: 'LeaderList';
    data: Array<{
      __typename?: 'Client';
      id: string;
      name: string;
      email?: string | null;
      job_title?: string | null;
    }>;
  };
};

export const CreateTaskDataDocument = gql`
  query CreateTaskData {
    clientsForTask {
      data {
        ...Insurance
      }
    }
    taskVisitTypes {
      ...TaskVisitType
    }
    contactRoles {
      ...ContactRole
    }
    countries {
      ...Country
    }
    leaders {
      data {
        ...LeaderSelect
      }
    }
  }
  ${InsuranceFragmentDoc}
  ${TaskVisitTypeFragmentDoc}
  ${ContactRoleFragmentDoc}
  ${CountryFragmentDoc}
  ${LeaderSelectFragmentDoc}
`;

export function useCreateTaskDataQuery(
  options?: Omit<Urql.UseQueryArgs<CreateTaskDataQueryVariables>, 'query'>,
) {
  return Urql.useQuery<CreateTaskDataQuery, CreateTaskDataQueryVariables>({
    query: CreateTaskDataDocument,
    ...options,
  });
}
