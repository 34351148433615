import Icon404 from '@image/empty-states/404.svg';
import { PublicLayout } from '@module/layout';
import { Button } from '@progress/kendo-react-buttons';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export interface NotFoundPageProps {
  title?: string | ReactNode;
  note?: string | ReactNode;
}

export const NotFoundPage = (props: NotFoundPageProps) => {
  const { t } = useTranslation();

  return (
    <PublicLayout>
      <div className="k-display-flex k-flex-grow k-flex-column k-justify-content-center k-align-items-center">
        <h5>{t('common.pages.not_found.error')}</h5>
        <div className="k-display-flex k-flex-column k-justify-content-center">
          <div>
            <img src={Icon404} width={240} />
          </div>
        </div>
        <h1>{props.title ? props.title : t('common.pages.not_found.title')}</h1>
        <p className="k-text-center">
          {props.note ? props.note : t('common.pages.not_found.note')}
        </p>
        <div className="k-flex-wrap k-d-flex k-gap-x-6">
          <Button iconClass="l-i-chevron-left" size={'large'} onClick={() => history.back()}>
            {t('common.pages.not_found.back')}
          </Button>
          <Link
            to="/"
            className="k-button k-button-lg k-button-rectangle k-button-solid k-button-solid-primary k-rounded-md"
          >
            {t('common.pages.not_found.back_to_home')}
          </Link>
        </div>
      </div>
    </PublicLayout>
  );
};
