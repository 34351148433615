function keysOf<K extends Record<string, string | number>>(o: K): (keyof K)[] {
  return Object.keys(o);
}

export function findEnumValue<
  TEnum extends Record<TEnumKey, TEnumValue>,
  TEnumKey extends string,
  TEnumValue extends string | number,
>(_enum: TEnum, value: TEnumValue): TEnum[keyof TEnum] | undefined {
  for (const enumKey of keysOf(_enum)) {
    if (_enum[enumKey] === value) {
      return _enum[enumKey];
    }
  }
  return undefined;
}

export function isValidEnumValue<
  TEnum extends Record<TEnumKey, TEnumValue>,
  TEnumKey extends string,
  TEnumValue extends string | number,
>(_enum: TEnum, value: TEnumValue): boolean {
  return Object.values(_enum).find((_value) => _value === value) !== undefined;
}
