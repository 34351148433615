import { addMethod, string } from 'yup';

addMethod(string, 'checkForbiddenPrefix', function checkForbiddenPrefix() {
  return this.test('plusCheck', 'validation.checkForbiddenPrefix.plusCheck', (value) =>
    value ? !value.startsWith('+') : true,
  )
    .test('minusCheck', 'validation.checkForbiddenPrefix.minusCheck', (value) =>
      value ? !value.startsWith('-') : true,
    )
    .test('equalsCheck', 'validation.checkForbiddenPrefix.equalsCheck', (value) =>
      value ? !value.startsWith('=') : true,
    )
    .test('atCheck', 'validation.checkForbiddenPrefix.atCheck', (value) =>
      value ? !value.startsWith('@') : true,
    )
    .test('tabCheck', 'validation.checkForbiddenPrefix.tabCheck', (value) =>
      value ? !value.startsWith('\x09') : true,
    )
    .test('carriageReturnCheck', 'validation.checkForbiddenPrefix.carriageReturnCheck', (value) =>
      value ? !value.startsWith('\x0D') : true,
    );
});
