import { useThemeColorValues } from '@module/layout/components/theme/hooks';
import { Instance } from 'pspdfkit';
import { useCallback } from 'react';

export const useThemeColor = () => {
  const colors = useThemeColorValues();

  const setThemeColor = useCallback(
    (instance: Instance) => {
      if (!instance) return;
      for (const selector of PSPDFKIT_CSS_SELECTORS) {
        instance.contentDocument.documentElement.style.setProperty(
          selector.toString(),
          colors.PRIMARY,
        );
      }
    },
    [colors],
  );

  return { setThemeColor };
};

const PSPDFKIT_CSS_SELECTORS = [
  '--PSPDFKit-ToolbarButton-isActive-background',
  '--PSPDFKit-Toolbar-dropdownButton-isFocused-background',
  '--PSPDFKit-Toolbar-dropdownButton-isSelected-background',
  '--PSPDFKit-ThumbnailsSidebar-gridView-label-isSelected-background',
  '--PSPDFKit-ThumbnailsSidebar-gridView-thumbnail-isSelected-boxShadow',
  '--PSPDFKit-AnnotationToolbar-iconLabel-isActive-background',
  '--PSPDFKit-AnnotationToolbar-iconLabel-isActive-border',
  '--PSPDFKit-Button--primary-border',
  '--PSPDFKit-Button--primary-backgroundColor',
];
