import './LoadingButton.scss';

import { Button, ButtonProps } from '@progress/kendo-react-buttons';
import { classNames } from '@progress/kendo-react-common';
import { Loader } from '@progress/kendo-react-indicators';

export const LoadingButton = ({
  type = 'button',
  children,
  className,
  isLoading,
  disabled,
  ...rest
}: ButtonProps & { isLoading?: boolean }) => {
  const isIconButton = Boolean(rest.icon || rest.iconClass) && children === undefined;

  return (
    <Button
      type={type}
      className={classNames(className, 'LoadingButton !k-relative', {
        isLoading,
        'k-icon-button': isIconButton,
      })}
      disabled={disabled || isLoading}
      {...rest}
    >
      <span className="LoadingButton__Content">{children}</span>

      {isLoading && (
        <Loader
          className="!k-absolute k-middle-center"
          style={{ transform: 'translate(-50%, -50%)' }}
        />
      )}
    </Button>
  );
};
