import {
  Ability,
  AbilityBuilder,
  AbilityClass,
  ForcedSubject,
  subject as subjectHelper,
} from '@casl/ability';
import { useCallback, useMemo } from 'react';

import { Permission } from '../../../types/graphql.generated';
import { GenericCan, GenericCanSharedProps } from '../components/GenericCan';
import { CurrentUserAbilityFragment } from '../graphql';
import { detectSubjectTypeByTypename as detectSubjectType } from '../helpers';
import { useMeOrDefault } from '../hooks';

interface InterfaceMessageTemplateSubject {
  me: CurrentUserAbilityFragment;
}

type InterfaceMessageTemplateSubjectArg = Partial<InterfaceMessageTemplateSubject>;

type Actions =
  | 'viewInterfaceMessageTemplates'
  | 'readInterfaceMessageTemplate'
  | 'createInterfaceMessageTemplate'
  | 'updateInterfaceMessageTemplate'
  | 'deleteInterfaceMessageTemplate';
type Subjects = InterfaceMessageTemplateSubject | 'InterfaceMessageTemplateSubject';

type InterfaceMessageTemplateAbility = Ability<[Actions, Subjects]>;
const interfaceMessageTemplateAbility = Ability as AbilityClass<InterfaceMessageTemplateAbility>;

export const useInterfaceMessageTemplateAbility = (): [
  InterfaceMessageTemplateAbility,
  (
    sub?: InterfaceMessageTemplateSubjectArg,
  ) => InterfaceMessageTemplateSubject & ForcedSubject<'InterfaceMessageTemplateSubject'>,
] => {
  const ability = useMemo(() => {
    const { can, build } = new AbilityBuilder(interfaceMessageTemplateAbility);

    can('viewInterfaceMessageTemplates', 'InterfaceMessageTemplateSubject', {
      'me.globalPermissions': { $in: [Permission.INTERFACE_MESSAGE_TEMPLATE_INDEX] },
    });
    can('readInterfaceMessageTemplate', 'InterfaceMessageTemplateSubject', {
      'me.globalPermissions': { $in: [Permission.INTERFACE_MESSAGE_TEMPLATE_READ] },
    });
    can('createInterfaceMessageTemplate', 'InterfaceMessageTemplateSubject', {
      'me.globalPermissions': { $in: [Permission.INTERFACE_MESSAGE_TEMPLATE_CREATE] },
    });
    can('updateInterfaceMessageTemplate', 'InterfaceMessageTemplateSubject', {
      'me.globalPermissions': { $in: [Permission.INTERFACE_MESSAGE_TEMPLATE_UPDATE] },
    });
    can('deleteInterfaceMessageTemplate', 'InterfaceMessageTemplateSubject', {
      'me.globalPermissions': { $in: [Permission.INTERFACE_MESSAGE_TEMPLATE_DELETE] },
    });

    return build({ detectSubjectType });
  }, []);

  const me = useMeOrDefault();
  const subject = useCallback(
    (sub?: InterfaceMessageTemplateSubjectArg) => {
      return subjectHelper('InterfaceMessageTemplateSubject', { me, ...sub });
    },
    [me],
  );

  return [ability, subject];
};

type CanInterfaceMessageTemplateProps = GenericCanSharedProps<Actions>;

export const CanInterfaceMessageTemplate = (props: CanInterfaceMessageTemplateProps) => {
  const [interfaceMessageTemplateAbility, interfaceMessageTemplateSubject] =
    useInterfaceMessageTemplateAbility();

  return (
    <GenericCan<Actions, Subjects, InterfaceMessageTemplateAbility>
      ability={interfaceMessageTemplateAbility}
      subject={interfaceMessageTemplateSubject()}
      {...props}
    />
  );
};
