import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CreateTaskFolderTemplateMutationVariables = Types.Exact<{
  input: Types.TaskFolderTemplateInput;
}>;

export type CreateTaskFolderTemplateMutation = {
  __typename?: 'Mutation';
  createTaskFolderTemplate?: { __typename?: 'TaskFolderTemplate'; id: string } | null;
};

export const CreateTaskFolderTemplateDocument = gql`
  mutation CreateTaskFolderTemplate($input: TaskFolderTemplateInput!) {
    createTaskFolderTemplate(input: $input) {
      id
    }
  }
`;

export function useCreateTaskFolderTemplateMutation() {
  return Urql.useMutation<
    CreateTaskFolderTemplateMutation,
    CreateTaskFolderTemplateMutationVariables
  >(CreateTaskFolderTemplateDocument);
}
