import { getContrastColor } from '@module/common';
import { CommonFieldRenderProps, FieldError, FieldHint } from '@module/shared/forms';
import { Chip } from '@progress/kendo-react-buttons';
import {
  ListItemProps,
  MultiSelect,
  MultiSelectBlurEvent,
  MultiSelectChangeEvent,
  MultiSelectFilterChangeEvent,
  MultiSelectProps,
  TagData,
} from '@progress/kendo-react-dropdowns';
import { FieldWrapper } from '@progress/kendo-react-form';
import { Label } from '@progress/kendo-react-labels';
import { cloneElement, ReactElement, useCallback, useMemo, useRef, useState } from 'react';

import { FileClassificationTagFragment } from '../graphql';

export interface FileClassificationTagProps {
  fileClassificationTag: FileClassificationTagFragment;
}

export const FileClassificationTag = (props: FileClassificationTagProps) => {
  const { fileClassificationTag } = props;

  return <span>{fileClassificationTag.title}</span>;
};

interface FileClassificationTagsProps {
  fileClassificationTags: FileClassificationTagFragment[];
}

type FileClassificationTagsSelectProps = FileClassificationTagsProps &
  CommonFieldRenderProps &
  Pick<
    MultiSelectProps,
    | 'id'
    | 'value'
    | 'placeholder'
    | 'required'
    | 'disabled'
    | 'onBlur'
    | 'onChange'
    | 'onFilterChange'
    | 'onFocus'
  >;

interface FileClassificationTagData extends TagData {
  data: FileClassificationTagFragment[];
}

interface FileClassificatioListItemProps extends ListItemProps {
  dataItem: FileClassificationTagFragment;
}

export const FileClassificationTagsSelect = (props: FileClassificationTagsSelectProps) => {
  const { label, fileClassificationTags, name, id, valid, value } = props;
  const multiselectRef = useRef<MultiSelect>(null);

  const [filter, setFilter] = useState('');

  const classificationTagsAsValue = useMemo(() => {
    const f = ((value as string[]) ?? []).map((file_classification_tag_id) => {
      return fileClassificationTags.find(
        (fileClassificationTag) => fileClassificationTag.id === file_classification_tag_id,
      );
    });
    return f.filter((v) => v) as FileClassificationTagFragment[];
  }, [fileClassificationTags, value]);

  const handleChange = useCallback(
    (e: MultiSelectChangeEvent) => {
      const file_classification_tag_ids = (e.value as FileClassificationTagFragment[]).map(
        (classificationTag) => classificationTag.id,
      );
      props.onChange?.({ ...e, value: file_classification_tag_ids });
    },
    [props],
  );
  const handleFilterChange = useCallback(
    (e: MultiSelectFilterChangeEvent) => {
      setFilter(e.filter.value);
      props.onFilterChange?.(e);
    },
    [props],
  );
  const handleBlur = useCallback(
    (e: MultiSelectBlurEvent) => {
      if (filter && multiselectRef.current) {
        handleChange({
          syntheticEvent: e.syntheticEvent,
          value: [...(value || []), { email: filter, name: filter }],
          nativeEvent: e.nativeEvent,
          target: multiselectRef.current,
        });
        setFilter('');
      }
    },
    [filter, handleChange, value],
  );
  const tagRender = (tagData: FileClassificationTagData, element: ReactElement) => {
    const classificationTag = tagData.data[0];

    return (
      <Chip
        key={classificationTag.id}
        {...element.props}
        text={classificationTag.title}
        rounded={'full'}
        style={{
          backgroundColor: classificationTag.color,
          color: getContrastColor(classificationTag.color),
        }}
        className="!k-border-0"
      />
    );
  };
  const itemRender = (
    li: React.ReactElement<HTMLLIElement>,
    itemProps: FileClassificatioListItemProps,
  ) => {
    const classificationTag = itemProps.dataItem;
    const itemChildren = (
      <Chip
        key={classificationTag.id}
        text={classificationTag.title}
        rounded={'full'}
        style={{
          backgroundColor: classificationTag.color,
          color: getContrastColor(classificationTag.color),
        }}
        className="!k-border-0"
      />
    );
    return cloneElement(li, li.props, itemChildren);
  };

  return (
    <FieldWrapper className="FileClassificationTagsSelect">
      <Label editorId={id} editorValid={valid}>
        {label}
      </Label>
      <MultiSelect
        ref={multiselectRef}
        textField="title"
        dataItemKey="id"
        name={name}
        data={fileClassificationTags}
        size="large"
        onChange={handleChange}
        onBlur={handleBlur}
        allowCustom
        filterable={true}
        filter={filter}
        onFilterChange={handleFilterChange}
        value={classificationTagsAsValue}
        itemRender={itemRender}
        tagRender={tagRender}
      />
      <FieldHint {...props} />
      <FieldError {...props} />
    </FieldWrapper>
  );
};
