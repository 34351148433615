import { useTranslation } from 'react-i18next';

import { ErrorButton, ErrorButtonProps } from './ErrorButton';

export type DeleteButtonProps = Omit<ErrorButtonProps, 'iconClass'>;
export const DeleteButton = (props: DeleteButtonProps) => {
  const { t } = useTranslation();
  const { label = t('common.labels.delete') } = props;
  return <ErrorButton {...props} label={label} iconClass="l-i-trash-2" />;
};
