import { ColorPickerInput, FormGroupVertical, TextInput } from '@module/shared/forms';
import { Field } from '@progress/kendo-react-form';
import { useTranslation } from 'react-i18next';

export const TaskGroupFormFields = () => {
  const { t } = useTranslation();

  return (
    <FormGroupVertical>
      <Field
        id="task_group.title"
        name={'title'}
        label={t('settings.pages.taskGroups.labels.name')}
        component={TextInput}
      />
      <Field
        id="task_group.color"
        name={'color'}
        label={t('settings.pages.taskGroups.labels.color')}
        size="large"
        component={ColorPickerInput}
      />
    </FormGroupVertical>
  );
};
