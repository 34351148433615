import { useAsyncFocusBlur } from '@progress/kendo-react-common';
import {
  CalendarProps,
  DateTimePicker,
  DateTimePickerProps,
} from '@progress/kendo-react-dateinputs';
import { FieldWrapper } from '@progress/kendo-react-form';
import { Popup, PopupProps } from '@progress/kendo-react-popup';
import { ComponentType, RefAttributes, useId, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Label } from '../components';
import { FieldError } from './FieldError';
import { FieldHint } from './FieldHint';
import { CommonFieldRenderProps } from './types';

type DateTimePickerInputProps = DateTimePickerProps &
  CommonFieldRenderProps & {
    calendar?: ComponentType<CalendarProps>;
  };

const DateTimePickerPopup = (props: PopupProps) => {
  return <Popup {...props} collision={{ horizontal: 'fit', vertical: 'flip' }} />;
};

export const DateTimePickerInput = (props: DateTimePickerInputProps & RefAttributes<never>) => {
  const defaultId = useId();
  const {
    size = 'large',
    value,
    id = defaultId,
    label,
    valid,
    required,
    disabled,
    onBlur,
    onChange,
    onFocus,
    name,
    tooltip,
    tooltipPosition,
    calendar,
    min,
    max,
  } = props;
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const focusProps = useAsyncFocusBlur({
    onFocus: () => setShow(true),
    onBlur: () => setShow(false),
  });
  return (
    <FieldWrapper>
      {label && (
        <Label
          tooltip={tooltip}
          tooltipPosition={tooltipPosition}
          label={label}
          name={name}
          editorId={id}
          editorValid={valid}
        >
          {label}
        </Label>
      )}
      <DateTimePicker
        id={id}
        value={value}
        size={size}
        disabled={disabled}
        format={t('common.formats.dateTimeWithoutSeconds')}
        popup={DateTimePickerPopup}
        min={min && min}
        max={max && max}
        calendar={calendar}
        required={required}
        show={show}
        onChange={(e) => {
          onChange?.(e);
          setShow(false);
        }}
        onFocus={(e) => {
          focusProps.onFocus?.();
          onFocus?.(e);
        }}
        onBlur={(e) => {
          focusProps.onBlur?.();
          onBlur?.(e);
        }}
      />
      <div className="HintAndError">
        <FieldHint {...props} />
        <FieldError {...props} />
      </div>
    </FieldWrapper>
  );
};
