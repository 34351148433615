import { isString } from '@module/shared/helpers';
import { classNames } from '@progress/kendo-react-common';
import { ReactNode } from 'react';

export interface SectionFormProps {
  title?: string | ReactNode;
  description?: string | ReactNode;
  content?: ReactNode;

  className?: string;
  classNameTitle?: string;
  classNameDescription?: string;
  classNameTitleDescription?: string;
  classNameContent?: string;
}

export const SectionForm = (props: SectionFormProps) => {
  const {
    title,
    description,
    content,
    className,
    classNameTitle,
    classNameDescription,
    classNameContent,
    classNameTitleDescription,
  } = props;

  return (
    <div className={classNames('section-form', className)}>
      <div className="row">
        <div className={classNames('col-md-3', classNameTitleDescription)}>
          {title &&
            (isString(title) ? (
              <h3 className={classNames('section-form-title', '!k-mt-0', classNameTitle)}>
                {title}
              </h3>
            ) : (
              <>{title}</>
            ))}
          {description &&
            (isString(description) ? (
              <p className={classNames('section-form-description', classNameDescription)}>
                {description}
              </p>
            ) : (
              <>{description}</>
            ))}
        </div>
        <div className={classNames('section-form-content', 'col-md-9 col-xl-6', classNameContent)}>
          {content}
        </div>
      </div>
    </div>
  );
};
