import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { PasswordSettings } from './usePasswordSettings';

export function usePasswordMessages(settings: PasswordSettings) {
  const { t } = useTranslation();
  return useMemo(() => {
    return {
      length: t('validation.password.length', { min: settings.minLength }),
      special: t('validation.password.special', { min: settings.minSpecial }),
      uppercase: t('validation.password.uppercase', { min: settings.minUpperCase }),
      numbers: t('validation.password.numbers', { min: settings.minNumbers }),
    };
  }, [settings.minLength, settings.minNumbers, settings.minSpecial, settings.minUpperCase, t]);
}
