import {
  Ability,
  AbilityBuilder,
  AbilityClass,
  ForcedSubject,
  subject as subjectHelper,
} from '@casl/ability';
import { useCallback, useMemo } from 'react';

import { Permission } from '../../../types/graphql.generated';
import { GenericCan, GenericCanSharedProps } from '../components/GenericCan';
import { CurrentUserAbilityFragment } from '../graphql';
import { detectSubjectTypeByTypename as detectSubjectType } from '../helpers';
import { useMeOrDefault } from '../hooks';

interface TaskFolderTemplateSubject {
  me: CurrentUserAbilityFragment;
}

type TaskFolderTemplateSubjectArg = Partial<TaskFolderTemplateSubject>;

type Actions =
  | 'viewTaskFolderTemplates'
  | 'viewTaskFolderTemplate'
  | 'createTaskFolderTemplate'
  | 'updateTaskFolderTemplate'
  | 'deleteTaskFolderTemplate';
type Subjects = TaskFolderTemplateSubject | 'TaskFolderTemplateSubject';

type TaskFolderTemplateAbility = Ability<[Actions, Subjects]>;
const taskTemplateAbility = Ability as AbilityClass<TaskFolderTemplateAbility>;

export const useTaskFolderTemplateAbility = (): [
  TaskFolderTemplateAbility,
  (
    sub?: TaskFolderTemplateSubjectArg,
  ) => TaskFolderTemplateSubject & ForcedSubject<'TaskFolderTemplateSubject'>,
] => {
  const ability = useMemo(() => {
    const { can, build } = new AbilityBuilder(taskTemplateAbility);

    can('viewTaskFolderTemplates', 'TaskFolderTemplateSubject', {
      'me.globalPermissions': { $in: [Permission.TASK_FOLDER_TEMPLATE_CREATE] },
    });
    can('viewTaskFolderTemplate', 'TaskFolderTemplateSubject', {
      'me.globalPermissions': { $in: [Permission.TASK_FOLDER_TEMPLATE_READ] },
    });
    can('createTaskFolderTemplate', 'TaskFolderTemplateSubject', {
      'me.globalPermissions': { $in: [Permission.TASK_FOLDER_TEMPLATE_CREATE] },
    });
    can('updateTaskFolderTemplate', 'TaskFolderTemplateSubject', {
      'me.globalPermissions': { $in: [Permission.TASK_FOLDER_TEMPLATE_UPDATE] },
    });
    can('deleteTaskFolderTemplate', 'TaskFolderTemplateSubject', {
      'me.globalPermissions': { $in: [Permission.TASK_FOLDER_TEMPLATE_DELETE] },
    });

    return build({ detectSubjectType });
  }, []);

  const me = useMeOrDefault();
  const subject = useCallback(
    (sub?: TaskFolderTemplateSubjectArg) => {
      return subjectHelper('TaskFolderTemplateSubject', { me, ...sub });
    },
    [me],
  );

  return [ability, subject];
};

type CanTaskFolderTemplateProps = GenericCanSharedProps<Actions>;

export const CanTaskFolderTemplate = (props: CanTaskFolderTemplateProps) => {
  const [taskFolderTemplateAbility, taskFolderTemplateSubject] = useTaskFolderTemplateAbility();

  return (
    <GenericCan<Actions, Subjects, TaskFolderTemplateAbility>
      ability={taskFolderTemplateAbility}
      subject={taskFolderTemplateSubject()}
      {...props}
    />
  );
};
