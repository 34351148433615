import './ColumnLayout.scss';

import { ReactNode } from 'react';

export interface ColumnLayoutProps {
  sidebar?: ReactNode;
  header?: ReactNode;
  content?: ReactNode;
}

export const ColumnLayout = (props: ColumnLayoutProps) => {
  const { sidebar, header, content } = props;

  return (
    <div className="column-layout">
      {sidebar && <div className="column-layout-sidebar">{sidebar}</div>}
      <div className="column-layout-wrapper">
        {header}
        <main className="column-layout-content">{content}</main>
      </div>
    </div>
  );
};
