import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type RestoreTaskFormMutationVariables = Types.Exact<{
  task_form_id: Types.Scalars['ID'];
}>;

export type RestoreTaskFormMutation = {
  __typename?: 'Mutation';
  restoreTaskForm: { __typename?: 'TaskForm'; id: string };
};

export const RestoreTaskFormDocument = gql`
  mutation RestoreTaskForm($task_form_id: ID!) {
    restoreTaskForm(task_form_id: $task_form_id) {
      id
    }
  }
`;

export function useRestoreTaskFormMutation() {
  return Urql.useMutation<RestoreTaskFormMutation, RestoreTaskFormMutationVariables>(
    RestoreTaskFormDocument,
  );
}
