export default {
  labels: {
    coverTitle: 'Cover-Title',
    tintColor: 'Tint Color',
    bgDarkColor: 'Background color (dark)',
    highlightColor: 'Highlight color',
    filename: 'Filename',
    footer: 'Footer',
    footerPaginateTitle: 'Pagination title',
    question_filter: 'Show questions',
    cover_layout: 'Cover-Layout',
    summary_layout: 'Summary-Layout',
    questions_layout: 'Questions-Layout',
    roomplan_layout: 'Roomplan-Layout',
    images_layout_1: 'Image-Layout 1',
    images_layout_2: 'Image-Layout 2',
    images_layout_4: 'Image-Layout 4',
    calculation_layout: 'Calculations-Layout 4',
    toc_layout: 'Table of contents layout',
    orderIdentifier: 'Pages',
    modules: 'Modules',
    selectedModules: 'Selected Modules',
    default: 'Use as default',
    isDefault: 'Default',
    use_alternate_logo: 'Use alternate logo',
    hide_primary_form: 'Hide primary set of questions',
    show_notes: 'Show notes',
  },
  pages: {
    reportLayoutsOverview: {
      title: 'Report templates',
      description: 'Here you will find a list of report templates.',
      toolbar: {
        create: 'New report template',
      },
    },
  },
  dialogs: {
    reportTemplateCreate: {
      title: 'New report template',
      selectedModules: {
        title: 'Pages in the manual',
        instruction:
          'The <bold>up/down arrows</bold> can be used to change the order of the pages. The <bold>right arrow</bold> can be used to integrate the pages into the report, while the <bold>left arrow</bold> can be used to remove them again. The <bold>double arrows</bold> to the right make it possible to transfer all content to the report with a single click.',
      },
    },
    reportTemplateEdit: {
      title: 'Edit report template',
    },
    delete: {
      title: 'Delete report template',
      message: 'Do you really want to delete this report tempalte',
    },
  },
  notifications: {
    createReportLayout: {
      success: 'Report template created!',
    },
    editReportLayout: {
      success: 'Report template changed!',
    },
    deleteReportLayout: {
      success: 'Report template deleted!',
    },
  },
  pageTypes: {
    COVER: 'Cover',
    IMAGES: 'Images',
    QUESTIONS: 'Questions',
    SUMMARY: 'Summary',
    CALCULATION: 'Calculation',
    DOCUMENTS: 'Documents',
    ROOM_PLAN: 'Room plan',
    FREETEXT: 'Freetext',
    TOC: 'Table of contents',
  },
  coverLayouts: {
    DEFAULT: 'Default',
    DEFAULT_NO_COVER_IMAGE: 'No cover image',
    DEFAULT_INVOICE_CHECK: 'Invoice verification',
    DEFAULT_CLAIMANT_COVER: 'Cover page with claimant',
    DEFAULT_RISK_COVER: 'Risk-Cover',
  },
  summaryLayouts: {
    DEFAULT: 'Default',
  },
  roomplanLayouts: {
    DEFAULT: 'Default',
  },
  questionLayouts: {
    DEFAULT: 'Default',
    COMPACT: 'Compact',
  },
  imagesLayout1s: {
    DEFAULT: 'Default',
  },
  imagesLayout2s: {
    DEFAULT: 'Default',
  },
  imagesLayout4s: {
    DEFAULT: 'Default',
  },
  calculationLayouts: {
    DEFAULT: 'Default',
  },
  tocLayouts: {
    DEFAULT: 'Default',
  },
};
