import 'mapbox-gl/dist/mapbox-gl.css';

import { MAPBOX_ACCESS_TOKEN } from '@env';
import { useLocale } from '@module/shared/localization';
import { CSSProperties, memo, MutableRefObject, ReactNode, RefCallback, useCallback } from 'react';
import ReactMapGL, {
  MapboxEvent,
  MapLayerMouseEvent,
  MapLayerTouchEvent,
  MapProps,
  MapRef,
  SymbolLayer,
  ViewState,
} from 'react-map-gl';

import IconHouse from '../../../../assets/home.png';
import IconHouseAbsent from '../../../../assets/home-absent.png';
import IconHouseAbsentSoon from '../../../../assets/home-absent-soon.png';
import IconHouseAvailableSoon from '../../../../assets/home-available-soon.png';
import { mapboxLoadImage } from '../../helpers';
import { MapStyle } from './const';

export interface MapboxMapProps
  extends Pick<
    MapProps,
    | 'mapStyle'
    | 'onClick'
    | 'onMove'
    | 'onMouseMove'
    | 'onMouseLeave'
    | 'onLoad'
    | 'onResize'
    | 'interactiveLayerIds'
  > {
  viewState: Partial<ViewState>;
  /** default: 100% */
  width?: CSSProperties['width'];
  /** default: 320 */
  height?: CSSProperties['height'];
  wrapperRef?: MutableRefObject<HTMLDivElement | null>;
  wrapperStyle?: CSSProperties | undefined;
  mapRef?: RefCallback<MapRef>;
  children?: ReactNode;
}

export const MapboxMap = memo((props: MapboxMapProps) => {
  const {
    viewState,
    width = '100%',
    height = 320,
    wrapperRef,
    wrapperStyle,
    mapStyle = MapStyle.Streets,
    mapRef,
    children,
    onClick,
    onMove,
    onMouseMove,
    onMouseLeave,
    onLoad,
    onResize,
    interactiveLayerIds,
  } = props;
  const {
    locale: { localeId },
  } = useLocale();

  const handleLoad = useCallback(
    (e: MapboxEvent) => {
      onLoad?.(e);

      mapboxLoadImage(e.target, 'house', IconHouse);
      mapboxLoadImage(e.target, 'houseAbsent', IconHouseAbsent);
      mapboxLoadImage(e.target, 'houseAbsentSoon', IconHouseAbsentSoon);
      mapboxLoadImage(e.target, 'houseAvailableSoon', IconHouseAvailableSoon);

      const mapboxLocale = localeId.substring(0, localeId.indexOf('-'));

      e.target.getStyle().layers?.forEach((layer) => {
        const symbolLayer = layer as SymbolLayer;

        // Only change language for label layers, so we do not try to translate f.e. road numbers
        if (symbolLayer.id.endsWith('-label')) {
          e.target.setLayoutProperty(symbolLayer.id, 'text-field', ['get', 'name_' + mapboxLocale]);
        }
      });
    },
    [onLoad, localeId],
  );
  const onMobileClick = useCallback(
    (e: MapLayerTouchEvent) => {
      onClick?.(e as unknown as MapLayerMouseEvent);
    },
    [onClick],
  );

  return (
    <div ref={wrapperRef} style={{ width, height, ...wrapperStyle }}>
      <ReactMapGL
        {...viewState}
        ref={mapRef}
        mapboxAccessToken={MAPBOX_ACCESS_TOKEN}
        mapStyle={mapStyle}
        scrollZoom={true}
        cooperativeGestures={false}
        dragPan={true}
        onTouchEnd={onMobileClick}
        dragRotate={false}
        interactiveLayerIds={interactiveLayerIds}
        onClick={onClick}
        onMove={onMove}
        onMouseMove={onMouseMove}
        onMouseLeave={onMouseLeave}
        onLoad={handleLoad}
        onResize={onResize}
      >
        {children}
      </ReactMapGL>
    </div>
  );
});
MapboxMap.displayName = 'MapboxMap';
