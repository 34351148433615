import { TagInput } from '@generated/graphql.generated';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { ObjectSchema } from 'yup';

export interface SubformClassificationTagValues extends TagInput {
  type_id: string;
}

export const useSubformClassificationTagInputSchema = () => {
  const { t } = useTranslation();
  return useMemo<ObjectSchema<SubformClassificationTagValues>>(
    () =>
      yup.object({
        title: yup.string().required(),
        color: yup
          .string()
          .trim()
          .matches(/^#?([a-f\d]{3,4}|[a-f\d]{6}|[a-f\d]{8})$/, t('validation.string.rgb'))
          .required(),
        // TODO: type with tag type enum
        type_id: yup.string().required(),
      }),
    [t],
  );
};
