import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import { ClientSelectFragmentDoc } from './clientSelectFragment.generated';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type InsurancesQueryVariables = Types.Exact<{ [key: string]: never }>;

export type InsurancesQuery = {
  __typename?: 'Query';
  insurances: {
    __typename?: 'InsuranceList';
    data: Array<{
      __typename?: 'Client';
      id: string;
      name: string;
      job_title?: string | null;
      email?: string | null;
    }>;
  };
};

export const InsurancesDocument = gql`
  query Insurances {
    insurances {
      data {
        ...ClientSelect
      }
    }
  }
  ${ClientSelectFragmentDoc}
`;

export function useInsurancesQuery(
  options?: Omit<Urql.UseQueryArgs<InsurancesQueryVariables>, 'query'>,
) {
  return Urql.useQuery<InsurancesQuery, InsurancesQueryVariables>({
    query: InsurancesDocument,
    ...options,
  });
}
