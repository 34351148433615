import { Loader } from '@module/common';
import {
  CancelButton,
  CreateButton,
  Dialog,
  DialogContent,
  DialogHeader,
  DialogHeaderBar,
  DialogHeaderIcon,
} from '@module/layout';
import { DialogProps, useDialogs } from '@module/shared/dialogs';
import {
  Form,
  FormElement,
  FormStateConsumer,
  FormStateProvider,
  useSchemaValidator,
} from '@module/shared/forms';
import { NotificationType, useNotifications } from '@module/shared/notifications';
import { DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Field } from '@progress/kendo-react-form';
import { useCallback, useId } from 'react';
import { useTranslation } from 'react-i18next';

import {
  SubformClassificationTagFormFields,
  TypeSelect,
} from '../components/SubfromClassificationTagFormFields';
import { useCreateSubformClassificationTagMutation } from '../graphql';
import { SubformClassificationTagValues, useSubformClassificationTagInputSchema } from '../hooks';

export const CreateSubformClassificationTagDialog = (props: DialogProps) => {
  const { t } = useTranslation();
  const { hideDialog } = useDialogs();
  const { showNotification } = useNotifications();
  const schema = useSubformClassificationTagInputSchema();
  const validator = useSchemaValidator(schema);
  const [{ fetching: isSaving }, mutate] = useCreateSubformClassificationTagMutation();
  const formId = useId();

  const handleSubmit = useCallback(
    (values: unknown) => {
      const input = values as SubformClassificationTagValues;
      mutate(
        { type_id: input.type_id, input: { title: input.title, color: input.color } },
        { additionalTypenames: ['TagPaginator'] },
      ).then((response) => {
        if (!response.error) {
          showNotification(
            t('settings.pages.subformClassificationTags.notifications.create.success'),
            NotificationType.Success,
          );
          hideDialog(props.dialogId);
        }
        if (response.error) {
          showNotification(
            t('settings.pages.subformClassificationTags.notifications.create.error'),
            NotificationType.Error,
          );
        }
      });
    },
    [mutate, hideDialog, showNotification, props.dialogId, t],
  );

  const handleClose = () => {
    hideDialog(props.dialogId);
  };

  return (
    <FormStateProvider>
      <Dialog width="small" onClose={handleClose}>
        {isSaving && <Loader />}

        <DialogHeaderBar layout="flex">
          <DialogHeaderIcon iconClass="l-i-contact u-text-2xl" color="primary" />

          <DialogHeader
            title={t('settings.pages.subformClassificationTags.dialogs.create.title')}
            description={t('settings.pages.subformClassificationTags.dialogs.create.note')}
          />
        </DialogHeaderBar>

        <DialogContent>
          <Form
            id={formId}
            schema={schema}
            onSubmit={handleSubmit}
            validator={validator}
            render={(formRenderProps) => (
              <FormElement formRenderProps={formRenderProps}>
                <SubformClassificationTagFormFields />
                <Field
                  id="subform_classification_tag.type_id"
                  name={'type_id'}
                  label={t('settings.pages.subformClassificationTags.labels.type')}
                  component={TypeSelect}
                />
              </FormElement>
            )}
          />
        </DialogContent>
        <DialogActionsBar layout="end">
          <FormStateConsumer>
            {() => (
              <>
                <CancelButton onClick={handleClose} />
                <CreateButton type="submit" form={formId} />
              </>
            )}
          </FormStateConsumer>
        </DialogActionsBar>
      </Dialog>
    </FormStateProvider>
  );
};
