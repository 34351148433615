import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import { AppModuleFragmentDoc } from './appModuleFragment.generated';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type AppModulesQueryVariables = Types.Exact<{ [key: string]: never }>;

export type AppModulesQuery = {
  __typename?: 'Query';
  appModules: Array<{
    __typename?: 'AppModule';
    id: string;
    title: string;
    description?: string | null;
    active: boolean;
    variables: Array<{
      __typename?: 'AppModuleSetting';
      id: string;
      title: string;
      description?: string | null;
      type: Types.AppModuleSettingType;
      key: string;
      value?: string | null;
    }>;
  }>;
};

export const AppModulesDocument = gql`
  query AppModules {
    appModules {
      ...AppModule
    }
  }
  ${AppModuleFragmentDoc}
`;

export function useAppModulesQuery(
  options?: Omit<Urql.UseQueryArgs<AppModulesQueryVariables>, 'query'>,
) {
  return Urql.useQuery<AppModulesQuery, AppModulesQueryVariables>({
    query: AppModulesDocument,
    ...options,
  });
}
