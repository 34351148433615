import { Instance } from 'pspdfkit';
import { useCallback, useEffect } from 'react';

interface UsePsPdfKitMouseEventsProps {
  onMouseEnter?: (e: MouseEvent) => void;
  onMouseOver?: (e: MouseEvent) => void;
  onMouseLeave?: (e: MouseEvent) => void;
  onMouseMove?: (e: MouseEvent) => void;
}

export const usePsPdfKitMouseEvents = (
  instance: Instance | null,
  props: UsePsPdfKitMouseEventsProps,
) => {
  const handleMouseEnter = useCallback(
    (e: Event) => {
      props.onMouseEnter?.(e as MouseEvent);
    },
    [props],
  );
  const handleMouseOver = useCallback(
    (e: Event) => {
      props.onMouseOver?.(e as MouseEvent);
    },
    [props],
  );

  const handleMouseLeave = useCallback(
    (e: Event) => {
      props.onMouseLeave?.(e as MouseEvent);
    },
    [props],
  );

  const handleMouseMove = useCallback(
    (e: Event) => {
      props.onMouseMove?.(e as MouseEvent);
    },
    [props],
  );

  useEffect(() => {
    instance?.contentDocument
      .querySelector('.PSPDFKit-Scroll')
      ?.addEventListener('mouseenter', handleMouseEnter);
    return () =>
      instance?.contentDocument
        .querySelector('.PSPDFKit-Scroll')
        ?.removeEventListener('mouseenter', handleMouseEnter);
  }, [handleMouseEnter, instance?.contentDocument]);

  useEffect(() => {
    instance?.contentDocument
      .querySelector('.PSPDFKit-Scroll')
      ?.addEventListener('mouseover', handleMouseOver);
    return () =>
      instance?.contentDocument
        .querySelector('.PSPDFKit-Scroll')
        ?.removeEventListener('mouseover', handleMouseOver);
  }, [handleMouseOver, instance?.contentDocument]);

  useEffect(() => {
    instance?.contentDocument
      .querySelector('.PSPDFKit-Scroll')
      ?.addEventListener('mouseleave', handleMouseLeave);
    return () =>
      instance?.contentDocument
        .querySelector('.PSPDFKit-Scroll')
        ?.removeEventListener('mouseleave', handleMouseLeave);
  }, [handleMouseLeave, instance?.contentDocument]);

  useEffect(() => {
    instance?.contentDocument
      .querySelector('.PSPDFKit-Scroll')
      ?.addEventListener('mousemove', handleMouseMove);
    return () =>
      instance?.contentDocument
        .querySelector('.PSPDFKit-Scroll')
        ?.removeEventListener('mousemove', handleMouseMove);
  }, [handleMouseMove, instance?.contentDocument]);
};
