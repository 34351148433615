import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CreateClientMutationVariables = Types.Exact<{
  input: Types.CreateClientInput;
}>;

export type CreateClientMutation = {
  __typename?: 'Mutation';
  createClient?: { __typename?: 'Client'; id: string } | null;
};

export const CreateClientDocument = gql`
  mutation CreateClient($input: CreateClientInput!) {
    createClient(input: $input) {
      id
    }
  }
`;

export function useCreateClientMutation() {
  return Urql.useMutation<CreateClientMutation, CreateClientMutationVariables>(
    CreateClientDocument,
  );
}
