import { ApiToken, BooleanCell, DateTimeCell, GridActionsButton, Loader } from '@module/common';
import { ClipBoardInput } from '@module/shared/forms';
import { NotificationType, useNotifications } from '@module/shared/notifications';
import { Grid, GridCellProps, GridColumn } from '@progress/kendo-react-grid';
import { StackLayout } from '@progress/kendo-react-layout';
import { get, orderBy } from 'lodash';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { PrimaryButton } from '../../layout';
import { useCreateApiTokenMutation, useMyApiTokensQuery } from '../graphql';
import { useConfirmDeleteApiToken } from '../hooks/useConfirmDeleteApiToken';

export const CreateApiTokenTab = () => {
  const { t } = useTranslation();
  const [{ fetching: generating }, createApiToken] = useCreateApiTokenMutation();
  const [{ data }] = useMyApiTokensQuery();
  const { showNotification } = useNotifications();
  const [apiToken, setApiToken] = useState<string | undefined>();

  const handleCreateApiToken = async () => {
    const result = await createApiToken({}, { additionalTypenames: ['ApiTokenList'] });
    if (!result.error) {
      showNotification(result.data?.createApiToken.message, NotificationType.Success);
      setApiToken(result.data?.createApiToken.api_token);
    }
    if (result.error) {
      const message = get(result.error, 'graphQLErrors[0].message');
      showNotification(message, NotificationType.Error);
    }
  };
  const CommandCell = (props: GridCellProps) => {
    const { dataItem } = props;
    const { t } = useTranslation();
    const confirmDeleteApiToken = useConfirmDeleteApiToken();

    const myAPiToken = dataItem as ApiToken;

    const handleDelete = useCallback(() => {
      confirmDeleteApiToken(myAPiToken.uuid);
    }, [myAPiToken.uuid, confirmDeleteApiToken]);

    const actionItems = [
      {
        actionType: 'delete',
        actionName: t('common.labels.delete'),
        icon: 'delete',
        handler: handleDelete,
      },
    ];

    return (
      <td className="k-command-cell k-text-center">
        <GridActionsButton actions={actionItems} />
      </td>
    );
  };

  return (
    <StackLayout orientation="vertical" gap={10} className="k-px-1">
      <div className="!k-mt-4">
        <b>{t('settings.createApiToken.title')}</b>
        <div>{t('settings.createApiToken.description')}</div>
      </div>
      {generating && <Loader />}
      {apiToken && !generating && (
        <ClipBoardInput
          name="clipboard-api-token"
          value={apiToken}
          iconClass="l-i-copy"
          touched={false}
          modified={false}
          visited={false}
          // Message property is now required
          validationMessage={''}
          valid
          onCopyToClipBoard={() => {
            showNotification(t('settings.createApiToken.copied'), NotificationType.Success);
          }}
        />
      )}

      <PrimaryButton label={t('settings.createApiToken.create')} onClick={handleCreateApiToken} />
      <Grid className="!k-border-0" data={orderBy(data?.myApiTokens.data, ['revoked'], ['asc'])}>
        <GridColumn
          field={'uuid'}
          className="!k-font-weight-semibold"
          title={t('settings.createApiToken.labels.uuid')}
        />
        <GridColumn
          field={'created_at'}
          title={t('settings.createApiToken.labels.createdAtDate')}
          cell={DateTimeCell}
        />
        <GridColumn
          field={'expires_at'}
          title={t('settings.createApiToken.labels.expiresAtDate')}
          cell={DateTimeCell}
        />
        <GridColumn
          field={'revoked'}
          title={t('settings.createApiToken.labels.revoked')}
          cell={BooleanCell}
          width={80}
        />
        <GridColumn
          title={t('common.labels.actions')}
          cell={CommandCell}
          width={100}
          filterable={false}
        />
      </Grid>
    </StackLayout>
  );
};
