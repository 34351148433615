import {
  useAppSettingsAbility,
  useClassificationTagAbility,
  useEmailTemplateAbility,
  usePdfTemplateAbility,
  useServiceCatalogAbility,
  useTaskTemplateAbility,
} from '@module/casl';
import { isTruthy } from '@module/shared/helpers';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export interface SettingsCardItem {
  title: string;
  description: string;
  path: string;
  icon: string;
  type: string;
}

export const useSettingsOverviewItems = () => {
  const { t } = useTranslation();
  const [appSettingsAbility, appSettingsSubject] = useAppSettingsAbility();
  const [taskTemplateAbility, taskTemplateSubject] = useTaskTemplateAbility();
  const [pdfTemplateAbility, pdfTemplateSubject] = usePdfTemplateAbility();
  const [classificationTagAbility, classificationTagSubject] = useClassificationTagAbility();
  const [serviceCatalogAbility, serviceCatalogSubject] = useServiceCatalogAbility();
  const [emailTemplateAbility, emailTemplateSubject] = useEmailTemplateAbility();
  const items: SettingsCardItem[] = useMemo(
    () =>
      [
        appSettingsAbility.can('updateGeneral', appSettingsSubject()) && {
          title: t('settings.generalTab.title'),
          icon: 'l-i-settings-2',
          description: t('settings.generalTab.description'),
          path: '/settings/platform/general',
          type: t('settings.overviewPage.label.settings'),
        },
        taskTemplateAbility.can('updateTaskTemplate', taskTemplateSubject()) && {
          title: t('templates.pages.overview.reportingTitle'),
          icon: 'l-i-clipboard-type',
          description: t('templates.pages.overview.reportingDescription'),
          path: '/settings/templates/reporting',
          type: t('settings.overviewPage.label.template'),
        },
        appSettingsAbility.can('updateStamps', appSettingsSubject()) && {
          title: t('stampTemplates.pages.overview.title'),
          icon: 'l-i-stamp',
          description: t('stampTemplates.pages.overview.description'),
          path: '/settings/stamp-templates',
          type: t('settings.overviewPage.label.template'),
        },
        pdfTemplateAbility.can('updatePdfTemplate', pdfTemplateSubject()) && {
          title: t('pdfForms.pages.overview.title'),
          icon: 'l-i-file-text',
          description: t('pdfForms.pages.overview.description'),
          path: '/settings/pdf-forms',
          type: t('settings.overviewPage.label.template'),
        },
        appSettingsAbility.can('updateModules', appSettingsSubject()) && {
          title: t('settings.modules.title'),
          icon: 'l-i-component',
          description: t('settings.modules.description'),
          path: '/settings/platform/modules',
          type: t('settings.overviewPage.label.settings'),
        },
        classificationTagAbility.can('updateClassificationTag', classificationTagSubject()) && {
          title: t('settings.pages.classificationTags.title'),
          icon: 'l-i-tags',
          description: t('settings.pages.classificationTags.description'),
          path: '/settings/classification-tags',
          type: t('settings.overviewPage.label.template'),
        },
        // TODO: Permission check
        serviceCatalogAbility.can('viewServiceCatalogItems', serviceCatalogSubject()) && {
          title: t(`serviceCatalog.pages.overview.title`),
          icon: 'l-i-album',
          description: t(`serviceCatalog.pages.overview.description`),
          path: '/settings/service-catalog/services',
          type: t('settings.overviewPage.label.template'),
        },
        emailTemplateAbility.can('updateEmailTemplate', emailTemplateSubject()) && {
          title: t('emailTemplates.pages.emailTemplateOverview.title'),
          icon: 'l-i-mail',
          description: t('emailTemplates.pages.emailTemplateOverview.description'),
          path: '/settings/email-templates',
          type: t('settings.overviewPage.label.template'),
        },
      ].filter(isTruthy),
    [
      t,
      appSettingsAbility,
      appSettingsSubject,
      taskTemplateAbility,
      taskTemplateSubject,
      pdfTemplateAbility,
      pdfTemplateSubject,
      classificationTagAbility,
      classificationTagSubject,
      serviceCatalogAbility,
      serviceCatalogSubject,
      emailTemplateAbility,
      emailTemplateSubject,
    ],
  );
  return items;
};
