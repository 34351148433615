import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import { QueryContactsFieldsFragmentDoc } from './contact_types.generated';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ContactsQueryVariables = Types.Exact<{
  filter: Types.ContactsFilter;
  sort: Types.ContactsSort;
  pagination: Types.PaginationInput;
}>;

export type ContactsQuery = {
  __typename?: 'Query';
  contacts: {
    __typename?: 'ContactPaginator';
    data: Array<{
      __typename?: 'Contact';
      id: string;
      client_location_id: string;
      first_name?: string | null;
      name?: string | null;
      street?: string | null;
      street_no?: string | null;
      postcode?: string | null;
      city?: string | null;
      country_id?: string | null;
      fax_number?: string | null;
      phone?: string | null;
      phone2?: string | null;
      mobile?: string | null;
      mobile2?: string | null;
      email?: string | null;
      email2?: string | null;
      iban?: string | null;
      bic?: string | null;
      longitude?: number | null;
      latitude?: number | null;
      salutation_id?: string | null;
      function_id?: string | null;
      note?: string | null;
      country?: { __typename?: 'Country'; title: string } | null;
      client_location?: {
        __typename?: 'ClientLocation';
        street?: string | null;
        street_no?: string | null;
        postcode?: string | null;
        different_policy_holder?: string | null;
        city?: string | null;
        id: string;
        client_id: string;
        country: { __typename?: 'Country'; title: string };
      } | null;
      role?: { __typename?: 'ContactRole'; title: string } | null;
      salutation?: { __typename?: 'Salutation'; id: string; title: string } | null;
      function?: { __typename?: 'ContactFunction'; id: string; title: string } | null;
    }>;
    paginatorInfo: { __typename?: 'PaginatorInfo'; total: number };
  };
};

export const ContactsDocument = gql`
  query Contacts($filter: ContactsFilter!, $sort: ContactsSort!, $pagination: PaginationInput!) {
    contacts(filter: $filter, sort: $sort, pagination: $pagination) {
      data {
        ...QueryContactsFields
      }
      paginatorInfo {
        total
      }
    }
  }
  ${QueryContactsFieldsFragmentDoc}
`;

export function useContactsQuery(
  options: Omit<Urql.UseQueryArgs<ContactsQueryVariables>, 'query'>,
) {
  return Urql.useQuery<ContactsQuery, ContactsQueryVariables>({
    query: ContactsDocument,
    ...options,
  });
}
