import { Label } from '@module/shared/forms/components/Label';
import { FieldRenderProps, FieldWrapper } from '@progress/kendo-react-form';
import { Input } from '@progress/kendo-react-inputs';
import { useMemo } from 'react';

import { TaskTagFragment, useTaskTagsQuery } from '../../graphql';
import { SelectTagMenu } from './SelectTagMenu';

export const TemplateTagInput = (props: FieldRenderProps) => {
  const {
    value,
    id,
    label,
    placeholder,
    required,
    disabled,
    onBlur,
    onChange,
    onFocus,
    autoComplete,
  } = props;

  const handleInsertTag = (tag: TaskTagFragment) => {
    onChange({ value: `${value ?? ''}${tag.key}` });
  };

  const [{ data, fetching }] = useTaskTagsQuery();

  const tags = useMemo(() => {
    return data?.taskTags ?? [];
  }, [data?.taskTags]);

  return (
    <FieldWrapper>
      <div className="k-display-flex k-flex-row k-justify-content-between k-align-items-center">
        <Label label={label}>{label}</Label>
        <SelectTagMenu onSelect={handleInsertTag} tags={tags} fetching={fetching} />
      </div>
      <span className="k-input k-input-solid k-input-lg k-rounded-md">
        <Input
          className="k-input-inner"
          type="text"
          id={id}
          autoComplete={autoComplete}
          value={value || ''}
          placeholder={placeholder}
          required={required}
          disabled={disabled}
          onBlur={onBlur}
          onChange={onChange}
          onFocus={onFocus}
        />
      </span>

      {/* Empty element for consistent spacing */}
      <div className="HintAndError" />
    </FieldWrapper>
  );
};
