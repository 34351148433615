import { Loader as LoaderBase } from '@progress/kendo-react-indicators';
import { ReactNode } from 'react';

export const Loader = ({ children }: { children?: ReactNode }) => {
  return (
    <div className="Loader k-overlay">
      <div className="k-centered k-d-flex-col k-align-items-center k-gap-4">
        <LoaderBase type="infinite-spinner" size="large" />
        {children && <div>{children}</div>}
      </div>
    </div>
  );
};
