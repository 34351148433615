import './CustomUpload.scss';

import { MAX_FILE_SIZE, PSPDFKIT_LICENSE_KEY, ROUTER_BASENAME } from '@env';
import { RefreshButton } from '@module/layout';
import { RequestInitExtended, uploadWithAxios } from '@module/shared/graphql';
import { formatBytes } from '@module/shared/helpers';
import { useObjectURL } from '@module/shared/hooks';
import { useFormatPercentage } from '@module/shared/localization';
import { Button } from '@progress/kendo-react-buttons';
import { classNames } from '@progress/kendo-react-common';
import {
  Upload,
  UploadFileInfo,
  UploadFileStatus,
  UploadListItemProps,
  UploadProps,
  UploadSelectMessageProps,
} from '@progress/kendo-react-upload';
import PSPDFKit from 'pspdfkit';
import {
  createContext,
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { FileType, fileTypeIcons, guessFileTypeFromFileName } from '../../helpers';
import { UploadRestrictions } from './types';
import { CustomUploadHandle, hasGetRawFile, UploadFileInfoWithGetRawFile } from './useCustomUpload';

interface CustomUploadContextType {
  progressHidden: boolean;
  maxFileSize: number;
}

const CustomUploadContext = createContext<CustomUploadContextType | undefined>(undefined);

const useCustomUploadContext = () => {
  const context = useContext(CustomUploadContext);

  if (!context) {
    throw new Error('CustomUploadContext not available');
  }
  return context;
};

interface PreviewProps {
  file: File;
}

const ImagePreview = ({ file }: PreviewProps) => {
  const imageSrc = useObjectURL(file);

  if (!imageSrc) return null;
  return <img src={imageSrc} className="file-preview" />;
};

const PDFPreview = ({ file }: PreviewProps) => {
  const [imageSrc, setImageSrc] = useState<string | null>(null);
  const [containerElement, setContainerElement] = useState<HTMLDivElement | null>(null);

  useEffect(() => {
    let isCanceled = false;

    (async () => {
      if (containerElement) {
        const blob = await file.arrayBuffer();
        if (!blob) return;
        try {
          const basename = ROUTER_BASENAME || '/';
          const instance = await PSPDFKit.load({
            document: blob,
            licenseKey: PSPDFKIT_LICENSE_KEY,
            container: containerElement,
            baseUrl: `${window.location.origin}${basename}`,
          });
          if (instance) {
            const src = await instance.renderPageAsImageURL({ width: 400 }, 0);
            if (isCanceled) {
              URL.revokeObjectURL(src);
            } else {
              setImageSrc(src);
            }
          }
        } catch {
          PSPDFKit.unload(containerElement);
        }
      }
    })();

    return () => {
      isCanceled = true;
      if (containerElement) {
        PSPDFKit.unload(containerElement);
      }
    };
  }, [file, containerElement]);

  return (
    <>
      <div ref={setContainerElement} style={{ width: 1, height: 1, display: 'none' }} />
      {imageSrc ? (
        <img src={imageSrc} className="file-preview" />
      ) : (
        <div className="file-preview">
          <span className={classNames(fileTypeIcons[FileType.PDF])} />
        </div>
      )}
    </>
  );
};

interface FilePreviewProps {
  file: UploadFileInfo;
  fileType: FileType;
}

const FilePreview = ({ file, fileType }: FilePreviewProps) => {
  const icon = fileTypeIcons[fileType];

  const rawFile = file.getRawFile?.();

  if (rawFile && [FileType.JPG, FileType.PNG, FileType.GIF].includes(fileType)) {
    return <ImagePreview file={rawFile} />;
  }

  if (rawFile && fileType === FileType.PDF) {
    return <PDFPreview file={rawFile} />;
  }

  return (
    <div className="file-preview">
      <span className={classNames('file-preview-icon', icon)} />
    </div>
  );
};

export const CustomProgressBar = (props: { className?: string | undefined; value: number }) => {
  const { className, value } = props;
  return (
    <div className={classNames('custom-progress-bar', className)}>
      <div className="progress" style={{ width: value.toFixed(0) + '%' }} />
    </div>
  );
};

interface CustomUploadButtonTextProps extends UploadSelectMessageProps {
  multiple: boolean | undefined;
  singleFileMessage: string;
}

const CustomUploadButtonText = (props: CustomUploadButtonTextProps) => {
  const { message, multiple, singleFileMessage } = props;
  return <span>{multiple ? message : singleFileMessage}</span>;
};

interface CustomUploadListItemProps extends Omit<UploadListItemProps, 'files'> {
  file: UploadFileInfo;
}

const CustomUploadListItem = (props: CustomUploadListItemProps) => {
  const { file } = props;
  const { t } = useTranslation();
  const hasValidationErrors = (file.validationErrors ?? []).length > 0;
  const fileType = guessFileTypeFromFileName(file.name);

  const { progressHidden, maxFileSize } = useCustomUploadContext();

  const formatPercentage = useFormatPercentage();
  const onCancel = useCallback(() => props.onCancel(file.uid), [file.uid, props]);
  const onRemove = useCallback(() => props.onRemove(file.uid), [file.uid, props]);
  const onRetry = useCallback(() => props.onRetry(file.uid), [file.uid, props]);
  const size = useMemo(() => formatBytes(file.size ?? 0), [file.size]);

  return (
    <div
      className={classNames(
        'custom-upload-list-item',
        'k-border-solid',
        file.status === UploadFileStatus.Uploaded
          ? 'k-border-primary'
          : [UploadFileStatus.UploadFailed, UploadFileStatus.RemoveFailed].includes(file.status) ||
              hasValidationErrors
            ? 'k-border-error'
            : 'k-border-light',
      )}
    >
      <FilePreview file={file} fileType={fileType} />
      <div className="wrapper">
        <div className="row">
          <div className="col">
            <h4 className="k-m-0">{file.name}</h4>
            {(file.validationErrors ?? []).map((validationError) => (
              <span key={validationError} className="k-text-error">
                {t(
                  `common.components.customUpload.validationErrors.${validationError}`,
                  validationError === 'invalidMaxFileSize'
                    ? { maxFileSize: formatBytes(maxFileSize) }
                    : {},
                )}
              </span>
            ))}
          </div>
          <div className="col-auto k-text-right">
            {hasValidationErrors && (
              <Button
                iconClass="l-i-trash-2"
                fillMode="flat"
                onClick={onCancel}
                aria-label={t('common.labels.cancel')}
              />
            )}
            {file.status === UploadFileStatus.Uploaded && (
              <div className="filled-icon">
                <span className="filled-icon-icon l-i-check" />
              </div>
            )}
            {file.status === UploadFileStatus.UploadFailed && (
              <>
                <RefreshButton onClick={onRetry} onlyIcon />
                <Button
                  iconClass="l-i-trash-2"
                  fillMode="flat"
                  onClick={onRemove}
                  aria-label={t('common.labels.delete')}
                />
              </>
            )}
            {![
              UploadFileStatus.Uploaded,
              UploadFileStatus.Uploading,
              UploadFileStatus.UploadFailed,
            ].includes(file.status) &&
              !hasValidationErrors && (
                <Button
                  iconClass="l-i-trash-2"
                  fillMode="flat"
                  onClick={onRemove}
                  aria-label={t('common.labels.delete')}
                />
              )}
          </div>
        </div>

        <div className="row">
          <span>{size}</span>
        </div>

        {!progressHidden && (
          <div className="row">
            <div className="col k-display-flex k-align-items-center ">
              <CustomProgressBar value={file.progress} />
            </div>
            <div className="col-auto k-text-right">
              <span>{formatPercentage(file.progress / 100)}</span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const CustomUploadList = (props: UploadListItemProps) => {
  const { files, ...rest } = props;
  return (
    <>
      {files.map((file) => (
        <CustomUploadListItem key={file.uid} file={file} {...rest} />
      ))}
    </>
  );
};

interface OkResult {
  isOk: true;
  uid: string;
}
interface ErrorResult {
  isOk: false;
  uid: string;
}
type UploadResult = OkResult | ErrorResult;

const resolveUpload = (uid: string): OkResult => ({ isOk: true, uid });
const rejectUpload = (uid: string): ErrorResult => ({ isOk: false, uid });

export interface CustomUploadSaveEvent {
  file: UploadFileInfoWithGetRawFile;
  resolve: typeof resolveUpload;
  reject: typeof rejectUpload;
  context: {
    fetchOptions: RequestInitExtended;
    fetch: (input: RequestInfo | URL, init?: RequestInitExtended) => Promise<Response>;
  };
}

export type CustomUploadOnSave = (event: CustomUploadSaveEvent) => Promise<UploadResult>;

export interface CustomUploadProps
  extends Omit<
      UploadProps,
      | 'accept'
      | 'restrictions'
      | 'batch'
      | 'listItemUI'
      | 'selectMessageUI'
      | 'saveUrl'
      | 'removeUrl'
    >,
    UploadRestrictions {
  progressHidden?: boolean;
  dropZoneHintHidden?: boolean;
  onSave?: CustomUploadOnSave;
}

export const CustomUpload = forwardRef<CustomUploadHandle, CustomUploadProps>(
  (props, forwardedRef) => {
    const {
      className,
      allowedExtensions,
      maxFileSize = MAX_FILE_SIZE,
      minFileSize,
      autoUpload = false,
      multiple = true,
      progressHidden = false,
      dropZoneHintHidden = false,
      onSave,
      ...rest
    } = props;

    const restrictionProps = useMemo(() => {
      const flatExtensions = allowedExtensions.flat();

      return {
        accept: flatExtensions.map((extension) => `.${extension}`).join(','),
        restrictions: {
          maxFileSize,
          minFileSize,
          allowedExtensions: flatExtensions,
        },
      };
    }, [allowedExtensions, maxFileSize, minFileSize]);

    const { t } = useTranslation();
    const uploadRef = useRef<Upload>(null);

    useImperativeHandle(
      forwardedRef,
      () => {
        return {
          triggerUpload: () => {
            uploadRef.current?.triggerUpload();
          },
          ...uploadRef.current,
        };
      },
      [],
    );

    const handleSave = useCallback<Exclude<NonNullable<UploadProps['saveUrl']>, string>>(
      async (files, _, onProgress) => {
        // this is called once per file selected
        const file = files[0];

        const fetchOptions: RequestInitExtended = {
          onUploadProgress: (progress: ProgressEvent) => {
            onProgress(file.uid, progress);
          },
        };

        if (onSave && hasGetRawFile(file)) {
          const result = await onSave({
            file,
            resolve: resolveUpload,
            reject: rejectUpload,
            context: { fetchOptions, fetch: uploadWithAxios },
          });

          if (result.isOk) {
            return result;
          }

          throw result;
        }

        throw new Error('CustomInput onSave property must be set');
      },
      [onSave],
    );

    return (
      <CustomUploadContext.Provider value={{ progressHidden, maxFileSize }}>
        <Upload
          {...rest}
          {...restrictionProps}
          ref={uploadRef}
          batch={false}
          showActionButtons={false}
          autoUpload={autoUpload}
          multiple={multiple}
          saveUrl={onSave ? handleSave : undefined}
          className={classNames(
            'custom-upload',
            dropZoneHintHidden && 'dropzone-hint-hidden',
            className,
          )}
          listItemUI={CustomUploadList}
          selectMessageUI={(messageProps) => (
            <CustomUploadButtonText
              multiple={multiple}
              singleFileMessage={t('common.components.customUpload.uploadFile')}
              {...messageProps}
            />
          )}
        />
      </CustomUploadContext.Provider>
    );
  },
);
CustomUpload.displayName = 'CustomUpload';
