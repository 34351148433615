export default {
  labels: {
    device_code: 'Geräte-ID',
    name: 'Name',
    device_type: 'Typ',
    category: 'Kategorie',
    state: 'Status',
    value: 'Wert',
  },
  pages: {
    overview: {
      title: 'Geräte',
      description: 'Hier können Sie Geräte verwalten.',
      toolbar: {
        createDevice: 'Neues Gerät',
        editDeviceCategories: 'Kategorien verwalten',
      },
    },
  },
  forms: {
    device: {
      name: {
        label: 'Name des Geräts',
      },
      device_code: {
        label: 'Geräte-ID',
      },
      device_type: {
        label: 'Gerätetyp',
      },
      category_id: {
        label: 'Gerätekategorie',
      },
      state_id: {
        label: 'Status',
      },
      value: {
        label: 'Wert',
      },
      unit_id: {
        label: 'Einheit',
      },
    },
  },
  dialogs: {
    createDevice: {
      title: 'Neues Gerät anlegen',
    },
    updateDevice: {
      title: 'Gerät bearbeiten',
    },
    deviceCategories: {
      title: 'Gerätekategorien verwalten',
      labels: {
        createCategory: 'Neue Kategorie',
      },
    },
  },
  notifications: {
    createDevice: {
      success: 'Gerät wurde erstellt.',
    },
    updateDevice: {
      success: 'Gerät wurde aktualisiert.',
    },
  },
};
