export default {
  labels: {
    area: 'Područje',
    residents: 'Rezident',
    client_primary: 'AR1',
    client_secondary: 'AR2',
    client_tertiary: 'AR3',
    client_leader: 'Voditelj tima',
  },
  dialogs: {
    update: {
      title: 'Uredite područje zadatka.',
      note: 'Ovdje možete postaviti odgovorne osobe i voditelje timova za područje dodjele.',
    },
    import: {
      title: 'Uvoz zadataka',
      downloadHint: 'Preuzmite oglednu tabelu',
      import: 'Uvoz',
    },
  },
  pages: {
    overview: {
      title: 'Područje dodjele',
      description: 'Ovdje možete vidjeti popis poštanskih područja i odgovornih osoba.',
      list: 'Lista',
      map: 'Karta',
      import: 'Uvoz',
      export: 'Izvoz',
    },
  },
  notifications: {
    update_area_assignment: {
      success: 'Područje zadataka je spremljeno.',
    },
  },
};
