import { noop } from '@progress/kendo-react-common';
import { useCallback, useState } from 'react';

import { PersistedDrawerState } from './types';

const DRAWER_STATE_STORAGE_KEY = 'drawerstate:v1';

const initialDrawerState: PersistedDrawerState = {
  expanded: false,
};

function loadDrawerState(): PersistedDrawerState | undefined {
  try {
    const drawerStateJSON = localStorage.getItem(DRAWER_STATE_STORAGE_KEY);
    if (drawerStateJSON) {
      return JSON.parse(drawerStateJSON);
    }
  } catch (e) {
    noop();
  }
  return undefined;
}

function saveDrawerState(drawserState: PersistedDrawerState) {
  localStorage.setItem(DRAWER_STATE_STORAGE_KEY, JSON.stringify(drawserState));
}

function getInitialDrawerState(): PersistedDrawerState {
  return loadDrawerState() ?? initialDrawerState;
}

export function usePersistedDrawerState(): [
  PersistedDrawerState,
  (drawerState: PersistedDrawerState) => void,
] {
  const [drawerState, setDrawerState] = useState(getInitialDrawerState);

  const persistDrawerState = useCallback((drawerStateNew: PersistedDrawerState) => {
    saveDrawerState(drawerStateNew);
    setDrawerState(drawerStateNew);
  }, []);

  return [drawerState, persistDrawerState];
}
