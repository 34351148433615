import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type DeleteAppMessageMutationVariables = Types.Exact<{
  app_message_id: Types.Scalars['ID'];
}>;

export type DeleteAppMessageMutation = {
  __typename?: 'Mutation';
  deleteAppMessage: { __typename?: 'AppMessage'; id: string };
};

export const DeleteAppMessageDocument = gql`
  mutation DeleteAppMessage($app_message_id: ID!) {
    deleteAppMessage(app_message_id: $app_message_id) {
      id
    }
  }
`;

export function useDeleteAppMessageMutation() {
  return Urql.useMutation<DeleteAppMessageMutation, DeleteAppMessageMutationVariables>(
    DeleteAppMessageDocument,
  );
}
