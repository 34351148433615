import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CountriesQueryVariables = Types.Exact<{ [key: string]: never }>;

export type CountriesQuery = {
  __typename?: 'Query';
  countries: Array<{ __typename?: 'Country'; id: string; title: string }>;
};

export const CountriesDocument = gql`
  query Countries {
    countries {
      id
      title
    }
  }
`;

export function useCountriesQuery(
  options?: Omit<Urql.UseQueryArgs<CountriesQueryVariables>, 'query'>,
) {
  return Urql.useQuery<CountriesQuery, CountriesQueryVariables>({
    query: CountriesDocument,
    ...options,
  });
}
