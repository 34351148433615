import { FilterField, TextInputFilter, useFilters } from '@module/shared/filters';
import { useMemo } from 'react';

export const useInterfaceMessageTemplatesFilters = () => {
  const searchFields = useMemo<Array<FilterField>>(
    () => [
      {
        name: 'title',
        filter: TextInputFilter,
        operator: 'contains',
      },
    ],
    [],
  );
  const filterContext = useFilters(searchFields);
  return filterContext;
};
