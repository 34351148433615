import './FooterMini.scss';

import { useAppSettingsPublicQuery } from '@module/common/graphql';
import { useBrandingData } from '@module/common/hooks';
import { useTranslation } from 'react-i18next';

import { ImprintPrivacyTerms } from '../pages/Login/ImprintPrivacyTerms';

export const FooterMini = () => {
  const { t } = useTranslation();
  const branding = useBrandingData();
  const [{ data, fetching }] = useAppSettingsPublicQuery();
  if (fetching) return null;
  return (
    <div className="FooterMini">
      <div className="k-display-flex !k-gap-4 k-pt-4 k-flex-wrap k-justify-content-center !k-align-items-center">
        <span className="k-font-weight-semibold">
          {data?.appSettingsPublic.app_title ?? branding.name}
        </span>
        <span className="k-fs-sm">
          {t('common.labels.version')} {branding.version}
        </span>
      </div>
      <ImprintPrivacyTerms
        imprint={data?.appSettingsPublic?.imprint}
        privacy={data?.appSettingsPublic?.privacy}
        conditions={data?.appSettingsPublic?.conditions}
      />
    </div>
  );
};
