export default {
  dialogs: {
    confirmCreateRandomTasks: {
      title: 'Create Demo Data',
      description:
        'Warning, this will create the selected tasks in the portal. Do you want to continue?',
    },
  },
  forms: {
    createRandomTasks: {
      title: 'Demo-Data',
      description: 'Here you cen generate demo data.',
      numTasks: 'Number of demo tasks',
      numTasksTooltip:
        'Please note that at least one insurance, one regulator and one team leader must exist to create tasks.',
      state: 'State',
      stateOptions: {
        NEW: 'New',
        ACCEPTED: 'Accepted',
      },
      urgent: 'Urgent',
      submit: 'Generate data',
    },
  },
  notifications: {
    createRandomTasks: {
      success: 'Demo data successfully generated.',
      error: 'Error while generating demo data.',
    },
  },
  pages: {
    demoData: {
      title: 'Demo-Data',
      description: 'Here you can generate demo data.',
    },
  },
};
