import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
export type CalculationItemFragment = {
  __typename?: 'TaskCalculationItem';
  id: string;
  title?: string | null;
  description?: string | null;
  accepted?: number | null;
  amount?: number | null;
  single_price?: number | null;
  sort_index?: number | null;
  time_value?: number | null;
  age?: number | null;
  correction_condition?: number | null;
  max_usage_years?: number | null;
  tax: number;
  own_contribution: boolean;
  state?: {
    __typename?: 'TaskCalculationItemState';
    id: string;
    title: string;
    color: string;
  } | null;
  payout_state?: {
    __typename?: 'TaskCalculationItemPayoutState';
    id: string;
    title: string;
    color: string;
  } | null;
  unit?: { __typename?: 'ServiceCatalogUnit'; id: string; title: string } | null;
  category?: {
    __typename?: 'TaskCalculationCategory';
    id: string;
    title: string;
    sort_index: number;
  } | null;
};

export const CalculationItemFragmentDoc = gql`
  fragment CalculationItem on TaskCalculationItem {
    id
    title
    description
    state {
      id
      title
      color
    }
    payout_state {
      id
      title
      color
    }
    unit {
      id
      title
    }
    category {
      id
      title
      sort_index
    }
    accepted
    amount
    single_price
    sort_index
    time_value
    age
    correction_condition
    max_usage_years
    tax
    own_contribution
  }
`;
