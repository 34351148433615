import { useCallback } from 'react';

import { InitialLoginInput, LoginInput, VerifyLoginInput } from '../../../types/graphql.generated';
import {
  convertResponseToAuthState,
  useInitialLoginMutation,
  useLoginAsCustomerMutation,
  useLoginAsOtherUserMutation,
  useLoginMutation,
  useLogoutMutation,
  useRestoreFromOtherUserMutation,
  useVerifyLoginMutation,
} from '../graphql';
import { useAuthContext } from './useAuthConext';

export function useAuthLogic() {
  const {
    clearAuthState,
    getAuthState,
    saveAuthState,
    clearAuthStatePrevious,
    getAuthStatePrevious,
    saveAuthStatePrevious,
  } = useAuthContext();
  const [{ fetching: isLoggingIn }, loginMutation] = useLoginMutation();
  const [{ fetching: isVerifying }, verifyLoginMutation] = useVerifyLoginMutation();
  const [{ fetching: isInitiallyLoggingIn }, initialLoginMutation] = useInitialLoginMutation();
  const [{ fetching: isLoggingInAsOtherUser }, loginAsOtherUserMutation] =
    useLoginAsOtherUserMutation();
  const [{ fetching: isRestoringFromOtherUser }, restoreFromOtherUser] =
    useRestoreFromOtherUserMutation();
  const [{ fetching: isLoggingInAsCustomer }, loginAsCustomerMutation] =
    useLoginAsCustomerMutation();
  useLoginAsOtherUserMutation();
  const [{ fetching: isLoggingOut }, logoutMutation] = useLogoutMutation();

  // fetchOptions.credentials is required in order to set cookies in POST cross domain requests
  // cors must be configured accordingly on the server side
  const initialLogin = useCallback(
    (input: InitialLoginInput) => {
      return initialLoginMutation({ input }, { fetchOptions: { credentials: 'include' } }).then(
        (response) => {
          if (!response.error && response.data?.initialLogin.authState) {
            const authState = convertResponseToAuthState(response.data?.initialLogin.authState);
            saveAuthState(authState);
          }
          return response;
        },
      );
    },
    [initialLoginMutation, saveAuthState],
  );

  const login = useCallback(
    (input: LoginInput) => {
      return loginMutation({ input }, { fetchOptions: { credentials: 'include' } }).then(
        (response) => {
          if (!response.error && response.data?.login.authState) {
            const authState = convertResponseToAuthState(response.data?.login.authState);
            saveAuthState(authState);
          }
          return response;
        },
      );
    },
    [loginMutation, saveAuthState],
  );

  const loginAsOtherUser = useCallback(
    (userId: string) => {
      return loginAsOtherUserMutation(
        { userId },
        { fetchOptions: { credentials: 'include' } },
      ).then((response) => {
        const existingAuthState = getAuthState();

        if (!response.error && response.data?.loginAsOtherUser.authState) {
          const authState = convertResponseToAuthState(response.data?.loginAsOtherUser.authState);

          saveAuthStatePrevious(existingAuthState);
          saveAuthState(authState);
        }
        return response;
      });
    },
    [getAuthState, loginAsOtherUserMutation, saveAuthState, saveAuthStatePrevious],
  );

  const loginAsCustomer = useCallback(
    (hash: string) => {
      return loginAsCustomerMutation({ hash }, { fetchOptions: { credentials: 'include' } }).then(
        (response) => {
          if (!response.error && response.data?.loginAsCustomer.authState) {
            const authState = convertResponseToAuthState(response.data?.loginAsCustomer.authState);
            saveAuthState(authState);
          }
          return response;
        },
      );
    },
    [loginAsCustomerMutation, saveAuthState],
  );

  const verifyLogin = useCallback(
    async (input: VerifyLoginInput) => {
      const response = await verifyLoginMutation(
        { input },
        { fetchOptions: { credentials: 'include' } },
      );

      if (!response.error && response.data?.verifyLogin.authState) {
        const authState = convertResponseToAuthState(response.data.verifyLogin.authState);
        saveAuthState(authState);
      }

      return response;
    },
    [verifyLoginMutation, saveAuthState],
  );

  const logout = useCallback(() => {
    return logoutMutation().then((response) => {
      clearAuthState();

      return response;
    });
  }, [clearAuthState, logoutMutation]);

  const restorePreviousUser = useCallback(async () => {
    const previousAuthState = getAuthStatePrevious();

    if (previousAuthState) {
      clearAuthStatePrevious();
      saveAuthState(previousAuthState);

      await restoreFromOtherUser();

      return true;
    }

    return false;
  }, [clearAuthStatePrevious, getAuthStatePrevious, restoreFromOtherUser, saveAuthState]);

  return {
    initialLogin,
    login,
    loginAsOtherUser,
    loginAsCustomer,
    verifyLogin,
    restorePreviousUser,
    isLoggingIn:
      isLoggingIn ||
      isLoggingInAsOtherUser ||
      isInitiallyLoggingIn ||
      isLoggingInAsCustomer ||
      isRestoringFromOtherUser,
    isVerifying,
    logout,
    isLoggingOut,
  };
}
