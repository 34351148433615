import { Dialog, DialogContent, DialogHeader, DialogHeaderBar } from '@module/layout';
import { DialogProps, useDialogs } from '@module/shared/dialogs';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

export interface PreviewImageDialogProps {
  image: string | undefined;
}

export const PreviewImageDialog = (props: PreviewImageDialogProps & DialogProps) => {
  const { t } = useTranslation();
  const { hideDialog } = useDialogs();
  const { image } = props;
  const handleClose = useCallback(() => {
    hideDialog(props.dialogId);
  }, [hideDialog, props.dialogId]);

  return (
    <Dialog onClose={handleClose} className={'imagePreviewDialog'}>
      <DialogHeaderBar>
        <DialogHeader title={t('common.labels.preview')} />
      </DialogHeaderBar>
      <DialogContent>{image ? <img src={image} style={{ width: '100%' }} /> : null}</DialogContent>
    </Dialog>
  );
};
