import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import { FileClassificationTagFragmentDoc } from './fileClassificationTagFragment.generated';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type FileClassificationTagsQueryVariables = Types.Exact<{
  filter: Types.FileClassificationTagsFilter;
  sort: Types.FileClassificationTagsSort;
  pagination: Types.PaginationInput;
}>;

export type FileClassificationTagsQuery = {
  __typename?: 'Query';
  fileClassificationTags: {
    __typename?: 'FileClassificationTagsPaginator';
    data: Array<{
      __typename?: 'FileClassificationTag';
      id: string;
      title: string;
      color: string;
      alias: string;
      type_id: Types.FileClassificationTagTypeId;
    }>;
    paginatorInfo: { __typename?: 'PaginatorInfo'; total: number };
  };
};

export const FileClassificationTagsDocument = gql`
  query FileClassificationTags(
    $filter: FileClassificationTagsFilter!
    $sort: FileClassificationTagsSort!
    $pagination: PaginationInput!
  ) {
    fileClassificationTags(filter: $filter, sort: $sort, pagination: $pagination) {
      data {
        ...FileClassificationTag
      }
      paginatorInfo {
        total
      }
    }
  }
  ${FileClassificationTagFragmentDoc}
`;

export function useFileClassificationTagsQuery(
  options: Omit<Urql.UseQueryArgs<FileClassificationTagsQueryVariables>, 'query'>,
) {
  return Urql.useQuery<FileClassificationTagsQuery, FileClassificationTagsQueryVariables>({
    query: FileClassificationTagsDocument,
    ...options,
  });
}
