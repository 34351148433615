import Maintenance from '@image/empty-states/Maintenance.svg';
import { PublicLayout } from '@module/layout';
import { useTranslation } from 'react-i18next';

export const MaintenancePage = () => {
  const { t } = useTranslation();

  return (
    <PublicLayout>
      <div className="k-display-flex k-flex-grow k-flex-column k-justify-content-center k-align-items-center MaintenancePage">
        <div className="k-display-flex k-flex-column k-justify-content-center">
          <div>
            <img src={Maintenance} width={240} />
          </div>
        </div>
        <h1>{t('auth.pages.maintenance.title')}</h1>
        <p>{t('auth.pages.maintenance.note')}</p>
      </div>
    </PublicLayout>
  );
};
