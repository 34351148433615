import { Input, InputChangeEvent } from '@progress/kendo-react-inputs';

import { FilterProps } from '../types';

export type TextInputFilterProps = FilterProps;

export const TextInputFilter = (props: TextInputFilterProps) => {
  const {
    filter: { field, operator, value },
  } = props;

  const handleChange = (e: InputChangeEvent) => {
    const value = e.value;
    const nextFilter = { field, operator, value, text: '' };
    props.onFilterChange({ nextFilter });
  };
  return (
    <span className="k-input k-input-lg k-rounded-md k-input-solid !u-width-full">
      <Input value={value} onChange={handleChange} className="k-input-inner" />
    </span>
  );
};
