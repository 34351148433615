import { Button } from '@progress/kendo-react-buttons';
import { useTranslation } from 'react-i18next';

import { useDrawerContext } from './useDrawerContext';

export const DrawerToggleButton = () => {
  const { toggleMobileExpanded } = useDrawerContext();
  const { t } = useTranslation();

  return (
    <Button
      iconClass="l-i-menu"
      size="large"
      fillMode="flat"
      onClick={toggleMobileExpanded}
      aria-label={t('common.labels.open')}
    />
  );
};
