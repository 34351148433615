import { HtmlContent } from '@module/common';
import { TemplateTagEditor, TemplateTagInput } from '@module/common/components';
import { AutoFillGrid } from '@module/layout';
import {
  FormGroupHorizontal,
  FormGroupVertical,
  RemainingCharacterIndicator,
  TextAreaInput,
  TextInput,
  useFormContext,
} from '@module/shared/forms';
import { Field, FieldRenderProps } from '@progress/kendo-react-form';
import { Card, CardBody, CardHeader } from '@progress/kendo-react-layout';
import { isString } from 'lodash';
import { useTranslation } from 'react-i18next';

import { InterfaceMessageTemplateCategorySelect } from './InterfaceMessageTemplateCategorySelect';

const TextAreaField = (props: FieldRenderProps) => {
  const { value } = props;

  return (
    <div>
      <TextAreaInput {...props} value={value} />
      <RemainingCharacterIndicator current={value?.length ?? 0} max={250} />
    </div>
  );
};

export const InterfaceMessageTemplateFormFields = () => {
  const { t } = useTranslation();

  const { valueGetter } = useFormContext();

  const bodyRaw = valueGetter('body') ?? '';
  const subjectRaw = valueGetter('subject') ?? '';

  const body: string = !isString(bodyRaw) ? '' : bodyRaw;
  const subject: string = !isString(subjectRaw) ? '' : subjectRaw;

  return (
    <AutoFillGrid maxColumns={2} columnMinWidth="300px" gap="15px">
      <FormGroupVertical>
        <FormGroupHorizontal maxColumns={2} columnMinWidth="300px">
          <Field
            id="interfaceMessageTemplates.formFields.title"
            name="title"
            label={t('interfaceMessageTemplates.labels.title')}
            component={TextInput}
          />
          <Field
            id="interfaceMessageTemplates.formFields.category_id"
            name="category_id"
            label={t('interfaceMessageTemplates.labels.category')}
            component={InterfaceMessageTemplateCategorySelect}
          />
        </FormGroupHorizontal>
        <Field
          id="interfaceMessageTemplates.formFields.description"
          name="description"
          label={t('interfaceMessageTemplates.labels.description')}
          component={TextAreaField}
        />
        <Field
          id="interfaceMessageTemplates.formFields.message_type"
          name="message_type"
          label={t('interfaceMessageTemplates.labels.messageType')}
          component={TextInput}
        />
        <Field
          id="interfaceMessageTemplates.formFields.subject"
          name="subject"
          label={t('interfaceMessageTemplates.labels.subject')}
          component={TemplateTagInput}
        />
        <Field
          id="interfaceMessageTemplates.formFields.body"
          name="body"
          label={t('interfaceMessageTemplates.labels.body')}
          component={TemplateTagEditor}
        />
      </FormGroupVertical>
      <Card className="k-p-4 k-h-full">
        <CardHeader>
          <h3 className="k-m-0">{t('common.labels.preview')}</h3>
        </CardHeader>
        <CardBody>
          <h3>
            <HtmlContent html={subject} />
          </h3>
          <div>
            <HtmlContent html={body} />
          </div>
        </CardBody>
      </Card>
    </AutoFillGrid>
  );
};
