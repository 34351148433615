import { useAuthContext } from '@module/auth';
import { useMeQueryContext } from '@module/casl';
import { LoadingPage } from '@module/layout';
import { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';

import { useAppSettingsQuery } from '../../graphql';
import { isGraphQLError } from '../../helpers';
import { AppSettingsProvider } from '../../hooks';

interface AppSettingsLoaderProps {
  children: ReactNode;
}

export const AppSettingsLoader = (props: AppSettingsLoaderProps) => {
  const { fetching: isLoadingMe, error } = useMeQueryContext();
  const [{ fetching: isLoadingAppSettings, data }] = useAppSettingsQuery();
  const { clearAuthState } = useAuthContext();

  const isLoading = isLoadingMe || isLoadingAppSettings;

  if (!isLoadingMe && error && isGraphQLError(error, 403)) {
    clearAuthState();
    return <Navigate to="/login" />;
  }

  return (
    <>
      {isLoading && <LoadingPage />}
      {!isLoading && data && <AppSettingsProvider data={data} {...props} />}
    </>
  );
};
