import { classNames } from '@progress/kendo-react-common';
import { InputHandle, InputProps } from '@progress/kendo-react-inputs';
import { omit } from 'lodash';
import { ChangeEvent, useCallback, useMemo } from 'react';

interface IconInputProps extends InputProps {
  icon?: string;
  iconClass?: string;
}

export const IconInput = (props: IconInputProps) => {
  const { className, valid, style, icon, iconClass } = props;

  const restProps = omit(props, [
    'className',
    'label',
    'id',
    'validationMessage',
    'defaultValue',
    'valid',
    'ariaLabelledBy',
    'ariaDescribedBy',
    'validityStyles',
    'style',
    'icon',
    'iconClass',
  ]);

  const classNameComputed = useMemo(() => {
    return classNames(
      'k-input k-input-md k-rounded-md k-input-solid',
      className,
      valid ? 'k-valid' : undefined,
    );
  }, [className, valid]);

  const iconClassName = useMemo(() => {
    return classNames(icon ? `k-font-icon k-i-${icon}` : undefined, iconClass);
  }, [icon, iconClass]);

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      props.onChange?.({
        syntheticEvent: event,
        nativeEvent: event.nativeEvent,
        value: event.target.value,
        target: event.target as unknown as InputHandle,
      });
    },
    [props],
  );

  return (
    <span className={classNameComputed} style={style}>
      <input className="k-input-inner" {...restProps} onChange={handleChange} />
      <span className="k-input-icon">
        <span className={iconClassName} />
      </span>
    </span>
  );
};
