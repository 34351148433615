import { useEffect } from 'react';

import { useHistory } from '../history/HistoryContext';

export interface TabData {
  translationKey: string;
  tabs: string[];
}

export interface PageTitleProps {
  title?: string;
}

export const PageTitle = (props: PageTitleProps) => {
  const { setHistoryTitle } = useHistory();
  useEffect(() => {
    document.title = props.title ?? '';
    if (props.title) setHistoryTitle(props.title);
  }, [props.title, setHistoryTitle]);

  return null;
};
