import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import { ClientLocationFieldsFragmentDoc } from './client_types.generated';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ClientLocationsQueryVariables = Types.Exact<{
  client_id: Types.Scalars['ID'];
  filter: Types.ClientLocationsFilter;
  sort: Types.ClientLocationsSort;
  pagination: Types.PaginationInput;
}>;

export type ClientLocationsQuery = {
  __typename?: 'Query';
  clientLocations: {
    __typename?: 'ClientLocationPaginator';
    data: Array<{
      __typename?: 'ClientLocation';
      id: string;
      client_location_type_id: Types.ClientLocationTypeId;
      primary?: boolean | null;
      street_no?: string | null;
      postcode?: string | null;
      city?: string | null;
      country_id?: string | null;
      street_full?: string | null;
      street?: string | null;
      latitude?: number | null;
      longitude?: number | null;
      notes?: string | null;
      client_id: string;
      different_policy_holder?: string | null;
      different_insurance_policy_number?: string | null;
      country: { __typename?: 'Country'; title: string };
      client_location_type: { __typename?: 'ClientLocationType'; title: string };
    }>;
    paginatorInfo: { __typename?: 'PaginatorInfo'; total: number };
  };
};

export const ClientLocationsDocument = gql`
  query ClientLocations(
    $client_id: ID!
    $filter: ClientLocationsFilter!
    $sort: ClientLocationsSort!
    $pagination: PaginationInput!
  ) {
    clientLocations(client_id: $client_id, filter: $filter, sort: $sort, pagination: $pagination) {
      data {
        id
        ...ClientLocationFields
      }
      paginatorInfo {
        total
      }
    }
  }
  ${ClientLocationFieldsFragmentDoc}
`;

export function useClientLocationsQuery(
  options: Omit<Urql.UseQueryArgs<ClientLocationsQueryVariables>, 'query'>,
) {
  return Urql.useQuery<ClientLocationsQuery, ClientLocationsQueryVariables>({
    query: ClientLocationsDocument,
    ...options,
  });
}
