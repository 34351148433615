import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type TaskGroupFragment = {
  __typename?: 'TaskGroup';
  id: string;
  title: string;
  color: string;
};

export type TaskGroupsQueryVariables = Types.Exact<{
  filter: Types.TaskGroupFilter;
  sort: Types.TaskGroupSort;
  pagination: Types.PaginationInput;
}>;

export type TaskGroupsQuery = {
  __typename?: 'Query';
  taskGroups: {
    __typename?: 'TaskGroupPaginator';
    data: Array<{ __typename?: 'TaskGroup'; id: string; title: string; color: string }>;
    paginatorInfo: { __typename?: 'PaginatorInfo'; total: number };
  };
};

export const TaskGroupFragmentDoc = gql`
  fragment TaskGroup on TaskGroup {
    id
    title
    color
  }
`;
export const TaskGroupsDocument = gql`
  query TaskGroups(
    $filter: TaskGroupFilter!
    $sort: TaskGroupSort!
    $pagination: PaginationInput!
  ) {
    taskGroups(filter: $filter, sort: $sort, pagination: $pagination) {
      data {
        ...TaskGroup
      }
      paginatorInfo {
        total
      }
    }
  }
  ${TaskGroupFragmentDoc}
`;

export function useTaskGroupsQuery(
  options: Omit<Urql.UseQueryArgs<TaskGroupsQueryVariables>, 'query'>,
) {
  return Urql.useQuery<TaskGroupsQuery, TaskGroupsQueryVariables>({
    query: TaskGroupsDocument,
    ...options,
  });
}
