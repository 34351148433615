import { ThemeConfig, ThemeVariables } from './types';

export const createThemeConfig = (
  themeId: ThemeConfig['themeId'],
  variables: Record<string, string>,
  load: ThemeConfig['load'],
): ThemeConfig => ({
  themeId,
  variables: variables as unknown as ThemeVariables,
  load,
});
