import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
export type CountryFragment = { __typename?: 'Country'; id: string; title: string };

export const CountryFragmentDoc = gql`
  fragment Country on Country {
    id
    title
  }
`;
