export default {
  labels: {
    to: 'An:',
    cc: 'CC:',
    bcc: 'BCC:',
    subject: 'Betreff:',
    body: 'Inhalt',
    attachments: 'Anhänge:',
    send: 'Senden',
    chooseEmailTemplate: 'Vorlage wählen',
    priority: {
      lowest: 'Niedrigste',
      low: 'Niedrig',
      normal: 'Normal',
      high: 'Hoch',
      highest: 'Höchste',
    },
  },
  notifications: {
    createEmail: {
      success: 'E-Mail gesendet!',
      error: 'Empfängeraddresse darf nicht leer sein!',
    },
    emailComposer: {
      error: 'Sie haben keine Berechtigung zum Senden von E-Mails!',
    },
  },
  dialogs: {
    emailComposer: {
      title: 'E-Mail verfassen',
    },
  },
};
