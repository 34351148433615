import './SettingsSection.scss';

import { useClientAbility } from '@module/casl';
import { Checkbox } from '@progress/kendo-react-inputs';
import { useTranslation } from 'react-i18next';
import { useLocalstorageState } from 'rooks';

export const BrowerSettingsSection = () => {
  const { t } = useTranslation();
  const [clientAbility, clientSubject] = useClientAbility();
  const [openTaskInNewTab, setOpenTaskInNewTab] = useLocalstorageState('openTaskInNewTab', false);
  const [openClientInNewTab, setOpenClientInNewTab] = useLocalstorageState(
    'openClientInNewTab',
    false,
  );
  const [taskAfterLogin, setTaskAfterLogin] = useLocalstorageState('taskAfterLogin', false);
  const [stickyFormHead, setStickyFormHead] = useLocalstorageState('formHeadSticky', false);

  const handleTaskCheck = () => {
    setOpenTaskInNewTab(!openTaskInNewTab);
  };

  const handleTaskAfterLoginCheck = () => {
    setTaskAfterLogin(!taskAfterLogin);
  };

  const handleClientCheck = () => {
    setOpenClientInNewTab(!openClientInNewTab);
  };
  const handleFormHeadCheck = () => {
    setStickyFormHead(!stickyFormHead);
  };
  return (
    <div className="k-px-1">
      <div className="u-text-info u-font-normal u-text-base k-my-4">
        {t('settings.browserSettings.description')}
      </div>
      <div className="k-mb-2">
        <Checkbox
          value={taskAfterLogin}
          label={t('settings.browserSettings.taskAfterLogin')}
          onChange={handleTaskAfterLoginCheck}
        />
      </div>
      <div className="k-mb-2">
        <Checkbox
          value={openTaskInNewTab}
          label={t('settings.browserSettings.openTaskInNewTab')}
          onChange={handleTaskCheck}
        />
      </div>
      <div className="k-mb-2">
        <Checkbox
          value={stickyFormHead}
          label={t('settings.browserSettings.stickyFormHeader')}
          onChange={handleFormHeadCheck}
        />
      </div>

      {clientAbility.can('viewClients', clientSubject()) && (
        <div>
          <Checkbox
            value={openClientInNewTab}
            label={t('settings.browserSettings.openClientInNewTab')}
            onChange={handleClientCheck}
          />
        </div>
      )}
    </div>
  );
};
