import { MapboxMap } from 'react-map-gl';

export function mapboxLoadImage(map: MapboxMap, name: string, url: string) {
  map.loadImage(url, (error, image) => {
    if (error) throw error;

    if (image) {
      map.addImage(name, image);
    }
  });
}
