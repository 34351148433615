import {
  Ability,
  AbilityBuilder,
  AbilityClass,
  ForcedSubject,
  subject as subjectHelper,
} from '@casl/ability';
import { useCallback, useMemo } from 'react';

import { Permission } from '../../../types/graphql.generated';
import { GenericCan, GenericCanSharedProps } from '../components/GenericCan';
import { CurrentUserAbilityFragment } from '../graphql';
import { detectSubjectTypeByTypename as detectSubjectType } from '../helpers';
import { useMeOrDefault } from '../hooks';

interface DeviceSubject {
  me: CurrentUserAbilityFragment;
}

type DeviceSubjectArg = Partial<DeviceSubject>;

type Actions =
  | 'viewDevices'
  | 'createDevice'
  | 'updateDevice'
  | 'deleteDevice'
  | 'importDevices'
  | 'exportDevices';
type Subjects = DeviceSubject | 'DeviceSubject';

type DeviceAbility = Ability<[Actions, Subjects]>;
const userAbility = Ability as AbilityClass<DeviceAbility>;

export const useDeviceAbility = (): [
  DeviceAbility,
  (sub?: DeviceSubjectArg) => DeviceSubject & ForcedSubject<'DeviceSubject'>,
] => {
  const ability = useMemo(() => {
    const { can, build } = new AbilityBuilder(userAbility);

    can('viewDevices', 'DeviceSubject', {
      'me.globalPermissions': { $in: [Permission.DEVICE_INDEX] },
    });
    can('createDevice', 'DeviceSubject', {
      'me.globalPermissions': { $in: [Permission.DEVICE_CREATE] },
    });
    can('updateDevice', 'DeviceSubject', {
      'me.globalPermissions': { $in: [Permission.DEVICE_UPDATE] },
    });
    can('importDevices', 'DeviceSubject', {
      'me.globalPermissions': { $in: [Permission.DEVICE_IMPORT] },
    });
    can('exportDevices', 'DeviceSubject', {
      'me.globalPermissions': { $in: [Permission.DEVICE_EXPORT] },
    });

    return build({ detectSubjectType });
  }, []);

  const me = useMeOrDefault();
  const subject = useCallback(
    (sub?: DeviceSubjectArg) => {
      return subjectHelper('DeviceSubject', { me, ...sub });
    },
    [me],
  );

  return [ability, subject];
};

type CanDeviceProps = GenericCanSharedProps<Actions>;

export const CanDevice = (props: CanDeviceProps) => {
  const [deviceAbility, deviceSubject] = useDeviceAbility();

  return (
    <GenericCan<Actions, Subjects, DeviceAbility>
      ability={deviceAbility}
      subject={deviceSubject()}
      {...props}
    />
  );
};
