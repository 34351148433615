import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import { AppEmailTransportFragmentDoc } from './appEmailTransports.generated';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type UpdateAppEmailTransportMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  input: Types.UpdateAppEmailTransportInput;
}>;

export type UpdateAppEmailTransportMutation = {
  __typename?: 'Mutation';
  updateAppEmailTransport: {
    __typename?: 'AppEmailTransport';
    id: string;
    title: string;
    type_id: Types.AppEmailTransportTypeId;
    active: boolean;
    smtp_host?: string | null;
    smtp_port?: number | null;
    smtp_username?: string | null;
    smtp_encryption?: Types.MailEncryptionType | null;
    type: { __typename?: 'AppEmailTransportType'; id: string; title: string; description: string };
  };
};

export const UpdateAppEmailTransportDocument = gql`
  mutation UpdateAppEmailTransport($id: ID!, $input: UpdateAppEmailTransportInput!) {
    updateAppEmailTransport(id: $id, input: $input) {
      ...AppEmailTransport
    }
  }
  ${AppEmailTransportFragmentDoc}
`;

export function useUpdateAppEmailTransportMutation() {
  return Urql.useMutation<
    UpdateAppEmailTransportMutation,
    UpdateAppEmailTransportMutationVariables
  >(UpdateAppEmailTransportDocument);
}
