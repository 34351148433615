import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CreateTaskCalculationTemplateMutationVariables = Types.Exact<{
  title: Types.Scalars['String'];
  taskId: Types.Scalars['ID'];
}>;

export type CreateTaskCalculationTemplateMutation = {
  __typename?: 'Mutation';
  createTaskCalculationTemplate: {
    __typename?: 'TaskCalculationTemplate';
    id: string;
    title: string;
  };
};

export const CreateTaskCalculationTemplateDocument = gql`
  mutation CreateTaskCalculationTemplate($title: String!, $taskId: ID!) {
    createTaskCalculationTemplate(title: $title, task_id: $taskId) {
      id
      title
    }
  }
`;

export function useCreateTaskCalculationTemplateMutation() {
  return Urql.useMutation<
    CreateTaskCalculationTemplateMutation,
    CreateTaskCalculationTemplateMutationVariables
  >(CreateTaskCalculationTemplateDocument);
}
