import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import { ClientLocationFieldsFragmentDoc } from './client_types.generated';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ClientQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;

export type ClientQuery = {
  __typename?: 'Query';
  client?: {
    __typename?: 'Client';
    type_id: string;
    salutation_id: string;
    leader_client_id?: string | null;
    name: string;
    email?: string | null;
    alternate_email?: string | null;
    phone?: string | null;
    web?: string | null;
    notes?: string | null;
    assignment_notes?: string | null;
    external_number?: string | null;
    external_client_id?: string | null;
    job_title?: string | null;
    state_id: Types.ClientStateId;
    state_notes?: string | null;
    is_absent?: boolean | null;
    primary_location?: {
      __typename?: 'ClientLocation';
      id: string;
      client_location_type_id: Types.ClientLocationTypeId;
      primary?: boolean | null;
      street_no?: string | null;
      postcode?: string | null;
      city?: string | null;
      country_id?: string | null;
      street_full?: string | null;
      street?: string | null;
      latitude?: number | null;
      longitude?: number | null;
      notes?: string | null;
      client_id: string;
      different_policy_holder?: string | null;
      different_insurance_policy_number?: string | null;
      country: { __typename?: 'Country'; title: string };
      client_location_type: { __typename?: 'ClientLocationType'; title: string };
    } | null;
    client_type?: { __typename?: 'ClientType'; title: string; color: string } | null;
    user?: { __typename?: 'User'; id: string; name: string } | null;
    qualifications: {
      __typename?: 'ClientQualificationList';
      data: Array<{ __typename?: 'Qualification'; id: string; color: string; title: string }>;
    };
    classification_tags: Array<{
      __typename?: 'ClassificationTag';
      id: string;
      title: string;
      color: string;
      alias: string;
      api_only: boolean;
    }>;
  } | null;
};

export const ClientDocument = gql`
  query Client($id: ID!) {
    client(id: $id) {
      type_id
      salutation_id
      leader_client_id
      name
      email
      alternate_email
      phone
      web
      notes
      assignment_notes
      external_number
      external_client_id
      job_title
      state_id
      state_notes
      is_absent
      primary_location {
        id
        ...ClientLocationFields
      }
      client_type {
        title
        color
      }
      user {
        id
        name
      }
      qualifications {
        data {
          id
          color
          title
        }
      }
      classification_tags {
        id
        title
        color
        alias
        api_only
      }
    }
  }
  ${ClientLocationFieldsFragmentDoc}
`;

export function useClientQuery(options: Omit<Urql.UseQueryArgs<ClientQueryVariables>, 'query'>) {
  return Urql.useQuery<ClientQuery, ClientQueryVariables>({ query: ClientDocument, ...options });
}
