import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type DeleteAppEmailTransportMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;

export type DeleteAppEmailTransportMutation = {
  __typename?: 'Mutation';
  deleteAppEmailTransport: { __typename?: 'AppEmailTransport'; id: string };
};

export const DeleteAppEmailTransportDocument = gql`
  mutation DeleteAppEmailTransport($id: ID!) {
    deleteAppEmailTransport(id: $id) {
      id
    }
  }
`;

export function useDeleteAppEmailTransportMutation() {
  return Urql.useMutation<
    DeleteAppEmailTransportMutation,
    DeleteAppEmailTransportMutationVariables
  >(DeleteAppEmailTransportDocument);
}
