import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
export type NotificationFieldsFragment = {
  __typename?: 'Notification';
  id: string;
  client_id: string;
  title: string;
  from_client_id?: string | null;
  read: boolean;
  created_at: string;
  task_id?: string | null;
  admin: boolean;
  from_client?: { __typename?: 'Client'; id: string; name: string } | null;
};

export const NotificationFieldsFragmentDoc = gql`
  fragment NotificationFields on Notification {
    id
    client_id
    title
    from_client_id
    from_client {
      id
      name
    }
    read
    created_at
    task_id
    admin
  }
`;
