import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CreateTaskSubTaskMutationVariables = Types.Exact<{
  task_id: Types.Scalars['ID'];
  input: Types.CreateTaskSubTaskInput;
}>;

export type CreateTaskSubTaskMutation = {
  __typename?: 'Mutation';
  createTaskSubTask: { __typename?: 'TaskSubTask'; id: string };
};

export const CreateTaskSubTaskDocument = gql`
  mutation CreateTaskSubTask($task_id: ID!, $input: CreateTaskSubTaskInput!) {
    createTaskSubTask(task_id: $task_id, input: $input) {
      id
    }
  }
`;

export function useCreateTaskSubTaskMutation() {
  return Urql.useMutation<CreateTaskSubTaskMutation, CreateTaskSubTaskMutationVariables>(
    CreateTaskSubTaskDocument,
  );
}
