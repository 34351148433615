export default {
  labels: {
    role: 'Role',
  },
  errors: {
    not_found: {
      title: 'User not found',
      note: 'The user you requested was not found',
    },
  },
  notifications: {
    send_qr_code: {
      success: 'QR-Code has been sent!',
    },
    update_user_role: {
      success: 'User role changed!',
    },
    update_user_password: {
      success: 'Password has been changed!',
    },
    update_user: {
      success: 'User details saved!',
    },
    createUser: {
      success: 'User has been created!',
    },
    deleteUser: {
      success: 'User has been deleted!',
    },
    send_email_verification_link: {
      success: 'Confirmation link has been sent!',
    },
    resendInvitation: {
      success: 'Invitation link has been sent!',
    },
  },
  components: {
    user_send_qr_code_panel: {
      title: 'Send QR-Code',
      description: 'Here you can send the QR-Code for the setup of the 2FA to the user',
      submit: 'Send QR-Code',
    },
    user_change_password_panel: {
      title: 'Change password',
    },
    user_common_panel: {
      title: 'User Details',
      sections: {
        userDetails: {
          title: 'User Details',
          description: 'Here you can see the user details',
        },
      },
    },
    user_email_verification_panel: {
      title: 'Email confirmation',
      submit: 'Send confirmation email',
      send: 'Send',
      status: {
        verified: 'Email address confirmed',
        unverified: 'The email address still needs to be confirmed.',
      },
    },
    user_update_panel: {
      title: 'User Details',
    },
    update_user_role_panel: {
      title: 'Change user role',
    },
  },
  dialogs: {
    createUser: {
      title: 'Create user',
      note: 'Create a new user account',
    },
    deleteUser: {
      title: 'Delete user',
      note: 'Do you really want to delete the user?',
    },
  },
  pages: {
    overview: {
      title: 'Users - Overview',
      description: 'Here you can see a list of existing users and their access permissions',
      toolbar: {
        create: 'New user',
      },
    },
    detail: {
      title: 'User - {{user_id}}',
      description: 'Here you can edit the user data e.g.(passwords, user role, name, etc.)',
      tabs: {
        common: 'General',
        password: 'Password',
        verify_email: 'email confirmation',
        role: 'User role',
      },
    },
  },
};
