import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type AppEmailTransportFragment = {
  __typename?: 'AppEmailTransport';
  id: string;
  title: string;
  type_id: Types.AppEmailTransportTypeId;
  active: boolean;
  smtp_host?: string | null;
  smtp_port?: number | null;
  smtp_username?: string | null;
  smtp_encryption?: Types.MailEncryptionType | null;
  type: { __typename?: 'AppEmailTransportType'; id: string; title: string; description: string };
};

export type AppEmailTransportsQueryVariables = Types.Exact<{ [key: string]: never }>;

export type AppEmailTransportsQuery = {
  __typename?: 'Query';
  appEmailTransports: Array<{
    __typename?: 'AppEmailTransport';
    id: string;
    title: string;
    type_id: Types.AppEmailTransportTypeId;
    active: boolean;
    smtp_host?: string | null;
    smtp_port?: number | null;
    smtp_username?: string | null;
    smtp_encryption?: Types.MailEncryptionType | null;
    type: { __typename?: 'AppEmailTransportType'; id: string; title: string; description: string };
  }>;
};

export const AppEmailTransportFragmentDoc = gql`
  fragment AppEmailTransport on AppEmailTransport {
    id
    title
    type_id
    type {
      id
      title
      description
    }
    active
    smtp_host
    smtp_port
    smtp_username
    smtp_encryption
  }
`;
export const AppEmailTransportsDocument = gql`
  query AppEmailTransports {
    appEmailTransports {
      ...AppEmailTransport
    }
  }
  ${AppEmailTransportFragmentDoc}
`;

export function useAppEmailTransportsQuery(
  options?: Omit<Urql.UseQueryArgs<AppEmailTransportsQueryVariables>, 'query'>,
) {
  return Urql.useQuery<AppEmailTransportsQuery, AppEmailTransportsQueryVariables>({
    query: AppEmailTransportsDocument,
    ...options,
  });
}
