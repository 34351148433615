import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ContactFunctionsQueryVariables = Types.Exact<{ [key: string]: never }>;

export type ContactFunctionsQuery = {
  __typename?: 'Query';
  contactFunctions: Array<{ __typename?: 'ContactFunction'; id: string; title: string }>;
};

export const ContactFunctionsDocument = gql`
  query ContactFunctions {
    contactFunctions {
      id
      title
    }
  }
`;

export function useContactFunctionsQuery(
  options?: Omit<Urql.UseQueryArgs<ContactFunctionsQueryVariables>, 'query'>,
) {
  return Urql.useQuery<ContactFunctionsQuery, ContactFunctionsQueryVariables>({
    query: ContactFunctionsDocument,
    ...options,
  });
}
