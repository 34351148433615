import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
export type ClassificationTagFragment = {
  __typename?: 'ClassificationTag';
  id: string;
  title: string;
  color: string;
  alias: string;
  api_only: boolean;
};

export const ClassificationTagFragmentDoc = gql`
  fragment ClassificationTag on ClassificationTag {
    id
    title
    color
    alias
    api_only
  }
`;
