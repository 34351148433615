import './SubNavigation.scss';

import { useMediaQueries } from '@module/layout';
import { Button, ButtonGroup } from '@progress/kendo-react-buttons';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Badge } from '@progress/kendo-react-indicators';

interface ItemProps {
  text: string;
  badge?: number | string;
}

export interface SubNavigationProps<TItemProps extends ItemProps> {
  className?: string;
  items: Array<TItemProps> | ReadonlyArray<TItemProps>;
  isItemActive: (item: TItemProps) => boolean;
  onItemClick: (item: TItemProps) => void;
}

const SubNavigationButtonGroup = <TItemProps extends ItemProps>(
  props: SubNavigationProps<TItemProps>,
) => {
  const { className, items, isItemActive, onItemClick } = props;
  return (
    <ButtonGroup className={className}>
      {items.map((item, index) => (
        <Button
          key={index}
          size="large"
          selected={isItemActive(item)}
          togglable
          onClick={() => onItemClick(item)}
        >
          {item.text}
          {item.badge ? <Badge themeColor="secondary">{item.badge}</Badge> : ''}
        </Button>
      ))}
    </ButtonGroup>
  );
};

const SubNavigationDropDown = <TItemProps extends ItemProps>(
  props: SubNavigationProps<TItemProps>,
) => {
  const { className, items, isItemActive, onItemClick } = props;

  const activeItem = items.find(isItemActive);

  return (
    <DropDownList
      className={className}
      data={items as Array<TItemProps>} // Kendo does not like ReadonlyArray
      size="large"
      textField="text"
      value={activeItem}
      onChange={({ value }) => onItemClick(value)}
    />
  );
};

export const SubNavigation = <TItemProps extends ItemProps>(
  props: SubNavigationProps<TItemProps>,
) => {
  const { mdUp } = useMediaQueries();
  if (mdUp) {
    return <SubNavigationButtonGroup {...props} className="SubNavigationButtonGroup" />;
  }

  return <SubNavigationDropDown {...props} />;
};
