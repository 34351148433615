import { isValidPostcode, useFormContext } from '@module/shared/forms';
import { InputChangeEvent } from '@progress/kendo-react-inputs';
import { useCallback } from 'react';

import { useFetchCityForPostcode } from './useFetchCityForPostcode';

export const useFillCityByPostcode = (cityFieldPath: string) => {
  const { onChange, valueGetter } = useFormContext();
  const fetchCityForPostcode = useFetchCityForPostcode();

  return {
    handlePostcodeChange: useCallback(
      async ({ value }: InputChangeEvent) => {
        if (!isValidPostcode(value)) {
          return;
        }

        const autocompletedCity = await fetchCityForPostcode(value);
        const currentCity = valueGetter(cityFieldPath);

        if (autocompletedCity && autocompletedCity !== currentCity) {
          onChange(cityFieldPath, { value: autocompletedCity });
        }
      },
      [cityFieldPath, valueGetter, fetchCityForPostcode, onChange],
    ),
  };
};
