export default {
  labels: {
    yes: 'Yes',
    no: 'No',
    makePayout: 'New entry',
    addPayout: 'Calculate new payroll',
    enableMultiselect: 'Enable multiselect',
    disableMultiselect: 'Disable multiselect',
    markAsPaid: 'Mark as Paid',
    selectedPayoutsCount: '{{count}} payout selected',
    exportPayoutAsXLSX: 'Export statement as XLSX',
  },
  pages: {
    payouts: {
      title: 'Payouts',
      description: 'Here you can find an overview of all payouts.',
      grid: {
        columns: {
          invoiceId: 'Invoice Number',
          taskId: 'For Task',
          clientName: 'Recipient',
          sumNet: 'Amount',
          stateId: 'Status',
          payoutDate: 'Paid on',
          payout: 'Payout',
          title: 'Title',
          insurance: 'Insurance',
        },
        actions: {
          markAsPaid: {
            label: 'Mark as Paid',
          },
          cancel: {
            label: 'Cancel',
          },
          edit: {
            label: 'Edit',
          },
        },
      },
      filter: {
        payoutId: 'Payout {{id}}',
      },
    },
  },
  notifications: {
    createPayout: {
      success: 'Payout successfully generated.',
      error: 'Unfortunately, an error occurred in the payout.',
    },
    addPayout: {
      success: 'Settlement successfully generated.',
    },
  },
  dialogs: {
    createPayout: {
      title: 'New payout',
      note: 'This will create a new disbursement. All invoices that do not yet belong to a disbursement will be used for this disbursement. The settings for disbursements in the master data for payers are used here. Do you want to start a new disbursement?',
    },
    addPayout: {
      title: 'Create Payout',
      note: 'Create a new payroll manually. To do this, specify the relevant team leader or adjuster and enter the corresponding invoice. Amounts can be entered manually in comparison to the automatically created statements and are not calculated on the basis of the master data settings',
      form: {
        title: 'Title',
        invoiceId: 'Invoice',
        clientId: 'Adjuster / Leader',
        sumNet: 'Sum (net)',
      },
    },
    cancelPayout: {
      title: 'Cancel payout?',
      note: 'Are you sure you want to cancel the payout?',
    },
    updatePayout: {
      title: 'Update payout',
      note: 'Here you can update the payout',
      form: {
        title: 'Title',
        sumNet: 'Sum (net)',
      },
    },
    confirmMarkAsPaid: {
      title: 'Set payout(s) as paid?',
      note: 'Are you sure you want to set the selected payout(s) as paid?',
    },
    exportPayouts: {
      title: 'Export payouts',
      description: 'Download the exported payouts.',
    },
  },
  components: {
    invoiceSelect: {
      renderValue: 'Invoice #{{identifier}} - {{sum}}',
      columns: {
        identifier: 'Invoice Number',
        taskId: 'Task',
        clientName: 'Insurance',
        externalNumber: 'Damage number',
        sumNet: 'Sum (net)',
      },
      renderedValue: 'Invoice #{identifier}} - {{sum}}',
    },
  },
};
