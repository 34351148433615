import './MinWidthGrid.scss';

import { classNames } from '@progress/kendo-react-common';
import { Grid, GridProps } from '@progress/kendo-react-grid';
import { CSSProperties } from 'react';

interface MinWidthGridProps extends GridProps {
  /** CSS length */
  minWidth: string;
}

/** Grid that will overflow when the available space is lower than given minWidth. */
export const MinWidthGrid = ({ className, style, minWidth, ...rest }: MinWidthGridProps) => (
  <Grid
    {...rest}
    className={classNames('MinWidthGrid', className)}
    style={{ ...style, '--min-width': minWidth } as CSSProperties}
  />
);
