import Icon403 from '@image/empty-states/403.svg';
import { PublicLayout } from '@module/layout';
import { Button } from '@progress/kendo-react-buttons';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export const NoPermissionPage = () => {
  const { t } = useTranslation();
  return (
    <PublicLayout>
      <div className="k-display-flex k-flex-grow k-flex-column k-justify-content-center k-align-items-center">
        <h5>{t('auth.pages.noPermission.error')}</h5>
        <div className="k-display-flex k-flex-column k-justify-content-center">
          <div>
            <img src={Icon403} width={240} />
          </div>
        </div>
        <h1>{t('auth.pages.noPermission.title')}</h1>
        <p>{t('auth.pages.noPermission.note')}</p>
        <div className="k-flex-wrap k-d-flex k-gap-x-6">
          <Button iconClass="l-i-chevron-left" size={'large'} onClick={() => history.back()}>
            {t('auth.pages.noPermission.back')}
          </Button>
          <Link
            to="/"
            className="k-button k-button-lg k-button-rectangle k-button-solid k-button-solid-primary k-rounded-md"
          >
            {t('auth.pages.noPermission.backToHome')}
          </Link>
        </div>
      </div>
    </PublicLayout>
  );
};
