import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
export type TaskSubTaskFragment = {
  __typename?: 'TaskSubTask';
  id: string;
  task_id: string;
  title: string;
  description?: string | null;
  due_date?: string | null;
  completed_date?: string | null;
  permissions: Array<Types.Permission>;
  state: { __typename?: 'TaskSubTaskState'; id: string; title: string; color: string };
  file?: {
    __typename?: 'FileItem';
    id: string;
    original_file_name: string;
    mime_type?: string | null;
    url: string;
  } | null;
};

export const TaskSubTaskFragmentDoc = gql`
  fragment TaskSubTask on TaskSubTask {
    id
    task_id
    title
    description
    due_date
    completed_date
    state {
      id
      title
      color
    }
    file {
      id
      original_file_name
      mime_type
      url
    }
    permissions
  }
`;
