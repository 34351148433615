import { DropDownListInput } from '@module/shared/forms';
import { FieldRenderProps } from '@progress/kendo-react-form';
import { useCallback, useMemo } from 'react';

import { ContactFunctionItem, useContactFunctions, useDropDownListDefaultItem } from '../../hooks';

export interface FunctionSelectProps extends FieldRenderProps {
  value: string | null;
  onChange: (event: { target?: unknown; value?: string | null }) => void;
}

export const FunctionSelect = (props: FunctionSelectProps) => {
  const { value, onChange, ...rest } = props;
  const { contactFunctions, isLoading } = useContactFunctions();
  const defaultItem = useDropDownListDefaultItem('id', 'title');

  const contactFunction = useMemo(
    () => contactFunctions.find((contactFunction) => contactFunction.id === value),
    [contactFunctions, value],
  );

  const handleChange = useCallback(
    (event: { value?: ContactFunctionItem }) => {
      if (event.value?.id === defaultItem.id) {
        onChange({
          ...event,
          value: null,
        });
      } else {
        onChange({
          ...event,
          value: event.value?.id ?? null,
        });
      }
    },
    [defaultItem.id, onChange],
  );
  return (
    <DropDownListInput
      {...rest}
      data={contactFunctions}
      dataItemKey={'id'}
      textField={'title'}
      defaultItem={defaultItem}
      value={contactFunction}
      onChange={handleChange}
      isLoading={isLoading}
    />
  );
};
