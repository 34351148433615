import { PageBreadcrumb, PageContent, PageSubHeader, PageTitle } from '@module/common';
import { SettingsLayout } from '@module/private';
import { useTranslation } from 'react-i18next';

import { TaskGroupGrid } from './TaskGroupGrid';

export const TaskGroupPage = () => {
  const { t } = useTranslation();

  return (
    <SettingsLayout>
      <PageTitle title={t('settings.pages.taskGroups.title')} />
      <PageBreadcrumb path={location.pathname} text={t('settings.pages.taskGroups.title')} />
      <PageSubHeader
        title={t('settings.pages.taskGroups.title')}
        description={t('settings.pages.taskGroups.description')}
      />
      <PageContent>
        <TaskGroupGrid />
      </PageContent>
    </SettingsLayout>
  );
};
