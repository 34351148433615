export default {
  labels: {
    editor: 'Editor',
    assignment: 'Zuweisung',
    id: 'Id',
    title: 'Titel',
    visibility: 'Sichtbarkeit',
    edit: 'Edit',
    preview: 'Vorschau',
    upload: 'Hochladen',
    new: 'Neu',
    download: 'Herunterladen',
    selectFieldDescription: 'Ändere oder füge Elemente zum PDF hinzu:',
    selectFieldType: 'Wähle den Feldtyp',
    selectField: 'Wählen Sie das Feld, welches Sie zuordnen möchten.',
    fieldPreferences: 'Feldeigenschaften',
    add: 'hinzufügen',
    signatureField: 'Mit einem Signaturfeld lassen sich Unterschriftsfelder im PDF anlegen',
    textField: 'Mit einem Textfeld lassen sich Formularfelder im PDF anlegen',
    removeAssociation: 'Zuweisung entfernen',
  },
  components: {
    measurements: {
      calibrateScale: 'Maßstab kalibrieren',
      polygonAreaMeasurement: 'Flächenmessung',
      setScale: 'Maßstab festlegen',
      newScale: 'neuen Maßstab hinzufügen',
      displaySecondaryUnit: 'Sekundäreinheit anzeigen',
      secondaryUnit: 'Sekundäreinheit',
      scaleName: 'Name des Maßstabs',
      scale: 'Maßstab',
      editAddScale: 'Maßstab bearbeiten/hinzufügen',
      duplicateScaleError: 'Maßstab mit diesem Namen existiert bereits',
      measurementScale: 'Maßstab',
      calibrationScaleSuccess: 'Maßstab erfolgreich hinzugefügt',
      calibrationScaleSubtitle:
        'Beginnen Sie die Messung, indem Sie eines der Messwerkzeuge auswählen.',
      Snapping: 'Einrasten',
    },
  },
  dialogs: {
    preview: {
      title: 'Vorschau',
    },
    edit: {
      title: 'PDF-Formular bearbeiten',
      note: 'Hier können Sie die Zuweisung von PDF Formularen und deren Titel bearbeiten.',
    },
    delete: {
      title: 'PDF-Formular löschen',
      note: 'Wollen Sie dieses PDF-Formular wirklich löschen?',
    },
    editAnnotations: {
      title: 'Annotationen bearbeiten',
    },
    new: {
      title: 'Neu',
      create: 'Erstellen',
    },
    update: {
      title: 'Update',
    },
    upload: {
      title: 'PDF-Formulare hochladen',
      note: 'Hier können Sie PDF-Formulare hochladen.',
      cancel: 'Abbrechen',
      upload: 'Hochladen',
    },
    designer: {
      title: 'Designer',
    },
  },
  pages: {
    overview: {
      title: 'PDF-Formulare',
      description: 'Hier finden Sie eine Auflistung von PDF-Formularen.',
      grid: {
        columns: {
          actions: 'Aktionen',
        },
      },
    },
  },
};
