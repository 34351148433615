import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type TaskCalculationTemplatesQueryVariables = Types.Exact<{ [key: string]: never }>;

export type TaskCalculationTemplatesQuery = {
  __typename?: 'Query';
  taskCalculationTemplatesForUser?: Array<{
    __typename?: 'TaskCalculationTemplate';
    id: string;
    title: string;
  }> | null;
};

export const TaskCalculationTemplatesDocument = gql`
  query TaskCalculationTemplates {
    taskCalculationTemplatesForUser {
      id
      title
    }
  }
`;

export function useTaskCalculationTemplatesQuery(
  options?: Omit<Urql.UseQueryArgs<TaskCalculationTemplatesQueryVariables>, 'query'>,
) {
  return Urql.useQuery<TaskCalculationTemplatesQuery, TaskCalculationTemplatesQueryVariables>({
    query: TaskCalculationTemplatesDocument,
    ...options,
  });
}
