import {
  Ability,
  AbilityBuilder,
  AbilityClass,
  ForcedSubject,
  subject as subjectHelper,
} from '@casl/ability';
import { useCallback, useMemo } from 'react';

import { Permission } from '../../../types/graphql.generated';
import { GenericCan, GenericCanSharedProps } from '../components/GenericCan';
import { CurrentUserAbilityFragment } from '../graphql';
import { detectSubjectTypeByTypename as detectSubjectType } from '../helpers';
import { useMeOrDefault } from '../hooks';

interface ServiceCatalogSubject {
  me: CurrentUserAbilityFragment;
}

type ServiceCatalogSubjectArg = Partial<ServiceCatalogSubject>;

type Actions =
  | 'viewServiceCatalogItems'
  | 'importServiceCatalogItems'
  | 'exportServiceCatalogItems'
  | 'viewPostcodeFactors'
  | 'viewTradesStatus'
  | 'upsertTradesStatus'
  | 'deleteTradesStatus';
type Subjects = ServiceCatalogSubject | 'ServiceCatalogSubject';

type ServiceCatalogAbility = Ability<[Actions, Subjects]>;
const userAbility = Ability as AbilityClass<ServiceCatalogAbility>;

export const useServiceCatalogAbility = (): [
  ServiceCatalogAbility,
  (
    sub?: ServiceCatalogSubjectArg,
  ) => ServiceCatalogSubject & ForcedSubject<'ServiceCatalogSubject'>,
] => {
  const ability = useMemo(() => {
    const { can, build } = new AbilityBuilder(userAbility);

    can('viewServiceCatalogItems', 'ServiceCatalogSubject', {
      'me.globalPermissions': { $in: [Permission.SERVICE_CATALOG_ITEM_INDEX] },
    });
    can('importServiceCatalogItems', 'ServiceCatalogSubject', {
      'me.globalPermissions': { $in: [Permission.SERVICE_CATALOG_ITEM_IMPORT] },
    });
    can('exportServiceCatalogItems', 'ServiceCatalogSubject', {
      'me.globalPermissions': { $in: [Permission.SERVICE_CATALOG_ITEM_EXPORT] },
    });
    can('viewPostcodeFactors', 'ServiceCatalogSubject', {
      'me.globalPermissions': { $in: [Permission.SERVICE_CATALOG_ITEM_POSTCODE_FACTORS] },
    });
    can('viewTradesStatus', 'ServiceCatalogSubject', {
      'me.globalPermissions': { $in: [Permission.SERVICE_CATALOG_ITEM_TRADE_STATUS] },
    });
    can('upsertTradesStatus', 'ServiceCatalogSubject', {
      'me.globalPermissions': { $in: [Permission.TASK_CALCULATION_ITEM_STATE_CREATE] },
    });
    can('deleteTradesStatus', 'ServiceCatalogSubject', {
      'me.globalPermissions': { $in: [Permission.TASK_CALCULATION_ITEM_STATE_CREATE] },
    });
    return build({ detectSubjectType });
  }, []);

  const me = useMeOrDefault();
  const subject = useCallback(
    (sub?: ServiceCatalogSubjectArg) => {
      return subjectHelper('ServiceCatalogSubject', { me, ...sub });
    },
    [me],
  );

  return [ability, subject];
};

type CanServiceCatalogProps = GenericCanSharedProps<Actions>;

export const CanServiceCatalog = (props: CanServiceCatalogProps) => {
  const [serviceCatalogAbility, serviceCatalogSubject] = useServiceCatalogAbility();

  return (
    <GenericCan<Actions, Subjects, ServiceCatalogAbility>
      ability={serviceCatalogAbility}
      subject={serviceCatalogSubject()}
      {...props}
    />
  );
};
