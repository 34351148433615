export default {
  labels: {
    to: 'TO:',
    cc: 'CC:',
    bcc: 'BCC:',
    subject: 'Subject:',
    body: 'Content',
    attachments: 'Attachments',
    send: 'Send',
    chooseEmailTemplate: 'choose template',
    totalAttachmentSize: 'Total size of the attachments: {{size}}',
    totalAttachmentSizeToBig:
      'Total size of attachments: {{size}}. Attention, the total size of the attachments is > 30 Mbyte - the successful sending of the message cannot be guaranteed.',
    priority: {
      lowest: 'Lowest',
      low: 'Low',
      normal: 'Normal',
      high: 'High',
      highest: 'Highest',
    },
  },
  notifications: {
    createEmail: {
      success: 'Email sent!',
      error: 'Recipient address must not be empty!',
    },
    emailComposer: {
      error: 'You do not have authorization to send e-mails!',
    },
  },
  dialogs: {
    emailComposer: {
      title: 'Compose email',
    },
    emailAddAttachments: {
      title: 'Add attachments',
      note: 'Choose files you want to attach.',
      submit: 'Select',
    },
  },
};
