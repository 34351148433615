import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type UpdateTaskFollowUpDateMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  follow_up_date?: Types.InputMaybe<Types.Scalars['DateTimeTz']>;
}>;

export type UpdateTaskFollowUpDateMutation = {
  __typename?: 'Mutation';
  updateTaskFollowUpDate: { __typename?: 'Task'; id: string; follow_up_date?: string | null };
};

export const UpdateTaskFollowUpDateDocument = gql`
  mutation UpdateTaskFollowUpDate($id: ID!, $follow_up_date: DateTimeTz) {
    updateTaskFollowUpDate(id: $id, input: { follow_up_date: $follow_up_date }) {
      id
      follow_up_date
    }
  }
`;

export function useUpdateTaskFollowUpDateMutation() {
  return Urql.useMutation<UpdateTaskFollowUpDateMutation, UpdateTaskFollowUpDateMutationVariables>(
    UpdateTaskFollowUpDateDocument,
  );
}
