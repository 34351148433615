import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import { SubformClassificationTagFragmentDoc } from './subformClassificationTagFragment.generated';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type DeleteSubformClassificationTagMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;

export type DeleteSubformClassificationTagMutation = {
  __typename?: 'Mutation';
  deleteTag: {
    __typename?: 'Tag';
    id: string;
    title: string;
    color: string;
    type_id: string;
    type: { __typename?: 'TagType'; id: string; title: string; color: string };
  };
};

export const DeleteSubformClassificationTagDocument = gql`
  mutation DeleteSubformClassificationTag($id: ID!) {
    deleteTag(id: $id) {
      ...SubformClassificationTag
    }
  }
  ${SubformClassificationTagFragmentDoc}
`;

export function useDeleteSubformClassificationTagMutation() {
  return Urql.useMutation<
    DeleteSubformClassificationTagMutation,
    DeleteSubformClassificationTagMutationVariables
  >(DeleteSubformClassificationTagDocument);
}
