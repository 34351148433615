export default {
  labels: {
    taskStates: 'Status zahtjeva',
    newAssignments: 'Novi zahtjevi',
    greeting: 'Dobar Dan',
    today: 'Danas',
    yesterday: 'Jučer',
    weeklyAvarage: 'Ø tjedno',
    monthlyAvarage: 'Ø mjesečno',
    tasks: 'Zahtjevi',
    all: 'Sve',
    toDo: 'Napraviti',
    done: 'Gotovo',
    completeAssignments: 'Završeni Zahtjevi',
    assignments: 'Zahtjevi',
    subTasks: 'Otvoreni zahtjevi',
    dailyCalendar: 'Agenda',
    mon: 'Pon',
    tue: 'Uto',
    wed: 'Sri',
    thu: 'Čet',
    fri: 'Pet',
    sat: 'Sub',
    sun: 'Ned',
    jan: 'Siječanj',
    feb: 'Veljača',
    mar: 'Ožujak',
    apr: 'Travanj',
    may: 'Svibanj',
    jun: 'Lipanj',
    jul: 'Srpanj',
    aug: 'Kolovoz',
    sep: 'Rujan',
    oct: 'Listopad',
    nov: 'Studeni',
    dec: 'Prosinac',
    dueToday: 'Rok je danas',
    openSubTasks: 'Otvoreni zadaci',
    subTasksToReview: 'zadatke za pregled',
    createdAssignments: 'Nove narudžbe',
    allLeaders: 'Sve vođe timova',
    interfaceErrors: 'Greška sučelja',
  },
  greeting: {
    title: 'Dobrodošli natrag, {{name}}',
    description: 'Ovdje ćete pronaći korisne informacije o trenutnim poslovima',
  },
  assignments: {
    labels: {
      notAcceptedTasks: 'Nije prihvaćeno',
      acceptedTasks: 'Prihvaćeno',
    },
    notAcceptedTasks: {
      noRecords: {
        title: 'Nema dostupnih zahtjeva',
        text: 'Trenutno nema neprihvaćenih zahtjeva.',
      },
    },
    acceptedTasks: {
      noRecords: {
        title: 'Nema dostupnih zahtjeva',
        text: 'Trenutno nema prihvaćenih zahtjeva.',
      },
    },
  },
  subTasks: {
    noRecords: {
      title: 'Nema zahtjeva',
      text: 'Trenutno nema otvorenih zahtjeva.',
    },
    due: 'Zbog',
    assignedTo: 'Odgovorna osoba:',
  },
  interfaceErrors: {
    taskLabelWithId: 'Zadatak: {{taskId}}',
    noRecords: {
      title: 'Nema grešaka u sučelju',
      text: 'Trenutno nema grešaka u sučelju.',
    },
  },
  metrics: {
    workloadForDashboard: {
      title: 'Iskorištenje po regulatoru',
      daily_new_tasks_per_owner: 'Nove narudžbe',
      in_work_average_per_owner: 'U nastajanju',
      available_owners: 'Dostupni regulatori',
    },
    notAcceptedTasksForDashboard: {
      title: 'Nije prihvaćeno',
      two_days_not_accepted: 'star 2 dana',
      three_days_not_accepted: 'star 3 dana',
      older_three_days_not_accepted: 'Starije od 3 dana',
    },
  },
};
