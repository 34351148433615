import { classNames } from '@progress/kendo-react-common';
import {
  TABLE_COL_INDEX_ATTRIBUTE,
  useTableKeyboardNavigation,
} from '@progress/kendo-react-data-tools';
import { GridCellProps } from '@progress/kendo-react-grid';

// Picks all props from `GridCellProps` that need to be passed to the `<td>` element to retain
// Kendo's style, table layout, keyboard and accessibility features while using custom cell
// components.
// Taken from: node_modules/@progress/kendo-react-grid/dist/es/cells/GridCell.js
export const useTdElementProps = (props: GridCellProps) => {
  const navigationAttributes = useTableKeyboardNavigation(props.id);

  return {
    colSpan: props.colSpan,
    style: props.style,
    className: classNames(props.className, {
      'k-state-selected': props.isSelected,
    }),
    role: 'cell',
    'aria-colindex': props.ariaColumnIndex,
    'aria-selected': props.isSelected,
    [TABLE_COL_INDEX_ATTRIBUTE]: props.columnIndex,
    ...navigationAttributes,
  } as const;
};
