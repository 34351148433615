import './PageSubHeader.scss';

import { classNames } from '@progress/kendo-react-common';
import { ReactNode } from 'react';

import { TaskNotification } from '../../../../types/graphql.generated';
import { useTaskNotifications } from '../../hooks/useTaskNotifications';
import { PageNotification } from './PageNotification';

export interface PageSubHeaderProps {
  title?: string;
  titleAddition?: ReactNode;
  description?: string;
  content?: ReactNode;
  align?: 'left' | 'center' | 'right';
  width?: 'auto' | 'fixed' | 'full';
  className?: string;
  pathTitle?: string;
  notifications?: TaskNotification[] | null;
}

export const PageSubHeader = (props: PageSubHeaderProps) => {
  const { align = 'left', className, width = 'full', content, notifications } = props;

  const { taskNotifications, removeNotification } = useTaskNotifications(notifications);

  return (
    <div
      id="PageSubHeader"
      className={classNames(
        'page-sub-header k-p-4',
        {
          'page-sub-header-left': align === 'left',
          'page-sub-header-center': align === 'center',
          'page-sub-header-right': align === 'right',
        },
        {
          'page-sub-header-width-auto': width === 'auto',
          'page-sub-header-width-fixed': width === 'fixed',
          'page-sub-header-width-full': width === 'full',
        },
        className,
      )}
    >
      {taskNotifications.length > 0 && (
        <div className="k-display-flex k-flex-column k-gap-2 u-width-full">
          {taskNotifications.map((notification) => (
            <PageNotification
              key={notification.id}
              notification={notification}
              onRemove={removeNotification}
            />
          ))}
        </div>
      )}

      <div className="page-sub-header-wrapper k-display-flex k-flex-row k-justify-content-between k-align-items-center flex-wrap">
        <div>
          <span className="k-d-inline-flex">
            {props.title && <h1 className="k-mt-4 k-mb-2 text-header">{props.title}</h1>}
            {props.titleAddition}
          </span>
          {props.description && <p>{props.description}</p>}
        </div>
        {content && <div>{content}</div>}
      </div>
    </div>
  );
};
