import { useConfirmDialogs } from '@module/common';
import { NotificationType, useNotifications } from '@module/shared/notifications';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useDeleteTaskGroupMutation } from '../graphql';

export const useConfirmDeleteTaskGroup = () => {
  const { t } = useTranslation();
  const confirm = useConfirmDialogs();
  const { showNotification } = useNotifications();
  const [, deleteClassificationTag] = useDeleteTaskGroupMutation();

  return useCallback(
    (tagId: string) => {
      confirm.delete({
        title: t('settings.pages.taskGroups.dialogs.delete.title'),
        description: t('settings.pages.taskGroups.dialogs.delete.note'),
        delete: async () => {
          const response = await deleteClassificationTag({ task_group_id: tagId });
          if (!response.error) {
            showNotification(
              t('settings.pages.taskGroups.notifications.delete.success'),
              NotificationType.Success,
            );
          }
          if (response.error) {
            showNotification(
              t('settings.pages.taskGroups.notifications.delete.error'),
              NotificationType.Error,
            );
          }
        },
      });
    },
    [confirm, deleteClassificationTag, showNotification, t],
  );
};
