import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type DeleteTaskInvoiceMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;

export type DeleteTaskInvoiceMutation = {
  __typename?: 'Mutation';
  deleteTaskInvoice: {
    __typename?: 'ResponseMessage';
    success?: boolean | null;
    message?: string | null;
  };
};

export const DeleteTaskInvoiceDocument = gql`
  mutation DeleteTaskInvoice($id: ID!) {
    deleteTaskInvoice(id: $id) {
      success
      message
    }
  }
`;

export function useDeleteTaskInvoiceMutation() {
  return Urql.useMutation<DeleteTaskInvoiceMutation, DeleteTaskInvoiceMutationVariables>(
    DeleteTaskInvoiceDocument,
  );
}
