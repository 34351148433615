import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CreateApiTokenMutationVariables = Types.Exact<{ [key: string]: never }>;

export type CreateApiTokenMutation = {
  __typename?: 'Mutation';
  createApiToken: { __typename?: 'CreateApiTokenResponse'; message: string; api_token: string };
};

export const CreateApiTokenDocument = gql`
  mutation CreateApiToken {
    createApiToken {
      message
      api_token
    }
  }
`;

export function useCreateApiTokenMutation() {
  return Urql.useMutation<CreateApiTokenMutation, CreateApiTokenMutationVariables | void>(
    CreateApiTokenDocument,
  );
}
