import { classNames } from '@progress/kendo-react-common';
import { GridCellProps } from '@progress/kendo-react-grid';
import { useMemo } from 'react';

import { ThemeColor } from '../../../../types/graphql.generated';
import { getThemeColorByField, getThemeColorFont } from '../../helpers';
import { DateFormat, useDateField, useDateFormat } from '../../hooks';

export interface ThemeColorDateCellProps {
  dateFormat: DateFormat;
  themeColorField?: string | [string];
  themeColor?: ThemeColor;
}

export const ThemeColorDateCell = (props: ThemeColorDateCellProps & GridCellProps) => {
  const date = useDateField(props.dataItem, props.field ?? '');
  const formatted = useDateFormat(date, props.dateFormat);

  const className = useMemo(() => {
    const themeColorField = getThemeColorByField(props.dataItem, props.themeColorField ?? '');
    return classNames(
      props.className,
      getThemeColorFont(themeColorField),
      getThemeColorFont(props.themeColor),
    );
  }, [props.className, props.dataItem, props.themeColorField, props.themeColor]);

  return (
    <td className={className} style={props.style}>
      {formatted}
    </td>
  );
};
