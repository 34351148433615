import { GridCellProps } from '@progress/kendo-react-grid';
import { get } from 'lodash';
import { useMemo } from 'react';

import { DateFormat, useFormatIsoDate, useTdElementProps } from '../../hooks';

export interface DateCellProps {
  dateFormat?: DateFormat;
}

export const DateCell = (props: DateCellProps & GridCellProps) => {
  const tdElementProps = useTdElementProps(props);
  const { dateFormat = DateFormat.Date } = props;
  const formatDate = useFormatIsoDate();
  const formatted = useMemo(() => {
    const fieldValue = get(props.dataItem, props.field ?? '');

    return fieldValue ? formatDate(fieldValue, dateFormat) : undefined;
  }, [dateFormat, formatDate, props.dataItem, props.field]);
  return <td {...tdElementProps}>{formatted}</td>;
};
