import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type DeleteTaskProposalMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;

export type DeleteTaskProposalMutation = {
  __typename?: 'Mutation';
  deleteTaskProposal: {
    __typename?: 'ResponseMessage';
    success?: boolean | null;
    message?: string | null;
  };
};

export const DeleteTaskProposalDocument = gql`
  mutation DeleteTaskProposal($id: ID!) {
    deleteTaskProposal(id: $id) {
      success
      message
    }
  }
`;

export function useDeleteTaskProposalMutation() {
  return Urql.useMutation<DeleteTaskProposalMutation, DeleteTaskProposalMutationVariables>(
    DeleteTaskProposalDocument,
  );
}
