export default {
  tasks: {
    generalSection: {
      labels: {
        auto_accept_task:
          'Automatische Auftragsannahme - der Auftrag wird automatisch um 22:00 von den Sachverständigen auf “angenommen” gesetzt.',
        adjuster_can_create_tasks: 'Sachverständiger kann Aufträge erstellen',
      },
    },
  },
};
