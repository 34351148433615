import {
  Ability,
  AbilityBuilder,
  AbilityClass,
  ForcedSubject,
  subject as subjectHelper,
} from '@casl/ability';
import { Permission } from '@generated/graphql.generated';
import { useCallback, useMemo } from 'react';

import { GenericCan, GenericCanSharedProps } from '../components/GenericCan';
import { CurrentUserAbilityFragment, TaskFolderAbilityFragment } from '../graphql';
import { detectSubjectTypeByTypename as detectSubjectType } from '../helpers';
import { useMeOrDefault } from '../hooks';

interface TaskFolderSubject {
  me: CurrentUserAbilityFragment;
  folder?: TaskFolderAbilityFragment;
}

type TaskFolderSubjectArg = Partial<TaskFolderSubject>;

type Actions = 'readFolder' | 'deleteFolder' | 'renameFolder' | 'updateFolderPermissions';

type Subjects = TaskFolderSubject | 'TaskFolderSubject';

type TaskFolderAbility = Ability<[Actions, Subjects]>;
const taskFolderAbility = Ability as AbilityClass<TaskFolderAbility>;

export const useTaskFolderAbility = (): [
  TaskFolderAbility,
  (sub?: TaskFolderSubjectArg) => TaskFolderSubject & ForcedSubject<'TaskFolderSubject'>,
] => {
  const ability = useMemo(() => {
    const { can, build } = new AbilityBuilder(taskFolderAbility);

    can('readFolder', 'TaskFolderSubject', {
      'folder.permissions': { $in: [Permission.TASK_FOLDER_READ_FOLDER] },
    });
    can('deleteFolder', 'TaskFolderSubject', {
      'folder.permissions': { $in: [Permission.TASK_FOLDER_DELETE_FOLDER] },
    });
    can('renameFolder', 'TaskFolderSubject', {
      'folder.permissions': { $in: [Permission.TASK_FOLDER_RENAME_FOLDER] },
    });
    can('updateFolderPermissions', 'TaskFolderSubject', {
      'folder.permissions': { $in: [Permission.TASK_FOLDER_UPDATE_FOLDER_PERMISSIONS] },
    });

    return build({ detectSubjectType });
  }, []);

  const me = useMeOrDefault();
  const subject = useCallback(
    (sub?: TaskFolderSubjectArg) => {
      return subjectHelper('TaskFolderSubject', { me, ...sub });
    },
    [me],
  );

  return [ability, subject];
};

interface CanTaskFolderProps extends GenericCanSharedProps<Actions> {
  folder?: TaskFolderAbilityFragment;
}

export const CanTaskFolder = (props: CanTaskFolderProps) => {
  const [taskFileAbility, taskFileSubject] = useTaskFolderAbility();
  const { folder, ...rest } = props;

  return (
    <GenericCan<Actions, Subjects, TaskFolderAbility>
      ability={taskFileAbility}
      subject={taskFileSubject({ folder })}
      {...rest}
    />
  );
};
