import { FieldRenderProps } from '@progress/kendo-react-form';
import { Error } from '@progress/kendo-react-labels';
import { Tooltip } from '@progress/kendo-react-tooltip';

export type FieldErrorProps = Pick<FieldRenderProps, 'valid' | 'validationMessage'>;

export const FieldError = ({ valid, validationMessage }: FieldErrorProps) => {
  if (valid || !validationMessage) {
    return null;
  }

  return (
    <Tooltip anchorElement="target">
      <Error>
        <span title={validationMessage}>{validationMessage}</span>
      </Error>
    </Tooltip>
  );
};
