import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type TaskLogsQueryVariables = Types.Exact<{
  taskId: Types.Scalars['ID'];
}>;

export type TaskLogsQuery = {
  __typename?: 'Query';
  taskLogs?: Array<{
    __typename?: 'TaskLog';
    id: string;
    title: string;
    created_at?: string | null;
    log_state_id: Types.TaskLogStateEnum;
    state: { __typename?: 'TaskLogState'; id: string; title: string; color: string };
    client?: { __typename?: 'Client'; name: string; job_title?: string | null } | null;
    sms_message?: { __typename?: 'SMSMessage'; body: string } | null;
  }> | null;
};

export const TaskLogsDocument = gql`
  query TaskLogs($taskId: ID!) {
    taskLogs(task_id: $taskId) {
      id
      title
      created_at
      state {
        id
        title
        color
      }
      client {
        name
        job_title
      }
      sms_message {
        body
      }
      log_state_id
    }
  }
`;

export function useTaskLogsQuery(
  options: Omit<Urql.UseQueryArgs<TaskLogsQueryVariables>, 'query'>,
) {
  return Urql.useQuery<TaskLogsQuery, TaskLogsQueryVariables>({
    query: TaskLogsDocument,
    ...options,
  });
}
