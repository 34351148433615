import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CreateContactMutationVariables = Types.Exact<{
  input: Types.CreateContactInput;
}>;

export type CreateContactMutation = {
  __typename?: 'Mutation';
  createContact?: { __typename?: 'Contact'; id: string } | null;
};

export const CreateContactDocument = gql`
  mutation CreateContact($input: CreateContactInput!) {
    createContact(input: $input) {
      id
    }
  }
`;

export function useCreateContactMutation() {
  return Urql.useMutation<CreateContactMutation, CreateContactMutationVariables>(
    CreateContactDocument,
  );
}
