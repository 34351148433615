export default {
  labels: {
    title: 'Titel',
    description: 'Beschreibung',
    category: 'Kategorie',
    messageType: 'Nachrichten Typ',
    newTemplate: 'Neue Vorlage',
    subject: 'Betreff',
    body: 'Text',
    assign: 'Zuweisen',
    assignedInsurances: 'Zugewiesene Versicherungen',
  },
  pages: {
    overview: {
      title: 'GDV Nachrichten Vorlagen',
      description:
        'Hier können Sie Nachrichten Vorlagen erstellen, welche danach über die GDV Schnittstelle versendet werden können.',
    },
  },
  dialogs: {
    create: {
      title: 'GDV Nachrichten Vorlage erstellen',
    },
    update: {
      title: 'GDV Nachrichten Vorlage bearbeiten',
    },
    delete: {
      title: 'GDV Nachrichten Vorlage löschen',
      description: 'Möchten Sie diese Vorlage wirklich löschen?',
    },
    assign: {
      title: 'Vorlage zuweisen',
    },
  },
  notifications: {
    templateCreated: 'Vorlage wurde erstellt',
    templateDeleted: 'Vorlage wurde gelöscht',
    templateUpdated: 'Vorlage wurde aktualisiert',
  },
};
