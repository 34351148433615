import {
  Ability,
  AbilityBuilder,
  AbilityClass,
  ForcedSubject,
  subject as subjectHelper,
} from '@casl/ability';
import { useCallback, useMemo } from 'react';

import { Permission, UserRole } from '../../../types/graphql.generated';
import { GenericCan, GenericCanSharedProps } from '../components/GenericCan';
import { CurrentUserAbilityFragment, UserAbilityFragment } from '../graphql';
import { detectSubjectTypeByTypename as detectSubjectType } from '../helpers';
import { useMeOrDefault } from '../hooks';

interface UserSubject {
  me: CurrentUserAbilityFragment;
  user?: UserAbilityFragment;
}

type UserSubjectArg = Partial<UserSubject>;

type Actions =
  | 'isAdmin'
  | 'isLeader'
  | 'isAdjuster'
  | 'viewUserCommon'
  | 'updateUserPasswordSelf'
  | 'updateUserPassword'
  | 'updateUserEmailVerification'
  | 'createApiToken'
  | 'sendQRCode';
type Subjects = UserSubject | 'UserSubject';

type UserAbility = Ability<[Actions, Subjects]>;
const userAbility = Ability as AbilityClass<UserAbility>;

export const useUserAbility = (): [
  UserAbility,
  (sub?: UserSubjectArg) => UserSubject & ForcedSubject<'UserSubject'>,
] => {
  const ability = useMemo(() => {
    const { can, build } = new AbilityBuilder(userAbility);

    can('isAdmin', 'UserSubject', { 'me.role': UserRole.ADMIN });
    can('isLeader', 'UserSubject', { 'me.role': UserRole.LEADER });
    can('isAdjuster', 'UserSubject', { 'me.role': UserRole.ADJUSTER });

    can('viewUserCommon', 'UserSubject', {
      'me.globalPermissions': { $in: [Permission.USER_READ] },
    });

    can('updateUserPasswordSelf', 'UserSubject', {
      'user.permissions': { $in: [Permission.USER_UPDATE_PASSWORD_SELF] },
    });
    can('updateUserPassword', 'UserSubject', {
      'user.permissions': { $in: [Permission.USER_UPDATE_PASSWORD] },
    });
    can('updateUserEmailVerification', 'UserSubject', {
      'user.permissions': { $in: [Permission.USER_UPDATE] },
    });
    can('createApiToken', 'UserSubject', {
      'me.globalPermissions': { $in: [Permission.USER_CREATE_API_TOKEN] },
    });
    can('sendQRCode', 'UserSubject', {
      'user.permissions': { $in: [Permission.USER_SEND_QRCODE] },
    });

    return build({ detectSubjectType });
  }, []);

  const me = useMeOrDefault();
  const subject = useCallback(
    (sub?: UserSubjectArg) => {
      return subjectHelper('UserSubject', { me, ...sub });
    },
    [me],
  );

  return [ability, subject];
};

interface CanUserProps extends GenericCanSharedProps<Actions> {
  user?: UserAbilityFragment;
}

export const CanUser = (props: CanUserProps) => {
  const [userAbility, userSubject] = useUserAbility();
  const { user, ...restProps } = props;

  return (
    <GenericCan<Actions, Subjects, UserAbility>
      ability={userAbility}
      subject={userSubject({ user })}
      {...restProps}
    />
  );
};
