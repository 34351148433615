import { createContext, ReactNode, useContext } from 'react';

import { ClientLoginFragment } from '../../graphql';

export interface QuickSelectClients {
  owner: ClientLoginFragment | null;
  leader: ClientLoginFragment | null;
}

export interface IChangeUserButtonContextType {
  quickSelectClients: QuickSelectClients | null;
}

const ChangeUserButtonContext = createContext<IChangeUserButtonContextType | null>(null);

export function useChangeUserButtonContext() {
  return useContext(ChangeUserButtonContext);
}

interface ChangeUserButtonContextProps {
  state: IChangeUserButtonContextType | null;
  children?: ReactNode;
}

export const ChangeUserButtonContextProvider = ({
  children,
  state,
}: ChangeUserButtonContextProps) => {
  return (
    <ChangeUserButtonContext.Provider value={state}>{children}</ChangeUserButtonContext.Provider>
  );
};
