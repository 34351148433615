export function rgbaToHex(value: string | null | undefined) {
  if (value) {
    const a = value.split('(')[1].split(')')[0];
    const b = a.split(',');
    b.splice(3, 1);
    const c = b.map(function (x) {
      x = parseInt(x).toString(16); //Convert to a base16 string
      return x.length == 1 ? '0' + x : x; //Add zero if we get only one character
    });
    return '#' + c.join('');
  }
}

export function hexToRgba(value: string | null | undefined, opacity?: number) {
  if (value) {
    const r = parseInt(value.slice(1, 3), 16);
    const g = parseInt(value.slice(3, 5), 16);
    const b = parseInt(value.slice(5, 7), 16);

    return `rgba(${r}, ${g}, ${b}, ${opacity || 1})`;
  }
}
