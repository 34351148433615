import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type TaskCalculationItemStatesQueryVariables = Types.Exact<{ [key: string]: never }>;

export type TaskCalculationItemStatesQuery = {
  __typename?: 'Query';
  taskCalculationItemStates?: Array<{
    __typename?: 'TaskCalculationItemState';
    id: string;
    title: string;
    color: string;
  }> | null;
};

export const TaskCalculationItemStatesDocument = gql`
  query TaskCalculationItemStates {
    taskCalculationItemStates {
      id
      title
      color
    }
  }
`;

export function useTaskCalculationItemStatesQuery(
  options?: Omit<Urql.UseQueryArgs<TaskCalculationItemStatesQueryVariables>, 'query'>,
) {
  return Urql.useQuery<TaskCalculationItemStatesQuery, TaskCalculationItemStatesQueryVariables>({
    query: TaskCalculationItemStatesDocument,
    ...options,
  });
}
