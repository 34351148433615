import './DrawerContainer.scss';

import { Button } from '@progress/kendo-react-buttons';
import { classNames } from '@progress/kendo-react-common';
import { Drawer, DrawerContent, DrawerNavigation } from '@progress/kendo-react-layout';
import { PopupProps, PopupPropsContext } from '@progress/kendo-react-popup';
import { CSSProperties, Fragment, ReactNode, Suspense, useLayoutEffect, useMemo } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';

import { useAppSettingsPublicQuery } from '../../graphql';
import { useBrandingData } from '../../hooks';
import { Loader } from '../Loader';
import { DrawerItem } from './DrawerItem';
import { DrawerData } from './types';
import { useDrawerContext } from './useDrawerContext';

const DrawerHeader = () => {
  const branding = useBrandingData();
  const [{ data }] = useAppSettingsPublicQuery();
  const { t } = useTranslation();
  const { layout, toggleExpanded, disableMobileExpanded } = useDrawerContext();

  const withButton = layout === 'mobile';

  return (
    <div
      className={classNames('DrawerHeader', { withButton })}
      onClick={layout === 'desktop' ? toggleExpanded : undefined}
    >
      <div>
        <img src={branding.logo_light} width={32} height={32} alt="" />
      </div>

      <div>
        <div className="k-font-weight-semibold">
          {data?.appSettingsPublic.app_title || branding.name}
        </div>
        <div className="k-fs-sm">
          {t('common.labels.version')} {branding.version}
        </div>
      </div>

      {withButton && (
        <Button
          iconClass="l-i-x"
          size="large"
          fillMode="flat"
          onClick={disableMobileExpanded}
          aria-label={t('common.labels.close')}
        />
      )}
    </div>
  );
};

export interface DrawerFooterProps {
  children?: ReactNode | undefined;
}

export const DrawerFooter = (props: DrawerFooterProps) => (
  <div className="DrawerFooter">{props.children}</div>
);

export interface DrawerContainerProps {
  children?: ReactNode | undefined;
  data: DrawerData;
}

const useDrawerWidths = (hasSidebar: boolean) =>
  useMemo(() => {
    const drawerMiniWidth = 64;
    const drawerExpandedWidth = hasSidebar ? 304 : 240;

    return {
      drawerMiniWidth,
      drawerExpandedWidth,
      drawerStyle: {
        '--drawerMiniWidth': `${drawerMiniWidth}px`,
        '--drawerExpandedWidth': `${drawerExpandedWidth}px`,
      } as CSSProperties,
    } as const;
  }, [hasSidebar]);

const getPopupContextValue = (defaultProps: PopupProps): PopupProps => ({
  ...defaultProps,
  // To make popups visible inside the Drawer sidebar, we need a higher z-index than the Drawer
  // sidebar itself.
  // Drawer's z-index is hardcoded in `@progress/kendo-theme-default/scss/drawer/_layout.scss`.
  style: { ...defaultProps.style, zIndex: 10001 },
});
const PopupZIndexProvider = (props: { children: ReactNode }) => (
  <PopupPropsContext.Provider {...props} value={getPopupContextValue} />
);

export const DrawerContainer = (props: DrawerContainerProps) => {
  const { data } = props;

  const { layout, expanded, disableMobileExpanded, mobileExpanded, hasSidebar, setSidebarSlot } =
    useDrawerContext();
  const { drawerMiniWidth, drawerExpandedWidth, drawerStyle } = useDrawerWidths(hasSidebar);

  return (
    <Drawer
      className={classNames('DrawerContainer', hasSidebar && 'withSidebar')}
      expanded={layout === 'desktop' ? expanded : mobileExpanded}
      position="start"
      mode={layout === 'desktop' ? 'push' : 'overlay'}
      mini={layout === 'desktop'}
      miniWidth={drawerMiniWidth}
      width={drawerExpandedWidth}
      style={drawerStyle}
      onOverlayClick={disableMobileExpanded}
    >
      <DrawerNavigation style={{ zIndex: 10001 }}>
        <PopupZIndexProvider>
          <div>
            <DrawerHeader />
            <nav className="DrawerMenu">
              <ul className="k-drawer-items">
                {data.sections.map((section, index) => (
                  <Fragment key={`section_${index}`}>
                    {section.items.map((item, index) => (
                      <DrawerItem
                        key={index}
                        item={item}
                        isLastItem={index + 1 === section.items.length}
                      />
                    ))}
                  </Fragment>
                ))}
              </ul>
            </nav>
          </div>

          <div ref={setSidebarSlot} />
        </PopupZIndexProvider>
      </DrawerNavigation>

      <DrawerContent className="DrawerContent">
        <Suspense fallback={<Loader />}>{props.children}</Suspense>
      </DrawerContent>
    </Drawer>
  );
};

export const DrawerSidebar = (props: { children: ReactNode }) => {
  const { sidebarSlot, registerSidebar } = useDrawerContext();

  useLayoutEffect(() => {
    const unregisterSidebar = registerSidebar();
    return () => unregisterSidebar();
  }, [registerSidebar]);

  return sidebarSlot
    ? createPortal(<PopupZIndexProvider>{props.children}</PopupZIndexProvider>, sidebarSlot)
    : null;
};
