import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type UpdateGlobalSettingsMutationVariables = Types.Exact<{
  input: Types.UpdateGlobalSettingsInput;
}>;

export type UpdateGlobalSettingsMutation = {
  __typename?: 'Mutation';
  updateGlobalSettings: {
    __typename?: 'AppSettings';
    app_title: string;
    tax: number;
    currency_id: string;
    show_weekends_in_calendar: boolean;
    activate_legacy_gdv: boolean;
    theme: Types.Theme;
    calendar_driving_times: Types.DrivingTimeSetting;
    auth_single_session_per_user: boolean;
    auth_max_session_duration?: number | null;
    auth_mode_2fa: boolean;
    auth_mode_2fa_sms: boolean;
    auth_mode_2fa_email: boolean;
    auto_logout_timeout?: number | null;
    auth_password_min_length: number;
    auth_password_min_special: number;
    auth_password_min_upper_case: number;
    auth_password_min_numbers: number;
    ui_form_debounce_interval: number;
    country: {
      __typename?: 'Country';
      id: string;
      bounding_box_min_longitude: number;
      bounding_box_min_latitude: number;
      bounding_box_max_longitude: number;
      bounding_box_max_latitude: number;
    };
  };
};

export const UpdateGlobalSettingsDocument = gql`
  mutation UpdateGlobalSettings($input: UpdateGlobalSettingsInput!) {
    updateGlobalSettings(input: $input) {
      app_title
      tax
      currency_id
      show_weekends_in_calendar
      activate_legacy_gdv
      theme
      calendar_driving_times
      country {
        id
        bounding_box_min_longitude
        bounding_box_min_latitude
        bounding_box_max_longitude
        bounding_box_max_latitude
      }
      auth_single_session_per_user
      auth_max_session_duration
      auth_mode_2fa
      auth_mode_2fa_sms
      auth_mode_2fa_email
      auto_logout_timeout
      auth_password_min_length
      auth_password_min_special
      auth_password_min_upper_case
      auth_password_min_numbers
      ui_form_debounce_interval
    }
  }
`;

export function useUpdateGlobalSettingsMutation() {
  return Urql.useMutation<UpdateGlobalSettingsMutation, UpdateGlobalSettingsMutationVariables>(
    UpdateGlobalSettingsDocument,
  );
}
