import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
export type ClientGridFragment = {
  __typename?: 'Client';
  id: string;
  name: string;
  email?: string | null;
  phone?: string | null;
  type: Types.ClientTypeId;
  state_id: Types.ClientStateId;
  job_title?: string | null;
  permissions: Array<Types.Permission>;
  state: {
    __typename?: 'ClientState';
    id: string;
    color: string;
    title: string;
    state_id: Types.ClientStateId;
  };
  client_type?: { __typename?: 'ClientType'; id: string; title: string; color: string } | null;
  qualifications: {
    __typename?: 'ClientQualificationList';
    data: Array<{ __typename?: 'Qualification'; title: string; id: string; color: string }>;
  };
  primary_location?: {
    __typename?: 'ClientLocation';
    street?: string | null;
    street_no?: string | null;
    street_full?: string | null;
    postcode?: string | null;
    city?: string | null;
    country: { __typename?: 'Country'; title: string };
  } | null;
};

export const ClientGridFragmentDoc = gql`
  fragment ClientGrid on Client {
    id
    name
    email
    phone
    type
    state_id
    job_title
    state {
      id
      color
      title
      state_id
    }
    client_type {
      id
      title
      color
    }
    qualifications {
      data {
        title
        id
        color
      }
    }
    permissions
    primary_location {
      street
      street_no
      street_full
      postcode
      city
      country {
        title
      }
    }
  }
`;
