import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ClientTypesQueryVariables = Types.Exact<{ [key: string]: never }>;

export type ClientTypesQuery = {
  __typename?: 'Query';
  clientTypes: Array<{
    __typename?: 'ClientType';
    id: string;
    title: string;
    color: string;
    type_id: Types.ClientTypeId;
    choose_for_tasks: boolean;
  }>;
};

export const ClientTypesDocument = gql`
  query ClientTypes {
    clientTypes {
      id
      title
      color
      type_id
      choose_for_tasks
    }
  }
`;

export function useClientTypesQuery(
  options?: Omit<Urql.UseQueryArgs<ClientTypesQueryVariables>, 'query'>,
) {
  return Urql.useQuery<ClientTypesQuery, ClientTypesQueryVariables>({
    query: ClientTypesDocument,
    ...options,
  });
}
