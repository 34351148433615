import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import { AuthStateFragmentDoc } from './types.generated';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type InitialLoginMutationVariables = Types.Exact<{
  input: Types.InitialLoginInput;
}>;

export type InitialLoginMutation = {
  __typename?: 'Mutation';
  initialLogin: {
    __typename?: 'InitialLoginResponse';
    message: string;
    authState: {
      __typename?: 'AuthState';
      data: {
        __typename?: 'AuthData';
        access_token: string;
        refresh_token: string;
        expires_at: string;
        refresh_token_expires_at: string;
      };
    };
  };
};

export const InitialLoginDocument = gql`
  mutation InitialLogin($input: InitialLoginInput!) {
    initialLogin(input: $input) {
      message
      authState {
        ...AuthState
      }
    }
  }
  ${AuthStateFragmentDoc}
`;

export function useInitialLoginMutation() {
  return Urql.useMutation<InitialLoginMutation, InitialLoginMutationVariables>(
    InitialLoginDocument,
  );
}
