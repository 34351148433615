import { stubArray } from 'lodash';
import { useState } from 'react';

import { usePsPdfKit } from '../hooks';
import { PdfContainer } from './PdfContainer';

export interface PdfViewerProps {
  url: string;
}

export const PdfViewer = (props: PdfViewerProps) => {
  const { url } = props;

  const [containerElement, setContainerElement] = useState<HTMLDivElement | null>(null);

  const { error, loading } = usePsPdfKit({
    documentUrl: url,
    containerElement,
    toolbarItems: stubArray,
    showToolbar: false,
  });

  return (
    <PdfContainer
      ref={setContainerElement}
      error={error}
      loading={loading}
      style={{
        border: '1px solid rgba(0, 0, 0, 0.3)',
        aspectRatio: '0.707', // A4
        overflow: 'scroll',
        position: 'relative',
      }}
    />
  );
};
