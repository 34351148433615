import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import { TaskRouteFragmentDoc } from './taskRouteFragment.generated';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type TaskRoutesQueryVariables = Types.Exact<{ [key: string]: never }>;

export type TaskRoutesQuery = {
  __typename?: 'Query';
  taskRoutes: {
    __typename?: 'TaskRoutePaginator';
    data: Array<{
      __typename?: 'TaskRoute';
      id: string;
      date: string;
      distance?: number | null;
      duration?: number | null;
      route?: any | null;
      waypoints: Array<{
        __typename?: 'TaskRouteWaypoint';
        id: string;
        type_id: Types.TaskRouteTypeEnum;
        distance?: number | null;
        duration?: number | null;
        route?: any | null;
        client?: {
          __typename?: 'Client';
          primary_location?: {
            __typename?: 'ClientLocation';
            longitude?: number | null;
            latitude?: number | null;
            street_full?: string | null;
            postcode?: string | null;
            city?: string | null;
          } | null;
        } | null;
        task_event?: {
          __typename?: 'TaskEvent';
          date_from: string;
          date_to: string;
          task: {
            __typename?: 'Task';
            primary_location?: {
              __typename?: 'TaskLocation';
              longitude?: number | null;
              latitude?: number | null;
              street_full?: string | null;
              postcode?: string | null;
              city?: string | null;
            } | null;
          };
        } | null;
      }>;
    }>;
  };
};

export const TaskRoutesDocument = gql`
  query TaskRoutes {
    taskRoutes {
      data {
        ...TaskRoute
      }
    }
  }
  ${TaskRouteFragmentDoc}
`;

export function useTaskRoutesQuery(
  options?: Omit<Urql.UseQueryArgs<TaskRoutesQueryVariables>, 'query'>,
) {
  return Urql.useQuery<TaskRoutesQuery, TaskRoutesQueryVariables>({
    query: TaskRoutesDocument,
    ...options,
  });
}
