import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type UpdateTaskRoomCategoryMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  input: Types.TaskRoomCategoryInput;
}>;

export type UpdateTaskRoomCategoryMutation = {
  __typename?: 'Mutation';
  updateTaskRoomCategory: {
    __typename?: 'TaskRoomCategory';
    uuid: string;
    id: string;
    task_id: string;
    title: string;
  };
};

export const UpdateTaskRoomCategoryDocument = gql`
  mutation UpdateTaskRoomCategory($id: ID!, $input: TaskRoomCategoryInput!) {
    updateTaskRoomCategory(id: $id, input: $input) {
      uuid
      id
      task_id
      title
    }
  }
`;

export function useUpdateTaskRoomCategoryMutation() {
  return Urql.useMutation<UpdateTaskRoomCategoryMutation, UpdateTaskRoomCategoryMutationVariables>(
    UpdateTaskRoomCategoryDocument,
  );
}
