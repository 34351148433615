import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type UpdateTaskProposalMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  input: Types.UpdateTaskProposalInput;
}>;

export type UpdateTaskProposalMutation = {
  __typename?: 'Mutation';
  updateTaskProposal: {
    __typename?: 'TaskProposal';
    note?: string | null;
    reminder?: boolean | null;
  };
};

export const UpdateTaskProposalDocument = gql`
  mutation UpdateTaskProposal($id: ID!, $input: UpdateTaskProposalInput!) {
    updateTaskProposal(id: $id, input: $input) {
      note
      reminder
    }
  }
`;

export function useUpdateTaskProposalMutation() {
  return Urql.useMutation<UpdateTaskProposalMutation, UpdateTaskProposalMutationVariables>(
    UpdateTaskProposalDocument,
  );
}
