import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
export type ContactRoleFragment = { __typename?: 'ContactRole'; id: string; title: string };

export const ContactRoleFragmentDoc = gql`
  fragment ContactRole on ContactRole {
    id
    title
  }
`;
