import { FieldWrapper } from '@progress/kendo-react-form';
import {
  ComponentType,
  FocusEvent as ReactFocusEvent,
  ReactNode,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';

import { Label } from '../components/Label';
import { CommonFieldRenderProps, FieldError, FieldHint } from '../inputs';
import { sanitizeInputValue } from '../inputs/helpers';
import { PasswordInput, PasswordInputProps } from '../inputs/PasswordInput';

export interface PasswordFieldExtensionProps {
  children?: ReactNode | undefined;
  value: string | number | undefined;
  anchor: HTMLElement;
}

export const PasswordFieldExtension = (props: PasswordFieldExtensionProps) => {
  const { anchor } = props;
  const style = useMemo(() => {
    return { width: anchor.clientWidth };
  }, [anchor.clientWidth]);

  return (
    <div className="k-fixed k-popover k-p-2" style={style}>
      {props.children}
    </div>
  );
};

type PasswordFieldPropsBase = CommonFieldRenderProps &
  Pick<
    PasswordInputProps,
    | 'size'
    | 'id'
    | 'value'
    | 'placeholder'
    | 'required'
    | 'disabled'
    | 'onBlur'
    | 'onChange'
    | 'onFocus'
  >;

interface PasswordFieldProps extends PasswordFieldPropsBase {
  extensionComponentType?: ComponentType<PasswordFieldExtensionProps>;
  extension?: (props: PasswordFieldExtensionProps) => ReactNode;
}

export const PasswordField = (props: PasswordFieldProps) => {
  const {
    id,
    label,
    value,
    placeholder,
    required,
    valid,
    disabled,
    onBlur,
    onChange,
    onFocus,
    name,
  } = props;

  const [showExtension, setShowExtension] = useState(false);
  const anchor = useRef<HTMLDivElement>(null);
  const valueSanitized = sanitizeInputValue(value);

  const handleBlur = useCallback(
    (e: ReactFocusEvent<HTMLInputElement>) => {
      setShowExtension(false);
      onBlur?.(e);
    },
    [onBlur],
  );

  const handleFocus = useCallback(
    (e: ReactFocusEvent<HTMLInputElement>) => {
      setShowExtension(true);
      onFocus?.(e);
    },
    [onFocus],
  );

  const extensionNode = useMemo(() => {
    if (props.extension && anchor.current && value && showExtension) {
      return props.extension({ value: value.toString(), anchor: anchor.current });
    }
    return null;
  }, [props, showExtension, value]);

  return (
    <FieldWrapper>
      <Label label={label} name={name} editorId={id} editorValid={valid}>
        {label}
      </Label>
      <div ref={anchor}>
        <PasswordInput
          id={id}
          value={valueSanitized ?? ''}
          placeholder={placeholder}
          required={required}
          disabled={disabled}
          onBlur={handleBlur}
          onChange={onChange}
          onFocus={handleFocus}
        />
        {extensionNode}
      </div>
      <div className="HintAndError">
        <FieldHint {...props} />
        <FieldError {...props} />
      </div>
    </FieldWrapper>
  );
};
