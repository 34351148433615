import { CloseButton, Dialog, DialogContent, DialogHeader, DialogHeaderBar } from '@module/layout';
import { DialogProps, useDialogs } from '@module/shared/dialogs';
import { DialogActionsBar } from '@progress/kendo-react-dialogs';

import { HtmlContent } from '../../common';

interface HTMLDialogProps extends DialogProps {
  header: string;
  html: string;
}

export const HTMLDialog = ({ dialogId, html, header }: HTMLDialogProps) => {
  const { hideDialog } = useDialogs();

  const handleClose = () => {
    hideDialog(dialogId);
  };

  return (
    <Dialog width="small" onClose={handleClose}>
      <DialogHeaderBar>
        <DialogHeader title={header} />
      </DialogHeaderBar>
      <DialogContent className="k-display-flex k-flex-column">
        <div>
          <HtmlContent html={html} />
        </div>
      </DialogContent>
      <DialogActionsBar>
        <CloseButton onClick={handleClose} />
      </DialogActionsBar>
    </Dialog>
  );
};
