import {
  Ability,
  AbilityBuilder,
  AbilityClass,
  ForcedSubject,
  subject as subjectHelper,
} from '@casl/ability';
import { useCallback, useMemo } from 'react';

import { Permission } from '../../../types/graphql.generated';
import { GenericCan, GenericCanSharedProps } from '../components/GenericCan';
import { CurrentUserAbilityFragment } from '../graphql';
import { detectSubjectTypeByTypename as detectSubjectType } from '../helpers';
import { useMeOrDefault } from '../hooks';

interface FileClassificationTagSubject {
  me: CurrentUserAbilityFragment;
}

type FileClassificationTagSubjectArg = Partial<FileClassificationTagSubject>;

type Actions =
  | 'viewFileClassificationTags'
  | 'viewFileClassificationTag'
  | 'createFileClassificationTag'
  | 'updateFileClassificationTag'
  | 'deleteFileClassificationTag';
type Subjects = FileClassificationTagSubject | 'FileClassificationTagSubject';

type FileClassificationTagAbility = Ability<[Actions, Subjects]>;
const taskTemplateAbility = Ability as AbilityClass<FileClassificationTagAbility>;

export const useFileClassificationTagAbility = (): [
  FileClassificationTagAbility,
  (
    sub?: FileClassificationTagSubjectArg,
  ) => FileClassificationTagSubject & ForcedSubject<'FileClassificationTagSubject'>,
] => {
  const ability = useMemo(() => {
    const { can, build } = new AbilityBuilder(taskTemplateAbility);

    can('viewFileClassificationTags', 'FileClassificationTagSubject', {
      'me.globalPermissions': { $in: [Permission.CLASSIFICATION_TAG_INDEX] },
    });
    can('viewFileClassificationTag', 'FileClassificationTagSubject', {
      'me.globalPermissions': { $in: [Permission.CLASSIFICATION_TAG_READ] },
    });
    can('createFileClassificationTag', 'FileClassificationTagSubject', {
      'me.globalPermissions': { $in: [Permission.CLASSIFICATION_TAG_CREATE] },
    });
    can('updateFileClassificationTag', 'FileClassificationTagSubject', {
      'me.globalPermissions': { $in: [Permission.CLASSIFICATION_TAG_UPDATE] },
    });
    can('deleteFileClassificationTag', 'FileClassificationTagSubject', {
      'me.globalPermissions': { $in: [Permission.CLASSIFICATION_TAG_DELETE] },
    });

    return build({ detectSubjectType });
  }, []);

  const me = useMeOrDefault();
  const subject = useCallback(
    (sub?: FileClassificationTagSubjectArg) => {
      return subjectHelper('FileClassificationTagSubject', { me, ...sub });
    },
    [me],
  );

  return [ability, subject];
};

type CanFileClassificationTagProps = GenericCanSharedProps<Actions>;

export const CanFileClassificationTag = (props: CanFileClassificationTagProps) => {
  const [fileClassificationTagAbility, fileClassificationTagSubject] =
    useFileClassificationTagAbility();

  return (
    <GenericCan<Actions, Subjects, FileClassificationTagAbility>
      ability={fileClassificationTagAbility}
      subject={fileClassificationTagSubject()}
      {...props}
    />
  );
};
