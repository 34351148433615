import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type UploadTaskDocumentMutationVariables = Types.Exact<{
  taskId: Types.Scalars['ID'];
  file: Types.UploadFileInput;
}>;

export type UploadTaskDocumentMutation = {
  __typename?: 'Mutation';
  uploadTaskDocuments: {
    __typename?: 'TaskDocumentsPaginator';
    data: Array<{ __typename?: 'FileItem'; id: string }>;
  };
};

export const UploadTaskDocumentDocument = gql`
  mutation UploadTaskDocument($taskId: ID!, $file: UploadFileInput!) {
    uploadTaskDocuments(task_id: $taskId, files: [$file]) {
      data {
        id
      }
    }
  }
`;

export function useUploadTaskDocumentMutation() {
  return Urql.useMutation<UploadTaskDocumentMutation, UploadTaskDocumentMutationVariables>(
    UploadTaskDocumentDocument,
  );
}
