import { LOCALE } from '@env';
import { i18n } from 'i18next';
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { LocaleDefinition, locales } from '../../../localization';

function getLocales(): LocaleDefinition[] {
  return locales;
}

function getLocale(localeId: string): LocaleDefinition | undefined {
  const index = locales.findIndex((locale) => locale.localeId === localeId);
  if (index !== -1) {
    return locales[index];
  }

  return undefined;
}

function getInitialLocaleId(): string {
  // @TODO do we still need the logic to choose languages?

  // const localeId = localStorage.getItem('locale');
  //
  // if (!localeId) {
  //   const locale = locales.find((locale) => locale.localeId === LOCALE);
  //   if (locale) {
  //     return locale.localeId;
  //   }
  //   return locales[0].localeId;
  // }

  return LOCALE;
}

function getInitialLocale(): LocaleDefinition {
  const localeId = getInitialLocaleId();
  const locale = getLocale(localeId);
  return locale ?? locales[0];
}

interface LocaleContextType {
  locale: LocaleDefinition;
  chooseLocale: (locale: LocaleDefinition) => void;
  getLocales: () => LocaleDefinition[];
}

const LocaleContext = createContext<LocaleContextType>({
  locale: getInitialLocale(),
  // eslint-disable-next-line
  chooseLocale: (locale: LocaleDefinition) => {
    return;
  },
  getLocales,
});

export function useLocale() {
  return useContext(LocaleContext);
}

export interface LocaleProviderProps {
  children?: ReactNode | undefined;
  i18n: i18n;
}

export const LocaleProvider = (props: LocaleProviderProps) => {
  const initialLocale = useMemo(() => getInitialLocale(), []);
  const [locale, setLocale] = useState<LocaleDefinition>(getInitialLocale());

  const chooseLocale = useCallback(
    (locale: LocaleDefinition) => {
      props.i18n.changeLanguage(locale.localeId).then(() => {
        setLocale(locale);
        localStorage.setItem('locale', locale.localeId);
      });
    },
    [props.i18n],
  );

  useEffect(() => {
    chooseLocale(initialLocale);
  }, [chooseLocale, initialLocale]);

  return (
    <LocaleContext.Provider value={{ locale, chooseLocale, getLocales }}>
      {props.children}
    </LocaleContext.Provider>
  );
};
