export function isNullish(value: unknown): value is null | undefined {
  return value === undefined || value === null;
}

export function isNotNullish<TValue>(
  value?: TValue | null | undefined,
): value is NonNullable<TValue> {
  return value !== undefined && value !== null;
}

export function isTruthy<TValue>(
  value?: TValue | false | 0 | '' | null | undefined,
): value is TValue {
  return Boolean(value);
}

export function isNumber(value: unknown): value is number {
  return typeof value === 'number';
}

export function isString(value: unknown): value is string {
  return typeof value === 'string';
}

export function isEmptyString(value: unknown): value is string {
  return isString(value) && value.length === 0;
}

export function isArray<T>(value: unknown, predicate: (value: unknown) => boolean): value is T[] {
  return Array.isArray(value) && value.every((element) => predicate(element));
}

export function isNumberArray(value: unknown): value is number[] {
  return isArray(value, isNumber);
}

export function isStringArray(value: unknown): value is string[] {
  return isArray(value, isString);
}

export function isNode(value: EventTarget | null): value is Node {
  return value !== null && 'nodeType' in value;
}

export function isObject(value: unknown): value is object {
  return value !== null && typeof value === 'object';
}

/**
 * Marks array access via index as possibly undefined.
 * Similar to the TS global flag `noUncheckedIndexedAccess`.
 */
export function safeIndexAccess<T extends Array<unknown>, I extends number>(
  array: T,
  index: I,
): T[I] | undefined {
  return array[index];
}
