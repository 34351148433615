// Copied from https://github.com/department-stockholm/aws-signature-v4
// and fixed the sorting of query parameters by using 'query-string' package instead of 'querystring'
import { isNotNullish } from '@module/shared/helpers';
import crypto from 'crypto';
import { isArray } from 'lodash';
import querystring from 'query-string';

function createCanonicalRequest(
  method: string,
  pathname: string,
  query: querystring.ParsedQuery<string>,
  headers: Record<string, string>,
  payload: string,
) {
  return [
    method.toUpperCase(),
    pathname,
    createCanonicalQueryString(query),
    createCanonicalHeaders(headers),
    createSignedHeaders(headers),
    payload,
  ].join('\n');
}

function createCanonicalQueryString(params: querystring.ParsedQuery<string>) {
  return Object.keys(params)
    .sort()
    .map(function (key) {
      const item = params[key];
      if (item === null) return null;
      if (isArray(item)) return null;
      return encodeURIComponent(key) + '=' + encodeURIComponent(item);
    })
    .filter(isNotNullish)
    .join('&');
}

function createCanonicalHeaders(headers: Record<string, string>) {
  return Object.keys(headers)
    .sort()
    .map(function (name) {
      return name.toLowerCase().trim() + ':' + headers[name].toString().trim() + '\n';
    })
    .join('');
}

function createSignedHeaders(headers: Record<string, string>) {
  return Object.keys(headers)
    .sort()
    .map(function (name) {
      return name.toLowerCase().trim();
    })
    .join(';');
}

function createCredentialScope(time: number, region: string, service: string) {
  return [toDate(time), region, service, 'aws4_request'].join('/');
}

function createStringToSign(time: number, region: string, service: string, request: string) {
  return [
    'AWS4-HMAC-SHA256',
    toTime(time),
    createCredentialScope(time, region, service),
    hash(request, 'hex'),
  ].join('\n');
}

function createSignature(
  secret: string,
  time: number,
  region: string,
  service: string,
  stringToSign: string,
): string {
  const h1 = hmac('AWS4' + secret, toDate(time)); // date-key
  const h2 = hmac(h1, region); // region-key
  const h3 = hmac(h2, service); // service-key
  const h4 = hmac(h3, 'aws4_request'); // signing-key
  return hmac(h4, stringToSign, 'hex');
}

interface SignOptions {
  protocol?: string;
  headers?: Record<string, string>;
  timestamp?: number;
  region?: string;
  expires?: number;
  query?: string;
  key: string;
  secret: string;
  sessionToken?: string;
}

export function createPresignedURL(
  method: string,
  host: string,
  path: string,
  service: string,
  payload: string,
  options?: SignOptions,
) {
  if (!options) throw new Error('options are required');

  options.protocol = options.protocol || 'https';
  options.headers = options.headers || {};
  options.timestamp = options.timestamp || Date.now();
  options.region = options.region || 'us-east-1';
  options.expires = options.expires || 86400; // 24 hours
  options.headers = options.headers || {};

  // host is required
  options.headers.Host = host;

  const query = options.query ? querystring.parse(options.query) : {};
  query['X-Amz-Algorithm'] = 'AWS4-HMAC-SHA256';
  query['X-Amz-Credential'] =
    options.key + '/' + createCredentialScope(options.timestamp, options.region, service);
  query['X-Amz-Date'] = toTime(options.timestamp);
  query['X-Amz-Expires'] = options.expires.toString();
  query['X-Amz-SignedHeaders'] = createSignedHeaders(options.headers);
  if (options.sessionToken) {
    query['X-Amz-Security-Token'] = options.sessionToken;
  }

  const canonicalRequest = createCanonicalRequest(method, path, query, options.headers, payload);
  const stringToSign = createStringToSign(
    options.timestamp,
    options.region,
    service,
    canonicalRequest,
  );
  const signature = createSignature(
    options.secret,
    options.timestamp,
    options.region,
    service,
    stringToSign,
  );
  query['X-Amz-Signature'] = signature;
  return options.protocol + '://' + host + path + '?' + querystring.stringify(query);
}

function toTime(time: number) {
  return new Date(time).toISOString().replace(/[:-]|\.\d{3}/g, '');
}

function toDate(time: number) {
  return toTime(time).substring(0, 8);
}

function hmac<T extends crypto.BinaryToTextEncoding | undefined>(
  key: crypto.BinaryLike | crypto.KeyObject,
  string: string,
  encoding?: T,
): T extends crypto.BinaryToTextEncoding ? string : Buffer;

function hmac(
  key: crypto.BinaryLike | crypto.KeyObject,
  string: string,
  encoding?: crypto.BinaryToTextEncoding,
): Buffer | string {
  const hashString = crypto.createHmac('sha256', key).update(string, 'utf8');
  if (encoding) {
    return hashString.digest(encoding);
  }
  return hashString.digest();
}

function hash<T extends crypto.BinaryToTextEncoding | undefined>(
  string: string,
  encoding?: T,
): T extends crypto.BinaryToTextEncoding ? string : Buffer;

function hash(string: string, encoding?: crypto.BinaryToTextEncoding) {
  const hashString = crypto.createHash('sha256').update(string, 'utf8');

  if (encoding) {
    return hashString.digest(encoding);
  }
  return hashString.digest();
}
