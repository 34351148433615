import { useEffect, useRef } from 'react';

export function useSelectFirstOption<TItem extends { id: string }>(
  value: string | null | undefined,
  items: TItem[],
  onChange: (value: { value: string }) => void,
) {
  // Remember if the value was set before, otherwise we can not clear the field
  const hadValue = useRef<boolean>(!!value);

  // Select value when there is only one option available
  useEffect(() => {
    if (hadValue.current) return;
    if (items.length !== 1) return;

    onChange({ value: items[0].id });
    hadValue.current = true;
  }, [value, onChange, items]);
}
