import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type TasksByOwnerClientIdQueryVariables = Types.Exact<{
  owner_client_id: Types.Scalars['ID'];
}>;

export type TasksByOwnerClientIdQuery = {
  __typename?: 'Query';
  tasksByOwnerClientId: Array<{
    __typename?: 'Task';
    id: string;
    state: { __typename?: 'TaskState'; id: string };
  }>;
};

export const TasksByOwnerClientIdDocument = gql`
  query TasksByOwnerClientId($owner_client_id: ID!) {
    tasksByOwnerClientId(owner_client_id: $owner_client_id) {
      id
      state {
        id
      }
    }
  }
`;

export function useTasksByOwnerClientIdQuery(
  options: Omit<Urql.UseQueryArgs<TasksByOwnerClientIdQueryVariables>, 'query'>,
) {
  return Urql.useQuery<TasksByOwnerClientIdQuery, TasksByOwnerClientIdQueryVariables>({
    query: TasksByOwnerClientIdDocument,
    ...options,
  });
}
