import './NotificationItem.scss';

import {
  HtmlContent,
  NotificationFieldsFragment,
  useMarkNotificationAsReadMutation,
} from '@module/common';
import { NotificationType, useNotifications } from '@module/shared/notifications';
import { Button } from '@progress/kendo-react-buttons';
import { classNames } from '@progress/kendo-react-common';
import { formatDistanceStrict } from 'date-fns';
import { de } from 'date-fns/locale';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

interface NotificationItemProps {
  notification: NotificationFieldsFragment;
  lastItem: boolean;
}

export const NotificationItem = ({ notification, lastItem }: NotificationItemProps) => {
  const { t } = useTranslation();
  const { showNotification } = useNotifications();
  const navigate = useNavigate();

  const [, markNotificationAsRead] = useMarkNotificationAsReadMutation();

  const handleMarkAsRead = async () => {
    const markNotificationAsReadResponse = await markNotificationAsRead(
      { id: notification.id },
      { additionalTypenames: ['Notification'] },
    );
    if (markNotificationAsReadResponse.error) {
      showNotification(
        t('common.components.layout.pageHeader.markAsRead.error'),
        NotificationType.Error,
      );
      return;
    }
    showNotification(
      t('common.components.layout.pageHeader.markAsRead.success'),
      NotificationType.Success,
    );
  };

  const handleNavigationClick = () => {
    if (notification.task_id) {
      if (!notification.read) handleMarkAsRead();
      navigate(`/tasks/${notification.task_id}`);
    }
  };

  return (
    <div
      className={classNames('k-p-2', 'k-display-flex k-flex-row k-align-items-center', {
        'k-border-left-0 k-border-top-0 k-border-right-0 k-border-bottom-1 k-border-solid k-border-light':
          !lastItem,
        'notification-unread': !notification.read,
      })}
    >
      <Button
        className="k-mr-3"
        disabled={notification.read}
        iconClass={classNames('l-i-eye', {
          'k-color-primary': !notification.read,
        })}
        onClick={handleMarkAsRead}
        aria-label={t('common.components.layout.pageHeader.markAllAsRead.title')}
      />
      <div
        className={notification.task_id ? 'k-cursor-pointer' : undefined}
        onClick={handleNavigationClick}
      >
        <div>
          <HtmlContent html={notification.title} />
        </div>
        <p className="subtle-text k-my-1">
          {t('common.components.layout.pageHeader.createdAt', {
            time: formatDistanceStrict(new Date(notification.created_at), new Date(), {
              locale: de,
            }),
          })}
          {notification.from_client_id && <span>{` - ${notification.from_client?.name}`}</span>}
        </p>
      </div>
    </div>
  );
};
