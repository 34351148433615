import {
  Ability,
  AbilityBuilder,
  AbilityClass,
  ForcedSubject,
  subject as subjectHelper,
} from '@casl/ability';
import { useCallback, useMemo } from 'react';

import { Permission } from '../../../types/graphql.generated';
import { CurrentUserAbilityFragment } from '../graphql';
import { detectSubjectTypeByTypename as detectSubjectType } from '../helpers';
import { useMeOrDefault } from '../hooks';

interface AdminSubject {
  me: CurrentUserAbilityFragment;
}

type AdminSubjectArg = Partial<AdminSubject>;

type Actions = 'read' | 'canChangeUser';
type Subjects = AdminSubject | 'AdminSubject';

type AdminAbility = Ability<[Actions, Subjects]>;
const adminAbility = Ability as AbilityClass<AdminAbility>;

export const useAdminAbility = (): [
  AdminAbility,
  (sub?: AdminSubjectArg) => AdminSubject & ForcedSubject<'AdminSubject'>,
] => {
  const ability = useMemo(() => {
    const { can, build } = new AbilityBuilder(adminAbility);

    can('read', 'AdminSubject', {
      'me.globalPermissions': { $in: [Permission.ADMIN_READ] },
    });
    can('canChangeUser', 'AdminSubject', {
      'me.globalPermissions': { $in: [Permission.ADMIN_CHANGE_USER] },
    });

    return build({ detectSubjectType });
  }, []);

  const me = useMeOrDefault();
  const subject = useCallback(
    (sub?: AdminSubjectArg) => {
      return subjectHelper('AdminSubject', { me, ...sub });
    },
    [me],
  );

  return [ability, subject];
};
