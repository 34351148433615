import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type TaskCalculationItemPayoutStatesQueryVariables = Types.Exact<{ [key: string]: never }>;

export type TaskCalculationItemPayoutStatesQuery = {
  __typename?: 'Query';
  taskCalculationItemPayoutStates?: Array<{
    __typename?: 'TaskCalculationItemPayoutState';
    id: string;
    title: string;
    color: string;
  }> | null;
};

export const TaskCalculationItemPayoutStatesDocument = gql`
  query TaskCalculationItemPayoutStates {
    taskCalculationItemPayoutStates {
      id
      title
      color
    }
  }
`;

export function useTaskCalculationItemPayoutStatesQuery(
  options?: Omit<Urql.UseQueryArgs<TaskCalculationItemPayoutStatesQueryVariables>, 'query'>,
) {
  return Urql.useQuery<
    TaskCalculationItemPayoutStatesQuery,
    TaskCalculationItemPayoutStatesQueryVariables
  >({ query: TaskCalculationItemPayoutStatesDocument, ...options });
}
