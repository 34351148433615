import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type RenameTaskFileMutationVariables = Types.Exact<{
  file_id: Types.Scalars['ID'];
  name?: Types.InputMaybe<Types.Scalars['String']>;
}>;

export type RenameTaskFileMutation = {
  __typename?: 'Mutation';
  renameTaskFile: { __typename?: 'FileItem'; id: string; original_file_name: string };
};

export const RenameTaskFileDocument = gql`
  mutation RenameTaskFile($file_id: ID!, $name: String) {
    renameTaskFile(file_id: $file_id, name: $name) {
      id
      original_file_name
    }
  }
`;

export function useRenameTaskFileMutation() {
  return Urql.useMutation<RenameTaskFileMutation, RenameTaskFileMutationVariables>(
    RenameTaskFileDocument,
  );
}
