import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type DeleteTaskContactMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;

export type DeleteTaskContactMutation = {
  __typename?: 'Mutation';
  deleteTaskContact: { __typename?: 'Contact'; id: string };
};

export const DeleteTaskContactDocument = gql`
  mutation DeleteTaskContact($id: ID!) {
    deleteTaskContact(id: $id) {
      id
    }
  }
`;

export function useDeleteTaskContactMutation() {
  return Urql.useMutation<DeleteTaskContactMutation, DeleteTaskContactMutationVariables>(
    DeleteTaskContactDocument,
  );
}
