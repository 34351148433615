import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type TaskMapAdjusterFragment = {
  __typename?: 'Client';
  id: string;
  name: string;
  assignment_notes?: string | null;
  availability: {
    __typename?: 'ClientAssignmentAvailabilityState';
    id: string;
    title: string;
    color: string;
  };
  client_type?: {
    __typename?: 'ClientType';
    id: string;
    title: string;
    color: string;
    type_id: Types.ClientTypeId;
  } | null;
  leader?: { __typename?: 'Client'; id: string } | null;
  primary_location?: {
    __typename?: 'ClientLocation';
    street?: string | null;
    street_no?: string | null;
    postcode?: string | null;
    city?: string | null;
    latitude?: number | null;
    longitude?: number | null;
  } | null;
};

export type TasksMapAdjustersQueryVariables = Types.Exact<{ [key: string]: never }>;

export type TasksMapAdjustersQuery = {
  __typename?: 'Query';
  tasksMapAdjusters: {
    __typename?: 'TasksMapAdjusterList';
    data: Array<{
      __typename?: 'Client';
      id: string;
      name: string;
      assignment_notes?: string | null;
      availability: {
        __typename?: 'ClientAssignmentAvailabilityState';
        id: string;
        title: string;
        color: string;
      };
      client_type?: {
        __typename?: 'ClientType';
        id: string;
        title: string;
        color: string;
        type_id: Types.ClientTypeId;
      } | null;
      leader?: { __typename?: 'Client'; id: string } | null;
      primary_location?: {
        __typename?: 'ClientLocation';
        street?: string | null;
        street_no?: string | null;
        postcode?: string | null;
        city?: string | null;
        latitude?: number | null;
        longitude?: number | null;
      } | null;
    }>;
  };
};

export const TaskMapAdjusterFragmentDoc = gql`
  fragment TaskMapAdjuster on Client {
    id
    name
    assignment_notes
    availability {
      id
      title
      color
    }
    client_type {
      id
      title
      color
      type_id
    }
    leader {
      id
    }
    primary_location {
      street
      street_no
      postcode
      city
      latitude
      longitude
    }
  }
`;
export const TasksMapAdjustersDocument = gql`
  query TasksMapAdjusters {
    tasksMapAdjusters {
      data {
        ...TaskMapAdjuster
      }
    }
  }
  ${TaskMapAdjusterFragmentDoc}
`;

export function useTasksMapAdjustersQuery(
  options?: Omit<Urql.UseQueryArgs<TasksMapAdjustersQueryVariables>, 'query'>,
) {
  return Urql.useQuery<TasksMapAdjustersQuery, TasksMapAdjustersQueryVariables>({
    query: TasksMapAdjustersDocument,
    ...options,
  });
}
