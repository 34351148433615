import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CreateAppAllowlistIpAddressMutationVariables = Types.Exact<{
  input: Types.AppAllowlistIpAddressInput;
}>;

export type CreateAppAllowlistIpAddressMutation = {
  __typename?: 'Mutation';
  createAppAllowlistIpAddress: {
    __typename?: 'AppAllowlistIpAddress';
    ip: string;
    description?: string | null;
    creator_id?: string | null;
    created_at: string;
    updated_at: string;
    creator?: { __typename?: 'Client'; name: string } | null;
  };
};

export const CreateAppAllowlistIpAddressDocument = gql`
  mutation CreateAppAllowlistIpAddress($input: AppAllowlistIpAddressInput!) {
    createAppAllowlistIpAddress(input: $input) {
      ip
      description
      creator_id
      creator {
        name
      }
      created_at
      updated_at
    }
  }
`;

export function useCreateAppAllowlistIpAddressMutation() {
  return Urql.useMutation<
    CreateAppAllowlistIpAddressMutation,
    CreateAppAllowlistIpAddressMutationVariables
  >(CreateAppAllowlistIpAddressDocument);
}
