import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type DeleteClientMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;

export type DeleteClientMutation = {
  __typename?: 'Mutation';
  deleteClient?: { __typename?: 'Client'; id: string } | null;
};

export const DeleteClientDocument = gql`
  mutation DeleteClient($id: ID!) {
    deleteClient(id: $id) {
      id
    }
  }
`;

export function useDeleteClientMutation() {
  return Urql.useMutation<DeleteClientMutation, DeleteClientMutationVariables>(
    DeleteClientDocument,
  );
}
