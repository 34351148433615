import { classNames } from '@progress/kendo-react-common';
import { InputHandle, InputProps } from '@progress/kendo-react-inputs';
import { omit } from 'lodash';
import { ChangeEvent, useCallback, useState } from 'react';

export type PasswordInputProps = Omit<InputProps, 'size'> & {
  size?: null | 'small' | 'medium' | 'large';
};

export const PasswordInput = (props: PasswordInputProps) => {
  const { id, className, valid, style, size = 'large' } = props;
  const [type, setType] = useState('password');

  const restProps = omit(props, [
    'size',
    'type',
    'className',
    'label',
    'validationMessage',
    'defaultValue',
    'valid',
    'ariaLabelledBy',
    'ariaDescribedBy',
    'validityStyles',
    'style',
  ]);

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      props.onChange?.({
        syntheticEvent: event,
        nativeEvent: event.nativeEvent,
        value: event.target.value,
        target: event.target as unknown as InputHandle,
      });
    },
    [props],
  );

  const handleToggleType = useCallback(() => {
    setType(type === 'password' ? 'text' : 'password');
  }, [type]);

  return (
    <span
      className={classNames('k-input k-input-md k-rounded-md k-input-solid', className, {
        'k-valid': valid,
        'k-input-sm': size === 'small',
        'k-input-md': size === 'medium',
        'k-input-lg': size === 'large',
      })}
      style={style}
    >
      <input id={id} className="k-input-inner" type={type} {...restProps} onChange={handleChange} />
      <span className="k-input-icon" onClick={handleToggleType}>
        <span className="l-i-eye" />
      </span>
    </span>
  );
};
