import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type DeleteTaskCalculationCategoryMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;

export type DeleteTaskCalculationCategoryMutation = {
  __typename?: 'Mutation';
  deleteTaskCalculationCategory: {
    __typename?: 'TaskCalculationCategory';
    id: string;
    title: string;
    sort_index: number;
  };
};

export const DeleteTaskCalculationCategoryDocument = gql`
  mutation DeleteTaskCalculationCategory($id: ID!) {
    deleteTaskCalculationCategory(id: $id) {
      id
      title
      sort_index
    }
  }
`;

export function useDeleteTaskCalculationCategoryMutation() {
  return Urql.useMutation<
    DeleteTaskCalculationCategoryMutation,
    DeleteTaskCalculationCategoryMutationVariables
  >(DeleteTaskCalculationCategoryDocument);
}
