import {
  ColorBadgeCell,
  Loader,
  TaskGroupSortAndFilterColumns,
  useGridPagerSettings,
} from '@module/common';
import { useGridStateWithPaginationReset } from '@module/common/hooks';
import { FilterContextProvider } from '@module/shared/filters';
import { GridState, useGridFilter, useGridPagination, useGridSort } from '@module/shared/graphql';
import { CompositeFilterDescriptor } from '@progress/kendo-data-query';
import {
  Grid,
  GridCellProps,
  GridColumn,
  GridDataStateChangeEvent,
} from '@progress/kendo-react-grid';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useTaskGroupsQuery } from '../../graphql';
import { useTaskGroupOverviewFilters } from '../../hooks/useTaskGroupOverviewFilter';
import { TaskGroupCommandCell } from './TaskGroupCommandCell';
import { TaskGroupToolbar } from './TaskGroupToolbar';

const CommandCell = (props: GridCellProps) => {
  const { dataItem } = props;
  return <TaskGroupCommandCell group={dataItem} />;
};

const mapColumns = {
  title: TaskGroupSortAndFilterColumns.TITLE,
};

function useGridVariables(gridState: GridState, filter: CompositeFilterDescriptor) {
  const gridFilters = useGridFilter(filter, mapColumns);
  const gridSort = useGridSort(gridState.sort, mapColumns);
  const gridPagination = useGridPagination(gridState);

  return useMemo(() => {
    return {
      filter: gridFilters,
      sort: { columns: gridSort },
      pagination: gridPagination,
    };
  }, [gridFilters, gridPagination, gridSort]);
}

export const TaskGroupGrid = () => {
  const { t } = useTranslation();
  const pagerSettings = useGridPagerSettings();
  const { filterContext } = useTaskGroupOverviewFilters();
  const [gridState, setGridState] = useGridStateWithPaginationReset(
    filterContext.filterState.filter,
  );
  const variables = useGridVariables(gridState, filterContext.filterState.filter);
  const [{ data, fetching: isLoading }, refetch] = useTaskGroupsQuery({ variables });

  const clients = data?.taskGroups.data ?? [];
  const total = data?.taskGroups.paginatorInfo.total ?? 0;

  const handleOnRefresh = useCallback(() => {
    refetch({ requestPolicy: 'network-only' });
  }, [refetch]);
  const handleOnDataStateChange = useCallback(
    (e: GridDataStateChangeEvent) => {
      setGridState(e.dataState);
    },
    [setGridState],
  );

  return (
    <FilterContextProvider filterContext={filterContext}>
      {isLoading && <Loader />}
      <TaskGroupToolbar
        onRefresh={handleOnRefresh}
        filter={variables.filter}
        sort={variables.sort}
      />
      <Grid
        className="!k-border-0"
        filterable={false}
        pageable={pagerSettings}
        sortable={true}
        onDataStateChange={handleOnDataStateChange}
        {...gridState}
        total={total}
        data={clients}
      >
        <GridColumn
          field={'title'}
          className="!k-font-weight-semibold"
          title={t('settings.pages.taskGroups.labels.name')}
        />
        <GridColumn cell={ColorBadgeCell} title={t('settings.pages.taskGroups.labels.color')} />
        <GridColumn
          title={t('common.labels.actions')}
          cell={CommandCell}
          width={100}
          filterable={false}
        />
      </Grid>
    </FilterContextProvider>
  );
};
