export default {
  labels: {
    completeTask: 'Bericht + Rechnung verschickt',
    confirmSendReport: 'Bericht verschickt',
  },
  dialogs: {
    sendReport: {
      title: 'Bericht verschickt',
    },
    completeTask: {
      title: 'Bericht & Rechnung verschickt',
    },
  },
};
