import { useMe } from '@module/casl';
import {
  CustomUploadOnSave,
  fileExtensions,
  useConfirmDialogs,
  useCustomUpload,
} from '@module/common';
import { UploadWithPreview } from '@module/common/components/UploadWithPreview/UploadWithPreview';
import { StackLayout } from '@progress/kendo-react-layout';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useUploadProfileImageMutation } from '../graphql';

export const ProfileImageContainer = () => {
  const { t } = useTranslation();
  const confirm = useConfirmDialogs();

  const me = useMe();
  const fileItem = me.profile_image;

  const [, uploadProfileImage] = useUploadProfileImageMutation();
  const { uploadProps } = useCustomUpload();

  const handleSave = useCallback<CustomUploadOnSave>(
    async (e) => {
      const { file, context, resolve, reject } = e;
      const result = await uploadProfileImage({ file: file.getRawFile() }, context);
      if (result.error) {
        return reject(file.uid);
      }
      return resolve(file.uid);
    },
    [uploadProfileImage],
  );

  const handleDelete = async () => {
    confirm.delete({
      title: t('settings.profileImage.confirmDelete.title'),
      description: t('settings.profileImage.confirmDelete.description'),
      delete: async () => {
        await uploadProfileImage({ file: null });
      },
    });
  };

  return (
    <StackLayout orientation="vertical" gap={10} className="k-px-1">
      <div className="!k-mt-4">
        <b>{t('settings.profileImage.title')}</b>
        <div>{t('settings.profileImage.description')}</div>
      </div>
      <UploadWithPreview
        allowedExtensions={[fileExtensions.JPG, fileExtensions.PNG]}
        item={fileItem}
        onDelete={handleDelete}
        onSave={handleSave}
        {...uploadProps}
        multiple={false}
        autoUpload
      />
    </StackLayout>
  );
};
