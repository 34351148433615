import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import { AuthStateFragmentDoc } from './types.generated';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type LoginAsCustomerMutationVariables = Types.Exact<{
  hash: Types.Scalars['String'];
}>;

export type LoginAsCustomerMutation = {
  __typename?: 'Mutation';
  loginAsCustomer: {
    __typename?: 'LoginResponse';
    message: string;
    requiresVerification: boolean;
    authState?: {
      __typename?: 'AuthState';
      data: {
        __typename?: 'AuthData';
        access_token: string;
        refresh_token: string;
        expires_at: string;
        refresh_token_expires_at: string;
      };
    } | null;
  };
};

export const LoginAsCustomerDocument = gql`
  mutation LoginAsCustomer($hash: String!) {
    loginAsCustomer(hash: $hash) {
      message
      authState {
        ...AuthState
      }
      requiresVerification
    }
  }
  ${AuthStateFragmentDoc}
`;

export function useLoginAsCustomerMutation() {
  return Urql.useMutation<LoginAsCustomerMutation, LoginAsCustomerMutationVariables>(
    LoginAsCustomerDocument,
  );
}
