export default {
  labels: {
    area: 'Gebiet',
    residents: 'Einwohner',
    client_primary: 'AR1',
    client_secondary: 'AR2',
    client_tertiary: 'AR3',
    client_leader: 'Teamleiter',
  },
  dialogs: {
    update: {
      title: 'Zuweisungsgebiet bearbeiten',
      note: 'Hier können Sie die Regulierer und Teamleiter für das Zuweisungsgebiet einstellen.',
    },
    import: {
      title: 'Zuweisungen Importieren',
      downloadHint: 'Beispiel Tabelle herunterladen',
      import: 'Import',
    },
  },
  pages: {
    overview: {
      title: 'Zuweisungsgebiete',
      description:
        'Hier sehen Sie eine Liste von Postleitzahlengebieten und den jeweils zugeständigen Sachbearbeitern.',
      list: 'Liste',
      map: 'Karte',
      import: 'Import',
      export: 'Export',
    },
  },
  notifications: {
    update_area_assignment: {
      success: 'Zuweisungsgebiet wurde gespeichert.',
    },
  },
};
