import './FilterToolbar.scss';

import { Button } from '@progress/kendo-react-buttons';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useDialogs } from '../../dialogs';
import { useFilterContext } from '../hooks/useFilterContext';
import { FilterBar } from './FilterBar';
import { FilterDialog } from './FilterDialog';

export const FilterToolbar = () => {
  const { t } = useTranslation();
  const filterContext = useFilterContext();
  const { showDialog } = useDialogs();

  const onToggleFilterDialog = useCallback(() => {
    showDialog({ filterContext }, FilterDialog);
  }, [filterContext, showDialog]);

  if (filterContext.expandedFields.length === 0 || !filterContext.filterState.expanded) {
    return null;
  }

  if (filterContext.expandedFields.length === 0 || !filterContext.filterState.expanded) {
    return null;
  }

  return (
    <div
      className="FilterToolbar k-widget k-toolbar !k-overflow-x-scroll
      k-display-flex k-flex-row !k-justify-content-between background !k-align-items-end k-p-2"
    >
      <div className="k-display-flex k-flex-row k-flex-wrap k-gap-2">
        <FilterBar
          fields={filterContext.expandedFields}
          onFilterChange={filterContext.changeExpandedFilter}
        />
      </div>

      {filterContext.additionalFields.length > 0 && (
        <Button onClick={onToggleFilterDialog} size="large">
          {t('common.components.filterBar.additional_filters', {
            num_filters: filterContext.additionalFields.length,
          })}
        </Button>
      )}
    </div>
  );
};
