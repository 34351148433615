import { WebMercatorViewport } from '@math.gl/web-mercator';
import { useCallback, useMemo, useState } from 'react';
import { MapProps, MapRef, ViewState, ViewStateChangeEvent } from 'react-map-gl';

interface Point {
  latitude: number;
  longitude: number;
}

interface FitPointsOptions {
  zoom?: number;
}

export interface MapSize {
  width: number;
  height: number;
}

const fitViewStateToPoints = <TViewState extends Partial<ViewState>>(
  viewState: TViewState,
  points: Array<{ latitude: number; longitude: number }>,
  mapSize: MapSize,
  options: FitPointsOptions = { zoom: 12 },
): TViewState => {
  // There are no bounds without points. We just keep the current state as is.
  if (!points.length) {
    return viewState;
  }

  // When there is only one point, center the map with a fixed zoom level.
  if (points.length === 1) {
    return {
      ...viewState,
      latitude: points[0].latitude,
      longitude: points[0].longitude,
      zoom: options.zoom,
    };
  }

  const latitudes = points.map(({ latitude }) => latitude);
  const longitudes = points.map(({ longitude }) => longitude);

  const southWest = {
    latitude: Math.min(...latitudes),
    longitude: Math.min(...longitudes),
  };

  const northEast = {
    latitude: Math.max(...latitudes),
    longitude: Math.max(...longitudes),
  };

  const { latitude, longitude, zoom } = new WebMercatorViewport(mapSize).fitBounds(
    [
      [southWest.longitude, southWest.latitude],
      [northEast.longitude, northEast.latitude],
    ],
    { padding: 48 },
  );

  return {
    ...viewState,
    latitude,
    longitude,
    zoom,
  };
};

export type MapViewState = Omit<ViewState, 'padding' | 'bearing' | 'pitch'>;

export function useMapboxViewState(initialViewState: MapViewState) {
  const [viewState, setViewState] = useState<MapViewState>(initialViewState);
  const [map, setMap] = useState<MapRef | null>(null);
  const [mapSize, setMapSize] = useState<MapSize | null>(null);

  const handleMapSize = useCallback<NonNullable<MapProps['onLoad'] | MapProps['onResize']>>(
    ({ target }) =>
      setMapSize({
        width: target.getContainer().clientWidth,
        height: target.getContainer().clientHeight,
      }),
    [],
  );

  const onMove = useCallback((e: ViewStateChangeEvent) => setViewState(e.viewState), []);

  const fitToPoints = useCallback(
    (points: Point[], options?: FitPointsOptions) => {
      setViewState((currentViewState) => {
        if (!map) {
          return currentViewState;
        }

        return fitViewStateToPoints(
          currentViewState,
          points,
          {
            width: map.getContainer().clientWidth,
            height: map.getContainer().clientHeight,
          },
          options,
        );
      });
    },
    [map],
  );

  const mapProps = useMemo(
    () => ({
      viewState,
      onMove,
      onLoad: handleMapSize,
      onResize: handleMapSize,
      mapRef: setMap,
    }),
    [onMove, handleMapSize, viewState],
  );
  return { mapProps, mapSize, fitToPoints, map };
}
