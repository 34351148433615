import { ChangeUserButton, HistoryButton, PageHeader } from '@module/common';
import { AppMessageIndicator } from '@module/common/components/layout/AppMessageIndicator';

import { LogoutTimer } from './LogoutTimer';
import { PageHeaderAvatar } from './PageHeaderAvatar';
import { PageHeaderNotifications } from './PageHeaderNotifications';

export const PrivatePageHeader = () => {
  return (
    <PageHeader
      history={<HistoryButton />}
      notifications={<PageHeaderNotifications />}
      logoutTimer={<LogoutTimer />}
      avatar={<PageHeaderAvatar />}
      changeUser={<ChangeUserButton />}
      appMessageIndicator={<AppMessageIndicator />}
    />
  );
};
