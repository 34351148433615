import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type DeleteContactMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;

export type DeleteContactMutation = {
  __typename?: 'Mutation';
  deleteContact?: { __typename?: 'Contact'; id: string } | null;
};

export const DeleteContactDocument = gql`
  mutation DeleteContact($id: ID!) {
    deleteContact(id: $id) {
      id
    }
  }
`;

export function useDeleteContactMutation() {
  return Urql.useMutation<DeleteContactMutation, DeleteContactMutationVariables>(
    DeleteContactDocument,
  );
}
