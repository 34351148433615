import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CreateTaskCalculationCategoryMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  category: Types.Scalars['String'];
  sort_index: Types.Scalars['Int'];
}>;

export type CreateTaskCalculationCategoryMutation = {
  __typename?: 'Mutation';
  createTaskCalculationCategory: {
    __typename?: 'TaskCalculationCategory';
    id: string;
    title: string;
    sort_index: number;
  };
};

export const CreateTaskCalculationCategoryDocument = gql`
  mutation CreateTaskCalculationCategory($id: ID!, $category: String!, $sort_index: Int!) {
    createTaskCalculationCategory(
      task_calculation_id: $id
      input: { title: $category, sort_index: $sort_index }
    ) {
      id
      title
      sort_index
    }
  }
`;

export function useCreateTaskCalculationCategoryMutation() {
  return Urql.useMutation<
    CreateTaskCalculationCategoryMutation,
    CreateTaskCalculationCategoryMutationVariables
  >(CreateTaskCalculationCategoryDocument);
}
