export default {
  labels: {
    id: 'Id',
    title: 'Naslov',
    unit: 'Jedinica',
    price: 'Cijena',
    category: 'Kategorija',
    import: 'Uvoz',
    export: 'Izvoz',
    postalCodeFactor: 'Faktor regije',
    postcodeFactor: 'faktor lokacije',
    description: 'Opis',
    catalog: 'Katalog',
    createCatalog: 'Izradite katalog',
    manageCatalogs: 'Upravljanje katalozima',
    color: 'Boja',
    submit: 'spasiti trgovinu',
  },
  components: {
    toolbar: 'Alatna traka',
    serviceCatalogCategoryFilter: {
      title: 'Kategorija',
      popoverTitle: 'Pokazati samo',
      selectAll: 'Pokaži sve',
      deselectAll: 'Odznači sve',
    },
  },
  dialogs: {
    import: {
      title: 'Uvoz usluga',
      note: 'Ovdje možete uvesti sve usluge putem Excela',
      save: 'Uvoz',
      cancel: 'Odustani',
      success: 'Katalog je uspješno uvezen.',
    },
    postalCodeFactors: {
      edit: {
        title: 'Faktor regije',
        note: 'Ovdje možete prilagoditi faktor regije.',
      },
    },
    export: {
      postalCodeFactors: {
        title: 'Izvezi faktor regije',
        note: 'Preuzmite izvezene faktore regije.',
      },
      catalogItems: {
        title: 'Preuzmite usluge',
        note: 'Preuzmite izveze usluge.',
      },
      postcodeFactors: {
        title: 'Čimbenici lokacije preuzimanja',
        note: 'Preuzmite izvezene faktore lokacije.',
      },
    },
    postcodeFactors: {
      edit: {
        title: 'faktor lokacije',
        note: 'Ovdje možete prilagoditi faktor lokacije.',
      },
    },
    createCatalog: {
      title: 'Izradite katalog',
      note: 'Ovdje možete kreirati novi katalog.',
      save: 'Izradite katalog',
      success: 'Katalog je uspješno izrađen.',
    },
    updateCatalogItem: {
      title: 'uredi izvedbu',
      note: 'Ovdje možete urediti izvedbu.',
      form: {
        identifier: 'identifikator',
        title: 'titula',
        description: 'Opis',
        price: 'Cijena',
        unit: 'Jedinica',
        category: 'kategorija',
        catalog: 'Katalog',
      },
      success: 'Izvedba je ažurirana.',
    },
    manageCatalogs: {
      title: 'Ažurirajte kataloge',
      note: 'Ovdje možete ažurirati kataloge.',
    },
    updateCatalog: {
      title: 'Uredi katalog',
      note: 'Ovdje možete urediti katalog.',
    },
  },
  pages: {
    overview: {
      title: 'Usluge',
      description: 'Ovdje ćete pronaći sve usluge',
    },
    postalCodeFactors: {
      title: 'Faktor regije',
      description: 'Ovdje možete pronaći sve faktore regije',
    },
    postcodeFactors: {
      title: 'čimbenici lokacije',
      description: 'Ovdje možete pronaći sve faktore lokacije',
    },
    tradesStatus: {
      title: 'Status obrta',
      description:
        'Ovdje možete prilagoditi status trgovine, koji se može koristiti za grupiranje novih trgovina.',
      toolbar: {
        createTradesStatus: 'Novi obrt',
      },
      dialog: {
        create: {
          title: 'stvoriti trgovinu',
          success: 'Trgovina uspješno stvorena',
          error: 'Nažalost, došlo je do pogreške prilikom kreiranja trgovine',
        },
        edit: {
          title: 'urediti trgovinu',
          success: 'Trgovina je uspješno obrađena',
          error: 'Nažalost, došlo je do pogreške prilikom spremanja trgovine',
        },
        delete: {
          title: 'Izbrisati trgovinu?',
          note: 'Želite li stvarno izbrisati ovaj unos?',
          success: 'Trgovina je uspješno izbrisana',
          error: 'Nažalost, došlo je do pogreške prilikom brisanja trgovine',
        },
      },
    },
  },
};
