export default {
  events: {
    created: 'Created',
    updated: 'Updated',
    deleted: 'Deleted',
    login: 'Login',
  },
  pages: {
    activities: {
      title: 'Activity log',
      description: 'Here you can see all actions that have been made.',
      dialogs: {
        changes: {
          title: 'Changes',
          description: 'The following changes havbe been made:',
          oldValue: 'Old value',
          newValue: 'New value',
        },
      },
      grid: {
        system: 'SYSTEM',
        columns: {
          createdAt: 'Timestamp',
          email: 'By',
          logName: 'Identifier',
          event: 'Type',
          description: 'Description',
          changes: 'Details',
        },
      },
    },
  },
};
