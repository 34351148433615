import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type MyApiTokensFragment = {
  __typename?: 'ApiTokenList';
  data: Array<{
    __typename?: 'ApiToken';
    uuid: string;
    user_id: string;
    revoked: boolean;
    expires_at: string;
    created_at: string;
  }>;
};

export type MyApiTokensQueryVariables = Types.Exact<{ [key: string]: never }>;

export type MyApiTokensQuery = {
  __typename?: 'Query';
  myApiTokens: {
    __typename?: 'ApiTokenList';
    data: Array<{
      __typename?: 'ApiToken';
      uuid: string;
      user_id: string;
      revoked: boolean;
      expires_at: string;
      created_at: string;
    }>;
  };
};

export const MyApiTokensFragmentDoc = gql`
  fragment MyApiTokens on ApiTokenList {
    data {
      uuid
      user_id
      revoked
      expires_at
      created_at
    }
  }
`;
export const MyApiTokensDocument = gql`
  query MyApiTokens {
    myApiTokens {
      ...MyApiTokens
    }
  }
  ${MyApiTokensFragmentDoc}
`;

export function useMyApiTokensQuery(
  options?: Omit<Urql.UseQueryArgs<MyApiTokensQueryVariables>, 'query'>,
) {
  return Urql.useQuery<MyApiTokensQuery, MyApiTokensQueryVariables>({
    query: MyApiTokensDocument,
    ...options,
  });
}
