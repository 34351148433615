import axios, { AxiosRequestConfig } from 'axios';

import { isString } from '../../helpers';

export interface RequestInitExtended extends RequestInit {
  onUploadProgress?: (progressEvent: ProgressEvent) => void;
  onUploadFinished?: () => void;
}

export function uploadWithAxios(
  input: RequestInfo | URL,
  init?: RequestInitExtended,
): Promise<Response> {
  const url = isString(input) ? input : input instanceof URL ? input.toString() : input.url;
  const formData = init?.body as FormData;

  return axios
    .post(url, formData, {
      onUploadProgress: init?.onUploadProgress as AxiosRequestConfig['onUploadProgress'],
      headers: init?.headers as Record<string, string> | undefined,
    })
    .then((result) => {
      // return fetch response
      init?.onUploadFinished?.();
      return new Response(JSON.stringify(result.data), {
        headers: result.headers as Record<string, string>,
        status: result.status,
        statusText: result.statusText,
      });
    });
}
