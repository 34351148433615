import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type UpdateUserPasswordSelfMutationVariables = Types.Exact<{
  input: Types.UpdateUserPasswordInput;
}>;

export type UpdateUserPasswordSelfMutation = {
  __typename?: 'Mutation';
  updateUserPasswordSelf?: { __typename?: 'User'; id: string } | null;
};

export const UpdateUserPasswordSelfDocument = gql`
  mutation UpdateUserPasswordSelf($input: UpdateUserPasswordInput!) {
    updateUserPasswordSelf(input: $input) {
      id
    }
  }
`;

export function useUpdateUserPasswordSelfMutation() {
  return Urql.useMutation<UpdateUserPasswordSelfMutation, UpdateUserPasswordSelfMutationVariables>(
    UpdateUserPasswordSelfDocument,
  );
}
