import { SortDescriptor } from '@progress/kendo-data-query';
import { useMemo } from 'react';

import { SortOrder } from '../../../../../types/graphql.generated';
import { ColumnsMap } from './types';

interface GridSort<TColumnsEnum> {
  column: TColumnsEnum;
  order: SortOrder;
}

type GridSorts<TColumnsEnum> = GridSort<TColumnsEnum>[];

function convertToGridSort<TColumnsEnum>(
  sort: SortDescriptor,
  columns: ColumnsMap<TColumnsEnum>,
): GridSort<TColumnsEnum> | undefined {
  if (sort.field in columns && sort.dir) {
    return {
      column: columns[sort.field],
      order: sort.dir === 'asc' ? SortOrder.ASC : SortOrder.DESC,
    };
  }
  return undefined;
}

export function useGridSort<TColumnsEnum>(
  sort: Array<SortDescriptor> | undefined,
  columns: ColumnsMap<TColumnsEnum>,
): GridSorts<TColumnsEnum> | undefined {
  return useMemo(() => {
    return sort
      ?.map((sort) => convertToGridSort(sort, columns))
      .filter((gridSort): gridSort is GridSort<TColumnsEnum> => gridSort !== undefined);
  }, [columns, sort]);
}
