import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type DeleteClientAbsenceMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;

export type DeleteClientAbsenceMutation = {
  __typename?: 'Mutation';
  deleteClientAbsence?: {
    __typename?: 'ResponseMessage';
    message?: string | null;
    success?: boolean | null;
  } | null;
};

export const DeleteClientAbsenceDocument = gql`
  mutation DeleteClientAbsence($id: ID!) {
    deleteClientAbsence(id: $id) {
      message
      success
    }
  }
`;

export function useDeleteClientAbsenceMutation() {
  return Urql.useMutation<DeleteClientAbsenceMutation, DeleteClientAbsenceMutationVariables>(
    DeleteClientAbsenceDocument,
  );
}
