import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import { ClassificationTagFragmentDoc } from './classificationTagFragment.generated';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ClassificationTagsQueryVariables = Types.Exact<{
  filter: Types.ClassificationTagsFilter;
  sort: Types.ClassificationTagsSort;
  pagination: Types.PaginationInput;
}>;

export type ClassificationTagsQuery = {
  __typename?: 'Query';
  classificationTags: {
    __typename?: 'ClassificationTagsPaginator';
    data: Array<{
      __typename?: 'ClassificationTag';
      id: string;
      title: string;
      color: string;
      alias: string;
      api_only: boolean;
    }>;
    paginatorInfo: { __typename?: 'PaginatorInfo'; total: number };
  };
};

export const ClassificationTagsDocument = gql`
  query ClassificationTags(
    $filter: ClassificationTagsFilter!
    $sort: ClassificationTagsSort!
    $pagination: PaginationInput!
  ) {
    classificationTags(filter: $filter, sort: $sort, pagination: $pagination) {
      data {
        ...ClassificationTag
      }
      paginatorInfo {
        total
      }
    }
  }
  ${ClassificationTagFragmentDoc}
`;

export function useClassificationTagsQuery(
  options: Omit<Urql.UseQueryArgs<ClassificationTagsQueryVariables>, 'query'>,
) {
  return Urql.useQuery<ClassificationTagsQuery, ClassificationTagsQueryVariables>({
    query: ClassificationTagsDocument,
    ...options,
  });
}
