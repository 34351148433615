import { GridCellProps } from '@progress/kendo-react-grid';
import { Input, InputChangeEvent, NumericTextBoxHandle } from '@progress/kendo-react-inputs';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { CellValueWithErrorHint } from './CellValueWithErrorHint';
import { useEditableCellContext } from './EditableCellContext';
import { EditableCellErrorHint } from './EditableCellErrorHint';

export const EditableTextCell = (props: GridCellProps) => {
  const editableCellContext = useEditableCellContext();
  const { dataItem, dataIndex, field } = props;
  const value = useMemo(() => {
    return field && dataItem[field] && typeof dataItem[field] === 'string'
      ? (dataItem[field] as string)
      : undefined;
  }, [dataItem, field]);

  const inEdit = dataItem.inEdit as string | undefined;
  const error = field && dataItem.errors ? dataItem.errors[field] : false;

  // handle focus
  const ref = useRef<NumericTextBoxHandle>(null);
  const [triggerFocus, setTriggerFocus] = useState(false);
  useEffect(() => {
    // reset after trigger
    if (ref.current?.element && triggerFocus) {
      setTriggerFocus(false);
      ref.current?.element.focus();
    }

    // reset if field not in edit mode
    if (inEdit !== field && triggerFocus) {
      setTriggerFocus(false);
    }
  }, [dataIndex, field, inEdit, triggerFocus]);

  // edit mode
  const handleEnterEdit = useCallback(() => {
    if (field && editableCellContext) {
      editableCellContext.enterEdit?.(dataItem, field);
      setTriggerFocus(true);
    }
  }, [dataItem, editableCellContext, field]);

  const handleChange = useCallback(
    (e: InputChangeEvent) => {
      if (field) {
        props.onChange?.({
          dataItem,
          dataIndex,
          syntheticEvent: e.syntheticEvent,
          field,
          value: e.target.value,
        });
      }
    },
    [dataIndex, dataItem, field, props],
  );

  if (!field) {
    return null;
  }

  return (
    <td className={props.className} onClick={handleEnterEdit}>
      {inEdit !== field && <CellValueWithErrorHint value={value} error={error} />}
      {inEdit && inEdit === field && (
        <span className="k-input k-input-solid k-rounded-md k-input-lg">
          <Input
            ref={ref}
            value={value}
            onChange={handleChange}
            className="k-text-right k-input-inner"
          />
          <EditableCellErrorHint error={error} />
        </span>
      )}
    </td>
  );
};
