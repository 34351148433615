export default {
  labels: {
    id: 'Id',
    apply: 'Primjeni',
    cancel: 'Odustani',
    back: 'Povratak',
    reset: 'Reset',
    submit: 'Prihvati',
    save: 'Snimi',
    upload: 'Učitaj',
    download: 'Preuzmi',
    compare: 'Usporedi',
    discard: 'Odbaci',
    saveChanges: 'Snimi izmjene',
    export: 'Izvoz',
    create: 'Kreiraj',
    delete: 'Izbriši',
    duplicate: 'Dupliciraj',
    confirm: 'Potvrdi',
    open: 'Otvori',
    preview: 'Pretpregled',
    select: 'Odaberi',
    edit: 'Uredi',
    rename: 'Preimenovati',
    search: 'Traži...',
    done: 'Gotovo',
    title: 'Naslov',
    name: 'Ime',
    firstname: 'Ime',
    lastname: 'Prezime',
    email: 'E-Mail',
    primary_email: 'Primarna E-Mail adresa',
    password: 'Lozinka',
    password_confirmation: 'Potvrda lozinke',
    phone: 'Telefon',
    phone2: 'Telefon 2',
    mobile: 'Mobitel',
    mobile2: 'Mobitel 2',
    fax: 'Fax',
    iban: 'IBAN',
    bic: 'BIC',
    salutation_id: 'Anrede',
    street: 'Ulica',
    postalCode: 'Pošt. Br.',
    city: 'Grad',
    country: 'Država',
    description: 'Opis',
    street_no: 'Kućni Br..',
    postal_code: 'Pošt. Br.',
    defaultItem: 'Molimo izaberite',
    defaultItemAll: 'Sve',
    defaultItemAllCountries: 'Sve države',
    notes: 'Bilješke',
    bankDetails: 'Bankovni detalji',
    communication: 'Komunikacija',
    personal: 'Osobno',
    address: 'Adresa',
    version: 'Versija',
    yes: 'Da',
    no: 'Ne',
    date: 'Datum',
    time: 'Vrijeme',
    close: 'Zatvori',
    loggedInAs: 'Prijavljen kao',
    loginAs: 'Prijavljen kao {{name}}',
    actions: 'Radnje',
    view: 'Pogled',
    from: 'od',
    to: 'do',
    new: 'Novo',
    add: 'Dodaj',
    remove: 'Ukloni',
    update: 'Ažuriraj',
    latitude: 'Zemljopisna širina',
    longitude: 'Zemljopisna dužina',
    designer: 'Dizajner',
    copy: 'Kopiraj',
    copied: 'Kopirano',
    finishDate: 'Datum završetka',
    color: 'boja',
    code: 'Kod',
    classify: 'Klasificiraj',
    createdAtDate: 'Kreirano {{date}}',
    todayRelativeDays_zero: 'Danas',
    todayRelativeDays_one: '{{count}} Dana',
    todayRelativeDays_other: '{{count}} Dana',
    todayRelativeMinutes: '{{count}} Minuta',
    duration: 'Trajanje',
    viewOnly: 'Samo pregled',
    closeTemporarily: 'Privremeno zatvori',
    lastLogin: 'Zadnja prijava',
    secondary_email: 'Sekundarna adresa e-pošte',
    primary_phone: 'Glavni telefonski broj',
    secondary_phone: 'Sekundarni broj telefona',
    changeAssignments: 'promijeniti dodjelu',
    role: 'uloga',
    location: 'Podružnica',
    general: 'općenito',
    postcode: 'Poštanski broj',
    loadingItem: 'Učitavam...',
    on: 'Na',
    off: 'Od',
    sendEmail: 'Pošalji e-mail',
    sendMailInternal: 'putem pregleda',
    sendMailExternal: 'putem lokalnog programa za e-poštu',
    appointment: 'posjet mjestu',
    external_preview: 'Otvori na novoj stranici',
    tab_preview: 'Otvori na novoj stranici',
    contact_name: 'ime/oznaka',
    otp: 'sigurnosni kod',
    salutation: 'Pozdrav',
    function: 'funkcija',
    newAppMessage: 'Nova poruka sustava',
    qualifications: 'Kvalifikacije',
    alternate_email: 'Alternativna email adresa',
    disabled: 'Onemogućeno',
    state: 'status',
    globalTaskSearch: 'Pregledaj narudžbe...',
  },
  formats: {
    date: 'dd.MM.yyyy',
    dateTime: 'dd.MM.yyyy HH:mm:ss',
    timeWithoutSeconds: 'H:mm',
    time: 'H:mm:ss',
    dayMonth: 'dd.MM.',
    dateTimeWithoutSeconds: 'dd.MM.yyyy HH:mm',
    monthYear: 'MMMM gggg',
  },
  validators: {
    required: ':field je potrebno',
  },
  navigation: {
    sections: {
      clients: 'Opći podaci',
      tasks: 'Zahtjevi',
      account: 'Korisnički račun',
      platform: 'Platforma',
      settings: 'Postavke',
      templateSettings: 'Šablone',
      serviceCatalog: 'Servisni katalog',
      extendedSettings: 'Napredne postavke',
      chat: 'razgovor',
      debug: 'debug',
      policyHolders: 'Osiguranik',
    },
    items: {
      dashboard: 'Nadzorna ploča',
      clients: 'Opći podaci',
      myTasks: 'Moji zahtjevi',
      tasks: 'Zahtjevi',
      calendar: 'Kalendar',
      serviceCatalogServices: 'Servisni katalog',
      serviceCatalogPostalCodeFactors: 'Uvjeti lokacije',
      reportingTemplates: 'Predlošci za izvješćivanje',
      statisticsTemplates: 'Statistički predlošci',
      emailTemplates: 'E-Mail predlošci',
      reports: 'Izvještaji',
      users: 'Korisnici',
      newTask: 'Novi Zahtjev',
      pdfs: 'PDF Formulari',
      articles: 'Artikli',
      map: 'Područja dodjele',
      platform: 'Platforma',
      profile: 'Profil',
      branding: 'Postavke aplikacije',
      folderTemplates: 'Standardni poredak',
      profileSettings: 'Postavke profila',
      logout: 'Izlaz',
      classificationTags: 'Klasifikacije reda',
      fileClassificationTags: 'Klasifikacije datoteka',
      activityLog: 'Aktivnost',
      invoices: 'računi',
      payouts: 'isplate',
      serviceCatalogPostcodeFactors: 'čimbenici lokacije',
      tradesStatus: 'Status obrta',
      subtasks: 'Zadaci',
      demoData: 'demo podaci',
      overview: 'Pregled',
      stampTemplates: 'Gumeni pečat',
      qualifications: 'Kvalifikacije',
      devices: 'Uređaji',
      policyHolders: 'Osiguranik',
      addressBook: 'imenik',
    },
  },
  components: {
    calendarToolbar: {
      today: 'Danas',
      timeline: 'Vremenska Crta',
      day: 'Dan',
      week: 'tjedan',
      month: 'Mjesec',
      agenda: 'Planer',
      createEvent: 'Kreiraj događaj',
    },
    calendar: {
      appointment: 'Termin',
      note: 'Ovdje možete unijeti novi termin.',
      start: 'Start',
      end: 'Završetak',
      title: 'Naslov',
      description: 'Opis',
      isAllDay: 'Cijeli dan',
    },
    layout: {
      pageHeader: {
        unread: 'Nepročitan',
        createdAt: 'prije {{time}}',
        markAsRead: {
          error: 'Obavijest se ne može označiti kao pročitana',
          success: 'Obavijest je označena kao pročitana',
        },
        markAllAsRead: {
          title: 'Označi sve kao pročitano',
          error: 'Obavijesti se ne mogu označiti kao pročitane',
          success: 'Sve obavijesti označene kao pročitane',
        },
      },
      historyPopup: {
        lastVisitedPages: 'Nedavno posjećene stranice',
        deleteHistory: 'Izvriši povijest',
      },
    },
    footer: {
      termsOfService: 'Uvjeti korištenja',
      privacy: 'Privatnost',
      contact: 'Kontakt',
      imprint: 'Impressum',
    },
    strength_bar: {
      security: 'Sigurnost',
      strength_1: 'vrlo slaba',
      strength_2: 'slaba',
      strength_3: 'srednja',
      strength_4: 'dobra',
      strength_5: 'vrlo dobra',
    },
    filterDialog: {
      title: 'Napredni filteri',
      note: 'Ovdje možete konfigurirati složenije filtere za svoje narudžbe.',
      tabs: {
        common: 'Standard',
        advanced: 'Napredna razina',
      },
    },
    filterBar: {
      additional_filters: 'Dodatni filteri({{num_filters}})',
    },
    filter: {
      popoverTitle: 'Pokazati samo',
      selectAll: 'Odabrati sve',
      deselectAll: 'Odznači sve',
    },
    maps: {
      mapSettings: {
        titleMapStyle: 'Prikaz karte',
        monochrome: 'Monochrom',
        satellite: 'Satelit',
        streets: 'Ulice',
      },
    },
    customUpload: {
      uploadFailed: 'Učitavanje nije uspjelo',
      uploading: 'Učitavanje...',
      uploadSuccessful: 'Učitavanje uspješno',
      validationErrors: {
        invalidFileExtension: 'Neispravna vrsta datoteke',
        invalidMaxFileSize: 'Datoteka je prevelika. Maksimalno dopušteno {{maxFileSize}}.',
      },
      uploadFile: 'Učitaj datoteku',
    },
    inputs: {
      switch: {
        active: 'Aktivan',
        inactive: 'Inaktivan',
        on: 'Aktiviraj',
        off: 'Deaktiviraj',
      },
    },
    category: {
      category: 'Kategorija',
      index: 'Index',
      addNewCategory: 'Dodaj novu kategoriju',
      validation: 'Dodajte barem jednu kategoriju',
    },
    changeUser: {
      popover: {
        title: 'Promjena korisnika',
        description: 'Odaberite korisnika na kojeg želite prebaciti.',
        placeholder: 'Traži korisnika...',
      },
      confirm: {
        title: 'Promjena korisnika',
        description: 'Želite li stvarno primijeniti na korisnika {{name}} ?',
      },
      overlay: {
        title: 'Promjena',
        description: 'Stranica se učitava s novim korisnikom.',
      },
      button: {
        backToPreviousUser: 'Povratak',
        switchUser: 'Promjeni korisnika',
      },
      quickSelect: {
        title: 'brz odabir',
      },
    },
    phoneInput: {
      invalid: 'Unesite važeći broj telefona.',
      hint: 'Unesite u međunarodnom formatu s vodećim +',
    },
    maskedTextInput: {
      maskValidationMessage: 'Unos nije u formatu {{maska}} - pogledajte informacije',
      info: {
        title: 'Unošenje maski',
        mask_0: '<bold>0</bold> - Zahtijeva znamenku (0-9).',
        mask_9: '<bold>9</bold> - Zahtijeva znamenku (0-9) ili razmak.',
        'mask_#': '<bold>#</bold> - Zahtijeva znamenku (0-9), razmak, znak plus (+) ili minus (-).',
        mask_L: '<bold>L</bold> - potrebno je slovo (a-Z).',
        'mask_?': '<bold>?</bold> - zahtijeva slovo (a-Z) ili razmak.',
        mask_A: '<bold>A</bold> - Potreban je alfanumerički znak (0-9, a-Z).',
        mask_a: '<bold>a</bold> - Zahtijeva alfanumerički znak (0-9, a-Z) ili razmak.',
        'mask_&': '<bold>&</bold> - Zahtijeva jedan znak (bez razmaka).',
        mask_C: '<bold>C</bold> - Zahtijeva znak ili razmak.',
        escape:
          'Također možete navesti određene znakove u svojoj maski. Ako je znak na vrhu popisa, možete ga preskočiti pomoću (<bold>\\</bold>). Primjer: Za sljedeći format: "AX-123-456" maska izgleda ovako: <bold>\\</bold>AX-000-000"',
      },
    },
    downloads: {
      title: 'Preuzimanja',
      filesInGeneration_one: '{{count}} Datoteka je u pripremi.',
      filesInGeneration_other: 'U pripremi je {{count}} datoteka.',
    },
    remainingCharacterIndicator: {
      info: 'Preostalo je {{remaining}} od {{max}} znakova',
    },
    uploadDropzone: {
      title: 'Prijenos datoteke: {{uploaded}}/{{ukupno}}',
      errors: {
        fileTooLarge: 'Datoteka je prevelika. Maksimalno dopušteno {{maxFileSize}}.',
        fileInvalidType: 'Nevažeći format datoteke. Dopušteno je sljedeće: {{allowedFileTypes}}.',
      },
    },
  },
  dialogs: {
    formNavigationBlocker: {
      title: 'Napusti stranicu?',
      note: 'Želite li spremiti ili odbaciti promjene?',
    },
    discard: {
      title: 'Nespremljene promjene',
      description: 'Želite li spremiti ili odbaciti promjene?',
    },
    warning: {
      title: 'Upozorenje',
      description: 'Želite li nastaviti s akcijom?',
    },
    export: {
      labels: {
        xlsx: 'XLSX izvoz',
      },
      success: 'Uspješan izvoz.',
      error: 'Greška kod izvoza.',
    },
  },
  pages: {
    not_found: {
      error: '404 greška',
      title: 'Stranica nije pronađena',
      note: 'Stranica koju ste tražili nije pronađena.',
      back_to_home: 'Na početnu stranicu',
      back: 'Povratak',
      refresh: 'Ažurirati',
    },
    loading: {
      title: 'Sadržaj se učitava ...',
      note: 'Učitavanje podataka aplikacije.\nBudite strpljivi.',
    },
  },
  errors: {
    generic: 'Nešto je pošlo po zlu',
    transcribe: 'Greška u transkripciji',
  },
  pdf: {
    stamps: {
      approved: {
        title: 'Odobreno',
        subtitle: 'od {{name}}',
      },
    },
    documentLoading: 'Dokument se učitava...',
    documentError: 'Pogreška pri učitavanju dokumenta',
  },
  notificationChannels: {
    internal: 'Interni',
    email: 'E-Mail',
    sms: 'SMS',
    push: 'Poruka',
    emailTemplate: {
      label: 'Predložak e-pošte',
      defaultItem: 'zadano',
    },
  },
  notificationGroups: {
    clients: {
      title: 'Obavijesti korisnika',
      description:
        'Odaberite kako biste odredili kako pohranjeni korisnici trebaju biti obaviješteni o radnjama.',
    },
    tasks: {
      title: 'Obavijesti o zahtjevu',
      description:
        'Odaberite kako biste odredili kako pohranjeni korisnici trebaju biti obaviješteni o radnjama.',
    },
  },
  transcribing: {
    message: 'Počelo je audio snimanje...',
  },
  client_types: {
    policy_holder: 'Imatelj police',
  },
  client_customer_state: {
    active: 'Aktivan',
    offer: 'Ponuda',
    cancellation: 'otkazivanje',
  },
  logoutTimer: {
    message: 'Bit ćete automatski odjavljeni za {{time}} sekundi.',
  },
};
