import { NavigationBar, PageSidebar, PageSidebarTitle } from '@module/common';
import { useTranslation } from 'react-i18next';

import { useSettingsNavigationData } from './useSettingsNavigationData';

export const SettingsNavigation = () => {
  const { t } = useTranslation();

  const navigationData = useSettingsNavigationData();
  return (
    <PageSidebar
      header={<PageSidebarTitle>{t('common.navigation.sections.settings')}</PageSidebarTitle>}
    >
      <NavigationBar data={navigationData} />
    </PageSidebar>
  );
};
