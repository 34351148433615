import Icon404 from '@image/empty-states/Media.svg';
import { Loader } from '@module/common/components/Loader';
import { Nl2Br } from '@module/shared/components';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { PublicLayout } from '../public-layout/PublicLayout';

export interface LoadingPageProps {
  title?: string | ReactNode;
  note?: string | ReactNode;
}

export const LoadingPage = (props: LoadingPageProps) => {
  const { t } = useTranslation();

  return (
    <PublicLayout>
      <div className="k-display-flex k-flex-grow k-flex-column k-justify-content-center k-align-items-center">
        <div className="k-display-flex k-flex-column k-justify-content-center">
          <div>
            <img src={Icon404} width={240} />
          </div>
        </div>
        <div>
          <Loader />
        </div>
        <h1>{props.title ? props.title : t('common.pages.loading.title')}</h1>
        <p className="k-text-center">
          {props.note ? props.note : <Nl2Br text={t('common.pages.loading.note')} />}
        </p>
      </div>
    </PublicLayout>
  );
};
