import './MobileAppDialog.scss';

import AppStoreBadgeDE from '@image/app-store-badge-de.svg';
import AppStoreBadgeEN from '@image/app-store-badge-en.svg';
import {
  BaseButton,
  CloseButton,
  Dialog,
  DialogContent,
  DialogHeader,
  DialogHeaderBar,
  DialogHeaderIcon,
} from '@module/layout';
import { DialogProps, useDialogs } from '@module/shared/dialogs';
import { useLocale } from '@module/shared/localization';
import { NotificationType, useNotifications } from '@module/shared/notifications';
import { QRCode } from '@progress/kendo-react-barcodes';
import { ButtonGroup } from '@progress/kendo-react-buttons';
import { Typography } from '@progress/kendo-react-common';
import { DialogActionsBar } from '@progress/kendo-react-dialogs';
import { useTranslation } from 'react-i18next';

interface MobileAppDialogProps extends DialogProps {
  serviceURL: string;
}

const getLocalizedBadges = (locale: string) => {
  switch (locale) {
    case 'en-US':
      return {
        appStore: AppStoreBadgeEN,
        appStoreLink: 'https://apps.apple.com/de/app/inspcto-pro/id6461768730',
      };
    default:
      return {
        appStore: AppStoreBadgeDE,
        appStoreLink: 'https://apps.apple.com/de/app/inspcto-pro/id6461768730',
      };
  }
};

export const MobileAppDialog = ({ dialogId, serviceURL }: MobileAppDialogProps) => {
  const { t } = useTranslation();
  const { hideDialog } = useDialogs();
  const { showNotification } = useNotifications();
  const currentLocale = useLocale();
  const localizedBadges = getLocalizedBadges(currentLocale.locale.localeId);

  const handleClose = () => {
    hideDialog(dialogId);
  };

  const copyLink = () => {
    if ('clipboard' in navigator) {
      navigator.clipboard.writeText(serviceURL);
      showNotification(t('auth.dialogs.useMobileApp.copySuccess'), NotificationType.Success);
    }
  };

  return (
    <Dialog width="small" onClose={handleClose}>
      <DialogHeaderBar>
        <DialogHeaderIcon iconClass="l-i-smartphone u-text-2xl" color="success" />
        <DialogHeader title={t('auth.dialogs.useMobileApp.title')} />
      </DialogHeaderBar>
      <DialogContent className="k-display-flex k-flex-column">
        <Typography.p className="!k-m-0">
          {t('auth.dialogs.useMobileApp.appDescription')}
        </Typography.p>
        <div className="app-store-badges">
          <a href={localizedBadges.appStoreLink} target="_blank" rel="noreferrer">
            <img className="app-store-badges__apple" src={localizedBadges.appStore} />
          </a>
        </div>
        <Typography.p className="!k-font-weight-bold">
          {t('auth.dialogs.useMobileApp.connectServiceTitle')}
        </Typography.p>
        <Typography.p className="!k-m-0">
          {t('auth.dialogs.useMobileApp.connectServiceDescription')}
        </Typography.p>
        {serviceURL && (
          <div className="u-width-full k-display-flex k-justify-content-center k-my-3">
            <QRCode value={serviceURL} errorCorrection="M" />
          </div>
        )}
        <Typography.p>{t('auth.dialogs.useMobileApp.alternativeServiceURL')}</Typography.p>

        <ButtonGroup className="u-width-full">
          <span className="service-url-link k-px-3">{serviceURL}</span>
          <BaseButton onClick={copyLink} iconClass="l-i-copy" label={t('common.labels.copy')} />
        </ButtonGroup>
      </DialogContent>
      <DialogActionsBar>
        <CloseButton onClick={handleClose} />
      </DialogActionsBar>
    </Dialog>
  );
};
