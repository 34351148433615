import { classNames } from '@progress/kendo-react-common';
import { ReactNode } from 'react';

export interface FormButtonsProps {
  children?: ReactNode | undefined;
  align?: 'left' | 'right' | 'center' | 'fill';
}

export const FormButtons = (props: FormButtonsProps) => {
  const { align = 'right' } = props;
  return (
    <div
      className={classNames('k-form-buttons', 'k-display-flex', 'k-gap-2', {
        'k-justify-content-start': align === 'left',
        'k-justify-content-center': align === 'center',
        'k-justify-content-end': align === 'right',
        'k-justify-content-space-between': align === 'fill',
      })}
    >
      {props.children}
    </div>
  );
};
