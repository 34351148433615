import { ComponentType, createContext, useContext } from 'react';

export interface DialogProps {
  dialogId: string;
}

export interface DialogItem {
  dialogId: string;
  // eslint-disable-next-line
  dialogProps: any;
  // eslint-disable-next-line
  dialogComponentType: ComponentType<any>;
}

interface DialogsContextType {
  dialogs: DialogItem[];
  showDialog: <T>(
    dialogProps: T,
    dialogComponentType: ComponentType<T & DialogProps>,
    dialogId?: string,
  ) => DialogItem | undefined;
  hideDialog: (dialogId: string) => void;
  hideAllDialogs: () => void;
}

export const DialogsContext = createContext<DialogsContextType>({} as DialogsContextType);

export const useDialogs = (): DialogsContextType => {
  return useContext(DialogsContext);
};
