import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CurrenciesQueryVariables = Types.Exact<{ [key: string]: never }>;

export type CurrenciesQuery = {
  __typename?: 'Query';
  currencies?: Array<{ __typename?: 'Currency'; id: string; title: string }> | null;
};

export const CurrenciesDocument = gql`
  query Currencies {
    currencies {
      id
      title
    }
  }
`;

export function useCurrenciesQuery(
  options?: Omit<Urql.UseQueryArgs<CurrenciesQueryVariables>, 'query'>,
) {
  return Urql.useQuery<CurrenciesQuery, CurrenciesQueryVariables>({
    query: CurrenciesDocument,
    ...options,
  });
}
