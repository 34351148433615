import { Notification, NotificationGroup } from '@progress/kendo-react-notification';

import { useNotifications } from './NotificationsContext';

export const NotificationsContainer = () => {
  const { notifications, hideNotification } = useNotifications();

  return (
    <NotificationGroup>
      {notifications.map((notification) => {
        return (
          <Notification
            key={notification.notificationId}
            type={{ style: notification.type, icon: notification.type !== 'none' }}
            closable={true}
            onClose={() => hideNotification(notification.notificationId)}
          >
            {typeof notification.text === 'string' ? (
              <p className="k-font-weight-semibold">{notification.text}</p>
            ) : (
              notification.text
            )}
          </Notification>
        );
      })}
    </NotificationGroup>
  );
};
