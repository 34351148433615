import { AppAllowlistError } from '@generated/api.generated';
import { isGraphQLError } from '@module/common';
import { useIpAllowlistGuard } from '@module/common/components/IpAllowlistGuard';
import { useMemo } from 'react';
import { mapExchange } from 'urql';

// if an error occurs with the specific error, that request was blocked because IP Address is not allowlisted
// then we set the guard active. This will result in rendering a "no permission" page.
export function useIpAllowlistExchange() {
  const [isGuardActive, setIsGuardActive] = useIpAllowlistGuard();
  return useMemo(
    () =>
      mapExchange({
        onError: (error) => {
          if (isGraphQLError(error, AppAllowlistError.ACCESS_DENIED) && !isGuardActive) {
            setIsGuardActive(true);
          }
        },
      }),
    [setIsGuardActive, isGuardActive],
  );
}
