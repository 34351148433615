import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import { DownloadFragmentDoc } from './downloadFragment.generated';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type DownloadGeneratedSubscriptionVariables = Types.Exact<{ [key: string]: never }>;

export type DownloadGeneratedSubscription = {
  __typename?: 'Subscription';
  downloadGenerated?: {
    __typename?: 'Download';
    id: string;
    state_id: Types.DownloadStateId;
    filename: string;
    file_ids: Array<string>;
    url_download: string;
  } | null;
};

export const DownloadGeneratedDocument = gql`
  subscription DownloadGenerated {
    downloadGenerated {
      ...Download
    }
  }
  ${DownloadFragmentDoc}
`;

export function useDownloadGeneratedSubscription<TData = DownloadGeneratedSubscription>(
  options?: Omit<Urql.UseSubscriptionArgs<DownloadGeneratedSubscriptionVariables>, 'query'>,
  handler?: Urql.SubscriptionHandler<DownloadGeneratedSubscription, TData>,
) {
  return Urql.useSubscription<
    DownloadGeneratedSubscription,
    TData,
    DownloadGeneratedSubscriptionVariables
  >({ query: DownloadGeneratedDocument, ...options }, handler);
}
