import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type AppSettingsPublicQueryVariables = Types.Exact<{ [key: string]: never }>;

export type AppSettingsPublicQuery = {
  __typename?: 'Query';
  appSettingsPublic: {
    __typename?: 'AppSettingsPublic';
    app_title: string;
    login_welcome_text?: string | null;
    conditions?: string | null;
    imprint?: string | null;
    privacy?: string | null;
    theme: Types.Theme;
    url_login_logo: string;
    auth_password_min_length: number;
    auth_password_min_special: number;
    auth_password_min_upper_case: number;
    auth_password_min_numbers: number;
    login_logo?: { __typename?: 'FileItem'; file_name: string; url: string; id: string } | null;
    login_background?: {
      __typename?: 'FileItem';
      file_name: string;
      url: string;
      id: string;
    } | null;
    menu_icon?: { __typename?: 'FileItem'; file_name: string; url: string; id: string } | null;
  };
};

export const AppSettingsPublicDocument = gql`
  query AppSettingsPublic {
    appSettingsPublic {
      app_title
      login_logo {
        file_name
        url
        id
      }
      login_background {
        file_name
        url
        id
      }
      login_welcome_text
      conditions
      imprint
      privacy
      menu_icon {
        file_name
        url
        id
      }
      theme
      url_login_logo
      auth_password_min_length
      auth_password_min_special
      auth_password_min_upper_case
      auth_password_min_numbers
    }
  }
`;

export function useAppSettingsPublicQuery(
  options?: Omit<Urql.UseQueryArgs<AppSettingsPublicQueryVariables>, 'query'>,
) {
  return Urql.useQuery<AppSettingsPublicQuery, AppSettingsPublicQueryVariables>({
    query: AppSettingsPublicDocument,
    ...options,
  });
}
