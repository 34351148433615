export interface ColumnCalcInterface {
  width?: number;
  minWidth?: number;
  show?: boolean;
}

export const calcCombinedColWidth = (columns: ColumnCalcInterface[]) => {
  return columns
    .map((col) => (col.show ? col.width || col.minWidth || 0 : 0))
    .reduce((prev, current) => prev + current, 0);
};
