export default {
  errors: {
    token_expired: 'Veza za potvrdu je istekla.',
    already_verified: 'E-mail je već potvrđen.',
  },
  pages: {
    login: {
      title: 'Dobrodošli natrag',
      subtitle: 'Dobrodošli natrag, molimo unesite svoje podatke.',
      submit: 'Registar',
      forgotPassword: 'Zaboravili ste lozinku?',
      or: 'oder',
      useMobile: 'Koristite aplikaciju Mobile Inspcto',
    },
    initialLogin: {
      title: 'Dobrodošli',
      subtitle: 'Dobrodošli u INSPCTO, pozvani ste na naš portal.',
      submit: 'Nastaviti',
      note: 'Odaberite svoju početnu lozinku za dovršetak registracije.',
    },
    forgotPassword: {
      title: 'Zaboravili ste lozinku',
      submit: 'Poslati poveznicu',
      note:
        'Za poništavanje lozinke unesite adresu e-pošte koju ste koristili.\n' +
        'Tada ćemo vam poslati poveznicu na vašu adresu e-pošte za postavljanje nove lozinke.',
      toLogin: 'Natrag na prijavu',
    },
    resetPassword: {
      title: 'Resetiranje lozinke',
      submit: 'Resetiranje lozinke',
      note: 'Unesite svoju novu lozinku.',
    },
    verifyEmail: {
      title: 'Potvrdi E-Mail',
      submit: 'Potvrdi E-Mail',
      note: 'Potvrdite svoju e-poštu klikom na gumb.',
    },
    noPermission: {
      error: '403 greška',
      title: 'Nema autorizacije',
      note: 'Nemate dopuštenje za pregled ove sgtranice.',
      backToHome: 'Na početnu stranicu',
      back: 'Povratak',
    },
    maintenance: {
      error: 'održavanje',
      title: 'Ova stranica se privremeno održava.',
      note: 'Molimo vas da se vratite neki drugi put kako biste doživjeli sve nove promjene.',
    },
  },
  dialogs: {
    useMobileApp: {
      title: 'Inspcto Mobile App',
      appDescription:
        'Portal možete koristiti i izravno putem preglednika na telefonu. Osim toga, nudimo i aplikaciju u App Storeu za iOS uređaje i aplikaciju u Play Storeu za Android uređaje. Aplikaciju možete preuzeti ovdje:',
      connectServiceTitle: 'Upute za povezivanje aplikacije s portalom:',
      connectServiceDescription:
        'Nakon što ste instalirali aplikaciju na svoj uređaj i otvorili je, odaberite "Povezivanje na portal" pod "Postavke" - to će automatski otvoriti kameru. Koristite ju za skeniranje QR koda prikazanog u nastavku za povezivanje dva uređaja.',
      alternativeServiceURL:
        'Ako imate problema, možete i ručno unijeti poslužitelj. Da biste to učinili, odaberite: ',
      copySuccess: 'Veza je kopirana u međuspremnik.',
    },
  },
};
