import { DocumentNode, Kind } from 'graphql';

/**
 * Get operation name from graphql query.
 * https://github.com/urql-graphql/urql/issues/787
 */
export const getOperationName = (query: DocumentNode): string | undefined => {
  for (let i = 0, l = query.definitions.length; i < l; i++) {
    const node = query.definitions[i];
    if (node.kind === Kind.OPERATION_DEFINITION && node.name) {
      return node.name.value;
    }
  }
};
