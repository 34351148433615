export default {
  dialogs: {
    create: {
      labels: {
        type: 'Besichtigungstyp',
      },
    },
    meeting: {
      labels: {
        step: {
          first: 'Die Besichtigung wurde erfolgreich angelegt',
        },
      },
    },
  },
};
