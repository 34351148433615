import { useInternationalization } from '@progress/kendo-react-intl';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

export type UnitType = 'km' | 'hour' | 'sq_m' | 'sq_km' | 'mb';

export const useFormatUnit = (unitType: UnitType) => {
  const { t } = useTranslation();
  const intl = useInternationalization();

  return useCallback(
    (value: number) => {
      const formatted = intl.formatNumber(value, t(`units.formats.${unitType}`));
      const label = t(`units.labels.${unitType}`);

      return `${formatted} ${label}`;
    },
    [intl, t, unitType],
  );
};
