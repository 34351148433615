import { createContext, ReactNode, useContext } from 'react';

export enum NotificationType {
  None = 'none',
  Success = 'success',
  Info = 'info',
  Error = 'error',
  Warning = 'warning',
}

export interface NotificationItem {
  notificationId: string;
  text: string | ReactNode;
  type: NotificationType;
  duration?: number;
}

export interface NotificationsContextType {
  notifications: NotificationItem[];
  showNotification: (
    text: string | ReactNode,
    type: NotificationType,
    duration?: number,
    enableAutoHide?: boolean,
  ) => string;
  hideNotification: (notificationId: string) => void;
}

export const NotificationsContext = createContext<NotificationsContextType>(
  {} as NotificationsContextType,
);

export const useNotifications = (): NotificationsContextType => {
  return useContext(NotificationsContext);
};
