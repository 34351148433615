import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
export type CurrentUserAbilityFragment = {
  __typename?: 'User';
  role: Types.UserRole;
  globalPermissions: Array<Types.Permission>;
};

export const CurrentUserAbilityFragmentDoc = gql`
  fragment CurrentUserAbility on User {
    role
    globalPermissions
  }
`;
