import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type DeleteClassificationTagMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;

export type DeleteClassificationTagMutation = {
  __typename?: 'Mutation';
  deleteClassificationTag?: { __typename?: 'ClassificationTag'; id: string } | null;
};

export const DeleteClassificationTagDocument = gql`
  mutation DeleteClassificationTag($id: ID!) {
    deleteClassificationTag(id: $id) {
      id
    }
  }
`;

export function useDeleteClassificationTagMutation() {
  return Urql.useMutation<
    DeleteClassificationTagMutation,
    DeleteClassificationTagMutationVariables
  >(DeleteClassificationTagDocument);
}
