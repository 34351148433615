import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type UpdateNotificationSettingsMutationVariables = Types.Exact<{
  notification_settings:
    | Array<Types.AppNotificationSettingInput>
    | Types.AppNotificationSettingInput;
}>;

export type UpdateNotificationSettingsMutation = {
  __typename?: 'Mutation';
  updateNotificationSettings: {
    __typename?: 'AppSettings';
    notification_settings: Array<{
      __typename?: 'AppNotificationSetting';
      id: string;
      type_id: Types.NotificationTypeId;
      internal: boolean;
      email: boolean;
      sms: boolean;
      push: boolean;
      type: { __typename?: 'NotificationType'; id: string; title: string };
    }>;
  };
};

export const UpdateNotificationSettingsDocument = gql`
  mutation UpdateNotificationSettings($notification_settings: [AppNotificationSettingInput!]!) {
    updateNotificationSettings(input: { notification_settings: $notification_settings }) {
      notification_settings {
        id
        type_id
        type {
          id
          title
        }
        internal
        email
        sms
        push
      }
    }
  }
`;

export function useUpdateNotificationSettingsMutation() {
  return Urql.useMutation<
    UpdateNotificationSettingsMutation,
    UpdateNotificationSettingsMutationVariables
  >(UpdateNotificationSettingsDocument);
}
