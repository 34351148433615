import { useMediaQueries } from '@module/layout';
import { GridPagerSettings } from '@progress/kendo-react-grid';
import { useMemo } from 'react';

const defaultPageSizes = [10, 20, 50, 100];

export function useGridPagerSettings(customPageSizes?: number[]) {
  const { smUp } = useMediaQueries();
  const pageSizes = customPageSizes ?? defaultPageSizes;

  return useMemo<GridPagerSettings>(
    () => ({
      buttonCount: 5,
      info: true,
      type: 'numeric',
      pageSizes: smUp ? pageSizes : false,
      previousNext: true,
    }),
    [smUp, pageSizes],
  );
}
