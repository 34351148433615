import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type DeleteInterfaceMessageTemplateMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;

export type DeleteInterfaceMessageTemplateMutation = {
  __typename?: 'Mutation';
  deleteInterfaceMessageTemplate?: { __typename?: 'InterfaceMessageTemplate'; id: string } | null;
};

export const DeleteInterfaceMessageTemplateDocument = gql`
  mutation DeleteInterfaceMessageTemplate($id: ID!) {
    deleteInterfaceMessageTemplate(id: $id) {
      id
    }
  }
`;

export function useDeleteInterfaceMessageTemplateMutation() {
  return Urql.useMutation<
    DeleteInterfaceMessageTemplateMutation,
    DeleteInterfaceMessageTemplateMutationVariables
  >(DeleteInterfaceMessageTemplateDocument);
}
