import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import { TaskSubTaskFragmentDoc } from './taskSubTaskFragment.generated';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ReopenTaskSubTaskMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;

export type ReopenTaskSubTaskMutation = {
  __typename?: 'Mutation';
  reopenTaskSubTask: {
    __typename?: 'TaskSubTask';
    id: string;
    task_id: string;
    title: string;
    description?: string | null;
    due_date?: string | null;
    completed_date?: string | null;
    permissions: Array<Types.Permission>;
    state: { __typename?: 'TaskSubTaskState'; id: string; title: string; color: string };
    file?: {
      __typename?: 'FileItem';
      id: string;
      original_file_name: string;
      mime_type?: string | null;
      url: string;
    } | null;
  };
};

export const ReopenTaskSubTaskDocument = gql`
  mutation ReopenTaskSubTask($id: ID!) {
    reopenTaskSubTask(id: $id) {
      ...TaskSubTask
    }
  }
  ${TaskSubTaskFragmentDoc}
`;

export function useReopenTaskSubTaskMutation() {
  return Urql.useMutation<ReopenTaskSubTaskMutation, ReopenTaskSubTaskMutationVariables>(
    ReopenTaskSubTaskDocument,
  );
}
