import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { ObjectSchema } from 'yup';

import { ClassificationTagInput } from '../../../types/graphql.generated';

export const useClassificationTagInputSchema = () => {
  const { t } = useTranslation();
  return useMemo<ObjectSchema<ClassificationTagInput>>(
    () =>
      yup.object({
        title: yup.string().required(),
        color: yup
          .string()
          .trim()
          .matches(/^#?([a-f\d]{3,4}|[a-f\d]{6}|[a-f\d]{8})$/, t('validation.string.rgb'))
          .required(),
        alias: yup.string().required(),
        api_only: yup.boolean().required(),
      }),
    [t],
  );
};
