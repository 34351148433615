import { Annotation, AnnotationsWillChangeReason, Instance, List } from 'pspdfkit';
import { useEffect } from 'react';

/**
 * Unfortunately PsPdfKit does not export interface EventMap
 * Add missing events if needed
 *
 * Reference: "pspdfkit/dist/index.d.ts"
 */
interface EventMap {
  'annotations.change': () => void;
  'annotations.create': (annotations: List<Annotation>) => void;
  'annotations.delete': (annotations: List<Annotation>) => void;
  'annotations.didSave': () => void;
  'annotations.willSave': () => void;
  'annotations.update': (annotations: List<Annotation>) => void;
  'annotations.willChange': (event: {
    reason: AnnotationsWillChangeReason;
    annotations: List<Annotation>;
  }) => void;
}

/**
 * Used to register handlers with cleanup
 *
 * @param instance
 * @param eventName
 * @param handler
 */
export function usePsPdfKitEvent<TKey extends keyof EventMap, THandler extends EventMap[TKey]>(
  instance: Instance | null,
  eventName: TKey,
  handler: THandler | undefined,
) {
  useEffect(() => {
    if (instance && handler) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      instance.addEventListener(eventName, handler);
      return () => {
        if (instance && handler !== undefined) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          instance.removeEventListener(eventName, handler);
        }
      };
    }
  }, [eventName, handler, instance]);
}
