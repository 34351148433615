import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ReorderTaskImagesMutationVariables = Types.Exact<{
  taskId: Types.Scalars['ID'];
  order: Array<Types.Scalars['Int']> | Types.Scalars['Int'];
}>;

export type ReorderTaskImagesMutation = {
  __typename?: 'Mutation';
  reorderTaskImages: {
    __typename?: 'TaskImagesPaginator';
    data: Array<{ __typename?: 'FileItem'; id: string }>;
  };
};

export const ReorderTaskImagesDocument = gql`
  mutation ReorderTaskImages($taskId: ID!, $order: [Int!]!) {
    reorderTaskImages(task_id: $taskId, order: $order) {
      data {
        id
      }
    }
  }
`;

export function useReorderTaskImagesMutation() {
  return Urql.useMutation<ReorderTaskImagesMutation, ReorderTaskImagesMutationVariables>(
    ReorderTaskImagesDocument,
  );
}
