import { CanInterfaceMessageTemplate } from '@module/casl/abilities/InterfaceMessageTemplateAbility';
import { NoPermissionPage, NotFoundPage } from '@module/public';
import { Route, Routes } from 'react-router-dom';

import { InterfaceMessageTemplatesOverview } from './pages/InterfaceMessageTemplatesOverview';

const InterfaceMessageTemplatesRoutes = () => {
  return (
    <Routes>
      <Route
        index
        element={
          <CanInterfaceMessageTemplate
            action="updateInterfaceMessageTemplate"
            failure={<NoPermissionPage />}
          >
            <InterfaceMessageTemplatesOverview />
          </CanInterfaceMessageTemplate>
        }
      />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};

export default InterfaceMessageTemplatesRoutes;
