import acp from './acp';
import activityLog from './activityLog';
import addressBook from './addressBook';
import areaAssignments from './areaAssignments';
import articles from './articles';
import auth from './auth';
import clients from './clients';
import common from './common';
import customerForm from './customerForm';
import dashboard from './dashboard';
import demoData from './demoData';
import devices from './devices';
import emails from './emails';
import emailTemplates from './emailTemplates';
import events from './events';
import interfaceMessageTemplates from './interfaceMessageTemplates';
import invoices from './invoices';
import payouts from './payouts';
import pdfForms from './pdfForms';
import policyHolders from './policyHolders';
import reportLayouts from './reportLayouts';
import serviceCatalog from './serviceCatalog';
import settings from './settings';
import subTaskTemplates from './subTasksTemplates';
import task from './task';
import tasks from './tasks';
import templates from './templates';
import units from './units';
import users from './users';
import validation from './validation';
import video from './video';

export const localsEN = {
  acp,
  areaAssignments,
  auth,
  clients,
  policyHolders,
  common,
  devices,
  emails,
  emailTemplates,
  events,
  pdfForms,
  serviceCatalog,
  task,
  tasks,
  templates,
  units,
  users,
  validation,
  articles,
  video,
  dashboard,
  reportLayouts,
  settings,
  activityLog,
  invoices,
  subTaskTemplates,
  payouts,
  demoData,
  customerForm,
  addressBook,
  interfaceMessageTemplates,
};
