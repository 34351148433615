import { ROUTER_BASENAME } from '@env';
import { AppMode } from '@generated/graphql.generated';
import { useAuthContext, useAuthLogic } from '@module/auth';
import { useAdminAbility, useMe } from '@module/casl';
import { BaseButton, SectionHeader, useMediaQueries } from '@module/layout';
import { Popover } from '@module/shared/components';
import { IconInput } from '@module/shared/forms';
import { NotificationType, useNotifications } from '@module/shared/notifications';
import { CompositeFilterDescriptor, filterBy } from '@progress/kendo-data-query';
import { Button } from '@progress/kendo-react-buttons';
import { classNames } from '@progress/kendo-react-common';
import { Loader } from '@progress/kendo-react-indicators';
import { InputChangeEvent } from '@progress/kendo-react-inputs';
import { StackLayout } from '@progress/kendo-react-layout';
import { noop } from 'lodash';
import { KeyboardEvent, useCallback, useEffect, useId, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ClientLoginFragment, useClientsLoginQuery } from '../../graphql';
import { useAppSettings, useDetectClickOutside } from '../../hooks';
import { Badge } from '../badges';
import { useConfirmDialogs } from '../confirm-dialog/ConfirmDialog';
import { NoRecords } from '../lists';
import { Overlay } from '../overlay/Overlay';
import { QuickSelectClients, useChangeUserButtonContext } from './ChangeUserButtonContext';

const CHANGE_USER_REDIRECT_TIMEOUT = 2000;

const ENTER_KEY = 'Enter';
const SPACE_KEY = ' ';

interface ChangeUserItemProps {
  client: ClientLoginFragment;
  onClick?: (client: ClientLoginFragment) => void;
}

const ChangeUserItem = (props: ChangeUserItemProps) => {
  const { client, onClick } = props;

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === ENTER_KEY || e.key === SPACE_KEY) {
      e.preventDefault();
      onClick?.(client);
    }
  };

  return (
    <div
      tabIndex={0}
      role="button"
      onKeyDown={handleKeyDown}
      className={classNames(
        'k-display-flex k-flex-row k-flex-nowrap k-p-1 k-gap-2 k-align-items-center ',
        { 'k-cursor-pointer k-hover': Boolean(onClick) },
      )}
      onClick={() => onClick?.(client)}
    >
      <span className="l-i-log-in" aria-hidden />
      <div className="k-overflow-hidden">
        <div className="k-font-weight-semibold">
          {client.name} {client.job_title ? ` (${client.job_title})` : null}
        </div>
        <div className="u-text-sm" style={{ textOverflow: 'ellipsis' }}>
          {client.email}
        </div>
      </div>
      <div className="k-flex-grow k-display-flex k-flex-row k-justify-content-end">
        <Badge backgroundColor={client.client_type?.color} color="white">
          {client.client_type?.title}
        </Badge>
      </div>
    </div>
  );
};

const ChangeUserOverlay = () => {
  const { t } = useTranslation();

  useEffect(() => {
    const timeout = setTimeout(() => {
      window.location.pathname = `${ROUTER_BASENAME}dashboard`;
    }, CHANGE_USER_REDIRECT_TIMEOUT);
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  });

  return (
    <Overlay>
      <div
        className="k-popover"
        style={{
          position: 'fixed',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        <div className="k-popover-inner">
          <div className="k-popover-body">
            <SectionHeader
              title={t('common.components.changeUser.overlay.title')}
              description={t('common.components.changeUser.overlay.description')}
            />
            <StackLayout orientation="horizontal" align={{ horizontal: 'center' }}>
              <Loader />
            </StackLayout>
          </div>
        </div>
      </div>
    </Overlay>
  );
};

interface ChangeUserPopoverProps {
  buttonId: string;
  anchor?: HTMLElement | null;
  onHide: () => void;
  onChangeUser: () => void;
  quickSelectClients?: QuickSelectClients | null;
  open: boolean;
}

const ChangeUserPopover = (props: ChangeUserPopoverProps) => {
  const { anchor, quickSelectClients, buttonId, open } = props;

  const refPopover = useDetectClickOutside<HTMLDivElement>(props.onHide, undefined, [
    `#${CSS.escape(buttonId)}`,
  ]);
  const { t } = useTranslation();
  const { showNotification } = useNotifications();
  const confirm = useConfirmDialogs();
  const appSettings = useAppSettings();

  const [search, setSearch] = useState('');
  const [{ data, fetching }] = useClientsLoginQuery({ pause: !open });

  const me = useMe();
  const { loginAsOtherUser } = useAuthLogic();

  const clientsFiltered = useMemo(() => {
    const filterSearch: CompositeFilterDescriptor = {
      logic: 'or',
      filters: [
        {
          field: 'name',
          operator: 'contains',
          value: search,
        },
        {
          field: 'email',
          operator: 'contains',
          value: search,
        },
      ],
    };

    const filter: CompositeFilterDescriptor = {
      logic: 'and',
      filters: [
        {
          field: 'id',
          operator: 'ne',
          value: me.id,
        },
        filterSearch,
      ],
    };
    return filterBy(data?.clientsLogin?.data ?? [], filter);
  }, [me.id, data?.clientsLogin?.data, search]);

  const handleSearch = (e: InputChangeEvent) => {
    setSearch(e.value);
  };

  const handleLoginAsUser = (client: ClientLoginFragment) => {
    // hide popover
    props.onHide();

    if (!client.user) {
      return;
    }
    const user = client.user;

    // show confirm dialog
    confirm.warning({
      title: t('common.components.changeUser.confirm.title'),
      description: (
        <>
          <div className="k-mb-4">
            {t('common.components.changeUser.confirm.description', { name: client.name })}
          </div>
          <ChangeUserItem client={client} />
        </>
      ),
      iconClass: 'l-i-log-in',
      confirm: () => {
        loginAsOtherUser(user.id).then((response) => {
          if (!response.error) {
            showNotification(response.data?.loginAsOtherUser.message, NotificationType.Success);
            props.onChangeUser();
            return;
          }
          if (response.error) {
            showNotification(response.error.graphQLErrors[0].message, NotificationType.Error);
          }
        });
      },
    });
  };

  const numVisibleItems = 10;
  return (
    <Popover
      show={open}
      anchor={anchor}
      position="bottom"
      collision={{
        vertical: 'fit',
        horizontal: 'fit',
      }}
      positionMode="fixed"
      style={{ width: 400 }}
      callout={true}
      title={
        <SectionHeader
          title={t('common.components.changeUser.popover.title')}
          description={t('common.components.changeUser.popover.description')}
          content={
            <Button
              iconClass="l-i-x"
              onClick={props.onHide}
              className="k-align-self-start"
              aria-label={t('common.labels.close')}
            />
          }
        />
      }
    >
      <div ref={refPopover}>
        <div className="k-mb-4">
          <IconInput
            iconClass="l-i-search"
            className="k-input-lg k-flex-grow"
            placeholder={t('common.components.changeUser.popover.placeholder')}
            onChange={handleSearch}
          />
        </div>
        <div
          className="k-overflow-y-scroll"
          style={{ height: `calc((22px + 8px)*${numVisibleItems})` }}
        >
          {clientsFiltered.length > 0 &&
            clientsFiltered.map((client) => (
              <ChangeUserItem key={client.id} client={client} onClick={handleLoginAsUser} />
            ))}
          {!fetching && clientsFiltered.length === 0 && (
            <NoRecords title={t('common.components.changeUser.popover.empty')} />
          )}
          {fetching && (
            <div className="k-d-flex k-w-full k-align-items-center k-h-full k-justify-content-center">
              <Loader type="pulsing" size="medium" />
            </div>
          )}
        </div>
        {quickSelectClients && (
          <>
            <h3 className="k-my-2">{t('common.components.changeUser.quickSelect.title')}</h3>
            <div className="k-width-full k-display-flex k-flex-nowrap k-gap-2">
              {quickSelectClients.owner && (
                <Button
                  onClick={() =>
                    quickSelectClients.owner ? handleLoginAsUser(quickSelectClients.owner) : noop()
                  }
                  size="large"
                  className="k-text-secondary"
                  style={{
                    background: quickSelectClients.owner.client_type?.color,
                    color: '#fff',
                    flexGrow: 1,
                  }}
                >
                  {quickSelectClients.owner.client_type?.title}
                </Button>
              )}
              {appSettings.app_mode !== AppMode.RISK && quickSelectClients.leader && (
                <Button
                  onClick={() =>
                    quickSelectClients.leader
                      ? handleLoginAsUser(quickSelectClients.leader)
                      : noop()
                  }
                  size="large"
                  style={{
                    background: quickSelectClients.leader.client_type?.color,
                    color: '#fff',
                    flexGrow: 1,
                  }}
                >
                  {quickSelectClients.leader.client_type?.title}
                </Button>
              )}
            </div>
          </>
        )}
      </div>
    </Popover>
  );
};

export const ChangeUserButton = () => {
  const { t } = useTranslation();
  const state = useChangeUserButtonContext();
  const buttonId = useId();

  const [adminAbility, adminSubject] = useAdminAbility();
  const { mdUp } = useMediaQueries();

  const [isPopoverVisible, showPopover] = useState(false);
  const [isOverlayVisible, showOverlay] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const { authStatePrevious } = useAuthContext();
  const { restorePreviousUser } = useAuthLogic();

  const switchBackToSuperUser = useCallback(async () => {
    if (await restorePreviousUser()) {
      showOverlay(true);
    }
  }, [restorePreviousUser]);

  return (
    <div ref={ref}>
      {authStatePrevious && (
        <BaseButton
          className="k-mr-4"
          type="button"
          onClick={() => switchBackToSuperUser()}
          label={t('common.components.changeUser.button.backToPreviousUser')}
        />
      )}
      {adminAbility.can('canChangeUser', adminSubject()) && !authStatePrevious && (
        <>
          <BaseButton
            id={buttonId}
            className="k-mr-4"
            type="button"
            onClick={() => showPopover(!isPopoverVisible)}
            iconClass={mdUp ? undefined : 'l-i-user'}
            label={mdUp ? t('common.components.changeUser.button.switchUser') : ''}
          />
          <ChangeUserPopover
            anchor={ref.current}
            onHide={() => showPopover(false)}
            onChangeUser={() => showOverlay(true)}
            quickSelectClients={state?.quickSelectClients}
            buttonId={buttonId}
            open={isPopoverVisible}
          />
        </>
      )}
      {isOverlayVisible && <ChangeUserOverlay />}
    </div>
  );
};
