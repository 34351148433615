import { Badge, getContrastColor } from '@module/common';
import { classNames } from '@progress/kendo-react-common';

import { FileClassificationTagFragment } from '../graphql';

interface FileClassificationTagBadgeProps {
  className?: string;
  classificationTag: FileClassificationTagFragment;
}

export const FileClassificationTagBadge = (props: FileClassificationTagBadgeProps) => {
  const { className, classificationTag } = props;

  return (
    <Badge
      key={classificationTag.id}
      backgroundColor={classificationTag.color}
      color={getContrastColor(classificationTag.color)}
      className={classNames('k-text-nowrap', 'k-overflow-hidden', className)}
    >
      <div className="k-overflow-hidden" />
      {classificationTag.title}
    </Badge>
  );
};
