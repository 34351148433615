import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ClientEmailNotificationSettingFragment = {
  __typename?: 'CombinedNotificationSetting';
  email: boolean;
  email_template_id?: string | null;
  email_template?: {
    __typename?: 'EmailTemplate';
    title: string;
    description?: string | null;
  } | null;
  email_contacts: Array<{
    __typename?: 'ClientNotificationSettingEmailContact';
    address: string;
    name?: string | null;
    type: Types.EmailContactType;
  }>;
  type: {
    __typename?: 'NotificationType';
    title: string;
    group: string;
    type_id: Types.NotificationTypeId;
  };
};

export type ClientEmailNotificationSettingsQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;

export type ClientEmailNotificationSettingsQuery = {
  __typename?: 'Query';
  clientEmailNotificationSettings: {
    __typename?: 'CombinedNotificationSettingsList';
    id: string;
    data: Array<{
      __typename?: 'CombinedNotificationSetting';
      email: boolean;
      email_template_id?: string | null;
      email_template?: {
        __typename?: 'EmailTemplate';
        title: string;
        description?: string | null;
      } | null;
      email_contacts: Array<{
        __typename?: 'ClientNotificationSettingEmailContact';
        address: string;
        name?: string | null;
        type: Types.EmailContactType;
      }>;
      type: {
        __typename?: 'NotificationType';
        title: string;
        group: string;
        type_id: Types.NotificationTypeId;
      };
    }>;
  };
};

export const ClientEmailNotificationSettingFragmentDoc = gql`
  fragment ClientEmailNotificationSetting on CombinedNotificationSetting {
    email
    email_template_id
    email_template {
      title
      description
    }
    email_contacts {
      address
      name
      type
    }
    type {
      title
      group
      type_id
    }
  }
`;
export const ClientEmailNotificationSettingsDocument = gql`
  query ClientEmailNotificationSettings($id: ID!) {
    clientEmailNotificationSettings(id: $id) {
      id
      data {
        ...ClientEmailNotificationSetting
      }
    }
  }
  ${ClientEmailNotificationSettingFragmentDoc}
`;

export function useClientEmailNotificationSettingsQuery(
  options: Omit<Urql.UseQueryArgs<ClientEmailNotificationSettingsQueryVariables>, 'query'>,
) {
  return Urql.useQuery<
    ClientEmailNotificationSettingsQuery,
    ClientEmailNotificationSettingsQueryVariables
  >({ query: ClientEmailNotificationSettingsDocument, ...options });
}
