import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type UpdateMapBoundsSettingsMutationVariables = Types.Exact<{
  bounds: Types.BoundsInput;
}>;

export type UpdateMapBoundsSettingsMutation = {
  __typename?: 'Mutation';
  updateMapBoundsSettings: {
    __typename?: 'AppSettings';
    map_bounds?: {
      __typename?: 'Bounds';
      min: { __typename?: 'Point'; longitude: number; latitude: number };
      max: { __typename?: 'Point'; longitude: number; latitude: number };
    } | null;
  };
};

export const UpdateMapBoundsSettingsDocument = gql`
  mutation UpdateMapBoundsSettings($bounds: BoundsInput!) {
    updateMapBoundsSettings(input: { map_bounds: $bounds }) {
      map_bounds {
        min {
          longitude
          latitude
        }
        max {
          longitude
          latitude
        }
      }
    }
  }
`;

export function useUpdateMapBoundsSettingsMutation() {
  return Urql.useMutation<
    UpdateMapBoundsSettingsMutation,
    UpdateMapBoundsSettingsMutationVariables
  >(UpdateMapBoundsSettingsDocument);
}
