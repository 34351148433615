export default {
  openTask: 'Otvoreni zahtjev',
  editEvent: 'Uredi termin',
  drive: 'Drive',
  dialogs: {
    subscribeToCalendar: {
      title: 'Pretplata na kalendar',
      note: 'Ovdje se možete pretplatiti na kalendar na svom mobilnom uređaju.',
      iosGuide: {
        headline: 'Sinkronizacija kalendara: s iOS uređajima',
        description:
          'Možete sinkronizirati kalendar s priloženom aplikacijom za kalendar na vašem iPhone/iPad uređaju. Postavke za ovo možete napraviti automatski ili ručno.',
        stepOne: 'Otvorite aplikaciju Kalendar na svom iPhone/iPad uređaju.',
        stepTwo: 'Dodirnite "Ostali kalendari", zatim dodirnite "Pretplati se".',
        stepThree: 'Unesite sljedeću poveznicu:',
        clickSave: 'Kliknite na "Spremi". Kalendar će se sada automatski ažurirati.',
      },
      androidGuide: {
        headline: 'Sinkronizacija kalendara: s Android uređajima',
        description:
          'Možete sinkronizirati kalendar s priloženom aplikacijom kalendara na svom Android uređaju.',
        stepOne: 'Otvorite aplikaciju Kalendar na svom Android uređaju.',
        stepTwo: 'Dodirnite "Dodaj kalendar", zatim dodirnite "Pretplati se".',
        stepThree: 'Unesite sljedeću poveznicu:',
        clickSave: 'Kliknite na "Spremi". Kalendar će se sada automatski ažurirati.',
      },
      copySuccess: 'Uspješno kopirano',
    },
    schedulerForm: {
      create: {
        title: 'Stvori termin',
        note: 'Dodatne termine možete kreirati ovdje.',
      },
      update: {
        title: 'Uredi termin',
        note: 'Ovdje možete urediti trenutni termin',
      },
    },
    deleteEvent: {
      title: 'Ukloni termin',
      note: 'Želite li stvarno ukloniti sastanak?',
    },
  },
  notifications: {
    createSuccess: 'Sastanak je uspješno kreiran.',
    createError: 'Došlo je do pogreške prilikom kreiranja termina.',
    updateSuccess: 'Termin erfolgreich aktualisiert.',
    updateError: 'Sastanak je uspješno ažuriran.',
    deleteSuccess: 'Sastanak je uspješno uklonjen.',
  },
  pages: {
    calendar: {
      title: 'Kalender',
      subscribeCalendar: 'Pretplatite se na kalendar',
      ios: 'iOS',
      android: 'Android',
    },
  },
  deleteEvent: 'Ukloni termin',
};
