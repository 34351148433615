import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import { TaskDetailMapFragmentDoc } from './taskDetailMapFragment.generated';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type TaskDetailMapQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;

export type TaskDetailMapQuery = {
  __typename?: 'Query';
  task: {
    __typename?: 'Task';
    id: string;
    external_number?: string | null;
    identifier?: string | null;
    reserve?: number | null;
    permissions: Array<Types.Permission>;
    client?: {
      __typename?: 'Client';
      id: string;
      name: string;
      job_title?: string | null;
      assigned_email_templates?: Array<{
        __typename?: 'EmailTemplate';
        id: string;
        title: string;
        description?: string | null;
      }> | null;
      direct_message_email_templates?: Array<{
        __typename?: 'EmailTemplate';
        id: string;
        title: string;
        description?: string | null;
      }> | null;
      direct_message_interface_message_templates?: Array<{
        __typename?: 'InterfaceMessageTemplate';
        id: string;
        title: string;
        description?: string | null;
      }> | null;
      assigned_templates: Array<
        | { __typename: 'EmailTemplate'; id: string; title: string; description?: string | null }
        | {
            __typename: 'InterfaceMessageTemplate';
            id: string;
            title: string;
            description?: string | null;
          }
      >;
    } | null;
    client_contact?: {
      __typename?: 'Contact';
      id: string;
      full_name?: string | null;
      phone?: string | null;
      mobile?: string | null;
      email?: string | null;
      note?: string | null;
    } | null;
    customer?: {
      __typename?: 'Contact';
      id: string;
      full_name?: string | null;
      street?: string | null;
      street_no?: string | null;
      postcode?: string | null;
      city?: string | null;
      country_id?: string | null;
      phone?: string | null;
      phone2?: string | null;
      mobile?: string | null;
      mobile2?: string | null;
    } | null;
    primary_location?: {
      __typename?: 'TaskLocation';
      street?: string | null;
      street_no?: string | null;
      postcode?: string | null;
      city?: string | null;
      country_id?: string | null;
      latitude?: number | null;
      longitude?: number | null;
      country: { __typename?: 'Country'; title: string };
    } | null;
    owner?: { __typename?: 'Client'; id: string; name: string; job_title?: string | null } | null;
    state: { __typename?: 'TaskState'; id: string; color: string; title: string };
    leader?: { __typename?: 'Client'; id: string; name: string } | null;
    primary_form?: {
      __typename?: 'TaskForm';
      id: string;
      template: {
        __typename?: 'TaskTemplate';
        id: string;
        color: string;
        title: string;
        estimated_appointment_time_seconds: number;
      };
    } | null;
    appointment?: {
      __typename?: 'TaskEvent';
      id: string;
      date_from: string;
      date_to: string;
    } | null;
    qualifications: {
      __typename?: 'TaskQualificationList';
      data: Array<{ __typename?: 'Qualification'; title: string; id: string; color: string }>;
    };
    classification_tags: {
      __typename?: 'TaskClassificationTagList';
      id: string;
      data: Array<{ __typename?: 'ClassificationTag'; id: string; title: string; color: string }>;
    };
    visit_type?: {
      __typename?: 'TaskVisitType';
      id: string;
      title: string;
      need_location: boolean;
    } | null;
    claimant?: {
      __typename?: 'Contact';
      id: string;
      full_name?: string | null;
      mobile?: string | null;
    } | null;
    sub_owners: Array<{
      __typename?: 'TaskMember';
      id: string;
      type: Types.TaskMemberType;
      task_id: string;
      client_id: string;
      client?: {
        __typename?: 'Client';
        id: string;
        name: string;
        phone?: string | null;
        email?: string | null;
        notes?: string | null;
        job_title?: string | null;
        client_type?: {
          __typename?: 'ClientType';
          id: string;
          title: string;
          color: string;
        } | null;
      } | null;
    }>;
  };
};

export const TaskDetailMapDocument = gql`
  query TaskDetailMap($id: ID!) {
    task(id: $id) {
      ...TaskDetailMap
    }
  }
  ${TaskDetailMapFragmentDoc}
`;

export function useTaskDetailMapQuery(
  options: Omit<Urql.UseQueryArgs<TaskDetailMapQueryVariables>, 'query'>,
) {
  return Urql.useQuery<TaskDetailMapQuery, TaskDetailMapQueryVariables>({
    query: TaskDetailMapDocument,
    ...options,
  });
}
