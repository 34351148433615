import { useMe, useUserAbility } from '@module/casl';
import { useId } from '@module/common';
import {
  CloseButton,
  Dialog,
  DialogContent,
  DialogHeader,
  DialogHeaderBar,
  DialogHeaderIcon,
  SaveButton,
} from '@module/layout';
import { DialogProps, useDialogs } from '@module/shared/dialogs';
import { isNotNullish } from '@module/shared/helpers';
import { DialogActionsBar } from '@progress/kendo-react-dialogs';
import { TabStrip, TabStripTab } from '@progress/kendo-react-layout';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { BrowerSettingsSection } from '../components/BrowserSettingsSection';
import { ChangePasswordForm } from '../components/ChangePasswordForm';
import { CreateApiTokenTab } from '../components/CreateApiTokenTab';
import { NotificationSettingsForm } from '../components/NotificationSettingsForm';
import { ProfileImageContainer } from '../components/ProfileImageContainer';
import { SettingsSection } from '../components/SettingsSection';

enum TabType {
  PROFILE_SETTINGS = 'PROFILE_SETTINGS',
  PROFILE_IMAGE = 'PROFILE_IMAGE',
  NOTIFICATION_SETTINGS = 'NOTIFICATION_SETTINGS',
  BROWSER_SETTINGS = 'BROWSER_SETTINGS',
  PASSWORD = 'PASSWORD',
  CREATE_API_TOKEN = 'CREATE_API_TOKEN',
}

export const ProfileSettingsDialog = (props: DialogProps) => {
  const { t } = useTranslation();
  const { hideDialog } = useDialogs();

  const user = useMe();
  const [userAbility, userSubject] = useUserAbility();
  const handleClose = () => {
    hideDialog(props.dialogId);
  };

  const notificationSettingsFormId = useId();
  const passwordFormId = useId();

  const enabledTabs = useMemo(() => {
    return [
      {
        type: TabType.PROFILE_SETTINGS,
        title: t('settings.profileUserSettings.title'),
        content: <SettingsSection />,
      },
      {
        type: TabType.PROFILE_IMAGE,
        title: t('settings.profileImage.title'),
        content: <ProfileImageContainer />,
      },
      {
        type: TabType.BROWSER_SETTINGS,
        title: t('settings.browserSettings.title'),
        content: <BrowerSettingsSection />,
      },
      user.client
        ? {
            type: TabType.NOTIFICATION_SETTINGS,
            title: t('settings.clientNotifications.title'),
            content: (
              <NotificationSettingsForm client={user.client} formId={notificationSettingsFormId} />
            ),
            formId: notificationSettingsFormId,
          }
        : null,
      userAbility.can('updateUserPasswordSelf', userSubject({ user }))
        ? {
            type: TabType.PASSWORD,
            title: t('settings.changePasswordSelf.title'),
            content: <ChangePasswordForm formId={passwordFormId} />,
            formId: passwordFormId,
          }
        : null,
      userAbility.can('createApiToken', userSubject())
        ? {
            type: TabType.CREATE_API_TOKEN,
            title: t('settings.createApiToken.title'),
            content: <CreateApiTokenTab />,
          }
        : null,
    ].filter(isNotNullish);
  }, [user, userAbility, userSubject, t, passwordFormId, notificationSettingsFormId]);

  const [tabIndex, setTabIndex] = useState<number>(0);
  const selectedTab = enabledTabs[tabIndex];

  return (
    <Dialog width="medium" height="fullscreen" onClose={handleClose}>
      <DialogHeaderBar>
        <DialogHeaderIcon iconClass={'l-i-user-cog u-text-2xl'} color="success" />
        <DialogHeader
          title={t('settings.dialogs.profileSettings.title')}
          description={t('settings.dialogs.profileSettings.description')}
        />
      </DialogHeaderBar>
      <DialogContent>
        <TabStrip selected={tabIndex} animation={false} onSelect={(e) => setTabIndex(e.selected)}>
          {enabledTabs.map(({ type, title, content }) => (
            <TabStripTab key={type} title={title}>
              {content}
            </TabStripTab>
          ))}
        </TabStrip>
      </DialogContent>

      <DialogActionsBar layout="end">
        <CloseButton onClick={handleClose} />
        {selectedTab.formId && <SaveButton form={selectedTab.formId} type="submit" />}
      </DialogActionsBar>
    </Dialog>
  );
};
