import { isNullish } from '@module/shared/helpers';
import { Editor } from '@progress/kendo-react-editor';

export function findTemplateTagAtCaret(editor?: Editor) {
  if (isNullish(editor) || isNullish(editor.contentElement)) {
    return undefined;
  }

  // contentElement is inside iframe
  const root = editor.contentElement.getRootNode() as Document;

  // check if there is any selection, caret counts as empty selection
  const selection = root.getSelection();
  if (isNullish(selection) || selection.rangeCount < 1) {
    return undefined;
  }

  const range = selection.getRangeAt(0);

  // empty or no text
  if (isNullish(range.startContainer.textContent)) {
    return undefined;
  }

  // if start and end offset match we get the caret position
  if (range.startOffset !== range.endOffset) {
    return undefined;
  }

  // split text at caret position to find last template key before the caret
  const textBeforeCaret = range.startContainer.textContent.substring(0, range.startOffset);
  const textAfterCaret = range.startContainer.textContent.substring(
    range.startOffset,
    range.startContainer.textContent.length,
  );

  // no open tag before caret
  const indexBeforeOpen = textBeforeCaret.lastIndexOf('{{');
  const indexBeforeClose = textBeforeCaret.lastIndexOf('}}');
  if ((indexBeforeOpen < indexBeforeClose && indexBeforeClose !== -1) || indexBeforeOpen === -1) {
    return undefined;
  }

  // no closed tag after caret
  const indexAfterOpen = textAfterCaret.indexOf('{{');
  const indexAfterClose = textAfterCaret.indexOf('}}');
  if ((indexAfterOpen !== -1 && indexBeforeOpen < indexAfterClose) || indexAfterClose === -1) {
    return undefined;
  }

  const templateTagLeft = indexBeforeOpen !== -1 ? textBeforeCaret.substring(indexBeforeOpen) : '';
  const templateTagRight =
    indexAfterClose !== -1 ? textAfterCaret.substring(0, indexAfterClose + 2) : '';

  const templateTag = templateTagLeft + templateTagRight;

  return {
    templateTag,
    indexBeforeOpen,
    indexAfterClose: indexAfterClose + textBeforeCaret.length,
    range,
  };
}

export function insertTemplateTag(insertTag: string, editor: Editor) {
  // contentElement is inside iframe
  const root = editor.contentElement?.getRootNode() as Document | undefined;
  // check if there is any selection, caret counts as empty selection
  const selection = root?.getSelection();
  if (isNullish(selection) || selection.rangeCount < 1) {
    return undefined;
  }

  const range = selection.getRangeAt(0);

  // empty or no text
  if (isNullish(range.startContainer.textContent)) {
    return undefined;
  }

  // if start and end offset match we get the caret position
  if (range.startOffset !== range.endOffset) {
    return undefined;
  }

  const found = findTemplateTagAtCaret(editor);
  if (found) {
    const textBefore = range.startContainer.textContent.substring(0, found.indexBeforeOpen);
    const textAfter = range.startContainer.textContent.substring(found.indexAfterClose + 2);
    const text = textBefore + insertTag + textAfter;

    const start = textBefore.length;
    const end = textBefore.length + insertTag.length;

    range.startContainer.textContent = text;
    range.setStart(range.startContainer, start);
    range.setEnd(range.startContainer, end);

    return range;
  }

  if (range.startOffset === range.endOffset) {
    const textBefore = range.startContainer.textContent.substring(0, range.startOffset);
    const textAfter = range.startContainer.textContent.substring(range.startOffset);
    const text = textBefore + insertTag + textAfter;

    range.startContainer.textContent = text;
    range.setStart(range.startContainer, 0);
    range.setEnd(range.startContainer, 0);
  }
}
