import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
export type TaskRouteWaypointFragment = {
  __typename?: 'TaskRouteWaypoint';
  id: string;
  type_id: Types.TaskRouteTypeEnum;
  distance?: number | null;
  duration?: number | null;
  route?: any | null;
  client?: {
    __typename?: 'Client';
    primary_location?: {
      __typename?: 'ClientLocation';
      longitude?: number | null;
      latitude?: number | null;
      street_full?: string | null;
      postcode?: string | null;
      city?: string | null;
    } | null;
  } | null;
  task_event?: {
    __typename?: 'TaskEvent';
    date_from: string;
    date_to: string;
    task: {
      __typename?: 'Task';
      primary_location?: {
        __typename?: 'TaskLocation';
        longitude?: number | null;
        latitude?: number | null;
        street_full?: string | null;
        postcode?: string | null;
        city?: string | null;
      } | null;
    };
  } | null;
};

export type TaskRouteFragment = {
  __typename?: 'TaskRoute';
  id: string;
  date: string;
  distance?: number | null;
  duration?: number | null;
  route?: any | null;
  waypoints: Array<{
    __typename?: 'TaskRouteWaypoint';
    id: string;
    type_id: Types.TaskRouteTypeEnum;
    distance?: number | null;
    duration?: number | null;
    route?: any | null;
    client?: {
      __typename?: 'Client';
      primary_location?: {
        __typename?: 'ClientLocation';
        longitude?: number | null;
        latitude?: number | null;
        street_full?: string | null;
        postcode?: string | null;
        city?: string | null;
      } | null;
    } | null;
    task_event?: {
      __typename?: 'TaskEvent';
      date_from: string;
      date_to: string;
      task: {
        __typename?: 'Task';
        primary_location?: {
          __typename?: 'TaskLocation';
          longitude?: number | null;
          latitude?: number | null;
          street_full?: string | null;
          postcode?: string | null;
          city?: string | null;
        } | null;
      };
    } | null;
  }>;
};

export const TaskRouteWaypointFragmentDoc = gql`
  fragment TaskRouteWaypoint on TaskRouteWaypoint {
    id
    type_id
    distance
    duration
    route
    client {
      primary_location {
        longitude
        latitude
        street_full
        postcode
        city
      }
    }
    task_event {
      task {
        primary_location {
          longitude
          latitude
          street_full
          postcode
          city
        }
      }
      date_from
      date_to
    }
  }
`;
export const TaskRouteFragmentDoc = gql`
  fragment TaskRoute on TaskRoute {
    id
    date
    distance
    duration
    route
    waypoints {
      ...TaskRouteWaypoint
    }
  }
  ${TaskRouteWaypointFragmentDoc}
`;
