import './PageNotification.scss';

import { classNames } from '@progress/kendo-react-common';
import { Tooltip } from '@progress/kendo-react-tooltip';
import { useTranslation } from 'react-i18next';

import { PageNotificationType, ThemeColor } from '../../../../types/graphql.generated';

export interface PageNotificationItem {
  id: string;
  title: string;
  description: string;
  type: PageNotificationType;
}

interface PageNotificationProps {
  notification: PageNotificationItem;
  onRemove: (id: string) => void;
}

export const getNotificationTypeIcon = (notificationType: PageNotificationType | ThemeColor) => {
  switch (notificationType) {
    case PageNotificationType.ERROR:
      return 'l-i-alert-circle u-text-2xl';
    case PageNotificationType.INFO:
      return 'l-i-info u-text-2xl';
    case PageNotificationType.SUCCESS:
      return 'l-i-check-circle-2 u-text-2xl';
    case PageNotificationType.WARNING:
      return 'l-i-alert-triangle u-text-2xl';
    default:
      break;
  }
};

export const PageNotification = ({ notification, onRemove }: PageNotificationProps) => {
  const iconClass = getNotificationTypeIcon(notification.type);
  const { t } = useTranslation();

  const handleRemove = () => {
    if (notification.id) {
      onRemove(notification.id);
    }
  };

  return (
    <div
      className={classNames({
        'page-notification': true,
        'page-notification--error': notification.type === PageNotificationType.ERROR,
        'page-notification--info': notification.type === PageNotificationType.INFO,
        'page-notification--success': notification.type === PageNotificationType.SUCCESS,
        'page-notification--warning': notification.type === PageNotificationType.WARNING,
      })}
    >
      <span className={iconClass} />
      <div className="page-notification__content">
        <p className="page-notification__title">{notification.title}</p>
        <p className="page-notification__description">{notification.description}</p>
      </div>
      <div className="page-notification__close">
        <Tooltip anchorElement="target" position="left">
          <span
            title={t('common.labels.closeTemporarily')}
            onClick={handleRemove}
            className="l-i-x k-p-2"
          />
        </Tooltip>
      </div>
    </div>
  );
};
