import { classNames } from '@progress/kendo-react-common';

import { useMediaQueries } from '../../hooks/';
import { FeaturedIcon, FeaturedIconColor } from '../icons/FeaturedIcon';

interface DialogHeaderIconProps {
  align?: 'left' | 'center' | 'right';
  className?: string;
  iconClass: string;
  color?: FeaturedIconColor;
}

export const DialogHeaderIcon = (props: DialogHeaderIconProps) => {
  const { align = 'center', className, iconClass, color = 'base' } = props;

  const { smUp } = useMediaQueries();

  if (!smUp) {
    return null;
  }

  return (
    <div
      className={classNames('dialog-header-icon', 'k-d-flex k-flex-column', 'k-mb-4', className, {
        'k-align-items-start': align === 'left',
        'k-align-items-center': align === 'center',
        'k-align-items-end': align === 'right',
      })}
    >
      <FeaturedIcon iconClass={iconClass} color={color} />
    </div>
  );
};
