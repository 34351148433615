import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type UpdateClientQualificationDisabledFormsMutationVariables = Types.Exact<{
  client_id: Types.Scalars['ID'];
  input: Types.UpdateClientQualificationDisabledFormsInput;
}>;

export type UpdateClientQualificationDisabledFormsMutation = {
  __typename?: 'Mutation';
  updateClientQualificationDisabledForms?: {
    __typename?: 'Client';
    id: string;
    disabled_templates: Array<{
      __typename?: 'ClientQualificationFormDisabled';
      id: string;
      template_id: string;
    }>;
  } | null;
};

export const UpdateClientQualificationDisabledFormsDocument = gql`
  mutation UpdateClientQualificationDisabledForms(
    $client_id: ID!
    $input: UpdateClientQualificationDisabledFormsInput!
  ) {
    updateClientQualificationDisabledForms(client_id: $client_id, input: $input) {
      id
      disabled_templates {
        id
        template_id
      }
    }
  }
`;

export function useUpdateClientQualificationDisabledFormsMutation() {
  return Urql.useMutation<
    UpdateClientQualificationDisabledFormsMutation,
    UpdateClientQualificationDisabledFormsMutationVariables
  >(UpdateClientQualificationDisabledFormsDocument);
}
