import {
  Ability,
  AbilityBuilder,
  AbilityClass,
  ForcedSubject,
  subject as subjectHelper,
} from '@casl/ability';
import { useCallback, useMemo } from 'react';

import { Permission } from '../../../types/graphql.generated';
import { GenericCan, GenericCanSharedProps } from '../components/GenericCan';
import { CurrentUserAbilityFragment, PolicyHolderAbilityFragment } from '../graphql';
import { useMeOrDefault } from '../hooks';

interface PolicyHolderSubject {
  me: CurrentUserAbilityFragment;
  policyHolder?: PolicyHolderAbilityFragment;
}

type PolicyHolderSubjectArg = Partial<PolicyHolderSubject>;

type Actions =
  | 'indexPolicyHolder'
  | 'viewPolicyHolders'
  | 'viewPolicyHolder'
  | 'createPolicyHolder'
  | 'updatePolicyHolder'
  | 'deletePolicyHolder'
  | 'importPolicyHolders';

type Subjects = PolicyHolderSubject | 'PolicyHolderSubject';

export type PolicyHolderAbility = Ability<[Actions, Subjects]>;
const policyHolderAbility = Ability as AbilityClass<PolicyHolderAbility>;

export const usePolicyHolderAbility = (): [
  PolicyHolderAbility,
  (sub?: PolicyHolderSubjectArg) => PolicyHolderSubject & ForcedSubject<'PolicyHolderSubject'>,
] => {
  const ability = useMemo(() => {
    const { can, build } = new AbilityBuilder(policyHolderAbility);

    can('indexPolicyHolder', 'PolicyHolderSubject', {
      'me.globalPermissions': { $in: [Permission.CLIENT_RISK_INDEX] },
    });

    can('viewPolicyHolders', 'PolicyHolderSubject', {
      'me.globalPermissions': { $in: [Permission.CLIENT_RISK_READ] },
    });
    can('viewPolicyHolder', 'PolicyHolderSubject', {
      'policyHolder.permissions': { $in: [Permission.CLIENT_READ_POLICY_HOLDER] },
    });
    can('updatePolicyHolder', 'PolicyHolderSubject', {
      'me.globalPermissions': { $in: [Permission.CLIENT_RISK_UPDATE] },
    });
    can('createPolicyHolder', 'PolicyHolderSubject', {
      'me.globalPermissions': { $in: [Permission.CLIENT_RISK_CREATE] },
    });
    can('deletePolicyHolder', 'PolicyHolderSubject', {
      'me.globalPermissions': { $in: [Permission.CLIENT_RISK_DELETE] },
    });
    can('importPolicyHolders', 'PolicyHolderSubject', {
      'me.globalPermissions': { $in: [Permission.CLIENT_RISK_IMPORT] },
    });

    return build();
  }, []);

  const me = useMeOrDefault();
  const subject = useCallback(
    (sub?: PolicyHolderSubjectArg) => {
      return subjectHelper('PolicyHolderSubject', { me, ...sub });
    },
    [me],
  );

  return [ability, subject];
};

interface CanPolicyHolderProps {
  policyHolder?: PolicyHolderAbilityFragment;
}

export const CanPolicyHolder = (props: CanPolicyHolderProps & GenericCanSharedProps<Actions>) => {
  const [policyHolderAbility, policyHolderSubject] = usePolicyHolderAbility();
  const { policyHolder, ...restProps } = props;

  return (
    <GenericCan<Actions, Subjects, PolicyHolderAbility>
      ability={policyHolderAbility}
      subject={policyHolderSubject({ policyHolder })}
      {...restProps}
    />
  );
};
