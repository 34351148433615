import { Button } from '@progress/kendo-react-buttons';
import { FieldRenderProps, FieldWrapper } from '@progress/kendo-react-form';
import { ColorPicker, ColorPickerChangeEvent } from '@progress/kendo-react-inputs';
import { colord } from 'colord';
import { t } from 'i18next';

import { useDialogs } from '../../dialogs';
import { Label } from '../components/Label';
import { SelectImageColorDialog } from '../dialogs/SelectImageColorDialog';
import { FieldError } from './FieldError';
import { FieldHint } from './FieldHint';

export const ImageColorPickerInput = (props: FieldRenderProps) => {
  const { value, id, valid, size = 'medium', onFocus, onChange, tooltip, tooltipPosition } = props;
  const { label, name } = props;
  const { showDialog } = useDialogs();

  const handleColorPick = (color: string) => {
    const hex = colord(color).toHex();
    onChange?.({ value: hex });
  };
  const onColorChange = (e: ColorPickerChangeEvent) => {
    const hex = colord(e.value).toHex();
    onChange?.({ ...e, value: hex });
  };

  return (
    <FieldWrapper>
      {label && (
        <Label
          tooltip={tooltip}
          tooltipPosition={tooltipPosition}
          label={label}
          name={name}
          editorId={id}
          editorValid={valid}
        >
          {label}
        </Label>
      )}

      <div className="k-display-flex k-gap-4">
        <ColorPicker
          id={id}
          value={value ? colord(value).toRgbString() : undefined}
          gradientSettings={{ opacity: false }}
          view="gradient"
          size={size}
          onChange={onColorChange}
          onFocus={onFocus}
        />
        <Button
          size="large"
          iconClass="l-i-pipette"
          onClick={() => showDialog({ handleChange: handleColorPick }, SelectImageColorDialog)}
          aria-label={t('templates.components.imageColorPicker.openDialog')}
        />
      </div>
      <div className="HintAndError">
        <FieldHint {...props} />
        <FieldError {...props} />
      </div>
    </FieldWrapper>
  );
};
