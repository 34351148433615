import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ReportTemplatesQueryVariables = Types.Exact<{ [key: string]: never }>;

export type ReportTemplatesQuery = {
  __typename?: 'Query';
  reportTemplates: Array<{
    __typename?: 'ReportTemplate';
    id: string;
    title: string;
    default: boolean;
  }>;
};

export const ReportTemplatesDocument = gql`
  query ReportTemplates {
    reportTemplates {
      id
      title
      default
    }
  }
`;

export function useReportTemplatesQuery(
  options?: Omit<Urql.UseQueryArgs<ReportTemplatesQueryVariables>, 'query'>,
) {
  return Urql.useQuery<ReportTemplatesQuery, ReportTemplatesQueryVariables>({
    query: ReportTemplatesDocument,
    ...options,
  });
}
