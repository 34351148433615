export default {
  openTask: 'Open task',
  editEvent: 'Edit event',
  drive: 'Drive',
  deleteEvent: 'Delete event',
  dialogs: {
    deleteEvent: {
      title: 'Delete event',
      note: 'Do you really want to delete the appointment?',
    },
    subscribeToCalendar: {
      title: 'Subscribe to Calendar',
      note: 'Here you can subscribe to the calendar on your mobile device',
      iosGuide: {
        headline: 'Calendar synchronisation: With iOS devices',
        description:
          'You can synchronise the calendar with the calendar app supplied with your iPhone/iPad. You can make the settings for this automatically or manually.',
        stepOne: 'Open the calendar app on your iPhone/iPad.',
        stepTwo: 'Tap on "Other calendars" and then on "Subscribe"',
        stepThree: 'Enter the following URL:',
        clickSave: 'Click on "Save". The calendar will now be updated automatically.',
      },
      androidGuide: {
        headline: 'Calendar synchronisation: with Android devices',
        description:
          'You can sync the calendar with the calendar app that comes with your Android device.',
        stepOne: 'Open the calendar app on your Android device',
        stepTwo: 'Tap on "Add calendar" and then on "Subscribe"',
        stepThree: 'Enter the following URL:',
        clickSave: 'Click on "Save". The calendar will now be updated automatically.',
      },
      copySuccess: 'Successfully copied',
    },
    schedulerForm: {
      create: {
        title: 'Create appointment',
        note: 'You can create more appointments here',
      },
      update: {
        title: 'Update appointment',
        note: 'You can edit the appointment here',
      },
    },
  },
  notifications: {
    createSuccess: 'Appointment successfully created',
    createError: 'There was an error creating the appointment',
    updateSuccess: 'Appointment successfully updated',
    updateError: 'There was an error updating the appointment',
    deleteSuccess: 'Appointment successfully deleted',
  },
  pages: {
    calendar: {
      title: 'Calendar',
      subscribeCalendar: 'Subscribe to calendar',
      ios: 'iOS',
      android: 'Android',
    },
  },
};
