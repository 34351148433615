import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type TaskRoomCategoriesQueryVariables = Types.Exact<{
  task_id: Types.Scalars['ID'];
}>;

export type TaskRoomCategoriesQuery = {
  __typename?: 'Query';
  taskRoomCategories: Array<{
    __typename?: 'TaskRoomCategory';
    id: string;
    title: string;
    uuid: string;
    task_id: string;
  }>;
};

export const TaskRoomCategoriesDocument = gql`
  query TaskRoomCategories($task_id: ID!) {
    taskRoomCategories(task_id: $task_id) {
      id
      title
      uuid
      task_id
    }
  }
`;

export function useTaskRoomCategoriesQuery(
  options: Omit<Urql.UseQueryArgs<TaskRoomCategoriesQueryVariables>, 'query'>,
) {
  return Urql.useQuery<TaskRoomCategoriesQuery, TaskRoomCategoriesQueryVariables>({
    query: TaskRoomCategoriesDocument,
    ...options,
  });
}
