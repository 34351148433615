import { useConfirmDialogs } from '@module/common';
import { NotificationType, useNotifications } from '@module/shared/notifications';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useDeleteFileClassificationTagMutation } from '../graphql';

export const useConfirmDeleteFileClassificationTag = () => {
  const { t } = useTranslation();
  const confirm = useConfirmDialogs();
  const { showNotification } = useNotifications();
  const [, deleteFileClassificationTag] = useDeleteFileClassificationTagMutation();

  return useCallback(
    (tagId: string) => {
      confirm.delete({
        title: t('settings.pages.fileClassificationTags.dialogs.delete.title'),
        description: t('settings.pages.fileClassificationTags.dialogs.delete.note'),
        delete: async () => {
          const response = await deleteFileClassificationTag({ id: tagId });
          if (!response.error) {
            showNotification(
              t('settings.pages.fileClassificationTags.notifications.delete.success'),
              NotificationType.Success,
            );
          }

          if (response.error) {
            showNotification(
              t('settings.pages.fileClassificationTags.notifications.delete.error'),
              NotificationType.Error,
            );
          }
        },
      });
    },
    [confirm, deleteFileClassificationTag, showNotification, t],
  );
};
