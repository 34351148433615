import { FormRenderProps } from '@progress/kendo-react-form';
import { useEffect } from 'react';

import { usePrevious } from '../../hooks';

interface FormFieldChangedProps<T> {
  formRenderProps: FormRenderProps;
  field: string;
  onChange: (event: { value?: T | null }) => void;
}

export const FormFieldChanged = <T,>(props: FormFieldChangedProps<T>) => {
  const { formRenderProps, field, onChange } = props;
  const value = formRenderProps.valueGetter(field);
  const previousValue = usePrevious(value);

  useEffect(() => {
    if (value !== previousValue) {
      onChange({ value });
    }
  }, [value, onChange, previousValue]);

  return null;
};
