import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import { TaskItemFieldsFragmentDoc } from './useTasksQuery.generated';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type TaskQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;

export type TaskQuery = {
  __typename?: 'Query';
  task: {
    __typename?: 'Task';
    id: string;
    identifier?: string | null;
    external_number?: string | null;
    policy_number?: string | null;
    urgent: boolean;
    task_visit_type_id?: string | null;
    damage_date?: string | null;
    reserve?: number | null;
    created_at: string;
    accepted_date?: string | null;
    follow_up_date?: string | null;
    reminder_date?: string | null;
    set_initial_contact_date?: string | null;
    set_on_site_appointment_date?: string | null;
    set_report_sent_date?: string | null;
    completion_date?: string | null;
    controlling_date?: string | null;
    rework_date?: string | null;
    invoice_date?: string | null;
    state_changed_date?: string | null;
    permissions: Array<Types.Permission>;
    open_subtasks_count: number;
    open_subtasks_color: Types.OpenSubTaskStateColor;
    claimant?: { __typename?: 'Contact'; id: string; name?: string | null } | null;
    customer?: {
      __typename?: 'Contact';
      id: string;
      first_name?: string | null;
      name?: string | null;
      full_name?: string | null;
      phone?: string | null;
      phone2?: string | null;
      mobile?: string | null;
      mobile2?: string | null;
      email?: string | null;
      email2?: string | null;
    } | null;
    primary_location?: {
      __typename?: 'TaskLocation';
      street?: string | null;
      street_no?: string | null;
      postcode?: string | null;
      city?: string | null;
      country_id?: string | null;
      latitude?: number | null;
      longitude?: number | null;
      country: { __typename?: 'Country'; title: string };
    } | null;
    primary_form?: {
      __typename?: 'TaskForm';
      id: string;
      template: {
        __typename?: 'TaskTemplate';
        id: string;
        color: string;
        title: string;
        shortcut?: string | null;
        estimated_appointment_time_seconds: number;
      };
    } | null;
    visit_type?: {
      __typename?: 'TaskVisitType';
      id: string;
      title: string;
      need_location: boolean;
    } | null;
    state: { __typename?: 'TaskState'; id: string; color: string; title: string };
    appointment?: {
      __typename?: 'TaskEvent';
      id: string;
      date_from: string;
      date_to: string;
      description?: string | null;
      event_type: { __typename?: 'TaskEventType'; id: string; color: string; title: string };
      calendar_event?: { __typename?: 'CalendarEvent'; id: string } | null;
    } | null;
    owner?: { __typename?: 'Client'; id: string; name: string; job_title?: string | null } | null;
    leader?: { __typename?: 'Client'; id: string; name: string; job_title?: string | null } | null;
    classification_tags: {
      __typename?: 'TaskClassificationTagList';
      id: string;
      data: Array<{
        __typename?: 'ClassificationTag';
        id: string;
        title: string;
        color: string;
        alias: string;
        api_only: boolean;
      }>;
    };
    client?: {
      __typename?: 'Client';
      id: string;
      name: string;
      job_title?: string | null;
      client_additional_policy_holder?: {
        __typename?: 'ClientAdditionalPolicyHolder';
        insurance_policy_number?: string | null;
        customer_number?: string | null;
      } | null;
      qualifications: {
        __typename?: 'ClientQualificationList';
        data: Array<{ __typename?: 'Qualification'; title: string; id: string; color: string }>;
      };
    } | null;
    sub_owners: Array<{
      __typename?: 'TaskMember';
      id: string;
      type: Types.TaskMemberType;
      task_id: string;
      client_id: string;
      client?: {
        __typename?: 'Client';
        id: string;
        name: string;
        phone?: string | null;
        email?: string | null;
        notes?: string | null;
        job_title?: string | null;
        client_type?: {
          __typename?: 'ClientType';
          id: string;
          title: string;
          color: string;
        } | null;
      } | null;
    }>;
    group?: { __typename?: 'TaskGroup'; id: string; title: string; color: string } | null;
    forms: Array<{
      __typename?: 'TaskForm';
      type_id: Types.TaskFormTypeId;
      tags?: Array<{
        __typename?: 'Tag';
        id: string;
        title: string;
        color: string;
        type_id: string;
        type: { __typename?: 'TagType'; id: string; title: string; color: string };
      }> | null;
    }>;
  };
};

export const TaskDocument = gql`
  query Task($id: ID!) {
    task(id: $id) {
      ...TaskItemFields
    }
  }
  ${TaskItemFieldsFragmentDoc}
`;

export function useTaskQuery(options: Omit<Urql.UseQueryArgs<TaskQueryVariables>, 'query'>) {
  return Urql.useQuery<TaskQuery, TaskQueryVariables>({ query: TaskDocument, ...options });
}
