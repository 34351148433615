export default {
  pages: {
    overview: {
      title: 'Stavka',
      description: 'Ovdje možete vidjeti popis unaprijed definiranih stavki za fakturiranje.',
      toolbar: {
        createArticle: 'Nova stavka',
        sync: 'Sinkronizacija s Billomatom',
      },
    },
  },
  labels: {
    articleNumber: 'Broj stavke',
    title: 'Naslov',
    description: 'Opis',
    price: 'Cijena',
    tax: 'Porez',
    unit: 'Jedinica',
    assignment: 'Osiguranja',
    autoPayout: {
      minSum: 'Vrijedi za iznos fakture od',
      maxSum: 'Vrijedi za iznos računa do',
      tooltip:
        'Kako bi se te vrijednosti mogle pročitati, pitanje sa zbrojem s identifikatorom "task_total_sum" mora biti definirano u dizajneru obrazaca. To omogućuje automatsko fakturiranje.',
    },
    assignments: 'osiguranja',
  },
  dialogs: {
    createArticle: {
      title: 'Kreiraj stavku',
      submit: 'Kreiraj stavku',
    },
    updateArticle: {
      title: 'Ažuriraj stavku',
      submit: 'Ažuriraj stavku',
    },
    exports: {
      title: 'Izvoz stavke',
      note: 'Preuzmite izvezene stavke.',
    },
  },
  notifications: {
    createArticle: {
      success: 'Stavka je kreirana.',
      error: 'Stavku nije bilo moguće kreirati.',
    },
    updateArticle: {
      success: 'Stavka ažurirana',
      error: 'Stavku nije bilo moguće ažurirati.',
    },
    sync: {
      success: 'Stavka je uspješno sinkronizirana',
      error: 'Stavka se ne može sinkronizirati.',
    },
  },
};
