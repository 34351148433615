import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type AcceptCustomerPortalEventFileMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;

export type AcceptCustomerPortalEventFileMutation = {
  __typename?: 'Mutation';
  acceptCustomerPortalEventFile: {
    __typename?: 'ResponseMessage';
    success?: boolean | null;
    message?: string | null;
  };
};

export const AcceptCustomerPortalEventFileDocument = gql`
  mutation AcceptCustomerPortalEventFile($id: ID!) {
    acceptCustomerPortalEventFile(id: $id) {
      success
      message
    }
  }
`;

export function useAcceptCustomerPortalEventFileMutation() {
  return Urql.useMutation<
    AcceptCustomerPortalEventFileMutation,
    AcceptCustomerPortalEventFileMutationVariables
  >(AcceptCustomerPortalEventFileDocument);
}
