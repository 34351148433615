import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
export type FileClassificationTagFragment = {
  __typename?: 'FileClassificationTag';
  id: string;
  title: string;
  color: string;
  alias: string;
  type_id: Types.FileClassificationTagTypeId;
};

export const FileClassificationTagFragmentDoc = gql`
  fragment FileClassificationTag on FileClassificationTag {
    id
    title
    color
    alias
    type_id
  }
`;
