import { useMemo } from 'react';

import { FileClassificationTagTypeId } from '../../../types/graphql.generated';
import { FileClassificationTagFragment, useFileClassificationTagsAllQuery } from '../graphql';

export const useFileClassificationTags = (withApiOnly = false) => {
  const [{ data }] = useFileClassificationTagsAllQuery();

  return useMemo<FileClassificationTagFragment[]>(
    () =>
      (data?.fileClassificationTags.data ?? []).filter(
        (fileClassificationTag) =>
          withApiOnly ||
          (!withApiOnly && fileClassificationTag.type_id !== FileClassificationTagTypeId.API),
      ),
    [data?.fileClassificationTags.data, withApiOnly],
  );
};
