import { classNames } from '@progress/kendo-react-common';
import { HTMLAttributes } from 'react';

export const Divider = ({ className, ...rest }: HTMLAttributes<HTMLHRElement>) => (
  <hr
    {...rest}
    className={classNames(
      'divider',
      'k-border-0 k-border-bottom k-border-solid k-border-light k-my-4',
      className,
    )}
  />
);
