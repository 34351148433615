import './LoginPage.scss';

import { useDialogs } from '@module/shared/dialogs';
import { useTranslation } from 'react-i18next';

import { HTMLDialog } from '../../dialogs';

const BottomLink = ({ header, html }: { header: string; html: string }) => {
  const { showDialog } = useDialogs();
  return <span onClick={() => showDialog({ header, html }, HTMLDialog)}>{header}</span>;
};

interface ImprintPrivacyTermsProps {
  imprint: string | undefined | null;
  privacy: string | undefined | null;
  conditions: string | undefined | null;
}

export const ImprintPrivacyTerms = (props: ImprintPrivacyTermsProps) => {
  const { imprint, privacy, conditions } = props;

  const { t } = useTranslation();

  return (
    <div className="conditions-privacy-imprint">
      {imprint && <BottomLink header={t('settings.loginPage.imprint')} html={imprint} />}
      {conditions && <BottomLink header={t('settings.loginPage.conditions')} html={conditions} />}
      {privacy && <BottomLink header={t('settings.loginPage.privacy')} html={privacy} />}
    </div>
  );
};
