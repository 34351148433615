import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CreateTaskMutationVariables = Types.Exact<{
  input: Types.CreateTaskInput;
}>;

export type CreateTaskMutation = {
  __typename?: 'Mutation';
  createTask: {
    __typename?: 'Task';
    id: string;
    client_id?: string | null;
    client_location_id?: string | null;
    client_contact_id?: string | null;
    folders: Array<{
      __typename?: 'TaskFolder';
      id: string;
      task_id: string;
      folder_type: Types.TaskFolderType;
    }>;
  };
};

export const CreateTaskDocument = gql`
  mutation CreateTask($input: CreateTaskInput!) {
    createTask(input: $input) {
      id
      client_id
      client_location_id
      client_contact_id
      folders {
        id
        task_id
        folder_type
      }
    }
  }
`;

export function useCreateTaskMutation() {
  return Urql.useMutation<CreateTaskMutation, CreateTaskMutationVariables>(CreateTaskDocument);
}
