import { useCallback, useEffect, useRef } from 'react';

export const useGetState = <T>(state: T) => {
  const stateRef = useRef(state);

  useEffect(() => {
    stateRef.current = state;
  }, [state]);

  return useCallback(() => stateRef.current, []);
};
