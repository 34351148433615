import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
export type UserItemFragment = {
  __typename?: 'User';
  id: string;
  name: string;
  client_id?: string | null;
  email: string;
  updated_at: string;
  last_login_at?: string | null;
  email_verified_at?: string | null;
  role: Types.UserRole;
};

export const UserItemFragmentDoc = gql`
  fragment UserItem on User {
    id
    name
    client_id
    email
    updated_at
    last_login_at
    email_verified_at
    role
  }
`;
