export default {
  events: {
    created: 'Kreiran',
    updated: 'Ažuriran',
    deleted: 'Izbrisan',
    login: 'Prijava',
  },
  pages: {
    activities: {
      title: 'Aktivnosti',
      description: 'Ovdje ćete pronaći pregled svih aktivnosti.',
      dialogs: {
        changes: {
          title: 'Izmjene',
          description: 'Napravljene su sljedeće izmjene:',
          oldValue: 'Stara vrijednost',
          newValue: 'Nova vrijednost',
        },
      },
      grid: {
        system: 'SISTEM',
        columns: {
          createdAt: 'Datum',
          email: 'Od',
          logName: 'Identifikator',
          event: 'Tip',
          description: 'Opis',
          changes: 'Detalji',
        },
      },
    },
  },
};
