import { DateFormat, useDateFormat } from '@module/common/hooks/useDateFormat';
import { Button } from '@progress/kendo-react-buttons';
import { classNames } from '@progress/kendo-react-common';
import {
  DateRangePicker,
  DateRangePickerChangeEvent,
  SelectionRange,
} from '@progress/kendo-react-dateinputs';
import { Input } from '@progress/kendo-react-inputs';
import { addDays, subDays } from 'date-fns';
import { useCallback, useEffect, useId, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { isString } from '../../helpers';
import { FilterProps } from '../types';

export type DateRangeFilterProps = FilterProps & {
  placeholder?: string;
};

function convertFilterValueToSelectionRange(value: unknown): SelectionRange {
  const dateRangeString = value as
    | { start: string | undefined; end: string | undefined }
    | undefined;

  // sub 1 day to correct addDays from convertSelectionRangeToFilterValue
  return {
    start: dateRangeString?.start ? new Date(dateRangeString.start) : null,
    end: dateRangeString?.end ? subDays(new Date(dateRangeString.end), 1) : null,
  };
}

function convertSelectionRangeToFilterValue(value: SelectionRange) {
  // add 1 day to filter including end date
  return {
    start: value.start?.toISOString(),
    end: value.end ? addDays(value.end, 1).toISOString() : undefined,
  };
}

const DummyInput = () => {
  return null;
};
export const DateRangeFilter = (props: DateRangeFilterProps) => {
  const [showPicker, setShowPicker] = useState(false);
  const id = useId();
  const { t } = useTranslation();
  const popupClass = `DatePickerPopup-${id}`;

  const value = useMemo(() => {
    return convertFilterValueToSelectionRange(props.filter.value);
  }, [props.filter.value]);

  const handleChange = useCallback(
    (e: DateRangePickerChangeEvent) => {
      const value = convertSelectionRangeToFilterValue(e.value);
      const nextFilter = { ...props.filter, value };

      props.onFilterChange({ nextFilter });
    },
    [props],
  );

  const handleReset = useCallback(() => {
    const value = { start: null, end: null };
    const nextFilter = { ...props.filter, value };
    props.onFilterChange({ nextFilter });
  }, [props]);

  const handleFocus = useCallback(() => {
    setShowPicker(true);
  }, []);

  const handleClickOutside = useCallback(
    (e: MouseEvent) => {
      if (showPicker) {
        const popup = e.composedPath().find((eventTarget) => {
          const elem = eventTarget as Element;
          return (
            elem &&
            elem.className !== undefined &&
            isString(elem.className) &&
            elem.className.includes(popupClass)
          );
        });

        if (popup === undefined) {
          setShowPicker(false);
          return;
        }
      }
    },
    [popupClass, showPicker],
  );

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside]);

  const ref = useRef<HTMLSpanElement>(null);
  const dateStart = useDateFormat(value?.start ?? undefined, DateFormat.Date);
  const dateEnd = useDateFormat(value?.end ?? undefined, DateFormat.Date);
  const dateFormatted = useMemo(
    () =>
      dateStart !== undefined && dateEnd !== undefined ? `${dateStart} - ${dateEnd}` : undefined,
    [dateEnd, dateStart],
  );

  return (
    <>
      <span ref={ref} className="k-d-block">
        {dateFormatted === undefined && (
          <span
            className={classNames(
              'k-input k-input-lg k-picker k-picker-lg k-rounded-md k-picker-solid',
            )}
            onClick={() => setShowPicker(!showPicker)}
          >
            <Input className="k-input-inner" placeholder={props.placeholder} disabled />
            <Button
              className="k-input-button"
              icon={classNames({ 'arrow-s': !showPicker, 'arrow-n': showPicker })}
              aria-label={
                showPicker
                  ? t('common.components.filter.closeFilter')
                  : t('common.components.filter.openFilter')
              }
            />
          </span>
        )}
        {dateFormatted && (
          <span className="k-input k-input-solid k-rounded-md k-input-lg u-width-10-rem">
            <Input
              className="k-input-inner k-text-center"
              readOnly={true}
              onClick={handleFocus}
              onFocus={handleFocus}
              value={dateFormatted}
            />
            <Button
              className="k-input-button k-button-lg k-icon-button"
              iconClass="l-i-x"
              onClick={handleReset}
              aria-label={t('common.labels.clear')}
            />
          </span>
        )}
        {ref && ref.current && showPicker && (
          <DateRangePicker
            show={showPicker}
            adaptive={true}
            popupSettings={{
              anchor: ref.current,
              popupClass,
              anchorAlign: { vertical: 'bottom', horizontal: 'left' },
              popupAlign: { vertical: 'top', horizontal: 'left' },
              margin: { vertical: 13, horizontal: 0 },
            }}
            startDateInput={DummyInput}
            endDateInput={DummyInput}
            value={value}
            onChange={handleChange}
          />
        )}
      </span>
    </>
  );
};
