import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type UpsertTaskFormQuestionTableRowsMutationVariables = Types.Exact<{
  task_form_id: Types.Scalars['ID'];
  table_rows: Array<Types.TaskFormQuestionTableRowInput> | Types.TaskFormQuestionTableRowInput;
}>;

export type UpsertTaskFormQuestionTableRowsMutation = {
  __typename?: 'Mutation';
  upsertTaskFormQuestionTableRows: Array<{
    __typename?: 'TaskQuestionTableRow';
    question_id: string;
  }>;
};

export const UpsertTaskFormQuestionTableRowsDocument = gql`
  mutation UpsertTaskFormQuestionTableRows(
    $task_form_id: ID!
    $table_rows: [TaskFormQuestionTableRowInput!]!
  ) {
    upsertTaskFormQuestionTableRows(task_form_id: $task_form_id, table_rows: $table_rows) {
      question_id
    }
  }
`;

export function useUpsertTaskFormQuestionTableRowsMutation() {
  return Urql.useMutation<
    UpsertTaskFormQuestionTableRowsMutation,
    UpsertTaskFormQuestionTableRowsMutationVariables
  >(UpsertTaskFormQuestionTableRowsDocument);
}
