import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type UpsertClientAdditionalLossAdjusterMutationVariables = Types.Exact<{
  client_id: Types.Scalars['ID'];
  input: Types.ClientAdditionalLossAdjusterInput;
}>;

export type UpsertClientAdditionalLossAdjusterMutation = {
  __typename?: 'Mutation';
  upsertClientAdditionalLossAdjuster: {
    __typename?: 'Client';
    client_additional_loss_adjuster?: {
      __typename?: 'ClientAdditionalLossAdjuster';
      damage_payout_amount?: number | null;
      damage_payout_percentage?: number | null;
      liability_payout_amount?: number | null;
      liability_payout_percentage?: number | null;
      business_payout_amount?: number | null;
      business_payout_percentage?: number | null;
      business_insurance: boolean;
      payout_type?: Types.PayoutType | null;
      has_payout: boolean;
      payout_type_tax?: Types.PayoutTypeTax | null;
      has_ipad: boolean;
      can_send_emails: boolean;
    } | null;
  };
};

export const UpsertClientAdditionalLossAdjusterDocument = gql`
  mutation UpsertClientAdditionalLossAdjuster(
    $client_id: ID!
    $input: ClientAdditionalLossAdjusterInput!
  ) {
    upsertClientAdditionalLossAdjuster(client_id: $client_id, input: $input) {
      client_additional_loss_adjuster {
        damage_payout_amount
        damage_payout_percentage
        liability_payout_amount
        liability_payout_percentage
        business_payout_amount
        business_payout_percentage
        business_insurance
        payout_type
        has_payout
        payout_type_tax
        has_ipad
        can_send_emails
      }
    }
  }
`;

export function useUpsertClientAdditionalLossAdjusterMutation() {
  return Urql.useMutation<
    UpsertClientAdditionalLossAdjusterMutation,
    UpsertClientAdditionalLossAdjusterMutationVariables
  >(UpsertClientAdditionalLossAdjusterDocument);
}
