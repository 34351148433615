import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import { AppMessageFragmentDoc } from './appMessageFragment.generated';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type VisibleMessagesQueryVariables = Types.Exact<{ [key: string]: never }>;

export type VisibleMessagesQuery = {
  __typename?: 'Query';
  visibleMessages: Array<{
    __typename?: 'AppMessage';
    id: string;
    title: string;
    body: string;
    type: Types.PageNotificationType;
    author_id: string;
    active: boolean;
    visible_from?: string | null;
    visible_to?: string | null;
    author?: { __typename?: 'Client'; id: string; name: string } | null;
  } | null>;
};

export const VisibleMessagesDocument = gql`
  query VisibleMessages {
    visibleMessages {
      ...AppMessage
    }
  }
  ${AppMessageFragmentDoc}
`;

export function useVisibleMessagesQuery(
  options?: Omit<Urql.UseQueryArgs<VisibleMessagesQueryVariables>, 'query'>,
) {
  return Urql.useQuery<VisibleMessagesQuery, VisibleMessagesQueryVariables>({
    query: VisibleMessagesDocument,
    ...options,
  });
}
