import { FieldWrapper } from '@progress/kendo-react-form';
import { Checkbox, CheckboxProps } from '@progress/kendo-react-inputs';
import { Ripple } from '@progress/kendo-react-ripple';

import { Label } from '../components';
import { FieldError } from './FieldError';
import { FieldHint } from './FieldHint';
import { CommonFieldRenderProps } from './types';

type CheckboxFieldProps = CommonFieldRenderProps &
  Pick<
    CheckboxProps,
    | 'id'
    | 'value'
    | 'placeholder'
    | 'required'
    | 'disabled'
    | 'onBlur'
    | 'onChange'
    | 'onFocus'
    | 'autoComplete'
    | 'label'
  > & {
    size?: null | 'small' | 'medium' | 'large';
    hintClass?: string;
  };
export const CheckboxInput = (props: CheckboxFieldProps) => {
  const {
    size = 'medium',
    value,
    id,
    label,
    valid,
    onChange,
    name,
    tooltip,
    tooltipPosition,
  } = props;
  return (
    <FieldWrapper className="k-checkbox-wrap !k-d-flex-col !k-mb-2" dir="ltr">
      <Ripple>
        <Checkbox onChange={onChange} value={value} size={size} label={label} />
      </Ripple>

      {label && (
        <Label
          className="k-checkbox-label"
          style={{ fontSize: '15px', lineHeight: 1.3333333333, fontWeight: 400 }}
          tooltip={tooltip}
          tooltipPosition={tooltipPosition}
          label={''}
          editorId={id}
          editorValid={valid}
          name={name}
        />
      )}
      <div className="HintAndError">
        <FieldHint {...props} />
        <FieldError {...props} />
      </div>
    </FieldWrapper>
  );
};
