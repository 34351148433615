import {
  Ability,
  AbilityBuilder,
  AbilityClass,
  ForcedSubject,
  subject as subjectHelper,
} from '@casl/ability';
import { Permission } from '@generated/graphql.generated';
import { useCallback, useMemo } from 'react';

import { GenericCan, GenericCanSharedProps } from '../components/GenericCan';
import { CurrentUserAbilityFragment } from '../graphql';
import { TaskFileAbilityFragment } from '../graphql/generated/TaskFileAbilityFragment.generated';
import { detectSubjectTypeByTypename as detectSubjectType } from '../helpers';
import { useMeOrDefault } from '../hooks';

interface TaskFileSubject {
  me: CurrentUserAbilityFragment;
  file?: TaskFileAbilityFragment;
}

type TaskFileSubjectArg = Partial<TaskFileSubject>;

type Actions =
  | 'activateFileForInterface'
  | 'deleteFile'
  | 'renameFile'
  | 'updateFile'
  | 'updateFileTags'
  | 'updateFileProperties';

type Subjects = TaskFileSubject | 'TaskFileSubject';

type TaskFileAbility = Ability<[Actions, Subjects]>;
const taskFileAbility = Ability as AbilityClass<TaskFileAbility>;

export const useTaskFileAbility = (): [
  TaskFileAbility,
  (sub?: TaskFileSubjectArg) => TaskFileSubject & ForcedSubject<'TaskFileSubject'>,
] => {
  const ability = useMemo(() => {
    const { can, build } = new AbilityBuilder(taskFileAbility);

    can('activateFileForInterface', 'TaskFileSubject', {
      'file.permissions': { $in: [Permission.TASK_FILE_ACTIVATE_FILE_FOR_INTERFACE] },
    });
    can('deleteFile', 'TaskFileSubject', {
      'file.permissions': { $in: [Permission.TASK_FILE_DELETE_FILE] },
    });
    can('renameFile', 'TaskFileSubject', {
      'file.permissions': { $in: [Permission.TASK_FILE_RENAME_FILE] },
    });
    can('updateFile', 'TaskFileSubject', {
      'file.permissions': { $in: [Permission.TASK_FILE_UPDATE_FILE] },
    });
    can('updateFileTags', 'TaskFileSubject', {
      'file.permissions': { $in: [Permission.TASK_FILE_UPDATE_FILE_TAGS] },
    });
    can('updateFileProperties', 'TaskFileSubject', {
      'file.permissions': { $in: [Permission.TASK_FILE_UPDATE_FILE_PROPERTIES] },
    });

    return build({ detectSubjectType });
  }, []);

  const me = useMeOrDefault();
  const subject = useCallback(
    (sub?: TaskFileSubjectArg) => {
      return subjectHelper('TaskFileSubject', { me, ...sub });
    },
    [me],
  );

  return [ability, subject];
};

interface CanTaskFileProps extends GenericCanSharedProps<Actions> {
  file?: TaskFileAbilityFragment;
}

export const CanTaskFile = (props: CanTaskFileProps) => {
  const [taskFileAbility, taskFileSubject] = useTaskFileAbility();
  const { file, ...rest } = props;

  return (
    <GenericCan<Actions, Subjects, TaskFileAbility>
      ability={taskFileAbility}
      subject={taskFileSubject({ file })}
      {...rest}
    />
  );
};
