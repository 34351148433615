export default {
  pages: {
    overview: {
      title: 'Articles',
      description: 'Here you can see a list of predefined articles for invoicing',
      toolbar: {
        createArticle: 'New article',
        sync: 'Synchronize with Billomat',
      },
    },
  },
  labels: {
    articleNumber: 'Article number',
    title: 'Title',
    description: 'description',
    price: 'price',
    tax: 'sales tax',
    unit: 'Unit',
    assignment: 'insurance',
    autoPayout: {
      minSum: 'Valid for invoice amount from',
      maxSum: 'Valid for invoice amount up to',
      tooltip:
        'In order for these values to be read out, a question with a sum with the identifier "task_total_sum" must be defined in the form designer. This enables automatic accounting.',
    },
    assignments: 'Insurances',
  },
  dialogs: {
    createArticle: {
      title: 'Create article',
      submit: 'Create article',
    },
    updateArticle: {
      title: 'Update article',
      submit: 'Update article',
    },
    exports: {
      title: 'Export article',
      note: 'Download the exported articles.',
    },
  },
  notifications: {
    createArticle: {
      success: 'Article was created',
      error: 'Article could not be created',
    },
    updateArticle: {
      success: 'Article was updated',
      error: 'Article could not be updated',
    },
    sync: {
      success: 'Article successfully synchronized',
      error: 'Article could not be synchronized.',
    },
  },
};
