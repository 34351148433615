import './PageContent.scss';

import { classNames } from '@progress/kendo-react-common';
import { ReactNode } from 'react';

export interface PageContentProps {
  align?: 'left' | 'center' | 'right';
  width?: 'auto' | 'fixed' | 'full';
  className?: string;
  children?: ReactNode | undefined;
}

export const PageContent = (props: PageContentProps) => {
  const { align = 'left', className, width = 'full' } = props;
  return (
    <div
      className={classNames(
        'page-content',
        {
          'page-content-left': align === 'left',
          'page-content-center': align === 'center',
          'page-content-right': align === 'right',
        },
        {
          'page-content-width-auto': width === 'auto',
          'page-content-width-fixed': width === 'fixed',
          'page-content-width-full': width === 'full',
        },
        className,
      )}
    >
      <div className="page-content-wrapper">{props.children}</div>
    </div>
  );
};
