import { createContext, ReactNode, useContext, useMemo } from 'react';

import { AppSettingsFragment, AppSettingsQuery } from '../graphql';
import { mock_mutations_constraints } from './mockMutationConstraints';

const AppSettingsContext = createContext<
  (AppSettingsFragment & { mutation_constraints: typeof mock_mutations_constraints }) | undefined
>(undefined);

interface AppSettingsProviderProps {
  children: ReactNode;
  data: AppSettingsQuery;
}

export const AppSettingsProvider = (props: AppSettingsProviderProps) => {
  const { data, ...rest } = props;

  const appSettings = useMemo(
    () =>
      data ? { ...data.appSettings, mutation_constraints: mock_mutations_constraints } : undefined,
    [data],
  );

  return <AppSettingsContext.Provider value={appSettings} {...rest} />;
};

export const useAppSettings = () => {
  const context = useContext(AppSettingsContext);

  if (!context) {
    throw new Error('useAppSettings must be used within AppSettingsProvider');
  }

  return context;
};
