import { useTranslation } from 'react-i18next';

import { BaseButton, BaseButtonProps } from './BaseButton';

export type CancelButtonProps = Omit<BaseButtonProps, 'iconClass'>;
export const CancelButton = (props: CancelButtonProps) => {
  const { t } = useTranslation();
  const { label = t('common.labels.cancel') } = props;
  return <BaseButton {...props} label={label} iconClass="l-i-ban" />;
};
