import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type UpsertClientTaxMutationVariables = Types.Exact<{
  client_id: Types.Scalars['ID'];
  input: Types.ClientTaxInput;
}>;

export type UpsertClientTaxMutation = {
  __typename?: 'Mutation';
  upsertClientTax?: {
    __typename?: 'Client';
    client_tax?: {
      __typename?: 'ClientTax';
      vat_number?: string | null;
      accounts_receivable_number?: string | null;
      eligible_tax_deduction: boolean;
    } | null;
  } | null;
};

export const UpsertClientTaxDocument = gql`
  mutation UpsertClientTax($client_id: ID!, $input: ClientTaxInput!) {
    upsertClientTax(client_id: $client_id, input: $input) {
      client_tax {
        vat_number
        accounts_receivable_number
        eligible_tax_deduction
      }
    }
  }
`;

export function useUpsertClientTaxMutation() {
  return Urql.useMutation<UpsertClientTaxMutation, UpsertClientTaxMutationVariables>(
    UpsertClientTaxDocument,
  );
}
