import { Hint } from '@progress/kendo-react-labels';
import { Tooltip } from '@progress/kendo-react-tooltip';
import { CSSProperties } from 'react';

interface InputHintProps {
  error: string | false;
  style?: CSSProperties;
}

export const EditableCellErrorHint = ({
  error,
  style = { background: '#fff' },
}: InputHintProps) => {
  if (!error) return null;
  return (
    <Hint
      style={style}
      className="k-d-inline-flex k-justify-content-center k-align-items-center k-color-warning k-px-2 k-cursor-pointer"
    >
      <Tooltip anchorElement="target" position="top">
        <span title={error} className="l-i-alert-circle" />
      </Tooltip>
    </Hint>
  );
};
