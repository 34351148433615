export default {
  labels: {
    insert: 'Einfügen',
    toolbarItemTitle: 'Stempel-Vorlagen',
  },
  dialogs: {
    create: {
      title: 'Neue Stempel-Vorlage',
      description: 'Erstellen Sie eine neue Stempel-Vorlage.',
      success: 'Die Stempel-Vorlage wurde erstellt.',
    },
    delete: {
      title: 'Stempel-Vorlage löschen',
      description: 'Möchten Sie die Stempel-Vorlage wirklich löschen?',
      success: 'Die Stempel-Vorlage wurde gelöscht.',
    },
    update: {
      title: 'Stempel-Vorlage bearbeiten',
      description: 'Bearbeiten Sie die Stempel-Vorlage.',
      success: 'Die Stempel-Vorlage wurde geändert.',
    },
    selectStampTemplate: {
      title: 'Stempel-Vorlage auswählen',
      description: 'Wählen Sie eine Stempel-Vorlage aus.',
    },
  },
  components: {
    stampTemplatesFormFields: {
      title: 'Titel',
      description: 'Beschreibung',
      stamp: 'Stempel-Datei hier hochladen',
    },
    stampTemplatesGrid: {
      columns: {
        thumbnail: 'Stempel',
        title: 'Titel',
        description: 'Beschreibung',
      },
      noRecords: 'Keine Stempel-Vorlagen gefunden',
    },
  },
  pages: {
    overview: {
      title: 'Stempel-Vorlagen',
      description:
        'Hier können Sie die Stempel-Vorlagen verwalten. Stempelvorlagen können in beliebige PDF Dokumente eingefügt werden.',
      toolbar: {
        createStampTemplate: 'Neue Stempel-Vorlage',
      },
    },
  },
};
