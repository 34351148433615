import './BrandingPanel.scss';

import { useTranslation } from 'react-i18next';

import { useBrandingData } from '../../hooks';

export interface BrandingPanelProps {
  size: 'small' | 'large';
  onClick?: () => void;
  color?: string;
}

export const BrandingPanel = (props: BrandingPanelProps) => {
  const { size, onClick, color } = props;
  const { t } = useTranslation();
  const branding = useBrandingData();

  if (size === 'large') {
    return (
      <div className="k-display-flex k-flex-column k-justify-content-center" onClick={onClick}>
        <div>
          <img src={color === 'light' ? branding.logo_light : branding.logo} width={140} />
        </div>
        <div>
          <span className="k-font-weight-semibold">{branding.name}</span>
        </div>
        <div>
          <span className="k-fs-sm">
            {t('common.labels.version')} {branding.version}
          </span>
        </div>
      </div>
    );
  }

  if (size === 'small') {
    return (
      <div
        className="k-p-2 k-display-flex k-flex-row BrandingPanel BrandingPanelSmall"
        onClick={onClick}
      >
        <img src={color === 'light' ? branding.logo_light : branding.logo} width={40} />

        <div className="k-pl-3 k-display-flex k-flex-column k-justify-content-center k-align-items-start">
          <span className="k-font-weight-semibold">{branding.name}</span>
          <span className="k-fs-sm">
            {t('common.labels.version')} {branding.version}
          </span>
        </div>
      </div>
    );
  }

  return null;
};
