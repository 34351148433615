export default {
  dialogs: {
    createPayout: {
      note: 'Damit erzeugen Sie eine neue Auszahlung. Sämtliche Rechnungen, welche noch zu keiner Auszahlung gehören, werden für diese Auszahlung herangezogen. Die Einstellungen für Auszahlungen bei den Stammdaten für Experten werden hierbei herangezogen. Wollen Sie eine neue Auszahlung starten?',
    },
    addPayout: {
      note: 'Erstellen Sie manuell eine neue Abrechnung. Dafür müssen den betreffenden Abteilungen oder Experten angeben und die dazugehörige Rechnung eintrage. Beträge können im vergleich zu den automatisch erstellten Abrechnungen manuell eingegeben werden und werden nicht auf Basis der Stammdateneintellungen berechnet.',
      form: {
        clientId: 'Experte / Abteilung',
      },
    },
  },
};
