import './RemainingCharacterIndicator.scss';

import { classNames } from '@progress/kendo-react-common';
import { useTranslation } from 'react-i18next';

interface RemainingCharacterIndicatorProps {
  max: number;
  current?: number;
}

export const RemainingCharacterIndicator = ({
  max,
  current = 0,
}: RemainingCharacterIndicatorProps) => {
  const { t } = useTranslation();

  return (
    <div className="RemainingCharacterIndicator">
      <span className={classNames(current > max && 'RemainingCharacterIndicator--error')}>
        {t('common.components.remainingCharacterIndicator.info', { remaining: max - current, max })}
      </span>
    </div>
  );
};
