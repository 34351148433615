export default {
  errors: {
    token_expired: 'Verifizierungs Link abgelauft.',
    already_verified: 'E-Mail wurde bereits verifiziert.',
  },
  pages: {
    login: {
      title: 'Willkommen zurück',
      subtitle: 'Willkommen zurück, bitte gib deine Daten ein.',
      submit: 'Anmelden',
      forgotPassword: 'Passwort vergessen?',
      or: 'oder',
      useMobile: 'Mobile Inspcto App verwenden',
    },
    initialLogin: {
      title: 'Willkommen',
      subtitle: 'Willkommen bei INSPCTO, Sie wurden in unser Portal eingeladen.',
      submit: 'Weiter',
      note: 'Wählen Sie ein initiales Passwort, um die Registrierung abzuschließen.',
    },
    forgotPassword: {
      title: 'Passwort Vergessen',
      submit: 'Link senden',
      note: 'Um Ihr Passwort zurückzusetzen, geben sie die von Ihnen verwendete E-Mail-Adresse ein.\nIm Anschluss senden wir Ihnen einen Link an Ihre E-Mail-Adresse, um ein neues Passwort zu setzen.',
      toLogin: 'Zurück zum Login',
    },
    resetPassword: {
      title: 'Passwort zurücksetzen',
      submit: 'Passwort zurücksetzen',
      note: 'Gibt dein neues Passwort ein.',
    },
    verifyEmail: {
      title: 'E-Mail Bestätigen',
      submit: 'E-Mail bestätigen',
      note: 'Bestätigen Sie Ihre E-Mail indem Sie auf den Button klicken.',
    },
    noPermission: {
      error: '403 Fehler',
      title: 'Keine Berechtigung',
      note: 'Sie haben keine Berechtigung, um diese Seite zu sehen.',
      backToHome: 'Zur Startseite',
      back: 'Zurück',
    },
    maintenance: {
      error: 'Wartung',
      title: 'Dieses Seite wird vorübergehend gewartet.',
      note: 'Kommen Sie bitte zu einem anderem Zeitpunkt zurück, um alle neue Änderungen zu erleben.',
    },
  },
  dialogs: {
    useMobileApp: {
      title: 'Inspcto Mobile App',
      appDescription:
        'Sie können das Portal auch direkt über Ihren Browser auf Ihrem Telefon nutzen. Zusätzlich bieten wir aber auch eine App im App Store für iOS Geräte und eine App im Play Store für Android Geräte an. Sie können die App hier herunterladen:',
      connectServiceTitle: 'Anleitung zum Verbinden der App mit ihrem Portal:',
      connectServiceDescription:
        'Wenn Sie die App auf Ihrem Gerät installiert und geöffnet haben, dann wählen Sie unter “Einstellungen” den Punkt “Mit Portal verknüpfen” - dadurch erscheint eine Kamera. Scannen Sie damit den unten angezeigten QR Code, damit sind die beiden Geräte miteinander verknüpft.',
      alternativeServiceURL:
        'Falls Sie Probleme haben, können Sie den Server auch dort manuell eintragen. Wählen Sie dazu: ',
      copySuccess: 'Der Link wurde in die Zwischenablage kopiert.',
    },
  },
};
