import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CreateAppEmailTransportMutationVariables = Types.Exact<{
  input: Types.CreateAppEmailTransportInput;
}>;

export type CreateAppEmailTransportMutation = {
  __typename?: 'Mutation';
  createAppEmailTransport: { __typename?: 'AppEmailTransport'; id: string };
};

export const CreateAppEmailTransportDocument = gql`
  mutation CreateAppEmailTransport($input: CreateAppEmailTransportInput!) {
    createAppEmailTransport(input: $input) {
      id
    }
  }
`;

export function useCreateAppEmailTransportMutation() {
  return Urql.useMutation<
    CreateAppEmailTransportMutation,
    CreateAppEmailTransportMutationVariables
  >(CreateAppEmailTransportDocument);
}
