import { classNames } from '@progress/kendo-react-common';
import { FieldWrapper } from '@progress/kendo-react-form';
import { Input, InputProps } from '@progress/kendo-react-inputs';
import { useId } from 'react';
import { useTranslation } from 'react-i18next';

import { Label } from '../components/Label';
import { FieldError } from './FieldError';
import { FieldHint } from './FieldHint';
import { sanitizeInputValue } from './helpers';
import { CommonFieldRenderProps } from './types';

export type TextInputProps = CommonFieldRenderProps &
  Pick<
    InputProps,
    | 'id'
    | 'value'
    | 'placeholder'
    | 'required'
    | 'disabled'
    | 'onBlur'
    | 'onChange'
    | 'onFocus'
    | 'autoComplete'
    | 'autoFocus'
    | 'label'
  > & {
    size?: null | 'small' | 'medium' | 'large';
    hintClass?: string;
    copyToClipboard?: boolean;
  };

export const TextInput = (props: TextInputProps) => {
  const defaultId = useId();
  const {
    size = 'large',
    value,
    id = defaultId,
    label,
    placeholder,
    valid,
    required,
    disabled,
    onBlur,
    onChange,
    onFocus,
    name,
    autoComplete,
    tooltip,
    tooltipPosition,
    copyToClipboard = false,
    autoFocus,
  } = props;

  const { t } = useTranslation();
  const valueSanitized = sanitizeInputValue(value);
  return (
    <FieldWrapper className="TextInput">
      {label && (
        <Label
          tooltip={tooltip}
          tooltipPosition={tooltipPosition}
          label={label}
          editorId={id}
          editorValid={valid}
          name={name}
        >
          {label}
        </Label>
      )}

      <span
        className={classNames('k-input k-input-solid k-rounded-md', {
          'k-input-sm': size === 'small',
          'k-input-md': size === 'medium',
          'k-input-lg': size === 'large',
        })}
      >
        <Input
          className="k-input-inner"
          type="text"
          id={id}
          autoComplete={autoComplete}
          autoFocus={autoFocus}
          value={valueSanitized}
          placeholder={placeholder}
          required={required}
          disabled={disabled}
          onBlur={onBlur}
          onChange={onChange}
          onFocus={onFocus}
        />

        {copyToClipboard && (
          <button
            type="button"
            title={t('common.labels.copy')}
            className="k-button k-button-md k-button-solid k-button-solid-base k-icon-button k-input-button"
            onClick={() => {
              navigator.clipboard.writeText(valueSanitized as string);
            }}
            aria-label={t('common.labels.copy')}
          >
            <span className="l-i-copy" />
          </button>
        )}
      </span>
      <div className="HintAndError">
        <FieldHint {...props} />
        <FieldError {...props} />
      </div>
    </FieldWrapper>
  );
};
