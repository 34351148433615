import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import { ClientSelectFragmentDoc } from './clientSelectFragment.generated';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ClientsForTaskQueryVariables = Types.Exact<{ [key: string]: never }>;

export type ClientsForTaskQuery = {
  __typename?: 'Query';
  clientsForTask: {
    __typename?: 'ClientList';
    data: Array<{
      __typename?: 'Client';
      id: string;
      name: string;
      job_title?: string | null;
      email?: string | null;
    }>;
  };
};

export const ClientsForTaskDocument = gql`
  query ClientsForTask {
    clientsForTask {
      data {
        ...ClientSelect
      }
    }
  }
  ${ClientSelectFragmentDoc}
`;

export function useClientsForTaskQuery(
  options?: Omit<Urql.UseQueryArgs<ClientsForTaskQueryVariables>, 'query'>,
) {
  return Urql.useQuery<ClientsForTaskQuery, ClientsForTaskQueryVariables>({
    query: ClientsForTaskDocument,
    ...options,
  });
}
