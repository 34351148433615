import { classNames } from '@progress/kendo-react-common';
import { FieldWrapper } from '@progress/kendo-react-form';
import { Input, InputProps } from '@progress/kendo-react-inputs';
import { useCallback, useId } from 'react';

import { Label } from '../components/Label';
import { FieldError } from './FieldError';
import { FieldHint } from './FieldHint';
import { sanitizeInputValue } from './helpers';
import { CommonFieldRenderProps } from './types';

type ClipBoardInputProps = CommonFieldRenderProps &
  Pick<
    InputProps,
    | 'id'
    | 'value'
    | 'placeholder'
    | 'required'
    | 'disabled'
    | 'onBlur'
    | 'onChange'
    | 'onFocus'
    | 'autoComplete'
    | 'label'
  > & {
    size?: null | 'small' | 'medium' | 'large';
    hintClass?: string;
    icon?: string;
    iconClass?: string;
    onCopyToClipBoard?: () => void;
  };

export const ClipBoardInput = (props: ClipBoardInputProps) => {
  const defaultId = useId();
  const {
    size = 'large',
    value,
    id = defaultId,
    label,
    placeholder,
    valid,
    required,
    disabled,
    onBlur,
    onChange,
    onFocus,
    name,
    autoComplete,
    tooltip,
    tooltipPosition,
    icon,
    iconClass,
    onCopyToClipBoard,
  } = props;

  const handleCopyToClipBoard = useCallback(async () => {
    if (value) {
      await navigator.clipboard.writeText(value.toString());
      onCopyToClipBoard?.();
    }
  }, [onCopyToClipBoard, value]);

  const valueSanitized = sanitizeInputValue(value);
  return (
    <FieldWrapper className="ClipBoardInput">
      {label && (
        <Label
          tooltip={tooltip}
          tooltipPosition={tooltipPosition}
          label={label}
          editorId={id}
          editorValid={valid}
          name={name}
        >
          {label}
        </Label>
      )}

      <span
        className={classNames('k-input k-input-solid k-rounded-md', {
          'k-input-sm': size === 'small',
          'k-input-md': size === 'medium',
          'k-input-lg': size === 'large',
        })}
      >
        <Input
          className="k-input-inner"
          type="text"
          id={id}
          autoComplete={autoComplete}
          value={valueSanitized}
          placeholder={placeholder}
          required={required}
          disabled={disabled}
          onBlur={onBlur}
          onChange={onChange}
          onFocus={onFocus}
        />
        <span className="k-input-icon k-cursor-pointer" onClick={handleCopyToClipBoard}>
          <span className={classNames(icon ? `k-font-icon k-i-${icon}` : undefined, iconClass)} />
        </span>
      </span>
      <div className="HintAndError">
        <FieldHint {...props} />
        <FieldError {...props} />
      </div>
    </FieldWrapper>
  );
};
