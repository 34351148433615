export default {
  labels: {
    createEntry: 'Eintrag erstellen',
    name: 'Name',
    email: 'E-Mail',
  },
  noRecords: {
    title: 'Keine Adressbucheinträge vorhanden',
    text: 'Es sind noch keine Adressbucheinträge vorhanden. Klicke auf den Button, um einen neuen Eintrag zu erstellen.',
  },
  pages: {
    overview: {
      title: 'Adressbuch',
      description:
        'Hier können Sie globale Adressen verwalten, welche allen Benutzern für z.B. Emails zur Verfügung stehen.',
    },
  },
  dialogs: {
    createEntry: {
      title: 'Adressbucheintrag erstellen',
    },
    updateEntry: {
      title: 'Adressbucheintrag aktualisieren',
    },
    deleteEntry: {
      title: 'Adressbucheintrag löschen',
      description: 'Möchten Sie den Adressbucheintrag wirklich löschen?',
    },
  },
  notifications: {
    entryCreated: 'Adressbucheintrag wurde erstellt',
    entryUpdated: 'Adressbucheintrag wurde aktualisiert',
    entryDeleted: 'Adressbucheintrag wurde gelöscht',
  },
};
