import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import { TaskFolderTemplateFieldsFragmentDoc } from './useTaskFolderTemplatesQuery.generated';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type TaskFolderTemplateQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;

export type TaskFolderTemplateQuery = {
  __typename?: 'Query';
  taskFolderTemplate: {
    __typename?: 'TaskFolderTemplate';
    id: string;
    name: string;
    updated_at: string;
  };
};

export const TaskFolderTemplateDocument = gql`
  query TaskFolderTemplate($id: ID!) {
    taskFolderTemplate(id: $id) {
      ...TaskFolderTemplateFields
    }
  }
  ${TaskFolderTemplateFieldsFragmentDoc}
`;

export function useTaskFolderTemplateQuery(
  options: Omit<Urql.UseQueryArgs<TaskFolderTemplateQueryVariables>, 'query'>,
) {
  return Urql.useQuery<TaskFolderTemplateQuery, TaskFolderTemplateQueryVariables>({
    query: TaskFolderTemplateDocument,
    ...options,
  });
}
