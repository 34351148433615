import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import { ClassificationTagFragmentDoc } from './classificationTagFragment.generated';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CreateClassificationTagMutationVariables = Types.Exact<{
  input: Types.ClassificationTagInput;
}>;

export type CreateClassificationTagMutation = {
  __typename?: 'Mutation';
  createClassificationTag?: {
    __typename?: 'ClassificationTag';
    id: string;
    title: string;
    color: string;
    alias: string;
    api_only: boolean;
  } | null;
};

export const CreateClassificationTagDocument = gql`
  mutation CreateClassificationTag($input: ClassificationTagInput!) {
    createClassificationTag(input: $input) {
      ...ClassificationTag
    }
  }
  ${ClassificationTagFragmentDoc}
`;

export function useCreateClassificationTagMutation() {
  return Urql.useMutation<
    CreateClassificationTagMutation,
    CreateClassificationTagMutationVariables
  >(CreateClassificationTagDocument);
}
