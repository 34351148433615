import {
  Ability,
  AbilityBuilder,
  AbilityClass,
  ForcedSubject,
  subject as subjectHelper,
} from '@casl/ability';
import { useCallback, useMemo } from 'react';

import { Permission } from '../../../types/graphql.generated';
import { GenericCan, GenericCanSharedProps } from '../components/GenericCan';
import { CurrentUserAbilityFragment } from '../graphql';
import { detectSubjectTypeByTypename as detectSubjectType } from '../helpers';
import { useMeOrDefault } from '../hooks';

interface EmailTemplateSubject {
  me: CurrentUserAbilityFragment;
}

type EmailTemplateSubjectArg = Partial<EmailTemplateSubject>;

type Actions =
  | 'viewEmailTemplates'
  | 'createEmailTemplate'
  | 'updateEmailTemplate'
  | 'deleteEmailTemplate';
type Subjects = EmailTemplateSubject | 'EmailTemplateSubject';

type EmailTemplateAbility = Ability<[Actions, Subjects]>;
const userAbility = Ability as AbilityClass<EmailTemplateAbility>;

export const useEmailTemplateAbility = (): [
  EmailTemplateAbility,
  (sub?: EmailTemplateSubjectArg) => EmailTemplateSubject & ForcedSubject<'EmailTemplateSubject'>,
] => {
  const ability = useMemo(() => {
    const { can, build } = new AbilityBuilder(userAbility);

    can('viewEmailTemplates', 'EmailTemplateSubject', {
      'me.globalPermissions': { $in: [Permission.EMAIL_TEMPLATE_INDEX] },
    });
    can('createEmailTemplate', 'EmailTemplateSubject', {
      'me.globalPermissions': { $in: [Permission.EMAIL_TEMPLATE_CREATE] },
    });
    can('updateEmailTemplate', 'EmailTemplateSubject', {
      'me.globalPermissions': { $in: [Permission.EMAIL_TEMPLATE_UPDATE] },
    });
    can('deleteEmailTemplate', 'EmailTemplateSubject', {
      'me.globalPermissions': { $in: [Permission.EMAIL_TEMPLATE_DELETE] },
    });

    return build({ detectSubjectType });
  }, []);

  const me = useMeOrDefault();
  const subject = useCallback(
    (sub?: EmailTemplateSubjectArg) => {
      return subjectHelper('EmailTemplateSubject', { me, ...sub });
    },
    [me],
  );

  return [ability, subject];
};

type CanEmailTemplateProps = GenericCanSharedProps<Actions>;

export const CanEmailTemplate = (props: CanEmailTemplateProps) => {
  const [emailTemplateAbility, emailTemplateSubject] = useEmailTemplateAbility();

  return (
    <GenericCan<Actions, Subjects, EmailTemplateAbility>
      ability={emailTemplateAbility}
      subject={emailTemplateSubject()}
      {...props}
    />
  );
};
