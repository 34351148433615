import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
export type TaskLocationFragment = {
  __typename?: 'TaskLocation';
  id: string;
  task_location_type_id?: string | null;
  street?: string | null;
  street_no?: string | null;
  postcode?: string | null;
  city?: string | null;
  latitude?: number | null;
  longitude?: number | null;
  country: { __typename?: 'Country'; id: string; title: string };
};

export const TaskLocationFragmentDoc = gql`
  fragment TaskLocation on TaskLocation {
    id
    task_location_type_id
    street
    street_no
    postcode
    city
    country {
      id
      title
    }
    latitude
    longitude
  }
`;
