export default {
  checkForbiddenPrefix: {
    plusCheck: "Beginnt mit ungültigem Zeichen '+' ",
    minusCheck: "Beginnt mit ungültigem Zeichen '-' ",
    equalsCheck: "Beginnt mit ungültigem Zeichen '=' ",
    atCheck: "Beginnt mit ungültigem Zeichen '@' ",
    tabCheck: "Beginnt mit ungültigem Zeichen '\\t' ",
    carriageReturnCheck: "Beginnt mit ungültigem Zeichen '\\r' ",
  },
  email: 'Geben Sie eine gültige E-Mail-Adresse ein, z.B. max.mustermann@example.com',
  firstname: 'Vorname sollte mindestens 4 Zeichen lang sein',
  required: 'Dieses Feld darf nicht leer sein.',
  // Yup
  mixed: {
    required: 'Dieses Feld darf nicht leer sein.',
    notType: 'Feld ist ungültig.',
  },
  string: {
    email: 'Geben Sie eine gültige E-Mail-Adresse ein, z.B. max.mustermann@example.com',
    min: 'Muss mindestens {{min}} Zeichen lang sein',
    max: 'Diese Feld darf maximal {{max}} Zeichen lang sein',
    length: 'Muss {{length}} Zeichen enthalten.',
    trim: 'Darf nicht mit Leerzeichen beginnen oder enden.',
    rgb: 'Muss dem RGB Format entsprechen, z.B. #FFFFFF.',
    rgba: 'Muss dem RGBA Format entsprechen, z.B. #FFFFFFFF.',
  },
  number: {
    integer: 'Muss eine ganze Zahl sein.',
    min: 'Muss mindestens {{min}} betragen.',
    max: 'Darf maximal {{max}} betragen.',
  },
  array: {
    min: 'Muss mindestens {{min}} Element(e) enthalten.',
    max: 'Darf maximal {{max}} Element(e) enthalten.',
  },

  // Yup custom
  uploadFileInfo:
    'Ungültige Datei(en). Bitte überprüfen Sie die Anforderungen für das Hochladen von Dateien.',
  password: {
    strength: 'Das Passwort ist zu schwach.',
    length: 'Mindestens {{min}} Zeichen.',
    special: 'Mindestens {{min}} Sonderzeichen (z.B. !@#$%^&*-).',
    numbers: 'Mindestends {{min}} Zahlen (z.B. 0-9)',
    uppercase: 'Mindestens {{min}} Großbuchstaben.',
    confirmation: 'Passwort wdh. muss übereinstimmen.',
    chain: {
      general: 'Passwort muss folgendes enthalten: {{chain}}',
      length: '{{min}} Zeichen',
      special: '{{min}} Sonderzeichen (z.B. !@#$%^&*-).',
      numbers: '{{min}} Zahl(en) (z.B. 0-9)',
      uppercase: '{{min}} Großbuchstabe(n).',
    },
  },
};
