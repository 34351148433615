import { useConfirmDialogs } from '@module/common';
import { NotificationType, useNotifications } from '@module/shared/notifications';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useDeleteApiTokenMutation } from '../graphql';

export const useConfirmDeleteApiToken = () => {
  const { t } = useTranslation();
  const confirm = useConfirmDialogs();
  const { showNotification } = useNotifications();
  const [, deleteApiToken] = useDeleteApiTokenMutation();

  return useCallback(
    (uuid: string) => {
      confirm.delete({
        title: t('settings.createApiToken.confirm.title'),
        description: t('settings.createApiToken.confirm.description'),
        delete: async () => {
          const response = await deleteApiToken(
            { uuid: uuid },
            { additionalTypenames: ['ApiTokenList'] },
          );

          if (response.data?.deleteApiToken.success) {
            showNotification(response.data?.deleteApiToken.message, NotificationType.Success);
          } else {
            showNotification(response.data?.deleteApiToken.message, NotificationType.Error);
          }
        },
      });
    },
    [confirm, deleteApiToken, showNotification, t],
  );
};
