import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
export type ExportConfigOptionFragment = {
  __typename?: 'ExportConfigOption';
  title: string;
  description?: string | null;
  key: string;
  value?: boolean | null;
};

export type ExportConfigFragment = {
  __typename?: 'ExportConfig';
  type: Types.ExportType;
  columns: Array<{
    __typename?: 'ExportConfigOption';
    title: string;
    description?: string | null;
    key: string;
    value?: boolean | null;
  }>;
  options: Array<{
    __typename?: 'ExportConfigOption';
    title: string;
    description?: string | null;
    key: string;
    value?: boolean | null;
  }>;
};

export const ExportConfigOptionFragmentDoc = gql`
  fragment ExportConfigOption on ExportConfigOption {
    title
    description
    key
    value
  }
`;
export const ExportConfigFragmentDoc = gql`
  fragment ExportConfig on ExportConfig {
    type
    columns {
      ...ExportConfigOption
    }
    options {
      ...ExportConfigOption
    }
  }
  ${ExportConfigOptionFragmentDoc}
`;
