import { isNotNullish, isNumber } from '@module/shared/helpers';
import { useFormatCurrency } from '@module/shared/localization';
import { GridCellProps } from '@progress/kendo-react-grid';
import {
  NumericTextBox,
  NumericTextBoxChangeEvent,
  NumericTextBoxHandle,
} from '@progress/kendo-react-inputs';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { CellValueWithErrorHint } from './CellValueWithErrorHint';
import { useEditableCellContext } from './EditableCellContext';
import { EditableCellErrorHint } from './EditableCellErrorHint';

export const EditableCurrencyCell = (props: GridCellProps) => {
  const editableCellContext = useEditableCellContext();
  const formatCurrency = useFormatCurrency();
  const { dataItem, dataIndex, field } = props;

  const value = useMemo(() => {
    return field && isNotNullish(dataItem[field]) && isNumber(dataItem[field])
      ? (dataItem[field] as number)
      : undefined;
  }, [dataItem, field]);

  const inEdit = dataItem.inEdit as string | undefined;
  const error = field && dataItem.errors ? dataItem.errors[field] : false;

  // handle focus
  const ref = useRef<NumericTextBoxHandle>(null);
  const [triggerFocus, setTriggerFocus] = useState(false);
  useEffect(() => {
    // reset after trigger
    if (ref.current?.element && triggerFocus) {
      setTriggerFocus(false);
      ref.current?.element.focus();
    }

    // reset if field not in edit mode
    if (inEdit !== field && triggerFocus) {
      setTriggerFocus(false);
    }
  }, [dataIndex, field, inEdit, triggerFocus]);

  // edit mode
  const handleEnterEdit = useCallback(() => {
    if (field && editableCellContext) {
      editableCellContext.enterEdit?.(dataItem, field);
      setTriggerFocus(true);
    }
  }, [dataItem, editableCellContext, field]);

  // handle changes
  const handleChange = useCallback(
    (e: NumericTextBoxChangeEvent) => {
      if (field) {
        props.onChange?.({
          dataItem,
          dataIndex,
          syntheticEvent: e.syntheticEvent,
          field,
          value: e.target.value,
        });
      }
    },
    [dataIndex, dataItem, field, props],
  );

  if (!field) {
    return null;
  }

  return (
    <td className={props.className} onClick={handleEnterEdit}>
      {inEdit !== field && (
        <CellValueWithErrorHint
          error={error}
          value={isNotNullish(value) ? formatCurrency(value) : undefined}
        />
      )}
      {inEdit && inEdit === field && (
        <span className="k-input">
          <NumericTextBox
            size="large"
            ref={ref}
            value={value}
            format={{
              style: 'currency',
              currency: 'EUR',
              currencyDisplay: 'symbol',
            }}
            spinners={false}
            onChange={handleChange}
            inputStyle={{ textAlign: 'right' }}
          />
          <EditableCellErrorHint error={error} />
        </span>
      )}
    </td>
  );
};
