import { Reducer } from 'react';

export type LoaderState<TData> =
  | { loading: true; error: undefined; data?: TData }
  | { loading: false; error: Error; data?: TData }
  | { loading: false; error: undefined; data: TData };

export type LoaderAction<TData> =
  | { type: 'PENDING' }
  | { type: 'REJECTED'; error: unknown }
  | { type: 'RESOLVED'; data: TData };

export type LoaderReducer<TData> = Reducer<LoaderState<TData>, LoaderAction<TData>>;

export const createLoaderReducer =
  <TData>(): LoaderReducer<TData> =>
  (state, action) => {
    switch (action.type) {
      case 'PENDING': {
        if (state.loading && !state.error) {
          return state;
        }

        return {
          ...state,
          loading: true,
          error: undefined,
        };
      }

      case 'REJECTED': {
        return {
          ...state,
          loading: false,
          error: action.error instanceof Error ? action.error : Error('Failed to load'),
        };
      }

      case 'RESOLVED': {
        return {
          ...state,
          loading: false,
          error: undefined,
          data: action.data,
        };
      }

      default: {
        return state;
      }
    }
  };
