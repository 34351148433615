export default {
  labels: {
    id: 'Id',
    title: 'Titel',
    description: 'Beschreibung',
    finishDateDefaultDays: 'Vorgegebene Lösungszeit in Werktagen',
    edit: 'Edit',
  },

  dialogs: {
    edit: {
      title: 'Aufgaben Vorlage bearbeiten',
      submit: 'Aufgaben Vorlage aktualisieren',
    },
    create: {
      title: 'Aufgaben Vorlage erstellen',
      submit: 'Aufgaben Vorlage erstellen',
    },
    delete: {
      title: 'Aufgaben Vorlage löschen',
      description: 'Sind Sie sicher, dass Sie die Aufgaben Vorlage löschen möchten?',
    },
  },
  pages: {
    overview: {
      title: 'Aufgaben Vorlagen',
      description: 'Hier sehen Sie eine Liste von Vorlagen für Aufgaben.',
      toolbar: {
        createSubTasksTemplate: 'Neue Aufgabe',
      },
    },
  },
  notifications: {
    create: {
      success: 'Aufgaben Vorlage wurde erstellt.',
      error: 'Aufgaben Vorlagen konnte nicht erstellt werden.',
    },
    edit: {
      success: 'Aufgaben Vorlage wurde aktualisiert.',
      error: 'Aufgaben Vorlage konnte nicht aktualisiert werden.',
    },
    delete: {
      success: 'Aufgaben Vorlage wurde gelöscht.',
      error: 'Aufgaben Vorlage konnte nicht gelöscht werden.',
    },
  },
};
