import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type GenerateTranscribeCredentialsMutationVariables = Types.Exact<{ [key: string]: never }>;

export type GenerateTranscribeCredentialsMutation = {
  __typename?: 'Mutation';
  generateTranscribeCredentials: {
    __typename?: 'TranscribeCredentials';
    accessKey: string;
    secretAccessKey: string;
    sessionToken: string;
  };
};

export const GenerateTranscribeCredentialsDocument = gql`
  mutation GenerateTranscribeCredentials {
    generateTranscribeCredentials {
      accessKey
      secretAccessKey
      sessionToken
    }
  }
`;

export function useGenerateTranscribeCredentialsMutation() {
  return Urql.useMutation<
    GenerateTranscribeCredentialsMutation,
    GenerateTranscribeCredentialsMutationVariables | void
  >(GenerateTranscribeCredentialsDocument);
}
