import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type AddCustomerPortalEventDocumentsMutationVariables = Types.Exact<{
  customer_portal_event_id: Types.Scalars['ID'];
  file_ids: Array<Types.Scalars['ID']> | Types.Scalars['ID'];
}>;

export type AddCustomerPortalEventDocumentsMutation = {
  __typename?: 'Mutation';
  addCustomerPortalEventDocuments?: { __typename?: 'CustomerPortalEvent'; id: string } | null;
};

export const AddCustomerPortalEventDocumentsDocument = gql`
  mutation AddCustomerPortalEventDocuments($customer_portal_event_id: ID!, $file_ids: [ID!]!) {
    addCustomerPortalEventDocuments(
      customer_portal_event_id: $customer_portal_event_id
      input: { file_ids: $file_ids }
    ) {
      id
    }
  }
`;

export function useAddCustomerPortalEventDocumentsMutation() {
  return Urql.useMutation<
    AddCustomerPortalEventDocumentsMutation,
    AddCustomerPortalEventDocumentsMutationVariables
  >(AddCustomerPortalEventDocumentsDocument);
}
