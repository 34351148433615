import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type TaskFolderTemplateFieldsFragment = {
  __typename?: 'TaskFolderTemplate';
  id: string;
  name: string;
  updated_at: string;
};

export type TaskFolderTemplatesQueryVariables = Types.Exact<{
  filter: Types.TaskFolderTemplatesFilter;
  pagination: Types.PaginationInput;
  sort: Types.TaskFolderTemplatesSort;
}>;

export type TaskFolderTemplatesQuery = {
  __typename?: 'Query';
  taskFolderTemplates: {
    __typename?: 'TaskFolderTemplatesPaginator';
    data: Array<{
      __typename?: 'TaskFolderTemplate';
      id: string;
      name: string;
      updated_at: string;
    }>;
    paginatorInfo: { __typename?: 'PaginatorInfo'; total: number };
  };
};

export const TaskFolderTemplateFieldsFragmentDoc = gql`
  fragment TaskFolderTemplateFields on TaskFolderTemplate {
    id
    name
    updated_at
  }
`;
export const TaskFolderTemplatesDocument = gql`
  query TaskFolderTemplates(
    $filter: TaskFolderTemplatesFilter!
    $pagination: PaginationInput!
    $sort: TaskFolderTemplatesSort!
  ) {
    taskFolderTemplates(filter: $filter, pagination: $pagination, sort: $sort) {
      data {
        ...TaskFolderTemplateFields
      }
      paginatorInfo {
        total
      }
    }
  }
  ${TaskFolderTemplateFieldsFragmentDoc}
`;

export function useTaskFolderTemplatesQuery(
  options: Omit<Urql.UseQueryArgs<TaskFolderTemplatesQueryVariables>, 'query'>,
) {
  return Urql.useQuery<TaskFolderTemplatesQuery, TaskFolderTemplatesQueryVariables>({
    query: TaskFolderTemplatesDocument,
    ...options,
  });
}
