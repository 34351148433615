import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import { QueryContactFieldsFragmentDoc } from './contact_types.generated';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ContactQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;

export type ContactQuery = {
  __typename?: 'Query';
  contact?: {
    __typename?: 'Contact';
    client_location_id: string;
    first_name?: string | null;
    name?: string | null;
    street?: string | null;
    street_no?: string | null;
    postcode?: string | null;
    city?: string | null;
    country_id?: string | null;
    fax_number?: string | null;
    phone?: string | null;
    phone2?: string | null;
    mobile?: string | null;
    mobile2?: string | null;
    email?: string | null;
    email2?: string | null;
    iban?: string | null;
    bic?: string | null;
    longitude?: number | null;
    latitude?: number | null;
    salutation_id?: string | null;
    function_id?: string | null;
    note?: string | null;
    client_location?: {
      __typename?: 'ClientLocation';
      id: string;
      client_id: string;
      different_policy_holder?: string | null;
      street?: string | null;
      street_no?: string | null;
      postcode?: string | null;
      city?: string | null;
      country: { __typename?: 'Country'; title: string };
    } | null;
    role?: { __typename?: 'ContactRole'; title: string } | null;
    salutation?: { __typename?: 'Salutation'; id: string; title: string } | null;
    function?: { __typename?: 'ContactFunction'; id: string; title: string } | null;
  } | null;
};

export const ContactDocument = gql`
  query Contact($id: ID!) {
    contact(id: $id) {
      ...QueryContactFields
    }
  }
  ${QueryContactFieldsFragmentDoc}
`;

export function useContactQuery(options: Omit<Urql.UseQueryArgs<ContactQueryVariables>, 'query'>) {
  return Urql.useQuery<ContactQuery, ContactQueryVariables>({ query: ContactDocument, ...options });
}
