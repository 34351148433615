import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type UpsertClientBankingMutationVariables = Types.Exact<{
  client_id: Types.Scalars['ID'];
  input: Types.ClientBankingInput;
}>;

export type UpsertClientBankingMutation = {
  __typename?: 'Mutation';
  upsertClientBanking?: {
    __typename?: 'Client';
    client_banking?: {
      __typename?: 'ClientBanking';
      bank_name?: string | null;
      iban?: string | null;
      account_number?: string | null;
      bank_code?: string | null;
      payout_note?: string | null;
      city?: string | null;
      note?: string | null;
      payment_target_days?: number | null;
      postcode?: string | null;
      street?: string | null;
      use_alternate_address?: boolean | null;
    } | null;
  } | null;
};

export const UpsertClientBankingDocument = gql`
  mutation UpsertClientBanking($client_id: ID!, $input: ClientBankingInput!) {
    upsertClientBanking(client_id: $client_id, input: $input) {
      client_banking {
        bank_name
        iban
        account_number
        bank_code
        payout_note
        city
        bank_name
        note
        payment_target_days
        postcode
        street
        use_alternate_address
      }
    }
  }
`;

export function useUpsertClientBankingMutation() {
  return Urql.useMutation<UpsertClientBankingMutation, UpsertClientBankingMutationVariables>(
    UpsertClientBankingDocument,
  );
}
