import { useMemo } from 'react';

import { useSalutationsQuery } from '../graphql';
import { SalutationsQuery } from '../graphql/generated/useSalutationsQuery.generated';

export type SalutationItem = SalutationsQuery['salutations'][number];

export function useSalutations() {
  const [{ data, fetching: isLoading }] = useSalutationsQuery();
  const salutations = useMemo(() => data?.salutations ?? [], [data?.salutations]);
  return { salutations, isLoading };
}
