import { isString } from '@module/shared/helpers';
import { parseISO } from 'date-fns';
import { get } from 'lodash';
import { useMemo } from 'react';

export function useDateField(data: unknown, field: string | [string]) {
  return useMemo(() => {
    const value = get(data, field);
    if (isString(value)) {
      return parseISO(value);
    }
    return undefined;
  }, [data, field]);
}
