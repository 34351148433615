import { FormRenderProps } from '@progress/kendo-react-form';
import { useMemo } from 'react';
import useDeepCompareEffect from 'use-deep-compare-effect';

import { useFormStateContext } from '../context';

export const FormSpy = (props: FormRenderProps) => {
  const formStateContext = useFormStateContext();
  const { errors, valid, touched, visited, modified, submitted, allowSubmit } = props;

  const formStateNew = useMemo(
    () => ({
      errors,
      valid,
      touched,
      visited,
      modified,
      submitted,
      allowSubmit,
    }),
    [errors, valid, touched, visited, modified, submitted, allowSubmit],
  );

  useDeepCompareEffect(() => {
    formStateContext?.setFormState(formStateNew);
  }, [formStateContext, formStateNew]);

  return null;
};
