import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type TaskStateFieldsFragment = {
  __typename?: 'TaskState';
  id: string;
  title: string;
  color: string;
  state_id: Types.TaskStateEnum;
};

export type TaskStatesQueryVariables = Types.Exact<{ [key: string]: never }>;

export type TaskStatesQuery = {
  __typename?: 'Query';
  taskStates: Array<{
    __typename?: 'TaskState';
    id: string;
    title: string;
    color: string;
    state_id: Types.TaskStateEnum;
  }>;
};

export const TaskStateFieldsFragmentDoc = gql`
  fragment TaskStateFields on TaskState {
    id
    title
    color
    state_id
  }
`;
export const TaskStatesDocument = gql`
  query TaskStates {
    taskStates {
      ...TaskStateFields
    }
  }
  ${TaskStateFieldsFragmentDoc}
`;

export function useTaskStatesQuery(
  options?: Omit<Urql.UseQueryArgs<TaskStatesQueryVariables>, 'query'>,
) {
  return Urql.useQuery<TaskStatesQuery, TaskStatesQueryVariables>({
    query: TaskStatesDocument,
    ...options,
  });
}
