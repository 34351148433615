import { Fragment } from 'react';

export const Nl2Br = ({ text }: { text: string }) => (
  <>
    {text.split('\n').map((line, index, lines) => (
      <Fragment key={index}>
        {line}
        {index < lines.length - 1 && <br />}
      </Fragment>
    ))}
  </>
);
