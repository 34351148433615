export default {
  toolbar: {
    close: 'Exit',
  },
  labels: {
    you: 'You',
    participantsEmpty: 'Waiting for more participants',
  },
  dialogues: {
    update: {
      join: 'Join',
    },
    location: {
      title: 'Location',
    },
    pdf: {
      title: 'Display document',
    },
  },
  create: {
    title: 'Create Meeting',
    start: 'Create Meeting',
    labels: {
      type: 'Job type',
      phoneNumber: 'phone number',
      date: 'Date/Time',
      duration: 'appointment duration',
      customerName: 'customer name',
      phoneNumberPrefix: 'area code',
      customerMail: 'e-mail',
      notes: 'Notes',
      allowUpload: 'allow upload',
      hasVideo: 'with video',
      sendEmail: 'send invitation directly to this email',
      sendSMS: 'send invitation directly to this phone number',
    },
    tooltips: {
      notes: 'Enter a note for the customer here. This will be displayed in his customer portal',
    },
  },
  pages: {
    meeting: {
      documents: {
        note: 'The organizer has provided a document for you. You can open the document by clicking on the image and, if necessary, sign it.',
      },
      form: {
        note: 'The organizer has requested answers to the following questions. Please answer these questions truthfully and then click send at the end of the form.',
        hasForm: 'You have already filled the form',
      },
      dialogs: {
        submitForm: {
          title: 'Submit form',
          description:
            'Do you really want to send the form? By doing so, you send the form, no further change is possible. Do you want to continue?',
        },
      },
      notifications: {
        form: {
          success: 'Form sent!',
          error: 'Error sending form',
        },
      },
      pageTitle: 'Video meeting',
      title: 'Damage overview',
      headlines: {
        schedule: 'schedule',
        documents: 'Documents',
        images: 'Image upload',
        form: 'Form',
      },
      labels: {
        owner: 'Contact person',
        contact: 'Contact',
        customer: 'Customer',
        number: 'claim number',
        notes: 'Notes',
        date: 'Video appointment',
        join: 'Join the video room now',
        step: {
          first: 'The job was successfully created',
          second: 'Video appointment',
          third: 'Damage check & completion',
        },
        uploadImages: 'Upload images',
        submitForm: 'Submit form',
      },
      teaser: {
        images:
          'The organizer has allowed image upload for you. Please upload meaningful images about the event if you are asked to do so.',
      },
    },
    call: {
      screenshot: 'Take photo',
      screenshotHeadline: 'Photos',
      messages: {
        screenshotDeleted: 'Photo deleted',
      },
      permissionError: {
        title: 'Permission error',
        microphone: {
          title: 'Microphone permission denied',
          permissionDenied: 'Please allow access to your microphone',
        },
        camera: {
          title: 'Camera permission denied',
          permissionDenied: 'Please allow access to your camera',
        },
        geolocation: {
          title: 'Location permission denied',
          permissionDenied: 'Please allow access to your location.',
          positionUnavailable: 'Your location could not be determined. Please try again',
          timeout: 'There was a problem finding your location. Please try again',
        },
      },
    },
  },
  dialogs: {
    update: {
      join: 'Join',
      title: 'Edit meeting',
    },
    location: {
      title: 'Location',
    },
    pdf: {
      title: 'Show document',
    },
    create: {
      title: 'Create meeting',
      start: 'Create meeting',
      note: 'Here you can create a new meeting.',
      labels: {
        submitForm: 'Submit form',
        type: 'Order type',
        phoneNumber: 'Phone number',
        date: 'Date/Time',
        duration: 'Term duration',
        customerName: 'Customer name',
        phoneNumberPrefix: 'Area code',
        customerMail: 'E-mail',
        notes: 'Notes',
        allowUpload: 'Allow customers to upload their own photos via the invitation link.',
        allowForm: 'Allow customers to answer form questions',
        hasVideo: 'With video',
        sendEmail: 'Send invitation directly to this email',
        sendSMS: 'Send invitation directly to this phone number',
      },
      tooltips: {
        notes: 'Enter a note for the customer here. This will be displayed in his customer portal',
      },
    },
  },
};
