import {
  Popover as KendoPopover,
  PopoverProps as KendoPopoverProps,
} from '@progress/kendo-react-tooltip';
import { useRef } from 'react';

// Because of accessibility reasons (tab order) we need to render the popover in place.
// Kendo default is to render to the body element.
// If you want to render the popover to the body element, you can use appendBody={document.body}
type PopoverProps = KendoPopoverProps;

export const Popover = (props: PopoverProps) => {
  const appenToRef = useRef<HTMLDivElement>(null);
  if (props.appendTo) return <KendoPopover {...props} />;
  return (
    <>
      <div ref={appenToRef} />
      <KendoPopover appendTo={appenToRef.current} {...props} />
    </>
  );
};
