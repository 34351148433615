import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import { NotificationFieldsFragmentDoc } from './notificationFragment.generated';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type NotificationsQueryVariables = Types.Exact<{ [key: string]: never }>;

export type NotificationsQuery = {
  __typename?: 'Query';
  notifications?: Array<{
    __typename?: 'Notification';
    id: string;
    client_id: string;
    title: string;
    from_client_id?: string | null;
    read: boolean;
    created_at: string;
    task_id?: string | null;
    admin: boolean;
    from_client?: { __typename?: 'Client'; id: string; name: string } | null;
  }> | null;
};

export const NotificationsDocument = gql`
  query Notifications {
    notifications {
      ...NotificationFields
    }
  }
  ${NotificationFieldsFragmentDoc}
`;

export function useNotificationsQuery(
  options?: Omit<Urql.UseQueryArgs<NotificationsQueryVariables>, 'query'>,
) {
  return Urql.useQuery<NotificationsQuery, NotificationsQueryVariables>({
    query: NotificationsDocument,
    ...options,
  });
}
