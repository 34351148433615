import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import { SubformClassificationTagFragmentDoc } from '../../../classification-tags/graphql/generated/subformClassificationTagFragment.generated';
export type AdditionalTaskFormFragment = {
  __typename?: 'TaskForm';
  id: string;
  updated_at: string;
  title: string;
  sort_index: number;
  removed_at?: string | null;
  type: { __typename?: 'TaskFormType'; id: string; type_id: Types.TaskFormTypeId };
  template: {
    __typename?: 'TaskTemplate';
    id: string;
    title: string;
    description?: string | null;
    type_id: Types.TaskTemplateTypeId;
  };
  tags?: Array<{
    __typename?: 'Tag';
    id: string;
    title: string;
    color: string;
    type_id: string;
    type: { __typename?: 'TagType'; id: string; title: string; color: string };
  }> | null;
};

export const AdditionalTaskFormFragmentDoc = gql`
  fragment AdditionalTaskForm on TaskForm {
    id
    updated_at
    title
    type {
      id
      type_id
    }
    template {
      id
      title
      description
      type_id
    }
    sort_index
    tags {
      ...SubformClassificationTag
    }
    removed_at
  }
  ${SubformClassificationTagFragmentDoc}
`;
