import { NotificationType, useNotifications } from '@module/shared/notifications';
import { Loader } from '@progress/kendo-react-indicators';
import { TextAreaHandle } from '@progress/kendo-react-inputs';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useClient } from 'urql';

import { AudioInput, streamAudioToWebSocket } from '../helpers';

interface CursorPos {
  start: number;
  end: number;
}

export const useTranscribe = ({
  onTranscribe,
}: {
  onTranscribe: (params: {
    text: string;
    transcribedText: string;
    initialText?: string | null;
  }) => void;
}) => {
  const [recording, setRecording] = useState<boolean>(false);
  const audioInputRef = useRef<AudioInput | null>(null);
  const [initialized, setInitalized] = useState<boolean>(false);
  const { i18n } = useTranslation();
  const client = useClient();
  const listeningNotificationRef = useRef<string | null>(null);
  const textAreaRef = useRef<TextAreaHandle>(null);
  const cursorPosRef = useRef<CursorPos>({
    start: 0,
    end: 0,
  });

  const setCursorPos = useCallback((cursorPos: CursorPos) => {
    cursorPosRef.current = cursorPos;
  }, []);

  const { showNotification, hideNotification } = useNotifications();
  const { t } = useTranslation();

  const hideListeningNotification = () => {
    if (!listeningNotificationRef.current) {
      return;
    }

    hideNotification(listeningNotificationRef.current);
  };

  const stopRecording = () => {
    audioInputRef.current?.stop();
    hideListeningNotification();
    setRecording(false);
    setInitalized(false);
  };

  const handleError = () => {
    stopRecording();
    showNotification(t('common.errors.transcribe'), NotificationType.Error);
  };

  const startRecording = (initialText?: string | null) => {
    if (recording || !('mediaDevices' in navigator)) {
      return;
    }

    setRecording(true);
    setInitalized(false);

    navigator.mediaDevices
      .getUserMedia({ video: false, audio: true })
      .then((stream) => {
        let currentTranscriptIndex = 0;
        const currentTranscriptionParts: string[] = [];
        return streamAudioToWebSocket(
          stream,
          client,
          i18n.resolvedLanguage,
          (data) => {
            if (data.Alternatives.length > 0) {
              currentTranscriptionParts[currentTranscriptIndex] = data.Alternatives[0].Transcript;
              // If textarea ref is set the cursor pos is used
              const newText =
                textAreaRef.current && textAreaRef.current.element.current
                  ? [
                      (initialText || '').slice(0, cursorPosRef.current.start),
                      ...currentTranscriptionParts,
                      (initialText || '').slice(cursorPosRef.current.end),
                    ]
                      .filter(Boolean)
                      .join('')
                  : [initialText, ...currentTranscriptionParts].filter(Boolean).join('');
              onTranscribe({
                text: newText,
                transcribedText: currentTranscriptionParts.join(' '),
                initialText,
              });
            }
            if (!data.IsPartial) {
              currentTranscriptIndex += 1;
            }
          },
          () => {
            listeningNotificationRef.current = showNotification(
              <div>
                <Loader /> {t('common.transcribing.message')}
              </div>,
              NotificationType.Info,
              0,
              false,
            );
            const notificationAudio = document.getElementById('notification-sound');
            if (notificationAudio && notificationAudio instanceof HTMLAudioElement) {
              notificationAudio.volume = 0.1;
              notificationAudio.play();
            }
            setInitalized(true);

            if (textAreaRef.current && textAreaRef.current.element.current) {
              setCursorPos({
                start: textAreaRef.current.element.current.selectionStart,
                end: textAreaRef.current.element.current.selectionEnd,
              });
            }
          },
          hideListeningNotification,
          handleError,
        );
      })
      .then((input) => {
        audioInputRef.current = input;
      })
      .catch(handleError);
  };

  useEffect(
    () => () => {
      audioInputRef.current?.stop();
    },
    [],
  );

  return {
    recording,
    startRecording,
    stopRecording,
    initialized,
    setCursorPos,
    textAreaRef,
  };
};
