import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type DeleteTaskFilesMutationVariables = Types.Exact<{
  task_id: Types.Scalars['ID'];
  file_ids: Array<Types.Scalars['ID']> | Types.Scalars['ID'];
}>;

export type DeleteTaskFilesMutation = {
  __typename?: 'Mutation';
  deleteTaskFiles: Array<{ __typename?: 'FileItem'; id: string }>;
};

export const DeleteTaskFilesDocument = gql`
  mutation DeleteTaskFiles($task_id: ID!, $file_ids: [ID!]!) {
    deleteTaskFiles(task_id: $task_id, file_ids: $file_ids) {
      id
    }
  }
`;

export function useDeleteTaskFilesMutation() {
  return Urql.useMutation<DeleteTaskFilesMutation, DeleteTaskFilesMutationVariables>(
    DeleteTaskFilesDocument,
  );
}
