import { FormValidatorType, KeyValue } from '@progress/kendo-react-form';
import { useCallback } from 'react';
import { AnyObject, date, ObjectSchema, StringSchema, ValidationError } from 'yup';

import i18n from '../../../../localization';
import { MessageWithParams } from '../../../../localization/yup';

const translateMessage = (message: string | MessageWithParams) =>
  typeof message === 'string' ? i18n.t(message) : i18n.t(message.key, message.params);

export const yupToKendoErrors = (yupError: ValidationError): KeyValue<string> => {
  const errors: KeyValue<string> = {};

  if (yupError.inner) {
    if (yupError.inner.length === 0 && yupError.path) {
      errors[yupError.path] = translateMessage(yupError.message);

      return errors;
    }

    for (const error of yupError.inner) {
      if (error.path && !errors[error.path]) {
        errors[error.path] = translateMessage(error.message);
      }
    }
  }

  return errors;
};

export function createSchemaValidator<TSchema extends ObjectSchema<AnyObject>>(
  schema: TSchema,
): FormValidatorType {
  return (values) => {
    try {
      schema.validateSync(values, { abortEarly: false });
    } catch (error) {
      if (error instanceof ValidationError) {
        return yupToKendoErrors(error);
      }

      throw error;
    }

    return {};
  };
}

export function useSchemaValidator<TSchema extends ObjectSchema<AnyObject>>(
  schema: TSchema,
): FormValidatorType {
  return useCallback(
    (values) => {
      try {
        schema.validateSync(values, { abortEarly: false });
      } catch (error: unknown) {
        if (error instanceof ValidationError) {
          return yupToKendoErrors(error);
        }

        throw error;
      }

      return {};
    },
    [schema],
  );
}

// Yup can validate both `Date` objects and ISO date strings. Unfortunately, type casting is
// necessary because the inferred type is always a `Date` object.
export const isoDateStringSchema = date() as unknown as StringSchema;
