import { useMemo } from 'react';

import { SubformClassificationTagFragment, useSubformClassificationTagsAllQuery } from '../graphql';

export const useSubformClassificationTags = () => {
  const [{ data }] = useSubformClassificationTagsAllQuery();

  return useMemo<SubformClassificationTagFragment[]>(
    () => data?.tags.data ?? [],
    [data?.tags.data],
  );
};
