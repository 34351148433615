import { email } from '@module/shared/helpers';
import { InferType, object, string } from 'yup';

import {
  EmailContact,
  EmailContactInput,
  EmailContactType,
} from '../../../types/graphql.generated';
import { PartialNullable } from '../types';

export const emailSelectItemSchema = object().shape({
  email: email().required(),
  name: string().nullable(),
});

export type EmailSelectItem = InferType<typeof emailSelectItemSchema>;

interface EmailSelectItemsByType {
  to: Array<EmailSelectItem>;
  cc: Array<EmailSelectItem>;
  bcc: Array<EmailSelectItem>;
}

const matchesEmailContactType =
  <TEmailContactType extends EmailContactType>(emailContactType: TEmailContactType) =>
  <TEmailContact extends Pick<EmailContact, 'type'>>(
    emailContact: TEmailContact,
  ): emailContact is TEmailContact & { type: TEmailContactType } => {
    return emailContact.type === emailContactType;
  };

const emailContactToEmailSelectItem = ({
  address,
  name,
}: Pick<EmailContact, 'address' | 'name'>): EmailSelectItem => ({ email: address, name });

export const emailContactsToEmailSelectItems = (
  emailContacts: Array<Pick<EmailContact, 'address' | 'name' | 'type'>>,
): EmailSelectItemsByType => {
  return {
    to: emailContacts
      .filter(matchesEmailContactType(EmailContactType.TO))
      .map(emailContactToEmailSelectItem),
    cc: emailContacts
      .filter(matchesEmailContactType(EmailContactType.CC))
      .map(emailContactToEmailSelectItem),
    bcc: emailContacts
      .filter(matchesEmailContactType(EmailContactType.BCC))
      .map(emailContactToEmailSelectItem),
  };
};

const emailSelectItemToEmailContactInput =
  (type: EmailContactType) =>
  ({ email, name }: EmailSelectItem): EmailContactInput => ({
    address: email,
    name: name ?? email,
    type,
  });

export const emailSelectItemsToEmailContactInputs = (
  itemsByType: PartialNullable<EmailSelectItemsByType>,
) => [
  ...(itemsByType.to ?? []).map(emailSelectItemToEmailContactInput(EmailContactType.TO)),
  ...(itemsByType.cc ?? []).map(emailSelectItemToEmailContactInput(EmailContactType.CC)),
  ...(itemsByType.bcc ?? []).map(emailSelectItemToEmailContactInput(EmailContactType.BCC)),
];
