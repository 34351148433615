import './Badge.scss';

import { classNames } from '@progress/kendo-react-common';
import { HTMLAttributes, ReactNode, useMemo } from 'react';

import { ThemeColor } from '../../../../types/graphql.generated';
import { getThemeColorBg } from '../../helpers';

export interface BadgeProps
  extends Pick<HTMLAttributes<HTMLSpanElement>, 'className' | 'style' | 'onClick' | 'title'> {
  children?: ReactNode | undefined;
  color?: string;
  backgroundColor?: string;
  backgroundThemeColor?: ThemeColor;
}

export const Badge = (props: BadgeProps) => {
  const style = useMemo(() => {
    return { ...(props?.style ?? {}), color: props.color, backgroundColor: props.backgroundColor };
  }, [props.backgroundColor, props.color, props?.style]);

  return (
    <span
      onClick={props.onClick}
      className={classNames('Badge', props?.className, getThemeColorBg(props.backgroundThemeColor))}
      style={style}
      title={props.title}
    >
      {props.children}
    </span>
  );
};

export interface ThemeBadgeProps extends HTMLAttributes<HTMLSpanElement> {
  theme: 'success' | 'warning' | 'error' | 'secondary';
}

export const ThemeBadge = ({ theme, className, ...rest }: ThemeBadgeProps) => (
  <span
    {...rest}
    className={classNames(
      'Badge',
      {
        'u-text-secondary u-bg-secondary-100': theme === 'secondary',
        'u-text-success-700 u-bg-success-50': theme === 'success',
        'u-text-warning-700 u-bg-warning-50': theme === 'warning',
        'u-text-error-700 u-bg-error-50': theme === 'error',
      },
      className,
    )}
  />
);
