import { useEffect, useState } from 'react';

export const useObjectURL = (blob: null | Blob | File) => {
  const [objectURL, setObjectURL] = useState<null | string>(null);
  useEffect(() => {
    if (!blob) {
      return;
    }
    const latestObjectURL = URL.createObjectURL(blob);
    setObjectURL(latestObjectURL);
    return () => {
      URL.revokeObjectURL(latestObjectURL);
      setObjectURL(null);
    };
  }, [blob]);
  return objectURL;
};
