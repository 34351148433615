import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type UpdateInvoiceSettingsMutationVariables = Types.Exact<{
  input: Types.InvoiceSettingsInput;
}>;

export type UpdateInvoiceSettingsMutation = {
  __typename?: 'Mutation';
  updateInvoiceSettings: {
    __typename?: 'AppSettings';
    invoice_from_name?: string | null;
    invoice_from_street?: string | null;
    invoice_from_postcode?: string | null;
    invoice_from_city?: string | null;
    invoice_from_country_id?: string | null;
    invoice_from_phone?: string | null;
    invoice_from_email?: string | null;
    invoice_from_web?: string | null;
    invoice_from_bank_title?: string | null;
    invoice_from_bank_iban?: string | null;
    invoice_from_bank_swift?: string | null;
    invoice_from_commercial_register?: string | null;
    invoice_from_ceo?: string | null;
    invoice_from_vat_title?: string | null;
    invoice_from_vat_id?: string | null;
    invoice_from_invoice_number_prefix?: string | null;
  };
};

export const UpdateInvoiceSettingsDocument = gql`
  mutation UpdateInvoiceSettings($input: InvoiceSettingsInput!) {
    updateInvoiceSettings(input: $input) {
      invoice_from_name
      invoice_from_street
      invoice_from_postcode
      invoice_from_city
      invoice_from_country_id
      invoice_from_phone
      invoice_from_email
      invoice_from_web
      invoice_from_bank_title
      invoice_from_bank_iban
      invoice_from_bank_swift
      invoice_from_commercial_register
      invoice_from_ceo
      invoice_from_vat_title
      invoice_from_vat_id
      invoice_from_invoice_number_prefix
    }
  }
`;

export function useUpdateInvoiceSettingsMutation() {
  return Urql.useMutation<UpdateInvoiceSettingsMutation, UpdateInvoiceSettingsMutationVariables>(
    UpdateInvoiceSettingsDocument,
  );
}
