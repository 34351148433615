import {
  Editor,
  EditorChangeEvent,
  EditorMountEvent,
  EditorPasteEvent,
  EditorTools,
  EditorUtils,
  PasteCleanupSettings,
} from '@progress/kendo-react-editor';
import { Label } from '@progress/kendo-react-labels';
import { useCallback, useId, useRef } from 'react';

import { sanitizePastedHtml } from '../../hooks';
import { sanitizeInputValue } from './helpers';
import { StricterFieldRenderProps } from './types';

const { pasteCleanup, sanitize, removeAttribute } = EditorUtils;

interface EditorProps extends StricterFieldRenderProps {
  label?: string;
  blank?: boolean;
  sanitizeOnPaste?: boolean;
}

export const EditorInput = (props: EditorProps) => {
  const { value, label, onBlur, onFocus, onChange, sanitizeOnPaste } = props;
  const labelId = useId();
  const ref = useRef<Editor>(null);
  let tools = [
    [EditorTools.Bold],
    [EditorTools.Italic],
    [EditorTools.Underline],
    [EditorTools.OrderedList],
    [EditorTools.InsertTable],
    [EditorTools.AddRowBefore],
    [EditorTools.AddRowAfter],
    [EditorTools.AddColumnBefore],
    [EditorTools.AddColumnAfter],
    [EditorTools.DeleteRow],
    [EditorTools.DeleteColumn],
    [EditorTools.DeleteTable],
    [EditorTools.MergeCells],
    [EditorTools.SplitCell],
  ];
  const styles = `
       p {
            font-family: Arial,Helvetica,sans-serif;
        }
    `;
  const pasteSettings: PasteCleanupSettings = {
    convertMsLists: true,
    attributes: {
      class: removeAttribute,
      style: removeAttribute,
      '*': removeAttribute,
    },
  };
  if (props.blank) {
    pasteSettings.stripTags = 'b|h1|h2|h3';
    tools = [];
  }
  const pasteHtml = (event: EditorPasteEvent) => {
    if (sanitizeOnPaste) {
      return sanitizePastedHtml(event.pastedHtml);
    } else {
      const html = pasteCleanup(sanitize(event.pastedHtml), pasteSettings);
      return html;
    }
  };
  const valueSanitized = sanitizeInputValue(value) as string;

  const onMount = (event: EditorMountEvent) => {
    const iframeDocument = event.dom.ownerDocument;
    const style = iframeDocument.createElement('style');
    style.appendChild(iframeDocument.createTextNode(styles));
    iframeDocument.head.appendChild(style);
  };

  const handleChange = useCallback(
    (event: EditorChangeEvent) => {
      onChange({
        ...event,
        value: event.html,
      });
    },
    [onChange],
  );

  return (
    <div className="k-form-field">
      {label && <Label id={labelId}>{label}</Label>}

      <Editor
        className="k-input k-rounded-md k-input-solid"
        ariaLabelledBy={label && labelId}
        ref={ref}
        onMount={onMount}
        tools={tools}
        defaultContent={valueSanitized ?? ''}
        onBlur={onBlur}
        onPasteHtml={pasteHtml}
        onChange={handleChange}
        onFocus={onFocus}
      />
    </div>
  );
};
