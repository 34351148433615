import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import { InterfaceMessageTemplateFragmentDoc } from './interfaceMessageTemplateFragment.generated';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type UpdateInterfaceMessageTemplateMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  input: Types.InterfaceMessageTemplateInput;
}>;

export type UpdateInterfaceMessageTemplateMutation = {
  __typename?: 'Mutation';
  updateInterfaceMessageTemplate?: {
    __typename?: 'InterfaceMessageTemplate';
    id: string;
    title: string;
    description?: string | null;
    message_type?: string | null;
    subject?: string | null;
    body?: string | null;
    category?: {
      __typename?: 'InterfaceMessageTemplateCategory';
      id: string;
      title: string;
    } | null;
    assignments: Array<{ __typename?: 'Client'; id: string; name: string }>;
  } | null;
};

export const UpdateInterfaceMessageTemplateDocument = gql`
  mutation UpdateInterfaceMessageTemplate($id: ID!, $input: InterfaceMessageTemplateInput!) {
    updateInterfaceMessageTemplate(id: $id, input: $input) {
      ...InterfaceMessageTemplate
    }
  }
  ${InterfaceMessageTemplateFragmentDoc}
`;

export function useUpdateInterfaceMessageTemplateMutation() {
  return Urql.useMutation<
    UpdateInterfaceMessageTemplateMutation,
    UpdateInterfaceMessageTemplateMutationVariables
  >(UpdateInterfaceMessageTemplateDocument);
}
