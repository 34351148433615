import { useMemo } from 'react';

import { useAppSettingsPublicQuery } from '../graphql';

const defaultSettings = { minLength: 8, minSpecial: 1, minUpperCase: 1, minNumbers: 1 };

export interface PasswordSettings {
  minLength: number;
  minSpecial: number;
  minUpperCase: number;
  minNumbers: number;
}

export function usePasswordSettings(): PasswordSettings {
  const [{ data }] = useAppSettingsPublicQuery();

  return useMemo<PasswordSettings>(() => {
    return {
      minLength: data ? data.appSettingsPublic.auth_password_min_length : defaultSettings.minLength,
      minSpecial: data
        ? data.appSettingsPublic.auth_password_min_special
        : defaultSettings.minSpecial,
      minUpperCase: data
        ? data.appSettingsPublic.auth_password_min_upper_case
        : defaultSettings.minUpperCase,
      minNumbers: data
        ? data.appSettingsPublic.auth_password_min_numbers
        : defaultSettings.minNumbers,
    };
  }, [data]);
}
