import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import { SubformClassificationTagFragmentDoc } from './subformClassificationTagFragment.generated';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type SubformClassificationTagsQueryVariables = Types.Exact<{
  filter: Types.TagsFilter;
  sort: Types.TagsSort;
  pagination: Types.PaginationInput;
}>;

export type SubformClassificationTagsQuery = {
  __typename?: 'Query';
  tags: {
    __typename?: 'TagPaginator';
    data: Array<{
      __typename?: 'Tag';
      id: string;
      title: string;
      color: string;
      type_id: string;
      type: { __typename?: 'TagType'; id: string; title: string; color: string };
    }>;
    paginatorInfo: { __typename?: 'PaginatorInfo'; total: number };
  };
};

export const SubformClassificationTagsDocument = gql`
  query SubformClassificationTags(
    $filter: TagsFilter!
    $sort: TagsSort!
    $pagination: PaginationInput!
  ) {
    tags(filter: $filter, sort: $sort, pagination: $pagination) {
      data {
        ...SubformClassificationTag
      }
      paginatorInfo {
        total
      }
    }
  }
  ${SubformClassificationTagFragmentDoc}
`;

export function useSubformClassificationTagsQuery(
  options: Omit<Urql.UseQueryArgs<SubformClassificationTagsQueryVariables>, 'query'>,
) {
  return Urql.useQuery<SubformClassificationTagsQuery, SubformClassificationTagsQueryVariables>({
    query: SubformClassificationTagsDocument,
    ...options,
  });
}
