import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type TaskTagFragment = { __typename?: 'TaskTag'; key: string; title: string };

export type TaskTagsQueryVariables = Types.Exact<{ [key: string]: never }>;

export type TaskTagsQuery = {
  __typename?: 'Query';
  taskTags: Array<{ __typename?: 'TaskTag'; key: string; title: string }>;
};

export const TaskTagFragmentDoc = gql`
  fragment TaskTag on TaskTag {
    key
    title
  }
`;
export const TaskTagsDocument = gql`
  query TaskTags {
    taskTags {
      ...TaskTag
    }
  }
  ${TaskTagFragmentDoc}
`;

export function useTaskTagsQuery(
  options?: Omit<Urql.UseQueryArgs<TaskTagsQueryVariables>, 'query'>,
) {
  return Urql.useQuery<TaskTagsQuery, TaskTagsQueryVariables>({
    query: TaskTagsDocument,
    ...options,
  });
}
