import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type DeleteTaskClassificationTagsMutationVariables = Types.Exact<{
  taskIds: Array<Types.Scalars['ID']> | Types.Scalars['ID'];
}>;

export type DeleteTaskClassificationTagsMutation = {
  __typename?: 'Mutation';
  deleteTaskClassificationTags: {
    __typename?: 'TasksBatchResponseMessage';
    success: boolean;
    message: string;
  };
};

export const DeleteTaskClassificationTagsDocument = gql`
  mutation DeleteTaskClassificationTags($taskIds: [ID!]!) {
    deleteTaskClassificationTags(task_ids: $taskIds) {
      success
      message
    }
  }
`;

export function useDeleteTaskClassificationTagsMutation() {
  return Urql.useMutation<
    DeleteTaskClassificationTagsMutation,
    DeleteTaskClassificationTagsMutationVariables
  >(DeleteTaskClassificationTagsDocument);
}
