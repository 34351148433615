import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export function useDropDownListDefaultItem(
  idField = 'id',
  textField = 'text',
  defaultValue: number | string | undefined | null = 0,
  defaultLabel = 'common.labels.defaultItem',
) {
  const { t } = useTranslation();
  return useMemo(() => {
    return {
      [idField]: defaultValue,
      [textField]: t(defaultLabel),
    };
  }, [defaultLabel, defaultValue, idField, t, textField]);
}
