import { DropDownList, DropDownListChangeEvent } from '@progress/kendo-react-dropdowns';
import { GridFilterCellProps } from '@progress/kendo-react-grid';
import { Loader } from '@progress/kendo-react-indicators';
import { useCallback, useMemo } from 'react';

import { CountryItem, useCountries, useDropDownListDefaultItem } from '../../hooks';

export const CountryFilterCell = (props: GridFilterCellProps) => {
  const { onChange, value } = props;
  const { countries, isLoading } = useCountries();
  const defaultItem = useDropDownListDefaultItem(
    'id',
    'title',
    0,
    'common.labels.defaultItemAllCountries',
  );

  const country = useMemo(
    () => countries.find((country) => country.id === value),
    [countries, value],
  );

  const handleChange = useCallback(
    (event: DropDownListChangeEvent) => {
      const valueAsItem = event.value as CountryItem;
      if (valueAsItem.id === defaultItem.id) {
        onChange({ value: valueAsItem.id, operator: 'gt', syntheticEvent: event.syntheticEvent });
      } else {
        onChange({ value: valueAsItem.id, operator: 'eq', syntheticEvent: event.syntheticEvent });
      }
    },
    [defaultItem.id, onChange],
  );

  return (
    <div className={'k-filtercell'}>
      <DropDownList
        data={countries}
        dataItemKey="id"
        textField="title"
        defaultItem={defaultItem}
        value={country}
        onChange={handleChange}
      />
      {isLoading && (
        <Loader
          type="converging-spinner"
          size={'small'}
          style={{ position: 'absolute', right: '2rem', top: '50%', marginTop: '-0.5rem' }}
        />
      )}
    </div>
  );
};
