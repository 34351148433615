import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type UpdateTaskFormQuestionCommentMutationVariables = Types.Exact<{
  task_form_id: Types.Scalars['ID'];
  question_id: Types.Scalars['String'];
  comment: Types.Scalars['String'];
}>;

export type UpdateTaskFormQuestionCommentMutation = {
  __typename?: 'Mutation';
  updateTaskFormQuestionComment: {
    __typename?: 'TaskQuestion';
    id: string;
    comment?: string | null;
  };
};

export const UpdateTaskFormQuestionCommentDocument = gql`
  mutation UpdateTaskFormQuestionComment(
    $task_form_id: ID!
    $question_id: String!
    $comment: String!
  ) {
    updateTaskFormQuestionComment(
      task_form_id: $task_form_id
      question_id: $question_id
      comment: $comment
    ) {
      id
      comment
    }
  }
`;

export function useUpdateTaskFormQuestionCommentMutation() {
  return Urql.useMutation<
    UpdateTaskFormQuestionCommentMutation,
    UpdateTaskFormQuestionCommentMutationVariables
  >(UpdateTaskFormQuestionCommentDocument);
}
