import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
export type ClientAbsenceFieldsFragment = {
  __typename?: 'ClientAbsence';
  client_id: string;
  from: string;
  id: string;
  reason: string;
  to: string;
};

export const ClientAbsenceFieldsFragmentDoc = gql`
  fragment ClientAbsenceFields on ClientAbsence {
    client_id
    from
    id
    reason
    to
  }
`;
