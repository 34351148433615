import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
export type AppMessageFragment = {
  __typename?: 'AppMessage';
  id: string;
  title: string;
  body: string;
  type: Types.PageNotificationType;
  author_id: string;
  active: boolean;
  visible_from?: string | null;
  visible_to?: string | null;
  author?: { __typename?: 'Client'; id: string; name: string } | null;
};

export const AppMessageFragmentDoc = gql`
  fragment AppMessage on AppMessage {
    id
    title
    body
    type
    author_id
    author {
      id
      name
    }
    active
    visible_from
    visible_to
  }
`;
