import './DefinitionList.scss';

import { classNames } from '@progress/kendo-react-common';
import { HTMLAttributes, ReactNode } from 'react';

interface DefinitionListProps extends HTMLAttributes<HTMLDListElement> {
  layoutPreset?: '3-cols-responsive';
}

export const DefinitionList = ({ className, layoutPreset, ...rest }: DefinitionListProps) => (
  <dl
    {...rest}
    className={classNames(
      'DefinitionList',
      layoutPreset === '3-cols-responsive' &&
        'row row-cols-sm-2 row-cols-xl-3 g-5 gx-xl-12 gy-xl-6',
      className,
    )}
  />
);

interface DefinitionListItemProps extends HTMLAttributes<HTMLDivElement> {
  label: ReactNode;
}

export const DefinitionListItem = (props: DefinitionListItemProps) => {
  const { children, label, className, ...rest } = props;

  return (
    <div {...rest} className={classNames('DefinitionListItem', className)}>
      <dt>{label}</dt>
      <dd>{children}</dd>
    </div>
  );
};
