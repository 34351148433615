import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type UpdateClientQualificationsMutationVariables = Types.Exact<{
  client_id: Types.Scalars['ID'];
  qualification_ids: Array<Types.Scalars['ID']> | Types.Scalars['ID'];
}>;

export type UpdateClientQualificationsMutation = {
  __typename?: 'Mutation';
  updateClientQualifications: {
    __typename?: 'Client';
    qualifications: {
      __typename?: 'ClientQualificationList';
      data: Array<{ __typename?: 'Qualification'; title: string }>;
    };
  };
};

export const UpdateClientQualificationsDocument = gql`
  mutation UpdateClientQualifications($client_id: ID!, $qualification_ids: [ID!]!) {
    updateClientQualifications(client_id: $client_id, qualification_ids: $qualification_ids) {
      qualifications {
        data {
          title
        }
      }
    }
  }
`;

export function useUpdateClientQualificationsMutation() {
  return Urql.useMutation<
    UpdateClientQualificationsMutation,
    UpdateClientQualificationsMutationVariables
  >(UpdateClientQualificationsDocument);
}
