import { MultiColumnComboBox, MultiColumnComboBoxProps } from '@progress/kendo-react-dropdowns';
import { FieldWrapper } from '@progress/kendo-react-form';
import { omit } from 'lodash';

import { Label } from '../components/Label';
import { CommonFieldRenderProps, FieldError, FieldHint } from '../inputs';

type MultiColumnComboBoxFieldProps = CommonFieldRenderProps &
  Omit<MultiColumnComboBoxProps, 'validationMessage'>;

const omitFieldRenderProps = <TProps extends CommonFieldRenderProps>(props: TProps) =>
  omit(props, ['label', 'modified', 'touched', 'valid', 'validationMessage', 'visited']);

export const MultiColumnComboBoxField = (props: MultiColumnComboBoxFieldProps) => {
  const { id, label, size = 'large', valid, name } = props;

  return (
    <FieldWrapper>
      <Label label={label} name={name} editorId={id} editorValid={valid}>
        {label}
      </Label>

      <MultiColumnComboBox size={size} {...omitFieldRenderProps(props)} />
      <div className="HintAndError">
        <FieldHint {...props} />
        <FieldError {...props} />
      </div>
    </FieldWrapper>
  );
};
