import './Ripple.scss';

import { Checkbox as KendoCheckbox, CheckboxProps } from '@progress/kendo-react-inputs';
import { Ripple } from '@progress/kendo-react-ripple';

export const Checkbox = (props: CheckboxProps) => {
  return (
    <Ripple className="Ripple">
      <KendoCheckbox {...props} />
    </Ripple>
  );
};
