import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import { CalculationItemFragmentDoc } from './taskCalculationItemFragment.generated';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CreateTaskCalculationItemMutationVariables = Types.Exact<{
  task_calculation_id: Types.Scalars['ID'];
  input: Types.TaskCalculationItemInput;
}>;

export type CreateTaskCalculationItemMutation = {
  __typename?: 'Mutation';
  createTaskCalculationItem: {
    __typename?: 'TaskCalculationItem';
    id: string;
    title?: string | null;
    description?: string | null;
    accepted?: number | null;
    amount?: number | null;
    single_price?: number | null;
    sort_index?: number | null;
    time_value?: number | null;
    age?: number | null;
    correction_condition?: number | null;
    max_usage_years?: number | null;
    tax: number;
    own_contribution: boolean;
    state?: {
      __typename?: 'TaskCalculationItemState';
      id: string;
      title: string;
      color: string;
    } | null;
    payout_state?: {
      __typename?: 'TaskCalculationItemPayoutState';
      id: string;
      title: string;
      color: string;
    } | null;
    unit?: { __typename?: 'ServiceCatalogUnit'; id: string; title: string } | null;
    category?: {
      __typename?: 'TaskCalculationCategory';
      id: string;
      title: string;
      sort_index: number;
    } | null;
  };
};

export const CreateTaskCalculationItemDocument = gql`
  mutation CreateTaskCalculationItem($task_calculation_id: ID!, $input: TaskCalculationItemInput!) {
    createTaskCalculationItem(task_calculation_id: $task_calculation_id, input: $input) {
      ...CalculationItem
    }
  }
  ${CalculationItemFragmentDoc}
`;

export function useCreateTaskCalculationItemMutation() {
  return Urql.useMutation<
    CreateTaskCalculationItemMutation,
    CreateTaskCalculationItemMutationVariables
  >(CreateTaskCalculationItemDocument);
}
