export default {
  components: {
    templateDesignerToolbar: {
      title: 'Dizajner predložaka',
      buttons: {
        designer: 'Dizajner',
        grid: 'Tablica',
        json: 'JSON',
        debug: 'Debug',
      },
      extended: {
        import: 'Uvoz iz Rocketform-a',
        internalActivate: 'Omogućite sva pitanja za interno izvješćivanje',
        internalDeactivate: 'Onemogući sva pitanja za interno izvješćivanje',
        customerActivate: 'Omogućite sva pitanja za izvješće korisnika',
        customerDeactivate: 'Onemogući sva pitanja za izvješće korisnika',
      },
      saveStatus: {
        saved: 'spremljeno',
        unsaved: 'Nespremljene promjene',
      },
    },
    templateRenderer: {
      noRecords: 'Nije pronađen nijedan unos za vašu prretragu',
      labels: {
        title: 'Umetnite naslov H1 za početak novog obrasca.',
      },
    },
    templatePreview: {
      title: 'Pregled',
      description: 'Ovo je pregled maske unosa u zahtjevima',
      table: {
        addNew: 'Novi unos',
        saveDefaultValues: 'Spremi zadane postavke',
        deleteRowConfirm: {
          title: 'Izbrisati unos?',
          description: 'Želite li stvarno izbrisati ovaj unos?',
        },
      },
      alert: 'Širina tablice mora biti 100%, provjerite svoje unose na lijevoj strani.',
      setTableDefaultValuesSuccess: 'Zadane vrijednosti su spremljene',
      maxLength: 'Tekst može imati najviše {{length}} znakova.',
    },
    toolbar: {
      textField: 'Tekst',
      selectField: 'Odaberi',
      dateField: 'Datum',
      dateTimeField: 'Datum i Vrijeme',
      timeField: 'Vrijeme',
      numericField: 'Broj',
      yesNoField: 'Da/Ne',
      headlineField: 'Naslov',
      tableField: 'Tablica',
      richtextField: 'Bogati tekst',
      subHeadlineField: 'podnaslov',
      paste: 'zalijepi kopiju',
      imageField: 'Slike',
    },
    emptyDropZone: {
      title: 'Nema pronađenih unosa',
      description: 'Ovdje možete ispustiti elemente s lijeve strane kako biste izgradili obrazac.',
    },
    emptyPreview: {
      title: 'Pregled još nije moguć',
      description: 'Pregled se ovdje prikazuje čim postoje elementi u obrascu.',
    },
    assignAllTemplates: {
      title: 'Prijavite dodjelu pitanja',
      description: 'Pažnja, ovo će dodijeliti sve obrasce za sve kupce. Želite li nastaviti?',
    },
    imageColorPicker: {
      title: 'Odaberite boju',
    },
    pageBreakIndicator: {
      pageBreak: 'Prijelom stranice',
    },
  },
  dialogs: {
    templateImport: {
      title: 'Pitanja za izvješće o uvozu',
      note: 'Ovdje možete uvesti sva pitanja izvješća putem Excela',
      save: 'Uvezi',
      cancel: 'Odustani',
      jsonCode: 'Ovdje možete kopirati Rocketformov JSON kod za uvoz sadržaja obrasca.',
    },
    templateDelete: {
      title: 'Izbriši predložak pitanja?',
      note: 'Jeste li sigurni da želite izbrisati ovaj predložak pitanja??',
    },
    templateEdit: {
      title: 'Uredi predložak pitanja?',
      note: 'Želite li stvarno urediti ovaj predložak pitanja?\n' + ' Kopija će se stvoriti.',
    },
    templateUpdate: {
      reportingTitle: 'Ažurirajte predložak pitanja za izvješće',
      statisticsTitle: 'Ažuriraj predložak pitanja o statistici',
      all: 'Alle Versicherungen',
      active: 'Aktiv für diese Versicherungen',
    },
    templateCreate: {
      reportingTitle: 'Stvorite predložak pitanja za izvješće',
      statisticsTitle: 'Stvorite predložak pitanja za statistiku',
      tabs: {
        general: 'Općenito',
        assignment: 'Dodjela',
      },
      form: {
        shortcut: 'Kratica',
        identifier: 'Interni identifikator',
        color: 'Boja',
        fieldset: 'Predložak',
        active: 'Aktivan',
        types: {
          label: 'Tip',
          form: 'Predložak',
          statistic: 'Statistika',
        },
        classification: {
          label: 'Klasifikacija',
          property: 'Imovina',
          liability: 'Odgovornost',
          business: 'Poslovno',
        },
        showTimeValueCalculation: 'Izračun vremenske vrijednosti omogućen',
        category: {
          label: 'Standardna kategorija kalkulacije',
          description:
            'Ovdje možete definirati standardne kategorije kalkulacije, koje su odmah dostupne za novu kalkulaciju odabranog predloška.',
        },
        enableWordConvert: 'Dopusti preuzimanje MS Worda',
        type: 'Tip',
        sum_alias: ' sum alias',
        accepted_alias: 'Prihvaća pseudonime',
        time_value_alias: 'vremenski alias',
        status_alias: 'državni alias',
        defaultSupervisorId: 'Predefinirani vođa tima',
      },
      values: {
        form: 'Oblik',
        additional: 'podobrazac',
      },
    },
    templateAssign: {
      title: 'Promjena dodjele pitanja u izvješću.',
      note: 'Ovdje možete dodijeliti pitanja o izvješću osiguravajućim društvima.',
    },
    confirmDebug: {
      title: 'Obrazac za otklanjanje pogrešaka',
      note: 'Želite li doista svugdje u ovom obrascu dodati identifikator koji nedostaje?',
    },
  },
  grid: {
    fields: {
      title: 'Naslov',
      description: 'Opis',
      mandatory: 'Obavezno',
      identifier: 'identifikator',
      type: 'Tip',
      selectables: 'Odgovor',
    },
    toolbar: {
      exportExcel: 'Izvezi Excel',
    },
  },
  pages: {
    overview: {
      create: 'Stvori predložak',
      reportingTitle: 'Naslov izvještaja',
      statisticsTitle: 'Naslov statistike',
      reportingDescription: 'Ovdje ćete pronaći popis svih pitanja izvješća.',
      statisticsDescription: 'Ovdje ćete pronaći popis svih statističkih pitanja.',
      showInactive: 'Prikaži nekativno',
      designer: 'Dizajner',
      import: 'Uvezi',
      export: 'Izvezi',
      grid: {
        titlePostfix: 'Kopirati',
        columns: {
          id: 'ID',
          title: 'Ime',
          color: 'Boja',
          version: 'Verzija',
          stateId: 'Status',
          shortcut: 'Kratica',
          identifier: 'Interni ID',
          description: 'Opis',
          state: 'Status',
          classification: 'Klasifikacija',
          actions: 'Akcije',
          insurance: 'osiguravatelj',
          template: 'šablona',
          type: 'Tip',
          updated_at: 'posljednje promjene u',
          usage_count: 'Korištenje',
        },
        values: {
          active: 'Aktivan',
          notActive: 'Nije Aktivan',
          all: 'svi',
          none: 'Ne',
          form: 'Oblik',
          statistics: 'statistika',
          additional: 'podobrazac',
        },
      },
      assignAll: 'Dodijelite sve obrasce za sve kupce',
    },
    designer: {
      title: 'Dizajner predloška',
      viewNotificationTitle: 'Način prikaza',
      viewNotificationDescription:
        'Ovdje možete vidjeti i provjeriti predložak, ali promjene neće biti spremljene',
    },
    breadcrumbs: {
      STATISTICS: 'Statistička pitanja',
      FORM: 'pitanja za izvještavanje',
      PRIMARY: 'Oblik',
      ADDITIONAL: 'podobrazac',
    },
  },
  fields: {
    connectionActivates: 'Aktiviraj:',
    table: {
      operations: {
        none: 'Nijedan',
        sum: 'Ukupno',
        average: 'Prosječno',
      },
      types: {
        text: 'Tekst',
        number: 'Broj',
        currency: 'Valuta',
        select: 'Izbor',
      },
      cols: {
        headline: 'Naslov',
        type: {
          label: 'Tip',
        },
        operation: {
          label: 'Operacija',
        },
        name: {
          label: 'Ime',
          placeholder: 'Unesite vrijednost',
        },
        size: {
          label: 'Veličina',
        },
        selectOptions: {
          label: 'selekcijska polja',
          placeholder: 'Unesite vrijednost odvojenu točkom i zarezom',
        },
      },
      addCol: 'Dodaj kolonu',
      alert: 'Zbroj širine tablice mora iznositi 100%, provjerite svoje unose veličine.',
      tax: {
        label: 'Upravljati',
        tooltip:
          'Ovo automatski dodaje PDV: i bruto liniju u izvješće, pri čemu se PDV i bruto ukupni iznos automatski izračunavaju.',
      },
    },
    additionalSettings: {
      button: 'Napredne postavke',
      key: {
        label: 'Identifikator',
        placeholder: 'Vrijednost',
        notUnique: 'Identifikator nije jedinstven',
      },
      value: {
        label: 'Zadani tekst',
        placeholder: 'Vrijednost',
        numberLabel: 'Predefinirani broj',
      },
      copy: 'kopiraj pitanje',
      footer: {
        label: 'Podnožje izvješća',
        description: 'Ovaj tekst se automatski prikazuje u izvješću ispod odgovora.',
      },
      alternateQuestionTitle: {
        label: 'Alternativni naslov pitanja za izvješće',
        description:
          'Ovdje možete nadjačati naslov pitanja da biste prikazali alternativni naslov za pitanje u izvješću.',
      },
      showNow: {
        label: 'Automatski unesite trenutačni datum/vrijeme',
      },
    },
    validation: {
      headline: 'Validacije',
      addValidation: 'Dodaj validaciju',
      notImplemented: 'Validator trenutno nije dostupan',
      minMax: {
        min: {
          label: 'Min',
          placeholder: 'Min',
        },
        max: {
          label: 'Max',
          placeholder: 'Max',
        },
      },
      select: {
        label: 'Vrsta provjere valjanosti',
        options: {
          minMax: 'Min/Max',
        },
      },
      doubleHeadline:
        'Pažnja, glavni naslov (H1) bez pitanja neće biti prikazan u izvješću ako je odmah ispod njega u dizajneru unesen drugi glavni naslov.',
      title: 'Naslov ne može biti prazan!',
    },
    common: {
      new: 'Novo polje',
      new_clean: 'novo polje',
      title: {
        label: 'Naslov',
      },
      description: {
        label: 'Opis',
      },
      mandatory: {
        label: 'Obavezno',
      },
      customerId: {
        label: 'Korisnički ID',
      },
      pageBreak: {
        label: 'Prijelom stranice',
      },
      summary: {
        label: 'Sažetak',
      },
      internal: {
        label: 'Interni',
      },
      hideReport: {
        label: 'Sakrij u izvješću',
      },
    },
    numeric: {
      integer: 'Broj',
      currency: 'Dodajte simbol valute',
    },
    yesNo: {
      options: {
        yes: 'Da',
        no: 'Ne',
      },
    },
    select: {
      multiple: 'Višestruki odabir',
      connectedValues: {
        hint: 'Ako povežete pitanja zajedno, povezano pitanje se neće pojaviti dok se ne odabere izbor koji ste napravili.',
        title: 'Odaberite pitanje(a) za povezivanje',
        fieldSearch: {
          placeholder: 'Traži pitanje ...',
          label: 'Naslov pitanja',
        },
        done: 'Gotovo',
      },
      editConnectedValues: 'Povezano',
      addOption: {
        button: 'Dodaj pitanje',
        label: 'Pitanje',
      },
      options: {
        header: 'Pitanje',
      },
      connectedValuesWeight: {
        hint: 'Ovdje možete unijeti težinu pitanja, tako da se rezultat automatski kreira putem obrasca ovisno o mogućnostima odabira. Na temelju ovog "bodovanja", npr. rezultati se mogu izvući iz izvješća.',
        title: 'Odaberite težinu pitanja.',
        done: 'u redu',
        input: {
          label: 'Bodovi',
        },
      },
      hiddenAnswers: {
        hint: 'Ovo pitanje možete sakriti ako su odabrani određeni odgovori. Ovo možete konfigurirati ovdje.',
        title: 'Odaberite odgovore koje želite sakriti',
        done: 'u redu',
        input: {
          label: 'Sakriti',
        },
      },
      renderDropdown: 'Prikaži kao padajući izbornik',
    },
    text: {
      mask: 'maska',
      maskValidationMessage: 'Unos nije u skladu s formatom {{maska}} -',
      minLengthValidation: 'Tekst mora imati najmanje {{min}} znakova.',
      maxLengthValidation: 'Tekst može imati najviše {{max}} znakova.',
      textFieldType: {
        label: 'Vrsta polja',
        options: {
          text: 'tekst',
          mask: 'Tekst s maskom',
          email: 'e-pošta',
          phone: 'Telefonski broj',
        },
      },
    },
    image: {
      allowMultiple: 'Dopusti više fotografija',
      allowImageDescription: 'Dopusti opis fotografije',
      addRequirement: 'Dodaj zahtjev',
      assigned: 'Dodijeljeno',
      notAssigned: 'Neraspoređeno',
      grid: {
        status: 'status',
        image: 'Slika',
        description: 'Opis',
        assignment: 'dodjela',
      },
      dialogs: {
        updateDescription: {
          title: 'Uredi opis',
          description: 'Unesite opis svoje slike ovdje',
        },
        delete: {
          title: 'Jasna slika',
          description: 'Želite li stvarno izbrisati ovu sliku?',
        },
      },
      notifications: {
        updateDescriptionSuccess: 'Opis je spremljen',
        deleteSuccess: 'Slika je izbrisana',
      },
    },
  },
  notifications: {
    templateCreate: {
      success: 'Predložak uspješno kreiran.',
      error: 'Došlo je do greške.',
    },
    templateDelete: {
      success: 'Predložak uspješno izbrisan',
    },
    templateEdit: {
      success: 'Predložak uspješno dupliciran.',
    },
    templateUpdate: {
      success: 'Predložak uspješno ažuriran.',
      error: 'Predložak nije moguće spremiti.',
    },
    templateDuplicate: {
      success: 'Predložak je dupliciran.',
    },
    templateAssign: {
      success: 'Predložak je uspješno dodijeljen.',
      error: 'Predložak nije moguće dodijeliti.',
    },
    copyQuestion: {
      success: '{{title}} je uspješno kopiran.',
    },
    templateAssignAll: {
      success: 'Predlošci su uspješno dodijeljeni.',
      error: 'Predlošci se ne mogu dodijeliti.',
    },
  },
};
