import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import { AppMessageFragmentDoc } from './appMessageFragment.generated';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type AppMessagesQueryVariables = Types.Exact<{ [key: string]: never }>;

export type AppMessagesQuery = {
  __typename?: 'Query';
  appMessages: Array<{
    __typename?: 'AppMessage';
    id: string;
    title: string;
    body: string;
    type: Types.PageNotificationType;
    author_id: string;
    active: boolean;
    visible_from?: string | null;
    visible_to?: string | null;
    author?: { __typename?: 'Client'; id: string; name: string } | null;
  } | null>;
};

export const AppMessagesDocument = gql`
  query AppMessages {
    appMessages {
      ...AppMessage
    }
  }
  ${AppMessageFragmentDoc}
`;

export function useAppMessagesQuery(
  options?: Omit<Urql.UseQueryArgs<AppMessagesQueryVariables>, 'query'>,
) {
  return Urql.useQuery<AppMessagesQuery, AppMessagesQueryVariables>({
    query: AppMessagesDocument,
    ...options,
  });
}
