import { FilterField, TextInputFilter, useFilters } from '@module/shared/filters';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

function useSearchFields() {
  const { t } = useTranslation();

  return useMemo(
    (): FilterField[] => [
      {
        name: 'title',
        label: t('common.labels.name'),
        filter: TextInputFilter,
        operator: 'contains',
      },
    ],
    [t],
  );
}

export function useClassificationTagOverviewFilters() {
  const searchFields = useSearchFields();
  const filterContext = useFilters(searchFields);

  return { filterContext };
}
