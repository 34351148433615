import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
export type MisspellingFragment = {
  __typename?: 'Misspelling';
  word: string;
  suggestions: Array<string>;
  lineNumber?: number | null;
  offset?: number | null;
};

export const MisspellingFragmentDoc = gql`
  fragment Misspelling on Misspelling {
    word
    suggestions
    lineNumber
    offset
  }
`;
