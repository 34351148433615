import areaAssignments from './areaAssignments';
import clients from './clients';
import common from './common';
import dashboard from './dashboard';
import demoData from './demoData';
import emailTemplates from './emailTemplates';
import events from './events';
import invoices from './invoices';
import payouts from './payouts';
import settings from './settings';
import task from './task';
import tasks from './tasks';
import video from './video';

export const localeOverrideRISK = {
  clients,
  demoData,
  areaAssignments,
  payouts,
  settings,
  tasks,
  task,
  common,
  dashboard,
  emailTemplates,
  events,
  invoices,
  video,
};
