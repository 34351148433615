import { Label } from '@module/shared/forms/components/Label';
import { isTruthy } from '@module/shared/helpers';
import { FieldRenderProps, FieldWrapper } from '@progress/kendo-react-form';
import {
  ListBox,
  ListBoxItemClickEvent as KendoListBoxItemClickEvent,
  ListBoxToolbar,
  ListBoxToolbarClickEvent,
  processListBoxData,
} from '@progress/kendo-react-listbox';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { boolean, object, string } from 'yup';

import { ClientsForTaskQuery, useClientsForTaskQuery } from '../../../clients/graphql';

export const insuranceAssignmentSelectableItemSchema = object({
  id: string().required(),
  name: string().required(),
  selected: boolean().required(),
});

export type InsuranceAssignmentItem = ClientsForTaskQuery['clientsForTask']['data'][number];
export type InsuranceAssignmentSelectableItem = InsuranceAssignmentItem & { selected: boolean };

const SELECTED_FIELD = 'selected';

interface InsuranceAssignmentListboxesProps extends FieldRenderProps {
  value: Array<InsuranceAssignmentSelectableItem> | null | undefined;
}

interface ListBoxItemClickEvent extends KendoListBoxItemClickEvent {
  dataItem?: InsuranceAssignmentSelectableItem;
}

export const InsuranceAssignmentListboxes = (props: InsuranceAssignmentListboxesProps) => {
  const { label, onChange, value, name } = props;
  const { t } = useTranslation();
  const [{ data }] = useClientsForTaskQuery();

  const [selected, setSelected] = useState<{ [key: string]: boolean }>({});

  const { availableInsurances, selectedInsurances } = useMemo(() => {
    const allInsurances = data?.clientsForTask.data ?? [];
    const selectedInsuranceIds = value?.map(({ id }) => id.toString()) || [];

    const availableInsurances = allInsurances
      .filter((insurance) => !selectedInsuranceIds?.includes(insurance.id))
      .map((insurance) => ({
        id: insurance.id,
        name: insurance.name,
        [SELECTED_FIELD]: selected[insurance.id] ?? false,
      }))
      .sort((a, b) => a.name.localeCompare(b.name));

    const selectedInsurances =
      selectedInsuranceIds
        ?.map((id) => {
          const currentInsurance = allInsurances.find((insurance) => insurance.id === id);
          if (!currentInsurance) return null;
          return {
            id: currentInsurance?.id,
            name: currentInsurance?.name,
            [SELECTED_FIELD]: selected[id] ?? false,
          };
        })
        .filter(isTruthy) ?? [];

    return { availableInsurances, selectedInsurances };
  }, [data, value, selected]);

  const handleToolBarClick = (event: ListBoxToolbarClickEvent) => {
    const result = processListBoxData(
      availableInsurances,
      selectedInsurances,
      event.toolName || '',
      SELECTED_FIELD,
    );

    onChange({ value: result.listBoxTwoData });
  };

  const handleItemClick = ({ dataItem }: ListBoxItemClickEvent) => {
    if (!dataItem) return;
    const newSelected = { ...selected, [dataItem.id]: !selected[dataItem.id] };
    setSelected(newSelected);
  };

  return (
    <FieldWrapper>
      <Label label={label} name={name}>
        {label}
      </Label>

      <div className="u-width-full k-display-flex k-gap-3">
        <div style={{ width: '50%' }}>
          <p>{t('common.components.insuranceAssignmentListboxes.all')}</p>

          <ListBox
            data={availableInsurances}
            textField="name"
            valueField="id"
            selectedField={SELECTED_FIELD}
            onItemClick={handleItemClick}
            style={{ width: '100%' }}
            toolbar={() => (
              <ListBoxToolbar
                tools={['transferTo', 'transferFrom', 'transferAllTo', 'transferAllFrom']}
                data={availableInsurances}
                dataConnected={selectedInsurances}
                onToolClick={handleToolBarClick}
              />
            )}
          />
        </div>
        <div style={{ width: '50%' }}>
          <p>{t('common.components.insuranceAssignmentListboxes.active')}</p>
          <ListBox
            data={selectedInsurances}
            textField="name"
            valueField="id"
            selectedField={SELECTED_FIELD}
            onItemClick={handleItemClick}
            style={{ width: '100%' }}
          />
        </div>
      </div>
    </FieldWrapper>
  );
};
