import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type DeleteApiTokenMutationVariables = Types.Exact<{
  uuid: Types.Scalars['ID'];
}>;

export type DeleteApiTokenMutation = {
  __typename?: 'Mutation';
  deleteApiToken: {
    __typename?: 'ResponseMessage';
    message?: string | null;
    success?: boolean | null;
  };
};

export const DeleteApiTokenDocument = gql`
  mutation DeleteApiToken($uuid: ID!) {
    deleteApiToken(uuid: $uuid) {
      message
      success
    }
  }
`;

export function useDeleteApiTokenMutation() {
  return Urql.useMutation<DeleteApiTokenMutation, DeleteApiTokenMutationVariables>(
    DeleteApiTokenDocument,
  );
}
