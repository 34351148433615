import { useForgotPasswordMutation } from '@module/auth/graphql';
import { BrandingPanel, ForgotPasswordInput, Loader, Message } from '@module/common';
import { PublicLayout } from '@module/layout';
import { Nl2Br } from '@module/shared/components';
import { EmailInput, Form, useSchemaValidator } from '@module/shared/forms';
import { email } from '@module/shared/helpers';
import { Button } from '@progress/kendo-react-buttons';
import { Field, FormElement } from '@progress/kendo-react-form';
import { Card, CardBody, CardHeader } from '@progress/kendo-react-layout';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import * as yup from 'yup';
import { ObjectSchema } from 'yup';

import { FooterMini } from '../../components/FooterMini';

const schema: ObjectSchema<ForgotPasswordInput> = yup.object({
  email: email().required(),
});

const FormFields = () => {
  const { t } = useTranslation();

  return (
    <FormElement width={240}>
      <div className="k-text-center">
        <Nl2Br text={t('auth.pages.forgotPassword.note')} />
      </div>
      <Field
        id="forgot_password.email"
        name={'email'}
        label={t('common.labels.email')}
        component={EmailInput}
      />

      <div className="k-d-flex-col k-mt-6 k-gap-4 text-center">
        <Button themeColor="primary" size="large" type="submit">
          {t('auth.pages.forgotPassword.submit')}
        </Button>

        <Link className="k-align-self-center text-decoration-none" to="/login">
          {t('auth.pages.forgotPassword.toLogin')}
        </Link>
      </div>
    </FormElement>
  );
};

export const ForgotPasswordPage = () => {
  const { t } = useTranslation();
  const [message, setMessage] = useState<string>();
  const [error, setError] = useState<string>();
  const validator = useSchemaValidator(schema);
  const [{ fetching: isLoading }, forgotPassword] = useForgotPasswordMutation();

  const handleSubmit = useCallback(
    (values: { [name: string]: unknown }) => {
      const input = values as ForgotPasswordInput;
      forgotPassword({ input }).then((response) => {
        if (!response.error) {
          setMessage(t(response.data?.forgotPassword?.message ?? ''));
        }
        if (response.error?.graphQLErrors.length) {
          setError(t(response.error?.graphQLErrors[0].message));
        }
      });
    },
    [forgotPassword, t],
  );

  return (
    <PublicLayout>
      <div className="k-display-flex k-flex-grow k-flex-column k-justify-content-top k-align-items-center">
        <Card style={{ width: 460 }} className="k-shadow k-mt-20">
          <CardHeader className="k-px-8 k-pt-8 k-text-center">
            <BrandingPanel size="small" />
            <h1>{t('auth.pages.forgotPassword.title')}</h1>
          </CardHeader>
          <CardBody className="k-px-16 k-py-12">
            {message && <Message type="success">{message}</Message>}
            {error && <Message type="error">{error}</Message>}
            {!error && !message && (
              <Form
                schema={schema}
                onSubmit={handleSubmit}
                validator={validator}
                render={() => <FormFields />}
              />
            )}
            {isLoading && <Loader />}
          </CardBody>
        </Card>
        <FooterMini />
      </div>
    </PublicLayout>
  );
};
