import { findEnumValue, isString } from '@module/shared/helpers';
import { get } from 'lodash';

import { ThemeColor } from '../../../types/graphql.generated';

export function getThemeColorByField(data: unknown, field: string | string[]) {
  if (field.length > 0) {
    const value = get(data, field);
    if (isString(value)) {
      return getThemeColor(value);
    }
  }
  return undefined;
}

export function getThemeColor(color: string): ThemeColor | undefined {
  return findEnumValue(ThemeColor, color);
}

const mapColor = {
  PRIMARY: 'k-color-primary',
  SECONDARY: 'k-color-secondary',
  TERTIARY: 'k-color-tertiary',
  INFO: 'k-color-info',
  SUCCESS: 'k-color-success',
  WARNING: 'k-color-warning',
  ERROR: 'k-color-error',
  DARK: 'k-color-dark',
  LIGHT: 'k-color-light',
  INVERSE: 'k-color-inverse',
};

export function getThemeColorFont(themeColor: ThemeColor | undefined): string | undefined {
  if (themeColor) {
    return mapColor[themeColor];
  }
  return undefined;
}

const mapColorBg = {
  PRIMARY: 'k-bg-primary',
  SECONDARY: 'k-bg-secondary',
  TERTIARY: 'k-bg-tertiary',
  INFO: 'k-bg-info',
  SUCCESS: 'k-bg-success',
  WARNING: 'k-bg-warning',
  ERROR: 'k-bg-error',
  DARK: 'k-bg-dark',
  LIGHT: 'k-bg-light',
  INVERSE: 'k-bg-inverse',
};

export function getThemeColorBg(themeColor: ThemeColor | undefined): string | undefined {
  if (themeColor) {
    return mapColorBg[themeColor];
  }
  return undefined;
}
