import { ClassificationTagInput, Loader, useId } from '@module/common';
import {
  CancelButton,
  CreateButton,
  Dialog,
  DialogContent,
  DialogHeader,
  DialogHeaderBar,
  DialogHeaderIcon,
} from '@module/layout';
import { DialogProps, useDialogs } from '@module/shared/dialogs';
import {
  CheckboxField,
  ColorPickerInput,
  Form,
  FormElement,
  FormStateConsumer,
  FormStateProvider,
  TextInput,
  useSchemaValidator,
} from '@module/shared/forms';
import { stripGraphQLTypeName } from '@module/shared/graphql';
import { NotificationType, useNotifications } from '@module/shared/notifications';
import { DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Field } from '@progress/kendo-react-form';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useCreateClassificationTagMutation } from '../graphql';
import { useClassificationTagInputSchema } from '../hooks';

export const CreateClassificationTagDialog = (props: DialogProps) => {
  const { t } = useTranslation();
  const { hideDialog } = useDialogs();
  const { showNotification } = useNotifications();
  const schema = useClassificationTagInputSchema();
  const validator = useSchemaValidator(schema);
  const [{ fetching: isSaving }, mutate] = useCreateClassificationTagMutation();
  const formId = useId();

  const handleSubmit = useCallback(
    (values: { [name: string]: unknown }) => {
      const input = stripGraphQLTypeName(values as ClassificationTagInput);

      mutate({ input }, { additionalTypenames: ['ClassificationTagsPaginator'] }).then(
        (response) => {
          if (!response.error) {
            showNotification(
              t('settings.pages.classificationTags.notifications.create.success'),
              NotificationType.Success,
            );
            hideDialog(props.dialogId);
          }
        },
      );
    },
    [mutate, hideDialog, showNotification, props.dialogId, t],
  );

  const handleClose = () => {
    hideDialog(props.dialogId);
  };

  const initialValues: Partial<ClassificationTagInput> = useMemo(
    () => ({
      api_only: false,
    }),
    [],
  );

  return (
    <FormStateProvider>
      <Dialog width="small" onClose={handleClose}>
        {isSaving && <Loader />}

        <DialogHeaderBar layout="flex">
          <DialogHeaderIcon iconClass="l-i-contact u-text-2xl" color="primary" />

          <DialogHeader
            title={t('settings.pages.classificationTags.dialogs.create.title')}
            description={t('settings.pages.classificationTags.dialogs.create.note')}
          />
        </DialogHeaderBar>

        <DialogContent>
          <Form
            id={formId}
            schema={schema}
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validator={validator}
            render={(formRenderProps) => (
              <FormElement formRenderProps={formRenderProps}>
                <Field
                  id="classification_tag.create.title"
                  name={'title'}
                  label={t('settings.pages.classificationTags.labels.name')}
                  component={TextInput}
                />
                <Field
                  id="classification_tag.create.alias"
                  name={'alias'}
                  label={t('settings.pages.classificationTags.labels.alias')}
                  component={TextInput}
                />
                <Field
                  id="classification_tag.create.api_only"
                  name={'api_only'}
                  label={t('settings.pages.classificationTags.labels.api_only')}
                  component={CheckboxField}
                />
                <Field
                  id="classification_tag.create.color"
                  name={'color'}
                  label={t('settings.pages.classificationTags.labels.color')}
                  size="large"
                  component={ColorPickerInput}
                />
              </FormElement>
            )}
          />
        </DialogContent>
        <DialogActionsBar layout="end">
          <FormStateConsumer>
            {() => (
              <>
                <CancelButton onClick={handleClose} />
                <CreateButton type="submit" form={formId} />
              </>
            )}
          </FormStateConsumer>
        </DialogActionsBar>
      </Dialog>
    </FormStateProvider>
  );
};
