export default {
  dialogs: {
    userCreate: {
      title: 'Benutzer erstellen',
      cancel: 'Abbrechen',
      submit: 'Erstellen',
      fields: {
        email: {
          label: 'E-Mail',
          hint: 'Geben Sie Ihre E-Mail ein, z. B. max.mustermann@example.com',
        },
        firstname: { label: 'Vorname' },
        lastname: { label: 'Nachname' },
        birthday: { label: 'Geburtstag' },
      },
    },
    userDelete: {
      title: 'Benutzer löschen',
      cancel: 'Abbrechen',
      submit: 'Löschen',
      note: 'Benutzer wirklich löschen?',
    },
  },
  pages: {
    userOverview: {
      grid: {
        columns: {
          name: 'Name',
          email: 'E-Mail',
          phone: 'Telefon',
        },
      },
      toolbar: {
        create: { label: 'Erstellen' },
      },
    },
  },
};
