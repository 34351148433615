import {
  GRAPHQL_API_URL,
  GRAPHQL_SUBSCRIPTIONS_AUTH_URL,
  PUSHER_APP_CLUSTER,
  PUSHER_APP_KEY,
  PUSHER_HOST,
  PUSHER_PORT,
} from '@env';
import { useAuthState, useIsAuthenticated } from '@module/auth';
import { GraphqlSubscriptionPusherClient } from '@module/shared/graphql';
import Pusher from 'pusher-js';
import { useMemo } from 'react';
import { Exchange, subscriptionExchange } from 'urql';

export function useGraphqlSubscriptionExchange(): Exchange | undefined {
  const isAuthenticated = useIsAuthenticated();
  const authState = useAuthState();

  return useMemo(() => {
    if (isAuthenticated && authState) {
      const headers = { Authorization: `Bearer ${authState.data.access_token}` };

      const pusher = new Pusher(PUSHER_APP_KEY, {
        wsHost: PUSHER_HOST,
        wsPort: PUSHER_PORT,
        cluster: PUSHER_APP_CLUSTER,
        forceTLS: false,
        disableStats: true,
        enabledTransports: ['ws', 'wss'],
        channelAuthorization: {
          endpoint: GRAPHQL_SUBSCRIPTIONS_AUTH_URL,
          transport: 'ajax',
          headers,
        },
      });

      const pusherClient = new GraphqlSubscriptionPusherClient(pusher, GRAPHQL_API_URL);

      return subscriptionExchange({
        forwardSubscription(request, operation) {
          return pusherClient.subscribe(request, operation);
        },
      });
    }

    return undefined;
  }, [authState, isAuthenticated]);
}
