import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import { InterfaceMessageTemplateFragmentDoc } from './interfaceMessageTemplateFragment.generated';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CreateInterfaceMessageTemplateMutationVariables = Types.Exact<{
  input: Types.InterfaceMessageTemplateInput;
}>;

export type CreateInterfaceMessageTemplateMutation = {
  __typename?: 'Mutation';
  createInterfaceMessageTemplate?: {
    __typename?: 'InterfaceMessageTemplate';
    id: string;
    title: string;
    description?: string | null;
    message_type?: string | null;
    subject?: string | null;
    body?: string | null;
    category?: {
      __typename?: 'InterfaceMessageTemplateCategory';
      id: string;
      title: string;
    } | null;
    assignments: Array<{ __typename?: 'Client'; id: string; name: string }>;
  } | null;
};

export const CreateInterfaceMessageTemplateDocument = gql`
  mutation CreateInterfaceMessageTemplate($input: InterfaceMessageTemplateInput!) {
    createInterfaceMessageTemplate(input: $input) {
      ...InterfaceMessageTemplate
    }
  }
  ${InterfaceMessageTemplateFragmentDoc}
`;

export function useCreateInterfaceMessageTemplateMutation() {
  return Urql.useMutation<
    CreateInterfaceMessageTemplateMutation,
    CreateInterfaceMessageTemplateMutationVariables
  >(CreateInterfaceMessageTemplateDocument);
}
