import './MapSettings.scss';

import { Popover } from '@module/shared/components';
import { Checkbox } from '@module/shared/forms';
import { Button } from '@progress/kendo-react-buttons';
import { classNames } from '@progress/kendo-react-common';
import { RadioButton, RadioButtonChangeEvent } from '@progress/kendo-react-inputs';
import { HTMLAttributes, ReactNode, useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocalstorageState } from 'rooks';

import { MapStyle } from './const';

export function useMapSettings() {
  const [mapStyle, setMapStyle] = useLocalstorageState('taskMap:mapStyle:v1', MapStyle.Streets);

  return { mapStyle, setMapStyle };
}

interface MapSettingsOptionProps {
  title: string;
  selected: boolean;
  onClick: () => void;
}

export const MapSettingsOption = (props: MapSettingsOptionProps) => {
  return (
    <div
      className="OptionListItem k-display-flex k-flex-row k-flex-nowrap k-align-items-center k-p-1 k-gap-2 k-cursor-pointer"
      onClick={props.onClick}
    >
      <Checkbox value={props.selected} />
      <div>
        <span>{props.title}</span>
      </div>
    </div>
  );
};

interface MapSettingsRadioChangeEvent extends RadioButtonChangeEvent {
  value: MapStyle;
}

interface MapSettingsRadioProps {
  title: string;
  value: MapStyle;
  checked: boolean;
  onChange: (event: MapSettingsRadioChangeEvent) => void;
}

export const MapSettingsRadio = (props: MapSettingsRadioProps) => {
  return (
    <label className="k-display-flex k-flex-row k-flex-nowrap k-align-items-center k-p-1 k-gap-2 k-cursor-pointer">
      <RadioButton value={props.value} onChange={props.onChange} checked={props.checked} />
      <div>
        <span>{props.title}</span>
      </div>
    </label>
  );
};

export interface MapSettingsProps
  extends Pick<HTMLAttributes<HTMLDivElement>, 'className' | 'style'> {
  mapStyle: MapStyle;
  setMapStyle: (mapStyle: MapStyle) => void;
  options?: ReactNode | undefined;
}

export const MapSettings = (props: MapSettingsProps) => {
  const { t } = useTranslation();
  const anchorRef = useRef<HTMLDivElement>(null);
  const [showSettings, setShowSettings] = useState(false);
  const { mapStyle, setMapStyle, options } = props;

  const toggleShowSettings = useCallback(() => {
    setShowSettings(!showSettings);
  }, [showSettings]);

  const handleMapStyleChange = useCallback(
    (event: MapSettingsRadioChangeEvent) => setMapStyle(event.value),
    [setMapStyle],
  );

  return (
    <div
      className="MapSettings"
      style={{ position: 'absolute', bottom: 35, right: 20 }}
      ref={anchorRef}
    >
      <Button
        className={classNames('MapSettingsButton', { selected: showSettings })}
        iconClass="l-i-settings"
        size="large"
        onClick={toggleShowSettings}
        aria-label={t('common.components.maps.mapSettings.label')}
      />
      {showSettings && anchorRef.current && (
        <Popover
          className="MapSettingsPopover"
          show={true}
          anchor={anchorRef.current}
          position="top"
          positionMode="fixed"
          style={{ width: 200 }}
          callout={true}
          margin={{ horizontal: 60, vertical: 20 }}
        >
          <h3 className="k-mt-0 k-mb-1">{t('common.components.maps.mapSettings.titleMapStyle')}</h3>
          <MapSettingsRadio
            title={t('common.components.maps.mapSettings.monochrome')}
            value={MapStyle.Monochrome}
            checked={mapStyle === MapStyle.Monochrome}
            onChange={handleMapStyleChange}
          />
          <MapSettingsRadio
            title={t('common.components.maps.mapSettings.satellite')}
            value={MapStyle.Satellite}
            checked={mapStyle === MapStyle.Satellite}
            onChange={handleMapStyleChange}
          />
          <MapSettingsRadio
            title={t('common.components.maps.mapSettings.streets')}
            value={MapStyle.Streets}
            checked={mapStyle === MapStyle.Streets}
            onChange={handleMapStyleChange}
          />
          {options}
        </Popover>
      )}
    </div>
  );
};
