import { useTaskGroupAbility } from '@module/casl/abilities/TaskGroupAbility';
import { GridActionsButton, TaskGroup } from '@module/common';
import { useDialogs } from '@module/shared/dialogs';
import { isTruthy } from '@module/shared/helpers';
import { useTranslation } from 'react-i18next';

import { EditTaskGroupDialog } from '../../dialogs';
import { useConfirmDeleteTaskGroup } from '../../hooks';

interface TaskGroupCommandCellProps {
  group: TaskGroup;
}

export const TaskGroupCommandCell = (props: TaskGroupCommandCellProps) => {
  const { t } = useTranslation();
  const confirmDeleteGroup = useConfirmDeleteTaskGroup();
  const { showDialog } = useDialogs();

  const { group } = props;

  const handleDelete = () => {
    confirmDeleteGroup(group.id.toString());
  };

  const handleEdit = () => {
    showDialog({ group }, EditTaskGroupDialog);
  };

  const [taskGroupAbility, taskGroupSubject] = useTaskGroupAbility();
  const actionItems = [
    taskGroupAbility.can('updateTaskGroup', taskGroupSubject()) && {
      actionType: 'edit',
      actionName: t('common.labels.edit'),
      icon: 'edit',
      handler: handleEdit,
    },
    taskGroupAbility.can('deleteTaskGroup', taskGroupSubject()) && {
      actionType: 'delete',
      actionName: t('common.labels.delete'),
      icon: 'delete',
      handler: handleDelete,
    },
  ].filter(isTruthy);

  return (
    <td className="k-command-cell k-text-center">
      {actionItems.length > 0 && <GridActionsButton actions={actionItems} />}
    </td>
  );
};
