export default {
  forms: {
    createTask: {
      fields: {
        parallelNumber: {
          label: 'KOS-Schaden',
        },
      },
    },
  },
};
