import { ReactNode, useCallback, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import {
  NotificationItem,
  NotificationsContext,
  NotificationsContextType,
} from './NotificationsContext';

export const NotificationsProvider = (props: { children?: ReactNode | undefined }) => {
  const [notifications, setNotifications] = useState<NotificationItem[]>([]);

  const hideNotification = useCallback<NotificationsContextType['hideNotification']>(
    (notificationId) => {
      setNotifications((prevState) =>
        prevState.filter((notification) => notification.notificationId !== notificationId),
      );
    },
    [setNotifications],
  );

  const showNotification = useCallback<NotificationsContextType['showNotification']>(
    (text, type, duration = 5000, enableAutoHide = true) => {
      const notificationId = uuidv4();
      const notification = { notificationId, text, type };

      setNotifications((prevState) => [...prevState, notification]);
      if (!enableAutoHide) return notificationId;
      // hide after 2000 ms
      setTimeout(() => {
        hideNotification(notification.notificationId);
      }, duration);

      return notificationId;
    },
    [hideNotification],
  );

  return (
    <NotificationsContext.Provider value={{ notifications, showNotification, hideNotification }}>
      {props.children}
    </NotificationsContext.Provider>
  );
};
