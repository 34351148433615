import './NonResizableTextArea.scss';

import { classNames } from '@progress/kendo-react-common';
import { TextArea, TextAreaHandle, TextAreaProps } from '@progress/kendo-react-inputs';
import { forwardRef } from 'react';

export type NonResizableTextAreaProps = Omit<TextAreaProps, 'autoSize'>;

export const NonResizableTextArea = forwardRef<TextAreaHandle, NonResizableTextAreaProps>(
  (props, ref) => (
    <TextArea
      {...props}
      ref={ref}
      className={classNames(props.className, 'NonResizableTextArea')}
    />
  ),
);

NonResizableTextArea.displayName = 'NonResizableTextArea';
