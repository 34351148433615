import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ArticleSelectQueryVariables = Types.Exact<{
  clientId: Types.Scalars['ID'];
}>;

export type ArticleSelectQuery = {
  __typename?: 'Query';
  articlesForClient: Array<{
    __typename?: 'Article';
    id: string;
    article_number: string;
    title: string;
    description?: string | null;
    sales_price?: number | null;
  }>;
};

export const ArticleSelectDocument = gql`
  query ArticleSelect($clientId: ID!) {
    articlesForClient(client_id: $clientId) {
      id
      article_number
      title
      description
      sales_price
    }
  }
`;

export function useArticleSelectQuery(
  options: Omit<Urql.UseQueryArgs<ArticleSelectQueryVariables>, 'query'>,
) {
  return Urql.useQuery<ArticleSelectQuery, ArticleSelectQueryVariables>({
    query: ArticleSelectDocument,
    ...options,
  });
}
