import MediaUploadIcon from '@image/empty-states/Upload-Media.svg';
import { Nl2Br } from '@module/shared/components';
import { Button } from '@progress/kendo-react-buttons';

export interface NoRecordProps {
  title: string;
  text?: string;
  buttonIconClass?: string;
  buttonLabel?: string;
  onClick?: () => void;
  onClickDisabled?: boolean;
}

export const NoRecords = (props: NoRecordProps) => {
  const { title, text, buttonIconClass, buttonLabel, onClick, onClickDisabled } = props;
  return (
    <div className="k-display-flex k-flex-row k-justify-content-center k-my-8 noPreviewInner">
      <div className="u-width-25-rem k-text-center">
        <img src={MediaUploadIcon} alt="" />
        <h2>{title}</h2>
        {text && (
          <p>
            <Nl2Br text={text} />
          </p>
        )}
        {onClick && buttonLabel && (
          <Button
            iconClass={buttonIconClass}
            size="large"
            themeColor="primary"
            onClick={onClick}
            disabled={onClickDisabled}
          >
            {buttonLabel}
          </Button>
        )}
      </div>
    </div>
  );
};
