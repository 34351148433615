import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CopyTaskFormQuestionsMutationVariables = Types.Exact<{
  task_form_id_source: Types.Scalars['ID'];
  task_form_id_target: Types.Scalars['ID'];
}>;

export type CopyTaskFormQuestionsMutation = {
  __typename?: 'Mutation';
  copyTaskFormQuestions: {
    __typename?: 'ResponseMessage';
    success?: boolean | null;
    message?: string | null;
  };
};

export const CopyTaskFormQuestionsDocument = gql`
  mutation CopyTaskFormQuestions($task_form_id_source: ID!, $task_form_id_target: ID!) {
    copyTaskFormQuestions(
      task_form_id_source: $task_form_id_source
      task_form_id_target: $task_form_id_target
    ) {
      success
      message
    }
  }
`;

export function useCopyTaskFormQuestionsMutation() {
  return Urql.useMutation<CopyTaskFormQuestionsMutation, CopyTaskFormQuestionsMutationVariables>(
    CopyTaskFormQuestionsDocument,
  );
}
