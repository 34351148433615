import { DropDownListInput } from '@module/shared/forms';
import { FieldRenderProps } from '@progress/kendo-react-form';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { CurrenciesQuery, useCurrenciesQuery } from '../../graphql';

type CurrencyItem = NonNullable<CurrenciesQuery['currencies']>[number];

interface CurrencySelectProps extends FieldRenderProps {
  value: CurrencyItem['id'] | null;
  onChange: (event: { target?: unknown; value?: CurrencyItem['id'] | null }) => void;
}

export const CurrencySelect = (props: CurrencySelectProps) => {
  const { value, onChange, ...rest } = props;
  const [{ data }] = useCurrenciesQuery();
  const { t } = useTranslation();

  const valueAsItem = useMemo(() => {
    if (!data?.currencies) {
      return {
        id: value,
        title: t('common.labels.loadingItem'),
      };
    }

    return data.currencies.find(({ id }) => id === value);
  }, [value, data?.currencies, t]);

  const handleChange = useCallback(
    (event: { value?: CurrencyItem }) => {
      onChange({
        ...event,
        value: event.value?.id ?? null,
      });
    },
    [onChange],
  );

  return (
    <DropDownListInput
      {...rest}
      data={data?.currencies ?? []}
      dataItemKey="id"
      textField="title"
      value={valueAsItem}
      disabled={!data?.currencies}
      onChange={handleChange}
    />
  );
};
