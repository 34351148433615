import './AutoFillGrid.scss';

import { classNames } from '@progress/kendo-react-common';
import { CSSProperties, HTMLAttributes } from 'react';

export interface AutoFillGridProps extends HTMLAttributes<HTMLDivElement> {
  maxColumns: number;
  columnMinWidth: string;
  gap: string;
  rowGap?: string;
}

export const AutoFillGrid = (props: AutoFillGridProps) => {
  const { className, style, maxColumns, columnMinWidth, gap, rowGap, ...rest } = props;

  // Unitless and empty values are not valid for the used CSS calc expression.
  const saveGap = gap.length && gap !== '0' ? gap : '0px';

  return (
    <div
      className={classNames('AutoFillGrid', className)}
      style={
        {
          ...style,
          '--max-columns': maxColumns,
          '--column-min-width': columnMinWidth,
          '--gap': saveGap,
          '--row-gap': rowGap,
        } as CSSProperties
      }
      {...rest}
    />
  );
};
