import {
  FormElement as KendoFormElement,
  FormElementProps as KendoFormElementProps,
  FormRenderProps,
} from '@progress/kendo-react-form';

import { FormSpy } from './FormSpy';

interface FormElementProps extends KendoFormElementProps {
  formRenderProps: FormRenderProps;
}

export const FormElement = (props: FormElementProps) => {
  const { formRenderProps, ...restProps } = props;
  return (
    <>
      <FormSpy {...formRenderProps} />
      <KendoFormElement {...restProps} />
    </>
  );
};
