import { classNames } from '@progress/kendo-react-common';
import { ReactNode } from 'react';

export interface MessageProps {
  children?: ReactNode | undefined;
  type: 'success' | 'error';
}

export const Message = (props: MessageProps) => {
  const { type, children } = props;

  return (
    <div
      className={classNames(
        'k-text-center',
        'k-border k-border-solid',
        'k-d-inline-flex k-flex-row k-justify-content-center k-align-items-center !k-gap-2',
        'k-py-4 k-px-2',
        {
          'k-text-error k-border-error': type === 'error',
          'k-text-success k-border-success': type === 'success',
        },
      )}
      style={{ width: '100%', borderRadius: '6px' }}
    >
      <span className="l-i-alert-circle" />
      {children}
    </div>
  );
};
