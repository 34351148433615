import { AuthStateFragment } from './generated/types.generated';

export { type AuthStateFragment } from './generated/types.generated';

export { useForgotPasswordMutation } from './generated/useForgotPasswordMutation.generated';
export { useInitialLoginMutation } from './generated/initialLoginMutation.generated';
export { useLoginMutation } from './generated/useLoginMutation.generated';
export { useLoginAsOtherUserMutation } from './generated/useLoginAsOtherUserMutation.generated';
export { useRestoreFromOtherUserMutation } from './generated/restoreFromOtherUser.generated';
export { useLoginAsCustomerMutation } from './generated/loginAsCustomerMutation.generated';
export { useLogoutMutation } from './generated/useLogoutMutation.generated';
export {
  useRefreshTokenMutation,
  type RefreshTokenMutation,
  type RefreshTokenMutationVariables,
  RefreshTokenDocument,
} from './generated/useRefreshTokenMutation.generated';
export { useResetPasswordMutation } from './generated/useResetPasswordMutation.generated';
export { useVerifyEmailMutation } from './generated/useVerifyEmailMutation.generated';
export { useVerifyLoginMutation } from './generated/verifyLoginMutation.generated';

export interface AuthState {
  data: {
    access_token: string;
    refresh_token: string;
    expires_at: string;
    refresh_token_expires_at: string;
  };
}

export function convertResponseToAuthState(authStateResponse: AuthStateFragment): AuthState {
  const { data } = authStateResponse;

  return {
    data: {
      access_token: data.access_token,
      refresh_token: data.refresh_token,
      expires_at: data.expires_at,
      refresh_token_expires_at: data.refresh_token_expires_at,
    },
  };
}
