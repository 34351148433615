import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type DeleteClientLocationMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;

export type DeleteClientLocationMutation = {
  __typename?: 'Mutation';
  deleteClientLocation?: { __typename?: 'ClientLocation'; id: string } | null;
};

export const DeleteClientLocationDocument = gql`
  mutation DeleteClientLocation($id: ID!) {
    deleteClientLocation(id: $id) {
      id
    }
  }
`;

export function useDeleteClientLocationMutation() {
  return Urql.useMutation<DeleteClientLocationMutation, DeleteClientLocationMutationVariables>(
    DeleteClientLocationDocument,
  );
}
