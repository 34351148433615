import './FeaturedIcon.scss';

import { classNames } from '@progress/kendo-react-common';

export type FeaturedIconColor =
  | 'base'
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'info'
  | 'success'
  | 'warning'
  | 'error';

interface FeaturedIconProps {
  className?: string;
  iconClass: string;
  color?: FeaturedIconColor;
}

export const FeaturedIcon = (props: FeaturedIconProps) => {
  const { className, color = 'base', iconClass } = props;
  return (
    <div className={classNames('featured-icon', className, 'featured-icon', `u-bg-${color}-50`)}>
      <div className={`u-bg-${color}-100`}>
        <span className={classNames(`u-text-${color}-600`, iconClass)} />
      </div>
    </div>
  );
};
