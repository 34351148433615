import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import { TaskSubTaskTemplateFragmentDoc } from './taskSubTaskTemplateFragment.generated';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type TaskSubTaskTemplatesQueryVariables = Types.Exact<{
  filter: Types.TaskSubTaskTemplateFilter;
  sort?: Types.InputMaybe<Types.TaskSubTaskTemplateSort>;
  pagination: Types.PaginationInput;
}>;

export type TaskSubTaskTemplatesQuery = {
  __typename?: 'Query';
  taskSubTaskTemplates: {
    __typename?: 'TaskSubTaskTemplatePaginator';
    data: Array<{
      __typename?: 'TaskSubTaskTemplate';
      id: string;
      title: string;
      description: string;
      finish_date_default_days: number;
    }>;
    paginatorInfo: { __typename?: 'PaginatorInfo'; total: number };
  };
};

export const TaskSubTaskTemplatesDocument = gql`
  query TaskSubTaskTemplates(
    $filter: TaskSubTaskTemplateFilter!
    $sort: TaskSubTaskTemplateSort
    $pagination: PaginationInput!
  ) {
    taskSubTaskTemplates(filter: $filter, sort: $sort, pagination: $pagination) {
      data {
        ...TaskSubTaskTemplate
      }
      paginatorInfo {
        total
      }
    }
  }
  ${TaskSubTaskTemplateFragmentDoc}
`;

export function useTaskSubTaskTemplatesQuery(
  options: Omit<Urql.UseQueryArgs<TaskSubTaskTemplatesQueryVariables>, 'query'>,
) {
  return Urql.useQuery<TaskSubTaskTemplatesQuery, TaskSubTaskTemplatesQueryVariables>({
    query: TaskSubTaskTemplatesDocument,
    ...options,
  });
}
