import { createContext, ReactNode, useContext } from 'react';

interface EditableCellContextType {
  enterEdit?: (dataItem: unknown, field: string) => void;
  leaveEdit?: (dataItem: unknown, field: string) => void;
}

const EditableCellContext = createContext<EditableCellContextType | undefined>(undefined);

export function useEditableCellContext() {
  return useContext(EditableCellContext);
}

interface EditableCellContextProviderProps {
  value: EditableCellContextType | undefined;
  children?: ReactNode | undefined;
}

export const EditableCellContextProvider = (props: EditableCellContextProviderProps) => {
  return (
    <EditableCellContext.Provider value={props.value}>
      {props.children}
    </EditableCellContext.Provider>
  );
};
