import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import { FileClassificationTagFragmentDoc } from './fileClassificationTagFragment.generated';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type FileClassificationTagsAllQueryVariables = Types.Exact<{ [key: string]: never }>;

export type FileClassificationTagsAllQuery = {
  __typename?: 'Query';
  fileClassificationTags: {
    __typename?: 'FileClassificationTagsPaginator';
    data: Array<{
      __typename?: 'FileClassificationTag';
      id: string;
      title: string;
      color: string;
      alias: string;
      type_id: Types.FileClassificationTagTypeId;
    }>;
  };
};

export const FileClassificationTagsAllDocument = gql`
  query FileClassificationTagsAll {
    fileClassificationTags(
      filter: { AND: [] }
      sort: { columns: [] }
      pagination: { skip: 0, take: 10000 }
    ) {
      data {
        ...FileClassificationTag
      }
    }
  }
  ${FileClassificationTagFragmentDoc}
`;

export function useFileClassificationTagsAllQuery(
  options?: Omit<Urql.UseQueryArgs<FileClassificationTagsAllQueryVariables>, 'query'>,
) {
  return Urql.useQuery<FileClassificationTagsAllQuery, FileClassificationTagsAllQueryVariables>({
    query: FileClassificationTagsAllDocument,
    ...options,
  });
}
