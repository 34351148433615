import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type UploadReportCoverDefaultBackgroundMutationVariables = Types.Exact<{
  file?: Types.InputMaybe<Types.Scalars['Upload']>;
}>;

export type UploadReportCoverDefaultBackgroundMutation = {
  __typename?: 'Mutation';
  uploadReportCoverDefaultBackground: {
    __typename?: 'AppSettings';
    login_background?: {
      __typename?: 'FileItem';
      file_name: string;
      url: string;
      id: string;
    } | null;
  };
};

export const UploadReportCoverDefaultBackgroundDocument = gql`
  mutation UploadReportCoverDefaultBackground($file: Upload) {
    uploadReportCoverDefaultBackground(file: $file) {
      login_background {
        file_name
        url
        id
      }
    }
  }
`;

export function useUploadReportCoverDefaultBackgroundMutation() {
  return Urql.useMutation<
    UploadReportCoverDefaultBackgroundMutation,
    UploadReportCoverDefaultBackgroundMutationVariables | void
  >(UploadReportCoverDefaultBackgroundDocument);
}
