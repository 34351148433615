import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
export type TaskQuestionFragment = {
  __typename?: 'TaskQuestion';
  id: string;
  question_id: string;
  task_form_id: string;
  answer?: string | null;
  answer_code?: string | null;
  note?: string | null;
  comment?: string | null;
  images?: Array<string> | null;
  highlighted: boolean;
  force_page_break_after: boolean;
  active_pages?: Array<number> | null;
  rows?: Array<{
    __typename?: 'TaskQuestionTableRow';
    row_id: string;
    question_id: string;
    column1?: string | null;
    column2?: string | null;
    column3?: string | null;
    column4?: string | null;
    column5?: string | null;
    column6?: string | null;
    column7?: string | null;
    column8?: string | null;
  } | null> | null;
  file?: { __typename?: 'FileItem'; id: string } | null;
};

export const TaskQuestionFragmentDoc = gql`
  fragment TaskQuestion on TaskQuestion {
    id
    question_id
    task_form_id
    answer
    answer_code
    note
    comment
    images
    highlighted
    force_page_break_after
    rows {
      row_id
      question_id
      column1
      column2
      column3
      column4
      column5
      column6
      column7
      column8
    }
    file {
      id
    }
    active_pages
  }
`;
