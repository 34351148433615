import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type TasksForPrimaryFormSelectQueryVariables = Types.Exact<{
  taskId: Types.Scalars['ID'];
}>;

export type TasksForPrimaryFormSelectQuery = {
  __typename?: 'Query';
  tasksForPrimaryFormSelect: {
    __typename?: 'TaskListResponse';
    data: Array<{
      __typename?: 'Task';
      id: string;
      customer?: { __typename?: 'Contact'; id: string; full_name?: string | null } | null;
      primary_form?: {
        __typename?: 'TaskForm';
        id: string;
        template: { __typename?: 'TaskTemplate'; id: string; title: string };
      } | null;
      client?: { __typename?: 'Client'; id: string } | null;
      primary_location?: {
        __typename?: 'TaskLocation';
        city?: string | null;
        street?: string | null;
        street_no?: string | null;
        postcode?: string | null;
      } | null;
    }>;
  };
};

export const TasksForPrimaryFormSelectDocument = gql`
  query TasksForPrimaryFormSelect($taskId: ID!) {
    tasksForPrimaryFormSelect(task_id: $taskId) {
      data {
        id
        customer {
          id
          full_name
        }
        primary_form {
          id
          template {
            id
            title
          }
        }
        client {
          id
        }
        primary_location {
          city
          street
          street_no
          postcode
        }
      }
    }
  }
`;

export function useTasksForPrimaryFormSelectQuery(
  options: Omit<Urql.UseQueryArgs<TasksForPrimaryFormSelectQueryVariables>, 'query'>,
) {
  return Urql.useQuery<TasksForPrimaryFormSelectQuery, TasksForPrimaryFormSelectQueryVariables>({
    query: TasksForPrimaryFormSelectDocument,
    ...options,
  });
}
