import { TaskGroupFilter, TaskGroupSort } from '@generated/graphql.generated';
import { CanTaskGroup } from '@module/casl/abilities/TaskGroupAbility';
import { RefreshButton } from '@module/layout';
import { useDialogs } from '@module/shared/dialogs';
import {
  FilterBar,
  FilterToolbar,
  ResetFilterContextButton,
  ToolbarFilterSearchBar,
  useFilterContext,
} from '@module/shared/filters';
import { Button, Toolbar, ToolbarItem, ToolbarSpacer } from '@progress/kendo-react-buttons';
import { useTranslation } from 'react-i18next';

import { CreateTaskGroupDialog } from '../../dialogs/CreateTaskGroupDialog';

export interface TaskGroupToolbarProps {
  onRefresh?: () => void;
  filter: TaskGroupFilter;
  sort: TaskGroupSort;
}

export const TaskGroupToolbar = (props: TaskGroupToolbarProps) => {
  const { t } = useTranslation();
  const { showDialog } = useDialogs();

  const handleRefresh = () => {
    props.onRefresh?.();
  };

  const handleCreateGroup = () => {
    showDialog({}, CreateTaskGroupDialog);
  };

  const filterContext = useFilterContext();

  return (
    <>
      <Toolbar className="!k-border-top-0 !k-border-x-0 !k-flex-shrink-0">
        <ToolbarFilterSearchBar />
        <FilterBar
          fields={filterContext.customFields}
          onFilterChange={filterContext.changeCustomFilter}
        />
        <ToolbarSpacer />
        <ToolbarItem>
          <ResetFilterContextButton />
        </ToolbarItem>
        <ToolbarItem>
          <RefreshButton onClick={handleRefresh} onlyIcon />
        </ToolbarItem>
        <CanTaskGroup action="createTaskGroup">
          <ToolbarItem>
            <Button
              iconClass="l-i-plus"
              size={'large'}
              themeColor={'primary'}
              onClick={handleCreateGroup}
            >
              {t('common.labels.create')}
            </Button>
          </ToolbarItem>
        </CanTaskGroup>
      </Toolbar>
      <FilterToolbar />
    </>
  );
};
