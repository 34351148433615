import { ReactNode } from 'react';

import { DrawerContextType } from './types';
import { DrawerContext } from './useDrawerContext';

export interface DrawerContextProviderProps {
  children?: ReactNode | undefined;
  drawerContext: DrawerContextType;
}

export const DrawerContextProvider = (props: DrawerContextProviderProps) => {
  return (
    <DrawerContext.Provider value={props.drawerContext}>{props.children}</DrawerContext.Provider>
  );
};
