import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import { TaskContactFragmentDoc } from './taskContactFragment.generated';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type UpdateTaskContactMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  input: Types.TaskContactUpdateInput;
}>;

export type UpdateTaskContactMutation = {
  __typename?: 'Mutation';
  updateTaskContact: {
    __typename?: 'Contact';
    id: string;
    role_id?: string | null;
    salutation_id?: string | null;
    function_id?: string | null;
    city?: string | null;
    country_id?: string | null;
    email?: string | null;
    email2?: string | null;
    fax_number?: string | null;
    first_name?: string | null;
    name?: string | null;
    mobile?: string | null;
    phone?: string | null;
    phone2?: string | null;
    postcode?: string | null;
    street?: string | null;
    street_no?: string | null;
    note?: string | null;
    role?: { __typename?: 'ContactRole'; role_id: Types.ContactRoleEnum } | null;
    country?: { __typename?: 'Country'; id: string; title: string } | null;
    salutation?: { __typename?: 'Salutation'; id: string; title: string } | null;
    function?: { __typename?: 'ContactFunction'; id: string; title: string } | null;
  };
};

export const UpdateTaskContactDocument = gql`
  mutation UpdateTaskContact($id: ID!, $input: TaskContactUpdateInput!) {
    updateTaskContact(id: $id, input: $input) {
      ...TaskContact
    }
  }
  ${TaskContactFragmentDoc}
`;

export function useUpdateTaskContactMutation() {
  return Urql.useMutation<UpdateTaskContactMutation, UpdateTaskContactMutationVariables>(
    UpdateTaskContactDocument,
  );
}
