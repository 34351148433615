import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import { DownloadFragmentDoc } from './downloadFragment.generated';
export type ExportResponseFragment = {
  __typename?: 'ExportResponse';
  success: boolean;
  message: string;
  download?: {
    __typename?: 'Download';
    id: string;
    state_id: Types.DownloadStateId;
    filename: string;
    file_ids: Array<string>;
    url_download: string;
  } | null;
};

export const ExportResponseFragmentDoc = gql`
  fragment ExportResponse on ExportResponse {
    success
    message
    download {
      ...Download
    }
  }
  ${DownloadFragmentDoc}
`;
