export default {
  labels: {
    category_id: 'Kategorie',
    body: 'E-Mail Text',
    filterTemplateKeys: 'Platzhalter durchsuchen...',
    preview: 'Vorschau',
    signature: 'Standard-E-Mail Vorlage',
    insurance: 'Versicherer',
    subject: 'Betreff',
    placeholder: 'Platzhalter hinzufügen',
    fileClassificationTags: 'Automatischer Tag für Dateien:',
    classificationTagsDescription:
      'Durch die Wahl eines Auftrag-Tags wird dieser Tag automatisch beim Auftrag hinzugefügt, sofern diese E-Mail verschickt wurde.',
    classificationTags: 'Automatischer Tag für Aufträge:',
    fileClassificationTagsDescription:
      'Durch die Wahl eines Datei-Tags wird dieser Tag automatisch bei der Datei hinzugefügt, sofern diese E-Mail mit dieser Datei als Attachment verschickt wurde.',
  },
  notifications: {
    emailTemplateCreate: {
      success: 'E-Mail Vorlage erstellt!',
      validationError: 'Validation failure!',
    },
    emailTemplateEdit: {
      success: 'E-Mail Vorlage gespeichert!',
      validationError: 'Validation failure!',
    },
    emailTemplateDelete: {
      success: 'E-Mail Vorlage gelöscht!',
    },
  },
  dialogs: {
    emailTemplateCreate: {
      title: 'E-Mail Vorlage erstellen',
      note: 'Hier können Sie E-Mail Vorlagen erstellen.',
      signatureNote:
        'Wenn „Standard-E-Mail Vorlage” aktiviert ist, dann wird diese Vorlage bei jeder neuen E-Mail direkt angezeigt.',
    },
    emailTemplateDelete: {
      title: 'E-Mail Vorlage löschen',
      note: 'E-Mail Vorlage wirklich löschen?',
    },
    chooseEmailTemplate: {
      title: 'E-Mail Vorlage auswählen',
      note: 'Die Auswahl eines Templates überschreibt bereits gesetzte Felder!',
      templates: 'Vorlage',
    },
    assignEmailTemplate: {
      title: 'E-Mail Vorlagen Zuweisung ändern',
      note: 'Hier können Sie die E-Mail Vorlagen den Versicherungen zuordnen.',
    },
  },

  pages: {
    emailTemplateOverview: {
      title: 'E-Mail Vorlagen',
      description: 'Hier sehen Sie eine Listen von E-Mail Vorlagen.',
      toolbar: { create: 'Vorlage erstellen' },
    },
    emailTemplateEdit: {
      title: 'E-Mail Vorlage - {{email_template_id}}',
      description: 'Hier können Sie die E-Mail Vorlage bearbeiten.',
    },
  },
};
