import { usePrevious } from '@module/shared/hooks';
import { isEqual } from 'lodash';
import { useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';

import { TaskNotification } from '..';
import { PageNotificationItem } from '../components/layout/PageNotification';

const addId = (notification: TaskNotification): PageNotificationItem => ({
  ...notification,
  id: uuid(),
});

export const useTaskNotifications = (defaultNotifications?: TaskNotification[] | null) => {
  const [taskNotifications, setTaskNotifications] = useState<PageNotificationItem[]>(() =>
    (defaultNotifications ?? []).map(addId),
  );

  const prevDefaultNotifications = usePrevious(defaultNotifications);

  useEffect(() => {
    if (!isEqual(defaultNotifications, prevDefaultNotifications)) {
      setTaskNotifications((defaultNotifications ?? []).map(addId));
    }
  }, [defaultNotifications, prevDefaultNotifications]);

  const removeNotification = (id: string) => {
    setTaskNotifications(taskNotifications.filter((n) => n.id !== id));
  };

  return {
    taskNotifications,
    removeNotification,
  };
};
