import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type NotificationTypesQueryVariables = Types.Exact<{ [key: string]: never }>;

export type NotificationTypesQuery = {
  __typename?: 'Query';
  notificationTypes: Array<{
    __typename?: 'NotificationType';
    id: string;
    type_id: Types.NotificationTypeId;
    group: string;
    title: string;
  }>;
};

export const NotificationTypesDocument = gql`
  query NotificationTypes {
    notificationTypes {
      id
      type_id
      group
      title
    }
  }
`;

export function useNotificationTypesQuery(
  options?: Omit<Urql.UseQueryArgs<NotificationTypesQueryVariables>, 'query'>,
) {
  return Urql.useQuery<NotificationTypesQuery, NotificationTypesQueryVariables>({
    query: NotificationTypesDocument,
    ...options,
  });
}
