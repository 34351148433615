export default {
  labels: {
    owner: 'Sachverständiger',
    revertControlling: 'Auftrag zurück zum Sachverständigen',
    loadInactiveAdjusters: 'Inaktive Sachverständige laden',
    inactiveAdjustersLoaded: 'Inaktive Sachverständige geladen',
  },
  components: {
    taskMapAdjusterPopup: {
      adjusterSelectSuccess: 'Sachverstäniger wurde ausgewählt',
    },

    taskMapTaskPopup: {
      assignedOwner: 'Aktueller Sachverständiger',
    },
  },

  dialogs: {
    confirmRevertControlling: {
      title: 'Auftrag zurück zum Sachverständigen übergeben',
    },
    changeTaskAssignments: {
      adjuster: 'Sachverständiger',
      note: 'Wählen Sie einen neuen Sachverständigen aus, der den Auftrag bearbeiten soll.',
      assignedOwner: 'Aktueller Sachverständiger: {{owner}}',
      noOwnerAssigned: 'Kein Sachverständiger zugewiesen',
      selectAdjuster: 'Sachverständigen auswählen...',
    },
  },
  forms: {
    createTask: {
      fields: {
        owner: {
          label: 'Sachverständiger',
          noLocationHint: 'Trage einen Ort ein um alle Sachverständigen zu sehen.',
        },
        damageNote: {
          label: 'Zu prüfende Sachen',
        },
      },
    },
  },
};
