import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type UpdateMailSettingsMutationVariables = Types.Exact<{
  input: Types.UpdateMailSettingsInput;
}>;

export type UpdateMailSettingsMutation = {
  __typename?: 'Mutation';
  updateMailSettings: {
    __typename?: 'AppSettings';
    id: string;
    mail_encryption?: Types.MailEncryptionType | null;
    mail_from_address?: string | null;
    mail_from_name?: string | null;
    mail_host?: string | null;
    mail_port?: number | null;
    mail_username?: string | null;
    mail_default_footer?: string | null;
    mail_send_failure_notice: boolean;
  };
};

export const UpdateMailSettingsDocument = gql`
  mutation UpdateMailSettings($input: UpdateMailSettingsInput!) {
    updateMailSettings(input: $input) {
      id
      mail_encryption
      mail_from_address
      mail_from_name
      mail_host
      mail_port
      mail_username
      mail_default_footer
      mail_send_failure_notice
    }
  }
`;

export function useUpdateMailSettingsMutation() {
  return Urql.useMutation<UpdateMailSettingsMutation, UpdateMailSettingsMutationVariables>(
    UpdateMailSettingsDocument,
  );
}
