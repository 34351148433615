import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import { InterfaceMessageTemplateFragmentDoc } from './interfaceMessageTemplateFragment.generated';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type InterfaceMessageTemplatesQueryVariables = Types.Exact<{
  filter: Types.InterfaceMessageTemplatesFilter;
  sort: Types.InterfaceMessageTemplatesSort;
  pagination: Types.PaginationInput;
}>;

export type InterfaceMessageTemplatesQuery = {
  __typename?: 'Query';
  interfaceMessageTemplates: {
    __typename?: 'InterfaceMessageTemplatePaginator';
    data: Array<{
      __typename?: 'InterfaceMessageTemplate';
      id: string;
      title: string;
      description?: string | null;
      message_type?: string | null;
      subject?: string | null;
      body?: string | null;
      category?: {
        __typename?: 'InterfaceMessageTemplateCategory';
        id: string;
        title: string;
      } | null;
      assignments: Array<{ __typename?: 'Client'; id: string; name: string }>;
    }>;
    paginatorInfo: { __typename?: 'PaginatorInfo'; total: number };
  };
};

export const InterfaceMessageTemplatesDocument = gql`
  query InterfaceMessageTemplates(
    $filter: InterfaceMessageTemplatesFilter!
    $sort: InterfaceMessageTemplatesSort!
    $pagination: PaginationInput!
  ) {
    interfaceMessageTemplates(filter: $filter, sort: $sort, pagination: $pagination) {
      data {
        ...InterfaceMessageTemplate
      }
      paginatorInfo {
        total
      }
    }
  }
  ${InterfaceMessageTemplateFragmentDoc}
`;

export function useInterfaceMessageTemplatesQuery(
  options: Omit<Urql.UseQueryArgs<InterfaceMessageTemplatesQueryVariables>, 'query'>,
) {
  return Urql.useQuery<InterfaceMessageTemplatesQuery, InterfaceMessageTemplatesQueryVariables>({
    query: InterfaceMessageTemplatesDocument,
    ...options,
  });
}
