import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ClientEmailNotificationTypesQueryVariables = Types.Exact<{ [key: string]: never }>;

export type ClientEmailNotificationTypesQuery = {
  __typename?: 'Query';
  clientEmailNotificationTypes: Array<{
    __typename?: 'NotificationType';
    id: string;
    type_id: Types.NotificationTypeId;
    group: string;
    title: string;
  }>;
};

export const ClientEmailNotificationTypesDocument = gql`
  query ClientEmailNotificationTypes {
    clientEmailNotificationTypes {
      id
      type_id
      group
      title
    }
  }
`;

export function useClientEmailNotificationTypesQuery(
  options?: Omit<Urql.UseQueryArgs<ClientEmailNotificationTypesQueryVariables>, 'query'>,
) {
  return Urql.useQuery<
    ClientEmailNotificationTypesQuery,
    ClientEmailNotificationTypesQueryVariables
  >({ query: ClientEmailNotificationTypesDocument, ...options });
}
