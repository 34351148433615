import { Button } from '@progress/kendo-react-buttons';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useDetectClickOutside } from '../../hooks';
import { useHistory } from './HistoryContext';
import { HistoryPopup } from './HistoryPopup';

export const HistoryButton = () => {
  const { history } = useHistory();
  const [historyVisible, setHistoryVisible] = useState<boolean>(false);
  const { t } = useTranslation();

  const historyPopupAnchor = useRef<HTMLDivElement>(null);
  const historyPopupRef = useDetectClickOutside<HTMLDivElement>(
    () => setHistoryVisible(false),
    100,
  );

  return (
    <>
      <div ref={historyPopupAnchor}>
        <div ref={historyPopupRef}>
          <Button
            disabled={history.length <= 1}
            onClick={() => setHistoryVisible(!historyVisible)}
            themeColor="primary"
            fillMode="flat"
            size="large"
            className="k-mr-1"
            aria-label={t('common.components.layout.historyPopup.label')}
          >
            <span className="l-i-history" />
          </Button>
        </div>
      </div>
      {historyVisible && historyPopupAnchor && (
        <HistoryPopup anchor={historyPopupAnchor} show={historyVisible} />
      )}
    </>
  );
};
