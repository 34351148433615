import {
  Ability,
  AbilityBuilder,
  AbilityClass,
  ForcedSubject,
  subject as subjectHelper,
} from '@casl/ability';
import { useCallback, useMemo } from 'react';

import { Permission } from '../../../types/graphql.generated';
import { GenericCan, GenericCanSharedProps } from '../components/GenericCan';
import { CurrentUserAbilityFragment } from '../graphql';
import { detectSubjectTypeByTypename as detectSubjectType } from '../helpers';
import { useMeOrDefault } from '../hooks';

interface AreaAssignmentSubject {
  me: CurrentUserAbilityFragment;
}

type AreaAssignmentSubjectArg = Partial<AreaAssignmentSubject>;

type Actions =
  | 'viewAreaAssignments'
  | 'updateAreaAssignments'
  | 'importAreaAssignments'
  | 'exportAreaAssignments';
type Subjects = AreaAssignmentSubject | 'AreaAssignmentSubject';

type AreaAssignmentAbility = Ability<[Actions, Subjects]>;
const userAbility = Ability as AbilityClass<AreaAssignmentAbility>;

export const useAreaAssignmentAbility = (): [
  AreaAssignmentAbility,
  (
    sub?: AreaAssignmentSubjectArg,
  ) => AreaAssignmentSubject & ForcedSubject<'AreaAssignmentSubject'>,
] => {
  const ability = useMemo(() => {
    const { can, build } = new AbilityBuilder(userAbility);

    can('viewAreaAssignments', 'AreaAssignmentSubject', {
      'me.globalPermissions': { $in: [Permission.AREA_ASSIGNMENT_INDEX] },
    });
    can('updateAreaAssignments', 'AreaAssignmentSubject', {
      'me.globalPermissions': { $in: [Permission.AREA_ASSIGNMENT_UPDATE] },
    });
    can('importAreaAssignments', 'AreaAssignmentSubject', {
      'me.globalPermissions': { $in: [Permission.AREA_ASSIGNMENT_IMPORT] },
    });
    can('exportAreaAssignments', 'AreaAssignmentSubject', {
      'me.globalPermissions': { $in: [Permission.AREA_ASSIGNMENT_EXPORT] },
    });

    return build({ detectSubjectType });
  }, []);

  const me = useMeOrDefault();
  const subject = useCallback(
    (sub?: AreaAssignmentSubjectArg) => {
      return subjectHelper('AreaAssignmentSubject', { me, ...sub });
    },
    [me],
  );

  return [ability, subject];
};

type CanAreaAssignmentProps = GenericCanSharedProps<Actions>;

export const CanAreaAssignment = (props: CanAreaAssignmentProps) => {
  const [areaAssignmentAbility, areaAssignmentSubject] = useAreaAssignmentAbility();

  return (
    <GenericCan<Actions, Subjects, AreaAssignmentAbility>
      ability={areaAssignmentAbility}
      subject={areaAssignmentSubject()}
      {...props}
    />
  );
};
