import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type PdfTemplatesForTaskQueryVariables = Types.Exact<{
  taskId: Types.Scalars['ID'];
}>;

export type PdfTemplatesForTaskQuery = {
  __typename?: 'Query';
  pdfTemplatesForTask: {
    __typename?: 'PdfTemplatePaginator';
    data: Array<{
      __typename?: 'PdfTemplate';
      id: string;
      title: string;
      custom: boolean;
      file: { __typename?: 'FileItem'; url_thumb?: string | null };
    }>;
  };
};

export const PdfTemplatesForTaskDocument = gql`
  query PdfTemplatesForTask($taskId: ID!) {
    pdfTemplatesForTask(task_id: $taskId) {
      data {
        id
        title
        custom
        file {
          url_thumb
        }
      }
    }
  }
`;

export function usePdfTemplatesForTaskQuery(
  options: Omit<Urql.UseQueryArgs<PdfTemplatesForTaskQueryVariables>, 'query'>,
) {
  return Urql.useQuery<PdfTemplatesForTaskQuery, PdfTemplatesForTaskQueryVariables>({
    query: PdfTemplatesForTaskDocument,
    ...options,
  });
}
