export default {
  dialogs: {
    createPayout: {
      note: 'Damit erzeugen Sie eine neue Auszahlung. Sämtliche Rechnungen, welche noch zu keiner Auszahlung gehören, werden für diese Auszahlung herangezogen. Die Einstellungen für Auszahlungen bei den Stammdaten für Sachverständige werden hierbei herangezogen. Wollen Sie eine neue Auszahlung starten?',
    },
  },
  pages: {
    payouts: {
      grid: {
        columns: {
          taskId: 'Besichtigung',
        },
      },
    },
  },
  components: {
    invoiceSelect: {
      columns: {
        taskId: 'Besichtigung',
      },
    },
  },
};
