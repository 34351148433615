import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type TaskInvoiceItemTemplateFragment = {
  __typename?: 'TaskInvoiceItemTemplate';
  title: string;
  unit?: string | null;
  unit_price: number;
  amount: number;
  sum_net: number;
  sum_gross: number;
  tax: number;
};

export type SuggestTaskInvoiceItemsMutationVariables = Types.Exact<{
  taskId: Types.Scalars['ID'];
}>;

export type SuggestTaskInvoiceItemsMutation = {
  __typename?: 'Mutation';
  suggestTaskInvoiceItems: Array<{
    __typename?: 'TaskInvoiceItemTemplate';
    title: string;
    unit?: string | null;
    unit_price: number;
    amount: number;
    sum_net: number;
    sum_gross: number;
    tax: number;
  }>;
};

export const TaskInvoiceItemTemplateFragmentDoc = gql`
  fragment TaskInvoiceItemTemplate on TaskInvoiceItemTemplate {
    title
    unit
    unit_price
    amount
    sum_net
    sum_gross
    tax
  }
`;
export const SuggestTaskInvoiceItemsDocument = gql`
  mutation SuggestTaskInvoiceItems($taskId: ID!) {
    suggestTaskInvoiceItems(task_id: $taskId) {
      ...TaskInvoiceItemTemplate
    }
  }
  ${TaskInvoiceItemTemplateFragmentDoc}
`;

export function useSuggestTaskInvoiceItemsMutation() {
  return Urql.useMutation<
    SuggestTaskInvoiceItemsMutation,
    SuggestTaskInvoiceItemsMutationVariables
  >(SuggestTaskInvoiceItemsDocument);
}
