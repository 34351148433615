import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import { AuthStateFragmentDoc } from './types.generated';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type RefreshTokenMutationVariables = Types.Exact<{
  input: Types.RefreshTokenInput;
}>;

export type RefreshTokenMutation = {
  __typename?: 'Mutation';
  refreshToken?: {
    __typename?: 'RefreshTokenResponse';
    message: string;
    authState: {
      __typename?: 'AuthState';
      data: {
        __typename?: 'AuthData';
        access_token: string;
        refresh_token: string;
        expires_at: string;
        refresh_token_expires_at: string;
      };
    };
  } | null;
};

export const RefreshTokenDocument = gql`
  mutation RefreshToken($input: RefreshTokenInput!) {
    refreshToken(input: $input) {
      message
      authState {
        ...AuthState
      }
    }
  }
  ${AuthStateFragmentDoc}
`;

export function useRefreshTokenMutation() {
  return Urql.useMutation<RefreshTokenMutation, RefreshTokenMutationVariables>(
    RefreshTokenDocument,
  );
}
