export default {
  labels: {
    role: 'Uloga',
  },
  errors: {
    not_found: {
      title: 'Korisnik nije pronađen',
      note: 'Korisnik kojeg ste tražili nije pronađen',
    },
  },
  notifications: {
    update_user_role: {
      success: 'Uloga korisnika promjenjena!',
    },
    update_user_password: {
      success: 'Lozinka je promjenjena!',
    },
    update_user: {
      success: 'Podaci o korisniku su spremljeni!',
    },
    createUser: {
      success: 'Korisnik je kreiran!',
    },
    deleteUser: {
      success: 'Korisnik je izbrisan!',
    },
    send_email_verification_link: {
      success: 'Poveznica za potvrdu je poslana!',
    },
    resendInvitation: {
      success: 'Poveznica je poslana!',
    },
    send_qr_code: {
      success: 'QR kod je poslan!',
    },
  },
  components: {
    user_change_password_panel: {
      title: 'Promjena lozinke',
    },
    user_common_panel: {
      title: 'Pojedinosti o korisniku',
      sections: {
        userDetails: {
          title: 'Pojedinosti o korisniku',
          description: 'Ovdje možete vidjeti podatke o korisniku',
        },
      },
    },
    user_email_verification_panel: {
      title: 'Potvrda E-Pošte',
      submit: 'Pošalji e-poštu za potvrdu',
      send: 'Pošalji',
      status: {
        verified: 'E-pošta potvrđena.',
        unverified: 'ADresa e-pošte tek treba biti potvrđena.',
      },
    },
    user_update_panel: {
      title: 'Pojedinosti o korisniku',
    },
    update_user_role_panel: {
      title: 'Promjena korisničke uloge',
    },
    user_send_qr_code_panel: {
      title: 'Pošalji QR kod',
      description: 'Ovdje možete poslati QR kod za postavljanje 2FA korisniku',
      submit: 'Pošalji QR kod',
    },
  },
  dialogs: {
    createUser: {
      title: 'Kreiraj korisnika',
      note: 'Napravite novi korisnički račun.',
    },
    deleteUser: {
      title: 'Izbriši korisnika',
      note: 'Želite li stvarno izbrisati korisnika?',
    },
  },
  pages: {
    overview: {
      title: 'Korisnici - Pregled',
      description: 'Ovdje možete vidjeti popis postojećih korisnika i njihova prava pristupa.',
      toolbar: {
        create: 'Novi korisnik',
      },
    },
    detail: {
      title: 'Korisnik - {{user_id}}',
      description:
        'Ovdje možete urediti korisničke podatke, npr. (lozinke, korisničku ulogu, ime itd.).',
      tabs: {
        common: 'Općenito',
        password: 'Lozinka',
        verify_email: 'Potvrdi E-Poštu',
        role: 'Korisnička uloga',
      },
    },
  },
};
