import Pusher from 'pusher-js';
import { ExecutionResult, Operation, SubscriptionOperation } from 'urql';
import { Observable } from 'zen-observable-ts';

interface ObserverLike<T> {
  next: (value: T) => void;
  error: (err: unknown) => void;
  complete: () => void;
}

// ExecutionResult is not a generic, so we extend this interface to type extensions that our
// GraphQL API sends.
interface ExecutionResultOverride extends ExecutionResult {
  extensions?: { lighthouse_subscriptions?: { channel?: string } };
}

export class GraphqlSubscriptionPusherClient {
  private pusher: Pusher;
  private urlGraphql: string;

  constructor(pusher: Pusher, urlGraphql: string) {
    this.pusher = pusher;
    this.urlGraphql = urlGraphql;
  }

  subscribe(request: SubscriptionOperation, operation: Operation) {
    return new Observable<ExecutionResult>((sink) => {
      let channelName: string | undefined;

      this.fetchOperation(request, operation).then((response) => {
        channelName = response?.extensions?.lighthouse_subscriptions?.channel;

        if (!channelName) return;

        this.subscribeToChannel(channelName, sink);
      });

      return () => this.unsubscribeFromChannel(channelName);
    });
  }

  private fetchOperation(request: SubscriptionOperation, operation: Operation) {
    const body = {
      operationName: request.operationName,
      query: request.query,
      variables: request.variables,
    };

    const headers: HeadersInit = {
      ...(operation.context.fetchOptions
        ? (operation.context.fetchOptions as RequestInit).headers
        : {}),
      Accept: 'application/json',
      'Content-Type': 'application/json',
    };

    return fetch(this.urlGraphql, {
      method: 'POST',
      headers,
      body: JSON.stringify(body),
    }).then((response) => response.json() as ExecutionResultOverride);
  }

  private subscribeToChannel(channelName: string, sink: ObserverLike<ExecutionResult>) {
    const channel = this.pusher.subscribe(channelName);
    channel.bind('lighthouse-subscription', (payload: { more: never; result: never }) => {
      if (!payload.more) {
        this.unsubscribeFromChannel(channelName);

        sink.complete();
      }

      const result = payload.result;

      if (result) {
        sink.next(result);
      }
    });
  }

  private unsubscribeFromChannel(channelName: string | undefined) {
    if (channelName) {
      this.pusher.unsubscribe(channelName);
    }
  }
}
