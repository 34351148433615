import './SelectImageColor.scss';

import { useAppSettingsPublicQuery } from '@module/common/graphql';
import { CloseButton, Dialog, DialogContent, DialogHeader, DialogHeaderBar } from '@module/layout';
import { DialogActionsBar } from '@progress/kendo-react-dialogs';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { ImageColorPicker } from 'react-image-color-picker';

import { DialogProps, useDialogs } from '../../dialogs/DialogsContext';

interface SelectImageColorDialogProps extends DialogProps {
  handleChange: ((color: string) => void) | undefined;
}

export const SelectImageColorDialog = (props: SelectImageColorDialogProps) => {
  const { dialogId, handleChange } = props;
  const { hideDialog } = useDialogs();
  const { t } = useTranslation();
  const [{ data }] = useAppSettingsPublicQuery();

  const handleClose = useCallback(() => {
    hideDialog(dialogId);
  }, [hideDialog, dialogId]);

  const handleColorPick = (color: string) => {
    handleChange?.(color);
    hideDialog(dialogId);
  };

  return (
    <Dialog className="dialog-content-no-padding" width="medium" onClose={handleClose}>
      <DialogHeaderBar>
        <DialogHeader title={t('templates.components.imageColorPicker.title')} />
      </DialogHeaderBar>
      <DialogContent>
        <div className="SelectImageColor">
          <ImageColorPicker
            onColorPick={handleColorPick}
            imgSrc={data?.appSettingsPublic.url_login_logo || ''}
            zoom={1}
          />
        </div>
      </DialogContent>

      <DialogActionsBar layout="end">
        <CloseButton onClick={handleClose} />
      </DialogActionsBar>
    </Dialog>
  );
};
