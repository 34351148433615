import type { Identifier } from 'dnd-core';
import { ReactNode, useRef } from 'react';
import { DragSourceMonitor, useDrag, useDrop } from 'react-dnd';

interface DraggableCardProps {
  children: ReactNode;
  index: number;
  id: string;
  moveCard: (dragIndex: number, hoverIndex: number) => void;
  onDropItem?: () => void;
  disableDrag?: boolean;
}

interface DragItem {
  index: number;
  id: string;
  type: string;
}

export const DraggableCard = ({
  children,
  id,
  index,
  moveCard,
  onDropItem,
  disableDrag = false,
}: DraggableCardProps) => {
  const ref = useRef<HTMLDivElement>(null);

  const [{ handlerId }, drop] = useDrop<DragItem, void, { handlerId: Identifier | null }>({
    accept: 'FORM-LIST-ITEM',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: DragItem, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const destinationBoundingRect = ref.current?.getBoundingClientRect();

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      const x = clientOffset?.x;
      const y = clientOffset?.y;
      if (!x || !y) return;
      // dont move items, when mouse is outside destination rectangle
      if (x < destinationBoundingRect.left || x >= destinationBoundingRect.right) return;
      if (y < destinationBoundingRect.top || y >= destinationBoundingRect.bottom) return;

      moveCard(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
    // trigger mutation that reorders forms when mutation is available
    drop() {
      onDropItem?.();
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: 'FORM-LIST-ITEM',
    canDrag: () => !disableDrag,
    item: () => {
      return { id, index };
    },
    collect: (monitor: DragSourceMonitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));
  return (
    <div ref={ref} style={{ opacity }} data-handler-id={handlerId}>
      {children}
    </div>
  );
};
