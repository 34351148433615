import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type UpsertClientAdditionalInsuranceInputMutationVariables = Types.Exact<{
  client_id: Types.Scalars['ID'];
  input: Types.ClientAdditionalInsuranceInput;
}>;

export type UpsertClientAdditionalInsuranceInputMutation = {
  __typename?: 'Mutation';
  upsertClientAdditionalInsurance: {
    __typename?: 'Client';
    client_additional_insurance?: {
      __typename?: 'ClientAdditionalInsurance';
      damage_number_mask?: string | null;
      term_of_payment?: number | null;
    } | null;
  };
};

export const UpsertClientAdditionalInsuranceInputDocument = gql`
  mutation UpsertClientAdditionalInsuranceInput(
    $client_id: ID!
    $input: ClientAdditionalInsuranceInput!
  ) {
    upsertClientAdditionalInsurance(client_id: $client_id, input: $input) {
      client_additional_insurance {
        damage_number_mask
        term_of_payment
      }
    }
  }
`;

export function useUpsertClientAdditionalInsuranceInputMutation() {
  return Urql.useMutation<
    UpsertClientAdditionalInsuranceInputMutation,
    UpsertClientAdditionalInsuranceInputMutationVariables
  >(UpsertClientAdditionalInsuranceInputDocument);
}
