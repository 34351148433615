import { useTranslation } from 'react-i18next';

import { PrimaryButton, PrimaryButtonProps } from './PrimaryButton';

export type SaveButtonProps = Omit<PrimaryButtonProps, 'iconClass'>;
export const SaveButton = (props: SaveButtonProps) => {
  const { t } = useTranslation();
  const { label = t('common.labels.save') } = props;
  return <PrimaryButton {...props} label={label} iconClass="l-i-save" />;
};
