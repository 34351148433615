import Icon403 from '@image/empty-states/403.svg';
import { PublicLayout } from '@module/layout';
import { Button } from '@progress/kendo-react-buttons';
import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

type IpAllowlistContextType = [boolean, Dispatch<SetStateAction<boolean>>] | null;

export const IpAllowlistContext = createContext<IpAllowlistContextType>(null);

export const useIpAllowlistGuard = () => {
  const context = useContext(IpAllowlistContext);
  if (!context) {
    throw new Error('useIpAllowlistGuard must be used within an IpAllowlistGuard');
  }
  return context;
};

export interface IpAllowlistGuard {
  children: ReactNode;
}
// This component is used to guard the application from unauthorized access.
// If the guard is active, it will render a "no permission" page.

// There is one unhandled edge case (which should be fine, because that should not happen often):
// If your IP is not allowlisted, you end up at this page. If a admin allowlists your IP, you need to refresh the page.
// If you do not refresh the page, but trigger a "history.back()" action, the URL will change, but the page will not reload.
// So after a user gets allowlisted, a full page refresh is mandatory.
export const IpAllowlistGuard = ({ children }: IpAllowlistGuard) => {
  const [isGuardActive, setIsGuardActive] = useState<boolean>(false);
  const { t } = useTranslation();

  if (isGuardActive) {
    return (
      <PublicLayout>
        <div
          className="k-display-flex k-flex-grow k-flex-column k-justify-content-center k-align-items-center k-p-4 k-text-center"
          style={{ maxWidth: 400, margin: '0 auto' }}
        >
          <h5>{t('common.ipAllowlistGuard.error')}</h5>
          <div className="k-display-flex k-flex-column k-justify-content-center">
            <div>
              <img src={Icon403} width={240} />
            </div>
          </div>
          <h1>{t('common.ipAllowlistGuard.title')}</h1>
          <p>{t('common.ipAllowlistGuard.note')}</p>
          <div className="k-flex-wrap k-d-flex k-gap-x-6">
            <Button
              iconClass="l-i-refresh-ccw"
              size="large"
              themeColor="primary"
              onClick={() => window.location.reload()}
            >
              {t('common.labels.refresh')}
            </Button>
          </div>
        </div>
      </PublicLayout>
    );
  }

  return (
    <IpAllowlistContext.Provider value={[isGuardActive, setIsGuardActive]}>
      {children}
    </IpAllowlistContext.Provider>
  );
};
