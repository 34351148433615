import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import { SubformClassificationTagFragmentDoc } from './subformClassificationTagFragment.generated';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CreateSubformClassificationTagMutationVariables = Types.Exact<{
  type_id: Types.Scalars['ID'];
  input: Types.TagInput;
}>;

export type CreateSubformClassificationTagMutation = {
  __typename?: 'Mutation';
  createTag: {
    __typename?: 'Tag';
    id: string;
    title: string;
    color: string;
    type_id: string;
    type: { __typename?: 'TagType'; id: string; title: string; color: string };
  };
};

export const CreateSubformClassificationTagDocument = gql`
  mutation CreateSubformClassificationTag($type_id: ID!, $input: TagInput!) {
    createTag(type_id: $type_id, input: $input) {
      ...SubformClassificationTag
    }
  }
  ${SubformClassificationTagFragmentDoc}
`;

export function useCreateSubformClassificationTagMutation() {
  return Urql.useMutation<
    CreateSubformClassificationTagMutation,
    CreateSubformClassificationTagMutationVariables
  >(CreateSubformClassificationTagDocument);
}
