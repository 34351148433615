import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import { ExportResponseFragmentDoc } from '../../../common/graphql/generated/exportResponseFragment.generated';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ExportTasksReworkDocumentsMutationVariables = Types.Exact<{
  filter: Types.TasksFilter;
  sort: Types.TasksSort;
  config: Types.ExportConfigInput;
}>;

export type ExportTasksReworkDocumentsMutation = {
  __typename?: 'Mutation';
  exportTasksReworkDocuments: {
    __typename?: 'ExportResponse';
    success: boolean;
    message: string;
    download?: {
      __typename?: 'Download';
      id: string;
      state_id: Types.DownloadStateId;
      filename: string;
      file_ids: Array<string>;
      url_download: string;
    } | null;
  };
};

export const ExportTasksReworkDocumentsDocument = gql`
  mutation ExportTasksReworkDocuments(
    $filter: TasksFilter!
    $sort: TasksSort!
    $config: ExportConfigInput!
  ) {
    exportTasksReworkDocuments(filter: $filter, sort: $sort, config: $config) {
      ...ExportResponse
    }
  }
  ${ExportResponseFragmentDoc}
`;

export function useExportTasksReworkDocumentsMutation() {
  return Urql.useMutation<
    ExportTasksReworkDocumentsMutation,
    ExportTasksReworkDocumentsMutationVariables
  >(ExportTasksReworkDocumentsDocument);
}
