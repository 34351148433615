export default {
  dialogs: {
    userCreate: {
      title: 'Create user',
      cancel: 'Cancel',
      submit: 'Create',
      fields: {
        email: {
          label: 'Email',
          hint: 'Enter your email e.g. max.mustermann@example.com',
        },
        firstname: {
          label: 'Firstname',
        },
        lastname: {
          label: 'Lastname',
        },
        birthday: {
          label: 'Birthday',
        },
      },
    },
    userDelete: {
      title: 'Delete user',
      cancel: 'Cancel',
      submit: 'Delete',
      note: 'Really delete user?',
    },
  },
  pages: {
    userOverview: {
      grid: {
        columns: {
          name: 'Name',
          email: 'Email',
          phone: 'Phone',
        },
      },
      toolbar: {
        create: {
          label: 'Create',
        },
      },
    },
  },
};
