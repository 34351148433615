import './PageHeader.scss';

import { useMediaQueries } from '@module/layout';
import { ReactNode } from 'react';

import { DrawerToggleButton, useDrawerContext } from '../drawer';
import { GlobalSearch } from '../GlobalSearch';
import { usePageContext, usePageSlot } from './PageContext';

export interface PageHeaderProps {
  history?: ReactNode;
  notifications?: ReactNode;
  avatar?: ReactNode;
  logoutTimer?: ReactNode;
  changeUser?: ReactNode;
  appMessageIndicator?: ReactNode;
}

export const PageHeader = (props: PageHeaderProps) => {
  const { history, notifications, avatar, changeUser, appMessageIndicator, logoutTimer } = props;

  const drawer = useDrawerContext();
  const page = usePageContext();

  const { mdUp } = useMediaQueries();

  const { setElement: setLeftElement } = usePageSlot('left');

  return (
    <header className="PageHeader">
      {/* On pages with a sidebar, we don't want a toggle button here since the button is already placed in the sidebar. */}
      {drawer.layout === 'mobile' && !page?.hasSidebar && (
        <div className="PageHeaderDrawerToggle">
          <DrawerToggleButton />
        </div>
      )}

      <div className="PageHeaderSlots">
        <div className="k-display-flex k-flex-row k-align-items-center" style={{ minWidth: 0 }}>
          {mdUp && <>{history}</>}

          <div style={{ minWidth: 0 }} ref={setLeftElement} />
        </div>
      </div>

      <GlobalSearch />

      <div className="PageHeaderFixedElements">
        {logoutTimer}
        {changeUser}
        {appMessageIndicator}
        {mdUp && <>{notifications}</>}
        {avatar}
      </div>
    </header>
  );
};
