export default {
  toolbar: {
    close: 'Zatvori',
  },
  labels: {
    you: 'Ti',
    participantsEmpty: 'Čeka se još sudionika',
  },
  dialogs: {
    update: {
      join: 'Pridruži se',
      title: 'Uredi sastanak',
    },
    location: {
      title: 'Lokacija',
    },
    pdf: {
      title: 'Pokazati dokument',
    },
    create: {
      title: 'Kreirajte sastanak',
      start: 'Kreirajte sastanak',
      note: 'Ovdje možete kreirati novi sastanak.',
      labels: {
        type: 'Vrsta zahtjeva',
        phoneNumber: 'Telefon',
        date: 'Datum/Vrijeme',
        duration: 'Trajanje',
        customerName: 'Korisnik',
        phoneNumberPrefix: 'Prefiks',
        customerMail: 'E-Pošta',
        notes: 'Bilješke',
        allowUpload:
          'Omogućite korisnicima da učitaju vlastite fotografije putem veze s pozivnicom.',
        hasVideo: 's Videom',
        sendEmail: 'Pošaljite pozivnicu izravno na ovu e-poštu',
        sendSMS: 'Pošaljite pozivnicu izravno na ovaj broj mobitela',
        allowForm: 'Omogućite korisnicima da odgovore na pitanja obrasca.',
      },
      tooltips: {
        notes:
          'Ovdje unesite napomenu za korisnika. To je prikazano na njegovom portalu za korisnike',
      },
    },
  },
  pages: {
    call: {
      screenshot: 'Fotografiraj',
      screenshotHeadline: 'Fotografije',
      messages: {
        screenshotDeleted: 'Fotografija izbrisana',
      },
      permissionError: {
        title: 'Pogreška dopuštenja',
        microphone: {
          title: 'Dopuštenje za mikrofon nije odobreno',
          permissionDenied: 'Dopustite pristup svom mikrofonu.',
        },
        camera: {
          title: 'Dopuštenje za kameru nije odobreno',
          permissionDenied: 'Dopustite pristup svojoj kameri.',
        },
        geolocation: {
          title: 'Dopuštenje za lokaciju nije odobreno',
          permissionDenied: 'Dopustite pristup svojoj lokaciji.',
          positionUnavailable: 'Vašu lokaciju nije bilo moguće utvrditi. Molim te pokušaj ponovno.',
          timeout: 'Došlo je do problema s dobivanjem vaše lokacije. Molim te pokušaj ponovno.',
        },
      },
    },
    meeting: {
      pageTitle: 'Video-Sastanak',
      title: 'Detalji događaja: {{ number }}',
      headlines: {
        schedule: 'Postupak',
        documents: 'Dokumenti',
        images: 'Učitavanje fotografija',
        form: 'Oblik',
      },
      teaser: {
        images:
          'Organizator vam je dopustio učitavanje fotografija. Ako se to od vas zatraži, prenesite smislene fotografije procesa.',
      },
      labels: {
        owner: 'Osoba za kontakt',
        contact: 'Kontakt',
        customer: 'Korisnik',
        number: 'Broj zahtjeva',
        notes: 'Bilješke',
        date: 'Video-Termin',
        join: 'Pridružite se videu sada',
        uploadImages: 'Učitaj slike',
        step: {
          first: 'Zahtjev je uspješno kreiran',
          second: 'Video-Termin',
          third: 'Pregled oštećenja i završetak',
        },
        submitForm: 'Pošalji obrazac',
      },
      documents: {
        note: 'Organizator vam je osigurao dokument. Dokument možete otvoriti klikom na sliku i po potrebi ga potpisati.',
      },
      form: {
        note: 'Organizator je tražio odgovore na sljedeća pitanja. Ispunite ova pitanja istinito i kliknite na Pošalji na kraju obrasca.',
        hasForm: 'Već ste ispunili obrazac',
      },
      dialogs: {
        submitForm: {
          title: 'Pošalji obrazac',
          description:
            'Želite li stvarno poslati obrazac? Ovim šaljete obrazac, daljnje izmjene više nisu moguće. Želite li nastaviti?',
        },
      },
      notifications: {
        form: {
          success: 'Obrazac je uspješno poslan',
          error: 'Pogreška pri slanju obrasca',
        },
      },
    },
  },
};
