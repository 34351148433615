import { useAuthLogic } from '@module/auth';
import {
  InitialLoginInput,
  Loader,
  Message,
  PageTitle,
  PasswordFieldExtension,
  useAppSettingsPublicQuery,
  useBrandingData,
} from '@module/common';
import { PublicLayout } from '@module/layout';
import { Nl2Br } from '@module/shared/components';
import {
  Form,
  FormButtons,
  PasswordField,
  PasswordFieldExtensionProps,
  useSchemaValidator,
} from '@module/shared/forms';
import { NotificationType, useNotifications } from '@module/shared/notifications';
import { Button } from '@progress/kendo-react-buttons';
import { Typography } from '@progress/kendo-react-common';
import { Field, FormElement } from '@progress/kendo-react-form';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { ObjectSchema } from 'yup';
import * as yup from 'yup';

const schema: ObjectSchema<InitialLoginInput> = yup.object({
  password: yup.string().required(),
  password_confirmation: yup.string().required(),
  token: yup.string().required(),
});

const FormFields = () => {
  const { t } = useTranslation();

  const renderPasswordStrength = (props: PasswordFieldExtensionProps) => {
    return <PasswordFieldExtension {...props} />;
  };

  return (
    <FormElement>
      <div className="k-text-center">
        <Nl2Br text={t('auth.pages.initialLogin.note')} />
      </div>
      <Field
        id="initial.login.password"
        name={'password'}
        label={t('common.labels.password')}
        component={PasswordField}
        extension={renderPasswordStrength}
      />
      <Field
        id="initial.login.password_confirmation"
        name={'password_confirmation'}
        label={t('common.labels.password_confirmation')}
        component={PasswordField}
        extension={renderPasswordStrength}
      />
      <FormButtons>
        <Button className="k-flex-grow" themeColor="primary" size="large" type="submit">
          {t('auth.pages.initialLogin.submit')}
        </Button>
      </FormButtons>
    </FormElement>
  );
};

export const InitialLoginPage = () => {
  const { t } = useTranslation();
  const { showNotification } = useNotifications();
  const [message, setMessage] = useState<string>();
  const [error, setError] = useState<string>();
  const { initialLogin } = useAuthLogic();
  const [showLoader, setShowLoader] = useState(false);
  const navigate = useNavigate();
  const validator = useSchemaValidator(schema);
  const branding = useBrandingData();
  const [{ data, fetching }] = useAppSettingsPublicQuery();

  // get url params
  const { token } = useParams();
  const initialValues = useMemo(() => {
    return { token };
  }, [token]);

  const handleSubmit = (values: { [name: string]: unknown }) => {
    const input = values as InitialLoginInput;

    setShowLoader(true);

    initialLogin(input).then((response) => {
      if (!response.error) {
        setMessage(response.data?.initialLogin.message);
        setError(undefined);

        showNotification(response.data?.initialLogin.message, NotificationType.Success);
        navigate('/dashboard');

        return;
      }

      if (response.error) {
        setMessage(undefined);
        setError(response.error.graphQLErrors[0].message);
        showNotification(response.error.graphQLErrors[0].message, NotificationType.Error);
      }

      setShowLoader(false);
    });
  };

  return (
    <PublicLayout>
      <PageTitle
        title={
          data?.appSettingsPublic.app_title
            ? data?.appSettingsPublic.app_title + ' - Login'
            : 'Login'
        }
      />
      <div className="LoginPage">
        <div className="login-background">
          <img src={branding.background} />
        </div>
        <div className="login-modal">
          <div className="login-modal-container">
            <div className="login-modal-input">
              <div className="k-display-flex k-flex-column k-justify-content-center">
                <div>
                  <img src={branding.logo} style={{ maxWidth: '400px', maxHeight: '20vh' }} />
                </div>
              </div>
              <Typography.h1>{data?.appSettingsPublic.app_title}</Typography.h1>
              {!fetching && data?.appSettingsPublic.login_welcome_text ? (
                <p>{data?.appSettingsPublic.login_welcome_text}</p>
              ) : (
                <p>{t('auth.pages.initialLogin.subtitle')}</p>
              )}
              {message && (
                <div className="k-mb-4">
                  <Message type="success">{message}</Message>
                </div>
              )}
              {error && (
                <div className="k-mb-4">
                  <Message type="error">{error}</Message>
                </div>
              )}
              <Form
                schema={schema}
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validator={validator}
                render={() => <FormFields />}
              />
              {showLoader && <Loader />}
              <div className="login-version">
                <span className="k-font-weight-semibold">
                  {data?.appSettingsPublic.app_title ?? branding.name}
                </span>
                <span className="k-fs-sm">
                  {t('common.labels.version')} {branding.version}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PublicLayout>
  );
};
