export default {
  components: {
    disabledFormsTab: {
      header: {
        description: 'Einzelne Formulare für den Sachverständigen deaktivieren',
      },
    },
    hiddenInsurancesTab: {
      header: {
        description: 'Hier können Sie den Sachverständigen für Versicherungen sperren',
      },
    },
  },
  pages: {
    clientOverview: {
      description:
        'Hier sehen Sie eine Liste von Sachverständigen, Teamleitern und Versicherungen.',
    },
  },
};
