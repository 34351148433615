import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type AssignInterfaceMessageTemplatesMutationVariables = Types.Exact<{
  client_id: Types.Scalars['ID'];
  interface_message_template_ids: Array<Types.Scalars['ID']> | Types.Scalars['ID'];
}>;

export type AssignInterfaceMessageTemplatesMutation = {
  __typename?: 'Mutation';
  assignInterfaceMessageTemplatesToClientDirectMessages: {
    __typename?: 'ResponseMessage';
    success?: boolean | null;
    message?: string | null;
  };
};

export const AssignInterfaceMessageTemplatesDocument = gql`
  mutation AssignInterfaceMessageTemplates(
    $client_id: ID!
    $interface_message_template_ids: [ID!]!
  ) {
    assignInterfaceMessageTemplatesToClientDirectMessages(
      client_id: $client_id
      interface_message_template_ids: $interface_message_template_ids
    ) {
      success
      message
    }
  }
`;

export function useAssignInterfaceMessageTemplatesMutation() {
  return Urql.useMutation<
    AssignInterfaceMessageTemplatesMutation,
    AssignInterfaceMessageTemplatesMutationVariables
  >(AssignInterfaceMessageTemplatesDocument);
}
