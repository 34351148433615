import { Button, ButtonProps } from '@progress/kendo-react-buttons';

export interface ErrorButtonProps
  extends Omit<ButtonProps, 'children' | 'themeColor' | 'size' | 'icon' | 'fillMode'> {
  label?: string;
}

export const ErrorButton = (props: ErrorButtonProps) => {
  const { label, type = 'button', ...rest } = props;

  return (
    <Button {...rest} type={type} size="large" themeColor="error">
      {label}
    </Button>
  );
};
