import {
  Ability,
  AbilityBuilder,
  AbilityClass,
  ForcedSubject,
  subject as subjectHelper,
} from '@casl/ability';
import { Permission } from '@generated/graphql.generated';
import { useCallback, useMemo } from 'react';

import { GenericCan, GenericCanSharedProps } from '../components/GenericCan';
import { CurrentUserAbilityFragment, TaskProposalAbilityFragment } from '../graphql';
import { detectSubjectTypeByTypename as detectSubjectType } from '../helpers';
import { useMeOrDefault } from '../hooks';

interface TaskProposalSubject {
  me: CurrentUserAbilityFragment;
  proposal?: TaskProposalAbilityFragment;
}

type TaskProposalSubjectArg = Partial<TaskProposalSubject>;

type Actions =
  | 'viewProposals'
  | 'readProposal'
  | 'createProposals'
  | 'createInvoiceFromProposal'
  | 'deleteProposal'
  | 'updateProposal'
  | 'completeProposal'
  | 'acceptProposal'
  | 'rejectProposal'
  | 'cancelProposal'
  | 'syncProposal'
  | 'editNote';
type Subjects = TaskProposalSubject | 'TaskProposalSubject';

export enum TaskProposalActionType {
  READ = 'readProposal',
  DELETE = 'deleteProposal',
  UPDATE = 'updateProposal',
  COMPLETE = 'completeProposal',
  ACCEPT = 'acceptProposal',
  REJECT = 'rejectProposal',
  CANCEL = 'cancelProposal',
  SYNC = 'syncProposal',
  EDIT_PROPOSAL_NOTE = 'editNote',
  CREATE_INVOICE_FROM_PROPOSAL = 'createInvoiceFromProposal',
}

type TaskProposalAbility = Ability<[Actions, Subjects]>;
const taskProposalAbility = Ability as AbilityClass<TaskProposalAbility>;

export const useTaskProposalAbility = (): [
  TaskProposalAbility,
  (sub?: TaskProposalSubjectArg) => TaskProposalSubject & ForcedSubject<'TaskProposalSubject'>,
] => {
  const ability = useMemo(() => {
    const { can, build } = new AbilityBuilder(taskProposalAbility);
    // global permissions
    can('viewProposals', 'TaskProposalSubject', {
      'me.globalPermissions': { $in: [Permission.TASK_PROPOSAL_INDEX] },
    });
    can('createProposals', 'TaskProposalSubject', {
      'me.globalPermissions': { $in: [Permission.TASK_PROPOSAL_CREATE] },
    });
    can('readProposal', 'TaskProposalSubject', {
      'me.globalPermissions': { $in: [Permission.TASK_PROPOSAL_READ] },
    });

    // proposal specific permissions
    can('syncProposal', 'TaskProposalSubject', {
      'proposal.permissions': { $in: [Permission.TASK_PROPOSAL_SYNC] },
    });
    can('deleteProposal', 'TaskProposalSubject', {
      'proposal.permissions': { $in: [Permission.TASK_PROPOSAL_DELETE] },
    });
    can('updateProposal', 'TaskProposalSubject', {
      'proposal.permissions': { $in: [Permission.TASK_PROPOSAL_UPDATE] },
    });
    can('editNote', 'TaskProposalSubject', {
      'proposal.permissions': { $in: [Permission.TASK_PROPOSAL_UPDATE] },
    });
    can('createInvoiceFromProposal', 'TaskProposalSubject', {
      'proposal.permissions': { $in: [Permission.TASK_PROPOSAL_CREATE_INVOICE_FROM_PROPOSAL] },
    });
    can('completeProposal', 'TaskProposalSubject', {
      'proposal.permissions': { $in: [Permission.TASK_PROPOSAL_COMPLETE] },
    });
    can('acceptProposal', 'TaskProposalSubject', {
      'proposal.permissions': { $in: [Permission.TASK_PROPOSAL_ACCEPT] },
    });
    can('rejectProposal', 'TaskProposalSubject', {
      'proposal.permissions': { $in: [Permission.TASK_PROPOSAL_REJECT] },
    });
    can('cancelProposal', 'TaskProposalSubject', {
      'proposal.permissions': { $in: [Permission.TASK_PROPOSAL_CANCEL] },
    });

    return build({ detectSubjectType });
  }, []);

  const me = useMeOrDefault();
  const subject = useCallback(
    (sub?: TaskProposalSubjectArg) => {
      return subjectHelper('TaskProposalSubject', { me, ...sub });
    },
    [me],
  );

  return [ability, subject];
};

type CanTaskProposalProps = GenericCanSharedProps<Actions> & {
  proposal?: TaskProposalAbilityFragment;
};

export const CanTaskProposal = (props: CanTaskProposalProps) => {
  const [proposalAbility, proposalSubject] = useTaskProposalAbility();
  const { proposal, ...restProps } = props;
  return (
    <GenericCan<Actions, Subjects, TaskProposalAbility>
      ability={proposalAbility}
      subject={proposalSubject({ proposal })}
      {...restProps}
    />
  );
};
