import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ChangeTaskFormTemplateMutationVariables = Types.Exact<{
  task_form_id: Types.Scalars['ID'];
  template_id: Types.Scalars['ID'];
}>;

export type ChangeTaskFormTemplateMutation = {
  __typename?: 'Mutation';
  changeTaskFormTemplate: { __typename?: 'TaskForm'; id: string };
};

export const ChangeTaskFormTemplateDocument = gql`
  mutation ChangeTaskFormTemplate($task_form_id: ID!, $template_id: ID!) {
    changeTaskFormTemplate(task_form_id: $task_form_id, template_id: $template_id) {
      id
    }
  }
`;

export function useChangeTaskFormTemplateMutation() {
  return Urql.useMutation<ChangeTaskFormTemplateMutation, ChangeTaskFormTemplateMutationVariables>(
    ChangeTaskFormTemplateDocument,
  );
}
