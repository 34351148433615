import { GRAPHQL_API_URL, LOG_GRAPHQL_ERRORS } from '@env';
import { useAuthExchange, useAuthState } from '@module/auth';
import { useIpAllowlistExchange } from '@module/auth/hooks/useIpAllowlistExchange';
import { isNotNullish } from '@module/shared/helpers';
import * as Sentry from '@sentry/react';
import { devtoolsExchange } from '@urql/devtools';
import noop from 'lodash/noop';
import { ReactNode, useCallback, useMemo } from 'react';
import {
  cacheExchange,
  createClient,
  fetchExchange,
  mapExchange,
  Provider as UrqlProvider,
} from 'urql';
import { v4 as uuid } from 'uuid';

import { useGraphqlSubscriptionExchange } from './useGraphqlSubscriptionExchange';

const sentryUrqlErrorExchange = mapExchange({
  onError: (error, operation) => {
    if (error.graphQLErrors) {
      for (const graphQLError of error.graphQLErrors) {
        Sentry.withScope((scope) => {
          scope.setTag('kind', operation.kind);
          scope.setExtra('query', operation.query.loc?.source);
          scope.setExtra('original', graphQLError);
          if (graphQLError.path) {
            scope.addBreadcrumb({
              category: 'query-path',
              message: graphQLError.path.join(' > '),
            });
          }
          scope.setTransactionName(graphQLError.message);
          scope.setFingerprint(['GRAPHQL', graphQLError.message]);
          Sentry.captureException(new Error(graphQLError.message));
        });
      }
    }
    if (error.networkError) {
      Sentry.withScope((scope) => {
        scope.setTag('kind', operation.kind);
        scope.setExtra('query', operation.query.loc?.source);
        Sentry.captureException(error.networkError);
      });
    }
  },
});

function useCreateUrqlClient() {
  const authExchange = useAuthExchange();
  const ipAllowlistExchange = useIpAllowlistExchange();
  const graphqlSubscriptionExchange = useGraphqlSubscriptionExchange();
  return useCallback(() => {
    return createClient({
      url: GRAPHQL_API_URL,
      exchanges: [
        devtoolsExchange,
        cacheExchange,
        authExchange,
        ipAllowlistExchange,
        LOG_GRAPHQL_ERRORS ? sentryUrqlErrorExchange : null,
        fetchExchange,
        graphqlSubscriptionExchange,
      ].filter(isNotNullish),
      fetchOptions: { credentials: 'include' },
    });
  }, [authExchange, graphqlSubscriptionExchange, ipAllowlistExchange]);
}

const UrqlClientProvider = (props: { hash: string; children?: ReactNode | undefined }) => {
  const { hash, children } = props;
  const createUrqlClient = useCreateUrqlClient();

  // force recreation if hash changes
  const urqlClient = useMemo(() => {
    noop(hash);
    return createUrqlClient();
  }, [createUrqlClient, hash]);

  return <UrqlProvider value={urqlClient}>{children}</UrqlProvider>;
};

export const UrqlClient = (props: { children?: ReactNode | undefined }) => {
  const { children } = props;
  const authState = useAuthState();

  const hash = useMemo(
    () => authState?.data?.access_token ?? uuid(),
    [authState?.data?.access_token],
  );

  return <UrqlClientProvider hash={hash}>{children}</UrqlClientProvider>;
};
