export default {
  labels: {
    id: 'ID',
    title: 'Title',
    description: 'Description',
    finishDateDefaultDays: 'Given solution time in working days',
    edit: 'Edit',
  },
  dialogs: {
    edit: {
      title: 'Edit Task Template',
      submit: 'Update Tasks Template',
    },
    create: {
      title: 'Create Task Template',
      submit: 'Create task template',
    },
    delete: {
      title: 'Delete task template',
      description: 'Are you sure you want to delete the task template?',
    },
  },
  pages: {
    overview: {
      title: 'Task Templates',
      description: 'Here you can see a list of templates for tasks',
      toolbar: {
        createSubTasksTemplate: 'New task',
      },
    },
  },
  notifications: {
    create: {
      success: 'Task templates was created',
      error: 'Task templates could not be created',
    },
    edit: {
      success: 'Task templates has been updated',
      error: 'Task templates could not be updated',
    },
    delete: {
      success: 'Tasks Template has been deleted.',
      error: 'Tasks Template could not be deleted.',
    },
  },
};
