import { format, parseISO } from 'date-fns';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export enum DateFormat {
  Date,
  DateTime,
  TimeWithoutSeconds,
  Time,
  DayMonth,
  DateTimeWithoutSeconds,
  MonthYear,
}

export function useFormatDate() {
  const { t } = useTranslation();

  return useCallback(
    (date: Date, dateFormat: DateFormat = DateFormat.Date) => {
      switch (dateFormat) {
        case DateFormat.Date:
          return format(date, t('common.formats.date'));
        case DateFormat.DateTime:
          return format(date, t('common.formats.dateTime'));
        case DateFormat.Time:
          return format(date, t('common.formats.time'));
        case DateFormat.TimeWithoutSeconds:
          return format(date, t('common.formats.timeWithoutSeconds'));
        case DateFormat.DateTimeWithoutSeconds:
          return format(date, t('common.formats.dateTimeWithoutSeconds'));
        case DateFormat.DayMonth:
          return format(date, t('common.formats.dayMonth'));
        case DateFormat.MonthYear:
          return format(date, t('common.formats.monthYear'));
      }
    },
    [t],
  );
}

export function useFormatIsoDate() {
  const formatDate = useFormatDate();

  return useCallback(
    (isoDate: string, dateFormat: DateFormat = DateFormat.Date) =>
      formatDate(parseISO(isoDate), dateFormat),
    [formatDate],
  );
}

export function useDateFormat(date: Date | undefined, dateFormat: DateFormat) {
  const formatDate = useFormatDate();

  return useMemo(() => {
    if (date instanceof Date) {
      return formatDate(date, dateFormat);
    }
  }, [date, dateFormat, formatDate]);
}
