import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import { InsuranceFragmentDoc } from './insuranceFragment.generated';
import { TaskVisitTypeFragmentDoc } from './taskVisitTypeFragment.generated';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type TaskDetailBaseDataQueryVariables = Types.Exact<{ [key: string]: never }>;

export type TaskDetailBaseDataQuery = {
  __typename?: 'Query';
  clientsForTask: {
    __typename?: 'ClientList';
    data: Array<{
      __typename?: 'Client';
      id: string;
      salutation_id: string;
      name: string;
      email?: string | null;
      phone?: string | null;
      notes?: string | null;
      job_title?: string | null;
      locations: Array<{
        __typename?: 'ClientLocation';
        id: string;
        street?: string | null;
        street_no?: string | null;
        postcode?: string | null;
        city?: string | null;
        contacts: Array<{
          __typename?: 'Contact';
          id: string;
          first_name?: string | null;
          name?: string | null;
        }>;
      }>;
      client_additional_insurance?: {
        __typename?: 'ClientAdditionalInsurance';
        damage_number_mask?: string | null;
      } | null;
      client_additional_policy_holder?: {
        __typename?: 'ClientAdditionalPolicyHolder';
        insurance_policy_number?: string | null;
        customer_number?: string | null;
        customer_state_date?: string | null;
        policy_holder_type_id?: Types.PolicyHolderType | null;
        customer_state?: { __typename?: 'ClientCustomerState'; title: string } | null;
      } | null;
      qualifications: {
        __typename?: 'ClientQualificationList';
        data: Array<{ __typename?: 'Qualification'; title: string; id: string; color: string }>;
      };
    }>;
  };
  taskVisitTypes: Array<{
    __typename?: 'TaskVisitType';
    id: string;
    title: string;
    need_location: boolean;
    show_location: boolean;
  }>;
};

export const TaskDetailBaseDataDocument = gql`
  query TaskDetailBaseData {
    clientsForTask {
      data {
        ...Insurance
      }
    }
    taskVisitTypes {
      ...TaskVisitType
    }
  }
  ${InsuranceFragmentDoc}
  ${TaskVisitTypeFragmentDoc}
`;

export function useTaskDetailBaseDataQuery(
  options?: Omit<Urql.UseQueryArgs<TaskDetailBaseDataQueryVariables>, 'query'>,
) {
  return Urql.useQuery<TaskDetailBaseDataQuery, TaskDetailBaseDataQueryVariables>({
    query: TaskDetailBaseDataDocument,
    ...options,
  });
}
