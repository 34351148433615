import { classNames } from '@progress/kendo-react-common';
import { GridCellProps } from '@progress/kendo-react-grid';
import { Tooltip, TooltipPosition } from '@progress/kendo-react-tooltip';
import { get } from 'lodash';
import { useMemo } from 'react';

import { ThemeColor } from '../../../../types/graphql.generated';
import { getContrastColor, getThemeColorByField } from '../../helpers';
import { Badge } from '../badges';

export interface BadgeCellProps {
  backgroundThemeColorField?: string | [string];
  backgroundThemeColor?: ThemeColor;
  backgroundColor?: string;
  backgroundColorField?: string;
  color?: string;
  badgeClassName?: string;
  tooltip?: {
    title?: string;
    field?: string;
    position?: TooltipPosition;
    style?: React.CSSProperties;
    tooltipStyle?: React.CSSProperties;
  };
}

export const BadgeCell = (props: BadgeCellProps & GridCellProps) => {
  const backgroundThemeColor = useMemo(() => {
    return (
      getThemeColorByField(props.dataItem, props.backgroundThemeColorField ?? '') ??
      props.backgroundThemeColor
    );
  }, [props.dataItem, props.backgroundThemeColorField, props.backgroundThemeColor]);

  const backgroundColor = useMemo(() => {
    return get(props.dataItem, props.backgroundColorField ?? '') ?? props.backgroundColor;
  }, [props.backgroundColor, props.backgroundColorField, props.dataItem]);

  const value = props.field ? get(props.dataItem, props.field) : undefined;
  const { tooltip } = props;
  const tooltipFieldValue = tooltip?.field ? get(props.dataItem, tooltip.field) : undefined;

  return (
    <td style={props.style} className={classNames(props.className, 'k-text-center')}>
      <Badge
        backgroundThemeColor={backgroundThemeColor}
        backgroundColor={backgroundColor}
        color={
          props.color ? props.color : backgroundColor ? getContrastColor(backgroundColor) : 'white'
        }
        className={classNames(
          props.badgeClassName,
          'k-text-ellipsis',
          'k-text-nowrap',
          'k-overflow-hidden',
        )}
      >
        {tooltip?.title || tooltipFieldValue ? (
          <Tooltip
            tooltipStyle={tooltip?.tooltipStyle}
            style={tooltip?.style}
            anchorElement="target"
            position={tooltip?.position}
          >
            <span title={tooltipFieldValue ?? tooltip?.title}>{value}</span>
          </Tooltip>
        ) : (
          <span>{value}</span>
        )}
      </Badge>
    </td>
  );
};
