import './FormGroupHorizontal.scss';

import { classNames } from '@progress/kendo-react-common';

import {
  AutoFillGrid,
  AutoFillGridProps,
} from '../../../layout/components/auto-fill-grid/AutoFillGrid';

export type FormGroupHorizontalProps = Partial<AutoFillGridProps>;

export const FormGroupHorizontal = (props: FormGroupHorizontalProps) => {
  const {
    className,
    maxColumns = 3,
    columnMinWidth = '200px',
    gap = 'var(--spacing-6)',
    ...rest
  } = props;

  return (
    <AutoFillGrid
      {...rest}
      className={classNames('FormGroupHorizontal', className)}
      maxColumns={maxColumns}
      columnMinWidth={columnMinWidth}
      gap={gap}
    />
  );
};
