import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
export type UserAbilityFragment = {
  __typename?: 'User';
  role: Types.UserRole;
  permissions: Array<Types.Permission>;
};

export const UserAbilityFragmentDoc = gql`
  fragment UserAbility on User {
    role
    permissions
  }
`;
