import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type TasksMapLayerFragment = {
  __typename?: 'TasksGeoJSONLayer';
  id: string;
  type: Types.MapboxLayerType;
  minzoom?: number | null;
  maxzoom?: number | null;
  filter?: any | null;
  layout?: any | null;
  paint?: any | null;
  metadata?: any | null;
};

export type TasksMapLayersQueryVariables = Types.Exact<{ [key: string]: never }>;

export type TasksMapLayersQuery = {
  __typename?: 'Query';
  tasksMapLayers: {
    __typename?: 'TasksGeoJSONLayers';
    layers: Array<{
      __typename?: 'TasksGeoJSONLayer';
      id: string;
      type: Types.MapboxLayerType;
      minzoom?: number | null;
      maxzoom?: number | null;
      filter?: any | null;
      layout?: any | null;
      paint?: any | null;
      metadata?: any | null;
    }>;
  };
};

export const TasksMapLayerFragmentDoc = gql`
  fragment TasksMapLayer on TasksGeoJSONLayer {
    id
    type
    minzoom
    maxzoom
    filter
    layout
    paint
    metadata
  }
`;
export const TasksMapLayersDocument = gql`
  query TasksMapLayers {
    tasksMapLayers {
      layers {
        ...TasksMapLayer
      }
    }
  }
  ${TasksMapLayerFragmentDoc}
`;

export function useTasksMapLayersQuery(
  options?: Omit<Urql.UseQueryArgs<TasksMapLayersQueryVariables>, 'query'>,
) {
  return Urql.useQuery<TasksMapLayersQuery, TasksMapLayersQueryVariables>({
    query: TasksMapLayersDocument,
    ...options,
  });
}
