import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import { ExportResponseFragmentDoc } from '../../../common/graphql/generated/exportResponseFragment.generated';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ExportClientsMutationVariables = Types.Exact<{
  filter: Types.ClientsFilter;
  sort: Types.ClientsSort;
  config: Types.ExportConfigInput;
}>;

export type ExportClientsMutation = {
  __typename?: 'Mutation';
  exportClients: {
    __typename?: 'ExportResponse';
    success: boolean;
    message: string;
    download?: {
      __typename?: 'Download';
      id: string;
      state_id: Types.DownloadStateId;
      filename: string;
      file_ids: Array<string>;
      url_download: string;
    } | null;
  };
};

export const ExportClientsDocument = gql`
  mutation ExportClients(
    $filter: ClientsFilter!
    $sort: ClientsSort!
    $config: ExportConfigInput!
  ) {
    exportClients(filter: $filter, sort: $sort, config: $config) {
      ...ExportResponse
    }
  }
  ${ExportResponseFragmentDoc}
`;

export function useExportClientsMutation() {
  return Urql.useMutation<ExportClientsMutation, ExportClientsMutationVariables>(
    ExportClientsDocument,
  );
}
