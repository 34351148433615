import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ClientAdditionalQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;

export type ClientAdditionalQuery = {
  __typename?: 'Query';
  client?: {
    __typename?: 'Client';
    client_additional_loss_adjuster?: {
      __typename?: 'ClientAdditionalLossAdjuster';
      damage_payout_amount?: number | null;
      damage_payout_percentage?: number | null;
      leader_damage_payout_amount?: number | null;
      leader_damage_payout_percentage?: number | null;
      liability_payout_amount?: number | null;
      liability_payout_percentage?: number | null;
      leader_liability_payout_amount?: number | null;
      leader_liability_payout_percentage?: number | null;
      business_payout_amount?: number | null;
      business_payout_percentage?: number | null;
      leader_business_payout_amount?: number | null;
      leader_business_payout_percentage?: number | null;
      business_insurance: boolean;
      payout_type?: Types.PayoutType | null;
      has_payout: boolean;
      payout_type_tax?: Types.PayoutTypeTax | null;
      has_ipad: boolean;
      can_send_emails: boolean;
    } | null;
  } | null;
};

export const ClientAdditionalDocument = gql`
  query ClientAdditional($id: ID!) {
    client(id: $id) {
      client_additional_loss_adjuster {
        damage_payout_amount
        damage_payout_percentage
        leader_damage_payout_amount
        leader_damage_payout_percentage
        liability_payout_amount
        liability_payout_percentage
        leader_liability_payout_amount
        leader_liability_payout_percentage
        business_payout_amount
        business_payout_percentage
        leader_business_payout_amount
        leader_business_payout_percentage
        business_insurance
        payout_type
        has_payout
        payout_type_tax
        has_ipad
        can_send_emails
      }
    }
  }
`;

export function useClientAdditionalQuery(
  options: Omit<Urql.UseQueryArgs<ClientAdditionalQueryVariables>, 'query'>,
) {
  return Urql.useQuery<ClientAdditionalQuery, ClientAdditionalQueryVariables>({
    query: ClientAdditionalDocument,
    ...options,
  });
}
