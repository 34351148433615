import {
  Ability,
  AbilityBuilder,
  AbilityClass,
  ForcedSubject,
  subject as subjectHelper,
} from '@casl/ability';
import { useCallback, useMemo } from 'react';

import { Permission } from '../../../types/graphql.generated';
import { GenericCan, GenericCanSharedProps } from '../components/GenericCan';
import { CurrentUserAbilityFragment } from '../graphql';
import { detectSubjectTypeByTypename as detectSubjectType } from '../helpers';
import { useMeOrDefault } from '../hooks';

interface ActivitySubject {
  me: CurrentUserAbilityFragment;
}

type ActivitySubjectArg = Partial<ActivitySubject>;

type Actions =
  | 'viewActivities'
  | 'readActivity'
  | 'createActivity'
  | 'updateActivity'
  | 'deleteActivity';
type Subjects = ActivitySubject | 'ActivitySubject';

type ActivityAbility = Ability<[Actions, Subjects]>;
const taskTemplateAbility = Ability as AbilityClass<ActivityAbility>;

export const useActivityAbility = (): [
  ActivityAbility,
  (sub?: ActivitySubjectArg) => ActivitySubject & ForcedSubject<'ActivitySubject'>,
] => {
  const ability = useMemo(() => {
    const { can, build } = new AbilityBuilder(taskTemplateAbility);

    can('viewActivities', 'ActivitySubject', {
      'me.globalPermissions': { $in: [Permission.ACTIVITY_INDEX] },
    });
    can('readActivity', 'ActivitySubject', {
      'me.globalPermissions': { $in: [Permission.ACTIVITY_READ] },
    });
    can('createActivity', 'ActivitySubject', {
      'me.globalPermissions': { $in: [Permission.ACTIVITY_CREATE] },
    });
    can('updateActivity', 'ActivitySubject', {
      'me.globalPermissions': { $in: [Permission.ACTIVITY_UPDATE] },
    });
    can('deleteActivity', 'ActivitySubject', {
      'me.globalPermissions': { $in: [Permission.ACTIVITY_DELETE] },
    });

    return build({ detectSubjectType });
  }, []);

  const me = useMeOrDefault();
  const subject = useCallback(
    (sub?: ActivitySubjectArg) => {
      return subjectHelper('ActivitySubject', { me, ...sub });
    },
    [me],
  );

  return [ability, subject];
};

type CanActivityProps = GenericCanSharedProps<Actions>;

export const CanActivity = (props: CanActivityProps) => {
  const [activityAbility, activitySubject] = useActivityAbility();

  return (
    <GenericCan<Actions, Subjects, ActivityAbility>
      ability={activityAbility}
      subject={activitySubject()}
      {...props}
    />
  );
};
