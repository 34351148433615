import {
  Ability,
  AbilityBuilder,
  AbilityClass,
  ForcedSubject,
  subject as subjectHelper,
} from '@casl/ability';
import { useCallback, useMemo } from 'react';

import { Permission } from '../../../types/graphql.generated';
import { GenericCan, GenericCanSharedProps } from '../components/GenericCan';
import { CurrentUserAbilityFragment } from '../graphql';
import { detectSubjectTypeByTypename as detectSubjectType } from '../helpers';
import { useMeOrDefault } from '../hooks';

interface ArticleSubject {
  me: CurrentUserAbilityFragment;
}

type ArticleSubjectArg = Partial<ArticleSubject>;

type Actions = 'viewArticles' | 'createArticle' | 'updateArticle' | 'syncArticles';
type Subjects = ArticleSubject | 'ArticleSubject';

type ArticleAbility = Ability<[Actions, Subjects]>;
const userAbility = Ability as AbilityClass<ArticleAbility>;

export const useArticleAbility = (): [
  ArticleAbility,
  (sub?: ArticleSubjectArg) => ArticleSubject & ForcedSubject<'ArticleSubject'>,
] => {
  const ability = useMemo(() => {
    const { can, build } = new AbilityBuilder(userAbility);

    can('viewArticles', 'ArticleSubject', {
      'me.globalPermissions': { $in: [Permission.ARTICLE_INDEX] },
    });
    can('createArticle', 'ArticleSubject', {
      'me.globalPermissions': { $in: [Permission.ARTICLE_CREATE] },
    });
    can('updateArticle', 'ArticleSubject', {
      'me.globalPermissions': { $in: [Permission.ARTICLE_UPDATE] },
    });
    can('syncArticles', 'ArticleSubject', {
      'me.globalPermissions': { $in: [Permission.SYNC_ARTICLES] },
    });

    return build({ detectSubjectType });
  }, []);

  const me = useMeOrDefault();
  const subject = useCallback(
    (sub?: ArticleSubjectArg) => {
      return subjectHelper('ArticleSubject', { me, ...sub });
    },
    [me],
  );

  return [ability, subject];
};

type CanArticleProps = GenericCanSharedProps<Actions>;

export const CanArticle = (props: CanArticleProps) => {
  const [articleAbility, articleSubject] = useArticleAbility();

  return (
    <GenericCan<Actions, Subjects, ArticleAbility>
      ability={articleAbility}
      subject={articleSubject()}
      {...props}
    />
  );
};
