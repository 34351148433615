import {
  Ability,
  AbilityBuilder,
  AbilityClass,
  ForcedSubject,
  subject as subjectHelper,
} from '@casl/ability';
import { useCallback, useMemo } from 'react';

import { Permission } from '../../../types/graphql.generated';
import { GenericCan, GenericCanSharedProps } from '../components/GenericCan';
import { CalendarEventAbilityFragment, CurrentUserAbilityFragment } from '../graphql';
import { detectSubjectTypeByTypename as detectSubjectType } from '../helpers';
import { useMeOrDefault } from '../hooks';

interface CalendarEventSubject {
  me: CurrentUserAbilityFragment;
  calendarEvent?: CalendarEventAbilityFragment;
}

type CalendarEventSubjectArg = Partial<CalendarEventSubject>;

type Actions =
  | 'viewEvents'
  | 'filterEventsByAdjuster'
  | 'createEvent'
  | 'updateEvent'
  | 'deleteEvent';
type Subjects = CalendarEventSubject | 'CalendarEventSubject';

type CalendarEventAbility = Ability<[Actions, Subjects]>;
const calendarEventAbility = Ability as AbilityClass<CalendarEventAbility>;

export const useCalendarEventAbility = (): [
  CalendarEventAbility,
  (sub?: CalendarEventSubjectArg) => CalendarEventSubject & ForcedSubject<'CalendarEventSubject'>,
] => {
  const ability = useMemo(() => {
    const { can, build } = new AbilityBuilder(calendarEventAbility);

    // global event actions
    can('viewEvents', 'CalendarEventSubject', {
      'me.globalPermissions': { $in: [Permission.CALENDAR_EVENT_INDEX] },
    });
    can('filterEventsByAdjuster', 'CalendarEventSubject', {
      'me.globalPermissions': { $in: [Permission.CALENDAR_EVENT_FILTER_ADJUSTERS] },
    });
    can('createEvent', 'CalendarEventSubject', {
      'me.globalPermissions': { $in: [Permission.CALENDAR_EVENT_CREATE] },
    });

    // event specific actions
    can('updateEvent', 'CalendarEventSubject', {
      'calendarEvent.permissions': { $in: [Permission.CALENDAR_EVENT_UPDATE] },
    });
    can('deleteEvent', 'CalendarEventSubject', {
      'calendarEvent.permissions': { $in: [Permission.CALENDAR_EVENT_DELETE] },
    });

    return build({ detectSubjectType });
  }, []);

  const me = useMeOrDefault();
  const subject = useCallback(
    (sub?: CalendarEventSubjectArg) => {
      return subjectHelper('CalendarEventSubject', { me, ...sub });
    },
    [me],
  );

  return [ability, subject];
};

type CanCalendarEventProps = GenericCanSharedProps<Actions>;

export const CanCalendarEvent = (props: CanCalendarEventProps) => {
  const [calendarEventAbility, calendarEventSubject] = useCalendarEventAbility();

  return (
    <GenericCan<Actions, Subjects, CalendarEventAbility>
      ability={calendarEventAbility}
      subject={calendarEventSubject()}
      {...props}
    />
  );
};
