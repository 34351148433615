import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
export type SubformClassificationTagFragment = {
  __typename?: 'Tag';
  id: string;
  title: string;
  color: string;
  type_id: string;
  type: { __typename?: 'TagType'; id: string; title: string; color: string };
};

export const SubformClassificationTagFragmentDoc = gql`
  fragment SubformClassificationTag on Tag {
    id
    title
    color
    type_id
    type {
      id
      title
      color
    }
  }
`;
