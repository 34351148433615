import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import { UserAbilityFragmentDoc } from './UserAbilityFragment.generated';
import { CurrentUserAbilityFragmentDoc } from './CurrentUserAbilityFragment.generated';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type MeFragment = {
  __typename?: 'User';
  id: string;
  email: string;
  name: string;
  last_login_at?: string | null;
  current_ip?: string | null;
  role: Types.UserRole;
  permissions: Array<Types.Permission>;
  globalPermissions: Array<Types.Permission>;
  profile_image?: { __typename?: 'FileItem'; url: string; file_name: string; id: string } | null;
  client?: {
    __typename?: 'Client';
    id: string;
    type: Types.ClientTypeId;
    dashboard_type: Types.DashboardType;
    client_type?: { __typename?: 'ClientType'; id: string; color: string; title: string } | null;
    notification_settings: {
      __typename?: 'CombinedNotificationSettingsList';
      id: string;
      data: Array<{
        __typename?: 'CombinedNotificationSetting';
        type_id: Types.NotificationTypeId;
        internal: boolean;
        email: boolean;
        sms: boolean;
        push: boolean;
      }>;
    };
    primary_location?: {
      __typename?: 'ClientLocation';
      city?: string | null;
      postcode?: string | null;
      street?: string | null;
      street_no?: string | null;
      country: { __typename?: 'Country'; title: string };
    } | null;
  } | null;
};

export type MeQueryVariables = Types.Exact<{ [key: string]: never }>;

export type MeQuery = {
  __typename?: 'Query';
  me?: {
    __typename?: 'User';
    id: string;
    email: string;
    name: string;
    last_login_at?: string | null;
    current_ip?: string | null;
    role: Types.UserRole;
    permissions: Array<Types.Permission>;
    globalPermissions: Array<Types.Permission>;
    profile_image?: { __typename?: 'FileItem'; url: string; file_name: string; id: string } | null;
    client?: {
      __typename?: 'Client';
      id: string;
      type: Types.ClientTypeId;
      dashboard_type: Types.DashboardType;
      client_type?: { __typename?: 'ClientType'; id: string; color: string; title: string } | null;
      notification_settings: {
        __typename?: 'CombinedNotificationSettingsList';
        id: string;
        data: Array<{
          __typename?: 'CombinedNotificationSetting';
          type_id: Types.NotificationTypeId;
          internal: boolean;
          email: boolean;
          sms: boolean;
          push: boolean;
        }>;
      };
      primary_location?: {
        __typename?: 'ClientLocation';
        city?: string | null;
        postcode?: string | null;
        street?: string | null;
        street_no?: string | null;
        country: { __typename?: 'Country'; title: string };
      } | null;
    } | null;
  } | null;
};

export const MeFragmentDoc = gql`
  fragment Me on User {
    id
    email
    name
    last_login_at
    current_ip
    profile_image {
      url
      file_name
      id
    }
    client {
      id
      type
      dashboard_type
      client_type {
        id
        color
        title
      }
      notification_settings {
        id
        data {
          type_id
          internal
          email
          sms
          push
        }
      }
      primary_location {
        city
        country {
          title
        }
        postcode
        street
        street_no
      }
    }
    ...UserAbility
    ...CurrentUserAbility
  }
  ${UserAbilityFragmentDoc}
  ${CurrentUserAbilityFragmentDoc}
`;
export const MeDocument = gql`
  query Me {
    me {
      ...Me
    }
  }
  ${MeFragmentDoc}
`;

export function useMeQuery(options?: Omit<Urql.UseQueryArgs<MeQueryVariables>, 'query'>) {
  return Urql.useQuery<MeQuery, MeQueryVariables>({ query: MeDocument, ...options });
}
