import { createContext, useContext } from 'react';

import { DrawerContextType } from './types';

export const DrawerContext = createContext<DrawerContextType | undefined>(undefined);

export function useDrawerContext() {
  const context = useContext(DrawerContext);

  if (!context) {
    throw new Error('useDrawerContext must be within DrawerContext.Provider');
  }

  return context;
}
