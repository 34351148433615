export default {
  labels: {
    category_id: 'Category',
    body: 'Email Text',
    filterTemplateKeys: 'Search placeholder...',
    preview: 'Preview',
    signature: 'Signature',
    subject: 'Subject',
    placeholder: 'add placeholder',
    fileClassificationTags: 'Automatic tag for files:',
    fileClassificationTagsDescription:
      'By selecting a file tag, this tag will be automatically added to the file, if this e-mail was sent with this file as attachment.',
    classificationTags: 'Automatic tag for tasks:',
    classificationTagsDescription:
      'By selecting a task tag, this tag will be automatically added to the task if this email was sent.',
  },
  notifications: {
    emailTemplateCreate: {
      success: 'Email template created!',
      validationError: 'Validation failure!',
    },
    emailTemplateEdit: {
      success: 'Email template saved!',
      validationError: 'Validation failure!',
    },
    emailTemplateDelete: {
      success: 'Email template deleted!',
    },
  },
  dialogs: {
    emailTemplateCreate: {
      title: 'Create email template',
      note: 'Here you can create email templates',
      signatureNote:
        'If "Default email template" is activated, then this template will be displayed directly with every new email.',
    },
    emailTemplateDelete: {
      title: 'Delete email template',
      note: 'Really delete email template?',
    },
    chooseEmailTemplate: {
      title: 'Select email template',
      note: 'Selecting a template overwrites fields that have already been set!',
    },
  },
  pages: {
    emailTemplateOverview: {
      title: 'Email Templates',
      description: 'Here you can see a list of email templates',
      toolbar: {
        create: 'Create template',
      },
    },
    emailTemplateEdit: {
      title: 'Email template - {{email_template_id}}',
      description: 'Here you can edit the email template',
    },
  },
};
