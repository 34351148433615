import { GridProps } from '@progress/kendo-react-grid';
import { SetStateAction, useCallback } from 'react';

export interface ReorderableColumnSettings {
  orderIndex?: number;
}
export type ReorderableColumnSettingsRecord = Record<string, ReorderableColumnSettings>;
type ReorderableColumnSettingsStateSetter = (
  value: SetStateAction<ReorderableColumnSettingsRecord>,
) => void;

export const useGridColumnReorderHandler = (stateSetter: ReorderableColumnSettingsStateSetter) => {
  const handleColumnReorder = useCallback<NonNullable<GridProps['onColumnReorder']>>(
    (event) =>
      stateSetter((prev) => {
        const columnSettingsRecordCopy = { ...prev };

        event.columns.forEach((column) => {
          if (!column.id || !('orderIndex' in column)) {
            return;
          }

          columnSettingsRecordCopy[column.id] = {
            ...columnSettingsRecordCopy[column.id],
            orderIndex: column.orderIndex,
          };
        });

        return columnSettingsRecordCopy;
      }),
    [stateSetter],
  );

  return handleColumnReorder;
};

export function useGetReorderableColumnSettings<const TColumn extends string>(
  columnSettingsRecord: ReorderableColumnSettingsRecord,
  defaultColumnOrder: ReadonlyArray<TColumn> | Array<TColumn>,
) {
  const getColumnSettings = useCallback(
    (column: TColumn) => ({
      id: column,
      orderIndex: columnSettingsRecord[column]?.orderIndex ?? defaultColumnOrder.indexOf(column),
      reorderable: true,
    }),
    [columnSettingsRecord, defaultColumnOrder],
  );

  return getColumnSettings;
}
