import './FormSection.scss';

import { classNames } from '@progress/kendo-react-common';
import { ReactNode } from 'react';

interface FormSectionProps {
  children?: ReactNode | undefined;
  header?: ReactNode;
  className?: string | undefined;
}

export const FormSection = ({ className, header, children }: FormSectionProps) => (
  <section className={classNames('FormSection', className)}>
    {header && (
      <header className="k-d-flex k-flex-wrap k-align-items-center k-justify-content-between k-gap-4 k-mb-8">
        {header}
      </header>
    )}

    {children}
  </section>
);

interface FormSectionTitleProps {
  children?: ReactNode | undefined;
  counter?: number;
}

export const FormSectionTitle = (props: FormSectionTitleProps) => {
  const { children } = props;

  return (
    <h2 {...props} className="FormSectionTitle">
      <div>{children}</div>
    </h2>
  );
};

export const FormSectionFillFooter = (props: { children?: ReactNode | undefined }) => (
  <footer className="FormSectionFillFooter">{props.children}</footer>
);
