import { useMemo } from 'react';
import { ConditionalKeys } from 'type-fest';

import { UploadRestrictions } from '../components/upload/types';
import { fileExtensions } from '../helpers/fileTypes';
import { useAppSettings } from './useAppSettings';

interface UploadMutationConstraints {
  max_filesize_bytes: number;
  allowed_extensions: Array<string>;
}

type UploadMutationName = ConditionalKeys<
  ReturnType<typeof useAppSettings>['mutation_constraints'],
  UploadMutationConstraints
>;

interface Options {
  /**
   * When set to true, HEIC files will be allowed, even if this conflicts with
   * the mutation constraints.
   * **Only use this, if the file will be converted to JPEG on client-side.**
   * @default false
   */
  forceAllowHeic: boolean;
}

export const useUploadRestrictionsByMutation = (
  mutationName: UploadMutationName,
  options: Options = { forceAllowHeic: false },
): UploadRestrictions => {
  const appSettings = useAppSettings();
  const constraints = appSettings.mutation_constraints[mutationName];

  return useMemo(() => {
    const shouldForceAllowHeic = () => {
      if (!options.forceAllowHeic) {
        return false;
      }

      // No need to enforce if API already allows HEIC
      const alreadyAllowsHeic = constraints.allowed_extensions.some((extension) =>
        fileExtensions.HEIC.includes(extension),
      );
      if (alreadyAllowsHeic) {
        return false;
      }

      // Can't allow HEIC if API doesn't allow JPEG, as this is the conversion target
      const allowsJpeg = constraints.allowed_extensions.some((extension) =>
        fileExtensions.JPG.includes(extension),
      );
      return allowsJpeg;
    };

    return {
      allowedExtensions: shouldForceAllowHeic()
        ? [...constraints.allowed_extensions, ...fileExtensions.HEIC]
        : constraints.allowed_extensions,
      maxFileSize: constraints.max_filesize_bytes,
    };
  }, [constraints, options.forceAllowHeic]);
};
