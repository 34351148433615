import { isString } from '@module/shared/helpers';
import { classNames } from '@progress/kendo-react-common';
import { ReactNode } from 'react';

export interface SectionHeaderProps {
  title?: string | ReactNode;
  description?: string | ReactNode;
  content?: ReactNode;

  className?: string;
  classNameTitle?: string;
  classNameDescription?: string;
  classNameContent?: string;
}

export const SectionHeader = (props: SectionHeaderProps) => {
  const {
    title,
    description,
    content,
    className,
    classNameTitle,
    classNameDescription,
    classNameContent,
  } = props;

  return (
    <div className={classNames('section-header', className)}>
      <div className="row">
        <div className="col-10 col-md">
          {title &&
            (isString(title) ? (
              <h2 className={classNames('section-header-title', classNameTitle)}>{title}</h2>
            ) : (
              <>{title}</>
            ))}
          {description &&
            (isString(description) ? (
              <p className={classNames('section-header-description', classNameDescription)}>
                {description}
              </p>
            ) : (
              <>{description}</>
            ))}
        </div>
        <div
          className={classNames(
            'section-header-content',
            'col-2 col-md-auto',
            'k-display-flex k-flex-row k-justify-content-end k-align-items-center',
            classNameContent,
          )}
        >
          {content}
        </div>
      </div>
    </div>
  );
};
