import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import { AuthStateFragmentDoc } from './types.generated';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type LoginAsOtherUserMutationVariables = Types.Exact<{
  userId: Types.Scalars['ID'];
}>;

export type LoginAsOtherUserMutation = {
  __typename?: 'Mutation';
  loginAsOtherUser: {
    __typename?: 'LoginResponse';
    message: string;
    authState?: {
      __typename?: 'AuthState';
      data: {
        __typename?: 'AuthData';
        access_token: string;
        refresh_token: string;
        expires_at: string;
        refresh_token_expires_at: string;
      };
    } | null;
  };
};

export const LoginAsOtherUserDocument = gql`
  mutation LoginAsOtherUser($userId: ID!) {
    loginAsOtherUser(user_id: $userId) {
      message
      authState {
        ...AuthState
      }
    }
  }
  ${AuthStateFragmentDoc}
`;

export function useLoginAsOtherUserMutation() {
  return Urql.useMutation<LoginAsOtherUserMutation, LoginAsOtherUserMutationVariables>(
    LoginAsOtherUserDocument,
  );
}
