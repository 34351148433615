import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type FormTemplatesQueryVariables = Types.Exact<{ [key: string]: never }>;

export type FormTemplatesQuery = {
  __typename?: 'Query';
  taskTemplates: {
    __typename?: 'TaskTemplatePaginator';
    data: Array<{ __typename?: 'TaskTemplate'; id: string; title: string }>;
  };
};

export const FormTemplatesDocument = gql`
  query FormTemplates {
    taskTemplates(
      filter: { AND: [{ column: TYPE_ID, operator: EQ, value: PRIMARY }] }
      pagination: { skip: 0, take: 999 }
      sort: { columns: [] }
    ) {
      data {
        id
        title
      }
    }
  }
`;

export function useFormTemplatesQuery(
  options?: Omit<Urql.UseQueryArgs<FormTemplatesQueryVariables>, 'query'>,
) {
  return Urql.useQuery<FormTemplatesQuery, FormTemplatesQueryVariables>({
    query: FormTemplatesDocument,
    ...options,
  });
}
