import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import { ExportConfigFragmentDoc } from '../../../common/graphql/generated/exportConfigFragment.generated';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ExportTasksConfigsQueryVariables = Types.Exact<{ [key: string]: never }>;

export type ExportTasksConfigsQuery = {
  __typename?: 'Query';
  exportTasksConfigs: {
    __typename?: 'ExportConfigList';
    data: Array<{
      __typename?: 'ExportConfig';
      type: Types.ExportType;
      columns: Array<{
        __typename?: 'ExportConfigOption';
        title: string;
        description?: string | null;
        key: string;
        value?: boolean | null;
      }>;
      options: Array<{
        __typename?: 'ExportConfigOption';
        title: string;
        description?: string | null;
        key: string;
        value?: boolean | null;
      }>;
    }>;
  };
};

export const ExportTasksConfigsDocument = gql`
  query ExportTasksConfigs {
    exportTasksConfigs {
      data {
        ...ExportConfig
      }
    }
  }
  ${ExportConfigFragmentDoc}
`;

export function useExportTasksConfigsQuery(
  options?: Omit<Urql.UseQueryArgs<ExportTasksConfigsQueryVariables>, 'query'>,
) {
  return Urql.useQuery<ExportTasksConfigsQuery, ExportTasksConfigsQueryVariables>({
    query: ExportTasksConfigsDocument,
    ...options,
  });
}
