import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CreateAppMessageMutationVariables = Types.Exact<{
  input: Types.CreateAppMessageInput;
}>;

export type CreateAppMessageMutation = {
  __typename?: 'Mutation';
  createAppMessage: { __typename?: 'AppMessage'; id: string };
};

export const CreateAppMessageDocument = gql`
  mutation CreateAppMessage($input: CreateAppMessageInput!) {
    createAppMessage(input: $input) {
      id
    }
  }
`;

export function useCreateAppMessageMutation() {
  return Urql.useMutation<CreateAppMessageMutation, CreateAppMessageMutationVariables>(
    CreateAppMessageDocument,
  );
}
