import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type DeleteTaskFormMutationVariables = Types.Exact<{
  task_form_id: Types.Scalars['ID'];
  force?: Types.InputMaybe<Types.Scalars['Boolean']>;
}>;

export type DeleteTaskFormMutation = {
  __typename?: 'Mutation';
  deleteTaskForm: { __typename?: 'TaskForm'; id: string };
};

export const DeleteTaskFormDocument = gql`
  mutation DeleteTaskForm($task_form_id: ID!, $force: Boolean) {
    deleteTaskForm(task_form_id: $task_form_id, force: $force) {
      id
    }
  }
`;

export function useDeleteTaskFormMutation() {
  return Urql.useMutation<DeleteTaskFormMutation, DeleteTaskFormMutationVariables>(
    DeleteTaskFormDocument,
  );
}
