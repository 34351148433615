import { useThemeColorValues } from '@module/layout';
import { memo, SVGAttributes } from 'react';
import { Marker, MarkerProps } from 'react-map-gl';

interface PinMarkerProps extends MarkerProps {
  size?: SVGAttributes<SVGElement>['width'];
  fill?: SVGAttributes<SVGElement>['fill'];
  stroke?: SVGAttributes<SVGElement>['stroke'];
}

export const PinMarker = memo((props: PinMarkerProps) => {
  const { PRIMARY } = useThemeColorValues();
  const { size = 32, fill = PRIMARY, stroke = 'rgba(255, 255, 255, 0.75)', ...rest } = props;

  return (
    <Marker {...rest}>
      <svg width={size} height={size} viewBox="0 0 24 25" fill={fill} stroke={stroke}>
        <path d="M20.2 15.7A10 10 0 0 0 12 0a10 10 0 0 0-7.4 16.8c2.6 3.1 7.4 7.6 7.4 7.6s4.8-4.5 7.4-7.5l.7-.9.1-.3z" />
      </svg>
    </Marker>
  );
});
PinMarker.displayName = 'PinMarker';
