export default {
  labels: {
    prev: 'Zurück',
    next: 'Weiter',
    contactData: 'Kontaktdaten',
    damage: 'Schaden',
    bankData: 'Bankdaten',
  },
  components: {
    mandatoryFieldsIndicator: {
      incompleteLabel: 'Pflichtfelder {{completedCount}}/{{totalCount}}',
      completeLabel: 'Pflichtfelder',
    },
  },
  dialogs: {
    insertSavedFormValues: {
      title: 'Formulardaten vorhanden',
      description:
        'Es sind bereits Formulardaten von einer früheren Version vorhanden. Wollen Sie diese übernehmen oder ein neues Formular beginnen?',
      insert: 'Daten übernehmen',
      newForm: 'Neues Formular',
    },
  },
  pages: {
    customerFormPage: {
      title: 'Kundenformular',
    },
  },
};
