export default {
  tabs: {
    common: 'Common',
    todos: 'ToDo',
    form: 'Form',
    images: 'Images',
    documents: 'Documents',
    emails: 'Emails',
    files: 'Files',
    calculation: 'Calculation',
    report: 'Reports',
    appointment: 'Appointments',
    roomItems: 'Room plan',
    invoices: 'Invoices',
    statistics: 'Statistics',
    logs: 'Logs',
    customerPortal: 'Customer Portal',
    additionalForms: 'Subforms',
    subforms: 'Subforms',
  },
  statistic: {
    dialogs: {
      exportTask: {
        title: 'Download statistics',
        note: 'Download the exported task and its statistics',
      },
    },
  },
  templateForm: {
    spellCheck: 'Spell check',
    dialogs: {
      exportTask: {
        title: 'Download form',
        note: 'Download the exported task and its form',
      },
      spellCheck: {
        title: 'Spell check',
        note: 'Here you can perform the spell check',
      },
    },
    mandatoryFieldsIndicator: {
      incompleteLabel: 'Mandatory fields {{completedCount}}/{{totalCount}}',
      completeLabel: 'Mandatory fields',
    },
  },
  customerPortal: {
    files: {
      dialogs: {
        documents: {
          title: 'Select documents',
          description: 'Add documents to your meeting',
        },
      },
      documents: {
        title: 'Documents',
        labels: {
          choose: 'Select documents',
          accept: 'Accept documents',
          decline: 'Reject documents',
        },
        grid: {
          columns: {
            original: 'Original',
            modified: 'Edited',
            noThumbnail: 'No thumbnail',
          },
        },
        teaser:
          'Here you can provide documents for the customer to review or UNsign via their customer link.',
      },
      files: {
        title: 'Images',
        teaser:
          'Here you will find the images that the customer has uploaded via his customer link. You can then transfer these to your order.',
        labels: {
          accept: 'Accept images',
          decline: 'Reject images',
        },
        grid: {
          columns: {
            file: 'Image',
          },
        },
      },
    },
    detail: {
      notFound: 'Meeting not found.',
      data: {
        title: 'Meeting',
      },
      buttons: {
        join: {
          label: 'Join meeting',
          inFuture: 'Meeting starts in: {{ duration }}',
          isNow: 'Meeting is now',
          inPast: 'Meeting was already',
        },
        copy: 'Copy link',
        sendSMS: 'Send SMS',
        sendEmail: 'send email',
      },
      messages: {
        sendSMS: 'SMS has been sent',
        sendEmail: 'Email has been sent.',
      },
      labels: {
        from: 'Start',
        to: 'End',
        contactPersonEmail: 'Contact person email',
        contactPersonName: 'Contact Person',
        contactPersonPhone: 'Contact Person Phone',
        customerEmail: 'Customer email',
        customerName: 'Name of primary participant',
        customerPhone: 'Customer phone',
        roomIdentifier: 'Room',
        allowCustomerUpload: 'Customer Upload allowed',
      },
    },
    grid: {
      toolbar: {
        create: 'Create Meeting',
      },
      noDate: 'Without appointment',
      from: 'Appointment',
      identifier: 'Room',
      customerName: 'Customer',
      type: 'OrderType',
      sendSms: 'SMS sent',
      sendEmail: 'Email sent',
      hasPosition: 'Position available',
      customerPhone: 'Customer phone number',
      customerEmail: 'Email customer',
      contactPersonName: 'Contact person',
    },
    messages: {
      created: 'Meeting successfully created',
      updated: 'Meeting successfully updated',
    },
    linkInfoDialog: {
      title: 'The link was copied to the clipboard',
      note: 'Please note that links are only for sending out to the customer.',
      confirm: 'All clear',
    },
  },
  roomPlaner: {
    grid: {
      title: 'Title',
      description: 'Description',
      size: 'Size',
      sum: 'Sum',
      length: 'Length',
      width: 'Width',
      height: 'Height',
      selectedServices: 'Selected services: ',
      toolbar: {
        create: 'New room',
        createCategory: 'New Category',
        settings: {
          transferData: 'Transfer room data into the calculation',
          syncMagicplan: 'Synchronize Magicplan',
          exportJson: 'Export data as JSON',
        },
      },
    },
    dialogs: {
      create: {
        title: 'Create entry',
        note: 'You can create additional rooms here',
        labels: {
          fieldSet: 'Create item',
          title: 'Title',
          description: 'Description',
          size: 'Size',
          category: 'Category',
          length: 'Length in m',
          width: 'Width in m',
          height: 'Height in m',
          serviceCatalogItems: 'Articles',
        },
      },
      update: {
        title: 'Edit entry',
        note: 'You can edit the room here',
      },
      delete: {
        title: 'Delete entry',
        message: 'Do you really want to delete this entry?',
      },
      transferData: {
        title: 'Transfer room data',
        description:
          'This allows you to transfer room data directly into the calculation as categories',
        useServiceCatalogCategories: 'Use service catalog categories',
      },
    },
  },
  appointment: {
    create: 'Appointment',
  },
  noteDialog: {
    title: 'Note for "{{title}}"',
    note: 'Enter any free text note for the selected question',
    buttonLabel: 'Note',
    startRecording: 'Start recording',
    stopRecording: 'Stop recording',
  },
  subTaskDialog: {
    title: {
      label: 'Create subtask',
      title: 'Title',
      description: 'Description',
      finishDateDefaultDays: 'Default days',
    },
    note: 'You can create more subtasks here',
    due: '({{ date }})',
    today: 'Today',
    editTitle: 'Edit task',
    editNote: 'Here you can edit the subtask',
    update: 'Save task',
    edit: {
      success: 'Successfully saved',
      error: 'Task was not saved!',
    },
    add3days: '3 days',
    add7days: '7 days',
    add14days: '14 days',
    file: {
      label: 'Linked file',
      selectFile: 'Link file',
    },
    add1day: '1 day',
  },
  subTaskGroup: {
    new: 'New',
    task: 'Order',
    created_at: 'Created on',
    title: 'Title',
    description: 'Description',
    controller: 'Controller',
    actions: 'Actions',
    inProgress: 'In Progress',
    completed: 'Completed',
    status: 'Status',
    assignedUser: 'Assigned to',
    userAssignment: 'Assignment',
    create: 'New task',
    search: 'Search',
    taskId: 'Task ID',
    undone: 'Mark as open',
    done: 'Mark as complete',
    edit: 'Edit',
    toDo: 'Open task',
    review: 'Mark for review',
    reopen: 'Back to open',
    delete: 'Delete',
  },
  logs: {
    list: {
      header: {
        date: 'Date & Time',
        from: 'From',
        message: 'Message',
        state: 'Status',
      },
    },
    dialogs: {
      create: {
        title: 'Create message',
        note: 'Here you can create new messages',
        labels: {
          title: 'Message',
        },
      },
    },
    sms: "Sent Message: '{{message}}'",
  },
  errors: {
    not_found: {
      title: 'Task not found',
      note: 'The Task you requested was not found',
    },
    not_activated: {
      title: 'Order not activated',
      note:
        'The order has been created and assigned to you.\n' +
        'The administrator has not yet released the order.\n' +
        'You will be able to view the order as soon as this is available.',
    },
  },
  contextMenu: {
    title: 'Advanced',
    copyFormData: 'Copy from other task',
    changeTemplate: 'Change form',
    createMessage: 'Create message',
    createTaskLog: 'Create entry',
    compareFormData: 'Compare form data with another order',
  },
  jumpMenu: {
    title: 'Jump to',
  },
  copyFormDataDialog: {
    title: 'Copy form data',
    submit: 'Copy',
    warning: 'Warning, this will overwrite all form data in the job. Do you want to continue?',
    labels: {
      from: 'From the job',
    },
    preview: 'View current form data in the new tab',
  },
  copyQuestionDialog: {
    buttonLabel: 'Copy questions',
    title: 'Copy questions',
    submit: 'Copy',
    labels: {
      template: 'Form',
    },
  },
  createSatelliteImageDialog: {
    title: 'Create Satellite Image',
    description:
      'Here you can create a satellite photo of an address. You can also draw elements on it',
    success: 'Satellite image was created.',
  },
  satelliteImageDescription: {
    titleRow: 'Satellite image of {{address}}',
    areaRow: 'Object {{num}}: {{area}}',
  },
  emails: {
    downloadAllAttachments: 'Download all attachments ({{count}})',
    to: 'To: ',
    cc: 'CC:',
    bcc: 'Bcc:',
    from: 'By:',
    state: {
      scheduled: 'will be sent...',
      delivered: 'sent',
      failed: 'failed.',
    },
  },
  subTaskFileDialog: {
    title: 'Link file',
    note: 'Link a file to this task.',
    submit: 'Link selection',
  },
  satelliteImageAddressDialog: {
    title: 'Update address',
    submit: 'Refresh',
    geolocateError: 'No coordinates were found for this address.',
  },
  sortImagesDialog: {
    title: 'Sort images',
  },
  sortDocumentsDialog: {
    title: 'Sort documents',
  },
};
