import { useTranslation } from 'react-i18next';

import { PrimaryButton, PrimaryButtonProps } from './PrimaryButton';

export type CreateButtonProps = Omit<PrimaryButtonProps, 'iconClass'>;
export const CreateButton = (props: CreateButtonProps) => {
  const { t } = useTranslation();
  const { label = t('common.labels.create') } = props;
  return <PrimaryButton {...props} label={label} iconClass="l-i-plus" />;
};
