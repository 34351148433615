import {
  CancelButton,
  Dialog,
  DialogContent,
  DialogHeader,
  DialogHeaderBar,
  DialogHeaderIcon,
  PrimaryButton,
} from '@module/layout';
import { DialogProps, useDialogs } from '@module/shared/dialogs';
import { NotificationType, useNotifications } from '@module/shared/notifications';
import { DropDownButton, DropDownButtonItemClickEvent } from '@progress/kendo-react-buttons';
import { DialogActionsBar } from '@progress/kendo-react-dialogs';
import { SwitchChangeEvent } from '@progress/kendo-react-inputs';
import { OperationContext, OperationResult } from '@urql/core';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  ExportConfigInput,
  ExportConfigOption,
  ExportConfigOptionInput,
  ExportType,
} from '../../../../types/graphql.generated';
import { ExportConfigFragment, ExportResponseFragment } from '../../graphql';
import { DateFormat, useFormatDate } from '../../hooks';
import { useDownloadsContext } from '../downloads';
import { ActionItem } from '../grid-actions-button/GridActionsButton';
import { LabelSwitchInput } from '../inputs';
import { Loader } from '../Loader';

interface ExportData {
  __typename?: string;
  file_name: string;
  url: string;
}

interface ExportDialogProps {
  title: string;
  description: string;
  config: ExportConfigFragment;
  onSubmit: (config: ExportConfigInput) => Promise<ExportData | ExportResponseFragment | undefined>;
  hideOptions?: boolean;
}

export const ExportDialog = (props: ExportDialogProps & DialogProps) => {
  const { title, description, config, onSubmit, hideOptions } = props;
  const { t } = useTranslation();
  const { hideDialog } = useDialogs();
  const { showNotification } = useNotifications();
  const formatDate = useFormatDate();
  const initialOptionInputs = useMemo<ExportConfigOptionInput[]>(
    () =>
      config.options.map(({ key, value }) => ({
        key,
        value: value === true,
      })),
    [config.options],
  );
  const [isLoading, setLoading] = useState(false);
  const [optionInputs, setOptionInputs] = useState<ExportConfigOptionInput[]>(initialOptionInputs);
  const toggleOption = (optionInput: ExportConfigOptionInput) => (e: SwitchChangeEvent) => {
    setOptionInputs(
      optionInputs.map((optionInputArray: ExportConfigOptionInput) =>
        optionInputArray.key === optionInput.key
          ? {
              ...optionInput,
              value: e.value === true,
            }
          : optionInputArray,
      ),
    );
  };

  const initialColumnInputs = useMemo<ExportConfigOptionInput[]>(
    () =>
      config.columns.map(({ key, value }) => ({
        key,
        value: value === true,
      })),
    [config.columns],
  );
  const [columnInputs, setColumnInputs] = useState<ExportConfigOptionInput[]>(initialColumnInputs);
  const toggleColumn = (columnInput: ExportConfigOptionInput) => (e: SwitchChangeEvent) => {
    setColumnInputs(
      columnInputs.map((columnInputArray: ExportConfigOptionInput) =>
        columnInputArray.key === columnInput.key
          ? {
              ...columnInput,
              value: e.value === true,
            }
          : columnInputArray,
      ),
    );
  };

  const handleClose = () => {
    hideDialog(props.dialogId);
  };

  const handleSubmit = async () => {
    setLoading(true);
    const response = await onSubmit({
      type: config.type,
      options: optionInputs,
      columns: columnInputs,
      timestamp: formatDate(new Date(), DateFormat.DateTime),
    });
    setLoading(false);

    if (response && isExportData(response) && response.url) {
      window.open(response.url, '_blank', 'noopener,noreferrer');
      showNotification(t('common.dialogs.export.success'), NotificationType.Success);
      handleClose();
    } else if (response && isExportResponse(response) && response.message) {
      showNotification(response.message, NotificationType.Success);
      handleClose();
    } else {
      showNotification(t('common.dialogs.export.error'), NotificationType.Error);
    }
  };

  return (
    <Dialog width="medium" onClose={handleClose}>
      <DialogHeaderBar layout="flex">
        <DialogHeaderIcon iconClass="l-i-file-down u-text-2xl" color="primary" />
        <DialogHeader title={title} description={description} />
      </DialogHeaderBar>

      <DialogContent>
        {optionInputs && config.options.length > 0 && !hideOptions && (
          <>
            {optionInputs.map((optionInput, index) => {
              const option: ExportConfigOption | undefined = config.options.find(
                (option) => option.key === optionInput.key,
              );
              return (
                option && (
                  <div key={index} className="row">
                    <div className="col-md-10">
                      <h3 className="k-m-0">{option.title}</h3>
                      <p>{option.description}</p>
                    </div>
                    <div className="col-md-2">
                      <LabelSwitchInput
                        value={optionInput.value}
                        onLabel={t('common.components.inputs.switch.active')}
                        offLabel={t('common.components.inputs.switch.inactive')}
                        onChange={toggleOption(optionInput)}
                        defaultChecked={optionInput.value}
                      />
                    </div>
                  </div>
                )
              );
            })}
          </>
        )}
        {optionInputs && config.columns.length > 0 && (
          <>
            {columnInputs.map((columnInput, index) => {
              const column = config.columns.find((column) => column.key === columnInput.key);
              return (
                column && (
                  <div key={index}>
                    <label> {column.title}</label>

                    <LabelSwitchInput
                      value={columnInput.value}
                      onLabel={t('common.components.inputs.switch.active')}
                      offLabel={t('common.components.inputs.switch.inactive')}
                      onChange={toggleColumn(columnInput)}
                    />
                  </div>
                )
              );
            })}
          </>
        )}
        {isLoading && <Loader />}
      </DialogContent>

      <DialogActionsBar layout="end">
        <CancelButton onClick={handleClose} />
        <PrimaryButton
          onClick={handleSubmit}
          label={t('common.labels.export')}
          iconClass="l-i-file-down"
        />
      </DialogActionsBar>
    </Dialog>
  );
};

interface TExportMutationBase {
  [key: string]: ExportData | ExportResponseFragment | 'Mutation';
}

interface TExportMutationVariablesBase {
  config: ExportConfigInput;
}

function isExportData(item: ExportData | ExportResponseFragment | 'Mutation'): item is ExportData {
  return item !== 'Mutation' && 'url' in item;
}

function isExportResponse(
  item: ExportData | ExportResponseFragment | 'Mutation',
): item is ExportResponseFragment {
  return item !== 'Mutation' && 'message' in item;
}

export function useExportDialog<
  TExportMutation extends TExportMutationBase,
  TExportMutationVariables extends TExportMutationVariablesBase,
  K extends keyof TExportMutation,
>(
  mutation: (
    variables: TExportMutationVariables,
    context?: Partial<OperationContext>,
  ) => Promise<OperationResult<TExportMutation, TExportMutationVariables>>,
  key: K,
  dialogProps: Pick<ExportDialogProps, 'title' | 'description'>,
  configs: ExportConfigFragment[],
  variables?: Omit<TExportMutationVariables, 'config'>,
  hideOptions?: boolean,
) {
  const { showDialog } = useDialogs();
  const { downloadCreated } = useDownloadsContext();

  return useCallback(
    (type: ExportType) => {
      let config = configs.find((config) => config.type === type);
      if (!config) {
        config = { type: type, columns: [], options: [] };
      }

      const onSubmit = async (
        config: ExportConfigInput,
      ): Promise<ExportData | ExportResponseFragment | undefined> => {
        const response = await mutation({ ...variables, config } as TExportMutationVariables);
        const exportData = response.data?.[key];

        if (!response.error && response.data && exportData && isExportData(exportData)) {
          return exportData;
        }
        if (!response.error && response.data && exportData && isExportResponse(exportData)) {
          if (exportData.download) downloadCreated(exportData.download);
          return exportData;
        }
      };

      showDialog({ ...dialogProps, config, onSubmit, hideOptions }, ExportDialog);
    },
    [configs, dialogProps, key, mutation, showDialog, variables, hideOptions, downloadCreated],
  );
}

export const useDefaultExportActionItems = () => {
  const { t } = useTranslation();

  return [
    {
      actionType: ExportType.XLSX,
      actionName: t('common.dialogs.export.labels.xlsx'),
      itemClass: 'large',
      icon: 'file-xls',
    },
  ];
};

interface ExportActionItem extends ActionItem {
  itemClass?: string;
}

interface ExportButtonProps {
  onClick?: (type: ExportType) => void;
  actionItems?: ExportActionItem[];
  disabled?: boolean;
}

export const ExportButton = ({ actionItems, onClick, disabled }: ExportButtonProps) => {
  const defaultItems = useDefaultExportActionItems();
  const items: ActionItem[] = actionItems ?? defaultItems;
  const { t } = useTranslation();

  const handleActionsDropdown = (e: DropDownButtonItemClickEvent) => {
    const exportType = e.item.actionType as ExportType;
    onClick?.(exportType);
  };

  return (
    <DropDownButton
      items={items}
      size="large"
      buttonClass="k-icon-button"
      iconClass="l-i-file-down"
      onItemClick={handleActionsDropdown}
      textField="actionName"
      disabled={disabled}
      ariaLabel={t('common.labels.export')}
    />
  );
};
