import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type UploadMenuIconMutationVariables = Types.Exact<{
  file?: Types.InputMaybe<Types.Scalars['Upload']>;
}>;

export type UploadMenuIconMutation = {
  __typename?: 'Mutation';
  uploadMenuIcon: {
    __typename?: 'AppSettings';
    menu_icon?: { __typename?: 'FileItem'; file_name: string; url: string; id: string } | null;
  };
};

export const UploadMenuIconDocument = gql`
  mutation UploadMenuIcon($file: Upload) {
    uploadMenuIcon(file: $file) {
      menu_icon {
        file_name
        url
        id
      }
    }
  }
`;

export function useUploadMenuIconMutation() {
  return Urql.useMutation<UploadMenuIconMutation, UploadMenuIconMutationVariables | void>(
    UploadMenuIconDocument,
  );
}
