import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CreateTaskRoomItemMutationVariables = Types.Exact<{
  task_id: Types.Scalars['ID'];
  input: Types.TaskRoomItemInput;
}>;

export type CreateTaskRoomItemMutation = {
  __typename?: 'Mutation';
  createTaskRoomItem?: {
    __typename?: 'TaskRoomItem';
    description?: string | null;
    id: string;
    size?: number | null;
    height?: number | null;
    uuid: string;
    length?: number | null;
    width?: number | null;
    task_id: string;
    title?: string | null;
    category?: { __typename?: 'TaskRoomCategory'; id: string; title: string } | null;
    service_catalog_items?: Array<{
      __typename?: 'ServiceCatalogItem';
      id: string;
      title: string;
    }> | null;
  } | null;
};

export const CreateTaskRoomItemDocument = gql`
  mutation CreateTaskRoomItem($task_id: ID!, $input: TaskRoomItemInput!) {
    createTaskRoomItem(task_id: $task_id, input: $input) {
      category {
        id
        title
      }
      description
      id
      size
      height
      uuid
      length
      width
      task_id
      title
      service_catalog_items {
        id
        title
      }
    }
  }
`;

export function useCreateTaskRoomItemMutation() {
  return Urql.useMutation<CreateTaskRoomItemMutation, CreateTaskRoomItemMutationVariables>(
    CreateTaskRoomItemDocument,
  );
}
