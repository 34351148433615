import { without } from 'lodash';
import {
  createContext,
  ReactNode,
  Reducer,
  useContext,
  useEffect,
  useMemo,
  useReducer,
} from 'react';

import { Download, DownloadStateId } from '../../../../types/graphql.generated';
import { DownloadFragment, useDownloadGeneratedSubscription } from '../../graphql';

type DownloadItem = NonNullable<DownloadFragment>;

interface DownloadsState {
  created: Array<DownloadItem['id']>;
  generated: Array<DownloadItem>;
}

type DownloadsAction =
  | { type: 'DOWNLOAD_CREATED'; id: Download['id'] }
  | { type: 'DOWNLOAD_GENERATED'; download: DownloadItem }
  | { type: 'REMOVE_DOWNLOAD'; id: Download['id'] };

const downloadsReducer: Reducer<DownloadsState, DownloadsAction> = (state, action) => {
  switch (action.type) {
    case 'DOWNLOAD_CREATED': {
      if (state.created.includes(action.id)) {
        return state;
      }

      if (state.generated.some(({ id }) => id === action.id)) {
        return state;
      }

      return { ...state, created: [...state.created, action.id] };
    }

    case 'DOWNLOAD_GENERATED': {
      // Prevent duplicates
      if (state.generated.find(({ id }) => id === action.download.id)) {
        return state;
      }
      return {
        ...state,
        created: without(state.created, action.download.id),
        generated: [...state.generated, action.download],
      };
    }

    case 'REMOVE_DOWNLOAD': {
      const downloadToRemove = state.generated.find(({ id }) => id === id);

      if (!downloadToRemove) {
        return state;
      }

      return {
        ...state,
        generated: without(state.generated, downloadToRemove),
      };
    }

    default: {
      return state;
    }
  }
};

interface DownloadsContextType {
  state: DownloadsState;
  downloadCreated: (id: DownloadItem) => void;
  removeDownload: (id: DownloadItem['id']) => void;
}

const DownloadsContext = createContext<DownloadsContextType | undefined>(undefined);

export const useDownloadsContext = () => {
  const context = useContext(DownloadsContext);

  if (!context) {
    throw new Error('useDownloadsContext must be within DownloadsContext');
  }

  return context;
};

export const DownloadsProvider = (props: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(downloadsReducer, { created: [], generated: [] });
  const [{ data: dataGeneratedSubscription }] = useDownloadGeneratedSubscription();

  useEffect(() => {
    if (dataGeneratedSubscription?.downloadGenerated) {
      dispatch({
        type: 'DOWNLOAD_GENERATED',
        download: dataGeneratedSubscription.downloadGenerated,
      });
    }
  }, [dataGeneratedSubscription]);

  const contextValue = useMemo<DownloadsContextType>(
    () => ({
      state,
      downloadCreated: (download: DownloadItem) => {
        if (download.state_id === DownloadStateId.CREATED) {
          dispatch({ type: 'DOWNLOAD_CREATED', id: download.id });
        }

        if (download.state_id === DownloadStateId.GENERATED) {
          if (download.file_ids.length === 1) {
            dispatch({ type: 'REMOVE_DOWNLOAD', id: download.id });
            window.location.href = download.url_download;
          } else {
            dispatch({ type: 'DOWNLOAD_GENERATED', download });
          }
        }
      },
      removeDownload: (id) => dispatch({ type: 'REMOVE_DOWNLOAD', id }),
    }),
    [state],
  );

  return <DownloadsContext.Provider value={contextValue} {...props} />;
};
