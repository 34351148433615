export default {
  labels: {
    to: 'To:',
    cc: 'CC:',
    bcc: 'BCC:',
    subject: 'Naslov:',
    body: 'Sadržaj',
    attachments: 'Prilozi:',
    send: 'Pošalji',
    chooseEmailTemplate: 'Odaberi predložak',
    totalAttachmentSize: 'Ukupna veličina privitaka: {{size}}',
    totalAttachmentSizeToBig:
      'Ukupna veličina privitaka: {{size}}. Pažnja, ukupna veličina privitaka je > 30 Mbajta - ne može se jamčiti uspješno slanje poruke.',
    priority: {
      lowest: 'Najniža',
      low: 'Niska',
      normal: 'Normalan',
      high: 'visoko',
      highest: 'Najviši',
    },
  },
  notifications: {
    createEmail: {
      success: 'E-Mail poslan!',
      error: 'Adresa primatelja ne može biti prazna!',
    },
    emailComposer: {
      error: 'Nemate dopuštenje za slanje e-pošte!',
    },
  },
  dialogs: {
    emailComposer: {
      title: 'Sastavite E-Mail',
    },
    emailAddAttachments: {
      title: 'dodati priloge',
      note: 'Odaberite datoteke koje želite priložiti.',
      submit: 'Odaberite',
    },
  },
};
