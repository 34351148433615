import { colord } from 'colord';

const LIGHTNESS_AMOUNT = 0.75;

export const getContrastColor = (hexColorString: string) => {
  const color = colord(hexColorString);

  if (color.brightness() >= 0.7) {
    return color.darken(LIGHTNESS_AMOUNT).toHex();
  }

  return color.lighten(LIGHTNESS_AMOUNT).toHex();
};
