import { isNotNullish } from '@module/shared/helpers';
import {
  UploadFileInfo,
  UploadFileStatus,
  UploadOnAddEvent,
  UploadOnProgressEvent,
  UploadOnRemoveEvent,
  UploadOnStatusChangeEvent,
} from '@progress/kendo-react-upload';
import { SetStateAction, useCallback, useMemo, useRef, useState } from 'react';

export interface UploadFileInfoWithGetRawFile extends Omit<UploadFileInfo, 'getRawFile'> {
  getRawFile: () => File;
}
export const hasGetRawFile = (fileInfo: UploadFileInfo): fileInfo is UploadFileInfoWithGetRawFile =>
  isNotNullish(fileInfo.getRawFile);

export interface CustomUploadHandle {
  triggerUpload: () => void;
}

const groupByUid = (files: UploadFileInfo[]): Record<UploadFileInfo['uid'], UploadFileInfo> =>
  Object.fromEntries(files.map((fileInfo) => [fileInfo.uid, fileInfo]));

export type SetUploadFiles = (value: SetStateAction<Array<UploadFileInfo>>) => void;

export const useUploadHandlers = (setFiles: SetUploadFiles) => {
  const onAdd = useCallback(({ newState }: UploadOnAddEvent) => setFiles(newState), [setFiles]);

  const onRemove = useCallback(
    ({ newState }: UploadOnRemoveEvent) => setFiles(newState),
    [setFiles],
  );

  const onProgress = useCallback(
    ({ affectedFiles }: UploadOnProgressEvent) => {
      const affectedFilesByUid = groupByUid(affectedFiles);

      setFiles((state) =>
        state.map((fileInfo) =>
          fileInfo.uid in affectedFilesByUid
            ? { ...fileInfo, progress: affectedFilesByUid[fileInfo.uid].progress }
            : fileInfo,
        ),
      );
    },
    [setFiles],
  );

  const onStatusChange = useCallback(
    ({ affectedFiles }: UploadOnStatusChangeEvent) => {
      const affectedFilesByUid = groupByUid(affectedFiles);

      setFiles((state) =>
        state.map((fileInfo) =>
          fileInfo.uid in affectedFilesByUid
            ? { ...fileInfo, status: affectedFilesByUid[fileInfo.uid].status }
            : fileInfo,
        ),
      );
    },
    [setFiles],
  );

  return { onAdd, onRemove, onProgress, onStatusChange };
};

interface UploadStates {
  /** Whether there are files that are currently being uploaded. */
  uploading: boolean;
  /** Whether there are files that have not yet be attempted to be uploaded and that have no validation errors. */
  hasFilesForUpload: boolean;
}

const getUploadStates = (files: Array<UploadFileInfo>): UploadStates => ({
  uploading: files.some(({ status }) => status === UploadFileStatus.Uploading),
  hasFilesForUpload: files.some(
    ({ status, validationErrors }) =>
      status === UploadFileStatus.Selected && (validationErrors ?? []).length === 0,
  ),
});

export function useCustomUpload() {
  const [files, setFiles] = useState<Array<UploadFileInfo>>([]);
  const uploadHandlers = useUploadHandlers(setFiles);
  const uploadRef = useRef<CustomUploadHandle>(null);
  const clearFiles = useCallback(() => setFiles([]), []);
  const triggerUpload = useCallback(() => uploadRef.current?.triggerUpload(), []);
  const uploadProps = { ...uploadHandlers, ref: uploadRef, files };
  const uploadStates = useMemo(() => getUploadStates(files), [files]);

  return { uploadProps, ...uploadStates, clearFiles, triggerUpload };
}
