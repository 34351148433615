import { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';

import { useIsAuthenticated } from '../hooks';

export const NavigateToLoginIfNotAuthenticated = ({ children }: { children: ReactNode }) => {
  const isAuthenticated = useIsAuthenticated();

  return isAuthenticated ? <>{children}</> : <Navigate to="/login" replace />;
};
