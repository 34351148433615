import { useMemo } from 'react';

import { GridState } from './types';

interface GridPagination {
  skip: number;
  take: number;
}

export function useGridPagination(
  gridState: GridState,
  defaultSkip = 0,
  defaultTake = 10,
): GridPagination {
  return useMemo(() => {
    return {
      skip: gridState.skip ?? defaultSkip,
      take: gridState.take ?? defaultTake,
    };
  }, [defaultSkip, defaultTake, gridState.skip, gridState.take]);
}
