import { PSPDFKIT_LICENSE_KEY, ROUTER_BASENAME } from '@env';
import { isValidUrl } from '@module/shared/forms';
import { createLoaderReducer } from '@module/shared/helpers';
import { useLocale } from '@module/shared/localization';
import { has, omit } from 'lodash';
import PSPDFKit, {
  Color,
  type Instance,
  type StampAnnotation,
  type StandaloneConfiguration,
  type ToolbarItem,
  type ViewState,
} from 'pspdfkit';
import { useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocalstorageState } from 'rooks';
import { LocaleDefinition } from 'src/localization';

import { useMe } from '../../casl';
import { FileType } from '../helpers';
import { Mutable } from '../types';
import { useThemeColor } from './usePsPdfKitColor';

interface PsPDFMessages {
  [locale: string]: Record<string, string>;
}

const getPSPDFKitLocale = (locale: LocaleDefinition) => {
  // supported locales: https://pspdfkit.com/guides/web/features/localization/
  return locale.localeId.split('-')[0];
};

const useSaveDefaultAnnotationPresets = (instance: Instance | null) => {
  // used types from pspdfkit index.d.ts as they are not exported

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  type AnnotationPreset = Record<string, any>;
  type AnnotationPresetID = string;

  interface AnnotationPresetsUpdateEvent {
    preventDefault: () => boolean;
    currentPreset: AnnotationPresetID;
    currentPresetProperties: AnnotationPreset;
    newPresetProperties: AnnotationPreset;
  }

  const [savedPresets, setSavedPresets] = useLocalstorageState<AnnotationPreset | null>(
    'savedPSPDFKitAnnotationPresets',
    null,
  );

  const isColor = (value: unknown): value is Color => {
    return has(value, 'r') && has(value, 'g') && has(value, 'b');
  };

  const getDefaultPresets = () => {
    const defaultPresets = PSPDFKit.defaultAnnotationPresets;
    const clonedPresets = { ...defaultPresets };
    if (!savedPresets) return clonedPresets;

    for (const [key, value] of Object.entries(savedPresets)) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const preset: any = clonedPresets[key];
      for (const [prop, propValue] of Object.entries(value)) {
        if (isColor(propValue)) {
          const color = new Color(propValue as Partial<{ r: number; g: number; b: number }>);
          preset[prop] = color;
        } else {
          preset[prop] = propValue;
        }
      }
      clonedPresets[key] = preset;
    }
    return clonedPresets;
  };

  const defaultPresets = useMemo(() => {
    return getDefaultPresets();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSaveAnnotationPresets = (event: AnnotationPresetsUpdateEvent) => {
    const { currentPreset, newPresetProperties } = event;
    // if newPresetProperties contains property with key "boundingBox" delete it
    // because it is not serializable and not relevant for presets
    const serializablePresetProps = omit(newPresetProperties, 'boundingBox');
    setSavedPresets((prev) => {
      if (!prev) {
        return {
          [currentPreset]: serializablePresetProps,
        };
      }
      return {
        ...prev,
        [currentPreset]: {
          ...prev[currentPreset],
          ...serializablePresetProps,
        },
      };
    });
  };
  useEffect(() => {
    if (instance) {
      // This is needed to change annotation presets after instance is loaded
      // see https://pspdfkit.com/guides/web/customizing-the-interface/using-annotation-presets/
      instance.addEventListener('annotationPresets.update', handleSaveAnnotationPresets);

      return () => {
        instance.removeEventListener('annotationPresets.update', handleSaveAnnotationPresets);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [instance]);

  return defaultPresets;
};

export type PsPdfKitLib = typeof import('pspdfkit').default;

export type InstantJSON = NonNullable<StandaloneConfiguration['instantJSON']>;

// Need to convert readonly to mutable since PsPDFKit gives us readonly types
// when getting `defaultToolbarItems` but only allows mutable types when
// setting `toolbarItems`.
type DefaultToolbarItem = Mutable<PsPdfKitLib['defaultToolbarItems'][number]>;

export type ToolbarItemsCallback = (args: {
  defaultToolbarItems: Array<DefaultToolbarItem>;
  psPdfKitLib: PsPdfKitLib;
  getInstance: () => Instance;
}) => Array<ToolbarItem>;

export interface UsePsPdfProps {
  documentUrl: string;
  containerElement: HTMLElement | null;
  instantJSON?: StandaloneConfiguration['instantJSON'];
  /** This callback **needs to have a stable identity**. So either create it outside of render or memoize with useCallback. */
  toolbarItems?: ToolbarItemsCallback;
  showToolbar?: boolean;
  sidebarMode?: ViewState['sidebarMode'];
  enableDocumentEditor?: boolean;
}

const useCreateStampAnnotationTemplates = () => {
  const me = useMe();
  const { t } = useTranslation();

  return useCallback(
    (psPdfKitLib: PsPdfKitLib): Array<StampAnnotation> => [
      new psPdfKitLib.Annotations.StampAnnotation({
        stampType: 'Custom',
        title: t('common.pdf.stamps.approved.title'),
        subtitle: t('common.pdf.stamps.approved.subtitle', { name: me.name }),
        color: new psPdfKitLib.Color({ r: 53, g: 87, b: 30 }),
        boundingBox: new psPdfKitLib.Geometry.Rect({
          left: 0,
          top: 0,
          width: 240,
          height: 80,
        }),
      }),
      ...psPdfKitLib.defaultStampAnnotationTemplates,
    ],
    [me.name, t],
  );
};

const psPdfKitLoaderReducer = createLoaderReducer<PsPdfKitLib>();

let cachedPsPdfKitLib: PsPdfKitLib;

const usePsPdfKitLoader = () => {
  const [state, dispatch] = useReducer(
    psPdfKitLoaderReducer,
    cachedPsPdfKitLib
      ? { loading: false, error: undefined, data: cachedPsPdfKitLib }
      : { loading: true, error: undefined },
  );

  useEffect(() => {
    (async () => {
      if (cachedPsPdfKitLib) {
        return;
      }

      dispatch({ type: 'PENDING' });

      try {
        cachedPsPdfKitLib = (await import('pspdfkit')).default;
        dispatch({ type: 'RESOLVED', data: cachedPsPdfKitLib });
      } catch (error) {
        dispatch({ type: 'REJECTED', error });
      }
    })();
  }, []);

  return state;
};

const documentLoaderReducer = createLoaderReducer<ArrayBuffer>();

const useDocumentLoader = (documentUrl: string) => {
  const [state, dispatch] = useReducer(documentLoaderReducer, { loading: true, error: undefined });

  useEffect(() => {
    (async () => {
      dispatch({ type: 'PENDING' });

      try {
        if (!isValidUrl(documentUrl)) {
          throw new Error('Invalid documentUrl');
        }

        // fetch data with credentials to work around cors errors
        const response = await fetch(documentUrl, { credentials: 'include' });
        dispatch({ type: 'RESOLVED', data: await response.arrayBuffer() });
      } catch (error) {
        dispatch({ type: 'REJECTED', error });
      }
    })();
  }, [documentUrl]);

  return state;
};

export const usePsPdfKit = (props: UsePsPdfProps) => {
  const {
    documentUrl,
    containerElement,
    instantJSON,
    showToolbar,
    toolbarItems,
    sidebarMode,
    enableDocumentEditor,
  } = props;
  const { t } = useTranslation();

  const { setThemeColor } = useThemeColor();
  const createStampAnnotationTemplates = useCreateStampAnnotationTemplates();

  const psPdfKitLoader = usePsPdfKitLoader();
  const psPdfKitLib = psPdfKitLoader.data;
  const documentLoader = useDocumentLoader(documentUrl);
  const documentData = documentLoader.data;

  const [instance, setInstance] = useState<Instance | null>(null);
  const defaultPresets = useSaveDefaultAnnotationPresets(instance);
  const [instanceError, setInstanceError] = useState<Error | undefined>();
  const { locale } = useLocale();

  useEffect(() => {
    (async function () {
      if (!containerElement || !psPdfKitLib || !documentData) {
        return;
      }
      // Since we have the license for the document editor are enabled by default. As we need the document feaures just on some places, we consider the document editor NOT as default.
      let defaultToolbarItems =
        psPdfKitLib.defaultToolbarItems as unknown as Array<DefaultToolbarItem>;

      if (!enableDocumentEditor) {
        defaultToolbarItems = defaultToolbarItems.filter(
          (toolbarItem) =>
            toolbarItem.type !== 'document-editor' && toolbarItem.type !== 'document-crop',
        );
      }

      const basename = ROUTER_BASENAME || '/';
      try {
        setInstanceError(undefined);
        const psPdfKitLocale = getPSPDFKitLocale(locale);
        const loadedInstance: Instance = await psPdfKitLib.load({
          locale: psPdfKitLocale,
          licenseKey: PSPDFKIT_LICENSE_KEY,
          container: containerElement,
          document: documentData,
          baseUrl: `${window.location.origin}${basename}`,
          styleSheets: [`${window.location.origin}${basename}pspdf.css`],
          initialViewState: new psPdfKitLib.ViewState({
            zoom: psPdfKitLib.ZoomMode.FIT_TO_VIEWPORT,
            showToolbar,
            sidebarMode,
          }),
          toolbarItems: toolbarItems
            ? toolbarItems?.({
                defaultToolbarItems,
                getInstance: () => loadedInstance,
                psPdfKitLib,
              })
            : defaultToolbarItems,
          enableClipboardActions: true,
          stampAnnotationTemplates: createStampAnnotationTemplates(psPdfKitLib),
          instantJSON,
          annotationPresets: defaultPresets,
        });

        const newTranslations = {
          calibrateScale: t('pdfForms.components.measurements.calibrateScale'),
          polygonAreaMeasurement: t('pdfForms.components.measurements.polygonAreaMeasurement'),
          setScale: t('pdfForms.components.measurements.setScale'),
          newScale: t('pdfForms.components.measurements.newScale'),
          displaySecondaryUnit: t('pdfForms.components.measurements.displaySecondaryUnit'),
          secondaryUnit: t('pdfForms.components.measurements.secondaryUnit'),
          scaleName: t('pdfForms.components.measurements.scaleName'),
          scale: t('pdfForms.components.measurements.scale'),
          editAddScale: t('pdfForms.components.measurements.editAddScale'),
          duplicateScaleError: t('pdfForms.components.measurements.duplicateScaleError'),
          measurementScale: t('pdfForms.components.measurements.measurementScale'),
          calibrationScaleSuccess: t('pdfForms.components.measurements.calibrationScaleSuccess'),
          calibrationScaleSubtitle: t('pdfForms.components.measurements.calibrationScaleSubtitle'),
          Snapping: t('pdfForms.components.measurements.Snapping'),
        };
        (psPdfKitLib.I18n.messages as PsPDFMessages)[psPdfKitLocale] = {
          ...(psPdfKitLib.I18n.messages as PsPDFMessages)[psPdfKitLocale],
          ...newTranslations,
        };

        setThemeColor(loadedInstance);
        setInstance(loadedInstance);
      } catch (error) {
        // Unfortunately PSPDFKit's errors can't be translated. As a workaround, we set an error
        // state so that we can display a custom error message in the consuming component.
        setInstanceError(error instanceof Error ? error : new Error('PSPDFKit load error'));
        psPdfKitLib.unload(containerElement);

        throw error;
      }
    })();

    return () => {
      if (containerElement) {
        psPdfKitLib?.unload(containerElement);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    containerElement,
    psPdfKitLib,
    documentData,
    instantJSON,
    toolbarItems,
    showToolbar,
    sidebarMode,
    createStampAnnotationTemplates,
    setThemeColor,
  ]);

  return useMemo(
    () => ({
      loading: psPdfKitLoader.loading || documentLoader.loading || false,
      error: psPdfKitLoader.error || documentLoader.error || instanceError || undefined,
      instance,
    }),
    [psPdfKitLoader, documentLoader, instance, instanceError],
  );
};

export const createFilteredToolbarItems =
  (types: Array<DefaultToolbarItem['type']>): ToolbarItemsCallback =>
  ({ defaultToolbarItems }) =>
    defaultToolbarItems.filter((item) => types.includes(item.type));

export const PSPDFKIT_FILE_TYPES = [FileType.JPG, FileType.PDF, FileType.PNG, FileType.TIF];
