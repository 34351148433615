/**
 * ATTENTION  react router removed the following hooks during beta and will probably
 * add them again later
 * https://github.com/remix-run/react-router/issues/8139
 *
 * This code serves as a temporary solution
 * https://gist.github.com/rmorse/426ffcc579922a82749934826fa9f743
 */

/**
 * These hooks re-implement the now removed useBlocker and usePrompt hooks in 'react-router-dom'.
 * Thanks for the idea @piecyk https://github.com/remix-run/react-router/issues/8139#issuecomment-953816315
 * Source: https://github.com/remix-run/react-router/commit/256cad70d3fd4500b1abcfea66f3ee622fb90874#diff-b60f1a2d4276b2a605c05e19816634111de2e8a4186fe9dd7de8e344b65ed4d3L344-L381
 */
import { Blocker, History } from 'history';
import { useCallback, useContext, useEffect, useRef } from 'react';
import { UNSAFE_NavigationContext as NavigationContext } from 'react-router-dom';

declare type NavigatorWithBlock = Pick<History, 'block' | 'go' | 'push' | 'replace' | 'createHref'>;

export type { Blocker };

/**
 * Blocks all navigation attempts. This is useful for preventing the page from
 * changing until some condition is met, like saving form data.
 *
 * @param  blocker
 * @param  when
 * @see https://reactrouter.com/api/useBlocker
 */
export function useBlocker(blocker: Blocker, when = true) {
  const { navigator } = useContext(NavigationContext);
  const navigatorCasted = navigator as NavigatorWithBlock;
  const shouldBlockOnlyOnce = useRef(false);

  useEffect(() => {
    if (!when) return;

    if (shouldBlockOnlyOnce.current) return;

    const unblock = navigatorCasted.block((tx) => {
      shouldBlockOnlyOnce.current = true;

      const autoUnblockingTx = {
        ...tx,
        retry() {
          // Automatically unblock the transition so it can play all the way
          // through before retrying it. TODO: Figure out how to re-enable
          // this block if the transition is cancelled for some reason.
          unblock();
          tx.retry();
        },
      };

      blocker(autoUnblockingTx);
    });

    return unblock;
  }, [blocker, when, navigatorCasted]);
}

/**
 * Prompts the user with an Alert before they leave the current screen.
 *
 * @param  message
 * @param  when
 */
export function usePrompt(message: string, when = true) {
  const blocker = useCallback<Blocker>(
    (tx) => {
      // eslint-disable-next-line no-alert
      if (window.confirm(message)) tx.retry();
    },
    [message],
  );

  useBlocker(blocker, when);
}
