import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type TaskExportColumnFragment = {
  __typename?: 'TaskExportColumn';
  name: string;
  label: string;
};

export type ExportTasksAvailableColumnsQueryVariables = Types.Exact<{ [key: string]: never }>;

export type ExportTasksAvailableColumnsQuery = {
  __typename?: 'Query';
  exportTasksAvailableColumns: {
    __typename?: 'TasksExportColumns';
    columns: Array<{ __typename?: 'TaskExportColumn'; name: string; label: string }>;
  };
};

export const TaskExportColumnFragmentDoc = gql`
  fragment TaskExportColumn on TaskExportColumn {
    name
    label
  }
`;
export const ExportTasksAvailableColumnsDocument = gql`
  query ExportTasksAvailableColumns {
    exportTasksAvailableColumns {
      columns {
        ...TaskExportColumn
      }
    }
  }
  ${TaskExportColumnFragmentDoc}
`;

export function useExportTasksAvailableColumnsQuery(
  options?: Omit<Urql.UseQueryArgs<ExportTasksAvailableColumnsQueryVariables>, 'query'>,
) {
  return Urql.useQuery<ExportTasksAvailableColumnsQuery, ExportTasksAvailableColumnsQueryVariables>(
    { query: ExportTasksAvailableColumnsDocument, ...options },
  );
}
