import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type AssignInterfaceMessageTemplateToClientsMutationVariables = Types.Exact<{
  templateId: Types.Scalars['ID'];
  clientIds: Array<Types.Scalars['ID']> | Types.Scalars['ID'];
}>;

export type AssignInterfaceMessageTemplateToClientsMutation = {
  __typename?: 'Mutation';
  assignInterfaceMessageTemplateToClients: {
    __typename?: 'ResponseMessage';
    success?: boolean | null;
    message?: string | null;
  };
};

export const AssignInterfaceMessageTemplateToClientsDocument = gql`
  mutation AssignInterfaceMessageTemplateToClients($templateId: ID!, $clientIds: [ID!]!) {
    assignInterfaceMessageTemplateToClients(
      interface_message_template_id: $templateId
      client_ids: $clientIds
    ) {
      success
      message
    }
  }
`;

export function useAssignInterfaceMessageTemplateToClientsMutation() {
  return Urql.useMutation<
    AssignInterfaceMessageTemplateToClientsMutation,
    AssignInterfaceMessageTemplateToClientsMutationVariables
  >(AssignInterfaceMessageTemplateToClientsDocument);
}
