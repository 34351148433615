import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import { TaskSubTaskFragmentDoc } from './taskSubTaskFragment.generated';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type UpdateTaskSubTaskMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  input: Types.UpdateTaskSubTaskInput;
}>;

export type UpdateTaskSubTaskMutation = {
  __typename?: 'Mutation';
  updateTaskSubTask: {
    __typename?: 'TaskSubTask';
    id: string;
    task_id: string;
    title: string;
    description?: string | null;
    due_date?: string | null;
    completed_date?: string | null;
    permissions: Array<Types.Permission>;
    state: { __typename?: 'TaskSubTaskState'; id: string; title: string; color: string };
    file?: {
      __typename?: 'FileItem';
      id: string;
      original_file_name: string;
      mime_type?: string | null;
      url: string;
    } | null;
  };
};

export const UpdateTaskSubTaskDocument = gql`
  mutation UpdateTaskSubTask($id: ID!, $input: UpdateTaskSubTaskInput!) {
    updateTaskSubTask(id: $id, input: $input) {
      ...TaskSubTask
    }
  }
  ${TaskSubTaskFragmentDoc}
`;

export function useUpdateTaskSubTaskMutation() {
  return Urql.useMutation<UpdateTaskSubTaskMutation, UpdateTaskSubTaskMutationVariables>(
    UpdateTaskSubTaskDocument,
  );
}
