export default {
  dialogs: {
    confirmCreateRandomTasks: {
      title: 'Testaufträge erzeugen',
      description:
        'Achtung, damit erzeugen Sie die gewählten Aufträge in dem Portal. Wollen Sie fortfahren?',
    },
  },
  forms: {
    createRandomTasks: {
      title: 'Test-Aufträge erzeugen',
      description:
        'Definieren Sie hier die Anzahl und Eigenschaften der zu erzeugenden Test-Aufträge.',
      numTasks: 'Anzahl der Testaufträge',
      numTasksTooltip:
        'Beachten Sie, dass für das erstellen von Aufträgen zumindest eine Versicherung, ein Regulierer und ein Teamleiter existieren muss.',
      state: 'Status',
      stateOptions: {
        NEW: 'Neu',
        ACCEPTED: 'Akzeptiert',
      },
      urgent: 'Dringend',
      submit: 'Testdaten erzeugen',
    },
  },
  notifications: {
    createRandomTasks: {
      success: 'Testaufträge erfolgreich erzeugt.',
      error: 'Fehler beim Erzeugen der Testaufträge.',
    },
  },
  pages: {
    demoData: {
      title: 'Demo-Daten',
      description: 'Hier können Sie Demo-Daten erzeugen.',
    },
  },
};
