import { groupBy } from 'lodash';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { NotificationTypesQuery, useNotificationTypesQuery } from '../graphql';

type NotificationTypeItem = NotificationTypesQuery['notificationTypes'][number];

export type GroupedNotificationTypes = Array<{
  group: NotificationTypeItem['group'];
  title: string;
  description: string;
  notificationTypes: Array<NotificationTypeItem>;
}>;

export const useGroupedNotificationTypesQuery = () => {
  const { t } = useTranslation();
  const [queryState] = useNotificationTypesQuery();

  const groupedNotificationTypes = useMemo<GroupedNotificationTypes | undefined>(() => {
    if (!queryState.data) {
      return;
    }

    return Object.entries(groupBy(queryState.data.notificationTypes, ({ group }) => group)).map(
      ([group, notificationTypes]) => ({
        group,
        title: t(`common.notificationGroups.${group}.title`),
        description: t(`common.notificationGroups.${group}.description`),
        notificationTypes,
      }),
    );
  }, [t, queryState.data]);

  return { ...queryState, groupedNotificationTypes };
};
