export default {
  tabs: {
    common: 'Besichtigungsdaten',
  },
  customerPortal: {
    files: {
      files: {
        teaser:
          'Hier finden Sie die Bilder, welcher der Kunde über seinen Kunden-Link hochgeladen hat. Diese können Sie her dann in Ihren Bericht übernehmen.',
      },
    },
    grid: {
      type: 'Besichtigungstyp',
    },
  },

  subTaskGroup: {
    task: 'Besichtigung',
    taskId: 'Besichtigungs ID',
  },

  errors: {
    not_found: {
      title: 'Besichtigung nicht gefunden',
      note: 'Die von Ihnen angeforderte Besichtigung wurde nicht gefunden',
    },
    not_activated: {
      title: 'Besichtigung nicht freigeschalten',
      note: 'Die Besichtigung wurde angelegt und Ihnen zugewiesen.\nBislang liegt vom Administrator noch keine Freigabe der Besichtigung vor.\nSobald diese vorliegt, können Sie die Besichtigung einsehen.',
    },
  },
  contextMenu: {
    copyFormData: 'Formulardaten von einer anderen Besichtigung einfügen',
    compareFormData: 'Formulardaten mit einer anderen Besichtigung vergleichen',
  },

  copyFormDataDialog: {
    title: 'Formulardaten von einer anderen Besichtigung',
    warning:
      'Diese Funktion dient dazu, dass man ähnlichen Fragen einer anderen Besichtigung hier einfügen kann. Sofern das Formular in der Quell- und Zielbesichtigung unterschiedlich ist, werden nur die Antworten mit dem selben Identifier über beide Formulare überschrieben. Achtung, durch das ausführen löschen Sie womöglich bereits getätigte Eingaben in diesem Formular. Möchten Sie fortfahren?',
    labels: {
      from: 'Quellbesichtigung wählen',
    },
  },
};
