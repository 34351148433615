export default {
  email: 'Enter a valid email e.g. max.mustermann@example.com',
  firstname: 'Firstname should be atleast 4 characters',
  required: 'This field should not be empty.',
  mixed: {
    required: 'This field should not be empty.',
    notType: 'Field is invalid.',
  },
  string: {
    email: 'Enter a valid email e.g. max.mustermann@example.com',
    min: 'Must be at least {{min}} characters',
    length: 'Must contain {{length}} characters.',
    trim: 'May not start or end with spaces.',
    rgb: 'Must use RGB format e.g. #FFFFFF.',
    rgba: 'Must use RGBA format e.g. #FFFFFFff.',
    max: 'This field may contain a maximum of {{max}} characters long',
  },
  number: {
    integer: 'Must be an integer.',
    min: 'Must be at least {{min}}.',
    max: 'Cannot exceed {{max}}.',
  },
  uploadFileInfo: 'Invalid file(s). Please check file upload requirements.',
  password: {
    length: 'At least {{min}} characters.',
    special: 'At least {{min}} special characters (e.g. !@#$%^&*-).',
    numbers: 'At least {{min}} numbers (e.g. 0-9)',
    uppercase: 'At least {{min}} in uppercase.',
    confirmation: 'Password confirm must match.',
    strength: 'The password is too weak.',
    chain: {
      general: 'Password must contain the following: {{chain}}',
      length: '{{min}} character',
      special: '{{min}} special characters (e.g. !@#$%^&*-).',
      numbers: '{{min}} Number(s) (e.g. 0-9)',
      uppercase: '{{min}} Capital letter(s).',
    },
  },
  array: {
    min: 'Must contain at least {{min}} element(s).',
    max: 'May contain a maximum of {{max}} element(s).',
  },
};
