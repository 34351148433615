import {
  Ability,
  AbilityBuilder,
  AbilityClass,
  ForcedSubject,
  subject as subjectHelper,
} from '@casl/ability';
import { useCallback, useMemo } from 'react';

import { Permission } from '../../../types/graphql.generated';
import { GenericCan, GenericCanSharedProps } from '../components/GenericCan';
import { CurrentUserAbilityFragment } from '../graphql';
import { detectSubjectTypeByTypename as detectSubjectType } from '../helpers';
import { useMeOrDefault } from '../hooks';

interface PayoutSubject {
  me: CurrentUserAbilityFragment;
}

type PayoutsSubjectArg = Partial<PayoutSubject>;

type Actions =
  | 'indexPayouts'
  | 'updatePayouts'
  | 'createPayouts'
  | 'setPayoutsPaid'
  | 'cancelPayouts';

type Subjects = PayoutSubject | 'PayoutsSubject';

export enum PayoutsActionType {
  READ = 'indexPayouts',
  UPDATE = 'updatePayouts',
  CREATE = 'createPayouts',
  SET_PAID = 'setPayoutsPaid',
  CANCEL = 'cancelPayouts',
}

type PayoutAbility = Ability<[Actions, Subjects]>;
const payoutAbility = Ability as AbilityClass<PayoutAbility>;

export const usePayoutsAbility = (): [
  PayoutAbility,
  (sub?: PayoutsSubjectArg) => PayoutSubject & ForcedSubject<'PayoutsSubject'>,
] => {
  const ability = useMemo(() => {
    const { can, build } = new AbilityBuilder(payoutAbility);
    // global permissionss

    can('indexPayouts', 'PayoutsSubject', {
      'me.globalPermissions': { $in: [Permission.PAYOUT_INDEX] },
    });
    can('updatePayouts', 'PayoutsSubject', {
      'me.globalPermissions': { $in: [Permission.PAYOUT_UPDATE] },
    });
    can('setPayoutsPaid', 'PayoutsSubject', {
      'me.globalPermissions': { $in: [Permission.PAYOUT_SET_PAID] },
    });
    can('cancelPayouts', 'PayoutsSubject', {
      'me.globalPermissions': { $in: [Permission.PAYOUT_CANCEL] },
    });
    can('createPayouts', 'PayoutsSubject', {
      'me.globalPermissions': { $in: [Permission.PAYOUT_CREATE] },
    });

    return build({ detectSubjectType });
  }, []);

  const me = useMeOrDefault();
  const subject = useCallback(
    (sub?: PayoutsSubjectArg) => {
      return subjectHelper('PayoutsSubject', { me, ...sub });
    },
    [me],
  );

  return [ability, subject];
};

type CanActivityProps = GenericCanSharedProps<Actions>;

export const CanPayout = (props: CanActivityProps) => {
  const [payoutAbility, payoutSubject] = usePayoutsAbility();

  return (
    <GenericCan<Actions, Subjects, PayoutAbility>
      ability={payoutAbility}
      subject={payoutSubject()}
      {...props}
    />
  );
};
