import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type UpdateLoginPageMutationVariables = Types.Exact<{
  input: Types.UpdateLoginPageInput;
}>;

export type UpdateLoginPageMutation = {
  __typename?: 'Mutation';
  updateLoginPage: {
    __typename?: 'AppSettings';
    login_welcome_text?: string | null;
    conditions?: string | null;
    imprint?: string | null;
    privacy?: string | null;
  };
};

export const UpdateLoginPageDocument = gql`
  mutation UpdateLoginPage($input: UpdateLoginPageInput!) {
    updateLoginPage(input: $input) {
      login_welcome_text
      conditions
      imprint
      privacy
    }
  }
`;

export function useUpdateLoginPageMutation() {
  return Urql.useMutation<UpdateLoginPageMutation, UpdateLoginPageMutationVariables>(
    UpdateLoginPageDocument,
  );
}
