import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
export type CalendarEventTypeFragment = {
  __typename?: 'CalendarEventType';
  id: string;
  title: string;
  color: string;
  type_id: Types.CalendarEventTypeId;
};

export const CalendarEventTypeFragmentDoc = gql`
  fragment CalendarEventType on CalendarEventType {
    id
    title
    color
    type_id
  }
`;
