import { GridCellProps } from '@progress/kendo-react-grid';
import { useTranslation } from 'react-i18next';

import { ThemeColor } from '../../../../types/graphql.generated';
import { Badge } from '../badges';

export const BooleanCell = (props: GridCellProps) => {
  const { t } = useTranslation();
  const { dataItem, field } = props;

  const value = field ? dataItem[field] : false;

  return (
    <td>
      {value ? (
        <Badge backgroundThemeColor={ThemeColor.PRIMARY} color={'white'}>
          {t('common.labels.yes')}
        </Badge>
      ) : (
        <Badge backgroundThemeColor={ThemeColor.LIGHT} color={'black'}>
          {t('common.labels.no')}
        </Badge>
      )}
    </td>
  );
};
