import './UnstyledButton.scss';

import { Button, ButtonProps } from '@progress/kendo-react-buttons';
import { classNames } from '@progress/kendo-react-common';

export const UnstyledButton = (props: ButtonProps) => {
  const { children, type = 'button', size = 'small', ...rest } = props;

  return (
    <Button
      {...rest}
      className={classNames('UnstyledButton', props.className)}
      type={type}
      size={size}
    >
      {children}
    </Button>
  );
};
