import {
  Ability,
  AbilityBuilder,
  AbilityClass,
  ForcedSubject,
  subject as subjectHelper,
} from '@casl/ability';
import { useCallback, useMemo } from 'react';

import { Permission } from '../../../types/graphql.generated';
import { GenericCan, GenericCanSharedProps } from '../components/GenericCan';
import { CurrentUserAbilityFragment } from '../graphql';
import { detectSubjectTypeByTypename as detectSubjectType } from '../helpers';
import { useMeOrDefault } from '../hooks';

interface ReportTemplateSubject {
  me: CurrentUserAbilityFragment;
}

type ReportTemplateSubjectArg = Partial<ReportTemplateSubject>;

type Actions =
  | 'viewReportTemplates'
  | 'createReportTemplate'
  | 'updateReportTemplate'
  | 'deleteReportTemplate';
type Subjects = ReportTemplateSubject | 'ReportTemplateSubject';

type ReportTemplateAbility = Ability<[Actions, Subjects]>;
const userAbility = Ability as AbilityClass<ReportTemplateAbility>;

export const useReportTemplateAbility = (): [
  ReportTemplateAbility,
  (
    sub?: ReportTemplateSubjectArg,
  ) => ReportTemplateSubject & ForcedSubject<'ReportTemplateSubject'>,
] => {
  const ability = useMemo(() => {
    const { can, build } = new AbilityBuilder(userAbility);

    can('viewReportTemplates', 'ReportTemplateSubject', {
      'me.globalPermissions': { $in: [Permission.REPORT_TEMPLATE_INDEX] },
    });
    can('createReportTemplate', 'ReportTemplateSubject', {
      'me.globalPermissions': { $in: [Permission.REPORT_TEMPLATE_CREATE] },
    });
    can('updateReportTemplate', 'ReportTemplateSubject', {
      'me.globalPermissions': { $in: [Permission.REPORT_TEMPLATE_UPDATE] },
    });
    can('deleteReportTemplate', 'ReportTemplateSubject', {
      'me.globalPermissions': { $in: [Permission.REPORT_TEMPLATE_DELETE] },
    });

    return build({ detectSubjectType });
  }, []);

  const me = useMeOrDefault();
  const subject = useCallback(
    (sub?: ReportTemplateSubjectArg) => {
      return subjectHelper('ReportTemplateSubject', { me, ...sub });
    },
    [me],
  );

  return [ability, subject];
};

type CanReportTemplateProps = GenericCanSharedProps<Actions>;

export const CanReportTemplate = (props: CanReportTemplateProps) => {
  const [reportTemplateAbility, reportTemplateSubject] = useReportTemplateAbility();

  return (
    <GenericCan<Actions, Subjects, ReportTemplateAbility>
      ability={reportTemplateAbility}
      subject={reportTemplateSubject()}
      {...props}
    />
  );
};
