export default {
  labels: {
    identifier: 'Angebots-Nr.',
    externalNumber: 'Schadennummer',
    date: 'Angebotsdatum',
    taskId: 'Auftrag',
    note: 'Notiz',
    address: 'Addresse',
    sumNet: 'Summe Netto',
    sumGross: 'Summe Brutto',
    stateId: 'Status',
    client: 'Kunde',
    activate: 'Angebot aktivieren',
    createInvoiceFromProposal: 'Rechnung erstellen',
    cancelProposal: 'Angebot stornieren',
    acceptProposal: 'Angebot akzeptieren',
    rejectProposal: 'Angebot ablehnen',
    editNote: 'Mahnstatus bearbeiten',
    total: 'Gesamt:',
    clients: 'Versicherungen',
  },
  dialogs: {
    delete: {
      title: 'Angebot löschen?',
      description: 'Sind Sie sicher, dass Sie die Angebot löschen wollen?',
    },
    cancelProposal: {
      title: 'Angebot stornieren?',
      description: 'Sind Sie sicher, dass Sie das Angebot stornieren wollen?',
    },
    acceptProposal: {
      title: 'Angebot annehmen?',
      description: 'Sind Sie sicher, dass Sie das Angebot annehmen wollen?',
      notifications: {
        success: 'Angebot angenommen.',
        error: 'Angebot konnte nicht angenommen werden.',
      },
    },
    rejectProposal: {
      title: 'Angebot ablehnen?',
      description: 'Sind Sie sicher, dass Sie das Angebot ablehnen wollen?',
      notifications: {
        success: 'Angebot abgelehnt.',
        error: 'Angebot konnte nicht abgelehnt werden.',
      },
    },
    createInvoiceFromProposal: {
      title: 'Rechnung erstellen',
      description: 'Sind Sie sicher, dass Sie eine Rechnung zu diesem Angebot erstellen wollen?',
      notifications: {
        success: 'Rechnung erfolgreich erstellt.',
        error: 'Rechnung konnte nicht erstellt werden.',
      },
    },
    invoiceExport: {
      title: 'Angebote exportieren',
      note: 'Laden Sie die exportierten Angebote herunter.',
    },
    editNote: {
      title: 'Mahnstatus',
      note: 'Mahnstatus bearbeiten',
      reminder: 'Mahnung verschickt',
      notifications: {
        error: 'Mahnstatus konnte leider nicht geändert werden.',
        success: 'Mahnstatus erfolgreich geändert.',
      },
    },
    proposalExport: {
      title: 'Angebot exportieren',
      note: 'Laden Sie die exportierten Angebote herunter.',
    },
    payDate: 'Bezahlt am',
    overdueDate: 'Zahlungsziel',
  },
  pages: {
    overview: {
      title: 'Angebote',
      description:
        'Hier sehen Sie eine Übersicht aller vom System erzeugten Angebote. Sie können über den Aktionen Button die Angebote öffnen.',
    },
  },
};
