import {
  Ability,
  AbilityBuilder,
  AbilityClass,
  ForcedSubject,
  subject as subjectHelper,
} from '@casl/ability';
import { useCallback, useMemo } from 'react';

import { Permission } from '../../../types/graphql.generated';
import { GenericCan, GenericCanSharedProps } from '../components/GenericCan';
import { CurrentUserAbilityFragment } from '../graphql';
import { detectSubjectTypeByTypename as detectSubjectType } from '../helpers';
import { useMeOrDefault } from '../hooks';

interface PdfTemplateSubject {
  me: CurrentUserAbilityFragment;
}

type PdfTemplateSubjectArg = Partial<PdfTemplateSubject>;

type Actions = 'viewPdfTemplates' | 'createPdfTemplate' | 'updatePdfTemplate' | 'deletePdfTemplate';
type Subjects = PdfTemplateSubject | 'PdfTemplateSubject';

type PdfTemplateAbility = Ability<[Actions, Subjects]>;
const userAbility = Ability as AbilityClass<PdfTemplateAbility>;

export const usePdfTemplateAbility = (): [
  PdfTemplateAbility,
  (sub?: PdfTemplateSubjectArg) => PdfTemplateSubject & ForcedSubject<'PdfTemplateSubject'>,
] => {
  const ability = useMemo(() => {
    const { can, build } = new AbilityBuilder(userAbility);

    can('viewPdfTemplates', 'PdfTemplateSubject', {
      'me.globalPermissions': { $in: [Permission.PDF_TEMPLATE_INDEX] },
    });
    can('createPdfTemplate', 'PdfTemplateSubject', {
      'me.globalPermissions': { $in: [Permission.PDF_TEMPLATE_CREATE] },
    });
    can('updatePdfTemplate', 'PdfTemplateSubject', {
      'me.globalPermissions': { $in: [Permission.PDF_TEMPLATE_UPDATE] },
    });
    can('deletePdfTemplate', 'PdfTemplateSubject', {
      'me.globalPermissions': { $in: [Permission.PDF_TEMPLATE_DELETE] },
    });

    return build({ detectSubjectType });
  }, []);

  const me = useMeOrDefault();
  const subject = useCallback(
    (sub?: PdfTemplateSubjectArg) => {
      return subjectHelper('PdfTemplateSubject', { me, ...sub });
    },
    [me],
  );

  return [ability, subject];
};

type CanPdfTemplateProps = GenericCanSharedProps<Actions>;

export const CanPdfTemplate = (props: CanPdfTemplateProps) => {
  const [pdfTemplateAbility, pdfTemplateSubject] = usePdfTemplateAbility();

  return (
    <GenericCan<Actions, Subjects, PdfTemplateAbility>
      ability={pdfTemplateAbility}
      subject={pdfTemplateSubject()}
      {...props}
    />
  );
};
