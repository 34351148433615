import { parseISO } from 'date-fns';

/**
 * Fixes UTC timezone offset of datetime inputs with date database fields
 *
 * The problem is that 2024-08-23T22:00:00.000Z will be converted 2024-08-23
 * thus the date is always one day of
 *
 * This is a temporary fix until with have a proper solution with Date inputs in graphql
 *
 * @see https://inspcto.atlassian.net/browse/IN-4245 for futher information
 *
 * @param date
 */
export const fixDateTimeToDateOffset = (date: string | null | undefined) => {
  if (date) {
    const isoDate = parseISO(date);
    const day = isoDate.getDate();
    isoDate.setUTCDate(day);
    isoDate.setUTCHours(0, 0, 0);

    return isoDate.toISOString();
  }

  return date;
};
