import { Button } from '@progress/kendo-react-buttons';
import { FieldWrapper } from '@progress/kendo-react-form';
import { TextArea, TextAreaHandle, TextAreaProps } from '@progress/kendo-react-inputs';
import { t } from 'i18next';
import { forwardRef, Ref, useId } from 'react';

import { useDialogs } from '../../dialogs';
import { Label } from '../components/Label';
import { FreeTextMaximizeDialog } from '../dialogs/FreeTextMaximizeDialog';
import { FieldError } from './FieldError';
import { FieldHint } from './FieldHint';
import { sanitizeInputValue } from './helpers';
import { CommonFieldRenderProps } from './types';

export type TextAreaInputProps = CommonFieldRenderProps &
  Pick<
    TextAreaProps,
    | 'size'
    | 'id'
    | 'value'
    | 'placeholder'
    | 'rows'
    | 'required'
    | 'disabled'
    | 'autoSize'
    | 'onBlur'
    | 'onChange'
    | 'onFocus'
    | 'maxLength'
  >;

export const TextAreaInput = forwardRef(
  (props: TextAreaInputProps, ref?: Ref<TextAreaHandle> | undefined) => {
    const defaultId = useId();
    const {
      size = 'large',
      value,
      id = defaultId,
      label,
      placeholder,
      rows,
      valid,
      required,
      disabled,
      autoSize,
      onBlur,
      onChange,
      onFocus,
      name,
      maxLength,
      tooltip,
      tooltipPosition,
      tooltipContent,
      maximizeMode,
    } = props;
    const { showDialog } = useDialogs();
    const valueSanitized = sanitizeInputValue(value);
    const handleZoom = () => {
      showDialog(
        {
          text: valueSanitized,
          save: props.onChange,
          title: label,
        },
        FreeTextMaximizeDialog,
      );
    };

    const tooltipValue = !tooltip && tooltipContent ? 'default' : tooltip;

    return (
      <FieldWrapper style={{ position: 'relative' }}>
        {label && (
          <Label
            tooltip={tooltipValue}
            tooltipPosition={tooltipPosition}
            tooltipContent={tooltipContent}
            label={label}
            name={name}
            editorId={id}
            editorValid={valid}
          >
            {label}
          </Label>
        )}
        {maximizeMode && (
          <Button
            type="button"
            iconClass="l-i-expand"
            onClick={() => handleZoom()}
            style={{ position: 'absolute', zIndex: 2, right: 0, margin: '5px' }}
            aria-label={t('common.labels.maximize')}
          />
        )}
        <div>
          <TextArea
            ref={ref}
            size={size}
            id={id}
            value={valueSanitized}
            placeholder={placeholder}
            rows={rows}
            required={required}
            disabled={disabled}
            autoSize={autoSize}
            onBlur={onBlur}
            maxLength={maxLength}
            onChange={onChange}
            onFocus={onFocus}
          />
        </div>
        <div className="HintAndError">
          <FieldHint {...props} />
          <FieldError {...props} />
        </div>
      </FieldWrapper>
    );
  },
);

TextAreaInput.displayName = 'TextAreaInput';
