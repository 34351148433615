import './Dialog.scss';

import { Button } from '@progress/kendo-react-buttons';
import { classNames } from '@progress/kendo-react-common';
import { Dialog as KendoDialog, DialogProps } from '@progress/kendo-react-dialogs';
import {
  createContext,
  CSSProperties,
  MouseEvent,
  ReactNode,
  SyntheticEvent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { getStoredValue, storeValue } from '../../utils/storeDialogMaximizeState';

type DialogContentPaddingMode = 'with' | 'none';
type DialogContentScrollMode = 'auto' | 'hidden' | 'scroll';

interface DialogContentProps {
  children: ReactNode;
  className?: string;
  padding?: DialogContentPaddingMode;
  scrollX?: DialogContentScrollMode;
  scrollY?: DialogContentScrollMode;
  style?: CSSProperties | undefined;
}

export const DialogContent = (props: DialogContentProps) => {
  const { padding = 'with', scrollX = 'hidden', scrollY = 'auto', style } = props;

  return (
    <div
      className={classNames(
        'dialog-content k-flex-grow k-pos-relative',
        props.className,
        {
          'padding-with': padding === 'with',
          'padding-none': padding === 'none',
        },
        {
          'scroll-x-auto': scrollX === 'auto',
          'scroll-x-hidden': scrollX === 'hidden',
          'scroll-x-scroll': scrollX === 'scroll',
          'scroll-y-auto': scrollY === 'auto',
          'scroll-y-hidden': scrollY === 'hidden',
          'scroll-y-scroll': scrollY === 'scroll',
        },
      )}
      style={style}
    >
      {props.children}
    </div>
  );
};

const DialogContext = createContext<{ maximized: boolean } | undefined>(undefined);

export const useDialogContext = () => {
  const context = useContext(DialogContext);

  if (!context) {
    throw new Error('Missing DialogContext');
  }

  return context;
};

interface CustomDialogProps extends Omit<DialogProps, 'title'> {
  children: ReactNode;
  actions?: ReactNode;
  width?: 'auto' | 'small' | 'medium' | 'large' | 'xl' | 'fullscreen';
  height?: 'auto' | 'small' | 'medium' | 'large' | 'xl' | 'fullscreen';
  maximizable?: boolean;
  maximized?: boolean;
  storeStateUnderName?: string; // Name of the dialog under which the current state is saved in the localstore
  preventCloseOnEscape?: boolean;
}

export const Dialog = (props: CustomDialogProps) => {
  const { t } = useTranslation();
  const {
    children,
    actions,
    width = 'auto',
    height = 'auto',
    maximizable = false,
    className,
    closeIcon = true,
    onClose,
    storeStateUnderName,
    preventCloseOnEscape = false,
    ...rest
  } = props;

  const dialogRef = useRef<KendoDialog>();

  const [maximized, setMaximized] = useState<boolean>(
    maximizable &&
      (props.maximized ?? (storeStateUnderName ? getStoredValue(storeStateUnderName) : false)),
  );
  const toggleMaximized = useCallback(() => {
    if (maximizable && storeStateUnderName?.length) {
      storeValue(storeStateUnderName, !maximized);
    }
    setMaximized((state) => !state);
  }, [maximizable, maximized, storeStateUnderName]);

  const w = useMemo(() => (maximized ? 'fullscreen' : width), [maximized, width]);
  const h = useMemo(() => (maximized ? 'fullscreen' : height), [maximized, height]);

  const handleClose = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      if (dialogRef.current) {
        onClose?.({
          target: dialogRef.current,
          syntheticEvent: {} as SyntheticEvent,
          nativeEvent: e.nativeEvent,
        });
      }
    },
    [onClose],
  );

  const contextValue = useMemo(() => ({ maximized }), [maximized]);

  useEffect(() => {
    dialogRef.current?.element?.focus();
  }, []);

  return (
    <DialogContext.Provider value={contextValue}>
      <KendoDialog
        {...rest}
        ref={dialogRef}
        className={classNames('dialog', className, {
          'dialog-width-auto': w === 'auto',
          'dialog-width-sm': w === 'small',
          'dialog-width-md': w === 'medium',
          'dialog-width-lg': w === 'large',
          'dialog-width-xl': w === 'xl',
          'dialog-width-fullscreen': w === 'fullscreen',
          'dialog-height-auto': h === 'auto',
          'dialog-height-sm': h === 'small',
          'dialog-height-md': h === 'medium',
          'dialog-height-lg': h === 'large',
          'dialog-height-xl': h === 'xl',
          'dialog-height-fullscreen': h === 'fullscreen',
        })}
        style={{ height: undefined }}
        onClose={(e) => {
          if (preventCloseOnEscape) {
            if (e.nativeEvent.key === 'Escape') {
              return;
            }
          }
          if (dialogRef.current) {
            onClose?.({
              target: dialogRef.current,
              syntheticEvent: {} as SyntheticEvent,
              nativeEvent: e.nativeEvent,
            });
          }
        }}
      >
        <div className="dialog-actions">
          {actions}

          {maximizable && (
            <Button
              size="large"
              fillMode="flat"
              icon={maximized ? 'window-restore' : 'window-maximize'}
              onClick={toggleMaximized}
              aria-label={maximized ? t('common.labels.minimize') : t('common.labels.maximize')}
            />
          )}
          {closeIcon && onClose && (
            <Button
              size="large"
              fillMode="flat"
              iconClass="l-i-x"
              onClick={handleClose}
              aria-label={t('common.labels.cancel')}
            />
          )}
        </div>
        {children}
      </KendoDialog>
    </DialogContext.Provider>
  );
};
