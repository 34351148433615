export default {
  labels: {
    km: 'km',
    hour: 'h',
    sq_m: 'm²',
    sq_km: 'km²',
    mb: 'MB',
    minute: 'barem',
  },
  formats: {
    km: 'n2',
    hour: 'n2',
    sq_m: 'n2',
    sq_km: 'n2',
    mb: 'n2',
    integer: 'n0',
  },
};
