import { classNames } from '@progress/kendo-react-common';
import { FieldWrapper } from '@progress/kendo-react-form';
import { Switch, SwitchChangeEvent, SwitchProps } from '@progress/kendo-react-inputs';
import React, { createRef, ForwardedRef, forwardRef, useCallback, useId } from 'react';
import { useTranslation } from 'react-i18next';

import { Label } from '../components/Label';
import { FieldError } from './FieldError';
import { FieldHint } from './FieldHint';
import { CommonFieldRenderProps } from './types';

export type BaseSwitchChangeHandler = (event: {
  target: SwitchChangeEvent['target'];
  value: boolean;
}) => void;

export interface BaseSwitchProps
  extends Pick<
    SwitchProps,
    | 'id'
    | 'name'
    | 'size'
    | 'onLabel'
    | 'offLabel'
    | 'required'
    | 'disabled'
    | 'onBlur'
    | 'onFocus'
    | 'checked'
  > {
  ref?: React.RefObject<HTMLInputElement>;
  value?: boolean | null;
  onChange: BaseSwitchChangeHandler;
}

export const BaseSwitch = forwardRef((props: BaseSwitchProps, ref: ForwardedRef<unknown>) => {
  const { t } = useTranslation();

  const {
    value,
    size = 'large',
    onLabel = t('common.labels.on'),
    offLabel = t('common.labels.off'),
    onChange,
    ...rest
  } = props;

  const handleChange = useCallback(
    (event: SwitchChangeEvent) => onChange({ ...event, value: event.target.value }),
    [onChange],
  );

  return (
    <Switch
      {...rest}
      size={size}
      ref={ref}
      checked={value ?? false}
      onLabel={onLabel}
      offLabel={offLabel}
      onChange={handleChange}
    />
  );
});
BaseSwitch.displayName = 'BaseSwitch';

export interface SwitchInputProps extends CommonFieldRenderProps, Omit<BaseSwitchProps, 'name'> {
  labelPosition?: 'top' | 'left';
}

export const SwitchInput = (props: SwitchInputProps) => {
  const defaultId = useId();
  const {
    id = defaultId,
    name,
    value,
    size,
    onLabel,
    offLabel,
    labelPosition = 'top',
    required,
    disabled,
    onChange,
    onBlur,
    onFocus,
    valid,
    label,
    tooltip,
    tooltipPosition,
  } = props;
  const switchRef = createRef();

  return (
    <FieldWrapper>
      <div
        className={classNames(
          'k-d-flex k-gap-x-2 k-gap-y-1',
          labelPosition === 'top'
            ? 'k-d-flex-col'
            : 'k-d-flex k-align-items-center k-justify-content-between k-flex-wrap',
        )}
      >
        {label && (
          <Label
            tooltip={tooltip}
            tooltipPosition={tooltipPosition}
            label={label}
            editorId={id}
            editorValid={valid}
            name={name}
            editorRef={switchRef}
            className="k-m-0"
          >
            {label}
          </Label>
        )}

        <BaseSwitch
          id={id}
          name={name}
          value={value}
          size={size}
          ref={switchRef}
          onLabel={onLabel}
          offLabel={offLabel}
          required={required}
          disabled={disabled}
          checked={value ?? false}
          onChange={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
        />
      </div>
      <div className="HintAndError">
        <FieldHint {...props} />
        <FieldError {...props} />
      </div>
    </FieldWrapper>
  );
};
