import DOMPurify from 'dompurify';
import parse from 'html-react-parser';

interface HtmlContentProps {
  html?: string | null;
}

export const HtmlContent = (props: HtmlContentProps) => {
  const { html } = props;
  return <>{parse(DOMPurify.sanitize(html ?? '', { USE_PROFILES: { html: true } }))}</>;
};

export const htmlToText = (html?: string | null) => {
  return DOMPurify.sanitize(html ?? '', { USE_PROFILES: { html: false } });
};
