import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import { ExportResponseFragmentDoc } from '../../../common/graphql/generated/exportResponseFragment.generated';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ExportTaskSubTasksMutationVariables = Types.Exact<{
  filter: Types.TaskSubTaskFilter;
  sort: Types.TaskSubTaskSort;
  config: Types.ExportConfigInput;
}>;

export type ExportTaskSubTasksMutation = {
  __typename?: 'Mutation';
  exportTaskSubTasks: {
    __typename?: 'ExportResponse';
    success: boolean;
    message: string;
    download?: {
      __typename?: 'Download';
      id: string;
      state_id: Types.DownloadStateId;
      filename: string;
      file_ids: Array<string>;
      url_download: string;
    } | null;
  };
};

export const ExportTaskSubTasksDocument = gql`
  mutation ExportTaskSubTasks(
    $filter: TaskSubTaskFilter!
    $sort: TaskSubTaskSort!
    $config: ExportConfigInput!
  ) {
    exportTaskSubTasks(filter: $filter, sort: $sort, config: $config) {
      ...ExportResponse
    }
  }
  ${ExportResponseFragmentDoc}
`;

export function useExportTaskSubTasksMutation() {
  return Urql.useMutation<ExportTaskSubTasksMutation, ExportTaskSubTasksMutationVariables>(
    ExportTaskSubTasksDocument,
  );
}
