export default {
  dialogs: {
    userCreate: {
      title: 'Kreiraj korisnika',
      cancel: 'Odustani',
      submit: 'Kreiraj',
      fields: {
        email: {
          label: 'E-Mail',
          hint: 'Unesite svoj e-maio, npr. petar.peric@example.com',
        },
        firstname: {
          label: 'Ime',
        },
        lastname: {
          label: 'Prezime',
        },
        birthday: {
          label: 'Datum rođenja',
        },
      },
    },
    userDelete: {
      title: 'Izbriši korisnika',
      cancel: 'Odustani',
      submit: 'Izbriši',
      note: 'Stvarno želite izbrisati korisnika?',
    },
  },
  pages: {
    userOverview: {
      grid: {
        columns: {
          name: 'Ime',
          email: 'E-Mail',
          phone: 'Telefon',
        },
      },
      toolbar: {
        create: {
          label: 'Kreiraj',
        },
      },
    },
  },
};
