import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type EmailTemplatesSelectQueryVariables = Types.Exact<{ [key: string]: never }>;

export type EmailTemplatesSelectQuery = {
  __typename?: 'Query';
  emailTemplates: {
    __typename?: 'EmailTemplatePaginator';
    data: Array<{ __typename?: 'EmailTemplate'; id: string; title: string }>;
  };
};

export const EmailTemplatesSelectDocument = gql`
  query EmailTemplatesSelect {
    emailTemplates(filter: {}, sort: {}, pagination: { skip: 0, take: 999 }) {
      data {
        id
        title
      }
    }
  }
`;

export function useEmailTemplatesSelectQuery(
  options?: Omit<Urql.UseQueryArgs<EmailTemplatesSelectQueryVariables>, 'query'>,
) {
  return Urql.useQuery<EmailTemplatesSelectQuery, EmailTemplatesSelectQueryVariables>({
    query: EmailTemplatesSelectDocument,
    ...options,
  });
}
