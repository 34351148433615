import { BBox2d } from '@turf/helpers/dist/js/lib/geojson';
import { bbox, FeatureCollection } from '@turf/turf';

import { Bounds, Country } from '../../../types/graphql.generated';

export function isBBox2D(value: Array<number>): value is BBox2d {
  // turf uses `Infinity` for uncomputable coordinates
  // See: https://github.com/Turfjs/turf/blob/876702a454d5d085d6e1e54ae286c2b5bc08f584/packages/turf-bbox/index.ts#L31
  return value.length === 4 && value.every(Number.isFinite);
}

export function calculateBounds(geoJson: FeatureCollection): BBox2d | undefined {
  if (geoJson.features.length === 0) {
    return undefined;
  }

  const bounds = bbox(geoJson);

  if (!isBBox2D(bounds)) {
    return undefined;
  }

  return bounds;
}

export type BoundsTuple = [
  [minLongitude: number, minLatitude: number],
  [maxLongitude: number, maxLatitude: number],
];

export const countryBoundsToApiBounds = (
  country: Pick<
    Country,
    | 'bounding_box_min_longitude'
    | 'bounding_box_min_latitude'
    | 'bounding_box_max_longitude'
    | 'bounding_box_max_latitude'
  >,
): Bounds => {
  const {
    bounding_box_min_longitude,
    bounding_box_min_latitude,
    bounding_box_max_longitude,
    bounding_box_max_latitude,
  } = country;

  return {
    min: {
      longitude: bounding_box_min_longitude,
      latitude: bounding_box_min_latitude,
    },
    max: {
      longitude: bounding_box_max_longitude,
      latitude: bounding_box_max_latitude,
    },
  };
};

export const boundsTupleToApiBounds = (boundsTuple: BoundsTuple): Bounds => {
  const [[minLongitude, minLatitude], [maxLongitude, maxLatitude]] = boundsTuple;

  return {
    min: {
      longitude: minLongitude,
      latitude: minLatitude,
    },
    max: {
      longitude: maxLongitude,
      latitude: maxLatitude,
    },
  };
};

export const apiBoundsToBoundsTuple = (bounds: Bounds): BoundsTuple => {
  return [
    [bounds.min.longitude, bounds.min.latitude],
    [bounds.max.longitude, bounds.max.latitude],
  ];
};
