import './MapLabel.scss';

import { classNames } from '@progress/kendo-react-common';
import { HTMLAttributes } from 'react';

export type MapLabelProps = Pick<
  HTMLAttributes<HTMLDivElement>,
  'className' | 'style' | 'children'
>;
export const MapLabel = (props: MapLabelProps) => {
  return (
    <div
      className={classNames(
        'MapLabel k-font-weight-semibold k-px-4 k-py-2 k-rounded-md',
        props.className,
      )}
      style={props.style}
    >
      <div className="k-display-flex k-flex-row k-justify-content-center k-align-items-center">
        {props.children}
      </div>
    </div>
  );
};
