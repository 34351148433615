import { createContext, useContext } from 'react';

import { getInitialFilterContext } from '../helpers';
import { FilterContextType } from '../types';

export const FilterContext = createContext<FilterContextType>(getInitialFilterContext());

export function useFilterContext() {
  return useContext(FilterContext);
}
