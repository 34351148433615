import { isNode } from '@module/shared/helpers';
import { useEffect, useRef } from 'react';

export function useDetectClickOutside<T extends Node>(
  onClickOutside: () => void,
  delay?: number | undefined,
  ignoreSelectors?: string[],
) {
  const ref = useRef<T>(null);
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      // without delay
      if (
        !delay &&
        ref &&
        ref.current &&
        isNode(event.target) &&
        !ref.current.contains(event.target)
      ) {
        const element = event.target as Element;
        if (ignoreSelectors && ignoreSelectors.some((selector) => element.closest(selector))) {
          return;
        }
        onClickOutside();
      }

      // with delay
      if (
        delay &&
        ref &&
        ref.current &&
        isNode(event.target) &&
        !ref.current.contains(event.target)
      ) {
        setTimeout(() => {
          if (ref && ref.current) {
            const element = event.target as Element;
            if (ignoreSelectors && ignoreSelectors.some((selector) => element.closest(selector))) {
              return;
            }
            onClickOutside();
          }
        }, delay);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [delay, onClickOutside, ref, ignoreSelectors]);
  return ref;
}
