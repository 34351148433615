import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ClientBankingQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;

export type ClientBankingQuery = {
  __typename?: 'Query';
  client?: {
    __typename?: 'Client';
    client_banking?: {
      __typename?: 'ClientBanking';
      bank_name?: string | null;
      iban?: string | null;
      account_number?: string | null;
      bank_code?: string | null;
      external_identifier?: string | null;
      payout_note?: string | null;
    } | null;
  } | null;
};

export const ClientBankingDocument = gql`
  query ClientBanking($id: ID!) {
    client(id: $id) {
      client_banking {
        bank_name
        iban
        account_number
        bank_code
        external_identifier
        payout_note
      }
    }
  }
`;

export function useClientBankingQuery(
  options: Omit<Urql.UseQueryArgs<ClientBankingQueryVariables>, 'query'>,
) {
  return Urql.useQuery<ClientBankingQuery, ClientBankingQueryVariables>({
    query: ClientBankingDocument,
    ...options,
  });
}
