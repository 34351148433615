import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type UpdateCustomerPortalEventMutationVariables = Types.Exact<{
  hash: Types.Scalars['String'];
  input: Types.UpdateCustomerPortalEventInput;
}>;

export type UpdateCustomerPortalEventMutation = {
  __typename?: 'Mutation';
  updateCustomerPortalEvent?: { __typename?: 'CustomerPortalEvent'; hash: string } | null;
};

export const UpdateCustomerPortalEventDocument = gql`
  mutation UpdateCustomerPortalEvent($hash: String!, $input: UpdateCustomerPortalEventInput!) {
    updateCustomerPortalEvent(hash: $hash, input: $input) {
      hash
    }
  }
`;

export function useUpdateCustomerPortalEventMutation() {
  return Urql.useMutation<
    UpdateCustomerPortalEventMutation,
    UpdateCustomerPortalEventMutationVariables
  >(UpdateCustomerPortalEventDocument);
}
