import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
export type AuthStateFragment = {
  __typename?: 'AuthState';
  data: {
    __typename?: 'AuthData';
    access_token: string;
    refresh_token: string;
    expires_at: string;
    refresh_token_expires_at: string;
  };
};

export const AuthStateFragmentDoc = gql`
  fragment AuthState on AuthState {
    data {
      access_token
      refresh_token
      expires_at
      refresh_token_expires_at
    }
  }
`;
