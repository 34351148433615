import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import { CalculationItemFragmentDoc } from './taskCalculationItemFragment.generated';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type TaskCalculationsQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;

export type TaskCalculationsQuery = {
  __typename?: 'Query';
  taskCalculations?: Array<{
    __typename?: 'TaskCalculation';
    id: string;
    title?: string | null;
    task_id: string;
    sum_alias?: string | null;
    status_alias?: string | null;
    accepted_alias?: string | null;
    tax_deductible?: boolean | null;
    time_value_alias?: string | null;
    time_value_column_active?: boolean | null;
    net_calculation: boolean;
    hide_column_status: boolean;
    hide_column_accepted: boolean;
    hide_column_payout_state: boolean;
    active_catalog_id: string;
    categories?: Array<{
      __typename?: 'TaskCalculationCategory';
      id: string;
      title: string;
      sort_index: number;
    }> | null;
    items?: Array<{
      __typename?: 'TaskCalculationItem';
      id: string;
      title?: string | null;
      description?: string | null;
      accepted?: number | null;
      amount?: number | null;
      single_price?: number | null;
      sort_index?: number | null;
      time_value?: number | null;
      age?: number | null;
      correction_condition?: number | null;
      max_usage_years?: number | null;
      tax: number;
      own_contribution: boolean;
      state?: {
        __typename?: 'TaskCalculationItemState';
        id: string;
        title: string;
        color: string;
      } | null;
      payout_state?: {
        __typename?: 'TaskCalculationItemPayoutState';
        id: string;
        title: string;
        color: string;
      } | null;
      unit?: { __typename?: 'ServiceCatalogUnit'; id: string; title: string } | null;
      category?: {
        __typename?: 'TaskCalculationCategory';
        id: string;
        title: string;
        sort_index: number;
      } | null;
    }> | null;
    group_by?: Array<{
      __typename?: 'TaskCalculationGroupType';
      group_type_id: Types.CalculationGroupType;
      id: string;
      task_calculation_id: string;
    } | null> | null;
  }> | null;
};

export const TaskCalculationsDocument = gql`
  query TaskCalculations($id: ID!) {
    taskCalculations(task_id: $id) {
      id
      title
      task_id
      sum_alias
      status_alias
      accepted_alias
      tax_deductible
      time_value_alias
      time_value_column_active
      net_calculation
      hide_column_status
      hide_column_accepted
      hide_column_payout_state
      categories {
        id
        title
        sort_index
      }
      items {
        ...CalculationItem
      }
      group_by {
        group_type_id
        id
        task_calculation_id
      }
      active_catalog_id
    }
  }
  ${CalculationItemFragmentDoc}
`;

export function useTaskCalculationsQuery(
  options: Omit<Urql.UseQueryArgs<TaskCalculationsQueryVariables>, 'query'>,
) {
  return Urql.useQuery<TaskCalculationsQuery, TaskCalculationsQueryVariables>({
    query: TaskCalculationsDocument,
    ...options,
  });
}
