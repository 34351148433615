import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
export type QueryContactFieldsFragment = {
  __typename?: 'Contact';
  client_location_id: string;
  first_name?: string | null;
  name?: string | null;
  street?: string | null;
  street_no?: string | null;
  postcode?: string | null;
  city?: string | null;
  country_id?: string | null;
  fax_number?: string | null;
  phone?: string | null;
  phone2?: string | null;
  mobile?: string | null;
  mobile2?: string | null;
  email?: string | null;
  email2?: string | null;
  iban?: string | null;
  bic?: string | null;
  longitude?: number | null;
  latitude?: number | null;
  salutation_id?: string | null;
  function_id?: string | null;
  note?: string | null;
  client_location?: {
    __typename?: 'ClientLocation';
    id: string;
    client_id: string;
    different_policy_holder?: string | null;
    street?: string | null;
    street_no?: string | null;
    postcode?: string | null;
    city?: string | null;
    country: { __typename?: 'Country'; title: string };
  } | null;
  role?: { __typename?: 'ContactRole'; title: string } | null;
  salutation?: { __typename?: 'Salutation'; id: string; title: string } | null;
  function?: { __typename?: 'ContactFunction'; id: string; title: string } | null;
};

export type QueryContactsFieldsFragment = {
  __typename?: 'Contact';
  id: string;
  client_location_id: string;
  first_name?: string | null;
  name?: string | null;
  street?: string | null;
  street_no?: string | null;
  postcode?: string | null;
  city?: string | null;
  country_id?: string | null;
  fax_number?: string | null;
  phone?: string | null;
  phone2?: string | null;
  mobile?: string | null;
  mobile2?: string | null;
  email?: string | null;
  email2?: string | null;
  iban?: string | null;
  bic?: string | null;
  longitude?: number | null;
  latitude?: number | null;
  salutation_id?: string | null;
  function_id?: string | null;
  note?: string | null;
  country?: { __typename?: 'Country'; title: string } | null;
  client_location?: {
    __typename?: 'ClientLocation';
    street?: string | null;
    street_no?: string | null;
    postcode?: string | null;
    different_policy_holder?: string | null;
    city?: string | null;
    id: string;
    client_id: string;
    country: { __typename?: 'Country'; title: string };
  } | null;
  role?: { __typename?: 'ContactRole'; title: string } | null;
  salutation?: { __typename?: 'Salutation'; id: string; title: string } | null;
  function?: { __typename?: 'ContactFunction'; id: string; title: string } | null;
};

export const QueryContactFieldsFragmentDoc = gql`
  fragment QueryContactFields on Contact {
    client_location_id
    first_name
    name
    street
    street_no
    postcode
    city
    country_id
    fax_number
    phone
    phone2
    mobile
    mobile2
    email
    email2
    iban
    bic
    longitude
    latitude
    salutation_id
    function_id
    client_location {
      id
      client_id
      different_policy_holder
      street
      street_no
      postcode
      city
      country {
        title
      }
    }
    role {
      title
    }
    salutation {
      id
      title
    }
    function {
      id
      title
    }
    note
  }
`;
export const QueryContactsFieldsFragmentDoc = gql`
  fragment QueryContactsFields on Contact {
    id
    ...QueryContactFields
    country {
      title
    }
    client_location {
      street
      street_no
      postcode
      different_policy_holder
      city
      country {
        title
      }
    }
  }
  ${QueryContactFieldsFragmentDoc}
`;
