import { Badge } from '@module/common';
import { classNames } from '@progress/kendo-react-common';

import { ClassificationTagFragment } from '../graphql';

interface ClassificationTagBadgeProps {
  className?: string;
  classificationTag: ClassificationTagFragment;
}

export const ClassificationTagBadge = (props: ClassificationTagBadgeProps) => {
  const { className, classificationTag } = props;

  return (
    <Badge
      key={classificationTag.id}
      backgroundColor={classificationTag.color}
      color="white"
      className={classNames('k-text-nowrap', 'k-overflow-hidden', className)}
    >
      <div className="k-overflow-hidden" />
      {classificationTag.title}
    </Badge>
  );
};
