import {
  Ability,
  AbilityBuilder,
  AbilityClass,
  ForcedSubject,
  subject as subjectHelper,
} from '@casl/ability';
import { useCallback, useMemo } from 'react';

import { Permission } from '../../../types/graphql.generated';
import { GenericCan, GenericCanSharedProps } from '../components/GenericCan';
import { CurrentUserAbilityFragment } from '../graphql';
import { detectSubjectTypeByTypename as detectSubjectType } from '../helpers';
import { useMeOrDefault } from '../hooks';

interface QualificationSubject {
  me: CurrentUserAbilityFragment;
}

type QualificationSubjectArg = Partial<QualificationSubject>;

type Actions =
  | 'viewQualifications'
  | 'viewQualification'
  | 'createQualification'
  | 'updateQualification'
  | 'deleteQualification'
  | 'exportQualifications';

type Subjects = QualificationSubject | 'QualificationSubject';

type QualificationAbility = Ability<[Actions, Subjects]>;
const taskTemplateAbility = Ability as AbilityClass<QualificationAbility>;

export const useQualificationAbility = (): [
  QualificationAbility,
  (sub?: QualificationSubjectArg) => QualificationSubject & ForcedSubject<'QualificationSubject'>,
] => {
  const ability = useMemo(() => {
    const { can, build } = new AbilityBuilder(taskTemplateAbility);

    can('viewQualifications', 'QualificationSubject', {
      'me.globalPermissions': { $in: [Permission.QUALIFICATION_INDEX] },
    });
    can('viewQualification', 'QualificationSubject', {
      'me.globalPermissions': { $in: [Permission.QUALIFICATION_READ] },
    });
    can('createQualification', 'QualificationSubject', {
      'me.globalPermissions': { $in: [Permission.QUALIFICATION_CREATE] },
    });
    can('updateQualification', 'QualificationSubject', {
      'me.globalPermissions': { $in: [Permission.QUALIFICATION_UPDATE] },
    });
    can('deleteQualification', 'QualificationSubject', {
      'me.globalPermissions': { $in: [Permission.QUALIFICATION_DELETE] },
    });
    can('exportQualifications', 'QualificationSubject', {
      'me.globalPermissions': { $in: [Permission.QUALIFICATION_EXPORT] },
    });

    return build({ detectSubjectType });
  }, []);

  const me = useMeOrDefault();
  const subject = useCallback(
    (sub?: QualificationSubjectArg) => {
      return subjectHelper('QualificationSubject', { me, ...sub });
    },
    [me],
  );

  return [ability, subject];
};

type CanQualificationProps = GenericCanSharedProps<Actions>;

export const CanQualification = (props: CanQualificationProps) => {
  const [qualificationSubjectAbility, qualificationSubject] = useQualificationAbility();

  return (
    <GenericCan<Actions, Subjects, QualificationAbility>
      ability={qualificationSubjectAbility}
      subject={qualificationSubject()}
      {...props}
    />
  );
};
