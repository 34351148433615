import { useDropDownListDefaultItem } from '@module/common';
import {
  ColorPickerInput,
  DropDownListInput,
  FormGroupVertical,
  TextInput,
} from '@module/shared/forms';
import { Field, FieldRenderProps } from '@progress/kendo-react-form';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useSubformClassificationTagTypeQuery } from '../graphql';

export interface TypeSelectProps extends FieldRenderProps {
  value: string | null;
}

export const TypeSelect = (props: TypeSelectProps) => {
  const { value, onChange, ...rest } = props;
  const defaultItem = useDropDownListDefaultItem('value', 'label');
  const [{ data, fetching: isLoading }] = useSubformClassificationTagTypeQuery();

  const options = useMemo(() => {
    return data?.tagTypes
      ? data.tagTypes.map((type) => {
          return { value: type.id, label: type.title };
        })
      : [];
  }, [data]);

  const type = useMemo(() => options.find((option) => option.value === value), [options, value]);

  const handleChange = useCallback(
    (event: { value?: { label: string; value: string } }) => {
      onChange({
        ...event,
        value: event.value?.value ?? null,
      });
    },
    [onChange],
  );

  return (
    <DropDownListInput
      {...rest}
      data={options}
      dataItemKey="value"
      textField="label"
      defaultItem={defaultItem}
      value={type}
      onChange={handleChange}
      isLoading={isLoading}
    />
  );
};

export const SubformClassificationTagFormFields = () => {
  const { t } = useTranslation();

  return (
    <FormGroupVertical>
      <Field
        id="subform_classification_tag.title"
        name={'title'}
        label={t('settings.pages.classificationTags.labels.name')}
        component={TextInput}
      />
      <Field
        id="subform_classification_tag.color"
        name={'color'}
        label={t('settings.pages.classificationTags.labels.color')}
        size="large"
        component={ColorPickerInput}
      />
    </FormGroupVertical>
  );
};
