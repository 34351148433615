import './HistoryPopup.scss';

import { ROUTER_BASENAME } from '@env';
import { Button } from '@progress/kendo-react-buttons';
import { Popup } from '@progress/kendo-react-popup';
import { MutableRefObject, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useHistory } from './HistoryContext';

interface HistoryPopupProps {
  anchor: MutableRefObject<HTMLDivElement | null>;
  show: boolean;
}

export const HistoryPopup = ({ anchor, show }: HistoryPopupProps) => {
  const { history, deleteHistory } = useHistory();
  const { t } = useTranslation();

  const historyItems = useMemo(() => {
    return history
      .map((item) => {
        return (
          <div
            className="k-py-2 k-display-flex k-flex-row k-flex-direction-row k-align-items-center k-justify-content-between k-gap-6"
            key={item.key}
          >
            <a className="history-link" href={`${ROUTER_BASENAME.slice(0, -1)}${item.pathname}`}>
              {item.pathname === '/dashboard' ? t('common.navigation.items.dashboard') : item.title}
            </a>
            <a
              className="history-link"
              href={`${ROUTER_BASENAME.slice(0, -1)}${item.pathname}`}
              target="_blank"
              rel="noreferrer"
            >
              <span className="k-font-icon k-i-hyperlink-open-sm" />
            </a>
          </div>
        );
      })
      .reverse();
  }, [history, t]);

  return (
    <Popup anchor={anchor.current} popupClass="history-popup" show={show}>
      <div className="k-display-flex k-gap-8 k-justify-content-between k-px-3 k-py-1 k-border-left-0 k-border-top-0 k-border-right-0 k-border-bottom-1 k-border-solid k-border-light">
        <span className="k-display-flex k-align-items-center">
          {t('common.components.layout.historyPopup.lastVisitedPages')}
        </span>
        <Button
          onClick={deleteHistory}
          size="large"
          className="k-color-primary k-font-weight-semibold"
          fillMode="flat"
          iconClass="l-i-trash-2"
        >
          {t('common.components.layout.historyPopup.deleteHistory')}
        </Button>
      </div>
      <div className="k-px-3 k-py-1">
        {historyItems.map((item, index) => {
          if (index !== 0) return item;
        })}
      </div>
    </Popup>
  );
};
