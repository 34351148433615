import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ServiceCatalogUnitsQueryVariables = Types.Exact<{ [key: string]: never }>;

export type ServiceCatalogUnitsQuery = {
  __typename?: 'Query';
  service_catalog_units?: Array<{
    __typename?: 'ServiceCatalogUnit';
    id: string;
    title: string;
  }> | null;
};

export const ServiceCatalogUnitsDocument = gql`
  query ServiceCatalogUnits {
    service_catalog_units {
      id
      title
    }
  }
`;

export function useServiceCatalogUnitsQuery(
  options?: Omit<Urql.UseQueryArgs<ServiceCatalogUnitsQueryVariables>, 'query'>,
) {
  return Urql.useQuery<ServiceCatalogUnitsQuery, ServiceCatalogUnitsQueryVariables>({
    query: ServiceCatalogUnitsDocument,
    ...options,
  });
}
