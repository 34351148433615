import { Button } from '@progress/kendo-react-buttons';
import { useTranslation } from 'react-i18next';

interface CountdownProps {
  remainingMs: number;
}

export const Countdown = ({ remainingMs = 60 }: CountdownProps) => {
  const { t } = useTranslation();

  return (
    <div>
      <Button themeColor={'error'} size={'large'} style={{ fontVariantNumeric: 'tabular-nums' }}>
        {t('common.logoutTimer.message', {
          time: Math.floor(remainingMs / 1000)
            .toString()
            .padStart(2, '0'),
        })}
      </Button>
    </div>
  );
};
