export default {
  labels: {
    editor: 'Editor',
    assignment: 'Assignment',
    id: 'ID',
    title: 'Title',
    visibility: 'Visibility',
    edit: 'Edit',
    preview: 'Preview',
    upload: 'Upload',
    new: 'New',
    download: 'Download',
    selectFieldDescription: 'Change or add elements to the PDF:',
    selectFieldType: 'Select the field type',
    selectField: 'Select the field you want to add',
    fieldPreferences: 'Field properties',
    add: 'Add',
    signatureField: 'With a signature field you can create signature fields in the PDF',
    textField: 'A text field allows you to create form fields in the PDFtext',
    removeAssociation: 'Remove assignment',
  },
  dialogs: {
    preview: {
      title: 'Preview',
    },
    edit: {
      title: 'Edit Pdf Form',
      note: 'Here you can edit the assignment of PDF forms and their titles',
    },
    editAnnotations: {
      title: 'Edit annotations',
    },
    new: {
      title: 'New',
      create: 'Create',
    },
    update: {
      title: 'Update',
    },
    upload: {
      title: 'Upload PDF forms',
      note: 'You can upload PDF forms here',
      cancel: 'Cancel',
      upload: 'Upload',
    },
    designer: {
      title: 'Designer',
    },
  },
  pages: {
    overview: {
      title: 'PDF forms',
      description: 'Here you will find a list of PDF forms',
      grid: {
        columns: {
          actions: 'Actions',
        },
      },
    },
  },
  components: {
    measurements: {
      calibrateScale: 'Calibrate scale',
      polygonAreaMeasurement: 'Area measurement',
      setScale: 'Set scale',
      newScale: 'Add new scale',
      displaySecondaryUnit: 'Show secondary unit',
      secondaryUnit: 'Secondary unit',
      scaleName: 'Name of the scale',
      scale: 'Scale',
      editAddScale: 'Edit/add scale',
      duplicateScaleError: 'Scale with this name already exists',
      measurementScale: 'Scale',
      calibrationScaleSuccess: 'Scale successfully added',
      calibrationScaleSubtitle: 'Start the measurement by selecting one of the measuring tools.',
      Snapping: 'Engage',
    },
  },
};
