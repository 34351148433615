import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import { TaskProposalFragmentDoc } from './taskProposalFragment.generated';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CreateTaskProposalMutationVariables = Types.Exact<{
  task_id: Types.Scalars['ID'];
  input: Types.CreateTaskProposalInput;
}>;

export type CreateTaskProposalMutation = {
  __typename?: 'Mutation';
  createTaskProposal: {
    __typename?: 'TaskProposal';
    id: string;
    task_id: string;
    external_proposal_id?: string | null;
    external_proposal_number?: string | null;
    external_proposal_url?: string | null;
    external_id?: number | null;
    date: string;
    state_id: Types.TaskProposalStateId;
    note?: string | null;
    reminder?: boolean | null;
    external_number: string;
    identifier: string;
    address: string;
    sum_gross: number;
    sum_net: number;
    sum_tax: number;
    url_preview: string;
    permissions: Array<Types.Permission>;
    task: { __typename?: 'Task'; identifier?: string | null };
    state: { __typename?: 'TaskProposalState'; id: string; title: string; color: string };
    items: Array<{
      __typename?: 'TaskProposalItem';
      id: string;
      position: string;
      title: string;
      unit?: string | null;
      unit_price: number;
      amount: number;
      sum_net: number;
      sum_gross: number;
      tax: number;
    }>;
    file?: { __typename?: 'FileItem'; id: string; file_name: string; url: string } | null;
  };
};

export const CreateTaskProposalDocument = gql`
  mutation CreateTaskProposal($task_id: ID!, $input: CreateTaskProposalInput!) {
    createTaskProposal(task_id: $task_id, input: $input) {
      ...TaskProposal
    }
  }
  ${TaskProposalFragmentDoc}
`;

export function useCreateTaskProposalMutation() {
  return Urql.useMutation<CreateTaskProposalMutation, CreateTaskProposalMutationVariables>(
    CreateTaskProposalDocument,
  );
}
