import areaAssignments from './areaAssignments';
import clients from './clients';
import dashboard from './dashboard';
import demoData from './demoData';
import payouts from './payouts';
import settings from './settings';
import tasks from './tasks';
import templates from './templates';

export const localeOverrideDSS = {
  areaAssignments,
  clients,
  tasks,
  templates,
  dashboard,
  settings,
  payouts,
  demoData,
};
