import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import { ClientLocationTypeFragmentDoc } from './clientLocationTypeFragment.generated';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ClientLocationTypesQueryVariables = Types.Exact<{ [key: string]: never }>;

export type ClientLocationTypesQuery = {
  __typename?: 'Query';
  clientLocationTypes: Array<{
    __typename?: 'ClientLocationType';
    id: string;
    title: string;
    type_id: Types.ClientLocationTypeId;
  }>;
};

export const ClientLocationTypesDocument = gql`
  query ClientLocationTypes {
    clientLocationTypes {
      ...ClientLocationType
    }
  }
  ${ClientLocationTypeFragmentDoc}
`;

export function useClientLocationTypesQuery(
  options?: Omit<Urql.UseQueryArgs<ClientLocationTypesQueryVariables>, 'query'>,
) {
  return Urql.useQuery<ClientLocationTypesQuery, ClientLocationTypesQueryVariables>({
    query: ClientLocationTypesDocument,
    ...options,
  });
}
