import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type UploadReportLogoMutationVariables = Types.Exact<{
  file?: Types.InputMaybe<Types.Scalars['Upload']>;
}>;

export type UploadReportLogoMutation = {
  __typename?: 'Mutation';
  uploadReportLogo: {
    __typename?: 'AppSettings';
    login_logo?: { __typename?: 'FileItem'; file_name: string; url: string; id: string } | null;
  };
};

export const UploadReportLogoDocument = gql`
  mutation UploadReportLogo($file: Upload) {
    uploadReportLogo(file: $file) {
      login_logo {
        file_name
        url
        id
      }
    }
  }
`;

export function useUploadReportLogoMutation() {
  return Urql.useMutation<UploadReportLogoMutation, UploadReportLogoMutationVariables | void>(
    UploadReportLogoDocument,
  );
}
