import './DropZoneOverlay.scss';

import { HTMLAttributes, ReactNode } from 'react';

import { useCheckForFileDrag } from '../../hooks';

interface DropZoneOverlayProps extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode;
}

export const DropZoneOverlay = (props: DropZoneOverlayProps) => {
  const { children } = props;
  const dragging = useCheckForFileDrag();

  return (
    <div className="drop-zone-wrapper" style={{ overflow: 'hidden', borderRadius: '6px' }}>
      {children}
      {dragging && <div className="drop-zone-overlay" />}
    </div>
  );
};
