import {
  DropDownButton,
  DropDownButtonHandle,
  DropDownButtonItemClickEvent,
  DropDownButtonProps,
} from '@progress/kendo-react-buttons';
import { Ref } from 'react';
import { useTranslation } from 'react-i18next';

export interface ActionItem<TActionType = string> {
  actionType: TActionType;
  actionName: string;
  icon?: string;
  handler?: () => void;
  iconClass?: string;
  render?: (props: { item: ActionItem<TActionType> }) => JSX.Element;
  hide?: boolean;
  disabled?: boolean;
}

interface GridActionButtonProps<TActionType> extends DropDownButtonProps {
  actions: ActionItem<TActionType>[];
  items?: never;
  textField?: never;
  iconClass?: never;
  onItemClick?: never;
}

export const GridActionsButton = <TActionType extends string>(
  props: GridActionButtonProps<TActionType> & { buttonRef?: Ref<DropDownButtonHandle> },
) => {
  const { actions, buttonRef, ...rest } = props;
  const { t } = useTranslation();

  const actionItems = actions
    .map((action) => ({
      actionType: action.actionType,
      actionName: action.actionName,
      icon: action.icon,
      iconClass: action.iconClass,
      render: action.render,
      hide: action.hide,
      disabled: action.disabled,
    }))
    .filter((action) => !action.hide);

  const getAction = (actionType: TActionType) => {
    return actions.find((action) => action.actionType === actionType)?.handler;
  };

  const handleActionsDropdown = (e: DropDownButtonItemClickEvent) => {
    const action = getAction(e.item.actionType);
    if (action) action();
  };

  return (
    <DropDownButton
      {...rest}
      ref={buttonRef}
      items={actionItems}
      disabled={actionItems.length === 0 || props.disabled}
      iconClass="l-i-more-vertical"
      ariaLabel={t('common.labels.actions')}
      onItemClick={handleActionsDropdown}
      textField="actionName"
    />
  );
};
