import { Button } from '@progress/kendo-react-buttons';
import { useTranslation } from 'react-i18next';

import { BaseButton, BaseButtonProps } from './BaseButton';

export interface RefreshButtonProps extends Omit<BaseButtonProps, 'iconClass'> {
  onlyIcon?: boolean;
}

export const RefreshButton = (props: RefreshButtonProps) => {
  const { t } = useTranslation();
  if (props.onlyIcon)
    return (
      <Button
        {...props}
        iconClass="l-i-refresh-cw"
        size="large"
        aria-label={t('common.labels.refresh')}
      />
    );
  return <BaseButton {...props} iconClass="l-i-refresh-cw" label={t('common.labels.refresh')} />;
};
