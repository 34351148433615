import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type DeleteCustomerPortalEventDocumentMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;

export type DeleteCustomerPortalEventDocumentMutation = {
  __typename?: 'Mutation';
  deleteCustomerPortalEventDocument: { __typename?: 'CustomerPortalEventDocument'; id: string };
};

export const DeleteCustomerPortalEventDocumentDocument = gql`
  mutation DeleteCustomerPortalEventDocument($id: ID!) {
    deleteCustomerPortalEventDocument(id: $id) {
      id
    }
  }
`;

export function useDeleteCustomerPortalEventDocumentMutation() {
  return Urql.useMutation<
    DeleteCustomerPortalEventDocumentMutation,
    DeleteCustomerPortalEventDocumentMutationVariables
  >(DeleteCustomerPortalEventDocumentDocument);
}
