import { useTranslation } from 'react-i18next';

import { BaseButton, BaseButtonProps } from './BaseButton';

export type CloseButtonProps = Omit<BaseButtonProps, 'iconClass'>;
export const CloseButton = (props: CloseButtonProps) => {
  const { t } = useTranslation();
  const { label = t('common.labels.close') } = props;
  return <BaseButton {...props} label={label} iconClass="l-i-x-circle" />;
};
