import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type MarkAllNotificationsAsReadMutationVariables = Types.Exact<{ [key: string]: never }>;

export type MarkAllNotificationsAsReadMutation = {
  __typename?: 'Mutation';
  markAllNotificationAsRead?: boolean | null;
};

export const MarkAllNotificationsAsReadDocument = gql`
  mutation MarkAllNotificationsAsRead {
    markAllNotificationAsRead
  }
`;

export function useMarkAllNotificationsAsReadMutation() {
  return Urql.useMutation<
    MarkAllNotificationsAsReadMutation,
    MarkAllNotificationsAsReadMutationVariables | void
  >(MarkAllNotificationsAsReadDocument);
}
