import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type UploadClientDataMutationVariables = Types.Exact<{
  file: Types.Scalars['Upload'];
}>;

export type UploadClientDataMutation = {
  __typename?: 'Mutation';
  uploadClientData: {
    __typename?: 'ResponseMessage';
    success?: boolean | null;
    message?: string | null;
  };
};

export const UploadClientDataDocument = gql`
  mutation UploadClientData($file: Upload!) {
    uploadClientData(file: $file) {
      success
      message
    }
  }
`;

export function useUploadClientDataMutation() {
  return Urql.useMutation<UploadClientDataMutation, UploadClientDataMutationVariables>(
    UploadClientDataDocument,
  );
}
