import { loadJsonFromLocalStorage, saveJsonToLocalStorage } from '@module/shared/helpers';
import { without } from 'lodash';
import { useCallback, useState } from 'react';

import { NavigationBarState, NavigationData } from './types';

const initialNavigationBarState: NavigationBarState = {
  collapsedSections: [],
};

export function useNavigationBarState(data: NavigationData) {
  const [navigationBarState, setNavigationBarState] = useState(
    loadJsonFromLocalStorage<NavigationBarState>(data.id) ?? initialNavigationBarState,
  );

  const isSectionCollapsed = useCallback(
    (sectionId: string) => {
      return navigationBarState.collapsedSections.includes(sectionId);
    },
    [navigationBarState.collapsedSections],
  );

  const toggleSectionCollapse = useCallback(
    (sectionId: string) => {
      setNavigationBarState((currentNavigationBarState) => {
        const collapsedSections = currentNavigationBarState.collapsedSections.includes(sectionId)
          ? without(currentNavigationBarState.collapsedSections, sectionId)
          : [...currentNavigationBarState.collapsedSections, sectionId];

        const navigationBarStateNew = { collapsedSections };
        saveJsonToLocalStorage(data.id, navigationBarStateNew);

        return navigationBarStateNew;
      });
    },
    [data.id],
  );

  return { isSectionCollapsed, toggleSectionCollapse };
}
