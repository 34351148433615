import {
  Ability,
  AbilityBuilder,
  AbilityClass,
  ForcedSubject,
  subject as subjectHelper,
} from '@casl/ability';
import { useCallback, useMemo } from 'react';

import { Permission } from '../../../types/graphql.generated';
import { GenericCan, GenericCanSharedProps } from '../components/GenericCan';
import { CurrentUserAbilityFragment, TaskTemplateAbilityFragment } from '../graphql';
import { detectSubjectTypeByTypename as detectSubjectType } from '../helpers';
import { useMeOrDefault } from '../hooks';

interface TaskTemplateSubject {
  me: CurrentUserAbilityFragment;
}

type TaskTemplateSubjectArg = Partial<TaskTemplateSubject>;

type Actions =
  | 'viewTaskTemplates'
  | 'viewTaskTemplate'
  | 'createTaskTemplate'
  | 'updateTaskTemplate'
  | 'deleteTaskTemplate'
  | 'designTaskTemplate'
  | 'duplicateTaskTemplate'
  | 'assignTaskTemplate';
type Subjects = TaskTemplateSubject | 'TaskTemplateSubject';

type TaskTemplateAbility = Ability<[Actions, Subjects]>;
const taskTemplateAbility = Ability as AbilityClass<TaskTemplateAbility>;

export const useTaskTemplateAbility = (): [
  TaskTemplateAbility,
  (sub?: TaskTemplateSubjectArg) => TaskTemplateSubject & ForcedSubject<'TaskTemplateSubject'>,
] => {
  const ability = useMemo(() => {
    const { can, build } = new AbilityBuilder(taskTemplateAbility);

    // global task actions

    can('viewTaskTemplates', 'TaskTemplateSubject', {
      'me.globalPermissions': { $in: [Permission.TASK_TEMPLATE_INDEX] },
    });
    can('viewTaskTemplate', 'TaskTemplateSubject', {
      'me.globalPermissions': { $in: [Permission.TASK_TEMPLATE_READ] },
    });
    can('createTaskTemplate', 'TaskTemplateSubject', {
      'me.globalPermissions': { $in: [Permission.TASK_TEMPLATE_CREATE] },
    });
    can('updateTaskTemplate', 'TaskTemplateSubject', {
      'me.globalPermissions': { $in: [Permission.TASK_TEMPLATE_UPDATE] },
    });
    can('deleteTaskTemplate', 'TaskTemplateSubject', {
      'me.globalPermissions': { $in: [Permission.TASK_TEMPLATE_DELETE] },
    });
    can('designTaskTemplate', 'TaskTemplateSubject', {
      'me.globalPermissions': { $in: [Permission.TASK_TEMPLATE_DESIGNER] },
    });
    can('duplicateTaskTemplate', 'TaskTemplateSubject', {
      'me.globalPermissions': { $in: [Permission.TASK_TEMPLATE_DUPLICATE] },
    });
    can('assignTaskTemplate', 'TaskTemplateSubject', {
      'me.globalPermissions': { $in: [Permission.TASK_TEMPLATE_ASSIGN] },
    });

    return build({ detectSubjectType });
  }, []);

  const me = useMeOrDefault();
  const subject = useCallback(
    (sub?: TaskTemplateSubjectArg) => {
      return subjectHelper('TaskTemplateSubject', { me, ...sub });
    },
    [me],
  );

  return [ability, subject];
};

interface CanTaskTemplateProps extends GenericCanSharedProps<Actions> {
  task?: TaskTemplateAbilityFragment;
}

export const CanTaskTemplate = (props: CanTaskTemplateProps) => {
  const [taskTemplateAbility, taskTemplateSubject] = useTaskTemplateAbility();

  return (
    <GenericCan<Actions, Subjects, TaskTemplateAbility>
      ability={taskTemplateAbility}
      subject={taskTemplateSubject()}
      {...props}
    />
  );
};
