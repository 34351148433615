import { isString, noop } from 'lodash';

const IS_MAXIMIZED_LOCALSTORE_KEY = 'isDialogMaximized';

interface MaximazedLocalStore {
  [key: string]: boolean;
}

export const getStoredValue = (storeStateUnderName: string): boolean => {
  const storedValues = getStoredMaximized();
  return storedValues?.[storeStateUnderName] || false;
};

export const storeValue = (storeStateUnderName: string, maximizedValue: boolean) => {
  const storedValues = getStoredMaximized() || {};

  if (storedValues) {
    storedValues[storeStateUnderName] = maximizedValue;
    localStorage.setItem(IS_MAXIMIZED_LOCALSTORE_KEY, JSON.stringify(storedValues));
  }
};

const getStoredMaximized = () => {
  const storedJson = localStorage.getItem(IS_MAXIMIZED_LOCALSTORE_KEY);
  if (!isString(storedJson)) {
    return null;
  }

  let maximizedValue: MaximazedLocalStore = {};
  try {
    maximizedValue = JSON.parse(storedJson);
  } catch (error) {
    noop();
  }
  return maximizedValue;
};
