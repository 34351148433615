import { type Instance } from 'pspdfkit';
import { useCallback, useEffect, useMemo } from 'react';

interface UsePsPdfKitScrollProps {
  onScroll?: (e: Event) => void;
}

export const usePsPdfKitScroll = (instance: Instance | null, props: UsePsPdfKitScrollProps) => {
  // capture ps pdf kit scroll event
  const handleScroll = useCallback(
    (e: Event) => {
      props.onScroll?.(e);
    },
    [props],
  );

  useEffect(() => {
    instance?.contentDocument
      .querySelector('.PSPDFKit-Scroll')
      ?.addEventListener('scroll', handleScroll);
    return () =>
      instance?.contentDocument
        .querySelector('.PSPDFKit-Scroll')
        ?.removeEventListener('scroll', handleScroll);
  }, [handleScroll, instance?.contentDocument]);

  const setScrollTop = useCallback(
    (scrollTop: number) => {
      const element = instance?.contentDocument.querySelector('.PSPDFKit-Scroll');
      if (element) element.scrollTop = scrollTop;
    },
    [instance?.contentDocument],
  );

  return useMemo(() => {
    return { setScrollTop };
  }, [setScrollTop]);
};
