import {
  DatePicker,
  DatePickerChangeEvent,
  DatePickerProps,
} from '@progress/kendo-react-dateinputs';
import { FieldWrapper } from '@progress/kendo-react-form';
import { Popup, PopupProps } from '@progress/kendo-react-popup';
import { parseISO } from 'date-fns';
import { useCallback, useId } from 'react';

import { Label } from '../components/Label';
import { FieldError } from './FieldError';
import { FieldHint } from './FieldHint';
import { CommonFieldRenderProps } from './types';

const DatePickerPopup = (props: PopupProps) => {
  return <Popup {...props} collision={{ horizontal: 'fit', vertical: 'flip' }} />;
};

type DatePickerInputProps = CommonFieldRenderProps &
  Pick<DatePickerProps, 'size' | 'id' | 'required' | 'disabled' | 'onBlur' | 'onFocus'> & {
    value: string | null;
    minDate: Date | undefined;
    maxDate: Date | undefined;
    onChange: (event: { target?: unknown; value?: string | null | undefined }) => void;
  };

export const DatePickerInput = (props: DatePickerInputProps) => {
  const defaultId = useId();
  const {
    size = 'large',
    value,
    id = defaultId,
    label,
    valid,
    required,
    disabled,
    name,
    tooltip,
    onChange,
    tooltipPosition,
    minDate,
    maxDate,
  } = props;
  const valueAsDate = value ? parseISO(value) : undefined;

  const handleChange = useCallback(
    (event: DatePickerChangeEvent) => {
      onChange?.({
        ...event,
        value: event.value ? event.value.toISOString() : null,
      });
    },
    [onChange],
  );

  return (
    <FieldWrapper>
      {label && (
        <Label
          tooltip={tooltip}
          tooltipPosition={tooltipPosition}
          label={label}
          name={name}
          editorId={id}
          editorValid={valid}
        >
          {label}
        </Label>
      )}

      <DatePicker
        size={size}
        id={id}
        min={minDate}
        max={maxDate}
        value={valueAsDate}
        required={required}
        disabled={disabled}
        popup={DatePickerPopup}
        onChange={handleChange}
      />

      <div className="HintAndError">
        <FieldHint {...props} />
        <FieldError {...props} />
      </div>
    </FieldWrapper>
  );
};
