import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { usePrevious } from './usePrevious';

export function useDetectNavigation(
  callback: (previousPathname: string, currentPathname: string) => void,
) {
  const { pathname: currentPathname } = useLocation();
  const previousPathname = usePrevious(currentPathname);

  useEffect(() => {
    if (currentPathname !== previousPathname) {
      callback(previousPathname, currentPathname);
    }
  }, [callback, currentPathname, previousPathname]);
}
