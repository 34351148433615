import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import { FileClassificationTagFragmentDoc } from './fileClassificationTagFragment.generated';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type UpdateTaskFileTagsMutationVariables = Types.Exact<{
  file_id: Types.Scalars['ID'];
  file_classification_tag_ids: Array<Types.Scalars['ID']> | Types.Scalars['ID'];
}>;

export type UpdateTaskFileTagsMutation = {
  __typename?: 'Mutation';
  updateTaskFileTags: {
    __typename?: 'FileClassificationTagList';
    id: string;
    data: Array<{
      __typename?: 'FileClassificationTag';
      id: string;
      title: string;
      color: string;
      alias: string;
      type_id: Types.FileClassificationTagTypeId;
    }>;
  };
};

export const UpdateTaskFileTagsDocument = gql`
  mutation UpdateTaskFileTags($file_id: ID!, $file_classification_tag_ids: [ID!]!) {
    updateTaskFileTags(
      file_id: $file_id
      file_classification_tag_ids: $file_classification_tag_ids
    ) {
      id
      data {
        ...FileClassificationTag
      }
    }
  }
  ${FileClassificationTagFragmentDoc}
`;

export function useUpdateTaskFileTagsMutation() {
  return Urql.useMutation<UpdateTaskFileTagsMutation, UpdateTaskFileTagsMutationVariables>(
    UpdateTaskFileTagsDocument,
  );
}
