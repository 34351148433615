import './SelectTagMenu.scss';

import {
  CancelButton,
  CreateButton,
  Dialog,
  DialogContent,
  DialogHeader,
  DialogHeaderBar,
} from '@module/layout';
import { DialogProps, useDialogs } from '@module/shared/dialogs';
import { IconInput } from '@module/shared/forms';
import { filterBy } from '@progress/kendo-data-query';
import { Button } from '@progress/kendo-react-buttons';
import { DialogActionsBar } from '@progress/kendo-react-dialogs';
import { InputChangeEvent } from '@progress/kendo-react-inputs';
import { ListBox, ListBoxItemClickEvent } from '@progress/kendo-react-listbox';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TaskTagFragment } from '../../graphql';
import { Loader } from '../Loader';

const SELECTED_FIELD = 'selected';

interface SelectTagDialogProps {
  onSelect: (tag: TaskTagFragment) => void;
  tags: Array<TaskTagFragment>;
}

export const SelectTagDialog = (props: SelectTagDialogProps & DialogProps) => {
  const { onSelect, dialogId } = props;

  const { t } = useTranslation();
  const { hideDialog } = useDialogs();
  const [tags, setTags] = useState(props.tags.map((tag) => ({ ...tag, selected: false })));
  const [filter, setFilter] = useState<string>('');

  const selectedTag = useMemo(() => {
    return tags.find((tag) => tag[SELECTED_FIELD]);
  }, [tags]);

  const handleFilter = (e: InputChangeEvent) => {
    setFilter(e.value);
  };

  const handleClose = () => {
    hideDialog(dialogId);
  };

  const addTag = () => {
    if (!selectedTag) return;
    onSelect(selectedTag);
    hideDialog(dialogId);
  };

  const handleItemClick = (e: ListBoxItemClickEvent) => {
    // only one item should be selected at a time
    setTags((prev) =>
      prev.map((tag) => {
        // toggle clicked item
        if (tag.key === e.dataItem.key) {
          return { ...tag, [SELECTED_FIELD]: !tag[SELECTED_FIELD] };
        }
        // deselect other items
        return { ...tag, [SELECTED_FIELD]: false };
      }),
    );
  };

  const filteredTags = useMemo(() => {
    if (!filter) return tags;
    return filterBy(tags, {
      logic: 'and',
      filters: [{ field: 'title', operator: 'startswith', value: filter, ignoreCase: true }],
    });
  }, [tags, filter]);

  return (
    <Dialog width="small" onClose={handleClose}>
      <DialogHeaderBar>
        <DialogHeader
          title={t('common.components.templateTagInput.selectTagDialog.title')}
          description={t('common.components.templateTagInput.selectTagDialog.description')}
        />
      </DialogHeaderBar>
      <DialogContent>
        <IconInput
          iconClass="l-i-search"
          placeholder={t('common.labels.search')}
          className="k-input-lg k-flex-grow"
          onChange={handleFilter}
        />
        <div className="SelectTagListBox k-mt-4">
          <ListBox
            style={{
              height: 400,
              width: '100%',
            }}
            data={filteredTags}
            textField="title"
            selectedField={SELECTED_FIELD}
            onItemClick={handleItemClick}
          />
        </div>
      </DialogContent>
      <DialogActionsBar>
        <CancelButton type="button" onClick={handleClose} />
        <CreateButton
          type="button"
          label={t('common.components.templateTagInput.addPlaceholder')}
          onClick={addTag}
          disabled={!selectedTag}
        />
      </DialogActionsBar>
    </Dialog>
  );
};

interface SelectTagMenuProps {
  onSelect: (tags: TaskTagFragment) => void;
  tags: TaskTagFragment[];
  fetching: boolean;
}

export const SelectTagMenu = (props: SelectTagMenuProps) => {
  const { onSelect, tags, fetching } = props;
  const { t } = useTranslation();
  const { showDialog } = useDialogs();

  const openSelectTag = () => {
    showDialog({ onSelect, tags }, SelectTagDialog);
  };

  return (
    <div>
      {fetching && <Loader />}
      <Button
        iconClass="l-i-plus"
        fillMode="flat"
        themeColor="primary"
        type="button"
        onClick={openSelectTag}
        disabled={fetching}
      >
        {t(t('common.components.templateTagInput.addPlaceholder'))}
      </Button>
    </div>
  );
};
