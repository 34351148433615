import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type EmailAttachmentFragment = {
  __typename?: 'EmailAttachment';
  id: string;
  file: {
    __typename?: 'FileItem';
    id: string;
    name: string;
    size: number;
    mime_type?: string | null;
    url_download: string;
  };
};

export type TaskEmailsQueryVariables = Types.Exact<{
  taskId: Types.Scalars['ID'];
}>;

export type TaskEmailsQuery = {
  __typename?: 'Query';
  emails: {
    __typename?: 'EmailPaginator';
    data: Array<{
      __typename?: 'Email';
      id?: string | null;
      subject: string;
      body: string;
      body_preview: string;
      state_id: Types.EmailSendState;
      send_date?: string | null;
      created_from_client?: {
        __typename?: 'Client';
        id: string;
        name: string;
        email?: string | null;
      } | null;
      attachments?: Array<{
        __typename?: 'EmailAttachment';
        id: string;
        file: {
          __typename?: 'FileItem';
          id: string;
          name: string;
          size: number;
          mime_type?: string | null;
          url_download: string;
        };
      }> | null;
      contacts?: Array<{
        __typename?: 'EmailContact';
        id: string;
        address: string;
        name?: string | null;
        type: Types.EmailContactType;
      }> | null;
    }>;
  };
};

export const EmailAttachmentFragmentDoc = gql`
  fragment EmailAttachment on EmailAttachment {
    id
    file {
      id
      name
      size
      mime_type
      url_download
    }
  }
`;
export const TaskEmailsDocument = gql`
  query TaskEmails($taskId: ID!) {
    emails(task_id: $taskId) {
      data {
        id
        subject
        body
        body_preview
        state_id
        send_date
        created_from_client {
          id
          name
          email
        }
        attachments {
          ...EmailAttachment
        }
        contacts {
          id
          address
          name
          type
        }
      }
    }
  }
  ${EmailAttachmentFragmentDoc}
`;

export function useTaskEmailsQuery(
  options: Omit<Urql.UseQueryArgs<TaskEmailsQueryVariables>, 'query'>,
) {
  return Urql.useQuery<TaskEmailsQuery, TaskEmailsQueryVariables>({
    query: TaskEmailsDocument,
    ...options,
  });
}
