import noop from 'lodash/noop';
import { createContext, useContext } from 'react';

import { AuthState } from '../graphql';

interface AuthContextType {
  authState: AuthState | null;
  clearAuthState: () => void;
  getAuthState: () => AuthState | null;
  saveAuthState: (authState: AuthState | null) => void;
  authStatePrevious: AuthState | null;
  clearAuthStatePrevious: () => void;
  getAuthStatePrevious: () => AuthState | null;
  saveAuthStatePrevious: (authState: AuthState | null) => void;
}

const initialAuthContext: AuthContextType = {
  authState: null,
  getAuthState: () => null,
  saveAuthState: noop,
  clearAuthState: noop,
  authStatePrevious: null,
  getAuthStatePrevious: () => null,
  saveAuthStatePrevious: noop,
  clearAuthStatePrevious: noop,
};

export const AuthContext = createContext<AuthContextType>(initialAuthContext);

export const useAuthContext = () => {
  return useContext(AuthContext);
};
