import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CreateTaskContactMutationVariables = Types.Exact<{
  task_id: Types.Scalars['ID'];
  input: Types.TaskContactCreateInput;
}>;

export type CreateTaskContactMutation = {
  __typename?: 'Mutation';
  createTaskContact: { __typename?: 'Contact'; id: string };
};

export const CreateTaskContactDocument = gql`
  mutation CreateTaskContact($task_id: ID!, $input: TaskContactCreateInput!) {
    createTaskContact(task_id: $task_id, input: $input) {
      id
    }
  }
`;

export function useCreateTaskContactMutation() {
  return Urql.useMutation<CreateTaskContactMutation, CreateTaskContactMutationVariables>(
    CreateTaskContactDocument,
  );
}
