import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type TaskSubTasksQueryVariables = Types.Exact<{
  taskId?: Types.InputMaybe<Types.Scalars['ID']>;
  filter: Types.TaskSubTaskFilter;
  sort?: Types.InputMaybe<Types.TaskSubTaskSort>;
  pagination: Types.PaginationInput;
}>;

export type TaskSubTasksQuery = {
  __typename?: 'Query';
  taskSubTasks: {
    __typename?: 'TaskSubTaskPaginator';
    data: Array<{
      __typename?: 'TaskSubTask';
      id: string;
      title: string;
      description?: string | null;
      state_id: Types.TaskSubTaskStateEnum;
      type_id: Types.TaskSubTaskType;
      identifier?: Types.TaskSubTaskWorkflowIdentifier | null;
      task_id: string;
      created_at?: string | null;
      completed_date?: string | null;
      due_date?: string | null;
      created_client_id: string;
      assigned_client_id: string;
      permissions: Array<Types.Permission>;
      state: { __typename?: 'TaskSubTaskState'; id: string; title: string; color: string };
      task: {
        __typename?: 'Task';
        id: string;
        allowed_clients_for_sub_task_assignment: {
          __typename?: 'TaskSubTaskAllowedClientsForAssignment';
          clients: Array<{
            __typename?: 'Client';
            id: string;
            name: string;
            job_title?: string | null;
            client_type?: {
              __typename?: 'ClientType';
              color: string;
              title: string;
              id: string;
            } | null;
          }>;
        };
      };
      controller?: { __typename?: 'Client'; name: string } | null;
      client?: {
        __typename?: 'Client';
        id: string;
        name: string;
        email?: string | null;
        job_title?: string | null;
        client_type?: {
          __typename?: 'ClientType';
          color: string;
          title: string;
          id: string;
        } | null;
      } | null;
      file?: {
        __typename?: 'FileItem';
        id: string;
        original_file_name: string;
        mime_type?: string | null;
        url: string;
      } | null;
    }>;
    paginatorInfo: { __typename?: 'PaginatorInfo'; total: number };
  };
};

export const TaskSubTasksDocument = gql`
  query TaskSubTasks(
    $taskId: ID
    $filter: TaskSubTaskFilter!
    $sort: TaskSubTaskSort
    $pagination: PaginationInput!
  ) {
    taskSubTasks(task_id: $taskId, filter: $filter, sort: $sort, pagination: $pagination) {
      data {
        id
        title
        description
        state_id
        type_id
        state {
          id
          title
          color
        }
        identifier
        task_id
        task {
          id
          allowed_clients_for_sub_task_assignment {
            clients {
              id
              name
              job_title
              client_type {
                color
                title
                id
              }
            }
          }
        }
        created_at
        completed_date
        due_date
        created_client_id
        assigned_client_id
        controller {
          name
        }
        client {
          id
          name
          email
          job_title
          client_type {
            color
            title
            id
          }
        }
        file {
          id
          original_file_name
          mime_type
          url
        }
        permissions
      }
      paginatorInfo {
        total
      }
    }
  }
`;

export function useTaskSubTasksQuery(
  options: Omit<Urql.UseQueryArgs<TaskSubTasksQueryVariables>, 'query'>,
) {
  return Urql.useQuery<TaskSubTasksQuery, TaskSubTasksQueryVariables>({
    query: TaskSubTasksDocument,
    ...options,
  });
}
