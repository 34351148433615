import { useContext, useMemo } from 'react';

import { ThemeColor } from '../../../../types/graphql.generated';
import { ThemeContext } from './context';

export const useThemeContext = () => {
  const context = useContext(ThemeContext);

  if (!context) {
    throw new Error('useThemeContext must be within ThemeContext.Provider');
  }

  return context;
};

export const useThemeVariables = () => {
  const themeContext = useThemeContext();
  return themeContext.themeConfig.variables;
};

type ThemeColorValues = Record<ThemeColor, string>;

export const useThemeColorValues = () => {
  const themeVariables = useThemeVariables();

  return useMemo<ThemeColorValues>(
    () => ({
      [ThemeColor.PRIMARY]: themeVariables.primaryColor,
      [ThemeColor.SECONDARY]: themeVariables.secondaryColor,
      [ThemeColor.TERTIARY]: themeVariables.tertiaryColor,
      [ThemeColor.INFO]: themeVariables.infoColor,
      [ThemeColor.SUCCESS]: themeVariables.successColor,
      [ThemeColor.WARNING]: themeVariables.warningColor,
      [ThemeColor.ERROR]: themeVariables.errorColor,
      [ThemeColor.LIGHT]: themeVariables.lightColor,
      [ThemeColor.DARK]: themeVariables.darkColor,
      [ThemeColor.INVERSE]: themeVariables.inverseColor,
    }),
    [themeVariables],
  );
};
