import { Scalars } from '@module/common';
import { getOperationName } from '@module/shared/graphql';
import { HttpStatusCode } from '@module/shared/helpers';
import { authExchange } from '@urql/exchange-auth';
import { parseISO } from 'date-fns';
import { GraphQLError } from 'graphql';
import { useMemo } from 'react';

import {
  convertResponseToAuthState,
  RefreshTokenDocument,
  RefreshTokenMutation,
  RefreshTokenMutationVariables,
} from '../graphql';
import { useAuthContext } from './useAuthConext';

// TODO API:
// Not every operation error includes a 401 code when the access token is invalid.
// Add well-formed error codes and fail on authentication _before_ authorization, so that we can
// distinguish between invalid tokens and missing permissions.
const isAuthenticationError = (error: GraphQLError) =>
  error.extensions.code === HttpStatusCode.UNAUTHORIZED;

const isTokenExpired = (expirationIsoDate: Scalars['DateTimeTz']) => {
  const nowTimestamp = Date.now();
  const expirationTimestamp = parseISO(expirationIsoDate).getTime();

  return nowTimestamp >= expirationTimestamp;
};

const publicOperations = [
  'AppSettingsPublic',
  'ForgotPassword',
  'InitialLogin',
  'Login',
  'ResetPassword',
  'VerifyEmail',
];

export function useAuthExchange() {
  const { clearAuthState, getAuthState, saveAuthState } = useAuthContext();

  return useMemo(
    () =>
      authExchange(async (utils) => ({
        addAuthToOperation: (operation) => {
          const authState = getAuthState();

          // the token isn't in the auth state, return the operation without changes
          if (!authState?.data.access_token) {
            return operation;
          }

          return utils.appendHeaders(operation, {
            Authorization: `Bearer ${authState.data.access_token}`,
          });
        },
        willAuthError: (operation) => {
          const operationName = getOperationName(operation.query);

          if (operationName && publicOperations.includes(operationName)) {
            return false;
          }

          const authState = getAuthState();

          if (!authState) {
            return true;
          }

          // We can skip the operation immediately when the access token is expired (=> refreshAuth).
          return isTokenExpired(authState.data.expires_at);
        },
        didAuthError: (error) => {
          // check if the error was an auth error (this can be implemented in various ways, e.g. 401 or a special error code)
          return error.graphQLErrors.some(isAuthenticationError);
        },
        refreshAuth: async () => {
          const authState = getAuthState();

          // authState in localStorage has vanished while the authState in React state probably still exists, which prevented the automatic redirect. Need to cleanup to trigger the login redirect.
          if (!authState) {
            clearAuthState();

            return;
          }

          // Can't use an an expired refresh token to fetch a new access_token => log out.
          if (isTokenExpired(authState.data.refresh_token_expires_at)) {
            clearAuthState();
            return;
          }

          const result = await utils.mutate<RefreshTokenMutation, RefreshTokenMutationVariables>(
            RefreshTokenDocument,
            { input: { refresh_token: authState.data.refresh_token } },
          );

          if (result.data?.refreshToken?.authState) {
            // set the new tokens
            const authStateNew = convertResponseToAuthState(result.data.refreshToken.authState);
            saveAuthState(authStateNew);
          } else {
            // otherwise, if refresh fails, clear storage and log out
            clearAuthState();
          }
        },
      })),
    [clearAuthState, getAuthState, saveAuthState],
  );
}
