export default {
  labels: {
    group: 'Sparte',
    owner: 'Experte',
    leader: 'Abteilung',
    revertControlling: 'Auftrag zurück zum Experten',
    noLeader: 'Ohne Abteilung',
    loadInactiveAdjusters: 'Inaktive Experten laden',
    inactiveAdjustersLoaded: 'Inaktive Experten geladen',
  },
  fieldComment: {
    label: 'Abteilung Kommentar:',
  },
  components: {
    taskMapAdjusterPopup: {
      adjusterSelectSuccess: 'Experte wurde ausgewählt',
      leader: 'Abteilung',
    },
    taskMapTaskPopup: {
      assignedOwner: 'Aktueller Experte',
    },
  },
  dialogs: {
    assignTaskMembers: {
      title: 'Experten & Vertretungen zuweisen',
      description: 'Hier können Sie Experten und Vertretungen für die Bearbeitung zuweisen.',
      owner: 'Experte',
    },
    reAssignTaskOwner: {
      description: 'Hier können Sie den Auftrag einem anderen Experten zuweisen.',
      owner: 'Experte',
    },
    confirmControlling: {
      note: 'Damit übergeben Sie den Auftrag an die zugewiesene Abteilung. Wollen Sie fortfahren?',
    },
    confirmRevertControlling: {
      title: 'Auftrag zurück zum Experten übergeben',
      note: 'Damit übergeben Sie den Auftrag wieder an den Experten. Wollen Sie fortfahren?',
    },
    changeTaskAssignments: {
      adjuster: 'Experten',
      note: 'Wählen Sie einen neuen Experten aus, der den Auftrag bearbeiten soll.',
      assignedOwner: 'Aktueller Experte: {{owner}}',
      noOwnerAssigned: 'Kein Experte zugewiesen',
      selectAdjuster: 'Experte auswählen...',
    },
  },
  pages: {
    overview: {
      mapSettings: {
        adjusterNames: 'Expertenname',
      },
    },
    common: {
      template: {
        dialogs: {
          editTemplate: {
            note: 'Hier können Sie die Formular-Vorlage auswählen und die Zuweisung von Experten und Abteilungen anpassen.',
          },
          editLeader: {
            title: 'Abteilung',
            description: 'Hier können Sie die Abteilung bearbeiten.',
          },
          editOwner: {
            title: 'Experte',
            description: 'Hier können Sie den Experten bearbeiten.',
          },
        },
      },
    },
  },
  forms: {
    createTask: {
      fields: {
        taskGroupId: {
          label: 'Sparte',
        },
        owner: {
          label: 'Experte',
          noLocationHint: 'Tragen Sie einen Ort ein, um alle Experten zu sehen.',
        },
        leader: {
          label: 'Abteilung',
        },
      },
      warnings: {
        noTemplates: {
          message:
            'Für die von Ihnen gewählte Versicherung sind keine Berichtsfragen verfügbar. Bitte kontaktieren Sie Ihren Administrator oder Ihre Abteilung.',
        },
      },
    },
  },
};
