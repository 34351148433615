import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import { SubformClassificationTagFragmentDoc } from './subformClassificationTagFragment.generated';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type UpdateSubformClassificationTagMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  input: Types.TagInput;
}>;

export type UpdateSubformClassificationTagMutation = {
  __typename?: 'Mutation';
  updateTag: {
    __typename?: 'Tag';
    id: string;
    title: string;
    color: string;
    type_id: string;
    type: { __typename?: 'TagType'; id: string; title: string; color: string };
  };
};

export const UpdateSubformClassificationTagDocument = gql`
  mutation UpdateSubformClassificationTag($id: ID!, $input: TagInput!) {
    updateTag(id: $id, input: $input) {
      ...SubformClassificationTag
    }
  }
  ${SubformClassificationTagFragmentDoc}
`;

export function useUpdateSubformClassificationTagMutation() {
  return Urql.useMutation<
    UpdateSubformClassificationTagMutation,
    UpdateSubformClassificationTagMutationVariables
  >(UpdateSubformClassificationTagDocument);
}
