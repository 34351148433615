import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ResetTaskAssignmentMutationVariables = Types.Exact<{
  task_id: Types.Scalars['ID'];
}>;

export type ResetTaskAssignmentMutation = {
  __typename?: 'Mutation';
  resetTaskAssignment?: { __typename?: 'Task'; id: string } | null;
};

export const ResetTaskAssignmentDocument = gql`
  mutation ResetTaskAssignment($task_id: ID!) {
    resetTaskAssignment(task_id: $task_id) {
      id
    }
  }
`;

export function useResetTaskAssignmentMutation() {
  return Urql.useMutation<ResetTaskAssignmentMutation, ResetTaskAssignmentMutationVariables>(
    ResetTaskAssignmentDocument,
  );
}
