import { CloseButton, Dialog, DialogContent, DialogHeader, DialogHeaderBar } from '@module/layout';
import { Button } from '@progress/kendo-react-buttons';
import { DialogActionsBar } from '@progress/kendo-react-dialogs';
import { TextArea, TextAreaChangeEvent } from '@progress/kendo-react-inputs';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DialogProps, useDialogs } from '../../dialogs/DialogsContext';

interface FreeTextMaximizeDialogProps extends DialogProps {
  text: string | number | string[] | undefined;
  save: ((value: TextAreaChangeEvent) => void) | undefined;
  title: string | undefined;
}

export const FreeTextMaximizeDialog = (props: FreeTextMaximizeDialogProps) => {
  const { text, dialogId } = props;
  const { hideDialog } = useDialogs();
  const { t } = useTranslation();
  const [newValue, setValue] = useState(text);

  const onSave = () => {
    hideDialog(dialogId);
  };
  const handleClose = useCallback(() => {
    hideDialog(dialogId);
  }, [hideDialog, dialogId]);

  const changeText = (e: TextAreaChangeEvent) => {
    if (props.save) {
      props.save(e);
    }
    setValue(e.value);
  };
  return (
    <Dialog
      className="dialog-content-no-padding"
      width="medium"
      height="fullscreen"
      onClose={handleClose}
    >
      <DialogHeaderBar>
        <DialogHeader title={props.title} />
      </DialogHeaderBar>
      <DialogContent>
        <TextArea size={'large'} autoSize={true} rows={23} value={newValue} onChange={changeText} />
      </DialogContent>

      <DialogActionsBar layout="end">
        <Button size="large" themeColor="primary" onClick={onSave}>
          {t('common.labels.save')}
        </Button>
        <CloseButton onClick={handleClose} />
      </DialogActionsBar>
    </Dialog>
  );
};
