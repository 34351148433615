import areaAssignments from './areaAssignments';
import clients from './clients';
import demoData from './demoData';
import payouts from './payouts';
import settings from './settings';
import tasks from './tasks';

export const localeOverrideSTP = {
  clients,
  demoData,
  areaAssignments,
  payouts,
  settings,
  tasks,
};
