import './CategoryList.scss';

import { BaseButton, BaseButtonProps } from '@module/layout';
import { NumberInput, TextInput } from '@module/shared/forms';
import { Label } from '@module/shared/forms/components/Label';
import { clone } from '@progress/kendo-react-common';
import {
  Field,
  FieldArray,
  FieldArrayRenderProps,
  FieldRenderProps,
} from '@progress/kendo-react-form';
import { Grid, GridCellProps, GridColumn, GridToolbar } from '@progress/kendo-react-grid';
import { Error } from '@progress/kendo-react-labels';
import { createContext, useCallback, useContext, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface DataItem {
  formDataIndex: number;
  order_index?: number;
}

interface FormGridEditInterface {
  onRemove: (dataItem: DataItem) => void;
  onEdit: (dataItem: DataItem, isNew: boolean) => void;
  onSave: () => void;
  onCancel: () => void;
  editIndex: number | undefined;
  parentField: string;
}

// Create Context to pass props to the Form Field components from the main component
export const FormGridEditContext = createContext<FormGridEditInterface>(
  {} as FormGridEditInterface,
);

const FORM_DATA_INDEX = 'formDataIndex';

const DisplayValue = (fieldRenderProps: FieldRenderProps) => {
  return <>{fieldRenderProps.value}</>;
};

const TextOrNumberCell = (props: GridCellProps & { type: 'text' | 'number' }) => {
  const { parentField, editIndex } = useContext(FormGridEditContext);
  const isInEdit = props.dataItem[FORM_DATA_INDEX] === editIndex;
  const Input = props.type === 'text' ? TextInput : NumberInput;

  return (
    <td>
      <Field
        component={isInEdit ? Input : DisplayValue}
        name={`${parentField}[${props.dataItem[FORM_DATA_INDEX]}].${props.field}`}
      />
    </td>
  );
};

const TextCell = (props: GridCellProps) => <TextOrNumberCell type="text" {...props} />;
const NumberCell = (props: GridCellProps) => <TextOrNumberCell type="number" {...props} />;

// Add a command cell to Edit, Update, Cancel and Delete an item
const CommandCell = (props: GridCellProps) => {
  const { onRemove, onEdit, onSave, onCancel, editIndex } = useContext(FormGridEditContext);
  const isInEdit = props.dataItem[FORM_DATA_INDEX] === editIndex;
  const isNewItem = !props.dataItem[FORM_DATA_INDEX];

  const { t } = useTranslation();

  const onRemoveClick = useCallback<NonNullable<BaseButtonProps['onChange']>>(
    (e) => {
      e.preventDefault();
      onRemove(props.dataItem);
    },
    [props.dataItem, onRemove],
  );

  const onEditClick = useCallback<NonNullable<BaseButtonProps['onChange']>>(
    (e) => {
      e.preventDefault();
      onEdit(props.dataItem, isNewItem);
    },
    [props.dataItem, onEdit, isNewItem],
  );

  const onSaveClick = useCallback<NonNullable<BaseButtonProps['onChange']>>(
    (e) => {
      e.preventDefault();
      onSave();
    },
    [onSave],
  );

  const onCancelClick = useCallback<NonNullable<BaseButtonProps['onChange']>>(
    (e) => {
      e.preventDefault();
      onCancel();
    },
    [onCancel],
  );

  return isInEdit ? (
    <td className="k-command-cell !k-align-top">
      <BaseButton iconClass={'l-i-save'} label={t('common.labels.save')} onClick={onSaveClick} />
      <BaseButton
        iconClass={'' + (isNewItem ? 'l-i-trash-2' : 'l-i-x')}
        label={isNewItem ? t('common.labels.remove') : t('common.labels.close')}
        onClick={isNewItem ? onRemoveClick : onCancelClick}
      />
    </td>
  ) : (
    <td className="k-command-cell">
      <BaseButton
        iconClass={'l-i-pencil'}
        label={t('common.labels.update')}
        onClick={onEditClick}
      />
      <BaseButton
        iconClass={'l-i-trash-2'}
        label={t('common.labels.remove')}
        onClick={onRemoveClick}
      />
    </td>
  );
};

// Create the Grid that will be used inside the Form
const FormGrid = (fieldArrayRenderProps: FieldArrayRenderProps) => {
  const { validationMessage, visited, name } = fieldArrayRenderProps;
  const [editIndex, setEditIndex] = useState<number | undefined>();
  const editItemCloneRef = useRef();
  const { t } = useTranslation();

  // Add a new item to the Form FieldArray that will be shown in the Grid
  const onAddNewCategory = useCallback<NonNullable<BaseButtonProps['onChange']>>(
    (e) => {
      e.preventDefault();
      typeof fieldArrayRenderProps.onUnshift === 'function' &&
        fieldArrayRenderProps.onUnshift({
          value: {
            order_index: 0,
            title: '',
          },
        });

      setEditIndex(0);
    },
    [fieldArrayRenderProps],
  );
  // Remove a new item to the Form FieldArray that will be removed from the Grid
  const onRemove = useCallback<FormGridEditInterface['onRemove']>(
    (dataItem) => {
      typeof fieldArrayRenderProps.onRemove === 'function' &&
        fieldArrayRenderProps.onRemove({
          index: dataItem[FORM_DATA_INDEX],
        });

      setEditIndex(undefined);
    },
    [fieldArrayRenderProps],
  );

  // Update an item from the Grid and update the index of the edited item
  const onEdit = useCallback<FormGridEditInterface['onEdit']>((dataItem, isNewItem) => {
    if (!isNewItem) {
      editItemCloneRef.current = clone(dataItem);
    }

    setEditIndex(dataItem[FORM_DATA_INDEX]);
  }, []);

  // Cancel the editing of an item and return its initial value
  const onCancel = useCallback(() => {
    if (editItemCloneRef.current) {
      typeof fieldArrayRenderProps.onReplace === 'function' &&
        fieldArrayRenderProps.onReplace({
          index: editItemCloneRef.current[FORM_DATA_INDEX],
          value: editItemCloneRef.current,
        });
    }

    editItemCloneRef.current = undefined;
    setEditIndex(undefined);
  }, [fieldArrayRenderProps]);

  // Save the changes
  const onSave = useCallback(() => {
    setEditIndex(undefined);
  }, []);

  const dataWithIndexes = Array.isArray(fieldArrayRenderProps.value)
    ? fieldArrayRenderProps.value?.map<DataItem>((item: object, index: number) => {
        return { ...item, [FORM_DATA_INDEX]: index };
      })
    : [];

  return (
    <FormGridEditContext.Provider
      value={{
        onCancel,
        onEdit,
        onRemove,
        onSave,
        editIndex,
        parentField: name as string,
      }}
    >
      {visited && validationMessage && <Error>{validationMessage as string}</Error>}

      <Grid
        data={dataWithIndexes}
        dataItemKey={FORM_DATA_INDEX as string}
        className="category-list"
      >
        <GridToolbar>
          <BaseButton
            iconClass="l-i-plus"
            label={t('common.components.category.addNewCategory')}
            onClick={onAddNewCategory}
          />
        </GridToolbar>
        <GridColumn
          field="title"
          title={t('common.components.category.category')}
          cell={TextCell}
        />
        <GridColumn
          field="order_index"
          title={t('common.components.category.index')}
          cell={NumberCell}
        />
        <GridColumn cell={CommandCell} />
      </Grid>
    </FormGridEditContext.Provider>
  );
};

export const CategoryList = () => {
  const { t } = useTranslation();

  return (
    <>
      <Label className="k-font-weight-semibold">
        {t('templates.dialogs.templateCreate.form.category.label')}
      </Label>
      <Label>{t('templates.dialogs.templateCreate.form.category.description')}</Label>
      <FieldArray name="default_categories" component={FormGrid} />
    </>
  );
};
