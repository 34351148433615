import { ReactNode } from 'react';

export const PublicLayout = (props: { children?: ReactNode | undefined }) => {
  return (
    <div
      style={{ position: 'relative', minHeight: 'var(--100dvh)' }}
      className="k-flex k-display-flex k-flex-column"
    >
      {props.children}
    </div>
  );
};
