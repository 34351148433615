import './FixedRatioImage.scss';

import { classNames } from '@progress/kendo-react-common';
import { CSSProperties, ImgHTMLAttributes, useState } from 'react';

interface FixedRatioImageProps extends ImgHTMLAttributes<HTMLImageElement> {
  aspectRatio: NonNullable<CSSProperties['aspectRatio']>;
  objectFit?: NonNullable<CSSProperties['objectFit']>;
  width?: CSSProperties['width'];
  wrapperClassName?: string;
  wrapperStyle?: Omit<CSSProperties, 'aspectRatio' | 'width'>;
}

export const FixedRatioImage = (props: FixedRatioImageProps) => {
  const {
    aspectRatio,
    objectFit = 'contain',
    width = '100%',
    wrapperClassName,
    wrapperStyle,
    ...rest
  } = props;
  const [isLoading, setIsLoading] = useState(true);

  return (
    <div
      className={classNames('FixedRatioImage', isLoading && 'isLoading', wrapperClassName)}
      style={{ ...wrapperStyle, aspectRatio, width }}
    >
      <img
        {...rest}
        style={{ ...rest.style, objectFit }}
        ref={(element) => {
          if (element?.complete) {
            setIsLoading(false);
          }
        }}
        onLoad={(event) => {
          rest.onLoad?.(event);
          setIsLoading(false);
        }}
        onError={(event) => {
          rest.onError?.(event);
          setIsLoading(false);
        }}
      />
    </div>
  );
};
