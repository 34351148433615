import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import { AuthStateFragmentDoc } from './types.generated';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type LoginMutationVariables = Types.Exact<{
  input: Types.LoginInput;
}>;

export type LoginMutation = {
  __typename?: 'Mutation';
  login: {
    __typename?: 'LoginResponse';
    message: string;
    requiresVerification: boolean;
    authState?: {
      __typename?: 'AuthState';
      data: {
        __typename?: 'AuthData';
        access_token: string;
        refresh_token: string;
        expires_at: string;
        refresh_token_expires_at: string;
      };
    } | null;
  };
};

export const LoginDocument = gql`
  mutation Login($input: LoginInput!) {
    login(input: $input) {
      message
      authState {
        ...AuthState
      }
      requiresVerification
    }
  }
  ${AuthStateFragmentDoc}
`;

export function useLoginMutation() {
  return Urql.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument);
}
