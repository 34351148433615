import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type TaskTemplatesAllQueryVariables = Types.Exact<{ [key: string]: never }>;

export type TaskTemplatesAllQuery = {
  __typename?: 'Query';
  taskTemplatesAll: Array<{
    __typename?: 'TaskTemplate';
    id: string;
    title: string;
    shortcut?: string | null;
    color: string;
    active?: boolean | null;
    json?: any | null;
    type_id: Types.TaskTemplateTypeId;
  }>;
};

export const TaskTemplatesAllDocument = gql`
  query TaskTemplatesAll {
    taskTemplatesAll {
      id
      title
      shortcut
      color
      active
      json
      type_id
    }
  }
`;

export function useTaskTemplatesAllQuery(
  options?: Omit<Urql.UseQueryArgs<TaskTemplatesAllQueryVariables>, 'query'>,
) {
  return Urql.useQuery<TaskTemplatesAllQuery, TaskTemplatesAllQueryVariables>({
    query: TaskTemplatesAllDocument,
    ...options,
  });
}
