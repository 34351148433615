import {
  Ability,
  AbilityBuilder,
  AbilityClass,
  ForcedSubject,
  subject as subjectHelper,
} from '@casl/ability';
import { useCallback, useMemo } from 'react';

import { Permission } from '../../../types/graphql.generated';
import { GenericCan, GenericCanSharedProps } from '../components/GenericCan';
import { CurrentUserAbilityFragment, TaskSubTaskAbilityFragment } from '../graphql';
import { detectSubjectTypeByTypename as detectSubjectType } from '../helpers';
import { useMeOrDefault } from '../hooks';

interface TaskSubTaskSubject {
  me: CurrentUserAbilityFragment;
  taskSubTask?: TaskSubTaskAbilityFragment;
}

type TaskSubTaskSubjectArg = Partial<TaskSubTaskSubject>;

type Actions =
  | 'viewTaskSubTasks'
  | 'viewTaskSubTask'
  | 'createTaskSubTask'
  | 'updateTaskSubTask'
  | 'updateTaskSubTaskTitle'
  | 'updateTaskSubTaskDescription'
  | 'updateTaskSubTaskFile'
  | 'updateTaskSubTaskFinishDate'
  | 'updateTaskSubTaskAssignee'
  | 'deleteTaskSubTask'
  | 'completeTaskSubTask'
  | 'reopenTaskSubTask'
  | 'reviewTaskSubTask';

type Subjects = TaskSubTaskSubject | 'TaskSubTaskSubject';

type TaskSubTaskAbility = Ability<[Actions, Subjects]>;
const taskTemplateAbility = Ability as AbilityClass<TaskSubTaskAbility>;

export const useTaskSubTaskAbility = (): [
  TaskSubTaskAbility,
  (sub?: TaskSubTaskSubjectArg) => TaskSubTaskSubject & ForcedSubject<'TaskSubTaskSubject'>,
] => {
  const ability = useMemo(() => {
    const { can, build } = new AbilityBuilder(taskTemplateAbility);

    can('viewTaskSubTasks', 'TaskSubTaskSubject', {
      'me.globalPermissions': { $in: [Permission.TASK_SUB_TASK_INDEX] },
    });
    can('viewTaskSubTask', 'TaskSubTaskSubject', {
      'me.globalPermissions': { $in: [Permission.TASK_SUB_TASK_READ] },
    });
    can('createTaskSubTask', 'TaskSubTaskSubject', {
      'me.globalPermissions': { $in: [Permission.TASK_SUB_TASK_CREATE] },
    });
    can('deleteTaskSubTask', 'TaskSubTaskSubject', {
      'taskSubTask.permissions': { $in: [Permission.TASK_SUB_TASK_DELETE] },
    });
    can('updateTaskSubTask', 'TaskSubTaskSubject', {
      'taskSubTask.permissions': { $in: [Permission.TASK_SUB_TASK_UPDATE] },
    });
    can('updateTaskSubTaskTitle', 'TaskSubTaskSubject', {
      'taskSubTask.permissions': { $in: [Permission.TASK_SUB_TASK_UPDATE_TITLE] },
    });
    can('updateTaskSubTaskDescription', 'TaskSubTaskSubject', {
      'taskSubTask.permissions': { $in: [Permission.TASK_SUB_TASK_UPDATE_DESCRIPTION] },
    });
    can('updateTaskSubTaskFile', 'TaskSubTaskSubject', {
      'taskSubTask.permissions': { $in: [Permission.TASK_SUB_TASK_UPDATE_FILE] },
    });
    can('updateTaskSubTaskFinishDate', 'TaskSubTaskSubject', {
      'taskSubTask.permissions': { $in: [Permission.TASK_SUB_TASK_UPDATE_DUE_DATE] },
    });
    can('updateTaskSubTaskAssignee', 'TaskSubTaskSubject', {
      'taskSubTask.permissions': { $in: [Permission.TASK_SUB_TASK_UPDATE_ASSIGNEE] },
    });
    can('completeTaskSubTask', 'TaskSubTaskSubject', {
      'taskSubTask.permissions': { $in: [Permission.TASK_SUB_TASK_COMPLETE] },
    });
    can('reopenTaskSubTask', 'TaskSubTaskSubject', {
      'taskSubTask.permissions': { $in: [Permission.TASK_SUB_TASK_REOPEN] },
    });
    can('reviewTaskSubTask', 'TaskSubTaskSubject', {
      'taskSubTask.permissions': { $in: [Permission.TASK_SUB_TASK_REVIEW] },
    });

    return build({ detectSubjectType });
  }, []);

  const me = useMeOrDefault();
  const subject = useCallback(
    (sub?: TaskSubTaskSubjectArg) => {
      return subjectHelper('TaskSubTaskSubject', { me, ...sub });
    },
    [me],
  );

  return [ability, subject];
};

interface CanTaskSubTaskProps extends GenericCanSharedProps<Actions> {
  taskSubTask?: TaskSubTaskAbilityFragment;
}

export const CanTaskSubTask = (props: CanTaskSubTaskProps) => {
  const [taskSubTaskAbility, taskSubTaskSubject] = useTaskSubTaskAbility();
  const { taskSubTask, ...restProps } = props;

  return (
    <GenericCan<Actions, Subjects, TaskSubTaskAbility>
      ability={taskSubTaskAbility}
      subject={taskSubTaskSubject({ taskSubTask })}
      {...restProps}
    />
  );
};
