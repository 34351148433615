import './FlexFillArea.scss';

import { classNames } from '@progress/kendo-react-common';
import { CSSProperties, ReactNode } from 'react';

export interface FlexFillAreaProps {
  children?: ReactNode;
  className?: string;
  style?: CSSProperties | undefined;
}

export const FlexFillArea = (props: FlexFillAreaProps) => {
  return (
    <div className={classNames('flex-fill-area', props.className)} style={props.style}>
      {props.children}
    </div>
  );
};
