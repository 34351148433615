export default {
  labels: {
    editor: 'Urednik',
    assignment: 'dodjela',
    id: 'Id',
    title: 'Naslov',
    visibility: 'Vidljivost',
    edit: 'Uredi',
    preview: 'Pregled',
    upload: 'Učitaj',
    new: 'Novo',
    download: 'Preuzmi',
    selectFieldDescription: 'Promijenite ili dodajte elemente u PDF:',
    selectFieldType: 'Odaberite vrstu polja',
    selectField: 'Odaberite polje koje želite mapirati.',
    fieldPreferences: 'Svojstva polja',
    add: 'dodaj',
    signatureField: 'Polja za potpis mogu se stvoriti u PDF-u s poljem za potpis',
    textField: 'S tekstnim poljem, polja obrasca mogu se stvoriti u PDF tekstu',
    removeAssociation: 'Ukloni dodjelu',
  },
  dialogs: {
    preview: {
      title: 'Pregled',
    },
    edit: {
      title: 'Uredi PDF obrazac',
      note: 'Ovdje možete urediti dodjelu pdf obrazaca i njihove naslove.',
    },
    editAnnotations: {
      title: 'Uredi bilješke',
    },
    new: {
      title: 'Novo',
      create: 'Kreiraj',
    },
    update: {
      title: 'Ažuriraj',
    },
    upload: {
      title: 'Prenesite pdf obrasce',
      note: 'Ovdje možete učitati pdf obrasce.',
      cancel: 'Odustani',
      upload: 'Učitaj',
    },
    designer: {
      title: 'Dizajner',
    },
  },
  pages: {
    overview: {
      title: 'PDF obrasci',
      description: 'Ovdje ćete pronaći popis PDF obrazaca.',
      grid: {
        columns: {
          actions: 'Radnje',
        },
      },
    },
  },
  components: {
    measurements: {
      calibrateScale: 'Kalibrirajte vagu',
      polygonAreaMeasurement: 'Mjerenje površine',
      setScale: 'Postavite ljestvicu',
      newScale: 'dodajte novu ljestvicu',
      displaySecondaryUnit: 'Prikaži sekundarnu jedinicu',
      secondaryUnit: 'Sekundarna jedinica',
      scaleName: 'Naziv ljestvice',
      scale: 'mjerilo',
      editAddScale: 'Uredi/dodaj skalu',
      duplicateScaleError: 'Ljestvica s ovim imenom već postoji',
      measurementScale: 'mjerilo',
      calibrationScaleSuccess: 'Mjerilo je uspješno dodano',
      calibrationScaleSubtitle: 'Započnite mjerenje odabirom jednog od alata za mjerenje.',
      Snapping: 'Snap',
    },
  },
};
