import { noop } from 'lodash';
import { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { Location, useLocation } from 'react-router-dom';

interface IHistory extends Location {
  title?: string;
}

interface HistoryContextType {
  history: IHistory[];
  setHistoryTitle: (currentTitle: string) => void;
  deleteHistory: () => void;
}

const HistoryContext = createContext<HistoryContextType>({} as HistoryContextType);

export const useHistory = (): HistoryContextType => {
  return useContext(HistoryContext);
};

export const HistoryProvider = (props: { children?: ReactNode | undefined }) => {
  const [history, setHistory] = useState<IHistory[]>([]);
  const [title, setTitle] = useState<string>();

  const handleSetItems = (
    currentHistory: IHistory[],
    currentTitle: string,
    currentLocation: Location,
  ) => {
    setHistory([...currentHistory, { ...currentLocation, title: currentTitle }]);
    localStorage.setItem(
      'history',
      JSON.stringify([...currentHistory, { ...currentLocation, title: currentTitle }]),
    );
  };

  const location = useLocation();

  const setHistoryTitle = (currentTitle: string) => {
    setTitle(currentTitle);
  };

  const deleteHistory = () => {
    localStorage.removeItem('history');
    setHistory([]);
  };

  useEffect(() => {
    const storedValues = localStorage.getItem('history');
    if (storedValues) {
      try {
        setHistory(JSON.parse(storedValues));
      } catch (e) {
        noop();
      }
    }
  }, []);

  useEffect(() => {
    const lastHistoryItem = history[history.length - 1];
    if (history.length === 0 && title) {
      setHistory([{ ...location, title }]);
      localStorage.setItem('history', JSON.stringify([{ ...location, title }]));
      return;
    }

    if (
      history.length > 0 &&
      title &&
      title !== lastHistoryItem.title &&
      location.pathname !== lastHistoryItem.pathname
    ) {
      if (history.length > 9) {
        handleSetItems(history.slice(history.length - 9, history.length), title, location);
      } else {
        handleSetItems(history, title, location);
      }
    }
  }, [location, title, history]);

  return (
    <HistoryContext.Provider value={{ history, setHistoryTitle, deleteHistory }}>
      {props.children}
    </HistoryContext.Provider>
  );
};
