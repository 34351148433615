import { useDropDownListDefaultItem } from '@module/common';
import { DropDownListInput } from '@module/shared/forms';
import { isNotNullish } from '@module/shared/helpers';
import { FieldRenderProps } from '@progress/kendo-react-form';
import { useCallback, useMemo } from 'react';

import {
  InterfaceMessageTemplateCategoryFragment,
  useInterfaceMessageTemplateCategoriesQuery,
} from '../graphql';

export interface InterfaceMessageTemplateCategorySelectProps extends FieldRenderProps {
  value: string | null | undefined;
}

export const InterfaceMessageTemplateCategorySelect = (
  props: InterfaceMessageTemplateCategorySelectProps,
) => {
  const { value, onChange, ...rest } = props;
  const defaultItem = useDropDownListDefaultItem('id', 'title', undefined);

  const [{ data, fetching }] = useInterfaceMessageTemplateCategoriesQuery();

  const items = useMemo(() => {
    if (!data?.interfaceMessageTemplateCategories) {
      return undefined;
    }

    return data.interfaceMessageTemplateCategories.filter(isNotNullish);
  }, [data?.interfaceMessageTemplateCategories]);

  const valueAsItem = useMemo(() => items?.find((item) => item.id === value), [value, items]);

  const handleChange = useCallback(
    (event: { value?: InterfaceMessageTemplateCategoryFragment }) => {
      onChange({
        ...event,
        value: event.value?.id ?? null,
      });
    },
    [onChange],
  );

  return (
    <DropDownListInput
      {...rest}
      data={items}
      dataItemKey="id"
      textField="title"
      value={valueAsItem}
      disabled={!items}
      isLoading={fetching}
      onChange={handleChange}
      defaultItem={defaultItem}
    />
  );
};
