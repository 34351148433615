import { API_URL, APP_VERSION } from '@env';
import { useMemo } from 'react';

const urlLoginLogo = `${API_URL}/app/login-logo`;
const urlLoginBackground = `${API_URL}/app/login-background`;
const urlMenuIcon = `${API_URL}/app/menu-icon`;

export function useBrandingData() {
  return useMemo(() => {
    return {
      logo_light: urlMenuIcon,
      logo: urlLoginLogo,
      background: urlLoginBackground,
      name: 'Rocket-ES',
      product: 'Inspcto',
      version: APP_VERSION,
    };
  }, []);
}
