import './FilterDialog.scss';

import {
  CancelButton,
  DialogContent,
  DialogHeader,
  DialogHeaderBar,
  DialogHeaderIcon,
  PrimaryButton,
} from '@module/layout';
import { CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { Filter } from '@progress/kendo-react-data-tools';
import { DialogActionsBar } from '@progress/kendo-react-dialogs';
import { TabStrip, TabStripSelectEventArguments, TabStripTab } from '@progress/kendo-react-layout';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Dialog } from '../../../layout';
import { DialogProps, useDialogs } from '../../dialogs';
import { isFilterDescriptor } from '../../graphql';
import { isEmptyString, isNotNullish } from '../../helpers';
import { FilterContextType } from '../types';
import { FilterBar } from './FilterBar';

export interface FilterDialogProps {
  filterContext: FilterContextType;
}

const initialFilter: CompositeFilterDescriptor = {
  logic: 'and',
  filters: [],
};

const TAB_COMMON = 0;
const TAB_ADVANCED = 1;

export const FilterDialog = (props: FilterDialogProps & DialogProps) => {
  const { t } = useTranslation();
  const { hideDialog } = useDialogs();
  const [selected, setSelected] = useState(TAB_COMMON);
  const [commonFilter, setCommonFilter] = useState<CompositeFilterDescriptor>(initialFilter);
  const [advancedFilter, setAdvancedFilter] = useState<CompositeFilterDescriptor>(initialFilter);

  const handleClose = useCallback(() => {
    hideDialog(props.dialogId);
  }, [hideDialog, props.dialogId]);

  const handleSubmit = useCallback(() => {
    if (selected === TAB_COMMON) {
      const filters = commonFilter.filters.filter(
        (filter) =>
          isFilterDescriptor(filter) && isNotNullish(filter.value) && !isEmptyString(filter.value),
      );
      props.filterContext.changeAdditionalFilter(filters.length > 0 ? commonFilter : null);
    }
    if (selected === TAB_ADVANCED) {
      props.filterContext.changeAdditionalFilter(advancedFilter);
    }

    hideDialog(props.dialogId);
  }, [advancedFilter, commonFilter, hideDialog, props, selected]);

  const handleSelect = useCallback((e: TabStripSelectEventArguments) => {
    setSelected(e.selected);
  }, []);

  const handleCommonFilterChange = useCallback((filter: CompositeFilterDescriptor | null) => {
    setCommonFilter(filter ?? initialFilter);
  }, []);

  const handleAdvancedFilterChange = useCallback((e: { filter: CompositeFilterDescriptor }) => {
    setAdvancedFilter(e.filter);
  }, []);

  return (
    <Dialog width="large" className="FilterDialog" onClose={handleClose}>
      <DialogHeaderBar>
        <DialogHeaderIcon iconClass={'l-i-filter u-text-2xl'} color="success" />
        <DialogHeader
          title={t('common.components.filterDialog.title')}
          description={t('common.components.filterDialog.note')}
        />
      </DialogHeaderBar>
      <DialogContent>
        <TabStrip selected={selected} onSelect={handleSelect} animation={false}>
          <TabStripTab title={t('common.components.filterDialog.tabs.common')}>
            <FilterBar
              fields={props.filterContext.additionalFields}
              onFilterChange={handleCommonFilterChange}
            />
          </TabStripTab>
          <TabStripTab title={t('common.components.filterDialog.tabs.advanced')}>
            <Filter value={advancedFilter} fields={[]} onChange={handleAdvancedFilterChange} />
          </TabStripTab>
        </TabStrip>
      </DialogContent>

      <DialogActionsBar layout="end">
        <CancelButton onClick={handleClose} />
        <PrimaryButton
          iconClass="l-i-check"
          label={t('common.labels.apply')}
          onClick={handleSubmit}
        />
      </DialogActionsBar>
    </Dialog>
  );
};
