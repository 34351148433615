import { Slide } from '@progress/kendo-react-animation';
import { Button } from '@progress/kendo-react-buttons';
import { useTranslation } from 'react-i18next';

import { fileTypeIcons, guessFileTypeFromFileName } from '../../helpers';
import { useDownloadsContext } from './DownloadsContext';

export const DownloadsPanel = () => {
  const { state, removeDownload } = useDownloadsContext();
  const { t } = useTranslation();

  const hasDownloads = state.created.length > 0 || state.generated.length > 0;

  return (
    <div
      style={{
        position: 'fixed',
        left: '50%',
        bottom: 0,
        transform: 'translateX(-50%)',
        zIndex: 1000,
      }}
    >
      <Slide direction="up" transitionEnterDuration={200} transitionExitDuration={100}>
        {hasDownloads && (
          <div className="k-popover k-pos-relative k-mb-4" style={{ minWidth: 288 }}>
            <div className="k-popover-callout k-callout-s" />

            <div className="k-popover-inner">
              <div className="k-popover-body">
                <h2 className="!k-mt-0 !k-mb-3 u-text-lg">
                  {t('common.components.downloads.title')}
                </h2>

                <ul className="k-list-none k-m-0 k-p-0 k-d-flex-col k-gap-1">
                  {state.created.length > 0 && (
                    <li className="k-d-inline-flex k-align-items-center k-gap-2 k-mb-1 u-text-base-400">
                      <span className="l-i-hourglass" />
                      <span>
                        {t('common.components.downloads.filesInGeneration', {
                          count: state.created.length,
                        })}
                      </span>
                    </li>
                  )}

                  {state.generated.map((download) => (
                    <li
                      key={download.id}
                      className="k-d-flex k-align-items-center k-justify-content-between"
                      style={{ wordBreak: 'break-all' }}
                    >
                      <a
                        href={download.url_download}
                        className="k-d-inline-flex k-align-items-center k-gap-2 k-link u-text-primary-500"
                        target="_blank"
                        rel="noreferrer"
                        onClick={() => removeDownload(download.id)}
                      >
                        <span
                          className={`${
                            fileTypeIcons[guessFileTypeFromFileName(download.filename)]
                          }`}
                        />
                        <span style={{ overflowWrap: 'anywhere' }}>{download.filename}</span>
                      </a>

                      <Button
                        type="button"
                        fillMode="flat"
                        size="small"
                        iconClass="l-i-x"
                        aria-label={t('common.labels.delete')}
                        onClick={() => removeDownload(download.id)}
                      />
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        )}
      </Slide>
    </div>
  );
};
