import { FieldWrapper } from '@progress/kendo-react-form';
import { NumericTextBox, NumericTextBoxProps } from '@progress/kendo-react-inputs';
import { useId } from 'react';

import { Label } from '../components/Label';
import { FieldError } from './FieldError';
import { FieldHint } from './FieldHint';
import { CommonFieldRenderProps } from './types';

type NumberInputProps = CommonFieldRenderProps &
  Pick<
    NumericTextBoxProps,
    | 'size'
    | 'id'
    | 'value'
    | 'placeholder'
    | 'min'
    | 'max'
    | 'step'
    | 'required'
    | 'disabled'
    | 'onBlur'
    | 'onChange'
    | 'onFocus'
    | 'format'
  > & {
    inputClassNames?: string;
  };

export const NumberInput = (props: NumberInputProps) => {
  const defaultId = useId();
  const {
    size = 'large',
    label,
    valid,
    id = defaultId,
    value,
    placeholder,
    min,
    max,
    step,
    required,
    disabled,
    onBlur,
    onChange,
    onFocus,
    name,
    inputClassNames,
    tooltip,
    tooltipPosition,
    format,
  } = props;
  return (
    <FieldWrapper>
      {label && (
        <Label
          tooltip={tooltip}
          tooltipPosition={tooltipPosition}
          label={label}
          name={name}
          editorId={id}
          editorValid={valid}
        >
          {label}
        </Label>
      )}

      <NumericTextBox
        className={inputClassNames}
        size={size}
        id={id}
        value={value}
        placeholder={placeholder}
        min={min}
        max={max}
        step={step}
        required={required}
        disabled={disabled}
        onBlur={onBlur}
        onChange={onChange}
        onFocus={onFocus}
        format={format}
      />
      <div className="HintAndError">
        <FieldHint {...props} />
        <FieldError {...props} />
      </div>
    </FieldWrapper>
  );
};
