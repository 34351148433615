import { ReactNode } from 'react';

import { FilterContext } from '../hooks/useFilterContext';
import { FilterContextType } from '../types';

export interface FilterContextProviderProps {
  children?: ReactNode | undefined;
  filterContext: FilterContextType;
}

export const FilterContextProvider = (props: FilterContextProviderProps) => {
  return (
    <FilterContext.Provider value={props.filterContext}>{props.children}</FilterContext.Provider>
  );
};
