import { Blocker, useBlocker } from '@module/shared/helpers';
import { FormHandle } from '@progress/kendo-react-form';
import { isEmpty } from 'lodash';
import { RefObject, SyntheticEvent, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { useConfirmDialogs } from './confirm-dialog/ConfirmDialog';

export const useFormBlockerConfirmDialog = (
  ref: RefObject<FormHandle>,
  params: FormBlockerProps,
) => {
  const { t } = useTranslation();
  const confirm = useConfirmDialogs();
  return useCallback(() => {
    confirm.discard({
      title: t('common.dialogs.formNavigationBlocker.title'),
      description: t('common.dialogs.formNavigationBlocker.note'),
      discard: () => {
        ref?.current?.resetForm();
        if (params.discard) params.discard();
      },
      save: () => {
        ref?.current?.onSubmit({} as SyntheticEvent);
      },
    });
  }, [confirm, t, ref, params]);
};

interface FormBlockerProps {
  discard?: () => void;
}

const FormBlocker = (ref: RefObject<FormHandle>, params: FormBlockerProps) => {
  const isModified = !isEmpty(ref?.current?.modified);
  const { t } = useTranslation();
  const confirm = useConfirmDialogs();
  const blocker = useCallback<Blocker>(
    (tx) => {
      confirm.discard({
        title: t('common.dialogs.formNavigationBlocker.title'),
        description: t('common.dialogs.formNavigationBlocker.note'),
        discard: () => {
          ref?.current?.resetForm();
          if (params.discard) params.discard();
          tx.retry();
        },
        save: () => {
          ref?.current?.onSubmit({} as SyntheticEvent);
        },
      });
    },
    [confirm, t, ref, params],
  );

  useBlocker(blocker, isModified);
  return null;
};

export function useFormBlocker() {
  const ref = useRef<FormHandle>(null);
  const Blocker = (params: FormBlockerProps) => {
    return FormBlocker(ref, params);
  };
  // Reset only inner value of the form without rerendering component
  const resetModified = useCallback(() => {
    if (ref.current?.modified && !isEmpty(ref.current.modified)) {
      ref.current.modified = {};
    }
  }, []);

  return { ref, Blocker, resetModified };
}

export function useFormReset() {
  const ref = useRef<FormHandle>(null);

  // Reset complete form and refresh it
  const resetForm = useCallback(() => {
    if (ref.current?.modified && !isEmpty(ref.current.modified)) {
      ref.current.resetForm();
    }
  }, []);

  return { ref, resetForm };
}
