import { createElement } from 'react';

import { useDialogs } from './DialogsContext';

export const DialogsContainer = () => {
  const { dialogs } = useDialogs();

  return (
    <>
      {dialogs.map((dialog) =>
        createElement(dialog.dialogComponentType, {
          key: dialog.dialogId,
          dialogId: dialog.dialogId,
          ...dialog.dialogProps,
        }),
      )}
    </>
  );
};
