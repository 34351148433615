import { FieldWrapper } from '@progress/kendo-react-form';
import { CheckboxProps } from '@progress/kendo-react-inputs';
import { Label } from '@progress/kendo-react-labels';

import { CommonFieldRenderProps, FieldError, FieldHint } from '../inputs';
import { Checkbox } from '../inputs/Checkbox';

type CheckboxFieldProps = CommonFieldRenderProps & Omit<CheckboxProps, ''>;

export const CheckboxField = (props: CheckboxFieldProps) => {
  const { id, label, size, onChange } = props;
  return (
    <FieldWrapper>
      <Label editorId={id} id={id}>
        {label}
      </Label>
      <Checkbox size={size} onChange={onChange} ariaLabelledBy={id} />
      <div className="HintAndError">
        <FieldHint {...props} />
        <FieldError {...props} />
      </div>
    </FieldWrapper>
  );
};
