import { classNames } from '@progress/kendo-react-common';
import { GridCell, GridCellProps } from '@progress/kendo-react-grid';
import { get } from 'lodash';
import { useMemo } from 'react';

import { ThemeColor } from '../../../../types/graphql.generated';
import { getThemeColorByField, getThemeColorFont } from '../../helpers';

export interface ColorCellProps {
  themeColorField?: string | [string];
  themeColor?: ThemeColor;
  fieldColor?: string | [string];
}

export const ColorCell = (props: ColorCellProps & GridCellProps) => {
  const { themeColorField, themeColor, className, fieldColor, style, ...restProps } = props;

  const classNameNew = useMemo(() => {
    const themeColorByField = getThemeColorByField(props.dataItem, themeColorField ?? '');
    return classNames(
      className,
      getThemeColorFont(themeColorByField),
      getThemeColorFont(themeColor),
    );
  }, [className, props.dataItem, themeColor, themeColorField]);

  const styleNew = useMemo(() => {
    if (fieldColor) {
      const color = get(props.dataItem, fieldColor);
      return { ...style, color: color };
    }
    return style;
  }, [fieldColor, props.dataItem, style]);

  return <GridCell className={classNameNew} style={styleNew} {...restProps} />;
};
