import { PageBreadcrumb, PageContent, PageSubHeader, PageTitle } from '@module/common';
import { SettingsLayout } from '@module/private';
import { useTranslation } from 'react-i18next';

import { InterfaceMessageTemplatesGrid } from '../components/InterfaceMessageTemplatesGrid';

export const InterfaceMessageTemplatesOverview = () => {
  const { t } = useTranslation();

  return (
    <SettingsLayout>
      <PageTitle title={t('interfaceMessageTemplates.pages.overview.title')} />
      <PageBreadcrumb
        path={location.pathname}
        text={t(`interfaceMessageTemplates.pages.overview.title`)}
      />
      <PageSubHeader
        title={t('interfaceMessageTemplates.pages.overview.title')}
        description={t('interfaceMessageTemplates.pages.overview.description')}
      />
      <PageContent>
        <InterfaceMessageTemplatesGrid />
      </PageContent>
    </SettingsLayout>
  );
};
