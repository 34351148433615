import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ApiTokensFragment = {
  __typename?: 'ApiTokenList';
  data: Array<{
    __typename?: 'ApiToken';
    uuid: string;
    user_id: string;
    revoked: boolean;
    expires_at: string;
    created_at: string;
  }>;
};

export type ApiTokensQueryVariables = Types.Exact<{ [key: string]: never }>;

export type ApiTokensQuery = {
  __typename?: 'Query';
  apiTokens: {
    __typename?: 'ApiTokenList';
    data: Array<{
      __typename?: 'ApiToken';
      uuid: string;
      user_id: string;
      revoked: boolean;
      expires_at: string;
      created_at: string;
    }>;
  };
};

export const ApiTokensFragmentDoc = gql`
  fragment ApiTokens on ApiTokenList {
    data {
      uuid
      user_id
      revoked
      expires_at
      created_at
    }
  }
`;
export const ApiTokensDocument = gql`
  query ApiTokens {
    apiTokens {
      ...ApiTokens
    }
  }
  ${ApiTokensFragmentDoc}
`;

export function useApiTokensQuery(
  options?: Omit<Urql.UseQueryArgs<ApiTokensQueryVariables>, 'query'>,
) {
  return Urql.useQuery<ApiTokensQuery, ApiTokensQueryVariables>({
    query: ApiTokensDocument,
    ...options,
  });
}
