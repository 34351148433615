import { useMemo } from 'react';

import { ClassificationTagFragment, useClassificationTagsAllQuery } from '../graphql';

export const useClassificationTags = (withApiOnly = false) => {
  const [{ data }] = useClassificationTagsAllQuery();

  return useMemo<ClassificationTagFragment[]>(
    () =>
      (data?.classificationTags.data ?? []).filter(
        (classificationTag) =>
          withApiOnly || (!withApiOnly && classificationTag.api_only === false),
      ),
    [data?.classificationTags.data, withApiOnly],
  );
};
