import { useMemo } from 'react';

import { CountryFragment, useCountriesQuery } from '../graphql';

export interface CountryItem {
  id: string;
  title: string;
}
export function useCountries(countryData?: CountryFragment[], isLoading?: boolean) {
  const [{ data, fetching }] = useCountriesQuery({ pause: !!countryData });
  const countries = useMemo<CountryItem[]>(
    () =>
      [...(countryData ? countryData : data?.countries ?? [])].sort(
        (a: CountryItem, b: CountryItem) => a.title.localeCompare(b.title),
      ),
    [countryData, data?.countries],
  );
  return { countries, isLoading: isLoading || fetching };
}
