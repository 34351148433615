import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type SubformClassificationTagTypeQueryVariables = Types.Exact<{ [key: string]: never }>;

export type SubformClassificationTagTypeQuery = {
  __typename?: 'Query';
  tagTypes: Array<{ __typename?: 'TagType'; id: string; title: string }>;
};

export const SubformClassificationTagTypeDocument = gql`
  query SubformClassificationTagType {
    tagTypes {
      id
      title
    }
  }
`;

export function useSubformClassificationTagTypeQuery(
  options?: Omit<Urql.UseQueryArgs<SubformClassificationTagTypeQueryVariables>, 'query'>,
) {
  return Urql.useQuery<
    SubformClassificationTagTypeQuery,
    SubformClassificationTagTypeQueryVariables
  >({ query: SubformClassificationTagTypeDocument, ...options });
}
