import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ContactRolesQueryVariables = Types.Exact<{ [key: string]: never }>;

export type ContactRolesQuery = {
  __typename?: 'Query';
  contactRoles: Array<{ __typename?: 'ContactRole'; id: string; title: string }>;
};

export const ContactRolesDocument = gql`
  query ContactRoles {
    contactRoles {
      id
      title
    }
  }
`;

export function useContactRolesQuery(
  options?: Omit<Urql.UseQueryArgs<ContactRolesQueryVariables>, 'query'>,
) {
  return Urql.useQuery<ContactRolesQuery, ContactRolesQueryVariables>({
    query: ContactRolesDocument,
    ...options,
  });
}
