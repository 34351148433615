import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
export type InterfaceMessageTemplateCategoryFragment = {
  __typename?: 'InterfaceMessageTemplateCategory';
  id: string;
  title: string;
};

export const InterfaceMessageTemplateCategoryFragmentDoc = gql`
  fragment InterfaceMessageTemplateCategory on InterfaceMessageTemplateCategory {
    id
    title
  }
`;
