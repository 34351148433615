import { FormRenderProps } from '@progress/kendo-react-form';
import { isEqual } from 'lodash';
import { createContext, ReactNode, useCallback, useContext, useMemo, useState } from 'react';

export type FormState = Pick<
  FormRenderProps,
  'errors' | 'valid' | 'touched' | 'visited' | 'modified' | 'submitted' | 'allowSubmit'
>;

export interface FormStateContextType {
  formState: FormState | undefined;
  setFormState: (formState: FormState | undefined) => void;
}

const FormStateContext = createContext<FormStateContextType | undefined>(undefined);

export const useFormStateContext = () => {
  return useContext(FormStateContext);
};

export const useFormState = () => {
  return useContext(FormStateContext)?.formState;
};

interface FormContextConsumerProps {
  children: (formState: FormState | undefined) => ReactNode;
}

interface FormStateProviderProps {
  children: ReactNode;
}

export const FormStateProvider = (props: FormStateProviderProps) => {
  const [formState, setFormStateInternal] = useState<FormState>();

  const setFormState = useCallback(
    (formStateNew: FormState | undefined) => {
      if (!isEqual(formState, formStateNew)) {
        setFormStateInternal(formStateNew);
      }
    },
    [formState],
  );

  const context = useMemo<FormStateContextType>(
    () => ({
      formState,
      setFormState,
    }),
    [formState, setFormState],
  );

  return <FormStateContext.Provider value={context}>{props.children}</FormStateContext.Provider>;
};

export const FormStateConsumer = (props: FormContextConsumerProps) => {
  const formState = useFormState();
  const children = useMemo(() => props.children(formState), [formState, props]);
  return <>{children}</>;
};
