import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ClientsForSubTaskControllerQueryVariables = Types.Exact<{ [key: string]: never }>;

export type ClientsForSubTaskControllerQuery = {
  __typename?: 'Query';
  clientsForSubTaskController: {
    __typename?: 'ClientsForSubTaskControllerList';
    data: Array<{
      __typename?: 'ClientFilterItem';
      title?: string | null;
      client?: {
        __typename?: 'Client';
        id: string;
        name: string;
        job_title?: string | null;
        client_type?: { __typename?: 'ClientType'; title: string; color: string } | null;
      } | null;
      children: Array<{
        __typename?: 'Client';
        id: string;
        name: string;
        job_title?: string | null;
        client_type?: { __typename?: 'ClientType'; title: string; color: string } | null;
      }>;
    }>;
  };
};

export const ClientsForSubTaskControllerDocument = gql`
  query ClientsForSubTaskController {
    clientsForSubTaskController {
      data {
        title
        client {
          id
          name
          job_title
          client_type {
            title
            color
          }
        }
        children {
          id
          name
          job_title
          client_type {
            title
            color
          }
        }
      }
    }
  }
`;

export function useClientsForSubTaskControllerQuery(
  options?: Omit<Urql.UseQueryArgs<ClientsForSubTaskControllerQueryVariables>, 'query'>,
) {
  return Urql.useQuery<ClientsForSubTaskControllerQuery, ClientsForSubTaskControllerQueryVariables>(
    { query: ClientsForSubTaskControllerDocument, ...options },
  );
}
