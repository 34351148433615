import { Annotation, AnnotationsWillChangeReason, Instance, List } from 'pspdfkit';

import { usePsPdfKitEvent } from './usePsPdfKitEvent';

interface UsePsPdfKitAnnotationEventsProps {
  onAnnotationsCreate?: (annotations: List<Annotation>) => void;
  onAnnotationsDelete?: (annotations: List<Annotation>) => void;
  onAnnotationsUpdate?: (annotations: List<Annotation>) => void;
  onAnnotationsChange?: () => void;
  onAnnotationsWillChange?: (event: {
    reason: AnnotationsWillChangeReason;
    annotations: List<Annotation>;
  }) => void;
}

export const usePsPdfKitAnnotationEvents = (
  instance: Instance | null,
  props: UsePsPdfKitAnnotationEventsProps,
) => {
  usePsPdfKitEvent(instance, 'annotations.create', props.onAnnotationsCreate);
  usePsPdfKitEvent(instance, 'annotations.delete', props.onAnnotationsDelete);
  usePsPdfKitEvent(instance, 'annotations.update', props.onAnnotationsUpdate);
  usePsPdfKitEvent(instance, 'annotations.change', props.onAnnotationsChange);
  usePsPdfKitEvent(instance, 'annotations.willChange', props.onAnnotationsWillChange);
};
