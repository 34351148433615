import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type DeleteTaskRoomCategoryMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;

export type DeleteTaskRoomCategoryMutation = {
  __typename?: 'Mutation';
  deleteTaskRoomCategory: { __typename?: 'TaskRoomCategory'; id: string; title: string };
};

export const DeleteTaskRoomCategoryDocument = gql`
  mutation DeleteTaskRoomCategory($id: ID!) {
    deleteTaskRoomCategory(id: $id) {
      id
      title
    }
  }
`;

export function useDeleteTaskRoomCategoryMutation() {
  return Urql.useMutation<DeleteTaskRoomCategoryMutation, DeleteTaskRoomCategoryMutationVariables>(
    DeleteTaskRoomCategoryDocument,
  );
}
