export default {
  openTask: 'Auftrag öffnen',
  editEvent: 'Termin bearbeiten',
  deleteEvent: 'Termin entfernen',
  drive: 'Drive',
  dialogs: {
    deleteEvent: {
      title: 'Termin entfernen',
      note: 'Wollen Sie den Termin wirklich entfernen?',
    },
    subscribeToCalendar: {
      title: 'Kalender abonnieren',
      note: 'Hier können Sie den Kalender auf Ihrem mobilen Endgerät abonnieren.',
      iosGuide: {
        headline: 'Kalender-Synchronisation: Mit iOS-Geräten',
        description:
          'Sie können den Kalender mit der mitgelieferten Kalender-App Ihres iPhones/iPads synchronisieren. Die Einstellungen dazu können Sie automatisch oder manuell vornehmen.',
        stepOne: 'Öffnen Sie die Kalender-App auf Ihrem iPhone/iPad.',
        stepTwo: 'Tippen Sie auf "Andere Kalender" und dann auf "Abonnieren".',
        stepThree: 'Geben Sie die folgende URL ein:',
        clickSave: 'Klicken Sie auf "Speichern". Der Kalender wird nun automatisch aktualisiert.',
      },
      androidGuide: {
        headline: 'Kalender-Synchronisation: Mit Android-Geräten',
        description:
          'Sie können den Kalender mit der mitgelieferten Kalender-App Ihres Android-Geräts synchronisieren.',
        stepOne: 'Öffnen Sie die Kalender-App auf Ihrem Android-Gerät.',
        stepTwo: 'Tippen Sie auf "Kalender hinzufügen" und dann auf "Abonnieren".',
        stepThree: 'Geben Sie die folgende URL ein:',
        clickSave: 'Klicken Sie auf "Speichern". Der Kalender wird nun automatisch aktualisiert.',
      },
      copySuccess: 'Erfolgreich Kopiert',
    },
    schedulerForm: {
      create: {
        title: 'Termin erstellen',
        note: 'Hier können Sie einen neuen Termin eintragen.',
      },
      update: {
        title: 'Termin bearbeiten',
        note: 'Hier können Sie den aktuellen Termin bearbeiten',
      },
    },
  },
  notifications: {
    createSuccess: 'Termin erfolgreich erstellt.',
    createError: 'Beim Erstellen des Termins ist ein Fehler aufgetreten.',
    updateSuccess: 'Termin erfolgreich aktualisiert.',
    updateError: 'Beim Aktualisieren des Termins ist ein Fehler aufgetreten.',
    deleteSuccess: 'Termin erfolgreich entfernt.',
  },
  pages: {
    calendar: {
      title: 'Kalender',
      subscribeCalendar: 'Kalender abonnieren',
      ios: 'iOS',
      android: 'Android',
    },
  },
};
