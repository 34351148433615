import './ScrollView.scss';

import { classNames } from '@progress/kendo-react-common';
import { ReactNode } from 'react';

export type ScrollViewMode = 'auto' | 'hidden' | 'scroll';

export interface ScrollViewProps {
  children?: ReactNode;
  className?: string;
  scrollX?: ScrollViewMode;
  scrollY?: ScrollViewMode;
}

export const ScrollView = (props: ScrollViewProps) => {
  const { scrollX = 'hidden', scrollY = 'hidden' } = props;
  return (
    <div
      id="template-designer-scroll-view"
      className={classNames('scroll-view', props.className, {
        'scroll-x-auto': scrollX === 'auto',
        'scroll-x-hidden': scrollX === 'hidden',
        'scroll-x-scroll': scrollX === 'scroll',
        'scroll-y-auto': scrollY === 'auto',
        'scroll-y-hidden': scrollY === 'hidden',
        'scroll-y-scroll': scrollY === 'scroll',
      })}
    >
      {props.children}
    </div>
  );
};
