import { useMemo } from 'react';

import { useThemeVariables } from '../components/theme/hooks';
import { useMatchMedia } from './useMatchMedia';

export const useMediaQueries = () => {
  const themeVariables = useThemeVariables();

  const smUp = useMatchMedia(`(min-width: ${themeVariables.smBreakpoint})`);
  const mdUp = useMatchMedia(`(min-width: ${themeVariables.mdBreakpoint})`);
  const lgUp = useMatchMedia(`(min-width: ${themeVariables.lgBreakpoint})`);
  const xlUp = useMatchMedia(`(min-width: ${themeVariables.xlBreakpoint})`);
  const xxlUp = useMatchMedia(`(min-width: ${themeVariables.xxlBreakpoint})`);
  return useMemo(() => ({ smUp, mdUp, lgUp, xlUp, xxlUp }), [lgUp, mdUp, smUp, xlUp, xxlUp]);
};
