export default {
  labels: {
    id: 'Id',
    title: 'Titel',
    description: 'Beschreibung',
    unit: 'Einheit',
    price: 'Preis',
    category: 'Kategorie',
    catalog: 'Katalog',
    import: 'Importieren',
    export: 'Exportieren',
    postcodeFactor: 'Ortsfaktor',
    createCatalog: 'Katalog erstellen',
    manageCatalogs: 'Kataloge verwalten',
    color: 'Farbe',
    submit: 'Gewerk speichern',
  },
  components: {
    toolbar: 'Toolbar',
    serviceCatalogCategoryFilter: {
      title: 'Kategorie',
      popoverTitle: 'Zeige nur',
      selectAll: 'Zeige alle',
      deselectAll: 'Alle abwählen',
    },
  },
  dialogs: {
    import: {
      title: 'Leistungsverzeichnis importieren',
      note: 'Hier können Sie alle Leistungen per Excel importieren',
      save: 'Importieren',
      cancel: 'Abbrechen',
      success: 'Katalog wurde erfolgreich importiert.',
    },
    postcodeFactors: {
      edit: {
        title: 'Ortsfaktor',
        note: 'Hier können Sie den Ortsfaktor anpassen.',
      },
    },
    export: {
      postcodeFactors: {
        title: 'Ortsfaktoren herunterladen',
        note: 'Laden Sie die exportierten Ortsfaktoren herunter.',
      },
      catalogItems: {
        title: 'Leistungen herunterladen',
        note: 'Laden Sie die exportierten Leistungen herunter.',
      },
    },
    createCatalog: {
      title: 'Katalog erstellen',
      note: 'Hier können Sie einen neuen Katalog erstellen.',
      save: 'Katalog erstellen',
      success: 'Katalog wurde erfolgreich erstellt.',
    },
    updateCatalogItem: {
      title: 'Leistung bearbeiten',
      note: 'Hier können Sie die Leistung bearbeiten.',
      form: {
        identifier: 'Identifier',
        title: 'Titel',
        description: 'Beschreibung',
        price: 'Preis',
        unit: 'Einheit',
        category: 'Kategorie',
        catalog: 'Katalog',
      },
      success: 'Leistung wurde aktualisiert.',
    },
    manageCatalogs: {
      title: 'Kataloge aktualisieren',
      note: 'Hier können Sie die Kataloge aktualisieren.',
    },
    updateCatalog: {
      title: 'Katalog bearbeiten',
      note: 'Hier können Sie den Katalog bearbeiten.',
    },
  },
  pages: {
    overview: {
      title: 'Leistungen',
      description: 'Hier finden Sie alle Leistungen',
    },
    postcodeFactors: {
      title: 'Ortsfaktoren',
      description: 'Hier finden Sie alle Ortsfaktoren',
    },
    tradesStatus: {
      title: 'Gewerke Status',
      toolbar: {
        createTradesStatus: 'Neues Gewerk',
      },
      dialog: {
        create: {
          title: 'Gewerk erstellen',
          success: 'Gewerk erfolgreich erstellt',
          error: 'Leider ist beim Erstellen des Gewerks ein Fehler aufgetreten',
        },
        edit: {
          title: 'Gewerk bearbeiten',
          success: 'Gewerk erfolgreich bearbeitet',
          error: 'Leider ist beim Speichern des Gewerks ein Fehler aufgetreten',
        },
        delete: {
          title: 'Gewerk löschen?',
          note: 'Willen Sie diesen Eintrag wirklich löschen?',
          success: 'Gewerk erfolgreich gelöscht',
          error: 'Leider ist beim Löschen des Gewerks ein Fehler aufgetreten',
        },
      },
      description:
        'Hier können Sie die Gewerke Status anpassen, welche bei neuen Gewerke zur Gruppierung verwendet werden können.',
    },
  },
};
