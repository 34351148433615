import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import { FileClassificationTagFragmentDoc } from './fileClassificationTagFragment.generated';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type DeleteFileClassificationTagMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;

export type DeleteFileClassificationTagMutation = {
  __typename?: 'Mutation';
  deleteFileClassificationTag?: {
    __typename?: 'FileClassificationTag';
    id: string;
    title: string;
    color: string;
    alias: string;
    type_id: Types.FileClassificationTagTypeId;
  } | null;
};

export const DeleteFileClassificationTagDocument = gql`
  mutation DeleteFileClassificationTag($id: ID!) {
    deleteFileClassificationTag(id: $id) {
      ...FileClassificationTag
    }
  }
  ${FileClassificationTagFragmentDoc}
`;

export function useDeleteFileClassificationTagMutation() {
  return Urql.useMutation<
    DeleteFileClassificationTagMutation,
    DeleteFileClassificationTagMutationVariables
  >(DeleteFileClassificationTagDocument);
}
