import './Label.scss';

import { classNames } from '@progress/kendo-react-common';
import { Label as KendoLabel, LabelProps } from '@progress/kendo-react-labels';
import { Tooltip } from '@progress/kendo-react-tooltip';
import { FC, useEffect } from 'react';

import { useIsFieldRequired } from '../context';
import { useLabelStoreState } from '../context/LabelStore';

interface CustomLabelProps extends LabelProps {
  tooltip?: string;
  tooltipPosition?: 'top' | 'bottom' | 'left' | 'right';
  name?: string;
  label?: string;
  required?: boolean;
  tooltipContent?: FC;
}

// label props is only needed if used in combination with LabelStore
export const Label = (props: CustomLabelProps) => {
  const { name, label, children, tooltip, tooltipPosition, className, tooltipContent, ...rest } =
    props;
  const [, addLabel] = useLabelStoreState();
  const isRequired = useIsFieldRequired(name);

  useEffect(() => {
    if (addLabel && name && label) {
      addLabel(name, label);
    }
  }, [name, addLabel, label]);

  return (
    <KendoLabel {...rest} className={classNames(className, tooltip && 'custom-tooltip')}>
      {children}
      {isRequired && <span className="k-color-error">*</span>}
      {tooltip && (
        // choose default position right, because in most cases there is not enough space
        // and collision detection misplaces the tooltip
        <Tooltip
          anchorElement="target"
          position={tooltipPosition || 'right'}
          content={tooltipContent}
        >
          <span title={tooltip} className="cursor-help l-i-help-circle" />
        </Tooltip>
      )}
    </KendoLabel>
  );
};
