import { useFormBlocker, useFormBlockerConfirmDialog } from '@module/common/components';
import {
  CancelButton,
  Dialog,
  DialogContent,
  DialogHeader,
  DialogHeaderBar,
  DialogHeaderIcon,
} from '@module/layout';
import { DialogProps, useDialogs } from '@module/shared/dialogs';
import { Form, FormContextProvider } from '@module/shared/forms';
import { NotificationType, useNotifications } from '@module/shared/notifications';
import { Button } from '@progress/kendo-react-buttons';
import { DialogActionsBar } from '@progress/kendo-react-dialogs';
import { FormElement } from '@progress/kendo-react-form';
import { isEmpty } from 'lodash';
import { useId } from 'react';
import { useTranslation } from 'react-i18next';

import { InterfaceMessageTemplateFormFields } from '../components/InterfaceMessageTemplateFormFields';
import { useCreateInterfaceMessageTemplateMutation } from '../graphql';
import { InterfaceMessageTemplateFormValues, useInterfaceMessageTemplateSchema } from '../hooks';

export const CreateInterfaceMessageTemplateDialog = (props: DialogProps) => {
  const { t } = useTranslation();
  const { hideDialog } = useDialogs();
  const { showNotification } = useNotifications();
  const { dialogId } = props;
  const { ref, Blocker } = useFormBlocker();

  const handleHideDialog = () => hideDialog(dialogId);

  const formId = useId();

  const [{ fetching }, createTemplate] = useCreateInterfaceMessageTemplateMutation();

  const handleSubmit = async (values: unknown) => {
    const input = values as InterfaceMessageTemplateFormValues;

    const mutationResult = await createTemplate(
      { input },
      { additionalTypenames: ['InterfaceMessageTemplatePaginator'] },
    );
    if (mutationResult.error) {
      showNotification(t('common.errors.generic'), NotificationType.Error);
    } else {
      showNotification(
        t('interfaceMessageTemplates.notifications.templateCreated'),
        NotificationType.Success,
      );
      handleHideDialog();
    }
  };

  const { schema, validator } = useInterfaceMessageTemplateSchema();

  const blockerConfirm = useFormBlockerConfirmDialog(ref, {
    discard: handleHideDialog,
  });

  const handleBlockerCancel = () => {
    if (!isEmpty(ref.current?.modified)) {
      blockerConfirm();
    } else {
      handleHideDialog();
    }
  };

  return (
    <Dialog
      width="fullscreen"
      height="fullscreen"
      onClose={handleBlockerCancel}
      preventCloseOnEscape
    >
      <DialogHeaderBar>
        <DialogHeaderIcon iconClass="l-i-plug u-text-2xl" color="success" />
        <DialogHeader title={t('interfaceMessageTemplates.dialogs.create.title')} />
      </DialogHeaderBar>
      <DialogContent>
        <Form
          ref={ref}
          id={formId}
          schema={schema}
          validator={validator}
          onSubmit={handleSubmit}
          render={(formRenderProps) => (
            <FormContextProvider value={formRenderProps}>
              <FormElement>
                <InterfaceMessageTemplateFormFields />
                <Blocker />
              </FormElement>
            </FormContextProvider>
          )}
        />
      </DialogContent>

      <DialogActionsBar layout="end">
        <CancelButton onClick={handleBlockerCancel} />
        <Button
          type="submit"
          form={formId}
          iconClass="l-i-save"
          size="large"
          themeColor="primary"
          disabled={fetching}
        >
          {t('common.labels.save')}
        </Button>
      </DialogActionsBar>
    </Dialog>
  );
};
