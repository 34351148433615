export default {
  labels: {
    identifier: 'Kennnummer',
    externalNumber: 'Schadennummer',
    date: 'Datum',
    taskId: 'Task Id',
    address: 'Addresse',
    sumNet: 'Netto Summe',
    sumGross: 'Brutto Summe',
    stateId: 'Status',
    client: 'kupac',
    activate: 'Aktiviraj fakturu',
    setPaid: 'Označi kao plaćeno',
    revertPaid: 'Označi kao otvoreno',
    cancelInvoice: 'Otkaži fakturu',
    payDate: 'Plaćeno dalje',
    overdueDate: 'Dospjelo od',
    total: 'Ukupno:',
    note: 'Status opomene',
    reminder: 'Podsjetnik je poslan',
    reminders: 'Pošaljite podsjetnike',
    editNote: 'Uredi status opomene',
    clients: 'Osiguranje',
    exportDatev: 'Izvezi podatke kao CSV (DATEV).',
  },
  pages: {
    overview: {
      title: 'Rechnungen',
      description: 'Hier sehen Sie eine Listen aller Rechnungen.',
    },
  },
  dialogs: {
    delete: {
      title: 'Izbrisati fakturu?',
      description: 'Jeste li sigurni da želite izbrisati fakturu?',
    },
    setPaid: {
      title: 'Označiti fakturu kao plaćenu?',
      description: 'Jeste li sigurni da želite označiti fakturu kao plaćenu?',
    },
    revertPaid: {
      title: 'Označiti fakturu kao otvorenu?',
      description: 'Jeste li sigurni da želite označiti fakturu kao otvorenu?',
    },
    cancelInvoice: {
      title: 'Otkazati fakturu?',
      description: 'Jeste li sigurni da želite poništiti fakturu?',
    },
    payDate: 'Plaćeno dalje',
    overdueDate: 'rok plaćanja',
    invoiceExport: {
      title: 'Izvozne fakture',
      note: 'Preuzmite izvezene fakture.',
    },
    exportDatev: {
      title: 'DATEV izvoz',
      description: 'Želite li izvesti odabranu fakturu u formatu Datev?',
      notifications: {
        success: 'Izvoz uspješan.',
        error: 'Došlo je do pogreške tijekom izvoza.',
      },
    },
    multipleNotes: {
      title: 'Pošaljite podsjetnike',
      description: 'Podsjetnici se šalju pomoću odabranog predloška za sljedeći račun:',
      notifications: {
        success: 'Podsjetnici su uspješno poslani.',
        error: 'Nije moguće poslati podsjetnike.',
      },
      labels: {
        remove: 'Ukloniti',
        template: 'odaberite Predložak',
        send: 'Pošaljite podsjetnike',
        tooltip: 'Uklanja samo fakturu s ovog popisa',
      },
    },
    editNote: {
      title: 'Status opomene',
      note: 'Uredi status opomene',
      reminder: 'Podsjetnik je poslan',
      notifications: {
        error: 'Nažalost, status opomene nije moguće promijeniti.',
        success: 'Status opomene je uspješno promijenjen.',
      },
    },
  },
};
