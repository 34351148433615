import { useAuthLogic } from '@module/auth';
import { useAppSettings } from '@module/common';
import { useEffect, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';

import { Countdown } from './Countdown';

const notificationMs = 60 * 1000; // 60s

const LogoutTimerInner = ({ autoLogoutTimeoutMs }: { autoLogoutTimeoutMs: number }) => {
  const { logout } = useAuthLogic();

  const [countdown, setCountdown] = useState<{ remainingMs: number } | null>(null);

  const { getRemainingTime } = useIdleTimer({
    timeout: autoLogoutTimeoutMs,
    throttle: 1000,
    crossTab: true,
    syncTimers: 1000,
    onIdle: () => {
      logout();
    },
  });

  // Polls for the remaining time to trigger the countdown notification
  useEffect(() => {
    const interval = setInterval(() => {
      const remainingMs = getRemainingTime();

      if (remainingMs <= notificationMs) {
        setCountdown({ remainingMs });
      } else {
        setCountdown(null);
      }
    }, 500);

    return () => {
      clearInterval(interval);
    };
  }, [getRemainingTime]);

  return <div>{countdown && <Countdown remainingMs={countdown.remainingMs} />}</div>;
};

export const LogoutTimer = () => {
  const appSettings = useAppSettings();

  if (!appSettings.auto_logout_timeout) {
    return null;
  }

  return <LogoutTimerInner autoLogoutTimeoutMs={appSettings.auto_logout_timeout * 1000} />;
};
