import './PageBreadcrumb.scss';

import { useMediaQueries } from '@module/layout';
import { classNames } from '@progress/kendo-react-common';
import { Breadcrumb, BreadcrumbLinkMouseEvent } from '@progress/kendo-react-layout';
import { last } from 'lodash';
import { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { v4 as uuid } from 'uuid';

import { DataModel } from '../history/types';
import { PageSlot } from './PageContext';

export interface PageBreadcrumbItem {
  path: string;
  text: string;
  icon?: ReactElement | null;
}

export interface PageBreadcrumbProps {
  path?: string;
  text?: string;
  iconClass?: string;

  /**
   * Specify multiple data items
   */
  data?: PageBreadcrumbItem[];
}

export const PageBreadcrumb = (props: PageBreadcrumbProps) => {
  const { path, text, iconClass, data } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { lgUp, mdUp } = useMediaQueries();

  const breadcrumbItems: DataModel[] = useMemo(() => {
    const homeBreadcrumb: PageBreadcrumbItem = {
      text: t('common.navigation.items.dashboard'),
      icon: <span className="l-i-layout-dashboard k-mr-1" aria-hidden />,
      path: '/dashboard',
    };

    let items: PageBreadcrumbItem[] = [];
    if (path && text) {
      items = [
        homeBreadcrumb,
        {
          path,
          text,
          icon: iconClass ? <span className={classNames(iconClass, 'k-mr-1')} aria-hidden /> : null,
        },
      ];
    } else if (data) {
      items = [homeBreadcrumb, ...data];
    } else {
      items = [homeBreadcrumb];
    }

    return items.map((item) => ({ ...item, id: uuid() }));
  }, [data, iconClass, path, t, text]);

  const handleBreadcrumbItemSelect = (e: BreadcrumbLinkMouseEvent) => {
    const breadcrumbItem = breadcrumbItems.find((item) => item.id === e.id);
    if (breadcrumbItem) {
      navigate(breadcrumbItem.path);
    }
  };

  return (
    <PageSlot slot="left">
      <div className="breadcrumbs">
        {mdUp && lgUp && (
          <Breadcrumb data={breadcrumbItems} onItemSelect={handleBreadcrumbItemSelect} />
        )}
        {mdUp && !lgUp && (
          <Breadcrumb
            data={breadcrumbItems.slice(Math.max(breadcrumbItems.length - 2, 1))}
            onItemSelect={handleBreadcrumbItemSelect}
          />
        )}
        {!mdUp && !lgUp && <div className="k-text-ellipsis">{last(breadcrumbItems)?.text}</div>}
      </div>
    </PageSlot>
  );
};
