import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ReorderTaskFormsMutationVariables = Types.Exact<{
  task_id: Types.Scalars['ID'];
  task_form_ids: Array<Types.Scalars['ID']> | Types.Scalars['ID'];
}>;

export type ReorderTaskFormsMutation = {
  __typename?: 'Mutation';
  reorderTaskForms: {
    __typename?: 'ResponseMessage';
    success?: boolean | null;
    message?: string | null;
  };
};

export const ReorderTaskFormsDocument = gql`
  mutation ReorderTaskForms($task_id: ID!, $task_form_ids: [ID!]!) {
    reorderTaskForms(task_id: $task_id, task_form_ids: $task_form_ids) {
      success
      message
    }
  }
`;

export function useReorderTaskFormsMutation() {
  return Urql.useMutation<ReorderTaskFormsMutation, ReorderTaskFormsMutationVariables>(
    ReorderTaskFormsDocument,
  );
}
