import { useInternationalization } from '@progress/kendo-react-intl';
import { useCallback } from 'react';

export const useFormatCurrency = () => {
  const intl = useInternationalization();

  return useCallback(
    (value: number, currency = 'EUR') => intl.formatNumber(value, { style: 'currency', currency }),
    [intl],
  );
};
