import { isString } from '@module/shared/helpers';
import noop from 'lodash/noop';
import { ReactNode, useCallback, useState } from 'react';

import { AuthState } from '../graphql';
import { AuthContext } from '../hooks';

const KEY_AUTH_STATE = 'authstate';
const KEY_AUTH_STATE_PREVIOUS = 'authstateprev';

const getAuthState = () => {
  const authStateJson = localStorage.getItem(KEY_AUTH_STATE);
  if (!isString(authStateJson)) {
    return null;
  }

  try {
    return JSON.parse(authStateJson) as AuthState;
  } finally {
    noop();
  }
  return null;
};

function saveAuthState(authState: AuthState | null) {
  if (authState) {
    localStorage.setItem(KEY_AUTH_STATE, JSON.stringify(authState));
  } else {
    clearAuthState();
  }
}

function clearAuthState() {
  localStorage.removeItem(KEY_AUTH_STATE);
  localStorage.removeItem(KEY_AUTH_STATE_PREVIOUS);
}

const getAuthStatePrevious = () => {
  const authStateJson = localStorage.getItem(KEY_AUTH_STATE_PREVIOUS);
  if (!isString(authStateJson)) {
    return null;
  }

  try {
    return JSON.parse(authStateJson) as AuthState;
  } finally {
    noop();
  }
  return null;
};

function saveAuthStatePrevious(authState: AuthState | null) {
  if (authState) {
    localStorage.setItem(KEY_AUTH_STATE_PREVIOUS, JSON.stringify(authState));
  } else {
    clearAuthState();
  }
}

function clearAuthStatePrevious() {
  localStorage.removeItem(KEY_AUTH_STATE_PREVIOUS);
}

export interface AuthProviderProps {
  children?: ReactNode | undefined;
}

export const AuthProvider = (props: AuthProviderProps) => {
  const [authState, setAuthState] = useState(getAuthState);
  const [authStatePrevious, setAuthStatePrevious] = useState(getAuthStatePrevious);

  const _clearAuthState = useCallback(() => {
    setAuthState(null);
    clearAuthState();
  }, []);

  const _saveAuthState = useCallback((authState: AuthState | null) => {
    setAuthState(authState);
    saveAuthState(authState);
  }, []);

  const _clearAuthStatePrevious = useCallback(() => {
    setAuthStatePrevious(null);
    clearAuthStatePrevious();
  }, []);

  const _saveAuthStatePrevious = useCallback((authState: AuthState | null) => {
    setAuthStatePrevious(authState);
    saveAuthStatePrevious(authState);
  }, []);

  return (
    <AuthContext.Provider
      value={{
        authState,
        clearAuthState: _clearAuthState,
        getAuthState,
        saveAuthState: _saveAuthState,
        authStatePrevious,
        clearAuthStatePrevious: _clearAuthStatePrevious,
        getAuthStatePrevious,
        saveAuthStatePrevious: _saveAuthStatePrevious,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};
