import { CombinedError } from '@urql/core';
import { get } from 'lodash';

export function isGraphQLError(error?: CombinedError | undefined, code?: number | undefined) {
  return error && code && code === get(error, 'graphQLErrors[0].extensions.code');
}

export function isGraphQLErrorWithBase(
  error?: CombinedError | undefined,
  base?: number | undefined,
) {
  const code = getGraphQLErrorCode(error);

  return code && base && code.toString().substring(0, 6) === base.toString().substring(0, 6);
}

export function getGraphQLErrorMessage(error?: CombinedError | undefined): string | undefined {
  return get(error, 'graphQLErrors[0].message');
}

export function getGraphQLErrorCode(error?: CombinedError | undefined): number | undefined {
  return get(error, 'graphQLErrors[0].extensions.code');
}
