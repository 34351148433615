import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type AppAllowlistIpAddressesQueryVariables = Types.Exact<{ [key: string]: never }>;

export type AppAllowlistIpAddressesQuery = {
  __typename?: 'Query';
  appAllowlistIpAddresses: Array<{
    __typename?: 'AppAllowlistIpAddress';
    ip: string;
    description?: string | null;
    creator_ip?: string | null;
    can_be_deleted: boolean;
    updated_at: string;
    created_at: string;
    is_current_ip: boolean;
    creator?: { __typename?: 'Client'; name: string } | null;
  }>;
};

export const AppAllowlistIpAddressesDocument = gql`
  query AppAllowlistIpAddresses {
    appAllowlistIpAddresses {
      ip
      description
      creator_ip
      can_be_deleted
      updated_at
      creator {
        name
      }
      created_at
      is_current_ip
    }
  }
`;

export function useAppAllowlistIpAddressesQuery(
  options?: Omit<Urql.UseQueryArgs<AppAllowlistIpAddressesQueryVariables>, 'query'>,
) {
  return Urql.useQuery<AppAllowlistIpAddressesQuery, AppAllowlistIpAddressesQueryVariables>({
    query: AppAllowlistIpAddressesDocument,
    ...options,
  });
}
