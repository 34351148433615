import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type UpdateTaskFormQuestionMutationVariables = Types.Exact<{
  task_form_id: Types.Scalars['ID'];
  input: Types.TaskFormQuestionInput;
}>;

export type UpdateTaskFormQuestionMutation = {
  __typename?: 'Mutation';
  updateTaskFormQuestions: Array<{ __typename?: 'TaskQuestion'; question_id: string } | null>;
};

export const UpdateTaskFormQuestionDocument = gql`
  mutation UpdateTaskFormQuestion($task_form_id: ID!, $input: TaskFormQuestionInput!) {
    updateTaskFormQuestions(task_form_id: $task_form_id, questions: [$input]) {
      question_id
    }
  }
`;

export function useUpdateTaskFormQuestionMutation() {
  return Urql.useMutation<UpdateTaskFormQuestionMutation, UpdateTaskFormQuestionMutationVariables>(
    UpdateTaskFormQuestionDocument,
  );
}
