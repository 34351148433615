import { useFormatCurrency } from '@module/shared/localization';
import { GridCellProps } from '@progress/kendo-react-grid';

import { useTdElementProps } from '../../hooks';

export const CurrencyCell = (props: GridCellProps) => {
  const tdElementProps = useTdElementProps(props);
  const { field, dataItem } = props;
  const formatCurrency = useFormatCurrency();

  if (!field) return <td />;

  return <td {...tdElementProps}>{formatCurrency(dataItem[field])}</td>;
};
