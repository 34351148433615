import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ClientAdditionalInsuranceQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;

export type ClientAdditionalInsuranceQuery = {
  __typename?: 'Query';
  client?: {
    __typename?: 'Client';
    client_additional_insurance?: {
      __typename?: 'ClientAdditionalInsurance';
      damage_number_mask?: string | null;
      term_of_payment?: number | null;
      tasks_statistic_export_columns?: Array<string> | null;
    } | null;
  } | null;
};

export const ClientAdditionalInsuranceDocument = gql`
  query ClientAdditionalInsurance($id: ID!) {
    client(id: $id) {
      client_additional_insurance {
        damage_number_mask
        term_of_payment
        tasks_statistic_export_columns
      }
    }
  }
`;

export function useClientAdditionalInsuranceQuery(
  options: Omit<Urql.UseQueryArgs<ClientAdditionalInsuranceQueryVariables>, 'query'>,
) {
  return Urql.useQuery<ClientAdditionalInsuranceQuery, ClientAdditionalInsuranceQueryVariables>({
    query: ClientAdditionalInsuranceDocument,
    ...options,
  });
}
