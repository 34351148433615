import './VerificationInput.scss';

import ReactVerificationInput, { VerificationInputProps } from 'react-verification-input';

export const VerificationInput = (props: VerificationInputProps) => {
  return (
    <ReactVerificationInput
      {...props}
      classNames={{
        container: 'VerificationInput',
        character: 'VerificationInput__character',
        characterInactive: 'VerificationInput__character--inactive',
        characterSelected: 'VerificationInput__character--selected',
      }}
    />
  );
};
