export default {
  events: {
    created: 'Erstellt',
    updated: 'Editiert',
    deleted: 'Gelöscht',
    login: 'Login',
  },
  pages: {
    activities: {
      title: 'Aktivitäten',
      description: 'Hier finden Sie eine Übersicht über alle Aktivitäten.',
      showChanges: 'Änderungen anzeigen',
      dialogs: {
        changes: {
          title: 'Änderungen',
          description: 'Die folgenden Änderungen wurden vorgenommen:',
          oldValue: 'Alter Wert',
          newValue: 'Neuer Wert',
        },
      },
      grid: {
        system: 'SYSTEM',
        columns: {
          createdAt: 'Datum',
          email: 'Von',
          logName: 'Identifier',
          event: 'Typ',
          description: 'Beschreibung',
          changes: 'Details',
        },
      },
    },
  },
};
