import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type DeclineTaskMutationVariables = Types.Exact<{
  input: Types.TaskDeclineReasonInput;
}>;

export type DeclineTaskMutation = {
  __typename?: 'Mutation';
  declineTask?: {
    __typename?: 'ResponseMessage';
    success?: boolean | null;
    message?: string | null;
  } | null;
};

export const DeclineTaskDocument = gql`
  mutation DeclineTask($input: TaskDeclineReasonInput!) {
    declineTask(input: $input) {
      success
      message
    }
  }
`;

export function useDeclineTaskMutation() {
  return Urql.useMutation<DeclineTaskMutation, DeclineTaskMutationVariables>(DeclineTaskDocument);
}
