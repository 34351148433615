export default {
  labels: {
    client_leader: 'Abteilung',
  },
  dialogs: {
    update: {
      note: 'Hier können Sie die Experten und Abteilung für das Zuweisungsgebiet einstellen.',
    },
  },
};
