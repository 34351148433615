/* eslint-disable @typescript-eslint/no-non-null-assertion */

declare global {
  interface Window {
    CFG_VERSION: string;
    CFG_LOCALE: string;
    CFG_LOCALE_OVERRIDE: string;
    CFG_API_URL: string;

    CFG_GRAPHQL_API_URL: string;
    CFG_GRAPHQL_SUBSCRIPTIONS_AUTH_URL: string;

    CFG_PUSHER_APP_KEY: string;
    CFG_PUSHER_APP_CLUSTER: string;
    CFG_PUSHER_HOST: string;
    CFG_PUSHER_PORT: string;

    CFG_PSPDFKIT_LICENSE_KEY: string;
    CFG_MAPBOX_ACCESS_TOKEN: string;
    CFG_SENTRY_DSN: string;
    CFG_ROUTER_BASENAME: string;
    CFG_MAX_FILE_SIZE: string;
    CFG_SENTRY_SAMPLE_RATE: string;
    CFG_LOG_GRAPHQL_ERRORS: string;
  }
}

export const APP_VERSION = window.CFG_VERSION;
export const LOCALE = window.CFG_LOCALE;
export const LOCALE_OVERRIDE = window.CFG_LOCALE_OVERRIDE;
export const API_URL = window.CFG_API_URL;

export const GRAPHQL_API_URL = window.CFG_GRAPHQL_API_URL;
export const GRAPHQL_SUBSCRIPTIONS_AUTH_URL = window.CFG_GRAPHQL_SUBSCRIPTIONS_AUTH_URL;

export const PUSHER_APP_KEY = window.CFG_PUSHER_APP_KEY;
export const PUSHER_APP_CLUSTER = window.CFG_PUSHER_APP_CLUSTER;
export const PUSHER_HOST = window.CFG_PUSHER_HOST;
export const PUSHER_PORT = parseInt(window.CFG_PUSHER_PORT);

export const PSPDFKIT_LICENSE_KEY = window.CFG_PSPDFKIT_LICENSE_KEY;
export const MAPBOX_ACCESS_TOKEN = window.CFG_MAPBOX_ACCESS_TOKEN;
export const ROUTER_BASENAME = window.CFG_ROUTER_BASENAME;
export const SENTRY_DSN = window.CFG_SENTRY_DSN;
export const MAX_FILE_SIZE = parseInt(window.CFG_MAX_FILE_SIZE);
export const SENTRY_SAMPLE_RATE = parseFloat(window.CFG_SENTRY_SAMPLE_RATE ?? '1');
export const LOG_GRAPHQL_ERRORS = window.CFG_LOG_GRAPHQL_ERRORS === 'true' ? true : false;
