import './FormGroupVertical.scss';

import { classNames } from '@progress/kendo-react-common';
import { StackLayout, StackLayoutProps } from '@progress/kendo-react-layout';

export type FormGroupVerticalProps = Omit<StackLayoutProps, 'orientation'>;

export const FormGroupVertical = (props: FormGroupVerticalProps) => {
  const { className, gap = 4, ...rest } = props;
  return (
    <StackLayout
      className={classNames('FormGroupVertical', className)}
      orientation="vertical"
      gap={gap}
      {...rest}
    />
  );
};
