export default {
  tabs: {
    common: 'Podaci o zahtjevu',
    todos: 'Zahtjevi',
    form: 'Formular',
    images: 'Slike',
    documents: 'Dokumenti',
    emails: 'E-Pošta',
    files: 'Datoteke',
    calculation: 'Kalkulacija',
    report: 'Izvještaj',
    appointment: 'Termin',
    roomItems: 'Plan sobe',
    invoices: 'Račun',
    statistics: 'Statistika',
    logs: 'Log',
    customerPortal: 'Korisnički portal',
    additionalForms: 'podobrasci',
    subforms: 'podobrasci',
  },
  statistic: {
    dialogs: {
      exportTask: {
        title: 'Preuzmi statistiku',
        note: 'Preuzimanje izvezenog zahtjeva i njegove statistike',
      },
    },
  },
  templateForm: {
    dialogs: {
      exportTask: {
        title: 'Preuzmi obrazac',
        note: 'Preuzimanje izvezenog zahtjeva i obrasca',
      },
      spellCheck: {
        title: 'Provjera pravopisa',
        note: 'Ovdje možete izvršiti provjeru pravopisa',
      },
    },
    spellCheck: 'Provjera pravopisa',
    mandatoryFieldsIndicator: {
      incompleteLabel: 'Obavezna polja {{completedCount}}/{{totalCount}}',
      completeLabel: 'Obavezna polja',
    },
  },
  customerPortal: {
    messages: {
      created: 'Sastanak je uspješno kreiran',
      updated: 'Sastanak je uspješno ažuriran',
    },
    files: {
      dialogs: {
        documents: {
          title: 'Odabir dokumenata',
          description: 'Dodavanje dokumenata u sastanak',
        },
      },
      files: {
        title: 'Slike',
        teaser:
          'Ovdje ćete pronaći slike koje je korisnik prenio putem svoje vez. Zatim ih možete prenijeti u svoj zahtjev.',
        labels: {
          accept: 'Slike prihvaćene',
          decline: 'Slike odbijene',
        },
        grid: {
          columns: {
            file: 'Slike',
          },
        },
      },
      documents: {
        title: 'Dokumenti',
        teaser:
          'Ovdje korisniku možete dostaviti dokumente za pregled ili potpis putem njegove korisničke veze.',
        labels: {
          choose: 'Dokumenti odabrani',
          accept: 'Dokumenti prihvaćeni',
          decline: 'Dokumenti odbijeni',
        },
        grid: {
          columns: {
            original: 'Original',
            modified: 'Ažuriran',
            noThumbnail: 'Bez sličica',
          },
        },
      },
    },
    detail: {
      notFound: 'Sastanak nije pronađen.',
      data: {
        title: 'Sastanak',
      },
      buttons: {
        join: {
          label: 'Uključivanje u sastanak',
          inFuture: 'Sastanak započinje u: {{ duration }}',
          isNow: 'Sastanak je sada',
          inPast: 'Sastanak je završio',
        },
        copy: 'Kopiranje poveznice',
        sendSMS: 'Pošalji SMS',
        sendEmail: 'Pošalji E-poštu',
      },
      messages: {
        sendSMS: 'SMS je poslan.',
        sendEmail: 'E-pošta je poslana.',
      },
      labels: {
        from: 'Start',
        to: 'Kraj',
        contactPersonEmail: 'Kontakt E-pošta',
        contactPersonName: 'Kontakt',
        contactPersonPhone: 'Kontakt Telefon',
        customerEmail: 'E-pošta korisnika',
        customerName: 'Naziv primarnog korisnika',
        customerPhone: 'Telefon korisnika',
        roomIdentifier: 'Prostor',
        allowCustomerUpload: 'Dopušten prijenos od strane korisnika',
      },
    },
    grid: {
      toolbar: {
        create: 'Kreiranje sastanka',
      },
      noDate: 'Nema termina',
      from: 'Termin',
      identifier: 'Prostor',
      customerName: 'Korisnik',
      type: 'Vrsta zahtjeva',
      sendSms: 'SMS poslan',
      sendEmail: 'E-pošta poslana',
      hasPosition: 'Pozicija dostupna',
      customerPhone: 'Telefon korisnika',
      customerEmail: 'E-Pošta korisnika',
      contactPersonName: 'Kontakt',
    },
    linkInfoDialog: {
      title: 'Veza je kopirana u međuspremnik',
      note: 'Imajte na umu da su poveznice namijenjene samo za slanje kupcu.',
      confirm: 'Razumijem',
    },
  },
  roomPlaner: {
    grid: {
      title: 'Naslov',
      description: 'Opis',
      size: 'Veličina',
      length: 'Duljina',
      width: 'Širina',
      height: 'Visina',
      sum: 'Ukupno',
      selectedServices: 'Odabrane usluge: ',
      toolbar: {
        create: 'Novi prostor',
        createCategory: 'Nova kategorija',
        settings: {
          transferData: 'Prijenos podataka u kalkulaciju',
          syncMagicplan: 'Sinkronizirajte Magicplan',
          exportJson: 'Izvoz podataka kao JSON',
        },
      },
    },
    dialogs: {
      create: {
        title: 'Stvori unos',
        note: 'Ovdje možete stvoriti dodatne sobe.',
        labels: {
          fieldSet: 'Stvori stavku',
          title: 'Naslov',
          description: 'Opis',
          size: 'Veličina u m²',
          category: 'Kategorija',
          length: 'Dužina u m',
          width: 'Širina u m',
          height: 'Visina u m',
          serviceCatalogItems: 'Stavke usluga',
        },
      },
      update: {
        title: 'Uredi stavku',
        note: 'Ovdje možete urediti trenutnu sobu.',
      },
      delete: {
        title: 'Izbriši unos',
        message: 'Jeste li sigurni da želite izbrisati ovaj unos??',
      },
      transferData: {
        title: 'Prijenos podataka o sobi',
        description:
          'To vam omogućuje prijenos prostornih podataka izravno kao kategorija u kalkulaciju.',
        useServiceCatalogCategories: 'Preuzimanje rezerviranih stavki kataloga usluga',
      },
    },
  },
  appointment: {
    create: 'Termin',
  },
  noteDialog: {
    title: 'Napomena za "{{title}}"',
    note: 'Unesite bilo koju tekstualnu bilješku za odabrano pitanje.',
    buttonLabel: 'Bilješka',
    startRecording: 'započeti snimanje',
    stopRecording: 'zaustaviti snimanje',
  },
  subTaskDialog: {
    title: {
      label: 'stvoriti podzadatak',
      title: 'titula',
      description: 'Opis',
      finishDateDefaultDays: 'Rok (u danima)',
    },
    note: 'Ovdje možete stvoriti dodatne podzahtjeve.',
    due: '({{ date }})',
    today: 'Danas',
    editTitle: 'Uređivanje zahtjeva',
    editNote: 'Ovdje možete urediti podzahtjev.',
    update: 'Spremi',
    edit: {
      success: 'Uspješno spremljeno',
      error: 'Zahtjev nije spremljen!',
    },
    add3days: '3 dana',
    add7days: '7 dana',
    add14days: '14 dana',
    file: {
      label: 'Povezana datoteka',
      selectFile: 'datoteka veze',
    },
    add1day: '1 dan',
  },
  subTaskGroup: {
    new: 'Noi',
    task: 'Zahtjev',
    created_at: 'Kreirano na',
    title: 'Naslov',
    description: 'Opis',
    controller: 'Kreirao',
    actions: 'Akcije',
    inProgress: 'U procesu',
    completed: 'Završen',
    status: 'Status',
    assignedUser: 'Dodijeljeno',
    userAssignment: 'Zahtjev',
    create: 'Kreiran',
    search: 'Traži',
    taskId: 'ID zahtjeva',
    undone: 'Označi kao otvoreno',
    done: 'Označi kao dovršeno',
    edit: 'Uređen',
    toDo: 'Otvori zahtjev',
    review: 'Označi za pregled',
    reopen: 'Natrag na otvaranje',
    delete: 'Izbrisati',
  },
  logs: {
    list: {
      header: {
        date: 'Datum i vrijeme',
        from: 'od',
        message: 'Poruka',
        state: 'Status',
      },
    },
    dialogs: {
      create: {
        title: 'Kreiranje poruke',
        note: 'Ovdje možete kreirati nove poruke.',
        labels: {
          title: 'Poruka',
        },
      },
    },
  },
  errors: {
    not_found: {
      title: 'Zahtjev nije pronađen',
      note: 'Zahtjev koji ste tražili nije pronađen',
    },
    not_activated: {
      title: 'Narudžba nije aktivirana',
      note:
        'Narudžba je kreirana i dodijeljena vama.\n' +
        'Administrator još nije odobrio narudžbu.\n' +
        'Čim ovo bude dostupno, možete pogledati narudžbu.',
    },
  },
  contextMenu: {
    title: 'Proširen',
    copyFormData: 'Kopiraj iz drugog zahtjeva',
    changeFormData: 'Promjena obrasca',
    createMessage: 'Kreiraj poruku',
    changeTemplate: 'promijeniti oblik',
    createTaskLog: 'Napravite unos',
    compareFormData: 'Usporedite podatke obrasca s drugom narudžbom',
  },
  jumpMenu: {
    title: 'Skoči na',
  },
  copyFormDataDialog: {
    title: 'Kopiranje podataka obrasca',
    submit: 'Kopija',
    warning: 'Pažnja, to će prebrisati sve podatke iz obrasca u zahtjevu. Želite li nastaviti?',
    labels: {
      from: 'Iz zahtjeva',
    },
    preview: 'Pogledajte trenutne podatke obrasca u novoj kartici',
  },
  copyQuestionDialog: {
    buttonLabel: 'Kopiraj pitanja',
    title: 'Kopiraj pitanja',
    submit: 'Kopija',
    labels: {
      template: 'Predložak',
    },
  },
  createSatelliteImageDialog: {
    title: 'Generiranje satelitske slike',
    description:
      'Ovdje možete stvoriti satelitsku fotografiju adrese. Također možete nacrtati elemente na njoj.',
    reload: 'Osvježi',
    success: 'Satelitska slika je napravljena.',
  },
  satelliteImageDescription: {
    titleRow: 'Satelitska slika {{address}}',
    areaRow: 'Objekt {{num}}: {{area}}',
  },
  emails: {
    downloadAllAttachments: 'Preuzimanje ({{count}}) priloga',
    to: 'To: ',
    cc: 'CC:',
    bcc: 'Bcc:',
    from: 'Iz:',
    state: {
      scheduled: 'bit će poslano...',
      delivered: 'Objavljeno',
      failed: 'neuspjeh.',
    },
  },
  subTaskFileDialog: {
    title: 'datoteka veze',
    note: 'Povežite datoteku s ovim zadatkom.',
    submit: 'Odabir veze',
  },
  satelliteImageAddressDialog: {
    title: 'ažurirati adresu',
    submit: 'Ažurirati',
    geolocateError: 'Za ovu adresu nisu pronađene koordinate.',
  },
  sortImagesDialog: {
    title: 'Poredaj slike',
  },
  sortDocumentsDialog: {
    title: 'Sortiraj dokumente',
  },
};
