import {
  useCalendarEventAbility,
  useClientAbility,
  useInvoiceAbility,
  usePolicyHolderAbility,
  useTaskAbility,
  useTaskProposalAbility,
  useTaskSubTaskAbility,
  useUserAbility,
} from '@module/casl';
import { usePayoutsAbility } from '@module/casl/abilities/PayoutAbility';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { PathPattern } from 'react-router';
import { matchPath, useLocation } from 'react-router-dom';

import { DrawerData } from '../components';

export function useDrawerData() {
  const { t } = useTranslation();

  const { pathname } = useLocation();

  const matchesPath = useCallback(
    (pattern: string | PathPattern<string>) => Boolean(matchPath(pattern, pathname)),
    [pathname],
  );

  const matchesSomePath = useCallback(
    (patterns: Array<string | PathPattern<string>>) => patterns.some(matchesPath),
    [matchesPath],
  );

  const [calendarEventAbility, calendarEventSubject] = useCalendarEventAbility();
  const [clientAbility, clientSubject] = useClientAbility();
  const [userAbility, userSubject] = useUserAbility();
  const [taskAbility, taskSubject] = useTaskAbility();
  const [subTaskAbility, subTaskSubject] = useTaskSubTaskAbility();
  const [invoiceAbility, invoiceSubject] = useInvoiceAbility();
  const [proposalAbility, proposalSubject] = useTaskProposalAbility();
  const [payoutsAbility, payoutsSubject] = usePayoutsAbility();
  const [policyHolderAbility, policyHolderSubject] = usePolicyHolderAbility();

  return useMemo<DrawerData>(() => {
    return {
      sections: [
        {
          items: [
            {
              icon: 'l-i-layout-dashboard',
              text: t('common.navigation.items.dashboard'),
              route: '/dashboard',
              isActive: matchesPath('/dashboard/*'),
              show: taskAbility.can('viewTasks', taskSubject()),
            },
          ].filter((item) => item.show),
        },
        {
          items: [
            {
              icon: 'l-i-contact',
              text: t('common.navigation.items.clients'),
              route: '/clients',
              isActive: matchesPath('/clients/*'),
              show: clientAbility.can('viewClients', clientSubject()),
            },
          ].filter((item) => item.show),
        },
        {
          items: [
            {
              icon: 'l-i-user',
              text: t('common.navigation.items.policyHolders'),
              route: '/policy-holders',
              isActive: matchesPath('/policy-holders/*'),
              show: policyHolderAbility.can('indexPolicyHolder', policyHolderSubject()),
            },
          ].filter((item) => item.show),
        },
        {
          items: [
            {
              icon: 'l-i-clipboard-list',
              text: t('common.navigation.items.tasks'),
              route: '/tasks',
              isActive: matchesPath('/tasks/*') && !matchesPath('/tasks/create/*'),
              show: taskAbility.can('viewTasks', taskSubject()),
            },
            {
              icon: 'l-i-plus-square',
              text: t('common.navigation.items.newTask'),
              route: '/tasks/create',
              isActive: matchesPath('/tasks/create/*'),
              show: taskAbility.can('createTask', taskSubject()),
            },
          ].filter((item) => item.show),
        },
        {
          items: [
            {
              icon: 'l-i-list-checks',
              text: t('common.navigation.items.myTasks'),
              route: '/sub-tasks',
              isActive: matchesPath('/sub-tasks/*'),
              show: subTaskAbility.can('viewTaskSubTasks', subTaskSubject()),
            },
            {
              icon: 'l-i-calendar',
              text: t('common.navigation.items.calendar'),
              route: '/events/calendar',
              isActive: matchesPath('/events/calendar/*'),
              show: calendarEventAbility.can('viewEvents', calendarEventSubject()),
            },
            {
              icon: 'l-i-banknote',
              text: t('common.navigation.items.invoices'),
              route: '/invoices',
              isActive: matchesPath('/invoices/*'),
              show: invoiceAbility.can('viewInvoices', invoiceSubject()),
            },
            {
              icon: 'l-i-scroll-text',
              text: t('common.navigation.items.proposals'),
              route: '/proposals',
              isActive: matchesPath('/proposals/*'),
              show: proposalAbility.can('viewProposals', proposalSubject()),
            },
            {
              icon: 'l-i-coins',
              text: t('common.navigation.items.payouts'),
              route: '/payouts',
              isActive: matchesPath('/payouts/*'),
              show: payoutsAbility.can('indexPayouts', payoutsSubject()),
            },
          ].filter((item) => item.show),
        },
        {
          items: [
            {
              icon: 'l-i-settings-2',
              text: t('common.navigation.sections.settings'),
              route: '/settings/overview',
              isActive: matchesSomePath(['/settings/*']),
              show: userAbility.can('isAdmin', userSubject()),
            },
          ].filter((item) => item.show),
        },
      ].filter((section) => section.items.length),
    };
  }, [
    t,
    matchesPath,
    taskAbility,
    taskSubject,
    clientAbility,
    clientSubject,
    policyHolderAbility,
    policyHolderSubject,
    subTaskAbility,
    subTaskSubject,
    calendarEventAbility,
    calendarEventSubject,
    invoiceAbility,
    invoiceSubject,
    proposalAbility,
    proposalSubject,
    payoutsAbility,
    payoutsSubject,
    matchesSomePath,
    userAbility,
    userSubject,
  ]);
}
