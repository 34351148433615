import './MaskedTextInput.scss';

import { FieldWrapper } from '@progress/kendo-react-form';
import {
  MaskedTextBox,
  MaskedTextBoxChangeEvent,
  MaskedTextBoxProps,
} from '@progress/kendo-react-inputs';
import { useId, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Label } from '../components/Label';
import { FieldError } from './FieldError';
import { FieldHint } from './FieldHint';
import { CommonFieldRenderProps } from './types';

export const MaskInputRuleList = () => {
  const { t } = useTranslation();
  const translationKeys = [
    'mask_0',
    'mask_9',
    'mask_#',
    'mask_L',
    'mask_?',
    'mask_A',
    'mask_a',
    'mask_&',
    'mask_C',
  ];
  return (
    <ul className="k-my-2 k-pl-5">
      {translationKeys.map((key) => (
        <li key={key}>
          {
            <Trans
              i18nKey={`common.components.maskedTextInput.info.${key}`}
              t={t}
              components={{ bold: <b /> }}
            />
          }
        </li>
      ))}
    </ul>
  );
};

export const MaskInputInfo = () => {
  const { t } = useTranslation();

  return (
    <div className="MaskInputInfo u-text-sm">
      <div className="k-display-flex k-align-items-center k-gap-2">
        <span className="l-i-info u-text-xl" />
        <h3 className="k-my-1">{t('common.components.maskedTextInput.info.title')}</h3>
      </div>
      <MaskInputRuleList />
      <p className="!k-m-0">
        <Trans
          i18nKey="common.components.maskedTextInput.info.escape"
          t={t}
          components={{ bold: <b /> }}
        />
      </p>
    </div>
  );
};

export const MaskedTextBoxDefaultTooltip = () => (
  <div className="u-text-sm" style={{ maxWidth: 450, margin: 0 }}>
    <MaskInputRuleList />
  </div>
);

export type MaskedTextInputProps = CommonFieldRenderProps &
  Pick<
    MaskedTextBoxProps,
    | 'id'
    | 'value'
    | 'placeholder'
    | 'required'
    | 'disabled'
    | 'onBlur'
    | 'onChange'
    | 'onFocus'
    | 'label'
    | 'mask'
    | 'rules'
  > & {
    size?: null | 'small' | 'medium' | 'large';
    hintClass?: string;
    maskValidationMessage?: string;
    ariaLabelledBy?: string;
  };

export const MaskedTextInput = (props: MaskedTextInputProps) => {
  const { t } = useTranslation();
  const defaultId = useId();
  const {
    size = 'large',
    value,
    id = defaultId,
    label,
    placeholder,
    valid,
    required,
    disabled,
    onBlur,
    onChange,
    onFocus,
    name,
    tooltip,
    tooltipPosition,
    mask,
    rules,
    ariaLabelledBy,
    maskValidationMessage = t('common.components.maskedTextInput.maskValidationMessage', {
      mask,
    }),
    validationMessage,
    tooltipContent = MaskedTextBoxDefaultTooltip,
  } = props;

  // we need a tooltip defined in order to show the tooltip. As we have a default tooltipContent that
  // should show up when we have no tooltip defined, we need to set a default tooltip value
  const tooltipValue = !tooltip && !props.tooltipContent ? 'default' : tooltip;

  const [internalValid, setInternalValid] = useState(true);

  const handleChange = (e: MaskedTextBoxChangeEvent) => {
    setInternalValid(e.target.validity.valid);
    onChange?.(e);
  };

  return (
    <FieldWrapper className="TextInput">
      {label && (
        <Label
          tooltip={tooltipValue}
          tooltipPosition={tooltipPosition}
          tooltipContent={tooltipContent}
          label={label}
          editorId={id}
          editorValid={valid}
          name={name}
        >
          {label};
        </Label>
      )}

      <MaskedTextBox
        id={id}
        value={value}
        size={size}
        mask={mask}
        rules={rules}
        placeholder={placeholder}
        required={required}
        disabled={disabled}
        onBlur={onBlur}
        onChange={handleChange}
        onFocus={onFocus}
        ariaLabelledBy={ariaLabelledBy}
      />
      <div className="HintAndError">
        <FieldHint {...props} />
        {/* the mask validation should only be rendered, when the general validation is satisfied */}
        {!valid ? (
          <FieldError valid={valid} validationMessage={validationMessage} />
        ) : (
          <FieldError valid={internalValid} validationMessage={maskValidationMessage} />
        )}
      </div>
    </FieldWrapper>
  );
};
