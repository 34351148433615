import { FormRenderProps } from '@progress/kendo-react-form';
import { createContext, useContext } from 'react';

export const FormContext = createContext<FormRenderProps | undefined>(undefined);

const FormContextProvider = FormContext.Provider;

const useFormContext = () => {
  const context = useContext(FormContext);

  if (!context) {
    throw new Error('useFormContext must be within FormContext.Provider');
  }

  return context;
};

export { FormContextProvider, useFormContext };
