import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type UpdateClientMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  input: Types.UpdateClientInput;
}>;

export type UpdateClientMutation = {
  __typename?: 'Mutation';
  updateClient?: {
    __typename?: 'Client';
    type_id: string;
    salutation_id: string;
    name: string;
    email?: string | null;
    phone?: string | null;
    web?: string | null;
    notes?: string | null;
    state_id: Types.ClientStateId;
    external_number?: string | null;
  } | null;
};

export const UpdateClientDocument = gql`
  mutation UpdateClient($id: ID!, $input: UpdateClientInput!) {
    updateClient(id: $id, input: $input) {
      type_id
      salutation_id
      name
      email
      phone
      web
      notes
      state_id
      external_number
    }
  }
`;

export function useUpdateClientMutation() {
  return Urql.useMutation<UpdateClientMutation, UpdateClientMutationVariables>(
    UpdateClientDocument,
  );
}
