import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ClientServiceLevelQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;

export type ClientServiceLevelQuery = {
  __typename?: 'Query';
  client?: {
    __typename?: 'Client';
    client_service_level?: {
      __typename?: 'ClientServiceLevel';
      accept_task?: number | null;
      send_report?: number | null;
      set_appointment?: number | null;
      set_controlling?: number | null;
      set_rework_done?: number | null;
    } | null;
  } | null;
};

export const ClientServiceLevelDocument = gql`
  query ClientServiceLevel($id: ID!) {
    client(id: $id) {
      client_service_level {
        accept_task
        send_report
        set_appointment
        set_controlling
        set_rework_done
      }
    }
  }
`;

export function useClientServiceLevelQuery(
  options: Omit<Urql.UseQueryArgs<ClientServiceLevelQueryVariables>, 'query'>,
) {
  return Urql.useQuery<ClientServiceLevelQuery, ClientServiceLevelQueryVariables>({
    query: ClientServiceLevelDocument,
    ...options,
  });
}
