import { Hint } from '@progress/kendo-react-labels';
import { Tooltip } from '@progress/kendo-react-tooltip';

import { CommonFieldRenderProps } from './types';

export type FieldHintProps = Pick<
  CommonFieldRenderProps,
  'hint' | 'valid' | 'validationMessage'
> & {
  hintClass?: string;
  hintTooltipStyle?: React.CSSProperties;
};

export const FieldHint = ({
  hint,
  valid,
  validationMessage,
  hintClass,
  hintTooltipStyle,
}: FieldHintProps) => {
  if (!hint || (!valid && validationMessage)) {
    return null;
  }

  return (
    <Tooltip tooltipStyle={hintTooltipStyle} anchorElement="target">
      <Hint className={hintClass}>
        <span title={hint}>{hint}</span>
      </Hint>
    </Tooltip>
  );
};
