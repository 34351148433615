import './dvhPolyfill.scss';

import { debounce } from 'lodash';

const setDvhProperty = () =>
  document.documentElement.style.setProperty(
    '--dvh-polyfill-1dvh',
    `${window.innerHeight * 0.01}px`,
  );

const polyfill = () => {
  if (window.CSS.supports('height', '1dvh')) {
    return;
  }

  setDvhProperty();

  let idleHandle: number | undefined = undefined;

  // requestIdleCallback is not supported in Safari.
  // Using debounce as fallback.
  const handleResize = window.requestIdleCallback
    ? () => {
        if (idleHandle) {
          window.cancelIdleCallback(idleHandle);
        }

        idleHandle = window.requestIdleCallback(setDvhProperty);
      }
    : debounce(setDvhProperty, 250);

  window.addEventListener('resize', handleResize);
};

polyfill();
