import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
export type AppModuleSettingFragment = {
  __typename?: 'AppModuleSetting';
  id: string;
  title: string;
  description?: string | null;
  type: Types.AppModuleSettingType;
  key: string;
  value?: string | null;
};

export type AppModuleFragment = {
  __typename?: 'AppModule';
  id: string;
  title: string;
  description?: string | null;
  active: boolean;
  variables: Array<{
    __typename?: 'AppModuleSetting';
    id: string;
    title: string;
    description?: string | null;
    type: Types.AppModuleSettingType;
    key: string;
    value?: string | null;
  }>;
};

export const AppModuleSettingFragmentDoc = gql`
  fragment AppModuleSetting on AppModuleSetting {
    id
    title
    description
    type
    key
    value
  }
`;
export const AppModuleFragmentDoc = gql`
  fragment AppModule on AppModule {
    id
    title
    description
    active
    variables {
      ...AppModuleSetting
    }
  }
  ${AppModuleSettingFragmentDoc}
`;
