export default {
  toolbar: {
    close: 'Beenden',
    mute: 'Stummschalten',
    unmute: 'Stummschaltung aufheben',
    videoOn: 'Kamera einschalten',
    videoOff: 'Kamera ausschalten',
  },
  labels: {
    you: 'Du',
    participantsEmpty: 'Warten auf weitere Teilnehmer',
    switchCamera: 'Kamera wechseln',
  },
  dialogs: {
    update: {
      title: 'Meeting bearbeiten',
      join: 'Beitreten',
    },
    location: {
      title: 'Standort',
    },
    pdf: {
      title: 'Dokument anzeigen',
    },
    create: {
      title: 'Meeting erstellen',
      start: 'Meeting erstellen',
      note: 'Hier können sie ein neues Meeting erstellen.',
      labels: {
        type: 'Auftragstyp',
        phoneNumber: 'Telefonnummer',
        date: 'Datum/Uhrzeit',
        duration: 'Termindauer',
        customerName: 'Kundenname',
        phoneNumberPrefix: 'Vorwahl',
        customerMail: 'E-Mail',
        notes: 'Hinweis an den Kunden',
        allowUpload: 'Kunden erlauben, eigene Fotos über den Einladungs-Link hochzuladen.',
        allowForm: 'Kunden erlauben, Formularfragen zu beantworten.',
        hasVideo: 'mit Video',
        sendEmail: 'Einladung direkt an diese E-Mail schicken',
        sendSMS: 'Einladung direkt an diese Telefonnummer schicken',
      },
      tooltips: {
        notes:
          'Geben Sie hier eine Notiz für den Kunden an. Diese wird in seinem Kundenportal angezeigt',
      },
    },
  },
  pages: {
    call: {
      screenshot: 'Foto aufnehmen',
      screenshotHeadline: 'Fotos',
      messages: {
        screenshotDeleted: 'Foto gelöscht',
      },
      permissionError: {
        title: 'Berechtigungs-Fehler',
        microphone: {
          title: 'Mikrofon-Berechtigung nicht erteilt',
          permissionDenied: 'Bitte erlauben Sie den Zugriff auf Ihr Mikrofon.',
        },
        camera: {
          title: 'Kamera-Berechtigung nicht erteilt',
          permissionDenied: 'Bitte erlauben Sie den Zugriff auf Ihre Kamera.',
        },
        geolocation: {
          title: 'Standort-Berechtigung nicht erteilt',
          permissionDenied: 'Bitte erlauben Sie den Zugriff auf Ihren Standort.',
          positionUnavailable:
            'Ihr Standort konnte nicht ermittelt werden. Bitte versuchen Sie es erneut.',
          timeout:
            'Es gab ein Problem beim ermitteln Ihres Standorts. Bitte versuchen Sie es erneut.',
        },
      },
    },
    meeting: {
      documents: {
        note: 'Der Organisator hat ein Dokument für Sie bereitgestellt. Sie können das Dokument durch Klick auf das Bild öffnen und gegebenenfalls unterschreiben.',
      },
      form: {
        note: 'Der Organisator hat um die Beantwortung folgender Fragen gebeten. Bitte füllen Sie diese Fragen wahrheitsgemäß aus und  klicken Sie anschließend am Ende des Formulars auf senden.',
        hasForm: 'Sie haben das Formular bereits ausgefüllt',
      },
      dialogs: {
        submitForm: {
          title: 'Formular absenden',
          description:
            'Möchten Sie das Formular wirklich absenden? Damit schicken Sie das Formular ab, eine weitere Änderung ist nicht mehr möglich. Wollen Sie fortfahren?',
        },
      },
      notifications: {
        form: {
          success: 'Formular erfolgreich abgesendet',
          error: 'Fehler beim Absenden des Formulars',
        },
      },
      pageTitle: 'Video-Meeting',
      title: 'Details zum Ereignis: {{ number }}',
      headlines: {
        schedule: 'Ablauf',
        documents: 'Dokumente',
        images: 'Bilderupload',
        form: 'Formular',
      },
      teaser: {
        images:
          'Der Organisator hat den Bilderupload für Sie erlaubt. Bitte laden Sie, sofern Sie dazu aufgefordert werden, aussagekräftige Bilder zum Vorgang hoch.',
      },
      labels: {
        submitForm: 'Formular absenden',
        owner: 'Ansprechpartner/in',
        contact: 'Kontakt',
        customer: 'Kunde',
        number: 'Schadennummer',
        noPhone: 'Nicht vorhanden',
        notes: 'Anmerkungen',
        date: 'Video-Termin',
        join: 'Jetzt den Video-Raum betreten',
        uploadImages: 'Bilder hochladen',
        step: {
          first: 'Der Auftrag wurde erfolgreich angelegt',
          second: 'Video-Termin',
          third: 'Schaden Prüfung & Abschluss',
        },
      },
    },
  },
};
