import { EditableCellErrorHint } from './EditableCellErrorHint';

interface CellValueProps {
  value?: string | number;
  error: string | false;
}

export const CellValueWithErrorHint = ({ error, value }: CellValueProps) => {
  return (
    <div className="k-d-inline-flex justify-content-between k-w-full">
      <span>{value}</span>
      {error && <EditableCellErrorHint style={{ background: 'inherit' }} error={error} />}
    </div>
  );
};
