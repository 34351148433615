import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import { TaskGroupFragmentDoc } from '../../../tasks/graphql/generated/taskGroups.generated';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type UpdateTaskGroupMutationVariables = Types.Exact<{
  task_group_id: Types.Scalars['ID'];
  input: Types.UpdateTaskGroupInput;
}>;

export type UpdateTaskGroupMutation = {
  __typename?: 'Mutation';
  updateTaskGroup?: { __typename?: 'TaskGroup'; id: string; title: string; color: string } | null;
};

export const UpdateTaskGroupDocument = gql`
  mutation UpdateTaskGroup($task_group_id: ID!, $input: UpdateTaskGroupInput!) {
    updateTaskGroup(task_group_id: $task_group_id, input: $input) {
      ...TaskGroup
    }
  }
  ${TaskGroupFragmentDoc}
`;

export function useUpdateTaskGroupMutation() {
  return Urql.useMutation<UpdateTaskGroupMutation, UpdateTaskGroupMutationVariables>(
    UpdateTaskGroupDocument,
  );
}
