import { CompositeFilterDescriptor, FilterDescriptor } from '@progress/kendo-data-query';
import { FilterOperator } from '@progress/kendo-react-data-tools';
import { ComponentType } from 'react';

export interface FilterChangeEvent {
  nextFilter: FilterDescriptor | FilterDescriptor[];
}
export interface InitialFilterState {
  search?: string;
  fixedFilters?: CompositeFilterDescriptor | null;
  searchFilters?: CompositeFilterDescriptor | null;
  expandedFilters?: CompositeFilterDescriptor | null;
  additionalFilters?: CompositeFilterDescriptor | null;
  customFilters?: CompositeFilterDescriptor | null;
  expanded?: boolean;
}

export enum FilterFieldType {
  SEARCH = 'search',
  EXPANDED = 'expanded',
  ADDITIONAL = 'additional',
  CUSTOM = 'custom',
  FIXED = 'fixed',
}

export type FilterDescriptorWithType = FilterDescriptor & { type: FilterFieldType };

export interface CompositeFilterDescriptorWithType extends CompositeFilterDescriptor {
  filters: Array<FilterDescriptorWithType | CompositeFilterDescriptorWithType>;
}

export interface FilterProps {
  filter: FilterDescriptor;
  onFilterChange: (event: FilterChangeEvent) => void;
  getFilter?: (name: string) => FilterDescriptorWithType | undefined;
  filterData?: unknown[];
  loading?: boolean;
}

export interface FilterField {
  name: string;
  label?: string;
  filter: ComponentType<FilterProps>;
  operator: string;
  operators?: Array<FilterOperator>;
  value?: unknown;
  className?: string;
  hidden?: boolean;
  filterData?: unknown[];
  loading?: boolean;
}

export type FilterFieldWithType = FilterField & { type: FilterFieldType };

export interface FilterState {
  search: string;
  fixedFilters: CompositeFilterDescriptor | null;
  searchFilters: CompositeFilterDescriptorWithType | null;
  expandedFilters: CompositeFilterDescriptorWithType | null;
  additionalFilters: CompositeFilterDescriptorWithType | null;
  customFilters: CompositeFilterDescriptorWithType | null;
  filter: CompositeFilterDescriptorWithType;
  expanded: boolean;
}

export interface FilterContextType {
  changeSearch: (search: string) => void;
  changeExpandedFilter: (filter: CompositeFilterDescriptor | null) => void;
  changeAdditionalFilter: (filter: CompositeFilterDescriptor | null) => void;
  changeCustomFilter: (filter: CompositeFilterDescriptor | null) => void;
  toggleExpanded: () => void;
  resetToInitialFilterState: () => void;
  hasActiveFilters: boolean;

  filterState: FilterState;

  searchFields: FilterFieldWithType[];
  expandedFields: FilterFieldWithType[];
  additionalFields: FilterFieldWithType[];
  customFields: FilterFieldWithType[];
}

export const isCompositeFilterDescriptorWithType = (
  object:
    | FilterDescriptorWithType
    | CompositeFilterDescriptorWithType
    | CompositeFilterDescriptor
    | FilterDescriptor,
): object is CompositeFilterDescriptorWithType => {
  return (
    object &&
    typeof object === 'object' &&
    'logic' in object &&
    'filters' in object &&
    Array.isArray(object.filters)
  );
};
