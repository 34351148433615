export default {
  labels: {
    allLeaders: 'Alle Abteilungen',
  },
  metrics: {
    workloadForDashboard: {
      title: 'Auslastung pro Experte',
      available_owners: 'Verfügbare Experten',
    },
  },
};
