import { useThemeVariables } from '@module/layout';

import { Badge } from './Badge';

export interface InlineBadgeProps {
  title: string;
}

export const InlineBadge = (props: InlineBadgeProps) => {
  const { title } = props;
  const themeVariables = useThemeVariables();
  return (
    <span className="InlineBadge" style={{ width: 4, height: 20, position: 'relative' }}>
      <Badge
        backgroundColor={themeVariables.primaryColor}
        color={'#ffffff'}
        style={{
          padding: 0,
          position: 'absolute',
          left: 0,
          top: -5,
          width: 24,
          height: 16,
          lineHeight: '16px',
          fontSize: '10px',
          fontWeight: 'bold',
        }}
      >
        {title}
      </Badge>
    </span>
  );
};
