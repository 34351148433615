import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import { ExportResponseFragmentDoc } from '../../../common/graphql/generated/exportResponseFragment.generated';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ExportTasksStatisticCustomMutationVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.ExportTaskStatisticCustomFilter>;
  config: Types.ExportConfigInput;
}>;

export type ExportTasksStatisticCustomMutation = {
  __typename?: 'Mutation';
  exportTasksStatisticCustom: {
    __typename?: 'ExportResponse';
    success: boolean;
    message: string;
    download?: {
      __typename?: 'Download';
      id: string;
      state_id: Types.DownloadStateId;
      filename: string;
      file_ids: Array<string>;
      url_download: string;
    } | null;
  };
};

export const ExportTasksStatisticCustomDocument = gql`
  mutation ExportTasksStatisticCustom(
    $filter: ExportTaskStatisticCustomFilter
    $config: ExportConfigInput!
  ) {
    exportTasksStatisticCustom(filter: $filter, config: $config) {
      ...ExportResponse
    }
  }
  ${ExportResponseFragmentDoc}
`;

export function useExportTasksStatisticCustomMutation() {
  return Urql.useMutation<
    ExportTasksStatisticCustomMutation,
    ExportTasksStatisticCustomMutationVariables
  >(ExportTasksStatisticCustomDocument);
}
