import { Badge, getContrastColor } from '@module/common';
import { useThemeVariables } from '@module/layout';
import { classNames } from '@progress/kendo-react-common';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

interface AssignableInsurance {
  id: string;
  name: string;
}

interface AssignedInsurancesBadgesProps {
  allInsurances: Array<AssignableInsurance>;
  assignedInsurances: Array<AssignableInsurance>;
  numVisibleBadges: number;
}

export const AssignedInsurancesBadges = (props: AssignedInsurancesBadgesProps) => {
  const { allInsurances, assignedInsurances, numVisibleBadges } = props;
  const { t } = useTranslation();
  const themeVariables = useThemeVariables();

  const badges = useMemo(() => {
    const noInsuranceSelected = assignedInsurances.length === 0;

    if (noInsuranceSelected) {
      return {
        names: [t('common.labels.none')],
        color: themeVariables.errorColor,
      };
    }

    const allInsurancesSeleted = allInsurances.every((client) =>
      assignedInsurances.some((assignedInsurance) => assignedInsurance.id === client.id),
    );

    if (allInsurancesSeleted) {
      return {
        names: [t('common.labels.all')],
        color: themeVariables.successColor,
      };
    }

    return {
      names: assignedInsurances.map((insurance) => insurance.name),
      color: themeVariables.primaryColor,
    };
  }, [t, allInsurances, assignedInsurances, themeVariables]);

  return (
    <div>
      {badges.names.slice(0, numVisibleBadges).map((badgeName, index) => (
        <Badge
          key={badgeName}
          backgroundColor={badges.color}
          color={getContrastColor(badges.color)}
          className={classNames({ 'k-ml-1': index > 0 })}
        >
          {badgeName}
        </Badge>
      ))}
      {badges.names.length > numVisibleBadges && (
        <Badge
          key="additional_badges_count"
          backgroundColor={themeVariables.lightColor}
          color={getContrastColor(themeVariables.lightColor)}
          className="k-text-nowrap k-overflow-hidden k-ml-1"
        >
          {`+${badges.names.length - numVisibleBadges}`}
        </Badge>
      )}
    </div>
  );
};
