import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import { SubformClassificationTagFragmentDoc } from './subformClassificationTagFragment.generated';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type SubformClassificationTagsAllQueryVariables = Types.Exact<{ [key: string]: never }>;

export type SubformClassificationTagsAllQuery = {
  __typename?: 'Query';
  tags: {
    __typename?: 'TagPaginator';
    data: Array<{
      __typename?: 'Tag';
      id: string;
      title: string;
      color: string;
      type_id: string;
      type: { __typename?: 'TagType'; id: string; title: string; color: string };
    }>;
  };
};

export const SubformClassificationTagsAllDocument = gql`
  query SubformClassificationTagsAll {
    tags(filter: { AND: [] }, sort: { columns: [] }, pagination: { skip: 0, take: 10000 }) {
      data {
        ...SubformClassificationTag
      }
    }
  }
  ${SubformClassificationTagFragmentDoc}
`;

export function useSubformClassificationTagsAllQuery(
  options?: Omit<Urql.UseQueryArgs<SubformClassificationTagsAllQueryVariables>, 'query'>,
) {
  return Urql.useQuery<
    SubformClassificationTagsAllQuery,
    SubformClassificationTagsAllQueryVariables
  >({ query: SubformClassificationTagsAllDocument, ...options });
}
