import {
  CancelButton,
  DeleteButton,
  Dialog,
  DialogHeader,
  DialogHeaderBar,
  FeaturedIcon,
  FeaturedIconColor,
  PrimaryButton,
} from '@module/layout';
import { DialogProps, useDialogs } from '@module/shared/dialogs';
import { NotificationType, useNotifications } from '@module/shared/notifications';
import { DialogActionsBar } from '@progress/kendo-react-dialogs';
import { ReactNode, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Loader } from '../Loader';

type ConfirmDialogMode = 'primary' | 'delete' | 'success';

export interface ConfirmResult {
  notification?: {
    text: string;
    type: NotificationType;
  };
}

export type ConfirmReturnType = Promise<ConfirmResult | undefined> | void;
export type ConfirmFunc = (() => ConfirmReturnType) | (() => void);

interface ConfirmDialogProps {
  title: string;
  description: string | ReactNode;
  color?: FeaturedIconColor;
  iconClass?: string;
  mode?: ConfirmDialogMode;
  onCancel?: () => void;
  onConfirm?: () => ConfirmReturnType;
  labelCancel?: string;
  labelConfirm?: string;
  children?: ReactNode;
}

export const ConfirmDialog = (props: ConfirmDialogProps & DialogProps) => {
  const {
    title,
    description,
    color = 'warning',
    iconClass,
    mode = 'primary',
    dialogId,
    onCancel,
    onConfirm,
    labelCancel,
    labelConfirm,
    children,
  } = props;
  const { hideDialog } = useDialogs();
  const { showNotification } = useNotifications();
  const [loading, setLoading] = useState(false);

  const handleCancel = () => {
    hideDialog(dialogId);
    onCancel?.();
  };

  const handleConfirm = async () => {
    const resultOrVoid = onConfirm?.();
    const result = Promise.resolve(resultOrVoid instanceof Promise ? resultOrVoid : undefined);

    // display loader if the confirm callback returns a promise
    if (result !== undefined) {
      setLoading(true);
    }

    const confirmation = await result;
    if (confirmation?.notification) {
      showNotification(confirmation.notification.text, confirmation.notification.type);
    }

    setLoading(false);
    hideDialog(dialogId);
  };

  return (
    <Dialog width="small" onClose={handleCancel}>
      {loading && <Loader />}
      <DialogHeaderBar>
        <div className="k-display-flex k-flex-row k-gap-4">
          {iconClass && (
            <div>
              <FeaturedIcon iconClass={iconClass} color={color} />
            </div>
          )}
          <div className="k-flex-grow">
            <DialogHeader title={title} description={description} align="left" />

            {children && <div className="k-mt-4">{children}</div>}
          </div>
        </div>
      </DialogHeaderBar>

      <DialogActionsBar layout="end">
        <CancelButton label={labelCancel} onClick={handleCancel} />
        {mode === 'primary' && (
          <PrimaryButton
            style={{ minWidth: '75px' }}
            label={labelConfirm}
            onClick={handleConfirm}
          />
        )}
        {mode === 'delete' && (
          <DeleteButton style={{ minWidth: '75px' }} label={labelConfirm} onClick={handleConfirm} />
        )}
        {mode === 'success' && (
          <PrimaryButton
            style={{ minWidth: '75px' }}
            label={labelConfirm}
            onClick={handleConfirm}
          />
        )}
      </DialogActionsBar>
    </Dialog>
  );
};

interface ConfirmArgs {
  title?: string;
  description?: string | ReactNode;
  iconClass?: string;
  labelCancel?: string;
  labelConfirm?: string;
  mode?: ConfirmDialogMode;
  color?: FeaturedIconColor;
  children?: ReactNode;
}

interface ConfirmDeleteArgs extends ConfirmArgs {
  cancel?: () => void;
  delete?: ConfirmFunc;
}

interface ConfirmDiscardArgs extends ConfirmArgs {
  discard?: () => void;
  save?: ConfirmFunc;
}

interface ConfirmWarningArgs extends ConfirmArgs {
  cancel?: () => void;
  confirm?: ConfirmFunc;
}

export const useConfirmDialogs = () => {
  const { t } = useTranslation();
  const { showDialog } = useDialogs();
  const confirm = useCallback(
    (props: ConfirmDialogProps) => {
      showDialog(props, ConfirmDialog);
    },
    [showDialog],
  );

  const confirmDelete = useCallback(
    (args: ConfirmDeleteArgs) => {
      const {
        title,
        description,
        iconClass,
        cancel,
        delete: deleteKeyword,
        labelCancel,
        labelConfirm,
        mode,
        color,
        children,
      } = args;
      confirm({
        title: title ?? t('common.dialogs.delete.title'),
        description: description ?? t('common.dialogs.delete.description'),
        iconClass: iconClass ?? 'l-i-trash-2 u-text-2xl',
        mode: mode ?? 'delete',
        color: color ?? 'error',
        labelCancel: labelCancel ?? t('common.labels.cancel'),
        labelConfirm: labelConfirm ?? t('common.labels.delete'),
        children,
        onCancel: cancel,
        onConfirm: deleteKeyword,
      });
    },
    [confirm, t],
  );

  const confirmDiscard = useCallback(
    (args: ConfirmDiscardArgs) => {
      const {
        title,
        description,
        iconClass,
        discard,
        save,
        labelCancel,
        labelConfirm,
        color,
        children,
      } = args;

      confirm({
        title: title ?? t('common.dialogs.discard.title'),
        description: description ?? t('common.dialogs.discard.description'),
        iconClass: iconClass ?? 'l-i-save u-text-2xl',
        color: color ?? 'warning',
        labelCancel: labelCancel ?? t('common.labels.discard'),
        labelConfirm: labelConfirm ?? t('common.labels.saveChanges'),
        children,
        onCancel: discard,
        onConfirm: save,
      });
    },
    [confirm, t],
  );

  const confirmWarning = useCallback(
    (args: ConfirmWarningArgs) => {
      const {
        title,
        description,
        iconClass,
        cancel,
        confirm: confirmAlias,
        labelCancel,
        labelConfirm,
        color,
        children,
      } = args;

      confirm({
        title: title ?? t('common.dialogs.warning.title'),
        description: description ?? t('common.dialogs.warning.description'),
        iconClass: iconClass ?? 'l-i-alert-triangle u-text-2xl',
        color: color ?? 'warning',
        labelCancel: labelCancel ?? t('common.labels.cancel'),
        labelConfirm: labelConfirm ?? t('common.labels.confirm'),
        children,
        onCancel: cancel,
        onConfirm: confirmAlias,
      });
    },
    [confirm, t],
  );

  return { confirm, delete: confirmDelete, discard: confirmDiscard, warning: confirmWarning };
};
