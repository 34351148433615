import { createLoaderReducer } from '@module/shared/helpers';
import { Workbook } from '@progress/kendo-ooxml';
import { Button } from '@progress/kendo-react-buttons';
import { classNames, Typography } from '@progress/kendo-react-common';
import { useInternationalization } from '@progress/kendo-react-intl';
import {
  defaultTabs,
  Spreadsheet,
  SpreadsheetHandle,
  SpreadsheetProps,
} from '@progress/kendo-react-spreadsheet';
import { saveIcon } from '@progress/kendo-svg-icons';
import {
  createContext,
  CSSProperties,
  ReactNode,
  RefObject,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useRef,
} from 'react';
import { useTranslation } from 'react-i18next';

import { useUpdateTaskFileMutation } from '../graphql';
import { FileType } from '../helpers';
import { Loader } from './Loader';

interface ExcelViewerProps extends Omit<SpreadsheetProps, 'defaultProps'> {
  fileId: string;
  fileUrl: string;
  className?: string;
  style?: CSSProperties;
}

// Intl.formatNumber must be at least called one time before using the Spreadsheet component,
// otherwise an error is thrown when editing a cell using a non-English locale:
// "numbers.currencies[numbers.localeCurrency] is undefined"
const useSpreadsheetIntlHackFix = () => {
  const intl = useInternationalization();
  useMemo(() => intl.formatNumber(1, 'c'), [intl]);
};

const MessageWrapper = (props: { children: ReactNode }) => (
  <div
    {...props}
    className="k-d-flex-col k-justify-content-center k-align-items-center k-gap-1"
    style={{ gridColumn: '1 / -1', gridRow: '1 / -1', backgroundColor: 'rgba(255, 255, 255, 0.9' }}
  />
);

const spreadsheetLoaderReducer = createLoaderReducer<Blob>();

const FileIdContext = createContext<string | undefined>(undefined);

interface SaveExcelToolbarButtonProps {
  spreadsheetRef: RefObject<SpreadsheetHandle['instance']>;
}

const SaveExcelToolbarButton = (props: SaveExcelToolbarButtonProps) => {
  const { spreadsheetRef } = props;

  const { t } = useTranslation();
  const fileId = useContext(FileIdContext);

  const [, updateFile] = useUpdateTaskFileMutation();

  const onClick = useCallback(() => {
    if (!fileId) return;

    const instance = spreadsheetRef.current;

    if (instance) {
      instance.saveAsExcel({
        ...instance.options.excel,
        saveAs: async (data: Blob, fileName: string) => {
          await updateFile({ file_id: fileId, file: new File([data], fileName) });
        },
        Workbook,
      });
    }
  }, [fileId, spreadsheetRef, updateFile]);

  if (!fileId) {
    return null;
  }

  return (
    <Button
      className="k-toolbar-button"
      aria-label={t('common.labels.save')}
      iconClass="l-i-download"
      fillMode="flat"
      svgIcon={saveIcon}
      onClick={onClick}
    />
  );
};

export const ExcelViewer = (props: ExcelViewerProps) => {
  const { fileId, fileUrl, className, style, ...rest } = props;

  const { t } = useTranslation();

  const spreadsheetRef = useRef<SpreadsheetHandle>(null);

  const [{ loading, error }, dispatch] = useReducer(spreadsheetLoaderReducer, {
    loading: true,
    error: undefined,
  });

  useEffect(() => {
    (async () => {
      dispatch({ type: 'PENDING' });

      try {
        const response = await fetch(fileUrl, { credentials: 'include' });
        const blob = await response.blob();

        if (spreadsheetRef.current) {
          spreadsheetRef.current.fromFile(blob);
          dispatch({ type: 'RESOLVED', data: blob });
        } else {
          throw new Error('Spreadsheet ref missing');
        }
      } catch (error) {
        dispatch({ type: 'REJECTED', error });
      }
    })();
  }, [fileUrl]);

  useSpreadsheetIntlHackFix();

  const toolbar = useMemo(() => {
    const mutableTabs = [...defaultTabs];

    mutableTabs[0].tools = [...defaultTabs[0].tools];
    mutableTabs[0].tools.push(SaveExcelToolbarButton);

    return mutableTabs;
  }, []);

  return (
    <FileIdContext.Provider value={fileId}>
      <div className={classNames('k-d-grid k-grid-cols-1', className)} style={style}>
        <Spreadsheet
          ref={spreadsheetRef}
          style={{
            gridColumn: '1 / -1',
            gridRow: '1 / -1',
            width: '100%',
            height: '100%',
            opacity: loading || error ? 0 : undefined,
          }}
          toolbar={toolbar}
          {...rest}
        />

        {error ? (
          <MessageWrapper>
            <span className="l-i-file-warning u-text-5xl u-text-error-500" />
            <Typography.p className="!k-m-0 k-text-center u-text-error-500">
              {t('common.pdf.documentError')}
            </Typography.p>
          </MessageWrapper>
        ) : loading ? (
          <MessageWrapper>
            <Loader />
            <Typography.p className="!k-m-0 k-text-center">
              {t('common.pdf.documentLoading')}
            </Typography.p>
          </MessageWrapper>
        ) : null}
      </div>
    </FileIdContext.Provider>
  );
};

export const SPREADSHEET_FILE_TYPES = [FileType.XLSX];
