import { GridFilter, GridState } from '@module/shared/graphql';
import { CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { useEffect } from 'react';

import { useGridStateInitial } from './useGridStateInitial';

export const useGridStateWithPaginationReset = (
  filter: CompositeFilterDescriptor | GridFilter<unknown> | null,
  initialGridStateOverride?: GridState,
) => {
  const [initialState, setGridState] = useGridStateInitial(initialGridStateOverride);
  useEffect(() => {
    // jump to page 1 when filter changes
    setGridState({ ...initialState, skip: 0 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  return [initialState, setGridState] as const;
};
