import { ComponentType, ReactNode, useCallback, useMemo, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { useDetectNavigation } from '../hooks';
import { DialogItem, DialogProps, DialogsContext } from './DialogsContext';

export const DialogsProvider = (props: { children?: ReactNode | undefined }) => {
  const [dialogs, setDialogs] = useState<DialogItem[]>([]);

  const showDialog = useCallback(
    <T,>(
      dialogProps: T,
      dialogComponentType: ComponentType<T & DialogProps>,
      dialogId?: string,
    ) => {
      if (dialogs.find((dialog) => dialog.dialogId === dialogId)) {
        return;
      }
      const dialog: DialogItem = {
        dialogId: dialogId ? dialogId : uuidv4(),
        dialogProps,
        dialogComponentType,
      };

      setDialogs((prevState) => [...prevState, dialog]);
      return dialog;
    },
    [dialogs],
  );

  const hideDialog = useCallback((dialogId: string) => {
    setDialogs((prevState) => prevState.filter((dialog) => dialog.dialogId !== dialogId));
  }, []);

  const hideAllDialogs = useCallback(() => {
    setDialogs([]);
  }, []);

  useDetectNavigation(() => hideAllDialogs());

  const contextData = useMemo(
    () => ({
      dialogs,
      showDialog,
      hideDialog,
      hideAllDialogs,
    }),
    [dialogs, hideAllDialogs, hideDialog, showDialog],
  );
  return <DialogsContext.Provider value={contextData}>{props.children}</DialogsContext.Provider>;
};
