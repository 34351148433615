import { GridCellProps } from '@progress/kendo-react-grid';
import { useInternationalization } from '@progress/kendo-react-intl';
import { get } from 'lodash';
import { useMemo } from 'react';

export interface UnitCellProps {
  format: string;
  unit: string;
}

export const UnitCell = (props: GridCellProps & UnitCellProps) => {
  const intl = useInternationalization();

  const { className, dataItem, field, format, unit } = props;
  const value = get(dataItem, field ?? '', '');

  const text = useMemo(() => {
    return `${intl.formatNumber(value, format)} ${unit}`;
  }, [intl, format, unit, value]);

  return <td className={className}>{text}</td>;
};
