import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
export type FileLinkFragment = {
  __typename?: 'FileLink';
  file_id: string;
  linkable_type: string;
  linkable_id: string;
  file?: { __typename?: 'FileItem'; id: string; name: string } | null;
};

export const FileLinkFragmentDoc = gql`
  fragment FileLink on FileLink {
    file_id
    file {
      id
      name
    }
    linkable_type
    linkable_id
  }
`;
