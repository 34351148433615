import { email } from '@module/shared/helpers';
import { InferType, object, string } from 'yup';

export const contactSchema = object().shape({
  role_id: string().required(),
  salutation_id: string().nullable(),
  function_id: string().nullable(),
  first_name: string().nullable(),
  name: string().min(2).required(),
  street: string().nullable(),
  street_no: string().nullable(),
  postcode: string().min(1).nullable(),
  city: string().nullable(),
  country_id: string().nullable(),
  phone: string().nullable(),
  phone2: string().nullable(),
  mobile: string().nullable(),
  email: email().nullable(),
  email2: email().nullable(),
  fax_number: string().nullable(),
  note: string().nullable(),
});

export type ContactSchema = InferType<typeof contactSchema>;
