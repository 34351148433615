import {
  Ability,
  AbilityBuilder,
  AbilityClass,
  ForcedSubject,
  subject as subjectHelper,
} from '@casl/ability';
import { useCallback, useMemo } from 'react';

import { Permission } from '../../../types/graphql.generated';
import { GenericCan, GenericCanSharedProps } from '../components/GenericCan';
import { CurrentUserAbilityFragment, TaskSubTaskTemplateAbilityFragment } from '../graphql';
import { detectSubjectTypeByTypename as detectSubjectType } from '../helpers';
import { useMeOrDefault } from '../hooks';

interface TaskSubTaskTemplateSubject {
  me: CurrentUserAbilityFragment;
  taskSubTaskTemplate?: TaskSubTaskTemplateAbilityFragment;
}

type TaskSubTaskTemplateSubjectArg = Partial<TaskSubTaskTemplateSubject>;

type Actions =
  | 'indexTaskSubTaskTemplates'
  | 'createTaskSubTaskTemplate'
  | 'readTaskSubTaskTemplate'
  | 'updateTaskSubTaskTemplate'
  | 'deleteTaskSubTaskTemplate';
type Subjects = TaskSubTaskTemplateSubject | 'TaskSubTaskTemplateSubject';

type TaskSubTaskTemplateAbility = Ability<[Actions, Subjects]>;
const taskSubTaskTemplateAbility = Ability as AbilityClass<TaskSubTaskTemplateAbility>;

export const useTaskSubTaskTemplateAbility = (): [
  TaskSubTaskTemplateAbility,
  (
    sub?: TaskSubTaskTemplateSubjectArg,
  ) => TaskSubTaskTemplateSubject & ForcedSubject<'TaskSubTaskTemplateSubject'>,
] => {
  const ability = useMemo(() => {
    const { can, build } = new AbilityBuilder(taskSubTaskTemplateAbility);

    can('indexTaskSubTaskTemplates', 'TaskSubTaskTemplateSubject', {
      'me.globalPermissions': { $in: [Permission.TASK_SUBTASK_TEMPLATE_INDEX] },
    });
    can('createTaskSubTaskTemplate', 'TaskSubTaskTemplateSubject', {
      'me.globalPermissions': { $in: [Permission.TASK_SUBTASK_TEMPLATE_CREATE] },
    });

    can('readTaskSubTaskTemplate', 'TaskSubTaskTemplateSubject', {
      'taskSubTaskTemplate.permissions': { $in: [Permission.TASK_SUBTASK_TEMPLATE_READ] },
    });
    can('updateTaskSubTaskTemplate', 'TaskSubTaskTemplateSubject', {
      'taskSubTaskTemplate.permissions': { $in: [Permission.TASK_SUBTASK_TEMPLATE_UPDATE] },
    });
    can('deleteTaskSubTaskTemplate', 'TaskSubTaskTemplateSubject', {
      'taskSubTaskTemplate.permissions': { $in: [Permission.TASK_SUBTASK_TEMPLATE_DELETE] },
    });

    return build({ detectSubjectType });
  }, []);

  const me = useMeOrDefault();
  const subject = useCallback(
    (sub?: TaskSubTaskTemplateSubjectArg) => {
      return subjectHelper('TaskSubTaskTemplateSubject', { me, ...sub });
    },
    [me],
  );

  return [ability, subject];
};

type CanTaskSubTaskTemplateProps = GenericCanSharedProps<Actions>;

export const CanTaskSubTaskTemplate = (props: CanTaskSubTaskTemplateProps) => {
  const [taskSubTaskTemplateAbility, taskSubTaskTemplateSubject] = useTaskSubTaskTemplateAbility();

  return (
    <GenericCan<Actions, Subjects, TaskSubTaskTemplateAbility>
      ability={taskSubTaskTemplateAbility}
      subject={taskSubTaskTemplateSubject()}
      {...props}
    />
  );
};
