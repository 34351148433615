import { EditorPasteEvent } from '@progress/kendo-react-editor';
import DOMPurify from 'dompurify';

// When pasting HTML into Kendo Editor component, often it happenes, that pasted content
// can not be removed afterwards, because it contains styling, we do not cover with
// the editor's toolbar. This hook is used to sanitize the pasted HTML content.

export const editorPasteSanitizeSettings = {
  ALLOWED_ATTR: [],
  ALLOWED_TAGS: ['p'],
};

export const sanitizePastedHtml = (html: string) => {
  return DOMPurify.sanitize(html, editorPasteSanitizeSettings);
};

export const useSanitizeEditorPasteHtml = () => {
  return (e: EditorPasteEvent) => {
    const text = sanitizePastedHtml(e.pastedHtml);
    return text;
  };
};
