import { GridCellProps } from '@progress/kendo-react-grid';

import { getContrastColor } from '../../helpers';
import { Badge } from '../badges';

interface ColorBadgeCellProps extends GridCellProps {
  dataItem: { color: string };
}

export const ColorBadgeCell = ({ dataItem }: ColorBadgeCellProps) => {
  return (
    <td className="k-text-center">
      <Badge backgroundColor={dataItem.color} color={getContrastColor(dataItem.color)}>
        {dataItem.color}
      </Badge>
    </td>
  );
};
