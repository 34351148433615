import { classNames } from '@progress/kendo-react-common';
import { AutoCompleteProps } from '@progress/kendo-react-dropdowns';
import { Popup } from '@progress/kendo-react-popup';
import {
  ChangeEvent,
  FocusEvent,
  ForwardedRef,
  forwardRef,
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { isNumber, isString } from '../../helpers';

type AutoCompleteDataItem = string | number | Record<string, unknown>;

export interface AutoCompleteImprovedChangeEvent {
  value: unknown;
  dataItem?: AutoCompleteDataItem;
  nativeEvent: Event;
  syntheticEvent: SyntheticEvent<HTMLElement>;
}

export interface AutoCompleteImprovedProps
  extends Pick<
    AutoCompleteProps,
    | 'opened'
    | 'style'
    | 'value'
    | 'defaultValue'
    | 'className'
    | 'clearButton'
    | 'placeholder'
    | 'readonly'
    | 'disabled'
    | 'tabIndex'
    | 'textField'
    | 'id'
    | 'fillMode'
    | 'rounded'
    | 'size'
  > {
  data?: AutoCompleteDataItem[];
  dataItemKey?: 'string';
  onChange?: (event: AutoCompleteImprovedChangeEvent) => void;
}

function getItemValue(dataItem: AutoCompleteDataItem, textField: string | undefined) {
  if (isString(dataItem) || isNumber(dataItem)) {
    return dataItem.toString();
  }

  if (textField && dataItem && typeof dataItem === 'object' && textField && dataItem[textField]) {
    const itemValue = dataItem[textField];
    if (isString(itemValue)) {
      return itemValue;
    }
    if (isNumber(itemValue)) {
      return itemValue.toString();
    }
  }
  return undefined;
}

export const AutoCompleteImproved = forwardRef(
  (props: AutoCompleteImprovedProps, ref: ForwardedRef<HTMLInputElement>) => {
    const { className, style, fillMode, rounded, size } = props;
    const { placeholder, tabIndex } = props;
    const { value, data, textField } = props;

    const anchorRef = useRef<HTMLElement>(null);
    const [search, setSearch] = useState<string>('');
    const [focused, setFocused] = useState<boolean>(false);

    useEffect(() => {
      setSearch(value ?? '');
    }, [value]);

    const handleSearch = useCallback((e: ChangeEvent<HTMLInputElement>) => {
      setSearch(e.target.value);
    }, []);

    const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
      // we always want to close the popup on blur
      setFocused(false);
      // if there is no value (e.target.value) we dont want to trigger onChange, as then a possible
      // existing input would be overwritten. As the current search term is empty and we dont
      // change the value, we want to sync the search term with the current value.
      if (!e.target.value) {
        if (!search) {
          setSearch(value ?? '');
        }
        return;
      }

      // there is an edge case - when we select the currently selected item again, we have a
      // value in the input (e.target.value). As we mostly use more than just the "textField"
      // property from the selected item object, we would overwrite the current selected item
      // with an onChange event that has a value, but no dataItem. To overcome that, we search
      // if there is an item with the same value in our items array - if yes, we pass it to the onChange.

      const item = data?.find((item) => {
        const itemValue = getItemValue(item, textField);
        return itemValue === e.target.value;
      });

      // we dont want to return if item === undefined, because then we "change" with the value of
      // the curent search term.

      props.onChange?.({
        dataItem: item,
        value: e.target.value,
        nativeEvent: e.nativeEvent,
        syntheticEvent: e,
      });
    };

    const handleSelectItem = useCallback(
      (event: {
        dataItem: AutoCompleteDataItem;
        nativeEvent: Event;
        syntheticEvent: SyntheticEvent<HTMLElement, Event>;
      }) => {
        const { dataItem, nativeEvent, syntheticEvent } = event;
        const value = getItemValue(dataItem, textField);
        setFocused(false);
        props.onChange?.({
          value,
          dataItem,
          nativeEvent,
          syntheticEvent,
        });
      },
      [props, textField],
    );

    const dataItems: AutoCompleteDataItem[] = useMemo(() => {
      return (data ?? []).filter((dataItem) => {
        const itemValue = getItemValue(dataItem, textField);
        return (itemValue && search && itemValue.includes(search)) || !search;
      });
    }, [data, search, textField]);

    const renderedItems = useMemo(() => {
      return dataItems.map((dataItem, index) => {
        const itemValue = getItemValue(dataItem, textField);

        return (
          <li
            key={index}
            className="k-list-item"
            style={{ position: 'unset' }}
            onMouseDown={(e) =>
              handleSelectItem({ dataItem, nativeEvent: e.nativeEvent, syntheticEvent: e })
            }
          >
            <span>{itemValue}</span>
          </li>
        );
      });
    }, [dataItems, handleSelectItem, textField]);

    const handleFocus = useCallback(() => {
      setFocused(true);
    }, []);

    return (
      <span
        className={classNames('k-input', className, {
          'k-input-flat': fillMode === 'flat',
          'k-input-outline': fillMode === 'outline',
          'k-input-solid': fillMode === 'solid',

          'k-rounded-sm': rounded === 'small',
          'k-rounded-md': rounded === 'medium',
          'k-rounded-lg': rounded === 'large',
          'k-rounded-full': rounded === 'full',
          'k-input-sm': size === 'small',
          'k-input-md': size === 'medium',
          'k-input-lg': size === 'large',
        })}
        style={style}
        ref={anchorRef}
      >
        <input
          ref={ref}
          className="k-input-inner"
          type="text"
          placeholder={placeholder}
          value={search}
          tabIndex={tabIndex}
          onChange={handleSearch}
          onFocus={handleFocus}
          onBlur={handleBlur}
        />
        {focused && anchorRef && anchorRef.current && (
          <Popup
            show={true}
            className="k-list k-list-md"
            anchor={anchorRef.current}
            style={{
              width: anchorRef.current.clientWidth,
              overflow: 'scroll',
              maxHeight: '300px',
            }}
          >
            <ul className="k-list-ul">{renderedItems}</ul>
          </Popup>
        )}
      </span>
    );
  },
);
AutoCompleteImproved.displayName = 'AutoCompleteImproved';
