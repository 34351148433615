import { useMemo } from 'react';

import { stripPropertyByName } from '../helpers';

export function stripGraphQLTypeName<T>(data: T) {
  return stripPropertyByName(data, '__typename');
}

export function useGraphQLOmitTypeName<T>(data: T) {
  return useMemo(() => {
    return stripGraphQLTypeName(data);
  }, [data]);
}
