import './LabelSwitch.scss';

import { Switch, SwitchProps } from '@progress/kendo-react-inputs';
import { Label } from '@progress/kendo-react-labels';

export const LabelSwitchInput = (props: SwitchProps) => {
  return (
    <div className="label-switch">
      <Switch {...props} size="small" onLabel={''} offLabel={''} />
      <Label>{props.value ? props.onLabel : props.offLabel}</Label>
    </div>
  );
};
