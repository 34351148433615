import {
  Ability,
  ExtractSubjectType,
  MongoQuery,
  PureAbility,
  Subject,
} from '@casl/ability/dist/types';
import { ReactNode, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export interface GenericCanSharedProps<TActions extends string> {
  action: TActions;
  not?: boolean;
  children?: ReactNode;
  onFailure?: () => void;
  failure?: ReactNode;
}

interface GenericCanProps<
  TActions extends string,
  TSubjects extends Subject,
  TAbility extends PureAbility<[TActions, TSubjects], MongoQuery>,
> extends GenericCanSharedProps<TActions> {
  ability: TAbility;
  action: TActions;
  subject: ExtractSubjectType<TSubjects> | Exclude<TSubjects, string>;
}

export const GenericCan = <
  TActions extends string,
  TSubjects extends Subject,
  TAbility extends Ability<[TActions, TSubjects]>,
>(
  props: GenericCanProps<TActions, TSubjects, TAbility>,
) => {
  const { ability, action, subject, children, not, onFailure, failure } = props;
  const navigate = useNavigate();

  // seems impossible to get rid of this any

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const hasAbility = ability.can(action, subject as unknown) && !not;

  useEffect(() => {
    if (!hasAbility && onFailure) {
      onFailure();
    }
  }, [ability, action, onFailure, hasAbility, navigate, props.action, subject]);

  return (
    <>
      {hasAbility && children}
      {!hasAbility && failure}
    </>
  );
};
