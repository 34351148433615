import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type UpdateModuleSettingsMutationVariables = Types.Exact<{
  input: Types.UpdateModuleSettingsInput;
}>;

export type UpdateModuleSettingsMutation = {
  __typename?: 'Mutation';
  updateModuleSettings: {
    __typename?: 'AppSettings';
    module_images_active: boolean;
    module_documents_active: boolean;
    module_calculation_active: boolean;
    module_room_plan_active: boolean;
    module_emails_active: boolean;
    module_customer_portal_active: boolean;
    module_statistic_active: boolean;
    module_invoices_active: boolean;
    module_calculation_hide_column_status: boolean;
    module_calculation_hide_column_accepted: boolean;
    module_calculation_default_net: boolean;
    module_calculation_hide_column_payout_state: boolean;
    mobile_app_active: boolean;
    mobile_can_accept: boolean;
    mobile_can_set_appointments: boolean;
    mobile_lock_tasks_when_mobile_active: boolean;
    module_extended_forms_active: boolean;
    module_qualifications_active: boolean;
    module_devices_active: boolean;
  };
};

export const UpdateModuleSettingsDocument = gql`
  mutation UpdateModuleSettings($input: UpdateModuleSettingsInput!) {
    updateModuleSettings(input: $input) {
      module_images_active
      module_documents_active
      module_calculation_active
      module_room_plan_active
      module_emails_active
      module_customer_portal_active
      module_statistic_active
      module_invoices_active
      module_calculation_hide_column_status
      module_calculation_hide_column_accepted
      module_calculation_default_net
      module_calculation_hide_column_payout_state
      mobile_app_active
      mobile_can_accept
      mobile_can_set_appointments
      mobile_lock_tasks_when_mobile_active
      module_extended_forms_active
      module_qualifications_active
      module_devices_active
    }
  }
`;

export function useUpdateModuleSettingsMutation() {
  return Urql.useMutation<UpdateModuleSettingsMutation, UpdateModuleSettingsMutationVariables>(
    UpdateModuleSettingsDocument,
  );
}
