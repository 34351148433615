import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import { TaskReportFieldsFragmentDoc } from './useTaskReportsQuery.generated';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CreateTaskReportMutationVariables = Types.Exact<{
  task_id: Types.Scalars['ID'];
  input: Types.CreateTaskReportInput;
  options: Types.TaskReportOptionsInput;
}>;

export type CreateTaskReportMutation = {
  __typename?: 'Mutation';
  createTaskReport: {
    __typename?: 'TaskReport';
    id: string;
    title: string;
    created_at: string;
    file?: {
      __typename?: 'FileItem';
      id: string;
      name: string;
      url: string;
      url_thumb?: string | null;
      size: number;
      mime_type?: string | null;
      is_extracting_pages: boolean;
      permissions: Array<Types.Permission>;
      classification_tags: {
        __typename?: 'FileClassificationTagList';
        data: Array<{
          __typename?: 'FileClassificationTag';
          id: string;
          title: string;
          color: string;
          alias: string;
          type_id: Types.FileClassificationTagTypeId;
        }>;
      };
    } | null;
  };
};

export const CreateTaskReportDocument = gql`
  mutation CreateTaskReport(
    $task_id: ID!
    $input: CreateTaskReportInput!
    $options: TaskReportOptionsInput!
  ) {
    createTaskReport(task_id: $task_id, input: $input, options: $options) {
      ...TaskReportFields
    }
  }
  ${TaskReportFieldsFragmentDoc}
`;

export function useCreateTaskReportMutation() {
  return Urql.useMutation<CreateTaskReportMutation, CreateTaskReportMutationVariables>(
    CreateTaskReportDocument,
  );
}
