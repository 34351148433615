import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import { InterfaceMessageTemplateCategoryFragmentDoc } from './interfaceMessageTemplateCategoryFragment.generated';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type InterfaceMessageTemplateCategoriesQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type InterfaceMessageTemplateCategoriesQuery = {
  __typename?: 'Query';
  interfaceMessageTemplateCategories: Array<{
    __typename?: 'InterfaceMessageTemplateCategory';
    id: string;
    title: string;
  }>;
};

export const InterfaceMessageTemplateCategoriesDocument = gql`
  query InterfaceMessageTemplateCategories {
    interfaceMessageTemplateCategories {
      ...InterfaceMessageTemplateCategory
    }
  }
  ${InterfaceMessageTemplateCategoryFragmentDoc}
`;

export function useInterfaceMessageTemplateCategoriesQuery(
  options?: Omit<Urql.UseQueryArgs<InterfaceMessageTemplateCategoriesQueryVariables>, 'query'>,
) {
  return Urql.useQuery<
    InterfaceMessageTemplateCategoriesQuery,
    InterfaceMessageTemplateCategoriesQueryVariables
  >({ query: InterfaceMessageTemplateCategoriesDocument, ...options });
}
