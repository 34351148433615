export default {
  labels: {
    openSettings: 'Einstellungen öffnen',
  },
  appModules: {
    title: 'App Module',
    description: 'Hier können Sie die Environmnent Variablen für Ihre App Module konfigurieren.',
    notifications: {
      updateSuccess: 'App modul wurde aktualisiert',
      toggleActive: {
        activateSuccess: 'App Modul wurde aktiviert',
        deactivateSuccess: 'App Modul wurde deaktiviert',
      },
    },
    dialogs: {
      update: {
        title: 'App Modul bearbeiten',
        description: 'Hier können Sie das App Modul bearbeiten.',
      },
    },
    formFields: {
      title: 'Titel',
      description: 'Beschreibung',
      active: 'Aktiviert',
      variables: 'Variablen',
      value: 'Wert',
      secretHint:
        'Aus Sicherheitsgründen wird der Wert nicht angezeigt. Sie können den Wert jedoch überschreiben.',
    },
  },
  overviewPage: {
    title: 'Einstellungen',
    description:
      'Einstellungen - hier finden Sie alle Einstellungen zum System. Anbei einige der wichtigsten Einstellungen zur Konfiguration Ihres Systemes.',
    goToLinkTitle: 'Zu den Einstellungen',
    label: {
      settings: 'Einstellungen',
      template: 'Vorlage',
    },
  },
  emailTransport: {
    title: 'E-Mail Transport',
    description: 'Hier können Sie verschiedene Methoden für den E-Mail Transport konfigurieren.',
    addTransport: 'E-Mail Transport hinzufügen',
    type: 'Typ',
    editTransport: 'Transport bearbeiten',
    deleteTransport: 'Transport löschen',
    testTransport: 'Test E-Mail senden',
    labels: {
      title: 'Titel',
      type: 'Typ',
      active: 'Aktiv',
    },
    transportTypes: {
      SMTP: 'SMTP',
    },
    dialogs: {
      create: {
        title: 'E-Mail Transport hinzufügen',
        description: 'Konfigurieren Sie hier den E-Mail Transport.',
        success: 'E-Mail Transport wurde erstellt',
      },
      update: {
        title: 'E-Mail Transport bearbeiten',
        description: 'Hier können Sie den E-Mail Transport bearbeiten.',
        success: 'E-Mail Transport aktualisiert',
      },
      delete: {
        title: 'E-Mail Transport löschen?',
        description: 'Sind Sie sicher, dass sie den Email Transport löschen wollen?',
      },
    },
    smtp: {
      passwordUpdatePlaceholder: 'Leer lassen, um das Passwort nicht zu ändern.',
      smtpHost: 'Host',
      smtpPort: 'Port',
      smtpUsername: 'Benutzername',
      smtpPassword: 'Passwort',
      smtpEncryption: 'Verschlüsselung',
      encryptionOptions: {
        SSL: 'SSL',
        TLS: 'TLS',
      },
    },
    notifications: {
      activateSuccess: 'E-Mail Transport aktiviert',
      deleteSuccess: 'E-Mail Transport gelöscht',
    },
  },
  buttonGroup: {
    none: 'Aus',
    inapp: 'In-App',
    email: 'E-Mail',
  },
  loginPage: {
    title: 'Login Seite',
    description: 'Hier können Sie Details zur Login Seite definieren.',
    welcometext: 'Willkommenstext',
    welcometextDesc: 'Dies ist der Text, der unter dem Logo angezeigt wird.',
    imprint: 'Impressum',
    conditions: 'AGB',
    privacy: 'Datenschutz',
  },
  loginLogo: {
    title: 'Login Logo',
    description: 'Laden Sie hier das Logo für die Einstiegsseite hoch.',
  },
  loginBackground: {
    title: 'Login Hintergrund',
    description:
      'Wählen Sie ein passendes Hintergrundbild für den rechten Bereich der Einstiegsseite.',
  },
  reportBackground: {
    title: 'Bericht Hintergrund',
    description: 'Wählen Sie ein passendes Hintergrundbild für deine Bericht-Seite.',
  },
  uploadTab: {
    title: 'Logos & Bilder',
    description: 'Laden Sie hier die passende Bilder für die App hoch.',
    confirmDelete: {
      title: 'Datei Löschen?',
      description: 'Möchten Sie diese Datei wirklich löschen?',
    },
  },
  reportLogo: {
    title: 'Bericht Logo',
    description: 'Laden Sie hier das Logo für die Bericht-Titelseite hoch.',
  },
  alternativeReportLogo: {
    title: 'Alternatives Berichts Logo',
    description: 'Laden Sie hier ein alternatives Logo für die Bericht-Titelseite hoch.',
  },
  ipTab: {
    title: 'IP Allowlist Adressen',
    description: 'Fügen Sie hier IP Adressen hinzu, von denen Sie Zugriff erlauben.',
    notifications: {
      ipDeleted: 'IP erfolgreich gelöscht!',
      create: {
        success: 'IP erfolgreich erstellt!',
      },
    },
    confirmEnable: {
      title: 'IP Allowlist aktivieren?',
      description:
        'Sind Sie sicher, dass sie die IP Allowlist aktivieren wollen? Nur Geräte, die sich mit einer IP Adresse zu Plattform verbinden, welche auch in der Allowlist hinterlegt ist, können die Plattform nutzen.',
    },
    labels: {
      ip: 'IP Addresse',
      addIp: 'IP hinzufügen',
      description: 'Beschreibung',
      user: 'Nutzer',
      createdAt: 'Erstellt am',
      createdBy: 'Erstellt durch',
      currentConnection: 'Akuelle Verbindung',
      state: 'Status',
    },
    test: {
      ip: 'Bitte geben Sie eine valide IPv4 oder IPv6 Addresse ein.',
    },
    confirmDelete: {
      title: 'Möchten Sie die IP löschen?',
      description: 'Möchten Sie die IP dauerhaft löschen.',
    },
    dialog: {
      title: 'IP Adressen Allowlist',
      note: 'Fügen Sie eine IP Adressen zur Allowlist hinzu.',
    },
  },
  invoicesTab: {
    title: 'Rechnungen',
    description: 'Konfigurieren Sie hier die Einstellungen für die Erzeugung von Rechnungen.',
    paymentInformation: {
      title: 'Zahlungsinformationen',
      description: 'Hier können Sie Ihre Zahlungsinformationen eingeben.',
    },
    invoicesOf: {
      title: 'Rechnung von',
      description: 'Ihre Unternehmens Informationen.',
    },
    extended: {
      title: 'Erweitert',
      description: 'Weitere Einstellungen.',
    },
    labels: {
      invoice_from_name: 'Name',
      invoice_from_street: 'Strasse',
      invoice_from_postcode: 'Postleitzahl',
      invoice_from_city: 'Stadt',
      invoice_from_country_id: 'Land',
      invoice_from_phone: 'Telefon',
      invoice_from_email: 'E-Mail',
      invoice_from_web: 'Web',
      invoice_from_bank_title: 'Name der Bank',
      invoice_from_bank_iban: 'IBAN',
      invoice_from_bank_swift: 'BIC/SWIFT',
      invoice_from_commercial_register: 'Handelsregisternummer',
      invoice_from_ceo: 'Geschäftsführer',
      invoice_from_vat_title: 'Finanzamt Name',
      invoice_from_vat_id: 'Umsatzssteuer-ID',
      invoice_from_invoice_number_prefix: 'Kürzel für Rechnungsnummer (z.B.: 2023 oder RE)',
    },
  },
  menuLogo: {
    title: 'Menü Logo',
    description: 'Wählen Sie das passende Menü Icon.',
  },
  colorTab: {
    title: 'Farben',
    description: 'Wählen Sie hier die Farben für Ihr App.',
  },
  primaryColor: {
    title: 'Primär Farbe',
    description: 'Wählen Sie ihre Primär Farbe.',
  },
  generalTab: {
    title: 'Allgemeine Einstellungen',
    description: 'Hier können Sie allgemeine Einstellungen für die App vornehmen.',
  },
  modulesTab: {
    title: 'Module',
    description: 'Hier können Sie definieren, welche Module aktiv sind',
  },
  assignmentsTab: {
    title: 'Zuweisungen',
    description: 'Hier können Sie die Zuweisungen definieren.',
  },
  assignments: {
    title: 'Zuweisungen',
    description: 'Hier können Sie die Zuweisungen definieren.',
    labels: {
      assignment_auto_active: 'Auto Zuweisung',
      assignment_type: 'Zuweisungs-Art',
      assignment_max_tasks_per_week: ' Maximale Aufträge pro Woche pro User',
      assignment_max_tasks_week_start_day: 'Reset des Wochencounters ab Tag',
      assignment_max_tasks_max_per_day: 'Maximale Aufträge pro Tag',
    },
  },
  pushNotificationsTab: {
    title: 'Push-Benachrichtigungen',
    description: 'Konfigurieren Sie das Versenden von Push-Benachrichtigungen',
  },
  activatePushNotifications: {
    title: 'Aktiviere Push-Benachrichtigungen',
    description: 'Hier können Sie den Versand von Nachrichten an Mobilgeräte aktivieren.',
    labels: {
      activate: 'Aktiviere Push-Benachrichtigungen',
    },
  },
  configurePushNotifications: {
    title: 'Push-Benachrichtigungen Konfiguration',
    description:
      'Kopieren Sie hier Ihre APNS Konfiguration herein, damit das System mit den mobilen Geräten (iOS und Android) kommunizieren kann.',
    labels: {
      configure: 'Push-Benachrichtigungen Konfiguration',
    },
  },
  appTitle: {
    title: 'Plattform Titel',
    description: 'Hier können Sie den Titel der Plattform ändern.',
    labels: {
      appTitle: 'Titel',
    },
  },
  currencies: {
    title: 'Kalkulation',
    description: 'Legen Sie hier die Einstellungen für die Kalkulation fest.',
    labels: {
      currencies: 'Währung',
      tax: 'Steuern in %',
    },
  },
  defaultCountry: {
    title: 'Land',
    description: 'Legen Sie das Standardland fest.',
  },
  calendar: {
    title: 'Kalendar',
    description: 'Definieren Sie hier Standardeinstellungen für die Kalender',
    labels: {
      show_weekends: 'Zeige Wochenenden',
      calendar_driving_times: 'Anzeige der Fahrzeiten',
      driving_times_off: 'Aus',
      driving_times_simple: 'Einfach',
      driving_times_extended: 'Erweitert',
    },
  },
  theme: {
    title: 'Farbthema',
    description: 'Legen Sie das Farbthema fest.',
  },
  webservice: {
    title: 'REST Webservice',
    description: 'Hier können Sie den REST Webservice für das Portal aktivieren oder deaktivieren.',
    labels: {
      webservice_active: 'REST Webservice',
    },
  },
  gdv: {
    title: 'GDV Webservice',
    description:
      'Hier können Sie das klassische GDV Webservice aktivieren. Hierzu benötigen Sie einen separaten Client, damit GDV Nachrichten verschickt werden können.',
    labels: {
      activate_legacy_gdv: 'GDV Webservice',
    },
  },
  mailTab: {
    title: 'E-Mail Einstellungen',
    description:
      'Hier können alle Einstellungen zum E-Mail Versand konfiguriert werden. Für den Versand von E-Mails ist ein funktionierender E-Mail Server notwendig.',
  },
  moduleTab: {
    title: 'Module',
    description: 'Hier können Sie definieren, welche Module aktiv sind',
  },
  modules: {
    title: 'Module für Auftragsbearbeitung',
    description:
      'Hier können Sie die Sichtbarkeit der Module in in der Auftrags-Detailseite konfigurieren.',
    labels: {
      images: 'Bildmodule',
      documents: 'Dokumente',
      calculations: 'Kalkulationen',
      roomPlan: 'Raum-Plan',
      mails: 'E-Mails',
      customerPortal: 'Kunden-Portal',
      statistics: 'Statistik',
      invoices: 'Rechnungen',
      logs: 'Berichte',
      subTasks: 'Aufgaben',
      additionalForms: 'Unterformulare',
      qualifications: 'Qualifikationen',
    },
  },
  rework: {
    title: 'Nacharbeitenmodul',
    description: 'Hier können Sie das Nacharbeitenmodul konfigurieren.',
    labels: {
      rework: 'Nachbearbeiten aktivieren',
      rework_states: 'Nachbearbeiten Status aktivieren',
    },
  },
  invoices: {
    title: 'Rechnungsmodul',
    description: 'Hier können Sie die Erzeugung von Rechnungen konfigurieren.',
    labels: {
      invoices: 'Rechnungen',
    },
  },
  proposals: {
    title: 'Angebotsmodul',
    description: 'Hier können Sie die Erzeugung von Angeboten konfigurieren.',
    labels: {
      invoices: 'Angebote',
    },
  },
  devicesModule: {
    title: 'Geräte',
    description: 'Hier können Sie die Sichtbarkeit des Gerätemoduls konfigurieren.',
    labels: {
      module_devices_active: 'Geräte',
    },
  },
  calculationModule: {
    title: 'Kalkulations Einstellungen',
    description:
      'Hier können Sie die Sichtbarkeit einzelner Spalten in der Kalkulation ändern. Die Änderungen wirken sich auf alle zukünftigen Aufträge mit Kalkulationen aus.',
    labels: {
      hideStatusColumn: 'Spalte "Status" ausblenden',
      hideAcceptedColumn: 'Spalte "Akzeptiert" ausblenden',
      hidePayoutStateColumn: 'Spalte "Bezahlstatus" ausblenden',
      calculationDefaultNet: 'Netto Kalkulation als Standard',
    },
  },
  appSettingsModule: {
    title: 'Mobile App Einstellungen',
    description: 'Hier können Sie Konfigurationen zur Verwendung der mobilen Apps bestimmen.',
    labels: {
      mobile_app_active: 'App Nutzung aktivieren',
      mobile_can_accept: 'App Nutzer darf Aufträge annehmen',
      mobile_can_set_appointments: 'App Nutzer darf Termine setzen',
      mobile_lock_tasks_when_mobile_active: 'Aufträge sind bei Benutzung der App gesperrt',
    },
  },
  mail: {
    title: 'SMTP-Server',
    description: 'Geben Sie hier die Zugangsdaten und die Addresse Ihres SMTP-Servers ein.',
    labels: {
      active: 'Aktiviere E-Mail Versand',
      port: 'Port',
      host: 'Host',
      username: 'Username',
      password: 'Passwort',
      encryption: 'Verschlüsselung',
      fromAddress: 'Absender-Adresse',
      fromName: 'Absender-Name',
      defaultFooter: 'Standard-Fusszeile',
      prefix: 'Prefix',
      mailSendFailureNotice: 'Fehlerbenachrichtigung versenden?',
    },
    updateSMTPSettingsDialog: {
      title: 'SMTP Einstellungen',
    },
    confirmReset: {
      title: 'SMTP Einstellungen zurücksetzen',
      description: 'Möchten Sie die SMTP Einstellungen wirklich zurücksetzen?',
    },
  },
  activateEmailDelivery: {
    title: 'Aktiviere E-Mail Versand',
    description: 'Hier können Sie den Versand von E-Mails aktivieren.',
  },
  mailSettings: {
    title: 'E-Mail Einstellungen',
    description: 'Hier finden Sie noch allgemeine Einstellungen zum Versand.',
  },
  notifications: {
    title: 'Benachrichtigungen',
    description: 'Wählen Sie, wie Plattform-Benutzer informiert werden sollen.',
  },
  profileUserSettings: {
    title: 'Nutzerdaten',
  },
  browserSettings: {
    title: 'Browser-Einstellungen',
    description: 'Browser-Einstellungen werden nur im aktuellen Browser gespeichert',
    taskAfterLogin: 'Auftragsliste/Karte soll nach Login angezeigt werden',
    openTaskInNewTab: 'Aufträge als Standard in einem neuen Tab öffnen',
    openClientInNewTab: 'Stammdaten als Standard in einem neuen Tab öffnen',
    stickyFormHeader: 'Formular-Kopf soll immer oben angezeigt werden',
  },
  profileImage: {
    title: 'Profilbild',
    description: 'Laden Sie hier ihr Profilbild hoch',
    confirmDelete: {
      title: 'Profilbild Löschen?',
      description: 'Möchten Sie ihr Profilbild wirklich löschen?',
    },
  },
  clientNotifications: {
    title: 'Benachrichtigungen',
  },
  changePasswordSelf: {
    title: 'Passwort ändern',
    description: 'Ändern Sie hier Ihr Passwort',
    success: 'Passwort erfolgreich geändert',
  },
  password: {
    title: 'Passwort',
    description: 'Hier können Sie die Mindestanforderung für Passwörter einstellen.',
    labels: {
      auth_password_min_length: 'Mindestlänge',
      auth_password_min_special: 'Anzahl an Sonderzeichen',
      auth_password_min_upper_case: 'Anzahl an Großbuchstaben',
      auth_password_min_numbers: 'Anzahl an Zahlen',
    },
  },
  auth: {
    title: 'Authentifizierung',
    description: 'Hier können Sie die Gültigkeitsdauer der Authentifizierung einstellen.',
    labels: {
      max_session_duration: 'Sessionlänge / Sessiondauer',
      single_session: 'Man kann sich nur auf einem Gerät einloggen',
      standard: 'Standard',
      quarter_hour: '15 Minuten',
      half_hour: '30 Minuten',
      one_hour: '1 Stunde',
      two_hours: '2 Stunden',
      four_hours: '4 Stunden',
      twofa_active: '2 Wege Authentifizierung aktiv',
      twofa_sms: 'Token per SMS',
      twofa_email: 'Token per E-Mail',
      yes: 'Ja',
      no: 'Nein',
    },
  },
  autoLogout: {
    title: 'Automatische Abmeldung',
    description:
      'Legen Sie fest, ob Benutzer nach Inaktivität automatisch abgemeldet werden sollen.',
    labels: {
      auto_logout_timeout: 'Automatische Abmeldung nach',
    },
  },
  createApiToken: {
    labels: {
      createdAtDate: 'Erstellt am',
      expiresAtDate: 'Läuft ab am',
      revoked: 'Gesperrt',
      uuid: 'Uuid',
    },
    title: 'API Token',
    description:
      'Sie können hier einen API Token für den automatisierten Zugriff auf das System erstellen.',
    create: 'API Token generieren',
    copied: 'API Token kopiert!',
    confirm: {
      title: 'Api Token Löschen',
      description: 'Den Api Token dauerhaft löschen?',
    },
  },
  mapsTab: {
    title: 'Karteneinstellungen',
    description: 'Hier können Sie Einstellungen für die Karten in der App vornehmen.',
  },
  mapBounds: {
    title: 'Kartenausschnitt',
    description: 'Stellen Sie Ihren bevorzugen Kartenausschnitt ein.',
  },
  tasks: {
    title: 'Prozesse',
    description: 'Konfigurieren Sie hier allgemeine Prozesse.',
    generalSection: {
      title: 'Allgemeine Einstellungen',
      description: 'Hier können Sie allgemeine Prozesseinstellungen finden',
      labels: {
        tasks_leader_visible_all:
          'Teamleiter können alle Aufträge sehen (nicht nur ihnen zugewiesene Aufträge)',
        auto_accept_task:
          'Automatische Auftragsannahme - der Auftrag wird automatisch um 22:00 von den Regulierenden auf “angenommen” gesetzt.',
        statistic_required_for_controlling:
          'Die Statistik muss ausgefüllt sein (Pflichtfelder), bevor der Auftrag ins Controlling übermittelt werden kann.',
        statistic_required_for_completion:
          'Die Statistik muss ausgefüllt sein (Pflichtfelder), bevor der Auftrag als abgeschlossen markiert werden kann.',
        permissions_delete_files_admin_only: 'Nur die Admins dürfen Dateien löschen.',
        adjuster_can_create_tasks: 'Regulierer kann Aufträge erstellen',
        hide_drive_log: 'Verstecke Fahrtzeiten-Eingabe unter Aufträge',
        activate_task_default_off: 'Auftrag direkt freigeben ausschalten',
        leader_can_assign: 'Teamleiter kann Aufträge zuweisen',
        msg_convert_single_file: 'Outlook MSG Dateianhänge in eine Datei umwandeln',
        everybody_can_see_finished_tasks: 'Jeder kann abgeschlossene Aufträge sehen',
        create_task_auto_fill_client:
          'Beim Erstellen eines Auftrages, übernehme von Block Basisdaten den Ansprechpartner/Versicherungsnehmer',
        create_task_auto_fill_client_address:
          'Beim Erstellen eines Auftrages, übernehme von Block Basisdaten den Besichtigungsort',
        allow_adjuster_to_send_emails: 'Regulierer darf E-Mails senden',
        adjuster_can_duplicate_own_tasks: 'Regulierer kann eigene Aufträge duplizieren',
        adjuster_can_change_task_location: 'Regulierer kann Besichtigungsort anpassen',
        adjuster_can_set_rework: 'Regulierer kann den Auftrag in Nacherfassung setzen',
        adjuster_can_send_interface: 'Regulierer kann Dateien per Schnittstelle senden',
        disable_task_type_on_desk: 'Deaktiviere Auftragstyp On-Desk',
        disable_task_type_video: 'Deaktiviere Auftragstyp Video',
        log_mails_in_task_log: 'Protokolliere Mails in der Akte',
        load_predefined_email: 'Lade Versicherung automatisch bei Nachrichten',
      },
    },
    tasksSection: {
      title: 'Aufträge Einstellungen',
      description: 'Hier können Sie allgemeine Auftragseinstellungen finden',
      tasks_max_sub_owner_count_hint:
        'Wird hier ein Wert größer 0 eingetragen, ist es möglich, dass dem Auftrag zu dem normalen Regulierer, weitere Vertretungen hinzugefügt werden können. Diese Vertretungen können dann auch Aufgaben im Auftrag übernehmen.',
      labels: {
        tasks_delete_tags_on_complete:
          'Auftrags-Klassifizierung bei Abschluss automatisch löschen.',
        tasks_fields_identifier_active: 'Schadensnummer im Formular anzeigen',
        tasks_fields_external_number_active: 'Fremdsystem-Nummer im Formular anzeigen',
        tasks_fields_parallel_number_active: 'Parallel- / Folgeauftrag im Formular anzeigen',
        tasks_fields_urgent_active: 'Eilauftrag im Formular anzeigen',
        tasks_fields_client_contact_id_active: 'Sachbearbeiter im Formular anzeigen',
        tasks_filter_cancelled: 'Stornierte Aufträge ausblenden',
        tasks_hide_duplicate: 'Deaktiviere Auftrag duplizieren Funktion',
        tasks_allow_send_sms: 'Aktiviere SMS Versand in Aufträgen',
        tasks_notify_owner_on_create:
          'Regulierer erhält eine Nachricht, wenn ein Auftrag für ihn angelegt wurde',
        tasks_notify_owner_on_new_file:
          'Regulierer erhält eine Nachricht, wenn eine Datei in den Ordner Nacharbeiten hochgeladen wurde',
        tasks_auto_generate_info_file:
          'Bei Auftragsanlage soll ein Info-Blatt mit den wichtigsten Informationen als Datei abgelegt werden',
        tasks_auto_assign_leader_when_empty:
          'Teamleiter werden automatisch zugewiesen, falls diese nicht gesetzt wurden.',
        tasks_num_routes: 'Anzahl der Routen, die als Regulierer auf der Karte angezeigt werden',
        tasks_max_sub_owner_count: 'Maximale Anzahl an Vertretungen (pro Auftrag)',
        tasks_fields_group_active: 'Gruppierung kann bei Auftragsanlage eingetragen werden',
        tasks_cleanup_forms_interval:
          'Anzahl Tage, nachdem in Papierkorb verschobene Unterformulare gelöscht werden ',
      },
    },
    reportSection: {
      title: 'Globale Berichtseinstellungen',
      description: 'Hier können Sie globale Einstellungen zu den Berichten vornehmen',
      labels: {
        show_summary_service_provider_block: 'Zeige Dienstleister Block in Zusammenfassung',
        global_phone: 'Globale Kontakt-Telefonnummer',
        global_mail: 'Globale Kontakt-E-Mail',
        max_export_pages: 'Anzahl PDF-Seiten, die maximal im Bericht exportiert werden',
        show_adjuster_email_service_provider_block:
          'Zeige Regulierer E-Mail in Dienstleister-Block',
      },
    },
    logSection: {
      title: 'Sichtbarkeit und Datenschutz',
      description: 'Hier können Sie globale Einstellungen zur Sichtbarkeit vornehmen',
      labels: {
        hide_log_times: 'Verstecke Uhrzeiten in Logs',
      },
    },
  },
  dialogs: {
    profileSettings: {
      title: 'Profileinstellungen',
      description: 'Hier können Sie Ihre Profileinstellungen vornehmen.',
    },
  },
  subnavigation: {
    general: 'Allgemein',
    login: 'Login Seite',
    uploads: 'Logos und Bilder',
    email: 'E-Mail',
    emailTransport: 'E-Mail Transport',
    notification: 'Benachrichtigungen',
    modules: 'Module',
    appModules: 'App Module',
    assignments: 'Zuweisungen',
    maps: 'Karten',
    ip: 'IP Allowlist',
    push: 'Push-Nachrichten',
    tasks: 'Prozesse',
    invoices: 'Rechnungen',
    appMessages: 'App Nachrichten',
    apiTokens: 'API Tokens',
  },
  pages: {
    platform: {
      title: 'App-Einstellungen',
      description: 'Hier finden Sie eine Übersicht der Einstellungen.',
    },
    qualifications: {
      title: 'Qualifikationen',
      description:
        'Hier können Sie eigene Qualifikationen anlegen um Ihre Aufträge mit einer Kategorien zu versehen. Nach diesen Kriterien können Sie auch filtern.',
      labels: {
        name: 'Name',
        color: 'Farbe',
      },
      dialogs: {
        create: {
          title: 'Qualifikation anlegen',
          note: 'Wählen Sie einen Namen und eine Farbe.',
        },
        update: {
          title: 'Qualifikation bearbeiten',
          note: 'Ändern Sie den Namen oder die Farbe.',
        },
        delete: {
          title: 'Qualifikation löschen',
          note: 'Wollen Sie die Qualifikation wirklich löschen?',
        },
        exports: {
          title: 'Qualifikationen exportieren',
          note: 'Laden Sie die exportierten Qualifikationen herunter.',
        },
      },
      notifications: {
        create: {
          success: 'Qualifikation gespeichert!',
        },
        delete: {
          success: 'Qualifikation gelöscht!',
          error: 'Qualifikation konnte nicht gelöscht werden, da sie noch verwendet wird!',
        },
      },
    },
    classificationTags: {
      title: 'Auftrags-Klassifikation',
      description:
        'Hier können Sie eigene Kategorien anlegen, um Ihre Aufträge mit einer Kategorie zu versehen. Nach diesen Kriterien können Sie auch filtern.',
      labels: {
        name: 'Name',
        alias: 'Alias',
        api_only: 'Wird nur von der API verwendet',
        color: 'Farbe',
      },
      dialogs: {
        create: {
          title: 'Klassifikation anlegen',
          note: 'Wählen Sie einen Namen und eine Farbe.',
        },
        delete: {
          title: 'Klassifikation löschen',
          note: 'Wollen Sie die Klassifikation wirklich löschen?',
        },
        exports: {
          title: 'Auftrags-Klassifizierung exportieren',
          note: 'Laden Sie die exportierten Auftrags-Klassifizierung herunter.',
        },
      },
      notifications: {
        create: {
          success: 'Auftrags-Klassifizierung gespeichert!',
        },
        delete: {
          success: 'Auftrags-Klassifizierung gelöscht!',
          error:
            'Auftrags-Klassifizierung konnte nicht gelöscht werden, da sie noch verwendet wird!',
        },
      },
    },

    fileClassificationTags: {
      title: 'Datei-Klassifikation',
      description:
        'Hier können Sie eigene Kategorien anlegen, um Ihre Dateien mit einer Kategorien zu versehen. Nach diesen Kriterien können Sie auch filtern.',
      labels: {
        name: 'Name',
        alias: 'Alias',
        api_only: 'Wird nur von der API verwendet',
        color: 'Farbe',
      },
      dialogs: {
        create: {
          title: 'Klassifikation anlegen',
          note: 'Wählen Sie einen Namen und eine Farbe.',
        },
        delete: {
          title: 'Klassifikation löschen',
          note: 'Wollen Sie die Klassifikation wirklich löschen?',
        },
        exports: {
          title: 'Datei-Klassifikationen exportieren',
          note: 'Laden Sie die exportierten Datei-Klassifikationen herunter.',
        },
      },
      notifications: {
        create: {
          success: 'Datei-Klassifizierung gespeichert!',
        },
        delete: {
          success: 'Datei-Klassifizierung gelöscht!',
          error: 'Datei-Klassifizierung konnte nicht gelöscht werden, da sie noch verwendet wird!',
        },
      },
    },
    subformClassificationTags: {
      title: 'Unterformular-Klassifikation',
      description:
        'Hier können Sie eigene Kategorien anlegen, um Ihre Unterformulare mit einer Kategorie zu versehen. Nach diesen Kriterien können Sie auch filtern.',
      labels: {
        name: 'Name',
        color: 'Farbe',
        type: 'Typ',
      },
      dialogs: {
        create: {
          title: 'Klassifikation anlegen',
          note: 'Wählen Sie einen Namen, eine Farbe und einen Typ.',
        },
        edit: {
          title: 'Klassifikation ändern',
          note: 'Hier können Sie die Klassifikation ändern.',
        },
        delete: {
          title: 'Klassifikation löschen',
          note: 'Wollen Sie die Klassifikation wirklich löschen?',
        },
        exports: {
          title: 'Unterformular-Klassifikation exportieren',
          note: 'Laden Sie die exportierten Unterformular-Klassifikation herunter.',
        },
      },
      notifications: {
        create: {
          success: 'Unterformular-Klassifikation gespeichert!',
          error: 'Unterformular-Klassifikation bereits vorhanden!',
        },
        edit: {
          success: 'Änderungen gespeichert!',
          error: 'Unterformular-Klassifikation bereits vorhanden!',
        },
        delete: {
          success: 'Unterformular-Klassifikation gelöscht!',
          error:
            'Unterformular-Klassifikation konnte nicht gelöscht werden, da sie noch verwendet wird!',
        },
      },
    },
    taskGroups: {
      title: 'Auftrags-Gruppierungen',
      description:
        'Hier können Sie eigene Gruppierungen anlegen, um Ihre Aufträge mit einer Gruppierungen zu versehen. Nach diesen Kriterien können Sie auch filtern.',
      labels: {
        name: 'Name',
        color: 'Farbe',
      },
      dialogs: {
        create: {
          title: 'Gruppierung anlegen',
          note: 'Wählen Sie einen Namen und eine Farbe.',
        },
        edit: {
          title: 'Gruppierung ändern',
          note: 'Hier können Sie die Gruppierung ändern.',
        },
        delete: {
          title: 'Gruppierung löschen',
          note: 'Wollen Sie die Gruppierung wirklich löschen?',
        },
        exports: {
          title: 'Auftrags-Gruppierung exportieren',
          note: 'Laden Sie die exportierten Auftrags-Gruppierung herunter.',
        },
      },
      notifications: {
        create: {
          success: 'Auftrags-Gruppierung gespeichert!',
          error: 'Auftrags-Gruppierung bereits vorhanden!',
        },
        edit: {
          success: 'Änderungen gespeichert!',
          error: 'Auftrags-Gruppierung bereits vorhanden!',
        },
        delete: {
          success: 'Auftrags-Gruppierung gelöscht!',
          error: 'Auftrags-Gruppierung konnte nicht gelöscht werden, da sie noch verwendet wird!',
        },
      },
    },
  },
  userDetails: {
    title: 'Benutzer Details',
    description: 'Hier können Sie ihre Benutzer Details anpassen.',
  },
  userPassword: {
    title: 'Nutzerpasswort ändern',
    description: 'Wählen Sie ein sicheres Passwort.',
  },
  userRole: {
    title: 'Benutzerrolle anpassen',
    description: 'Wählen Sie hier Ihre Benutzerrolle.',
  },
  emailVerification: {
    title: 'Status',
    description: 'Sehen Sie hier den Status ihrer E-Mail Bestätigung',
  },
  invitationLink: {
    title: 'Einladungs-Link',
    description: 'Den Einladungs-Link für den Benutzer erneut versenden',
  },
  general: {
    notifications: {
      success: 'Erfolgreich gespeichert.',
      error: 'Leider ist ein Fehler aufgetreten.',
    },
  },
  appMessages: {
    dialogs: {
      create: {
        title: 'Nachricht erstellen',
        description: 'Hier können Sie eine neue Nachricht erstellen.',
        success: 'Nachricht wurde erstellt.',
      },
      update: {
        title: 'Nachricht bearbeiten',
        description: 'Hier können Sie eine Nachricht bearbeiten',
        success: 'Nachricht wurde geändert.',
      },
      delete: {
        title: 'Nachricht löschen?',
        description: 'Möchten Sie die Nachricht wirklich löschen?',
        success: 'Nachricht wurde gelöscht.',
      },
      changeActiveState: {
        title: 'Status ändern?',
        description: 'Möchten Sie den Status der Nachricht wirklich ändern?',
        success: 'Status wurde geändert.',
      },
    },
    title: 'App Nachrichten',
    description:
      'Hier können Sie globale Nachrichten erstellen, die allen Benutzern in deren Dashboard-Seite angezeigt werden.',
    typeOptions: {
      INFO: 'Information',
      SUCCESS: 'Erfolgsnachricht',
      ERROR: 'Fehler',
      WARNING: 'Warnung',
    },
    labels: {
      title: 'Titel',
      body: 'Nachricht',
      active: 'Aktiv',
      visible_from: 'Sichtbar von',
      visible_to: 'Sichtbar bis',
      type: 'Typ',
    },
    gridNoRecords: {
      title: 'Keine Nachrichten vorhanden.',
      text: 'Leg jetzt eine neue Nachricht an.',
      create: 'Nachricht erstellen',
    },
  },
  ui: {
    title: 'Benutzeroberfläche',
    labels: {
      ui_form_debounce_interval: 'Speicherungs Interval für Formulare in (ms)',
    },
  },
};
