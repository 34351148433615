import {
  checkPasswordLength,
  checkPasswordNumbers,
  checkPasswordSpecial,
  checkPasswordUppercase,
} from '@module/common/helpers';
import { usePasswordSettings } from '@module/common/hooks/usePasswordSettings';
import { useTranslation } from 'react-i18next';
import { string } from 'yup';

export const useYupPasswordValidation = () => {
  const { t } = useTranslation();
  const settings = usePasswordSettings();

  return () =>
    string().test('password-strength', t('validation.password.strength'), function (value, ctx) {
      const errors = [];
      if (!value?.length) return true;
      if (!checkPasswordLength(value, settings))
        errors.push(t('validation.password.chain.length', { min: settings.minLength }));
      if (!checkPasswordNumbers(value, settings))
        errors.push(t('validation.password.chain.numbers', { min: settings.minNumbers }));
      if (!checkPasswordSpecial(value, settings))
        errors.push(t('validation.password.chain.special', { min: settings.minSpecial }));
      if (!checkPasswordUppercase(value, settings))
        errors.push(t('validation.password.chain.uppercase', { min: settings.minUpperCase }));

      if (errors.length > 0) {
        return ctx.createError({
          message: {
            key: 'validation.password.chain.general',
            params: { chain: errors.join(', ') },
          },
          path: ctx.path,
        });
      }

      return true;
    });
};
