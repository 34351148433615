import {
  Ability,
  AbilityBuilder,
  AbilityClass,
  ForcedSubject,
  subject as subjectHelper,
} from '@casl/ability';
import { useCallback, useMemo } from 'react';

import { Permission } from '../../../types/graphql.generated';
import { GenericCan, GenericCanSharedProps } from '../components/GenericCan';
import { CurrentUserAbilityFragment } from '../graphql';
import { detectSubjectTypeByTypename as detectSubjectType } from '../helpers';
import { useMeOrDefault } from '../hooks';

interface AddressBookSubject {
  me: CurrentUserAbilityFragment;
}

type AddressBookSubjectArg = Partial<AddressBookSubject>;

type Actions =
  | 'viewAddressBookEntries'
  | 'viewAddressBookEntry'
  | 'createAddressBookEntry'
  | 'updateAddressBookEntry'
  | 'deleteAddressBookEntry';
type Subjects = AddressBookSubject | 'AddressBookSubject';

type AddressBookAbility = Ability<[Actions, Subjects]>;
const addressBookAbility = Ability as AbilityClass<AddressBookAbility>;

export const useAddressBookAbility = (): [
  AddressBookAbility,
  (sub?: AddressBookSubjectArg) => AddressBookSubject & ForcedSubject<'AddressBookSubject'>,
] => {
  const ability = useMemo(() => {
    const { can, build } = new AbilityBuilder(addressBookAbility);

    can('viewAddressBookEntries', 'AddressBookSubject', {
      'me.globalPermissions': { $in: [Permission.ADDRESS_BOOK_LIST] },
    });
    can('viewAddressBookEntry', 'AddressBookSubject', {
      // use addressBookEntry.permissions
      'me.globalPermissions': { $in: [Permission.ADDRESS_BOOK_GET] },
    });
    can('createAddressBookEntry', 'AddressBookSubject', {
      'me.globalPermissions': { $in: [Permission.ADDRESS_BOOK_CREATE] },
    });
    // use addressBookEntry.permissions
    can('updateAddressBookEntry', 'AddressBookSubject', {
      'me.globalPermissions': { $in: [Permission.ADDRESS_BOOK_UPDATE] },
    });
    // use addressBookEntry.permissions
    can('deleteAddressBookEntry', 'AddressBookSubject', {
      'me.globalPermissions': { $in: [Permission.ADDRESS_BOOK_DELETE] },
    });

    return build({ detectSubjectType });
  }, []);

  const me = useMeOrDefault();
  const subject = useCallback(
    (sub?: AddressBookSubjectArg) => {
      return subjectHelper('AddressBookSubject', { me, ...sub });
    },
    [me],
  );

  return [ability, subject];
};

type CanAddressBookProps = GenericCanSharedProps<Actions>;

export const CanAddressBook = (props: CanAddressBookProps) => {
  const [addressBookAbility, addressBookSubject] = useAddressBookAbility();

  return (
    <GenericCan<Actions, Subjects, AddressBookAbility>
      ability={addressBookAbility}
      subject={addressBookSubject()}
      {...props}
    />
  );
};
